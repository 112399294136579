import { initializeApp } from "firebase/app";

export const firebaseConfig = {
    apiKey: "AIzaSyDgOrOWNfPAYcdnNxY8ayUnpBbiKeIPpb8",
    authDomain: "doqwik-5cfd7.firebaseapp.com",
    projectId: "doqwik-5cfd7",
    storageBucket: "doqwik-5cfd7.appspot.com",
    messagingSenderId: "653049587149",
    appId: "1:653049587149:web:388edd9192e246d6035084",
    measurementId: "G-D3JKDKSBP6",
    databaseURL: 'https://doqwik-5cfd7-default-rtdb.firebaseio.com',
  }

  export const firebaseWeb = initializeApp(firebaseConfig);
