import Header from "../../components/header";
import Footer from "../../components/footer";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardAsVendor from "../../components/dashboardAsVendor";
import axios from "axios";
import { getSession } from "../../utils/helper";
import Modal from "react-bootstrap/Modal";
import { apiBaseUrl } from "../../config/constant";
import { requestNewSubservice } from "../../components/common";


const Service_Selection = () => {
  const token = getSession("token");
  const navigate = useNavigate();
  const p_serviceListData = JSON.parse(localStorage.getItem("p_service_list"));

  const [showprocced, setProceed] = useState(false);
  const [subservice, setsubservice] = useState(false);
  const [showappointment, setappointment] = useState(true);
  const [priceproceed, setpriceproced] = useState(false);
  const [services, setServices] = useState(p_serviceListData);
  const [editprocess, seteditprocess] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [subServices, setSubServices] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [showRequestSuccess, setShowRequestSuccess] = useState(true);
  const [addNewPopup, setAddNewPopup] = useState(false);
  const [procession, setProcessing] =useState(false);
  const handleClose = () => {
    setShow(false);
    setAddNewPopup(false);
    setShowRequestSuccess(false);
    setFormData((peeData) => ({
      ...peeData,
      subService_name : ""
    }))
    setProcessing(false);
  }
  const handleShow = () => setShow(true);
  const [selectedId, setSelectedId] = useState("");
  const [isCategory, setSetIsCategory] = useState(false);
  const [formData, setFormData] = useState({
    services: "",
    sub_services: "",
    price: "",
  });

  const addServiceData = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl + "provider/addedit-services-by-company",
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  // end

  const handleItemClick = (subService_id, subServiceIndex) => {
    setSelectedItem(subServiceIndex);
    setFormData((prevFormData) => ({
      ...prevFormData,
      sub_services: subService_id,
    }));
    setError((preError) => ({
      ...preError,
      subservice: "",
      price: "",
    }));
  };
const [isFirstTime,setIsFirstTime]=useState(false)
  const nextContent = () => {
    if (!showprocced) {
      if (!selectedService) {
        setError((preError) => ({
          ...preError,
          service: "Please select a service.",
          addNewService: "",
        }));
        return;
      }
      setProceed(true);
      setappointment(false);
    } else {
      if (!formData.sub_services) {
        setError((preError) => ({
          ...preError,
          subservice: "Please select a Sub Service.",
        }));
      } else if (!formData.price && isFirstTime ) {
    
        setError((preError) => ({
          ...preError,
          price: "Please enter price.",
        }));
      }

    
    }
    if(formData.sub_services && selectedService){
    
      setIsFirstTime(true)
    }
    setsubservice(true);
    if (formData.sub_services) {
      setpriceproced(true);
    }
    if (formData.price) {
      seteditprocess(true);
    }

    if (formData.services && formData.sub_services.length && formData.price) {
      addServiceData()
        .then((data) => {
          if (data.status === true) {
            handleShow();
          }
        })
        .catch((error) => {
          // console.error("Error occurred:", error);
        });
    }
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(apiBaseUrl + "common/get-service", {
          headers: {
            key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
            "Content-Type": "multipart/form-data",
          },
        });
        setServices(response?.data?.response?.result);
        localStorage.setItem("p_service_list",JSON.stringify(response?.data?.response?.result));
      } catch (error) {
        console.error(error);
      }
    };

    fetchServices();
  }, []);

  const handleServiceSelection = (item) => {
    const optServiceID = sessionStorage.getItem("vendor_service");
    if (item?.isCategory === "Y") {
      setSetIsCategory(true);
    }
    if (item?._id === optServiceID || optServiceID==='undefined'|| optServiceID===null ) {
      setSelectedId(item?._id);
      setSubServices(item?.sub_services);
      setSelectedService(item?.service_name);
      setFormData((prevFormData) => ({
        ...prevFormData,
        services: item?._id,
      }));
      setError((preError) => ({
        ...preError,
        addNewService: "",
        service: "",
      }));
    } else {
      setSelectedId("");
      setSubServices("");
      setSelectedService("");
      setFormData((prevFormData) => ({
        ...prevFormData,
        services: "",
      }));
      setError((preError) => ({
        ...preError,
        addNewService: `You have already selected one service to add more services please contact admin@doqwik.ng`,
        service: "",
      }));
      return false;
    }
  };
  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    const name = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      category: selectedCategory,
    }));
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === "price") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        price: value,
      }));
    }
    if (name === "subService_name") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        subService_name: value,
      }));
      setError((preError) => ({
        ...preError,
        subService_name : ""
      }));
    }
    setError((preError) => ({
      ...preError,
      subservice: "",
      price: "",
    }));
  };
  const sendNewSubServiceRequest = async () =>{
    if(!formData?.subService_name){
      setError((preError) => ({
        ...preError,
        subservice: "",
        price: "",
        subService_name : "Please enter Sub-Service name"
      }));
    } else{
      setProcessing(true);
      const params ={
        ...(token?{token : token}:null),
        ...(formData?.services?{service : formData?.services}:null),
        ...(formData?.subService_name?{subservice : formData.subService_name}:null)
      }
      const result = JSON.parse(await requestNewSubservice(params));
      if(result?.status === true || result?.status === 'true'){
        // console.log('Success');
        setShowRequestSuccess(true);
        setAddNewPopup(false)
        setProcessing(false);
      } else{
        console.log(result?.message);
        setError((preError) => ({
          ...preError,
          subservice: "",
          price: "",
          subService_name : result?.message
        }));
        setProcessing(false);
      }
    }
  }
  return (
    <>
      <Header />
      <div className="dashboard">
        <div className="container">
          <div className="row mx-0">
            <DashboardAsVendor />
            <div className="col-md">
              {showappointment && (
                <div className="form_book_appintment_next mb-4">
                  <div>
                    <div className="row mx-0">
                    <p className="fill_form mb-0">
                      <span> Specialisation </span>
                    </p>
                    <h6 id="text_fotnSixze"> Add Your Working
                      Service.</h6>
                      
                      {error?.addNewService && (
                        <p style={{ color: "red" }}>
                          {error.addNewService ? error.addNewService : ""}
                        </p>
                      )}
                      {error?.service && (
                        <p style={{ color: "red" }}>
                          {error.service ? error.service : ""}
                        </p>
                      )}
                      <ul className="all_services">
                        {services?.map((item, index) => (
                          <li
                            key={item._id}
                            onClick={() => handleServiceSelection(item)}
                            className={
                              selectedId === item?._id ? "colored" : ""
                            }
                          >
                            <img src={item.icon} />
                            <p>{item.service_name}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {showprocced && selectedService && (
                <div className="form_book_appintment_next_form">
                  <div className="row">
                    <p className="fill_form mb-0">
                      <span> Specialisation </span> 
                    </p>
                    <h6 id="text_fotnSixze"> Add Your Working
                      Service.</h6>

                    <div className="row">
                      <div className="col-md-3">
                        <h1>Service</h1>
                        <div className="user_name">
                          <input
                            type="text"
                            value={selectedService}
                            className="unix_slon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {subservice && (
                <div className="form_book_appintment_next_form ">
                  <div className="row "></div>
                  <div className="row">
                    <div className="col-md-12">
                      <h1 id="left_margin">Sub-Service</h1>
                      <div className="user_selection">
                        <ul className="subService-category">
                          {subServices.map((subService, subIndex) => (
                            <li
                              key={subIndex}
                              className={
                                selectedItem === subIndex ? "active" : ""
                              }
                              onClick={() =>
                                handleItemClick(subService._id, subIndex)
                              }
                              id="sub_service_align"
                            >
                              {subService.sub_service_name}
                            </li>
                          ))}
                          <li onClick={() => setAddNewPopup(true) } id="sub_service_align" > {" "} <b>+</b> Add New{" "} </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {error?.subservice && (
                    <p style={{ color: "red" }}>{error?.subservice}</p>
                  )}
                  {/* <p>Please select sub service.</p> */}
                </div>
              )}
              {priceproceed && (
                <div className="form_book_appintment_next_form pb-4">
                  <div className="row">
                    <div className="col-md-12">
                      <h1>Price</h1>
                      <div className="user_selection">
                        <div className="price">
                          <span>
                            <img src={process.env.PUBLIC_URL +"../vendor_dashboard/nan.png"}/>
                          </span>
                          <input type="text" placeholder="Enter Service Amount" name="price" onChange={handleInputChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {error?.price && (
                    <p style={{ color: "red" }}>{error?.price}</p>
                  )}
                </div>
              )}
              <div className="proceed_next mt-4 mb-4">
                {editprocess && (
                  <div class="button-container edit_proceed mt-4 mb-4">
                    <button class="button button1" id="btn-otp-resend">
                      Edit Details
                    </button>
                    <button class="button button2">Edit Details</button>
                  </div>
                )}
                <div
                  class="button-container mt-4 mb-4 mr-0"
                  onClick={nextContent}
                >
                  <button class="button button1"> Proceed To Next</button>
                  <button class="button button2"> Proceed To Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <Modal
        show={show}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>
          <img
            src={process.env.PUBLIC_URL + "/images/user/cong.gif"}
            alt="playstore"
            className="cong-done-json"
            style={{ width: "150px" }}
          />

          <div className="congrats-profile-created">
            <h6>
              <br />
              <span> Service added successfully Done</span>
            </h6>

            <div class="button-container" style={{ width: "200px" }}>
              <button className="button button1">Proceed To Next</button>
              <button
                className="button button2"
                onClick={() => {
                  window.location.href = "/vendor/service";
                }}
              >
                Proceed To Next
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Request Sub-Service */}
       <Modal show={addNewPopup} onHide={handleClose} size='sm' centered>
        <Modal.Header closeButton>
          <h6>Suggest New Sub-Service</h6>
        </Modal.Header>
        <Modal.Body>   
          <div className={procession?"blur-effect":""}>
            <div className={`wallet-enter-Amount form-group`} >
              <input type="text" className='col-12' value={formData.subService_name} name="subService_name" id="subService_name" onChange={handleInputChange} placeholder="Sub-Service Name" />
              <br/>
            </div>
            {error?.subService_name && (<p className='error'>{error?.subService_name}</p>)}
            <br/>
            <div className="assign-worker-container" id="assign_work_list" >
              <button className="button assign-worker" onClick={sendNewSubServiceRequest}>Proceed To Next</button>
              <button className="button assign-worker1" onClick={sendNewSubServiceRequest}>Proceed To Next</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Success Popup */}
      <Modal
        show={showRequestSuccess}
        onHide={handleClose}
        centered
        style={{
          // width: "351px",
          // marginLeft: "38%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
        size='sm'
      >
        <Modal.Body>
          <div className="cross-X"  onClick={handleClose}>
            <img src={process.env.PUBLIC_URL + "/images/user/cross.png"} alt="playstore" />
            <img src={process.env.PUBLIC_URL + "/images/user/X.png"} alt="playstore" />
          </div>
          <img src={process.env.PUBLIC_URL + "/images/user/cong.gif"} alt="playstore" className="cong-done-json" style={{ width: "150px" }} />

          <div className="congrats-profile-created">
            <h6>
              <br />
              <span> Thank you for your request. Our team will contact you soon.</span>
            </h6>

            <div class="button-container" style={{ width: "200px" }}>
              <button className="button button1">Proceed To Next</button>
              <button className="button button2" onClick={() => {navigate('/vendor/service')}} > Proceed To Next </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Service_Selection;
