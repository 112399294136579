import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link ,useNavigate} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import React, { useState,useEffect,useRef } from 'react';
import LeftnavbarIndividual from "../../components/leftnavbaraIndividual";
import { setSession, getSession} from "../../utils/helper";
import axios from 'axios';
import { BaseUrl, apiBaseUrl } from "../../config/constant";

const Individuallatest_detail = ()=>{
const navigate = useNavigate();   
const [textareaValue, setTextareaValue] = useState('');
const [processid,setId_process] = useState({
  jobId: "",
  rejectReason: "",
});
const [reject,setreject] = useState(false);
  const location = useLocation();
  const data = location.state;    
  const base_url = BaseUrl;

const handleClose = () =>{
  setreject(false);
  } 
  
// reject_order
const reject_order = async (data) =>{
 
  try {
    let token = getSession("token");
    
    const response = await axios.post(
      apiBaseUrl+'order/job-reject',
      {
        jobId:data._id,
        rejectReason:textareaValue, 
      },

     
      {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      }
    );
    if (response.data && response.data.status && response.data.status === true) {
      navigate('/individual/latesttasklist');
      setData(response.data.response.result);
      console.log(response.data.response.result);
    } else {
      
      alert("Job verification code is wrong. Please try again.");
    }
    console.log(response.data.response.result);
  } catch (error) {
    console.error(error);
  } 
}
// reject_popup
const reject_popup = (data) =>{
  setId_process(data);
  setreject(true);
} 

// accept_order

const accept_order = async (data) => {
  try {
    let token = getSession("token");
    console.log(data);
    const response = await axios.post(
      apiBaseUrl+'order/job-accept',
      {
        jobId:data._id, 
      },
      {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      }
    );
    navigate('/individual/latesttasklist');
  } catch (error) {
    console.error(error);
  }
};


    return(
        <>
         <Header />
   <div className="dashboard">
    <div className="container">
        <div className="row mx-0">
        <LeftnavbarIndividual></LeftnavbarIndividual>
            <div className="col-md-9">
            <div className="right_side  mt-3">
               <div className="row mx-0" >
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                <div className="btn_es_align">
                <button className="arrow_down"><Link to="/individual/latesttasklist"><img src={process.env.PUBLIC_URL + "/vendor_dashboard/order_detail/order_details.png"} /></Link></button>
                {data.job_status === "Processing" && (
                   <>
    <div className="col-md-4 col-4 px-0">
    <div className="assign-worker-container" onClick={() => accept_order(data)}>
      <button className="button assign-worker">Accept</button>
      <button className="button assign-worker1">Accept</button>
    </div>
  </div>
  <div className="col-md-4 col-4 px-0">
      <div className="assign-viewer-container" onClick={() => reject_popup(data)}>
        <button className="button assign-View-Detail">Reject</button>
        <button className="button assign-View-Detail1">Reject</button>
      </div>
    </div>
    </>
                )}
 
                 
                  </div>
                 

                 
                    <div className="card_details">
                        <div className="card_heading">
                            <h1>Service Location</h1>
                        </div>
                        <div className="card_content">
                          
                            <span><img src={process.env.PUBLIC_URL + "/images/card_location.png"} alt="app"  /> 
                            
                            <p>{data.service_location?.address1}, {data.service_location?.address2},
                      {data.service_location?.land_mark}, {data.service_location?.city},
                      {data.service_location?.state}, {data.service_location?.country}</p></span>
                          
                        </div>
                    </div>
                    <div className="card_details">
                        <div className="card_heading">
                            <h1>Selected Provider</h1>
                        </div>
                        <div className="card_content">
                        <div className="select_provider">   
                            <div>  <img src={base_url + data?.providerData?.profile_pic} alt="app" className="worker_img" /> </div>
                            <div className="select_provider_content">
                                <h1>{data.providerData.fullName}</h1>  
                                <p>{data.providerData.address1}, {data.providerData.address2},
                      {data.providerData.land_mark}, {data.providerData.city},
                      {data.providerData.state}, {data.providerData.country}</p>
                               
                                </div>
                          
                          <div>
                            <span><img src={process.env.PUBLIC_URL + "../images/Star.png"} className="star_img"/><p>4.6</p></span>
                          </div>
                          </div>
                        </div>
                    </div>
                    <div className="card_details">
                        <div className="card_heading">
                            <h1>Slot Date & Timing</h1>
                        </div>
                        <div className="card_content">
                          <ul>
                            <li><span>Service Date</span> <p>{data?.service_date}</p></li>
                            <li><span>Service Timing</span> <p>{data?.service_time}</p></li>
                          </ul>
                        </div>
                    </div>
                    <div className="card_details mb-2">
                        <div className="card_heading">
                            <h1>Service Detail</h1>
                        </div>
                        <div className="card_content">
                            <h2> {data.service_name},{data.sub_service_name} </h2>
                            <p className="my-2">It is a long established fact that reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem is that it has a more-or-less normal distribution of letters, as opposed.</p>
                          <div className="plumbering_img">
                            {data.jobImage? <img src={base_url + data.jobImage} alt="app" className="worker_img" /> : ""}
          
                    
                          </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 mb-4">
                
                    <div className="payment_details mt-2">
                     <h1>Payment Detail</h1>
                     
                     <div className="vendor_payment_status" id="payemtOPtiomplace">
                   
                     <input placeholder="Payment Status"  className="payment_status"/>
                     {data.paymentStatus === "Initialize"?(
                      <button className="prepaid_post" id="prePaidOption">Postpaid Payment</button>
                     ):(
                      <button className="prepaid_post" id="PostPaidOption">Postpaid Payment</button>
                     )}
                     
                     </div>
                     <ul className="mt-2"> 
                        <li className="pt-0"><p>Total Amount</p> <span>₦ {data.amount}</span></li>
                        <li><p>Discount</p> <span className="text_change_color">₦ {data.discount}</span></li>
                        <li><p>Other Charges</p> <span>Free</span></li>
                        <li className="pb-0 bottom_none"><p className="text_change_color">Amount Paid</p> <span className="text_change_color">₦ {data.paidAmount}</span></li>
                     </ul>
                    </div>
                   
                    
                </div>
               </div>
                
             </div>
            </div>
        </div>
    </div>
   </div>
    <Footer />
 <Modal show={reject} onHide={handleClose} centered  style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose}>
            <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
          

            <div className="congrats-profile-created  well_done" style={{marginTop:"0px"}}>
           
         <p>Reason For Reject</p>
              
              <textarea    value={textareaValue} onChange={(e) => setTextareaValue(e.target.value)} className="add_reasons"></textarea>
              <div class="button-container mt-3" style={{ width: "150px" }}   onClick={() => reject_order(data)}>
                <button className="button button1 s_btn" > <Link style={{ textDecoration: "none",color: "#fff !important"}} to='/individual/orderhistory'> Submit
                  </Link></button>
                <button className="button button2">
                  <Link style={{ textDecoration: "none", color: "#ffffff" }} to='/individual/orderhistory'>Submit
                  </Link>
                </button>
              </div>
          
            </div>
          </Modal.Body>
        </Modal> 
    <div>
   
      </div>
        </>
    );
};

export default Individuallatest_detail;
