import React, { useState,useEffect } from "react";
import Slider from "react-slick";
import axios from "axios";
import { getSession } from "../utils/helper";
import { apiBaseUrl } from "../config/constant";
const customDotsClass = 'custom-dots-class';

function DashbordSlider() {
  const [settings] = useState({
    dots: false,
    dotsClass: 'slick-dots customDotsClass',
    infinite: true,
    speed: 500,
    slidesToShow: 2.2,
    slidesToScroll: 2,
    arrows: false
  });
const[data,setData]=useState([])
const token = getSession("token");
  const fetchUserHistoryData = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl+"order/get-user-job-list",
        {
          limit: "",
          skip: "",
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      setData(response.data.response.result);
    } catch (error) {
      console.error(error);
    }
  };
console.log(data,"$55")
  useEffect(() => {
    fetchUserHistoryData()
  }, []);

  return (
    <div>
      <Slider {...settings}>
      {/* {data.map((item,index)=>(
        <div className="t-main-content" key={index}>
        <div className="order-history-box">
                            <div className="row">
                                <div className="col-md-3">
                                <img src={process.env.PUBLIC_URL + "../hisytory_user.png"} alt="app"  className="app_img" />
                                </div>
                                <div className="col-md-9 px-0">
                                    <button className="order_compelte">Order {item.job_status}</button>
                                    <h1>{item.service_name},{item.sub_service_name}</h1>
                                </div>
                            </div>
                            <hr />

                            <div className="row mx-0">
                                <div className="col-md-12 mb-2">
                                <p><img src={process.env.PUBLIC_URL + "../location.png"} alt="app" /> {item.service_location
.address1}-{item.service_location
.postal_code}, {item.service_location
.city},{item.service_location
.state}, {item.service_location
.country}</p>
                                </div>
                                <div className="date_options">
                                    <p>{item.service_date} at {item.service_time}</p>
                                    <span>₦ {item.paidAmount}/-</span>
                                </div>
                                <div className="vertification_code">
                                  <p className="">Verification Code</p>
                                  <div className="numberes">
                                  {String(item.verificationCode).split('').map((char, index) => (
              <input
                key={index}
                type="text"
                placeholder={char}
              />
            ))}
                                  </div>
                                </div>
                                <div className="details_btns">
                                <button className="view_detail">View Detail</button>
                                <button className="rate">Rate</button>
                                <button className="Reorder">Reorder</button>
                                </div>
                            </div>
                    </div>
</div>
      ))} */}


      </Slider>
    </div>
  );
}

export default DashbordSlider;
