import Header from "../../components/header";
import Footer from "../../components/footer";
import React, { useEffect, useState, useRef } from "react";
import { Link , useNavigate} from "react-router-dom";
import { getSession } from "../../utils/helper";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { AddWorkerSchema } from "../../components/validators/vendorFormValidator";
import DashboardAsVendor from "../../components/dashboardAsVendor";
import { apiBaseUrl } from "../../config/constant";
import { getCountry,getSate,getCity } from "../../components/common";

const AddNewProvider = () => {
  const imageInputRef = useRef(null);
  const navigate = useNavigate();
  const token = getSession("token");
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [checkBlur, setBlur] = useState(false);
  const [checkBlur2, setBlur2] = useState(false);
  const [show, setShow] = useState(false);
  const [services, setServices] = useState([]);
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState("");
  const [showSelectService, setShowSelectService] = useState(false);
  const [workerId , setWorkerId]=useState('')
  const [showSelectedService, setShowSelectedService] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const isFetchDataCalled = useRef(false);
  const [selectedServiceData,setSelectedServiceData]= useState([])
  const [selectedOption, setSelectedOption] = useState(false);
  const [state, setState ]=useState([]);
  const [city, setCity]=useState([])
  const [country, setCountry] = useState([])
  const handleClose = () => {setShow(false);   navigate("/vendor/company-workers"); 
};

  const handleShow = () => setShow(true);

  const profileDetailsHandler = () => {
    if (!isSectionVisible) {
      setSectionVisible(true);
      setBlur(false);
    } else {
      setBlur(true);
    
    }

    if (isSectionVisible && checkBlur) {
      setBlur2(true);
      if (checkBlur2) {
        handleShow();
      }
    }
  };

  //   form data field

  const certificateImage = new File([""], "profile_pic.png", {
    type: "image/png",
  });
 
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    country: "",
    state: "",
    city: "",
    address1: "",
    address2: "",
    land_mark: "",
    postal_code: "",
    companyRegNo: "2424",
    tinNumber: "2424",
    platform: "Web",
    identificationType: "",
    identificationNumber: "",
    profile_pic: "",
    userType:"Worker"
  });

  // formData for service field

  const [selectedServices, setSelectedServices] = useState([]);

const handleServiceSelection = (serviceId) => {
  const formattedServiceId = serviceId.replaceAll("'", "");

  if (selectedServices.includes(formattedServiceId)) {
    setSelectedServices((prevSelectedServices) =>
      prevSelectedServices.filter((id) => id !== formattedServiceId)
    );
  } else {
    setSelectedServices((prevSelectedServices) => [
      ...prevSelectedServices,
      formattedServiceId,
    ]);
  }
};

const [formServiceData, setFormServiceData] = useState({
  workerData: "",
  services: [], // Initialize as an empty array
});

// Update formServiceData.services when selectedServices changes
useEffect(() => {
  setFormServiceData((prevFormServiceData) => ({
    ...prevFormServiceData,
    services: selectedServices,
  }));
}, [selectedServices]);

  // end
  // end form data field
  // Fetch API
  const fetchSelectedServiceData = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl+"provider/add-services-company-worker",
        formServiceData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
   
        }
      );
     
         return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  

  const fetchData = async () => {
    try {
      const form = new FormData();
      form.append("fullName", formData.fullName);
      form.append("email", formData.email);
      form.append("phone", formData.phone);
      form.append("country", formData.country);
      form.append("identificationType", formData.identificationType);
      form.append("identificationNumber", formData.identificationNumber);
      form.append("state", formData.state);
      form.append("city", formData.city);
      form.append("land_mark", formData.land_mark);
      form.append("postal_code", formData.postal_code);
      form.append("companyRegNo", formData.companyRegNo);
      form.append("tinNumber", formData.tinNumber);
      form.append("platform", formData.platform);
      form.append("profile_pic", formData.profile_pic); 

      const response = await axios.post(
        apiBaseUrl+"provider/add-worker",
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  // end fetch api
  // form handle change
  const handleChange = async (e) => {
    const { name, value } = e.target;
    if(name==='country'){
      const jsonObject = JSON.parse(value);
      const country= await  getSate(jsonObject.id);
      setState(country)
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: jsonObject.name,
      }));
    }else if(name==='state'){
      const jsonObject = JSON.parse(value);
      const state= await  getCity(jsonObject.id);

setCity(state)
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: jsonObject.name,
      }));
    }else if(name==='city'){
      const jsonObject = JSON.parse(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: jsonObject.name,
      }));
    }else if(name==='email'){
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setSuccess((prevSuccess) => ({
        ...prevSuccess,
        email: "", 
      }));
      const checkEmail = String(value).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      if(checkEmail){
        await checkDuplicateEmail(value);
      } else{
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear the error message for the corresponding field
        }));
      }
    }else if(name==='phone'){
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setSuccess((prevSuccess) => ({
        ...prevSuccess,
        phone: "", 
      }));

      const checkPhone = value.length > 9;
      if(checkPhone){
        await checkDuplicatePhone(value);
      } else{
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear the error message for the corresponding field
        }));
      }

    } else{
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Clear the error message for the corresponding field
      }));
  }
};
  // end form handle change
  // handle form Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    AddWorkerSchema.validate(formData, { abortEarly: false })
      .then(() => {
        if (!isDataFetched) {
          fetchData()
            .then((data) => {
              if (data.status === true) {
                if(data.response.result.message){
                  alert(data.response.result.message);
                }else{
                  const data2 = data.response.result._id;
                  setWorkerId(data2)
                  setIsDataFetched(true);
                  isFetchDataCalled.current = true;
                  setFormServiceData((prevFormServiceData) => ({
                    ...prevFormServiceData,
                    workerData: data2,
                  }));
                  setGalleryData((prevFormServiceData) => ({
                    ...prevFormServiceData,
                    worker_id: data2,
                  }));
                  setShowSelectService(true);
                  // handleShow();
                }
             
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        if (isDataFetched === true) {
          setFormServiceData((prevFormServiceData) => ({
            ...prevFormServiceData,
            services: selectedServices,
          }));
          setGalleryData((prevFormServiceData) => ({
            ...prevFormServiceData,
            services: selectedServices[0],
          }));

          fetchSelectedServiceData()
            .then((data) => {
              if (data.status === true) {
                if(data.response.result)
                {setSelectedServiceData(data.response.result)}
                setShowSelectedService(true);
                setShowSelectService(false);
                // handleShow();
              }
            })
            .catch((error) => {
              console.log(error);
            });
            postImageData().then((data) => {
              if (data.status === true) {
            handleShow()
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((validationErrors) => {
        const newErrors = {};
        validationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        const isFullNameValid = !newErrors.fullName;
        const isPhoneValid = !newErrors.phone;
        const isdocumentTypeValid = !newErrors.documentType;
        const isdocumentNoValid = !newErrors.documentNo;
        if(newErrors.email){ newErrors.isEmailAvailable = ''; }
        if(newErrors.phone){ newErrors.isPhoneAvailable =''; }else{ newErrors.phone = newErrors.isPhoneAvailable }
        if (
          isFullNameValid &&
          isdocumentTypeValid &&
          isPhoneValid &&
          isdocumentNoValid
        ) {
          profileDetailsHandler();
        }

        // Update the state with the new errors
        setErrors(newErrors);
      });
  };
  // end handle form submit

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countries = await getCountry();
        setCountry(countries);
      } catch (error) {
        // Handle any errors here
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  

  // profile Image Capture
  const [selectedImage, setSelectedImage] = useState(null);

  const handleCaptureButtonClick = () => {
    imageInputRef.current.click();
  };

  const handleImageSelect = (event) => {
    const file = event.target.files[0];

    setFormData((prevFormData) => ({
      ...prevFormData,
      profile_pic: file,
    }));
    setSelectedImage(file);
  };

//  ///////////////////////////////////// upload image of Work //////////////////////

const handleImageGallery = (event) => {
  const file = event.target.files[0];
  const name = event.target.name;
  const reader = new FileReader();

  // const [galleryImgPre, setGalleryImgPre] = useState({})
  reader.onloadend = () => {
    // After reading the file, set it as the image source
    setGalleryData((prevFormData) => ({
      ...prevFormData,
      [name]: file,
    }));
  };

  if (file) {
    reader.readAsDataURL(file);
  } else {
    // If no file selected, set the property to null or any default value you want
    setGalleryData((prevFormData) => ({
      ...prevFormData,
      [name]: null,
    }));
  }
};
const [galleryData,setGalleryData] = useState(

  {
    worker_id : "",
    services  : "",
    before_work_pic : "" ,
    after_work_pic :"",
    user_type    : "worker",
  }
)


const postImageData = async () => {
  try {
    const response = await axios.post(
      apiBaseUrl+"provider/worker-service-gallary",
      galleryData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      }
    );
return response.data;
  } catch (error) {
    console.error(error);
  }
};
const fetchServices = async () => {
  try {
    const response = await axios.get(
      "https://doqwik.ng/front/v1/provider/get-company-services-list",
      {
        headers: {
          Authorization:token,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    setServices(response?.data?.response?.result);
  } catch (error) {
    console.error(error);
  }
};
useEffect(() => {
  fetchServices();
}, []);


//CHeck Duplicate Email
const checkDuplicateEmail = async (email) => {
  try {

    const response = await axios.post(
      apiBaseUrl+"provider/check-provider-email",
      {email : email},
      {
        headers: {
          key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed"
        },
      }
    );
    if(response?.data?.responseCode === 200 || response?.data?.responseCode === '200'){
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "", 
        isEmailAvailable : ""
      }));

      setSuccess((prevSuccess) => ({
        ...prevSuccess,
        email: "Available", 
      }));

      setFormData((prevFormData) => ({
        ...prevFormData,
        isEmailAvailable: 1,
        email : email
      }));
    }
    return true;
  } catch (error) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: error?.response?.data?.statusMessage, 
      isEmailAvailable : ""
    }));
    setSuccess((prevSuccess) => ({
      ...prevSuccess,
      email: "", 
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      isEmailAvailable: 0,
      email : email
    }));
    return false;

  }
}  //End of function

//CHeck Duplicate Email
const checkDuplicatePhone = async (phone) => {
  try {

    const response = await axios.post(
      apiBaseUrl+"provider/check-provider-phone",
      {phone : phone},
      {
        headers: {
          key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed"
        },
      }
    );
    if(response?.data?.responseCode === 200 || response?.data?.responseCode === '200'){
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: "", 
        isPhoneAvailable : ""
      }));

      setSuccess((prevSuccess) => ({
        ...prevSuccess,
        phone: "Available", 
      }));

      setFormData((prevFormData) => ({
        ...prevFormData,
        isPhoneAvailable: 1,
      }));
    }
    return true;
  } catch (error) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      phone: error?.response?.data?.statusMessage, 
      isPhoneAvailable : ""
    }));
    setSuccess((prevSuccess) => ({
      ...prevSuccess,
      phone: "", 
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      isPhoneAvailable: 0,
    }));
    return false;
  }
}  //End of function
// ///////////////////////////////////// ENd //////////////////////////////////////////////////////////////
  return (
    <>
      <Header />
      <div className="create-profile">
        <div className="form_book_appintment">
          <div className="d-flex justify-content-between" style={{ height: "70px" }} >
            <p className="fill_form"> Complete Your Company Provider’s Details.</p>
            <div className="add-pro-img">
              {selectedImage && selectedImage ? (<div className="image-container" onClick={handleCaptureButtonClick} >
                  <img className="circle-img" src={process.env.PUBLIC_URL + "/images/user/circle.png"} alt="profile-add" />
                  <div className="overlay">
                    {selectedImage && (<img src={URL.createObjectURL(selectedImage)} alt="Selected" id="circle_uploaded_image" /> )}
                  </div>
                </div>
              ) : (
                <>
                  <div className={`image-container ${errors.profile_pic ? "VendorInputError" : ""}`} onClick={handleCaptureButtonClick} >
                    <img className="circle-img" src={process.env.PUBLIC_URL +"/images/user/circle.png"}alt="profile-add" />
                    <div className="overlay">
                      <img src={process.env.PUBLIC_URL + "/images/user/addprofile.png"} alt="overlay" />
                    </div>
                  </div>
                  {errors.profile_pic && (<div className="error" style={{ position: "absolute", top: "40px" }} >{errors.profile_pic}</div>)}
                </>
              )}
              <input type="file" accept="image/*" style={{ display: "none" }} onChange={handleImageSelect} ref={imageInputRef} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 col-lg-3">
              <div className={`form_group ${errors.fullName ? "VendorInputError" : ""}`} >
                <input type="text" onChange={handleChange} placeholder="Full Name" name="fullName" id="name" oninput="allow_alphabets(this)" />
              </div>
              {errors.fullName && (<div className="error">{errors.fullName}</div>)}
            </div>
            <div className="col-md-4 col-lg-3">
              <div className={`form_group ${errors.email ? "VendorInputError" : ""} ${success.email ? "VendorInputSuccess" : ""}` } >
                <input type="text" onChange={handleChange} placeholder="Email Address" name="email" id="email" />
              </div>
              {errors.email && (<div className="error">{errors.email}</div>)}
              {errors.isEmailAvailable && <div className="error">{errors.isEmailAvailable}</div>}
            </div>
            <div className="col-md-4 col-lg-3">
              <div className={`form_group ${errors.phone ? "VendorInputError" : ""} ${success.phone ? "VendorInputSuccess" : ""}`} >
                <input type="number" onChange={handleChange} placeholder="Mobile Number" maxlength="10" minlength="10" name="phone" id="phone" onkeypress="return onlyNumberKey(event)" />
              </div>
              {errors.phone && (<div className="error">{errors.phone}</div>)}
              {/* {errors.isPhoneAvailable && <div className="error">{errors.isPhoneAvailable}</div>} */}
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="form_group">
                <div className="custom-select-wrapper">
                  <select className={`custom-select ${errors.city ? "VendorInputError" : ""}`} name="country" onChange={handleChange} >
                    <option>Select Country</option>
                    {country.map((item, index)=>(
                      <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                    ))}
                  </select>
                  <img src={ process.env.PUBLIC_URL + "/images/user/selectArrow.png"} alt="arrow" className="arrow-image" />
                </div>
                {errors.country && (<div className="error">{errors.country}</div>)}
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="form_group">
                <div className="custom-select-wrapper">
                  <select className={`custom-select ${errors.city ? "VendorInputError" : ""}`} name="state" onChange={handleChange} >
                    <option>Select State</option>
                    {state.map((item, index)=> (           
                      <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                    ))} 
                  </select>
                  <img src={process.env.PUBLIC_URL +"/images/user/selectArrow.png"}alt="arrow" className="arrow-image"/>
                </div>
                {errors.state && (<div className="error">{errors.state}</div>)}
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="form_group">
                <div className="custom-select-wrapper">
                  <select className={`custom-select ${errors.city ? "VendorInputError" : ""}`} name="city" onChange={handleChange} >
                    <option>Select City</option>
                    {city.map((item, index)=>(
                      <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                    ))}
                  </select>
                  <img src={process.env.PUBLIC_URL +"/images/user/selectArrow.png"} alt="arrow" className="arrow-image" />
                </div>
                {errors.city && (<div className="error">{errors.city}</div>)}
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className={`form_group ${errors.land_mark ? "VendorInputError" : ""}`} >
                <input type="text" onChange={handleChange} placeholder="Landmark" name="land_mark" id="name" oninput="allow_alphabets(this)" />
              </div>
              {errors.land_mark && (
                <div className="error">{errors.land_mark}</div>
              )}
            </div>
            <div className="col-md-6 col-lg-3">
              <div
                className={`form_group ${
                  errors.postal_code ? "VendorInputError" : ""
                }`}
              >
                <input
                  type="number"
                  onChange={handleChange}
                  placeholder="Postal Code"
                  maxLength={6}
                  name="postal_code"
                  id="postal_code"
                  onkeypress="return onlyNumberKey(event)"
                />
              </div>
              {errors.postal_code && (
                <div className="error">{errors.postal_code}</div>
              )}
            </div>
            </div>
          <div className="row">
          <div className="col-md-6">
              <div
                className={`form_group ${
                  errors.address1 ? "VendorInputError" : ""
                }`}
              >
                <input
                  type="text"
                  onChange={handleChange}
                  placeholder="Address Line 1 "
                  name="address1"
                  id="address1"
                  oninput="allow_alphabets(this)"
                />
              </div>
              {errors.address1 && (
                <div className="error">{errors.address1}</div>
              )}
            </div>

            <div className="col-md-6">
              <div
                className={`form_group ${
                  errors.address2 ? "VendorInputError" : ""
                }`}
              >
                <input
                  type="text"
                  onChange={handleChange}
                  placeholder="Address Line 2"
                  name="address2"
                  id="address2"
                  oninput="allow_alphabets(this)"
                />
              </div>
              {errors.address2 && (
                <div className="error">{errors.address2}</div>
              )}
            </div>
          </div>
        </div>

        <div className="">
          <></>
        </div>
        {/* Varification section */}
        {/* {isSectionVisible && ( */}
        <>
          <div className="form_book_appintment mt-4">
            <div
              className="d-flex justify-content-between"
              style={{ height: "70px" }}
            >
              <p className="fill_form">
                <span> Verification </span> <br />
                Complete Your Verification Details.
              </p>
            </div>
            <div className={!checkBlur ? "" : "blur-effect"}>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xs">
                  <div className="verification">
                    <div className="row">
                      <div className="col-md-6 col-lg-4">
                      <div className="identify_card">
                      <input
                        type="radio"
                        name="identificationType"
                        value="nationalID"
                        // checked={selectedOption === 'nationalID'}
                        onChange={handleChange}
                      />
                      <p>National Identification Card</p>
                    </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                      <div className="identify_card">
                      <input
                        type="radio"
                        name="identificationType"
                        value="passport"
                        // checked={selectedOption === 'passport'}
                        onChange={handleChange}
                      />
                      <p>Passport</p>
                    
                    </div>
                    {errors.identificationType && (
                    <div className="error">
                      {errors.identificationType}
                    </div>
                  )}
                      </div>
                      <div className="col-md-6 col-lg-4">
                      <div className="identify_card">
                      <input
                        type="radio"
                        name="identificationType"
                        value="drivingLicense"
                        // checked={selectedOption === 'drivingLicense'}
                        onChange={handleChange}
                      />
                      <p>Driving Licence</p>
                    </div>
                      </div>
                    </div>
                    
                    
                    
                    
                    
                  </div>
                  
                </div>
                <div className="col-sm-12 col-md-12 col-lg-3 col-xs">
                <div className="mt-2">
                      <input
                        type="text"
                        placeholder="Enter Identification Card No."
                        className="card_verify"
                        name="identificationNumber"
                        onChange={handleChange}
                      />
                      {errors.identificationNumber && (
                        <div className="error">
                          {errors.identificationNumber}
                        </div>
                      )}
                    </div>
                </div>
              </div>

              <div className={!checkBlur ? "" : "blur-effect"}></div>
            </div>
          </div>
        </>

        {showSelectService ? (
          <div className="form_book_appintment_next mt-4">
            <div className={!checkBlur ? "" : "blur-effect"}>
              <div className={!checkBlur ? "" : "blur-effect"}>
                <div className="row">
                  <p className="fill_form">
                    <span> Specialisation </span> <br /> Select
                    Provider’s Services.
                  </p>
                  <ul className="all_services" id="servie_select">
                    {services.map((item, index) => (
                      <li
                        key={item._id}
                        className={selectedServices.includes(item._id) ? 'active' : ''}
                        onClick={() =>
                          handleServiceSelection(item._id)
                        }
                      >
                        <img src={item.subServices.sub_service_icon} />
                        <p>{item.subServices.sub_service_name}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {showSelectedService ? (
          <>
            <div className="form_book_appintment_next mt-4">
              <div className={!checkBlur ? "" : "blur-effect"}>
                <div className={!checkBlur ? "" : "blur-effect"}>
                  <div className="row">
                    <p className="fill_form">
                      <span> Specialisation </span> <br /> Select
                      Provider’s Services.
                    </p>
                    <ul className="after_all_services">
                    {selectedServiceData.map((item, index) => (
<li key={index}>
<img src={item.serviceData.icon} />
<p>{item.serviceData.service_name}</p>
</li>
))}

                  
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="add_more_image">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <p className="fill_form">
                      <span> Work Gallery </span> <br /> Upload
                      Provider’s Services Images.
                    </p>
                  </div>
                  <div className="col-md-6">
                    <div className="add_more_btn">
                      {/* <button className="add_more_image_btn">
                        Add More Images
                      </button> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="images_upload">
                      <div className="before_upload">
                      <label htmlFor="before_work_pic">
                      <img
src={
galleryData.before_work_pic
? URL.createObjectURL(galleryData.before_work_pic)
: process.env.PUBLIC_URL + "/images/after_work.png"
}
alt="Work"
/>
          
        </label>
        <input
          type="file"
          id="before_work_pic"
          name="before_work_pic"
          style={{ display: "none" }}
          onChange={handleImageGallery}
        />
                    
                      </div>
                      <div className="after_upload">
                      <label htmlFor="after_work_pic">
        
                                        <img
src={
galleryData.after_work_pic
? URL.createObjectURL(galleryData.after_work_pic)
:  process.env.PUBLIC_URL + "/images/befor_image.png"
}
alt="Work"
/>
        </label>
        <input
          type="file"
          id="after_work_pic"
          name="after_work_pic"
          style={{ display: "none" }}
          onChange={handleImageGallery}
        />
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {checkBlur2 && (
          <div
            class="button-container mt-4 mb-4"
            style={{ width: "190px", float: "left" }}
          >
            <button class="button button1" id="btn-otp-resend">
              Edit Details
            </button>
            <button class="button button2">Edit Details</button>
          </div>
        )}
        <div
          className=" add_provider_btn button-container mt-4 mb-4"
          style={{ marginRight: "0" }}
        >
          <button className="button button1" onClick={handleSubmit}>
            {" "}
            Proceed To Next
          </button>
          <button className="button button2" onClick={handleSubmit}>
            {" "}
            Proceed To Next
          </button>
        </div>
      </div>
            
      
      <section className="mb-0">
        <div className="download_aap">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <img
                  src={process.env.PUBLIC_URL + "../app_img.png"}
                  alt="app"
                  className="app_img"
                />
              </div>
              <div className="col-md-6 pt-4">
                <h1>Download the app</h1>
                <p>
                  Get the app. Get things done. Compare prices, read reviews and
                  book top-rated services. All in one free app.
                </p>
              </div>
              <div className="col-md-3">
                <ul>
                  <li>
                    {" "}
                    <img
                      src={process.env.PUBLIC_URL + "../androied.png"}
                      alt="app"
                      className="app_img"
                    />{" "}
                  </li>
                  <li>
                    {" "}
                    <img
                      src={process.env.PUBLIC_URL + "../ios.png"}
                      alt="app"
                      className="app_img"
                    />{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      
      <Modal
        show={show}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>
          <img
            src={process.env.PUBLIC_URL + "/images/user/cong.gif"}
            alt="playstore"
            className="cong-done-json"
            style={{ width: "150px" }}
          />

          <div className="congrats-profile-created">
            <h6>
              Congratulations
              <br />
              <span> Worker has added!</span>
            </h6>

            <div class="button-container" style={{ width: "200px" }}>
              <button className="button button1">Proceed To Next</button>
              <button className="button button2">
                <Link
                  to="/vendor/company-workers"
                  style={{ textDecoration: "none", color: "#ffffff" }}
                >
                  {" "}
                  Proceed To Next{" "}
                </Link>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewProvider;
