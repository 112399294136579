import Modal from 'react-bootstrap/Modal'; 
import './common.css'
import { Accordion, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React ,{ useRef , useCallback , useState,useEffect} from "react";
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Header from './header';
import { apiBaseUrl } from '../config/constant';
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';
import { getSessionForIndividual, getSessionForLoginCompany, getSessionForLoginUser, getSessionForLoginWorker } from '../utils/helper';

const Footer = () => {
  // const {data}=props;
  const company = getSessionForLoginCompany('vendor')
  const Individual=getSessionForIndividual('individual')
  const  user=getSessionForLoginUser('user');
  const worker = getSessionForLoginWorker('worker');
  const f_generalData = JSON.parse(localStorage.getItem('footer_generalData'));
  const [data,setData]=useState(f_generalData?f_generalData:[])

  const addServiceData = async () => {
    try {
      const response = await axios.get(
        apiBaseUrl+"common/get-general-data",
        {
          headers: {
            key: 'd1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed',
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response)
      setData(response.data.response)
      localStorage.setItem('footer_generalData', JSON.stringify(response?.data?.response))

      // console.log(response.data,"hii");
    } catch (error) {
      console.error(error);
    }
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [check,setCheck] = useState(false);
  const headerRef = useRef();
  const openHeaderModal = () => {
    headerRef.current.openModal();
  };

  const openHeaderCompanyModal = () => {
    headerRef.current.openCompanyModel();
  };

  const openIndividualModel = () => {
    headerRef.current.openIndividualModel();
  };

  const [formData,setFormData]=useState('')
  // validation email for subscribe

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [show2,setShow2]=useState(false);
  const handleShow2=()=>{
    setShow2(true)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError('');
  };

  const handleSubscribe = async () => {
    if (!email) {
      setEmailError('Please enter an email address');
    } else if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
    } else {
      try {
        const response = await postEmailData();
        
        if (response.status === true) {
          Congratulation();
        }
      } catch (error) {
        // Handle the error if the subscription fails
        console.error('Error:', error);
      }
  
      handleShow2();
  
      // Reset the input field and error message
      setEmail('');
      setEmailError('');
    }
  };
  


  const validateEmail = (email) => {
    // A simple email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  
  const postEmailData = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl+'common/subscribe-newsletter',
        {
         email : email
      
        },
        {
          headers: {
            key: 'd1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed',
            'Content-Type': 'application/json',
          },
        }
      );
      return(response.data);
    } catch (error) {
      console.error(error);
    }
  };


  function getCurrentYear() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return currentYear;
}

// Example usage:
const currentYear = getCurrentYear();
  useEffect(()=>{
    // addServiceData();
    addServiceData();
    getCurrentYear();
  },[])
  // end validation email subscribe
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };

  const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
  };

  const toggleDropdown3 = () => {
    setIsOpen3(!isOpen3);
  };

  const toggleDropdown4 = () => {
    setIsOpen4(!isOpen4);
  };

  const toggleDropdown5 = () => {
    setIsOpen5(!isOpen5);
  };

const openModal=()=>{
  handleShow()
}
  return (
    <>
    <div id="ds-k-none">
    <Header ref={headerRef} /> 
    </div>


 
   
    <div className='futer'>
      <div className='container'>
      <div className='d-flex justify-content-between'>
        <div className='logo' id='mb-Futer_logo'>
<img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="logo"/>
        </div>
        <ul className='social_media'>
        {/* <img src={process.env.PUBLIC_URL + "/images/socialMedia.png"}/> */}
        <li><Link to={data?.result?.facebook_link} target="_blank" rel="noopener noreferrer" aria-label="Go to facebook"><i className="fa fa-facebook"></i></Link></li>
        <li><Link to={data?.result?.insta_link}  target="_blank" rel="noopener noreferrer" aria-label="Go to instgram" ><i className="fa fa-instagram"></i></Link></li>
        <li><Link to={data?.result?.linkedin_link}  target="_blank" rel="noopener noreferrer" aria-label="Go to linkdin"><i className="fa fa-linkedin" ></i></Link></li>
        <li><Link to={data?.result?.twitter_link}  target="_blank" rel="noopener noreferrer" aria-label="Go to twitter">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><style>{`svg{fill:#2e3092}`}</style><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
          </Link></li>
        {/* <FontAwesomeIcon icon="" /> */}

        </ul>
      </div>
      <div className='footer-line'>     </div>
<Row className='footer-hg-subtitle mb_display_none' >
<Col>
<ul style={{width:"91px"}}>
  <li id='futer-hg-title'> Quick Links </li>
  <li> <Link to="/" style={{ textDecoration: 'none', color:"#000000" }}>Home</Link> </li>
  <li> </li>
  <li> <Link to="/services" style={{ textDecoration: 'none', color:"#000000" }}>Services</Link> </li>
  <li> <Link to="/contact" style={{ textDecoration: 'none', color:"#000000" }}>Contact</Link> </li>
  <li> <Link to="/about-us" style={{ textDecoration: 'none', color:"#000000" }}>About us</Link> </li>
</ul>
</Col>
<Col>
<ul style={{ width: "150px" }}>
  <li id='futer-hg-title'>Join DoQwik</li>
  <li onClick={() => { worker || user || company || Individual ? openModal() : openHeaderModal() }}>Sign Up</li>
  <li><Link to="/getapp" style={{ textDecoration: 'none', color: "#000000" }}>Get The App</Link></li>
  <li onClick={() => { worker || user || company || Individual ? openModal() : openHeaderCompanyModal() }}>Join As A Company</li>
  <li onClick={() => { worker || user || company || Individual ? openModal() : openIndividualModel() }}>Join As An Individual</li>
</ul>

</Col>
<Col>
<ul style={{width:"123px"}}>
  <li id='futer-hg-title'> Top Services </li>
  {data?.topFooterService && (<>
    {data?.topFooterService.slice(0,4).map((item,index)=>(
      <li> <Link to={`/user_service_list/${item._id}`}>{item.service_name}</Link> </li>
    ))}
    </>)} 
  
</ul>
</Col>
<Col>
<ul  style={{width:"120px"}}>
<li id='futer-hg-title'>
    Top Services
  </li>
  {data?.topFooterService && (<>
    {data?.topFooterService.slice(4,7).map((item,index)=>(
      <li> <Link to={`/user_service_list/${item._id}`}>{item.service_name}</Link> </li>
    ))}
  </>)}
  
  
</ul>
</Col>
<Col>
<ul  style={{width:"180px"}}>
  <li id='futer-hg-title'>
    Helps & Support
  </li>
  <li>
  <Link to="/Faq" style={{ textDecoration: 'none', color:"#000000" }}> FAQ</Link>

  </li>
  {/* <li>
  <Link to="/Faq" style={{ textDecoration: 'none', color:"#000000" }}>Safety</Link>
  
  </li> */}
  <li>
  <Link to="/term_condition" style={{ textDecoration: 'none', color:"#000000" }}> Terms & Conditions</Link>
   
  </li>
  <li>
  <Link to="/privacy-policy" style={{ textDecoration: 'none', color:"#000000" }}> Privacy Policy</Link>
   
  </li>
</ul>
</Col>
<Col>
  <ul id="subscribe" className='user_subscribe' >
  <li id='futer-hg-title'>
      Newsletter
    </li>
    <li id="footer-iput"  >
      <input type='email' placeholder='Enter Your Email '      value={email}
        onChange={handleEmailChange}/>

    </li>
    {emailError && <p style={{position:"relative", right:"0"}} className="error-message" id="subscribe-email">{emailError}</p>}
  <li>
<div className="button-container" style={{ margin: "0 0px" }}>
  <button className="button button1" style={{width:"176px"}} onClick={handleSubscribe}>Subscribe Now </button>
  <button className="button button2"  style={{width:"176px"}} onClick={handleSubscribe}>Subscribe Now </button>
</div>
</li>
  </ul>
</Col>


</Row>

<Row className='footer-hg-subtitle web_display_block' >
<Accordion>
<Accordion.Item eventKey="0">
        <Accordion.Header>Quick Links</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li><Link to="/" style={{ textDecoration: 'none', color: "#000000" }}>Home</Link></li>
            <li><Link to="/about-us" style={{ textDecoration: 'none', color: "#000000" }}>About</Link></li>
            <li><Link to="/contact" style={{ textDecoration: 'none', color: "#000000" }}>Contact</Link></li>
            <li><Link to="/services" style={{ textDecoration: 'none', color: "#000000" }}>Services</Link></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      {/* Other Accordion.Item components go here */}
<Accordion.Item eventKey="1">
        <Accordion.Header>Join DoQwik</Accordion.Header>
        <Accordion.Body>
          {worker || user || company || Individual ?
            <div onClick={openModal}>
              <ul>
                <li onClick={openModal}>Sign Up</li>
                <li onClick={openModal}>Join As A Company</li>
                <li onClick={openModal}>Join As A Individual</li>
              </ul>
            </div> :
            <>
              <ul>
                <li onClick={openHeaderModal}>Sign Up</li>
                <li onClick={openHeaderCompanyModal}>Join As A Company</li>
                <li onClick={openIndividualModel}>Join As A Individual</li>
              </ul>
            </>
          }
        </Accordion.Body>
      </Accordion.Item>
<Accordion.Item eventKey="2">
        <Accordion.Header>Top Services</Accordion.Header>
        <Accordion.Body>
          {data?.topFooterService && (
            <>
              {data.topFooterService.slice(0, 4).map((item, index) => (
                <ul key={index}>
                  <li><Link to={`/user_service_list/${item._id}`}>{item.service_name}</Link></li>
                </ul>
              ))}
            </>
          )}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>  Helps & Support</Accordion.Header>
        <Accordion.Body>
          <ul>  
        <li>
  <Link to="/Faq" style={{ textDecoration: 'none', color:"#000000" }}> FAQ</Link>

  </li>
  <li>
  <Link to="/term_condition" style={{ textDecoration: 'none', color:"#000000" }}> Terms & Conditions</Link>
   
  </li>
  <li>
  <Link to="/privacy-policy" style={{ textDecoration: 'none', color:"#000000" }}> Privacy Policy</Link>
   
  </li>
  </ul>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>

      {/* Other Accordion.Item components go here */}

      {/* Repeat the structure for other sections */}

      {/* Example: */}
      {/* 
      <Accordion.Item eventKey="3">
        <Accordion.Header>Helps & Support</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li><Link to="/Faq" style={{ textDecoration: 'none', color: "#000000" }}>FAQ</Link></li>
            <li><Link to="/term_condition" style={{ textDecoration: 'none', color: "#000000" }}>Terms & Conditions</Link></li>
            <li><Link to="/privacy-policy" style={{ textDecoration: 'none', color: "#000000" }}>Privacy Policy</Link></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      */}
   
{/* <Col>

<Dropdown show={isOpen1} onToggle={toggleDropdown1}>
      <Dropdown.Toggle variant="succes" id="dropdown-basic">
      Quick Links
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">  <Link to="/" style={{ textDecoration: 'none', color:"#000000" }}>Home</Link></Dropdown.Item>
        <Dropdown.Item href="#/action-3"><Link to="/about-us" style={{ textDecoration: 'none', color:"#000000" }}>About</Link></Dropdown.Item>
        <Dropdown.Item href="#/action-2">    <Link to="/contact" style={{ textDecoration: 'none', color:"#000000" }}>Contact</Link>   </Dropdown.Item>
        <Dropdown.Item href="#/action-3">  <Link to="/services" style={{ textDecoration: 'none', color:"#000000" }}>Services</Link></Dropdown.Item>
        <Dropdown.Item href="#/action-3">  <Link to="/contact" style={{ textDecoration: 'none', color:"#000000" }}>Contact</Link></Dropdown.Item>
 
      </Dropdown.Menu>
    </Dropdown>
</Col>
<Col>

<Dropdown show={isOpen2} onToggle={toggleDropdown2}>
      <Dropdown.Toggle variant="succes" id="dropdown-basic">
      Join DoQwik
      </Dropdown.Toggle>
      <Dropdown.Menu>
      {worker || user || company ||Individual? 
               <div onClick={openModal}>
               <Dropdown.Item href="#/action-1" onClick={openModal}>Sign Up</Dropdown.Item>
               <Dropdown.Item href="#/action-2" onClick={openModal}>Join As A Company</Dropdown.Item>
               <Dropdown.Item href="#/action-3" onClick={openModal}>Join As A Individual</Dropdown.Item>
             </div>:
             <>
                     <Dropdown.Item href="#/action-1 " onClick={openHeaderModal}>   Sign Up</Dropdown.Item>
        <Dropdown.Item href="#/action-2" onClick={openHeaderCompanyModal}>   Join As A Company  </Dropdown.Item>
        <Dropdown.Item href="#/action-3"  onClick={openIndividualModel}>  Join As A Individual</Dropdown.Item>
             </>

      }
 
      </Dropdown.Menu>
    </Dropdown>

</Col>
<Col>

<Dropdown show={isOpen3} onToggle={toggleDropdown3}>
      <Dropdown.Toggle variant="succes" id="dropdown-basic">
      Top Services
      </Dropdown.Toggle>
      <Dropdown.Menu>
      {data?.topFooterService && (<>
        {data?.topFooterService.slice(0,4).map((item,index)=>(
        <Dropdown.Item href="#/action-1">   <Link to={`/user_service_list/${item._id}`}>{item.service_name}</Link></Dropdown.Item>
        ))}
      </>) }
      
      </Dropdown.Menu>
    </Dropdown>

</Col>
<Col>

<Dropdown show={isOpen4} onToggle={toggleDropdown4}>
      <Dropdown.Toggle variant="succes" id="dropdown-basic">
      Top Services
      </Dropdown.Toggle>
      <Dropdown.Menu>
      {data?.topFooterService && (<>
        {data?.topFooterService.slice(4,7).map((item,index)=>(
        <Dropdown.Item href="#/action-1">   <Link to={`/user_service_list/${item._id}`}>{item.service_name}</Link></Dropdown.Item>
        ))}
      </>)}
      
      </Dropdown.Menu>
    </Dropdown>

</Col>
<Col>

<Dropdown show={isOpen5} onToggle={toggleDropdown5}>
      <Dropdown.Toggle variant="succes" id="dropdown-basic">
      Helps & Support
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">    <Link to="/Faq" style={{ textDecoration: 'none', color:"#000000" }}> FAQ</Link></Dropdown.Item>
        <Dropdown.Item href="#/action-3">  <Link to="/term_condition" style={{ textDecoration: 'none', color:"#000000" }}> Terms & Conditions</Link></Dropdown.Item>
        <Dropdown.Item href="#/action-2">  <Link to="/privacy-policy" style={{ textDecoration: 'none', color:"#000000" }}> Privacy Policy</Link>  </Dropdown.Item>
  
      </Dropdown.Menu>
    </Dropdown>
</Col>
<Col>
  <ul id="subscribe" className='user_subscribe mb_display_none' >
  <li id='futer-hg-title'>
      Newsletter
    </li>
    <li id="footer-iput"  >
      <input type='email' placeholder='Enter Your Email '      value={email}
        onChange={handleEmailChange}/>

    </li>
    {emailError && <p style={{position:"relative", right:"0"}} className="error-message" id="subscribe-email">{emailError}</p>}
  <li>
<div className="button-container">
  <button className="button button1" style={{width:"176px"}} onClick={handleSubscribe}>Subscribe Now</button>
  <button className="button button2"  style={{width:"176px"}} onClick={handleSubscribe}>Subscribe Now</button>
</div>
</li>
  </ul>
</Col> */}
</Row>

 <div className='footer-line'>     </div>
 <p id="footer-last-line">© {currentYear} Copyright DoQwik. All Rights Reserved.</p>
 </div>
      </div>
     {/* for emil confirmation popup */}
     <Modal show={show2}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={()=>{window.location.reload();}}>
            <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
          

            <div className="congrats-profile-created  well_done" style={{marginTop:"0px"}}>
            <img
                src={process.env.PUBLIC_URL + "../images/greeting.gif"}
                alt="playstore" className="gif"
              />
         
              <p>Thank you for subscribing to DoQwik Services, We will keep you posted on our updates.</p>
              <div class="button-container" style={{ width: "150px" }} onClick={()=>{window.location.reload();}}>
                <button className="button button1" > Proceed To Next
                 </button>
                <button className="button button2">
                Proceed To Next
          
                </button>
              </div>
          
            </div>
          </Modal.Body>
        </Modal> 

        <Modal
        show={show}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created  well_done"
            style={{ marginTop: "0px" }}
          >
            
            <h2>Logout First !</h2>
            {/* <p>Please Continue Your Work</p> </>:<h2>Job Rejected!</h2>  */}

            <div
              class="button-container"
              style={{ width: "150px" }}
              onClick={handleClose}
            >
              <button className="button button1"> Proceed To Next</button>
              <button className="button button2">Proceed To Next</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Footer
