import React from 'react'
import Header from '../../components/header';
import Footer from '../../components/footer';
import DashboardAsVendor from "../../components/dashboardAsVendor";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar} from '@fortawesome/fontawesome-free-solid';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import  { useState, useEffect } from 'react';
import { Link , useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BaseUrl, apiBaseUrl } from '../../config/constant';
import { getSession } from '../../utils/helper';
import { chnageWorkerOnlineStatus } from '../../components/common';
import Modal from "react-bootstrap/Modal";

const Vendorworkdetails =  () =>{
    const navigate = useNavigate();
    const token = getSession("token");
    const [isActive, setIsActive] = useState(false);
    const [show, setShow] = useState(false);
    const { key } = useParams();
    const [profileData,setProfileData] = useState([]);
    const [serviceData ,setServicesData] = useState([]);
    const [workerGalleryData , setWorkerGalleryData]= useState([]);
    const [clientReviewsData, setClientReviewsData] = useState([]);
    const [data,setData]=useState([])
    const [selectedService, setSelectedService] = useState('');
    const [subServices,setSubServices] = useState([])
    const [selectedId, setSelectedId] = useState('');
    const [isProcessing, setIsProcession] = useState(false);
    const handleClose = () => {
      setShow(false);
      navigate("/vendor/company-workers");
    };
    const handleShow = () => setShow(true);
    const handleClick = () => {
      setIsActive(true);
    };
    const [value, setValue] = useState(false);

  const handleChange = (val) => {
    setValue(val);
  };
    const percentage = 100;
    // const profileImage = 'http://localhost:3000/vendor_dashboard/my%20Profile.png';

    const getCompanyServiceList = async () => {
        try {
          const url = apiBaseUrl+'provider/get-company-services-list';
          const response = await axios.get(url, {
            headers: {
              'Authorization': token
            }
          });
          setData(response.data.response.result)
        } catch (error) {
          // Handle errors
          console.error('Error fetching data:', error);
        }
      };
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.post(
              apiBaseUrl+'provider/worker-detail',
              { worker_id: key },
              {
                headers: {
                  key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
                  'Content-Type': 'application/json'
                }
              } 
            );
            setProfileData(response?.data?.response?.result?.workerDetailsData);
            setServicesData(response?.data?.response?.result?.serviceDetails);
            setWorkerGalleryData(response?.data?.response?.result?.workerServiceData);
            setClientReviewsData(response?.data?.response?.result?.workerFeedbackData);
          } catch (error) {
            console.error(error);
          }
        };
        fetchData();
      }, []);

    
      const addServiceToWorker = async () => {
        try {
          const response = await axios.post(
            'https://doqwik.ng/front/v1/provider/add-services-company-worker',
            {
              services: [selectedId],
              workerData: profileData?._id,
            },
            {
              headers: {
                Authorization: token,
                     'Content-Type': 'application/json',
              },
            }
          );
          // console.log('Response:', response);
          if(response.data.status===true){
            setSelectedId('')
            handleShow()
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };
  

  const handleEdit=(item)=>{
    navigate('/vendor/edit_worker', { state: item });
  }
  const handleServiceSelection=(item)=>{
    setSelectedId(item?._id)
    setSubServices(item?.sub_services);
    setSelectedService(item?.service_name);
  }
  const handleOnlineStatus = async (status) => {
    try {
      if(profileData && profileData.online_status !== status){
        const token = getSession('token');
        const worker_id = profileData._id;
        setIsProcession(true);
        if(status && token && worker_id){
          const options = {
            token : token,
            status : status,
            worker_id : worker_id
          }        
          const result = JSON.parse(await chnageWorkerOnlineStatus(options));
          if(result.status === true || result.status === 'true'){
            setProfileData((preData)=>({
              ...preData,
              online_status : status
            })) 
          }else{

          }
        } 
        setIsProcession(false);
      }
    } catch (error) {
      setIsProcession(false);
    }
  }

    return(
    <>
    <Header />

    <div className="dashboard">
      <div className="container">
        <div className="row mx-0 worklist_dashboard">
          <DashboardAsVendor/>
          <div className="col-md-12 col-lg-9">
            <div className="vendors_right_form">
              <div className='row mx-0'>
                <div className='col-md-1 col-sm-2'>
                  <div style={{ width: '100px' }}>
                    <CircularProgressbar  value={percentage} strokeWidth={6}
                      styles={buildStyles({
                        strokeLinecap: 'butt', 
                        pathColor: `rgba(86,209,98, 1, ${percentage / 100})`,
                        textColor: '#24A831',
                        trailColor: '#d6d6d6',
                      })}
                    />
                    <img src={profileData.profile_pic?profileData.profile_pic:'https://doqwik.ng/images/avatar-default-icon.png'} alt="Your Image"
                      style={{width: '63px',height: '65px',position: 'absolute',transform: 'translate(-119%, 16%)',}} 
                      className='pfile_img'/>
                    <div className='circular_value'>
                      <p  className='percentage_value' > {`${percentage}%`} </p>
                    </div>
                  </div>
                </div>
                <div className='col-md-8 col-sm-10'>
                    <div className='workdetails_list'>
                      <div className='work_list_details'>
                        <h1>{profileData.fullName} </h1>
                        <span>
                          {profileData.averageRating && (<>
                            <FontAwesomeIcon icon={faStar} className="star_yellow" /><p>{profileData.averageRating?profileData.averageRating:''}</p>
                          </>)}
                          </span>
                      </div>
                      <p>{profileData.address2}-{profileData.postal_code}, {profileData.city},{profileData.country}</p>
                      <div className='status'  >
                        <p>Status:</p>
                        <div className={`${isProcessing?'blur-effect':''}`}>
                          <button onClick={()=>{handleOnlineStatus('Online')}} className={`online ${profileData?.online_status === 'Online' ? 'active' : ''}`}>Online</button>
                          <button onClick={() => { handleOnlineStatus('Offline') }} className={`offline ${profileData?.online_status !== 'Online' ? 'active67' : ''}`}>Offline</button>

                        </div>
                      </div>
                    </div>
                </div>
                <div className='col-md-3 col-sm-4'>
                <div class="button-container listing_container mt-0 mb-4" style={{width:"166px",marginLeft:"10px",backgroundColor:"#3A76BB !important"}} onClick={()=>{handleEdit(profileData)}}>
            <button class="button button1">
            Edit Details
            </button>
            <button class="button button2">
                 Edit Details</button>
          </div>
                </div>
             </div>
              <div className='row mx-0'>
               
                <div className='col-md-12'>
                <div className='work_list_category'>
                <h2>Identification Type :</h2>
                 <div className='identification_types'>
                    <button className='driving_icenece'>{profileData.identificationType}</button>
                    <input type="text" placeholder={profileData.identificationNumber} className='identiy_card blur-effect' />
                 </div>
                 
                </div>
                </div>
                <div className='col-md-12'>
                    {/* <div className='work_list_category'>
                        <h2>Service ( Work Category )</h2>
                        <div className='work_Category'>
                            <ul>

                            {serviceData && serviceData.map((item,index)=>(
                                <li key={index}>
                                    <div className='services_worklist'>
                                    <img src={item.icon} alt="profile-image"/>
                                        <p>{item.service_name}</p>
                                    </div>
                                </li>
                            ))}
                    
                           
                            </ul>
                        </div>
                    </div> */}

                    {/* <div className='work_list_category'>
                        <h2>Service ( Work Gallery )</h2>
                    <ul>
                        <li>
                            <div className='service_category_list'>
                                <img src={process.env.PUBLIC_URL + "/vendor_dashboard/worklist/left_side.png" } />
                                <img src={process.env.PUBLIC_URL + "/vendor_dashboard/worklist/right_side.png" }  />
                            </div>
                        </li>
                        <li>
                            <div className='service_category_list'>
                                <img src={process.env.PUBLIC_URL + "/vendor_dashboard/worklist/left_side.png" } />
                                <img src={process.env.PUBLIC_URL + "/vendor_dashboard/worklist/right_side.png" }  />
                            </div>
                        </li>
                        <li>
                            <div className='service_category_list'>
                                <img src={process.env.PUBLIC_URL + "/vendor_dashboard/worklist/left_side.png" } />
                                <img src={process.env.PUBLIC_URL + "/vendor_dashboard/worklist/right_side.png" }  />
                            </div>
                        </li>
                        <li>
                            <div className='service_category_list'>
                                <img src={process.env.PUBLIC_URL + "/vendor_dashboard/worklist/left_side.png" } />
                                <img src={process.env.PUBLIC_URL + "/vendor_dashboard/worklist/right_side.png" }  />
                            </div>
                        </li>
                    </ul> 
                    </div>
                    <div className='work_list_category'>
                        <h2 className='mt-3'>Client Reviews</h2>
                        <div className='row mx-0'>
                            <div className='col-md-6 p-0'>
                             <div className='cardes_box'>
                                <div className='card_header'>
                                    <div className=''>
                                       <img src={process.env.PUBLIC_URL + "/vendor_dashboard/worklist/user_review_box.png"} />
                                    </div> 
                                    <div className=''>
                                        <h2>Alexa Coop</h2>
                                        <span> 
                                            <ul>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow" /></li>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow"  /></li>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow" /></li>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow"/></li>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow" /></li>
                     </ul> <p>4.0</p></span>
                                    </div>
                                </div>
                                <p>It is a long established fact that reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem is that it has a more-or-less normal distribution of letters, as opposed.</p>
                             </div>
                            </div>
                            <div className='col-md-6 p-0 '>
                            <div className='cardes_box mx-0'>
                                <div className='card_header'>
                                    <div className=''>
                                       <img src={process.env.PUBLIC_URL + "/vendor_dashboard/worklist/user_review_box.png"} />
                                    </div> 
                                    <div className=''>
                                        <h2>Alexa Coop</h2>
                                        <span> 
                                            <ul>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow" /></li>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow"  /></li>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow" /></li>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow"/></li>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow" /></li>
                     </ul> <p>4.0</p></span>
                                    </div>
                                </div>
                                <p>It is a long established fact that reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem is that it has a more-or-less normal distribution of letters, as opposed.</p>
                             </div>
                            </div>
                            <div className='col-md-6 p-0'>
                            <div className='cardes_box'>
                                <div className='card_header'>
                                    <div className=''>
                                       <img src={process.env.PUBLIC_URL + "/vendor_dashboard/worklist/user_review_box.png"} />
                                    </div> 
                                    <div className=''>
                                        <h2>Alexa Coop</h2>
                                        <span> 
                                            <ul>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow" /></li>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow"  /></li>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow" /></li>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow"/></li>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow" /></li>
                     </ul> <p>4.0</p></span>
                                    </div>
                                </div>
                                <p>It is a long established fact that reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem is that it has a more-or-less normal distribution of letters, as opposed.</p>
                             </div>
                            </div>
                            <div className='col-md-6 p-0 '>
                            <div className='cardes_box mx-0'>
                                <div className='card_header'>
                                    <div className=''>
                                       <img src={process.env.PUBLIC_URL + "/vendor_dashboard/worklist/user_review_box.png"} />
                                    </div> 
                                    <div className=''>
                                        <h2>Alexa Coop</h2>
                                        <span> 
                                            <ul>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow" /></li>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow"  /></li>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow" /></li>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow"/></li>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow" /></li>
                     </ul> <p>4.0</p></span>
                                    </div>
                                </div>
                                <p>It is a long established fact that reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem is that it has a more-or-less normal distribution of letters, as opposed.</p>
                             </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                </div>   

                <div className="row mx-0">
<p className="fill_form"><span> Specialisation </span> <br /> Select Worker’s Services.</p>
<ul className="all_services">

{data?.map((item, index) => (
                              <li
                                key={item._id}
                                onClick={() =>
                                  handleServiceSelection(item)
                                }
                                className={selectedId === item?._id ? 'colored' : ''}
                              >
                                <img src={item.subServices.sub_service_icon} />
                                <p>{item.subServices.sub_service_name}</p>
                              </li>
                            ))}
</ul>
            </div>
            {
                selectedId?           <div className=" mx-3 button-container listing_container mt-0 mb-4" style={{width:"166px",marginLeft:"47px",backgroundColor:"#3A76BB !important"}} onClick={addServiceToWorker}>
            <button className="button button1">
            Next
            </button>
            <button className="button button2">
                 Next</button>
          </div>:
          <div className=" mx-3 button-container listing_container mt-0 mb-4" style={{width:"166px",marginLeft:"47px",backgroundColor:"#3A76BB !important"}} onClick={getCompanyServiceList}>
          <button className="button button1">
          Add Services
          </button>
          <button className="button button2">
               Add Services</button>
        </div>
            }
       
             </div>
            </div>
        </div>
    </div>
   </div>
    <Footer />



    <Modal
        show={show}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created  well_done"
            style={{ marginTop: "0px" }}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/greeting.gif"}
              alt="playstore"
              className="gif"
            />

            <h2>Service Added!</h2>
            {/* <p>Please Continue Your Work</p> </>:<h2>Job Rejected!</h2>  */}

            <div
              class="button-container"
              style={{ width: "150px" }}
              onClick={handleClose}
            >
              <button className="button button1"> Proceed To Next</button>
              <button className="button button2">Proceed To Next</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
     </>
    );
}; 

export default Vendorworkdetails;
