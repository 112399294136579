import Header from "../../components/header";
import Footer from "../../components/footer";
import { faStar } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import DashboardAsVendor from "../../components/dashboardAsVendor";
import { BaseUrl, apiBaseUrl } from "../../config/constant";
import { getSession } from "../../utils/helper";
import axios from "axios";

const VendorFeedback = () => {
  const token = getSession("token");

  const [show, setShow] = useState(false);
  const [value, setValue] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [review, setreview] = useState();
  const [rating, setRating] = useState(0);
  const [showRate, setShowRate] = useState(false);
  const [errorsForRating, setErrorsForRating] = useState("");
  const [FeedbackData, setFeedbackData] = useState([]);
  const [show3, setShow3] = useState(false);
  const handleShow3 = () => setShow3(true);
  const handleClose3 = () => setShow3(false);
  const maxRating = 5;
  
  const handleClose4 = () => {
    setShowRate(false);
  };
  const handleStarClick = (clickedRating) => {
    setRating(clickedRating);
    setFormDataforRate((prevData) => ({
      ...prevData,
      star: clickedRating,
    }));
  };
  const [formData, setFormData] = useState({
    message: "",
    star: "",
  });
  const [formDataforRate, setFormDataforRate] = useState({
    jobId: "",
    message: "",
    star: rating,
  });

  const FeedbackForm = async () => {
    if (formDataforRate.message) {
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };

      try {
        const response = await axios.post(
          apiBaseUrl + "order/reply-feedback",
          formDataforRate,
          { headers }
        );
        console.log("Response3:", response.data);
        if (response.data.status === true) {
          handleClose4();
          handleShow3();
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else setErrorsForRating("Please Filled Your Feedback");
  };

  const handleTextareaChange = (e) => {
    setErrorsForRating("");
    const messageValue = e.target.value;
    setFormDataforRate((prevData) => ({
      ...prevData,
      message: messageValue,
    }));
  };

  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const response = await axios.get(apiBaseUrl + "order/get-feedback", {
          headers: {
            Authorization: token,
            "content-type": "application/json",
          },
        });
        setFeedbackData(response.data.response.result);
        console.log(response.data); // Assuming the response contains the data you need
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData2();
  }, []);
  //   ////////////////////feedback time///////////////////

  const getAgoTime = (timestamp) => {
    const now = new Date().getTime() / 1000; // Convert current time to seconds
    console.log("now:", now);

    const timeDifference = now - timestamp / 1000; // Convert the timestamp to seconds
    console.log("timestamp:", timestamp);
    console.log("timeDifference:", timeDifference);

    const seconds = Math.floor(timeDifference);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} days ago`;
    } else if (hours > 0) {
      return `${hours} hours ago`;
    } else if (minutes > 0) {
      return `${minutes} minutes ago`;
    } else {
      return `${seconds} seconds ago`;
    }
  };
  const handleShowRate = (data) => {
    setFormDataforRate((prevData)=>({
      ...prevData,
      jobId:data._id
    }))
    setShowRate(true);
  };
  ////////////////////////end ////////////////////////////
  return (
    <>
      <Header />
      <div className="dashboard">
        <div className="container">
          <div className="row mx-0 mb_view_center">
            <DashboardAsVendor />
            <div className="col-md-9">
              <div className="vendors_right_formses">
                <div className="row mx-0">
                  <div className="col-ms-12">
                    <h1 className="my_order mt-2">Review </h1>
                    <h2 className="order_details">Feedbacks</h2>
                  </div>
                </div>
                {FeedbackData?.map((item, index) => (
                  <div className="right_side_boxex mt-3">
                    <div className=" mx-0">
                      <div className="d-flex">
                        <div className="row ">
                          <span className="user_name">
                            {" "}
                            <img
                              src={BaseUrl + item.user_id.profile_pic}
                            />{" "}
                            <p>{item.user_id.fullName}</p>
                          </span>
                        </div>
                        <div className="col-md-9 ">
                          <div className="right">
                            <p>{getAgoTime(item.userFeedback.feedbackAt)}</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        {Array.from({ length: maxRating }).map((_, index) => (
                          <FontAwesomeIcon
                            key={index}
                            icon={faStar}
                            style={{
                              color:
                                index < item.userFeedback?.star
                                  ? "#FFCC00"
                                  : "grey",
                            }}
                          />
                        ))}
                        <p className="feedbacks_text">
                          {" "}
                          {item.userFeedback.message}
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="stares_boxes">
                          <span className="vendor_stares">
                            {/* <div className="vendor_star">
                              {Array.from({ length: maxRating }).map(
                                (_, index) => (
                                  <FontAwesomeIcon
                                    key={index}
                                    icon={faStar}
                                    style={{
                                      color:
                                        index < item.userFeedback?.reply_star
                                          ? "#FFCC00"
                                          : "grey",
                                    }}
                                  />
                                )
                              )}
                            </div> */}
                            {/* <p>4.0</p> */}
                          </span>
                          <p>{item.userFeedback.reply}</p>
                        </div>
                      </div>
                      <div className="row mt-4 pt-4">
                        <div className="col-md-2">
                          <div className="button-container mt-0">
                            <button className="button button1">
                              View Detail
                            </button>
                            <button className="button button2">
                              View Detail
                            </button>
                          </div>
                        </div>
                        <div className="col-md-4">
                        {item.userFeedback.reply? "" :
                        <div
                            className="button-container-feedback mt-0"
                            onClick={()=>{handleShowRate(item)}}
                          >
                            <button className="button button1">
                              Reply To Feedback
                            </button>
                            <button className="button button2">
                              Reply To Feedback
                            </button>
                          </div>
                        }
                          
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* <div className="right_side_boxex mt-3 mb-4">
                <div className="row mx-0">
                    <div className="col-md-2 ">
                        <span className="user_name">  <img src={process.env.PUBLIC_URL + "/vendor_dashboard/alexa_user.png"} /> <p>Alexa Coop</p></span>
                    </div>
                    <div className="col-md-10 ">
                        <div className="right">
                        <p>3 days ago</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                    <div className="vendor_star">
                                <FontAwesomeIcon icon={faStar} className="star_yellow" />
                            <FontAwesomeIcon icon={faStar} className="star_yellow" />
                            <FontAwesomeIcon icon={faStar} className="star_yellow" />
                            <FontAwesomeIcon icon={faStar} className="star_yellow" />
                            <FontAwesomeIcon icon={faStar} className="gray" />
                                </div>
                        <p className="feedbacks_text">It is a long established fact that reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem  is that it has a more-or-less normal distribution of letters, as opposed.</p>
                    </div>
                    <div className="row">
                    <div className="col-md-2">
                    <div className="button-container mt-0" >
                        <button className="button button1">View Detail</button><button className="button button2">View Detail</button></div>
                    </div>
                    <div className="col-md-4">
                    <div className="button-container-feedback mt-0" onClick={handleShowRate}>
                        <button className="button button1">Reply To Feedback</button><button className="button button2">Reply To Feedback</button></div>
                    </div>
                    <div className="col-md-4">
                   
                    </div>
                    </div>
                    
                </div>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Modal
        show={showRate}
        onHide={handleClose4}
        id="model_rating"
        className=" slideInUp"
      >
        <div className="model_header">
          <div className="header_viewes">
            <h1>Your Valuable Feedback</h1>
          </div>
          <div className="">
            <img
              src={process.env.PUBLIC_URL + "../images/model_cross.png"}
              alt="app"
              className="cross_icon"
              onClick={()=>{setShowRate(false)}}
            />
          </div>
        </div>

        <Modal.Body className="py-0">
          <Form>
            <div className="row mx-0">
              <div className="col-md-12">
                <div className="text_rating">
                  <p>Rate Us</p>
                  {/* <div className="multiple_stars_content">
                    <ul>
                      {[1, 2, 3, 4, 5].map((index) => (
                        <li key={index} onClick={() => handleStarClick(index)}>
                          <FontAwesomeIcon
                            icon={faStar}
                            className={index <= rating ? "star_yellow" : "star"}
                          />
                        </li>
                      ))}
                    </ul>
                  </div> */}
                  <textarea
                    name="message"
                    placeholder="Feedback message"
                    value={formDataforRate.message}
                    onChange={handleTextareaChange}
                  />

                  {errorsForRating ? (
                    <p className="error" id="error_feedback">
                      {errorsForRating}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="model_submit" onClick={FeedbackForm}>
            <button className=" button submit_close">Submit</button>
            <button className="button submit_close_hover">Submit</button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
        size="sm" // Add this line to set the size to small (sm)
        centered
      >
        <div
          className="cross-X"
          onClick={() => {
            handleClose3();
            window.location.reload();
          }}
          style={{ position: "absolute", right: "0" }}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/user/cross.png"}
            alt="playstore"
          />
          <img
            src={process.env.PUBLIC_URL + "/images/user/X.png"}
            alt="playstore"
          />
        </div>
        <Modal.Body>Thanks For Your Valuable Feedback </Modal.Body>
        <Modal.Footer>
          <div className="proceed_next mt-4 mb-4">
            <div class="button-container proccess_next mt-4 mb-4 mr-0">
              <button
                class="button button1"
                onClick={() => {
                  window.location.reload();
                }}
              >
                {" "}
                Proceed To Next
              </button>
              <button
                class="button button2"
                onClick={() => {
                  window.location.reload();
                }}
              >
                {" "}
                Proceed To Next
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VendorFeedback;
