import Header from "../../components/header";
import Footer from "../../components/footer";
import "./providerlist.css";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import React,{ useEffect, useState } from "react";
import { getSession ,setSession } from "../../utils/helper"; 
import Modal from "react-bootstrap/Modal";
import { usePaystackPayment } from 'react-paystack';
import { BaseUrl, apiBaseUrl } from "../../config/constant";
import { Col, Row } from "react-bootstrap";


const Providercheckout = () =>{
    const location = useLocation();
    const data = location.state;
const token = getSession("token");
const item = data?.data?.response?.result
// const [item,setItem] = useState('')
// console.log(item,"LL")
const [serviceLocation , setServiceLocation] = useState(data.data.response.result.service_location   )
const providerData=data.data.response.result.providerData;

let [promocode, setPromoCode] = useState(null);
let [validationMsg, setValidationMsg] = useState("");

let [paid_amount, setPaidAmount] = useState("0");
let [discount_amount, setDiscountAmount] = useState("0");


let [validationClass, setValidationClass] = useState("hide");
const [couponData, setCouponData] = useState([]);

const [data2,setData2]=useState([])
const [selectedItemId, setSelectedItemId] = useState(null);

const [show,setShow]=useState(false);
const handleShow=()=>{setShow(true)};
const [show2 , setShow2] = useState(false);
const [show3, setShow3] = useState(false);
const handleClose3 = () => setShow3(false);
const handleShow3 = () => setShow3(true);
const handleClose2 = () => setShow2(false);
const handleShow2 = () => setShow2(true);
const [address,setAddress]=useState([])
const  handleClose=()=>{setShow(false);
  window.location.href = '/user_dashboard/order_history';
};
// console.log(serviceLocation)
// console.log(address);
const fetchData = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl+'order/order-summary',
        {
            slot_id: data.slotid,
            worker_id: data.worker_id,
            coupon_code: '20% discount',
          },
        {
          headers: {
            Authorization:token, 
            'Content-Type': 'application/json',
          },
        }
      );
  // setItem(response?.data?.response?.result);
      // Handle the response data here
    } catch (error) {
      console.error(error);
      // Handle the error here
    }
  };
  
  useEffect(() => {
    fetchData() 
    // setItem(data?.data?.response?.result)
  },[])
  
  


  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = apiBaseUrl+"users/address-list";
        const response = await axios.get(url, {
          headers: {
            Authorization: token,
          },
        });
  
        // Do something with the response data
        setData2(response.data.response.result);
   
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const [paymentMode, setPaymentMode] = useState('PostPaid');

  const handlePaymentChange = (event) => {
    setPaymentMode(event.target.value);
    // Add handleClose2() and handleShowPay() calls here if needed.
    // Otherwise, you can remove the onClick from the input below.
  };

  const fetchCheckOutData = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl+'order/proceed-to-pay',
        {
          jobID : item._id,
          paymentType:paymentMode,
          locationID : item.serviceLocation, 
          ...(promocode?{couponCode:promocode.toUpperCase()}:null)
          
      },
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json'
          }
          
        }
  
      );
return response.data;
    } catch (error) {
      console.error(error);
      if(error?.response?.data?.statusCode === "couponcodeerror"){
        setValidationMsg("Wrong Coupon Code.");
        setValidationClass('error');
      }

    }
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // await validationSlotSchema.validate(formData, { abortEarly: false });
  
      fetchCheckOutData()
        .then((data) => {
          console.log(data,"chdata")
          if (data.status === true) {
            handleClose2();
            handleShow();
          }
        })
        .catch((error) => {
          console.error(error);
        });
  
      // Validation succeeded, proceed with form submission
    } 
    catch (error) {
console.log(error)
    }
  };

// ////////////////////////////////////////////////////////////////////////////// payment Gateway /////////////////////////////////////////////////////////////
const publicKey = 'pk_test_bf755297dbb8d47a792a4eb9e153eab8fe4a1613'; // Replace with your actual Paystack public key

const [amount, setAmount] = useState('');
const [email, setEmail] = useState('');
const [showPay, setShowPay] = useState(false);
const handleClosePay = () => setShowPay(false);
const handleShowPay = () => setShowPay(true);

const onSuccess = (reference) => {
  console.log('Payment successful. Reference: ', reference);
  // Add your logic for handling successful payments here
  alert('Payment successful! Reference: ' + reference);
};

const onClose = () => {
  console.log('Payment closed');
  // Add your logic for handling closed payments here
};

const onError = (error) => {
  console.error('Payment error: ', error);
  // Add your logic for handling payment errors here
};

const config = {
  reference: new Date().getTime().toString(), // Generate unique references for each transaction
  email,
  amount: amount * 100, // Convert amount to kobo (lowest currency unit in Nigeria)
  publicKey,
  test: true,
  onSuccess,
  onClose,
  onError,
};

const initializePayment = usePaystackPayment(config);

const handlePayment = () => {
  // Perform any additional validations here if needed
  // For simplicity, we'll just check if the email and amount are valid
  const isValidAmount = !isNaN(amount) && amount > 0;
  const isValidEmail = email.trim() !== '';

  if (isValidAmount && isValidEmail) {
    // Proceed with the payment
    initializePayment();
  } else {
    // Show an error message or perform any validation feedback
    alert('Please enter a valid amount and email.');
  }
};

////////////////////////////////////////////////////////////////////////////////////////ENd Payment Gateway //////////////////////////////////////////////////


const handleAdd = () => {
  if (userData?.addressData && userData?.addressData?.length > 0) {
    handleShow2();
    handleClose();
  } else handleShow3();
  handleClose();
};

const handleChangeCoupon=(e)=>{
  console.log(e.target.value);
  setPromoCode(e.target.value)
  setValidationMsg('')
}

const handleApplyCoupon = async () => {
  // console.log(item._id);
  try {
    const response = await axios.post(
      apiBaseUrl+'common/validate-coupon',
      {
        jobId : item._id,
        ...(promocode?{promocode:promocode.toUpperCase()}:null)
    },
      {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        } 
      }
    );
    // console.log(response);
    setCouponData(response?.data?.response?.result);
    console.log(response?.data?.response?.result);
    if(response?.data?.response?.result?.status === 'success'){
      setValidationMsg("Code Applied!");
      setValidationClass('success');
      setDiscountAmount(response?.data?.response?.result.discountAmount)
    }else{
      setValidationMsg(response?.data?.response?.result?.message);
      setValidationClass('error');
    }
    // if()
  } catch (error) {
    // console.log(error.response.data.statusMessage);
    setValidationMsg(error?.response?.data?.statusMessage);
    setValidationClass("error");
    console.error(error);
  }
}



React.useEffect(() => {
  if (data.length > 0 && selectedItemId === null) {
    setSelectedItemId(data[0]._id);
  }
}, []);

const handleRadioClick = (item) => {
  setSelectedItemId(item?._id);
  setAddress(item);
handleClose3();

};

    return(
        <>
        <Header/>
        <div className="provider_checkout">
        <div className="container">
      <Row mx={0} className="mb_view_center">
        <Col xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }}>
        <div className="card_details">
                        <div className="card_heading">
                            <h1>Service Location</h1>
                        </div>
                        <div className="card_content">
                          <div className="row mx-0">
                            <div className="col-md-8">
                            <span className="mt-2"><img src={process.env.PUBLIC_URL + "../images/card_location.png"} alt="app"  /> 
                            
 <p>{serviceLocation.land_mark},{serviceLocation.address1} , {serviceLocation.city} ,{serviceLocation.state} ,{serviceLocation.postal_code} ,{serviceLocation.country} </p>               </span>
                            </div>
                            <div className="col-md-4">
                            <div class="button-container" onClick={handleShow3} id="chang_address_checkout_btn" >
                                <button class="button button1">Change Address</button>
                                <button class="button button2">Change Address</button>
                                </div>       
                            </div>
                          </div>
                          
                                
                               
                          
                        </div>
                    </div>
                    <div className="card_details">
                        <div className="card_heading">
                            <h1>Selected Provider</h1>
                        </div>
                        <div className="card_content">
                            <div className="row">
                                <div className="col-md-8 col-sm-12 col-xs-12 ">
                                <div className="provider_checkout_content"><img src={BaseUrl+providerData.profile_pic} alt="app"  className="worker_img"/> 
                                <div className="select_provider_content">
                                <h1>{item?item.worker_name:""}</h1>  
                                <p className="mx-0">{providerData.land_mark},{providerData.address1} , {providerData.city} ,{providerData.state} ,{providerData.postal_code} ,{providerData.country} </p>
                               
                                </div>
                                </div>
                               
                                </div>
                             
                                <div className="col-md-4 ">
                                <span className="checkout_content"><img src={process.env.PUBLIC_URL + "../images/Star.png"} className="star_img"/><p>{providerData.averageRating?providerData.averageRating:"0.0"}</p></span>
                                </div>
                            </div>
                      
                        </div>
                    </div>
                    <div className="card_details">
                        <div className="card_heading">
                            <h1>Slot Date & Timing</h1>
                        </div>
                        <div className="card_content">
                          <ul>
                            <li><span>Service Date</span> <p>{item.service_date}</p></li>
                            <li><span>Service Timing</span> <p>{item.service_time}</p></li>
                          </ul>
                        </div>
                    </div>
                    <div className="card_details mb-2">
                        <div className="card_heading">
                            <h1>Service</h1>
                        </div>
                        <div className="card_content">
                            <h2>{item?.service_name},{item?.sub_service_name}  </h2>
                            <p className="my-2">{item?.job_desc}</p>
                           { item?.jobImage?     <div className="plumbering_img">
                          <img
                            src={BaseUrl+item?.jobImage  }
                            alt="service_image"
                          />
                       
                        </div> :"" }
                        
                        </div>
                    </div>
        </Col>
   


                 
        <Col xs={{ span: 12, order: 1 }} md={{ span: 4, order: 2 }}>

                    <div className="payment_details mt-4">
                    <h1>Apply Coupon</h1>
                   
                    <div className="apply_coupen">
                      <input type="text" name="promocode" value={promocode} onChange={handleChangeCoupon} id="promocode" placeholder="Apply Coupon If Any" />
                      <button className="apply_btn" onClick={ handleApplyCoupon } >Apply</button> 
                      
                    </div>
                    <div  className="coupon-Error" >

             
                    {validationMsg==='Code Applied!' ?<p style={{color:"green"}}>{validationMsg}</p>:  <p className={validationClass}>{validationMsg}</p>}
                    </div>
                     <h1 className="mt-4 pt-4">Payment Detail</h1>
                     <ul className="mt-2"> 
                        <li className="pt-0"><p>Total Amount</p> <span>₦ {item.amount}</span></li>
                        <li><p>Discount</p> <span className="text_change_color">₦ {discount_amount?discount_amount:0}</span></li>
                        <li><p>Other Charges</p> <span>Free</span></li>
                        <li className="pb-0 bottom_none"><p className="text_change_color">Amount Paid</p> <span className="text_change_color">₦ {item.amount?item.amount - discount_amount:0}</span></li>
                     </ul>
                     <div class="button-container" id='proceed_to_btn'>
                      <button class="button button1">Proceed To Next</button>
                      <button class="button button2" onClick={ handleSubmit}>Proceed To Next</button>
                    </div>
                    </div>
                    
             
  </Col>
            
          
      </Row>
          
                
           
             </div>     
             </div>     
        <Footer></Footer>

        <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>   Select Payment Mode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="payment-container">
      <label className="payment-label" htmlFor="cashPayment">
        <input
          type="radio"
          id="cashPayment"
          name="paymentMode"
          value="PostPaid"
          checked={paymentMode === 'PostPaid'}
          onChange={handlePaymentChange}
        />
        Cash Payment
      </label>
      <br />
      <label className="payment-label" htmlFor="onlinePayment">
        <input
          type="radio"
          id="onlinePayment"
          name="paymentMode"
          value="Prepaid"
          checked={paymentMode === 'Prepaid'}
          onChange={handlePaymentChange}
        />
        Online Payment
      </label>
    </div>
        </Modal.Body>
     <Modal.Footer>
     <div class="button-container" >
  <button class="button button1">Proceed To Pay</button>
  <button class="button button2" onClick={ handleSubmit}>Proceed To Pay</button>
</div>
     </Modal.Footer>
      </Modal>



<Modal show={show}
         onHide={handleClose}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose}>
            <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
          

            <div className="congrats-profile-created  well_done" style={{marginTop:"0px"}}>
            <img
                src={process.env.PUBLIC_URL + "../images/greeting.gif"}
                alt="playstore" className="gif"
              />
        
          <h2>Order has been Placed !</h2> 
         {/* <p>Please Continue Your Work</p> </>:<h2>Job Rejected!</h2>  */}
       
         
              
              <div class="button-container" style={{ width: "150px" }} onClick={handleClose}>
                <button className="button button1" >  Proceed To Next
               </button>
                <button className="button button2">
      Proceed To Next
             
                </button>
              </div>
          
            </div>
          </Modal.Body>
        </Modal> 

{/* paymentGateway */}

<Modal show={showPay} onHide={handleClosePay}>
        <Modal.Header closeButton>
 
        </Modal.Header>
        <Modal.Body> 
        <div className="payment-Gateway">
      <h2>Payment Form</h2>
      <input
        type="number"
        placeholder="Enter the amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button type="button" onClick={handlePayment}>
        Pay Now
      </button>
    </div>
        </Modal.Body>
  
      </Modal>


      <Modal show={show3} onHide={handleClose3} >
  
  <Modal.Body>
  <div className="" onClick={()=>{handleClose3()}} style={{position:"absolute",right:"0",top:"-10px"}}>
      <img
        src={process.env.PUBLIC_URL + "../images/cross.png"}
        alt="app"
        className="cross_icon"
        
      />
    </div>
  <h6>Select your location</h6>

    {" "}
    <div className="row mx-0 mt-3 mb-2 modal_size">
      {data2.map((item, index) => (
        <div className="col-6 mb-3" key={index}>
          <div className="address_details">
            <div className="address_card">
              <input
                type="radio"
                onClick={() => handleRadioClick(item)}
                checked={selectedItemId === item._id}
              />
              <span>
                <button className="addres_num">
                  Address {index + 1}
                </button>
              </span>
            </div>
            <p>
              {item.land_mark}, {item.address1}, {item.city}, {item.state}
              , {item.postal_code}, {item.country}
            </p>
          </div>
        </div>
      ))}
    </div>
  </Modal.Body>
  <Modal.Footer></Modal.Footer>
</Modal>
{/* end Payment gateway */}


        </>
    );
};

export default Providercheckout;
