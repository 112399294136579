import React from "react";
import Slider from "react-slick";
import { LazyLoadImage } from "react-lazy-load-image-component";
const customDotsClass = 'custom-dots-class';

const Testimonial = (props) => {
  const {Testimonial}=props;
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    dotsClass: 'slick-dots  customDotsClass',
    slidesToShow: 2,
    autoplay:true,
    // slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 2,
          // slidesToScroll: 2,
          // initialSlide: 0,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          // slidesToScroll: 2,
          // initialSlide: 2
          dots: false,
          arrows:false,
          marging:20,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          // slidesToScroll: 2,
          // initialSlide: 2
          dots: false,
          arrows:false,
          marging:20,
        }
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 1,
          // slidesToScroll: 2,
          // initialSlide: 2
          dots: true,
          arrows:false,
          marging:20,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  };
  
  return (
    <div>
      <Slider {...settings}>
      {Testimonial.map((item,indexedDB) =>{
        return(
          <div>
          <div className="t-main-content">
            <h6>
             {item.description}
              </h6>
            <ul className="d-flex">
              <li>
              <LazyLoadImage src={item.image} alt="Background Image" />
              </li>
              <li className="d-flex" id="testimonial-name">
                <span>
                <LazyLoadImage src={process.env.PUBLIC_URL + '/images/string1.png'} alt="Background Image" />
                </span>
   {item.name}
                <span>
                <LazyLoadImage src={process.env.PUBLIC_URL + '/images/string.png'} alt="Background Image" />
                </span>
              </li>
            </ul>
          </div>
        </div>
        )
    
      })}

      
      </Slider>
    </div>
  );
};

export default Testimonial;
