import Header from "../../components/header";
import Footer from "../../components/footer";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { setSession, getSession } from "../../utils/helper";
import { apiBaseUrl } from "../../config/constant";
import { requestNewSubservice } from "../../components/common";

const Individualservice_Selection = () => {
  const [service, setService] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [error, setError] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedService, setSelectedService] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const [isColored, setIsColored] = useState(false);
  const [subservices, setSubservices] = useState([]);
  // const[subcolored,setsubcolored] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [response, setResponse] = useState(null);
  const [selectedServices, setSelectedServices] = useState("");
  const [selectedSubServiceId, setSelectedSubServiceId] = useState("");
  const [showprocced, setProceed] = useState(false);
  const [subservice, setsubservice] = useState(false);
  const [showappointment, setappointment] = useState(true);
  const [priceproceed, setpriceproced] = useState(false);
  const [editprocess, seteditprocess] = useState(false);
  const [priceError, setPriceError] = useState("");
  const [serviceError, setServiceError] = useState("");
  const [moreServiceError, setMoreServiceError] = useState("");
  const [sucesspop, setSuccessPop] = useState(true);
  const [subServiceError, setSubServiceError] = useState("");
  const [show, setShow] = useState(false);
  const [showRequestSuccess, setShowRequestSuccess] = useState(false);
  const [addNewPopup, setAddNewPopup] = useState(false);
  const [procession, setProcessing] =useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  // const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setAddNewPopup(false);
    setShowRequestSuccess(false);
    setFormData((peeData) => ({
      ...peeData,
      subService_name : ""
    }))
    setProcessing(false);
  }

  const [formData, setFormData] = useState({
    services: "",
    sub_services: "",
  });

  const navigate = useNavigate();

  const [isCat, setIsCat] = useState("N");

  const handleSubServiceSelection = (subServiceId) => {
    setSelectedSubServiceId((Selected) =>
      Selected === subServiceId ? "" : subServiceId
    );
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    if (name === "subService_name") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        subService_name: value,
      }));
      setError((preError) => ({
        ...preError,
        subService_name : ""
      }));
    }else {
      const numericValue = value.replace(/\D/g, "");
      setInputValue(numericValue);
      if (value !== numericValue) {
        setError("Only numbers are allowed");
      } else {
        setError("");
      }
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setError("");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          apiBaseUrl + "common/get-services-details-data",
          {
            headers: {
              key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
              "Content-Type": "application/json",
            },
          }
        );

        const category = service.find((item) => item._id === selectedId);
        setSelectedCategory(category);
        setService(response.data.response.result);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (selectedId) {
      const selectedCategory = service.find((item) => item._id === selectedId);
      if (selectedCategory && selectedCategory.sub_services) {
        setSubservices(selectedCategory.sub_services);
      }
    }
  }, [selectedId, service]);

  const selectOptions = (itemId, itemName, cat) => {
    const optService = sessionStorage.getItem("individual_service");
    if (optService && itemId === optService) {
      setSelectedServices((prevSelected) => ({
        ...prevSelected,
        [itemId]: !prevSelected[itemId],
      }));
      setIsCat(cat);
      setIsColored((prevIsColored) => !prevIsColored);
      setSelectedId(itemId);
      setSelectedName(itemName);
      setSelectedId(itemId);
      setMoreServiceError("");
      setServiceError("");
    } else {
      setSelectedServices((prevSelected) => ({
        ...prevSelected,
        [itemId]: "",
      }));
      setIsCat("");
      setIsColored("");
      setSelectedId("");
      setSelectedName("");
      setSelectedId("");
      setServiceError("");
      setMoreServiceError(
        "You have already selected one service to add more services please contact admin@doqwik.ng"
      );
    }
  };

  const showcontent = () => {
    setappointment(true);
    setProceed(false);
    setsubservice(false);
    setpriceproced(false);
    seteditprocess(false);
  };
  const nextContent = async (event) => {
    if (!showprocced) {
      if (!selectedId) {
        setServiceError("Please select a service.");
        return;
      } else {
        setappointment(false);
        setProceed(true);
        setsubservice(true);
        setpriceproced(false);
        setSubServiceError(false);
      }
    }

    if (setProceed === "" && setProceed(false)) {
      // Here, showprocced is true
      setError(false);
      setsubservice(true);
    } else if (!subservice) {
      setsubservice(true);
      setpriceproced(false);
    } else if (!priceproceed) {
      if (!selectedSubServiceId) {
        setSubServiceError("Please select a sub-service.");
        return;
      } else {
        setSubServiceError("");
      }
      setpriceproced(true);
    } else if (!editprocess) {
      if (inputValue.trim() === "") {
        setPriceError("Please enter a value.");
        return;
      } else {
        setPriceError("");
      }
      seteditprocess(true);
      try {
        const data = {
          services: selectedId,
          price: inputValue,
          sub_services: selectedSubServiceId,
          category: selectedOption,
        };
        // console.log(data);
        let token = getSession("token");
        const headers = {
          Authorization: token,
          "Content-Type": "application/json",
        };
        const apiUrl = apiBaseUrl + "provider/add-services-by-individual";

        const response = await axios.post(apiUrl, data, { headers });

        setResponse(response.data);
        if (response.data.responseCode === 200) {
          setShow(true);
          setSuccessPop(true);
        } else {
          // Handle other responses if needed
          console.error("Response code:", response.data.code);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      event.preventDefault();
    } else if (!priceproceed) {
      setpriceproced(true);
    }
  };

  const sendNewSubServiceRequest = async () =>{
    if(!formData?.subService_name){
      setError((preError) => ({
        ...preError,
        subservice: "",
        price: "",
        subService_name : "Please enter Sub-Service name"
      }));
    } else{
      setProcessing(true);
      let token = getSession("token");
      const params ={
        ...(token?{token : token}:null),
        ...(selectedId?{service : selectedId}:null),
        ...(formData?.subService_name?{subservice : formData.subService_name}:null)
      }
      const result = JSON.parse(await requestNewSubservice(params));
      if(result?.status === true || result?.status === 'true'){
        setShowRequestSuccess(true);
        setAddNewPopup(false)
        setProcessing(false);
      } else{
        console.log(result?.message);
        setError((preError) => ({
          ...preError,
          subservice: "",
          subService_name : result?.message
        }));
        setProcessing(false);
      }
    }
  }

  return (
    <>
      <Header />
      <div className="dashboard">
        <div className="container">
          <div className="row mx-0">
            <div className="col-md-12">
              {showappointment && (
                <div className="form_book_appintment_next mb-4">
                  <div>
                    <div className="row mx-0">
                      <p className="fill_form">
                        <span> Specialisation </span> <br /> Select Provider’s
                        Services.
                      </p>
                      {moreServiceError && (
                        <p style={{ color: "red" }}>
                          {moreServiceError ? moreServiceError : ""}
                        </p>
                      )}
                      <ul className="all_services">
                        <>
                          {service &&
                            service.map((item) => (
                              <li
                                key={item._id}
                                onClick={() =>
                                  selectOptions(
                                    item._id,
                                    item.service_name,
                                    item.isCategory
                                  )
                                }
                                className={
                                  selectedId === item._id ? "colored" : ""
                                }
                              >
                                <img src={item.icon} alt={item.service_name} />
                                <p>{item.service_name}</p>
                              </li>
                            ))}
                        </>
                      </ul>
                      {serviceError && (
                        <p style={{ color: "red" }}>{serviceError}</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {showprocced && (
                <div className="form_book_appintment_next_form">
                  <div className="row">
                    <p className="fill_form mb-0">
                      <span>                 <button className="arrow_down" onClick={()=>{ navigate(-1)}}><img src={process.env.PUBLIC_URL + "/vendor_dashboard/order_detail/order_details.png"} /></button>Add Your Working Service. </span>{" "}
                    </p>
                    <div className="row">
                      <div className="col-md-3">
                        <h1>Service </h1>
                        <div className="user_name">
                          <input
                            type="text"
                            placeholder="Unisex Salon"
                            className="unix_slon"
                            value={selectedId ? `${selectedName}` : ""}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {subservice && (
                <div className="form_book_appintment_next_form">
                  <div className="row "></div>

                  <div className="row">
                    <div className="col-md-12">
                      <h1>Sub-Service</h1>
                      <div className="user_selection subservice_error">
                        <ul className="equalWidthINdivService">
                          {subservices.map((subservice) => (
                            <li key={subservice._id} onClick={() => handleSubServiceSelection(subservice._id) }
                              className={ selectedSubServiceId === subservice._id ? "selectedcolores" : "" } >
                              {subservice.sub_service_name}
                            </li>
                          ))}
                          <li onClick={() => {setAddNewPopup(true)} }> <b>+</b> Add New </li>
                          </ul>
                        {subServiceError && (<p style={{ color: "red" }}>{subServiceError}</p>)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {priceproceed && (
                <div className="form_book_appintment_next_form pb-4">
                  <div className="row">
                    <div className="col-md-12">
                      <h1>Price</h1>

                      <div className="user_selection">
                        <div className="price">
                          <span>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "../vendor_dashboard/nan.png"
                              }
                            />
                          </span>
                          <input
                            type="text"
                            placeholder="Enter Service Amount"
                            value={inputValue}
                            onChange={handleChange}
                            readOnly={editprocess}
                            className={error ? "error" : ""}
                          />
                          {priceError && (
                            <div style={{ color: "red" }}>{priceError}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="proceed_next mt-4 mb-4">
              {editprocess && (
                <div
                  class="button-container edit_proceed mt-4 mb-4"
                  onClick={showcontent}
                >
                  <button class="button button1" id="btn-otp-resend">
                    Edit Details
                  </button>
                  <button class="button button2">Edit Details</button>
                </div>
              )}
              <div
                class="button-container  mt-4 mb-4 mr-0"
                onClick={nextContent}
              >
                <button class="button button1" type="submit">
                  {" "}
                  Proceed To Next
                </button>
                <button class="button button2" type="submit">
                  {" "}
                  Proceed To Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {sucesspop && (
        <Modal show={show} onHide={handleClose} centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }} ClassName="custom-modal-dialog" >
          <Modal.Body>
            <div className="cross-X">
              <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cong.gif"}
              alt="playstore"
              className="cong-done-json"
              style={{ width: "150px" }}
            />

            <div className="congrats-profile-created">
              <h6>
                Congratulations
                <br />
                <span> Services are successfully Done</span>
              </h6>

              <div class="button-container" style={{ width: "200px" }}>
                <button className="button button1">Proceed To Next</button>
                <button className="button button2">
                  <Link
                    to="/individual/services"
                    style={{ textDecoration: "none", color: "#ffffff" }}
                  >
                    {" "}
                    Proceed To Next{" "}
                  </Link>
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {/* Request Sub-Service */}
      <Modal show={addNewPopup} onHide={handleClose} size='sm' centered>
        <Modal.Header closeButton>
          <h6>Suggest New Sub-Service</h6>
        </Modal.Header>
        <Modal.Body>   
          <div className={procession?"blur-effect":""}>
            <div className={`wallet-enter-Amount form-group`} >
              <input type="text" className='col-12' value={formData.subService_name} name="subService_name" id="subService_name" onChange={handleChange} placeholder="Sub-Service Name" />
              <br/>
            </div>
            {error?.subService_name && (<p className='error'>{error?.subService_name}</p>)}
            <br/>
            <div className="assign-worker-container" id="assign_work_list" >
              <button className="button assign-worker" onClick={sendNewSubServiceRequest}>Proceed To Next</button>
              <button className="button assign-worker1" onClick={sendNewSubServiceRequest}>Proceed To Next</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Success Popup */}
      <Modal
        show={showRequestSuccess}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X"  onClick={handleClose}>
            <img src={process.env.PUBLIC_URL + "/images/user/cross.png"} alt="playstore" />
            <img src={process.env.PUBLIC_URL + "/images/user/X.png"} alt="playstore" />
          </div>
          <img src={process.env.PUBLIC_URL + "/images/user/cong.gif"} alt="playstore" className="cong-done-json" style={{ width: "150px" }} />

          <div className="congrats-profile-created">
            <h6>
              <br />
              <span> Thank you for your request. Our team will contact you soon.</span>
            </h6>

            <div class="button-container" style={{ width: "200px" }}>
              <button className="button button1">Proceed To Next</button>
              <button className="button button2" onClick={() => {navigate('/individual/services')}} > Proceed To Next </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Footer />
    </>
  );
};

export default Individualservice_Selection;
