import { useRef } from "react";
import {
    getDatabase,
    ref,
    query,
    orderByChild,
    equalTo,
    push,
    onValue,
    set
  } from "firebase/database";
  import { firebaseWeb } from "../../firebase";

  const database = getDatabase(firebaseWeb);
  const databaseRef = ref(database, "/");


  export const ChatWithSelectedUser = async (vendor, toId) => {
    try {
      const userId = vendor?._id;
        const toUserId =toId.id||toId._id;
      const chatRef = ref(database, `/messages/${userId}/${toUserId }`);
  
      return new Promise((resolve, reject) => {
        const unsubscribe = onValue(chatRef, (snapshot) => {
          try {
            const data = snapshot.val();
            if (data) {
              // Data exists, process it
              const messages = Object.entries(data).map(([key, messageData]) => {
                return {
                  sender: messageData.sender,
                  receiver: messageData.receiver,
                  message: messageData.message,
                  image: messageData.image,
                  createdAt: messageData.createdAt,
                };
              });
  
              // Sort messages by createdAt in descending order
              const sortedMessages = messages.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
  
              // Resolve with the sorted messages
              resolve(sortedMessages);
            } else {
              // Data doesn't exist or is empty, resolve with an empty array
              resolve([]);
            }
          } catch (error) {
            // Handle any errors that occur while processing data
            reject(error);
          }
        });
  
        // Clean up the listener when the component unmounts
        return () => {
          unsubscribe();
        };
      });
    } catch (error) {
      console.error(error);
      // Handle the error using your Alert component or other error-handling method
      throw error; // Rethrow the error to indicate failure
    }
  };
  
