import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
// import './dashboard.css';
import ReactApexChart from 'react-apexcharts';
import React, { useState, useEffect } from 'react';
import DashboardAsVendor from "../../components/dashboardAsVendor";
import axios from "axios"; 
import { setSession, getSession} from "../../utils/helper";
import { apiBaseUrl } from "../../config/constant";


const VendorDashboard = () =>{
const [series_data,setSeriesData] = useState([]);
const [chart_series,setChartOptions_series] = useState([]);
const [last7DateValues, setLast7DateValues] = useState([]);
const[monthly_reevnue_data,setmothly_revenue_data] = useState([]);
const [monthValues, setMonthValues] = useState([]); 
useEffect(() => {
    const fetchData = async () => {
      let token = getSession('token');
      try {
        const response = await axios.get(
          apiBaseUrl+'order/get-daily-revenue',
          {
            headers: {
              Authorization: token,
              'Content-Type': 'application/json',
            },
          }
        );

        const result = response.data.response.result;

        // Filter the data for the current month
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-based
        const currentMonthData = result.filter(item => {
          const itemDate = new Date(item.date);
          return (
            itemDate.getFullYear() === currentYear &&
            itemDate.getMonth() + 1 === currentMonth
          );
        });

        const currentMonthDateSum = currentMonthData.map(item => item.sum);
        const currentMonthDateValues = currentMonthData.map(item => item.date);
        
        setSeriesData(currentMonthDateSum);
        setLast7DateValues(currentMonthDateValues);

        const updatedChartOptions_series = {
          ...chartOptions_series,
          xaxis: {
            ...chartOptions_series.xaxis,
            categories: currentMonthDateValues,
          },
        };
        setChartOptions_series(updatedChartOptions_series);

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);


// useEffect(() => {
//   const fetchData = async () => {
//     let token = getSession('token');
//     try {
//       const response = await axios.get(
//         apiBaseUrl+'order/get-daily-revenue',
//         {
//           headers: {
//             Authorization: token,
//             'Content-Type': 'application/json',
//           },
//         }
//       );

//       const result = response.data.response.result;

//       // Get the data for the last 7 days including the present day
//       const currentDate = new Date();
//       const last7DaysData = result.filter(item => {
//         const itemDate = new Date(item.date);
//         const timeDiff = currentDate - itemDate;
//         const daysDiff = timeDiff / (1000 * 3600 * 24);
//         return daysDiff <= 6;
//       });

//       const last7DateSum = last7DaysData.map(item => item.sum);
//       const last7DateValues1 = last7DaysData.map(item => item.date);

//       setSeriesData(last7DateSum);
//       setLast7DateValues(last7DateValues1);

//       const updatedChartOptions_series = {
//         ...chartOptions_series,
//         xaxis: {
//           ...chartOptions_series.xaxis,
//           categories: last7DateValues1,
//         },
//       };
//       setChartOptions_series(updatedChartOptions_series);

//     } catch (error) {
//       console.error(error);
//     }
//   };

//   fetchData();
// }, []);

// useEffect(() => {
//   const fetchData = async () => {
//     let token = getSession('token');
//     try {
//       const response = await axios.get(
//         apiBaseUrl+'order/get-daily-revenue',
//         {
//           headers: {
//             Authorization: token,
//             'Content-Type': 'application/json',
//           },
//         }
//       );

//       const result = response.data.response.result;

//       // Get the last 7 days' data
//       const last7DaysData = result.slice(-7);

//       const last7DateSum = last7DaysData.map(item => item.sum);
//       const last7DateValues1 = last7DaysData.map(item => item.date);

//       // Add the current date to the array of date values
//       const currentDate = new Date().toISOString();
//       last7DateValues1.push(currentDate);

//       console.log(currentDate);
//      console.log(last7DateValues1);
//       setSeriesData(last7DateSum);
//       setLast7DateValues(last7DateValues1);

//       const updatedChartOptions_series = {
//         ...chartOptions_series,
//         xaxis: {
//           ...chartOptions_series.xaxis,
//           categories: last7DateValues1,
//         },
//       };
//       setChartOptions_series(updatedChartOptions_series);

//     } catch (error) {
//       console.error(error);
//     }
//   };

//   fetchData();
// }, []);


// Mothly revenue 
useEffect(() => {
  const fetchData = async () => {
    let token = getSession('token');
    try {
      const response = await axios.get(
        apiBaseUrl+'order/get-monthly-revenue',
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        }
      );
      const result = response.data.response.result;
      // console.log(result);
      
      // Get the last seven months' data
      const lastSevenMonthsData = result.slice(0, 7);

      const sum = lastSevenMonthsData.map(item => item.sum);
      
      let monthly_reevnue_data = [
        {
          name: 'Monthly Revenue',
          data: sum.reverse(),
        },
      ];
      setmothly_revenue_data(monthly_reevnue_data);

      const monthValues = lastSevenMonthsData.map(item => {
        const date = new Date(item.date);
        const month = date.toLocaleString('default', { month: 'short' }); // Get month name
        return month;
      });
      setMonthValues(monthValues.reverse());
      // console.log(monthValues);

      // ... rest of your code
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, []);



  
const chartOptions = {
  chart: {
    height: 350,
    type: 'area',
    zoom: {
      enabled: true,
    },
    toolbar: {
      show: true,
    },
    background: '#f7f7f7',
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    colors: ['#4EA863', '#4EA863'],
  },
  xaxis: {
    type: 'category', // Change to 'category' type for months
    categories: monthValues, // Use monthValues for x-axis categories
    labels: {
      style: {
        fontSize: '12px', // You can customize label styles
      },
    },
  },
  fill: {
    colors: ['#4EA863', '#D9D9D9'], // Change area colors
  },
  tooltip: {
    x: {
      format: 'MMM', // Format for the tooltip
    },
  },
};


  const chartOptions_series = {
    chart: {
      height: 350,
      type: 'area',
      
      zoom: {
        enabled: true, 
      },
      scrollX: {
        enabled: true,
      },
      toolbar: {
        show: true, 
      },
      background: '#f7f7f7',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      colors: ['#4EA863', '#4EA863'],
    },
    fill: {
      colors: ['#4EA863', '#D9D9D9'], // Change area colors
    },
    tooltip: {
      x: {
        format: 'HH:mm dd/MM/yy ',
      },
      
    },
  };
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
          if (progress < 100) {
            setProgress(progress + 10);
          } else {
            clearInterval(interval);
          }
        }, 1000);
    
        return () => {
          clearInterval(interval);
        };
      }, [progress]);

    
      // console.log(monthly_reevnue_data)
      // console.log(chartOptions)
      
return(
    <>
    <Header />
   <div className="dashboard">
    <div className="container">
        <div className="row mx-0">
        <DashboardAsVendor/>
            <div className="col-lg-9 col-md-12">
             <div className="vendors_right_form">
              <div className="row mx-0">
                <div className="col-md-6">
                  <div className="vendors_form">
                  <div className="row mx-0">
                    <div className="col-md-6 pl-0">
                     <h1>Daily Revenue</h1>
                    </div>
                    <div className="col-md-6">
                       <div className="conaitner-view-details">
                        <button className="button view-details"><Link to="/vendor/revenuerecord">View All</Link></button>
                        <button className="button view-detail1"><Link to="/vendor/revenuerecord">View All</Link></button>
                       </div>
                    </div>
                    <ReactApexChart options={chartOptions_series} series={[{ data: series_data }]} type="area" height={350} />

                  </div>
                  </div>
                </div>
                <div className="col-md-6">
                <div className="vendors_form">
                  <div className="row mx-0 px-0">
                    <div className="col-md-6">
                     <h1>Monthly Revenue</h1>
                    </div>
                    <div className="col-md-6">
                       <div className="conaitner-view-details">
                        <button className="button view-details"><Link to="/vendor/revenuerecord">View All</Link></button>
                        <button className="button view-detail1"><Link to="/vendor/revenuerecord">View All</Link></button>
                       </div>
                    </div>
                    <ReactApexChart options={chartOptions} series={monthly_reevnue_data} type="area" height={350} />
                  </div>
                  </div>
                </div>
              </div>

              <div className="row mx-0">
                <div className="col-md-12 pr-0">
                  <ul className="vendor_dashboard_view">
                  <li>
                  <Link to="/vendor/revenuerecord">
                      <div className="vendor_dashbaord_box_view">
                      <img src={process.env.PUBLIC_URL + "/vendor_dashboard/card.png"} alt="right_qutation" />
                      <p>Revenue Records</p>
                      
                      </div>
                      </Link>
                    </li>
                    <li>
                    <Link to="/vendor/order-history">
                      <div className="vendor_dashbaord_box_view">
                      <img src={process.env.PUBLIC_URL + "/vendor_dashboard/delete.png"} alt="right_qutation" />
                      <p>Orders Listing</p>
                     
                      </div>
                      </Link>
                    </li>
                    <li>
                    <Link to="/vendor/service">
                      <div className="vendor_dashbaord_box_view">
                      <img src={process.env.PUBLIC_URL + "/vendor_dashboard/box.png"} alt="right_qutation" />
                      <p>Service</p>
                      </div>
                      </Link>
                    </li>
                  
                    <li>
                    <Link to="/vendor/revenueorder">
                      <div className="vendor_dashbaord_box_view">
                      <img src={process.env.PUBLIC_URL + "/vendor_dashboard/message.png"} alt="right_qutation" />
                      <p>Chats</p>
                      
                      </div>
                      </Link>
                    </li>
                    <li>
                    <Link to="/vendor/feedbacks">
                      <div className="vendor_dashbaord_box_view">
                      <img src={process.env.PUBLIC_URL + "/vendor_dashboard/feedback.png"} alt="right_qutation" />
                      <p>Feedback</p>
                      </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
             </div>
            </div>
        </div>
    </div>
   </div>
    <Footer />
    </>
);
};

export default VendorDashboard;
