import Header from "../../components/header";
import Footer from "../../components/footer";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import { getSession } from "../../utils/helper";
import Modal from "react-bootstrap/Modal";
import DashboardAsWorker from "../../components/DashboardAsWorker";
import { apiBaseUrl } from "../../config/constant";


const WorkerService = () =>{
  const token = getSession("token");
    const [showprocced,setProceed] = useState(false);
    const [subservice,setsubservice]=useState(false);
    const [showappointment,setappointment] = useState(true);
    const [priceproceed,setpriceproced] = useState(false);
    const [services, setServices] = useState([]);
    const [editprocess,seteditprocess]=useState(false);
    const [selectedService, setSelectedService] = useState('');
    const [subServices,setSubServices] = useState([])
    const [selectedItem, setSelectedItem] = useState(null);
    const [error, setError] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedId, setSelectedId] = useState('');
    const [isCategory,setSetIsCategory]=useState(false)
    const [formData,setFormData]=useState({
      services:"",
      sub_services:"",
      price:""

    })
    const addServiceData = async () => {
      try {
        const response = await axios.post(
          apiBaseUrl+"provider/addedit-services-by-company",
          formData,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
        return response.data
      } catch (error) {
        console.error(error);
      }
    };
    const handleItemClick = (subService_id,subServiceIndex) => {
      setSelectedItem(subServiceIndex);
      setFormData((prevFormData) => ({
        ...prevFormData,
        sub_services: subService_id
      }));


    };

const nextContent=()=>{
  if (!showprocced) {
    if (!selectedService) {
      setError('Please select a service.');
      return;
    }
    setProceed(true);
    setappointment(false)
    } 
  
    setsubservice(true)
   
  if(formData.sub_services){
    setpriceproced(true);
  }
  if(formData.price){
    seteditprocess(true);
  }

  if(formData.services && formData.sub_services.length && formData.price){
    addServiceData().then((data) => {
      console.log('Data received:', data);
      if(data.status === true){
        handleShow()
      }
    }).catch((error) => {
      // Logic to handle errors when the promise is rejected
      console.error('Error occurred:', error);
    })

}
}
console.log(services,"jjjjj")
    
    useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(
          apiBaseUrl+"provider/get-worker-service-list",
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
        setServices(response?.data?.response?.result);
  
      } catch (error) {
        console.error(error);
      }
    };

    fetchServices()
  }, []);

  const handleServiceSelection=(item)=>{
    if(item?.isCategory==='Y'){
      setSetIsCategory(true)
    }
    setSelectedId(item?._id)
    setSubServices(item?.sub_services);
    setSelectedService(item?.service_name);
    setFormData((prevFormData) => ({
      ...prevFormData,
      services: item?._id
    }));
  }
  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    const name = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      category: selectedCategory
    }));
  };
  
  const handleInputChange=(e)=>{
const value=e.target.value;
const name= e.target.name;

if(name==="price"){
  setFormData((prevFormData) => ({
    ...prevFormData,
    price: value
  }));
}
  }


return(
    <>
      <Header />
        <div className="dashboard">
    <div className="container">
        <div className="row mx-0">
<DashboardAsWorker/>
            <div className="col-md">
                {
                    showappointment &&
               
            <div className="form_book_appintment_next mb-4">
            <div>
           <div className="row mx-0">
<p className="fill_form"><span> Specialisation </span> <br /> Select Provider’s Services.</p>
<ul className="all_services">

{services?.map((item, index) => (
                              <li
                                key={item._id}
                                onClick={() =>
                                  handleServiceSelection(item)
                                }
                                className={selectedId === item?._id ? 'colore' : ''}
                              >
                                <img src={item.subServices.sub_service_icon} />
                                <p>{item.subServices.sub_service_name}</p>
                              </li>
                            ))}
</ul>
            </div>
           </div>
         
            </div>
             }
           
           
             
          
            {showprocced  && selectedService &&
           <div className="form_book_appintment_next_form">
           <div className="row">
         <p className="fill_form mb-0"><span> Specialisation </span> <br /> Add Your Working Service.</p>
              <div className="row">
                <div className="col-md-3">
                    <h1>Service</h1>
                    <div className="user_name"><input type="text"  value={selectedService} className="unix_slon"/></div>
                </div>
                {isCategory?
                <div className="col-md-9">
                <div className="">
                <h1>Gender</h1>
                <div className="user_selection d-flex">
      <div className="user_name">
        <input
          type="radio"
          className="radio_btns"
          value="Male"
          checked={formData.category === "Male"}
          onChange={handleCategoryChange}
        />
        <label>Male</label>
      </div>
      <div className="user_name">
        <input
          type="radio"
          className="radio_btns"
          value="Female"
          checked={formData.category === "Female"}
          onChange={handleCategoryChange}
        />
        <label>Female</label>
      </div>
      <div className="user_name">
        <input
          type="radio"
          className="radio_btns"
          value="Both"
          checked={formData.category === "Both"}
          onChange={handleCategoryChange}
        />
        <label>Other</label>
      </div>
    </div>
    </div>

                </div>
                : ""}
              </div>

            </div>
           </div>
            }
             {subservice &&
             <div className="form_book_appintment_next_form">
            <div className="row ">
                </div>
        
              <div className="row">
                <div className="col-md-12">
                    <h1 id="left_margin">Sub-Service</h1>
                
                <div className="user_selection">
          
        
       

                <ul className="subService-category">
            {subServices.map((subService, subIndex) => (
              <li 
          key={subIndex}
          className={selectedItem === subIndex ? 'active' : ''}
          onClick={() => handleItemClick(subService._id,subIndex)}
          id="sub_service_align"
        >
          {subService.sub_service_name}
        </li>
            ))}
          </ul>


                    </div>
                </div>
                
              </div>

            </div>
}
{priceproceed &&
  <div className="form_book_appintment_next_form pb-4">
    
          <div className="row">
          <div className="col-md-12">
              <h1>Price</h1>
          
          <div className="user_selection">
            <div className="price">
            <span><img src={process.env.PUBLIC_URL + "../vendor_dashboard/nan.png"} /></span><input type="text" placeholder="Enter Service Amount" name="price" onChange={handleInputChange}/>
            </div>
              </div>
          </div>
          
        </div>

      </div>
}

   
{/* <div className="proceed_next mt-4 mb-4" >
 { editprocess &&
  <div class="button-container edit_proceed mt-4 mb-4" id="mb_worker_service_btn" ><button class="button button1" id="btn-otp-resend">Edit Details</button><button class="button button2">Edit Details</button></div>
}
           <div class="button-container mt-4 mb-4 mr-0" id="mb_worker_service_btn" onClick={nextContent}><button class="button button1">  Proceed To Next</button><button class="button button2">  Proceed To Next</button></div>
           </div> */}
   
   
   </div>          
          
         


            </div>
        </div>
    </div>
   
        <Footer />

        <Modal
        show={show}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>
          <img
            src={process.env.PUBLIC_URL + "/images/user/cong.gif"}
            alt="playstore"
            className="cong-done-json"
            style={{ width: "150px" }}
          />

          <div className="congrats-profile-created">
            <h6>
   
              <br />
              <span> Service added successfully Done</span>
            </h6>
    
            <div class="button-container" style={{ width: "200px" }}>
              <button className="button button1">Proceed To Next</button>
              <button className="button button2" onClick={()=>{
                  window.location.href = '/vendor/service';
              }}>
              Proceed To Next
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
)
}

export default WorkerService;
