import * as yup from "yup";

export const ContactvalidationSchema = yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    // lastName: yup.string().required('Last Name is required'),
    inquiry: yup.string().required('Inquiry is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    // message: yup.string().required('Message is required'),
  });


  export  const validationSchema = yup.object().shape({
    fullName: yup.string().required("Full Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phone: yup
      .string()
      .matches(/^[0-9]{10}$/, "Phone Number must be 10 digits")
      .required("Phone Number is required"),
    gender: yup
      .string()
      .oneOf(["Male", "Female"], "Please select a gender"),
      skill: yup.string().required("Skills are required"),
      social_media_handle: yup
      .string()
      .url("Social Media Handle must be a valid URL")
      .required("Social Media Handle is required"),
      business_social_media_handle: yup
      .string()
      .url("Business Social Media Handle must be a valid URL")
      .required("Business Social Media Handle is required"),
  });