import React ,{useEffect, useState}from 'react'
import { Link ,NavLink } from 'react-router-dom'
import { getSessionForIndividual,logout  } from '../utils/helper';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { BaseUrl } from '../config/constant';
import { getBanks, verifyBVN, verifyPhone, verifyPhoneOTP, verifyEmail, verifyEmailOTP, providerUpdateProfile, getProviderServiceList, checkDocumentVerification, checkProfilePercentage, getWalletData,sentWithdrawRequest,loginUserCheck,VerifyUser } from './common';
import Select from 'react-select';

const LeftnavbarIndividual = ()=>{
const vendor  = getSessionForIndividual('individual');
const AUTHTOKEN  = getSessionForIndividual('token');
const percentage = vendor?.profileComplete || 70;
const handleLogout=()=>{
  logout();
  window.location = "/";
}
const [isopen, setopen] = useState(false);
const toggleMenu = () => {setopen(!isopen)};

const [show,setShow]=useState(false)
const [phoneVerify,setPhoneVerify]=useState(false);
const [emailVerify,setEmailVerify]=useState(false);
const [BVNVerify,setBVNVerify]=useState(false);
const [banks, setBanks]=useState([]);
const [selectedBank, setSelectedBank] = useState(null);

const [showReload,setShowReload]=useState(false);
const [walletData,setWalletData]=useState()

const handleBankChange = (selectedOption) => {
  setSelectedBank(selectedOption);
};
const [inputValues, setInputValues] = useState(Array(6).fill(''));
const [withdrawAmpunt, setWithdrawAmpunt]=useState();
const [errorMsg,setErrorMsg]=useState('')

const [value, setValue]=useState('');
const [showOtpBlock,setshowOtpBlock]=useState(false)

const [countdown, setCountdown] = useState(60);
const [isCounting, setIsCounting] = useState(false);
const [requestsent, setRequestSent] =useState(false);

const handleVerifyMobileClose=()=>{
  setPhoneVerify(false);
  setshowOtpBlock(false);
  setErrorMsg('');
  setInputValues(Array(6).fill(''));
}
const handleVerifyEmailClose=()=>{
  setEmailVerify(false);
  setshowOtpBlock(false);
  setErrorMsg('');
  setInputValues(Array(6).fill(''));
}
const handleVerifyBVNClose=()=>{
  setBVNVerify(false);
  setshowOtpBlock(false);
  setSelectedBank(null);
  setErrorMsg('');
  setValue('');
}

useEffect(()=>{
  try{
    handleWalletData(vendor.token);
    getBanks().then((bankData)=>{
      setBanks(bankData);
    });
    getProviderServiceList(vendor.token);
    checkDocumentVerification();
  } catch(error){
    if(error?.response?.status === 401 || error?.response?.statusText === "Unauthorized"){
      handleLogout();
    }
  }
},[]);


const handleInputChange = (event, index) => {
  const value = event.target.value;
  if (/^\d+$/.test(value) && value.length === 1) {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
    if (index < 5) {
      document.getElementById(`input-${index + 1}`).focus();
    }
  }
};
const handleKeyDown = (event, index) => {
  if (event.key === 'Backspace') {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
    if(index > 0){
      document.getElementById(`input-${index - 1}`).focus();
    }
  }

};

const handleChange=async(e)=>{
  const {value, name}= e.target;
  if(name === 'withdrawAmpunt'){
    setWithdrawAmpunt(value);
    setErrorMsg('');
  }else{
    setErrorMsg('')
    setValue(value);
  }
}

const resendPhoneVerifyOTP = async()=>{
  verifyPhone(vendor.token);
  setCountdown(60);
  setIsCounting(true);
}
const resendEmailVerifyOTP = async()=>{
  verifyEmail(vendor.token);
  setCountdown(60);
  setIsCounting(true);
}
const handleVerifyPhoneSubmit=async()=>{
  if (vendor.token) { 
    if(!showOtpBlock){
      const data = await verifyPhone(vendor.token);
      setshowOtpBlock(true)
      setCountdown(60);
      setIsCounting(true);
    }else{
      const otp = inputValues.join('');
      const resp = await verifyPhoneOTP(otp,vendor.token)
      if(resp){
        checkProfilePercentage('individual');
        handleVerifyMobileClose();
      } else {
        setErrorMsg('Please enter correct OTP');    
      }
    }
  }else{
    setErrorMsg('Please try afer some time.');
  }
}
const handleVerifyEmailSubmit=async()=>{
  if (vendor.token) { 
    if(!showOtpBlock){
      const data = await verifyEmail(vendor.token);
      setshowOtpBlock(true)
      setCountdown(60);
      setIsCounting(true);
    }else{
      const otp = inputValues.join('');
      const respounse = await verifyEmailOTP(otp,vendor.token)
      if(respounse){ 
        checkProfilePercentage('individual');
        handleVerifyEmailClose(); 
      }
      else{ setErrorMsg('Please enter correct OTP'); }
    }
  }else{
    setErrorMsg('Please try afer some time.');
  }
}
const handleBVNSubmit = async () => {
  try {
    if(!selectedBank){
      setErrorMsg('Please select bank name');
    } else if(!value){
      setErrorMsg('Please enter account number');
    } else if(value && value.length < 8){
      setErrorMsg('Invalid account number');
    } else{
      const bankData = banks.filter((bank) =>bank.name.includes(selectedBank.value));
      if(verifyBVN(value,bankData[0].code)){
        const params = {
          bankAcountNumber  : value,
          bankName          : bankData[0].name,
          bankCode          : bankData[0].code,
          isBVNVerify       : 1
        }
        const resp = await providerUpdateProfile(params,vendor.token,'BVN');
        if(resp){
          checkProfilePercentage('individual');
          handleVerifyBVNClose();
        }else{
          setErrorMsg('Please try after some time.');  
        }
      }else{
        setErrorMsg('Bank verification fail.');
      }
    }
  } catch (error) {
    setErrorMsg('Please try after some time.');
    return false;
  }
} //End of Function

const handleWalletData = async () => {
  try {
    const data = await getWalletData(vendor.token);
    if (data.status === true) {
      setWalletData(data.response.result)
      setShowReload(false);
    } else { console.error('Data status is not true:', data); }
  } catch (error) { 
    if(error?.response?.status === 401 || error?.response?.statusText === "Unauthorized"){
      handleLogout();
    }
   }
};

const handleSubmit=()=>{
  if (parseFloat(walletData.avl_ballance) >= withdrawAmpunt) { 
    if(!showOtpBlock){
      loginUserCheck(vendor.token).then((res)=>{})
      setshowOtpBlock(true);  
      setCountdown(60);
      setIsCounting(true);
    }else{
      const otp = inputValues.join('');
      if(otp.length === 6){
        VerifyUser(otp,vendor.token).then((res)=>{
          if(res.status === true || res.status === '200' || res.status === 200){
            setRequestSent(true);
            if(withdrawAmpunt){
              setErrorMsg('');
              sentWithdrawRequest(vendor.token, withdrawAmpunt).then((res)=>{
                // console.log(res);
                if(res.status === 200 || res.status === '200' || res.status === true){
                  setshowOtpBlock(false);
                  setValue('');
                  setWithdrawAmpunt('');
                  setShowReload(true);
                  handleWalletData(vendor.token);
                  handleClose();
                }
              })
            }else{
              setRequestSent(false);
              setErrorMsg('Please try again');
            }
          }else{
            setRequestSent(false);
            setErrorMsg('Please enter correct OTP')
          }
        })
      }else{
        setRequestSent(false);
        setErrorMsg('Please enter valid OTP.');
      }
    }
  }else{
    setErrorMsg('Insufficient funds in your wallet.');
  }
}

const resendLoginUserCheckOTP = async()=>{
  loginUserCheck(vendor.token);
  setCountdown(60);
  setIsCounting(true);
}

const handleClose=()=>{
  setShow(false)
} 

//for 60 secong Counter
useEffect(() => {
  let countdownInterval;
  // console.log(isCounting);
  if (isCounting) {
    countdownInterval = setInterval(() => {
      if (countdown > 0) {
        setCountdown((prevCountdown) => prevCountdown - 1);
      } else {
        clearInterval(countdownInterval);
        setIsCounting(false);
      }
    }, 1000);
  } else {
    clearInterval(countdownInterval);
  }

  return () => {
    clearInterval(countdownInterval);
  };
}, [countdown, isCounting]);
return(
<>
  <div className="col-md-3">
    <div className="user_profile" id="vendor_profile">
      <div className="d-flex justify-content-around " style={{padding:"0px 10px 0 10px"}}>
        <div className=""> 
          <div style={{ width: '69px',margin:'auto' ,height:'98px'}}>
            <CircularProgressbar  value={percentage} strokeWidth={6} styles={buildStyles({ strokeLinecap: 'butt', pathColor: `rgba(86,209,98, 1, ${percentage / 100})`, textColor: '#24A831', trailColor: '#d6d6d6'})} id="circularbar_dashboard" />
            <img src={vendor?.profile_pic?vendor?.profile_pic:"../images/avatar-default-icon.png"} alt="app"  className="app_img_profile_pic"    id="dashborad_pro" />
            <div className='circular_value'>
              <p className='percentage_value' id="percntage_vendor_dash" > {`${percentage}%`} </p>
            </div>
          </div>
        </div>
        <div className=" px-0 indiv-usr">
          <h1>{vendor.fullName}</h1>
          <p>
            <span> 
              <img src={process.env.PUBLIC_URL + "/images/vendor/mobile.png"} alt="app" className="app_img" />
            </span>{vendor.phone}
          </p>
          <p>
            <span> 
              <img src={process.env.PUBLIC_URL + "/images/vendor/mail.png"} alt="app"  className="app_img" />
            </span>{vendor.email}
          </p>
        </div>
      </div>

      <div className='wallet-icon'>
        <div  id="wallet_amount">
          <img src={process.env.PUBLIC_URL+"/images/icon/wallet.png"} alt='wallet-img' />
          <b className={showReload?`skeleton-loading`:""}> ₦ {walletData?.avl_ballance? parseFloat(walletData?.avl_ballance).toFixed(2):"0.00"}</b>
        </div>
        {!showReload ? (
          <img src={process.env.PUBLIC_URL + "/images/icon/reload.png"} alt="reload-img" id="reload" onClick={()=>{ setShowReload(true);handleWalletData(vendor.token)}} />
        ) : ( '' )}
        <div className='withdraw_request'>
          <button onClick={() => {setShow(true); }}>Withdraw request</button>
        </div>     
      </div>
    </div>
    <div className="user_listing">
      {vendor.isMobileVerified? '':(<>
          {/* <h1 onClick={()=>{setPhoneVerify(true)}}>Verify Phone Number <span> <Button className="bar_icon_toggle" aria-label="bar" id="verify"> +5 % </Button></span></h1> */}
          <h1 style={{cursor: 'pointer'}} onClick={()=>{setPhoneVerify(true)}}>Verify Phone Number <span> <Button aria-label="bar" id="verify"> +5 % </Button></span></h1>
      </> )}
      {vendor.isEmailVerified? '':(<>
          <h1 style={{cursor: 'pointer'}} onClick={()=>{setEmailVerify(true)}} >Verify Email <span> <Button aria-label="bar" id="verify"> +5 % </Button></span></h1>
      </> )}
      {vendor.isBVNVerify? '':(<>
          <h1 style={{cursor: 'pointer'}} onClick={()=>{setBVNVerify(true)}}>Verify BVN <span> <Button aria-label="bar" id="verify"> +5 % </Button></span></h1>
      </> )}
      {vendor.serviceAddded? '':(<>
        <NavLink to="/individual/service_Selections" style={{textDecoration:"none"}}><h1>Add Services<span> <Button aria-label="bar" id="verify"> +5 % </Button></span></h1></NavLink>
      </> )}
      {vendor.isUploadDocument? '':(<>
        <NavLink to="/individual/profile" style={{textDecoration:"none"}}><h1>Verify Document<span> <Button aria-label="bar" id="verify"> +5 % </Button></span></h1></NavLink>
      </> )}
      <h1 id="account_information">Account Information <span> <button className="bar_icon_toggle" aria-label="bar"  onClick={toggleMenu}> <i className={`fa ${isopen ? "fa-times" : "fa-bars"}`}></i> </button></span></h1> 
      <div className={isopen ? "toggled" : ""} style={{ padding: 0 ,margin:0}}>
        <ul>
          <NavLink to="/individual/dashboard" style={{textDecoration:"none"}}> <li> <div>Dashboard </div></li> </NavLink> 
          <NavLink to="/individual/profile" style={{textDecoration:"none"}}>  <li> <div> My Profile </div></li>  </NavLink>  
          <NavLink to="/individual/orderhistory" style={{textDecoration:"none"}} >  <li><div> Order</div></li> </NavLink>  
          <NavLink to="/individual/services" style={{textDecoration:"none"}}> <li><div>Services</div></li>   </NavLink>
          <NavLink to="/individual/revenueorder"  style={{textDecoration:"none"}}>  <li><div>Revenue Records</div></li> </NavLink>    
          <NavLink to="/individual/chat" style={{textDecoration:"none"}}>    <li><div>Chats</div></li>   </NavLink>   
          <NavLink to="/individual/feedbacks" style={{textDecoration:"none"}}>   <li><div>Feedbacks</div></li> </NavLink>  
          <li onClick={handleLogout}><div className='logout'>Logout</div></li>         
        </ul>
      </div>
    </div>
  </div>

  {/* Wallet Withdraw Model */}
  <Modal show={show} onHide={handleClose} size='sm' centered>
    <Modal.Header closeButton>
      <h6>Withdraw request</h6>
    </Modal.Header>
    <Modal.Body>   
      <div className={`${requestsent?'blur-effect':''}`}>
        <div className={`wallet-enter-Amount form-group ${!showOtpBlock ? '' : 'blur-effect'}`} >
          <input type="number" className='col-12' placeholder="Enter Widthdraw Amount " name="withdrawAmpunt" id="name" oninput="allow_alphabets(this)" onChange={handleChange} value={withdrawAmpunt?withdrawAmpunt:''} />
        </div>
        <p className='error'>{errorMsg}</p>
        {showOtpBlock? 
        <>
        <div className='otp-wallet-box'>
            {inputValues.map((value, index) => (
              <input key={index} type="text" id={`input-${index}`} value={value?value:''} onChange={(event) => handleInputChange(event, index)} onKeyDown={(event) => handleKeyDown(event, index)} maxLength={1} className='input-box' />
            ))}
          </div>
          {isCounting ? (
            <p className='error'> {' Time Left :'} {countdown} </p>
            ) : (
            <div
                className={checkBlur? 'button-container' : ' button-container '}
                style={{ width: '176px', marginTop: '15px' }}
                onClick={()=>{resendLoginUserCheckOTP()}} >
                <button className='button button1' id='btn-otp-resend'>
                  Resend Code
                </button>
                <button className='button button2'>Resend Code</button>
            </div>
          )}</>
          : ""}
          <br/>
        <div className="assign-worker-container" id="assign_work_list" onClick={handleSubmit}>
          <button className="button assign-worker">{!showOtpBlock?'Proceed To Next' : 'Verify Otp'}</button>
          <button className="button assign-worker1">{!showOtpBlock?'Proceed To Next' : 'Verify Otp'}</button>
        </div>
      </div>
    </Modal.Body>
  </Modal>

  {/* Verify Phone Model */}
  <Modal show={phoneVerify} onHide={handleVerifyMobileClose} size='sm' centered>
    <Modal.Header closeButton><h6>Verify Your Phone</h6></Modal.Header>
    <Modal.Body>   
      <div className="">
        <div className={`wallet-enter-Amount form-group ${!showOtpBlock ? '' : 'blur-effect'}`} >
          <input type="number" className='col-12' value={vendor.phone} name="phone" id="phone" oninput="allow_alphabets(this)" readOnly />
        </div>
        
        {showOtpBlock? 
        <>
          <div className='otp-wallet-box'>
            {inputValues.map((value, index) => (
              <input key={index} type="text" id={`input-${index}`} value={value} onChange={(event) => handleInputChange(event, index)} onKeyDown={(event) => handleKeyDown(event, index)} maxLength={1} className='input-box' />
            ))}
          </div>
          {isCounting ? (
              <p className='error'> {' Time Left :'} {countdown} </p>
              ) : (
              <div
                  className="button-container"
                  style={{ width: '176px', marginTop: '15px' }}
                  onClick={resendPhoneVerifyOTP} >
                  <button className='button button1' id='btn-otp-resend'>
                    Resend Code
                  </button>
                  <button className='button button2'>Resend Code</button>
              </div>
            )}
           </>: ""}
        <p className='error'>{errorMsg}</p>
        <div className="assign-worker-container" id="assign_work_list" onClick={handleVerifyPhoneSubmit}>
          <button className="button assign-worker">{!showOtpBlock?'Proceed To Next' : 'Verify Otp'}</button>
          <button className="button assign-worker1">{!showOtpBlock?'Proceed To Next' : 'Verify Otp'}</button>
        </div>
      </div>
    </Modal.Body>
  </Modal>
  {/* End of Function */}
  {/* Verify Email Model */}
  <Modal show={emailVerify} onHide={handleVerifyEmailClose} size='sm' centered>
        <Modal.Header closeButton>
          <h6>Verify Your Email</h6>
        </Modal.Header>
        <Modal.Body>   
          <div className="">
            <div className={`wallet-enter-Amount form-group ${!showOtpBlock ? '' : 'blur-effect'}`} >
              <input type="email" className='col-12' value={vendor.email} name="email" id="email" readOnly />
            </div>
            
            {showOtpBlock? 
            <>
              <div className='otp-wallet-box'>
                {inputValues.map((value, index) => (
                  <input key={index} type="text" id={`input-${index}`} value={value} onChange={(event) => handleInputChange(event, index)} onKeyDown={(event) => handleKeyDown(event, index)} maxLength={1} className='input-box' />
                ))}
              </div>
              {isCounting ? (
                <p className='error'> {' Time Left :'} {countdown} </p>
                ) : (
                <div
                    className="button-container"
                    style={{ width: '176px', marginTop: '15px' }}
                    onClick={resendEmailVerifyOTP} >
                    <button className='button button1' id='btn-otp-resend'>
                      Resend Code
                    </button>
                    <button className='button button2'>Resend Code</button>
                </div>
              )}</>
               : ""}
            <p className='error'>{errorMsg}</p>
            <div className="assign-worker-container" id="assign_work_list" onClick={handleVerifyEmailSubmit}>
              <button className="button assign-worker">{!showOtpBlock?'Proceed To Next' : 'Verify Otp'}</button>
              <button className="button assign-worker1">{!showOtpBlock?'Proceed To Next' : 'Verify Otp'}</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* End of Function */}

      {/* Verify BVN Model */}
      <Modal show={BVNVerify} onHide={handleVerifyBVNClose} size='sm' centered>
        <Modal.Header closeButton>
          <h6>Verify your BVN</h6>
        </Modal.Header>
        <Modal.Body>   
          <div className="">
            <div className={`wallet-enter-Amount form-group`} >
              <Select
                options={banks.map((bank) => ({ value: bank.name, label: bank.name }))}
                onChange={handleBankChange}
                value={selectedBank}
                isSearchable={true} // Enable search
                placeholder="Select Bank"
              />
              <input type="number" className='col-12' placeholder='Enter you account number' onChange={handleChange} name="account_number" id="account_number" />
            </div>
            <p className='error'>{errorMsg}</p>
            <div className="assign-worker-container" id="assign_work_list" onClick={handleBVNSubmit}>
              <button className="button assign-worker">{!showOtpBlock?'Proceed To Next' : 'Verify Otp'}</button>
              <button className="button assign-worker1">{!showOtpBlock?'Proceed To Next' : 'Verify Otp'}</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* End of Function */}
</>
 )
}
export default LeftnavbarIndividual;