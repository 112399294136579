import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "../pages/homepage.css";
import {ADMIN_URL_HOME} from '../config/constant';
import { LazyLoadImage } from "react-lazy-load-image-component";

const customDotsClass = 'custom-dots-class';

const dotsStyles = `
  .${customDotsClass} {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    list-style: none;
  }
  .${customDotsClass} button {
    width: 10px;
    height: 10px;
    margin: 5px;
    border-radius: 50%;
    background-color:  #0071c1;
    border: 1px solid black;
    cursor: pointer;
  }
  .${customDotsClass} button:hover {
    background-color:  #0071c1;
  }
  .${customDotsClass} .slick-active button {
    background-color:  #0071c1 !important;
    border-color:  #0071c1;
  }
`;

const HomeBanner = (props) => {
  const{bannerData}=props;

  const adminUrlHome = process.env.REACT_APP_ADMIN_URL_HOME;

  const settings = {
    dots: false,
    dotsClass: `slick-dots ${customDotsClass}`,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
  };

  return (
    <div className="containerxy">
      <style>{dotsStyles}</style>

      <Slider className="slick-slider" {...settings}>

      {bannerData && bannerData.map((item,index) => {
  return (
    
    <div key={index}>
      <div className="banner">
        <div className="banner-child" />
        {/* <div className="banner-item" /> */}
        <LazyLoadImage  className="mask-group-icon4" alt="" src={item.image} />
      </div>
      <div className="banner-text">
        <div className="get-expert-professional-container">
          <p className="get-expert">{item.title[0]}</p> 
          <p className="professional-services">{item.title[1]}</p> 
          <p className="get-expert">{item.title[2]}</p> 
        </div>
        <LazyLoadImage  className="banner-text-child" alt="" src="/line-1.svg" />
        <LazyLoadImage  className="banner-text-item" alt="" src="/line-1.svg" />
        <div className="quality-home-services">
          {item.title[3]}
        </div>
        <div className="doqwik-is-your-container">
          <p className="get-expert">
            {item.title[4]}
          </p>
          <p className="get-expert">
            {item.title[5]}
          </p>
          <p className="get-expert">
            {item.title[6]}
          </p>
        </div>
      </div>
    </div>
  );
})}

     
      </Slider>
    </div>
  );
};

export default HomeBanner;
