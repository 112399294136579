import Header from "../../components/header";
import Footer from "../../components/footer";
import DashboardAsVendor from "../../components/dashboardAsVendor";
import { useState,useEffect} from "react";
import axios from "axios";
import ReactApexChart from 'react-apexcharts';
import { setSession, getSession} from "../../utils/helper";
import { apiBaseUrl } from "../../config/constant";

const Revenuecode = () =>{
const [tab,settab] = useState(false);
const [series_data,setSeriesData] = useState([]);
const [chart_series,setChartOptions_series] = useState([]);
const [last7DateValues, setLast7DateValues] = useState([]);
const[monthly_reevnue_data,setmothly_revenue_data] = useState([]);
const [monthValues, setMonthValues] = useState([]);
const[monthly_data,setmonthly_data] = useState([]);
const [monthly_date,set_monthly_date] = useState([]);
const [data,setdata] = useState([]);
const [activetab,setactivetab] = useState(true);
const [isActive1, setIsActive1] = useState(false);
const [isActive2, setIsActive2] = useState(false);
const Activedaily_tab = () =>{
    setactivetab(false);
    settab(true);
    setIsActive1(true);
    setIsActive2(false);
}
const Activemonthly_tab = () =>{
     settab(false);
     setactivetab(true);
     setIsActive2(!isActive2);
     setIsActive1(false);
}
    // revenue Daily
    useEffect(() => {
      const fetchData = async () => {
          let token = getSession('token');
          try {
              const response = await axios.get(
                  apiBaseUrl+'order/get-daily-revenue',
                  {
                      headers: {
                          Authorization: token,
                          'Content-Type': 'application/json',
                      },
                  }
              );
  
              const result = response.data.response.result;
              console.log(result);
  
              const currentMonthData = result.filter(item => {
                  const itemDate = new Date(item.date);
                  const currentDate = new Date();
                  return (
                      itemDate.getFullYear() === currentDate.getFullYear() &&
                      itemDate.getMonth() === currentDate.getMonth()
                  );
              });
  
              const currentMonthDateSum = currentMonthData.map(item => item.sum);
              const currentMonthDateValues = currentMonthData.map(item => item.date);
  
              setdata(currentMonthData);  // Use the original order
              setSeriesData(currentMonthDateSum);
  
              // Format the x-axis labels using the reversed date values
              const formattedDates = currentMonthDateValues.map(date => {
                  const [year, month] = date.split('-');
                  return `${month}/${year}`;
              });
  
              const updatedChartOptions_series = {
                  ...chartOptions_series,
                  xaxis: {
                      ...chartOptions_series.xaxis,
                      categories: formattedDates,
                  },
              };
              setChartOptions_series(updatedChartOptions_series);
  
              // Filter out dates with zero sums and set Last7DateValues
              const filteredDatesWithValues = currentMonthData
    .filter(item => item.sum !== 0)
    .map(item => {
        const [year, month, day] = item.date.split('-');
        return `${day}/${month}/${year}`;
    });
setLast7DateValues(filteredDatesWithValues);
  
          } catch (error) {
              console.error(error);
          }
      };
  
      fetchData();
  }, []);
  
  
  
  
  
      
      
      


      const chartOptions_series = {
        chart: {
          height: 350,
          type: 'area',
          
          zoom: {
            enabled: true, 
          },
          scrollX: {
            enabled: true,
          },
          toolbar: {
            show: true, 
          },
          
          background: '#f7f7f7',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          colors: ['#4EA863', '#4EA863'],
        },
        fill: {
          colors: ['#4EA863', '#D9D9D9'], 
        },
        tooltip: {
          x: {
            format: 'HH:mm dd/MM/yy ',
          },
           xaxis: {
      type: 'category', // Change to 'category' type for months
      categories: monthly_date, // Use monthValues for x-axis categories
      labels: {
        style: {
          fontSize: '12px', // You can customize label styles
        },
      },
    },
          
        },
      };
      // Revenue Monthly 
        
const chartOptions = {
    chart: {
      height: 350,
      type: 'area',
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: true,
      },
      background: '#f7f7f7',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      colors: ['#4EA863', '#4EA863'],
    },
    xaxis: {
      type: 'category', // Change to 'category' type for months
      categories: monthly_date, // Use monthValues for x-axis categories
      labels: {
        style: {
          fontSize: '12px', // You can customize label styles
        },
      },
    },
    fill: {
      colors: ['#4EA863', '#D9D9D9'], // Change area colors
    },
    tooltip: {
      x: {
        format: 'MMM', // Format for the tooltip
      },
    },
  };



    // Monthly Revenue Records
    // useEffect(() => {
    //     const fetchData = async () => {
    //       let token = getSession('token');
    //       try {
    //         const response = await axios.get(
    //           apiBaseUrl+'order/get-monthly-revenue',
    //           {
    //             headers: {
    //               Authorization: token,
    //               'Content-Type': 'application/json',
    //             },
    //           }
    //         );
            
    //         const result = response.data.response.result;
    //         setmonthly_data(result);
    //         console.log(result);
      
    //         // Get the last seven months' data
    //         const lastSevenMonthsData = result;
      
    //         const sum = lastSevenMonthsData.map(item => item.sum);
      
    //         let monthly_reevnue_data = [
    //           {
    //             name: 'Monthly Revenue',
    //             data: sum.reverse(),
    //           },
    //         ];
          
      
    //         const monthYearValues = lastSevenMonthsData.map(item => {
    //           const date = new Date(item.date);
    //           const month = date.toLocaleString('default', { month: 'short' }); // Get month name
    //           const year = date.getFullYear();
    //           return `${month} ${year}`;
    //         });
    //         setmothly_revenue_data(monthly_reevnue_data);
    //         set_monthly_date(monthYearValues.reverse());
    //         setMonthValues(monthYearValues);
    //         console.log(monthYearValues);
    //         console.log('MonthValues updated:', monthYearValues);
    //         // ... rest of your code
    //       } catch (error) {
    //         console.error(error);
    //       }
    //     };
      
    //     fetchData();
    //   }, []);
      
    useEffect(() => {
      const fetchData = async () => {
        let token = getSession('token');
        try {
          const response = await axios.get(
            apiBaseUrl+'order/get-monthly-revenue',
            {
              headers: {
                Authorization: token,
                'Content-Type': 'application/json',
              },
            }
          );
          
          const result = response.data.response.result;
          setmonthly_data(result);
          console.log(result);
    
          // Get the last seven months' data
          const lastSevenMonthsData = result;
    
          const sum = lastSevenMonthsData.map(item => item.sum);
    
          let monthly_reevnue_data = [
            {
              name: 'Monthly Revenue',
              data: [...sum].reverse(), // Create a new array and reverse it
            },
          ];
    
          const monthYearValues = lastSevenMonthsData.map(item => {
            const date = new Date(item.date);
            const month = date.toLocaleString('default', { month: 'short' }); // Get month name
            const year = date.getFullYear();
            return `${month} ${year}`;
          });
    
          // Reverse the arrays before setting them in state
          const reversedMonthYearValues = [...monthYearValues].reverse();
          setmothly_revenue_data(monthly_reevnue_data);
          set_monthly_date(reversedMonthYearValues); // Reverse the dates for monthly_date state
          setMonthValues(monthYearValues); // Use the original order for MonthValues state
    
          console.log('Reversed MonthYearValues:', [...monthYearValues].reverse());
          console.log('Reversed MonthValues:',monthYearValues);
    
          // ... rest of your code
        } catch (error) {
          console.error(error);
        }
      };
    
      fetchData();
    }, []);
    

    return(
        <>
         <Header />
        <div className="dashboard">
    <div className="container">
        <div className="row mx-0">
        <DashboardAsVendor/>
            <div className="col-md-9">
             <div className="vendors_right_formses">
             <div className="row mx-0">
                    <div className="col-md-7">
                        
                        <h1 className="myorder mt-3">Revenue Details  </h1>
                  
                       
                    </div>
                    <div className="col-md-5">
                        <div className="revenue ">
                            <button onClick={Activedaily_tab }  className={isActive1 ? 'active' : ''}>Daily Revenue</button>
                            <button onClick={Activemonthly_tab}  className={isActive2 ? 'active' : ''}>Monthly Revenue</button>
                        </div>
                    </div>
                </div>
             { tab &&
                    
             <>
             <div className="row mt-2 mx-0" id="daily_monthly">
             <ReactApexChart options={chartOptions_series} series={[{ data: series_data }]} type="area" height={350} />
            </div>
                                 
               <div className="row data_analytics mx-0">
               {data && data.filter(item => item.sum !== 0).map((item, index) => (
  <div className="col-md-4" key={item}>
    <div className="reveue_daily">
      <div className="revenue_date">
        <div>
          <p>Amount Received</p>
          <span>{last7DateValues[index]}</span>
        </div>
        <div><button>₦ {item.sum}</button></div>
      </div>
    </div>
  </div>
))}

              
                </div>
                </>
              }
            
         
           {activetab &&
              
             <>
<div className="row mt-2 mx-0" id="monthly_data">
<ReactApexChart options={chartOptions} series={monthly_reevnue_data} type="area" height={350} />
                                    </div>
        <div className="row data_analytics mx-0">
            {monthly_data && monthly_data.filter(item =>item.sum !==0).map ((item,index)=>(
              <div className="col-md-4" >
              <div className="reveue_daily">
                  <div className="revenue_date">
                      <div>
                          <p>Amount Received</p>
                          <span>{monthValues[index]}</span>
                      </div>
                      <div><button>₦ {item.sum}</button></div>

                  </div>


              </div>
          </div>
            ))}
               
            </div>
                                        </>
                 }
              
               
                </div>             
           
            </div>
        </div>
    </div>
   </div>
        <Footer />
        </>
    );
};

export default Revenuecode;