import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import './dashboard.css';
import UserDashboard from '../../components/dashboardAsUser'
import React, { useState ,useEffect } from 'react';
import { validationSchemaForAddress , validationSchemaForEditAddress} from "../../components/validators/userFormValidator";
import { getSession , getSessionForLoginUser ,setSessionForLoginUser} from "../../utils/helper";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { apiBaseUrl } from "../../config/constant";
import { getCountry,getSate,getCity  } from "../../components/common";

const My_address = ()=>{
const token =getSession('token');
const userData = getSessionForLoginUser('user')

const navigate = useNavigate();
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
const [show2, setShow2] = useState(false);
const handleClose2 = () => setShow2(false);
const handleShow2 = () => setShow2(true);
const[deleteId,setDeleteId] = useState('');
const [show5, setShow5] = useState(false);
const handleClose5 = () => setShow5(false);
const handleShow5 = (id) =>{ setDeleteId(id);setShow5(true); }
const [errors, setErrors] = useState({});
const [errors2, setErrors2] = useState({});
const[result,setResult] = useState('')
const[data,setData]=useState([]);

const [country, setCountry] = useState([])
const[ state, setState ]=useState([]);
const[city, setCity]=useState([])
const [defaultAddress, setDefaultAddresss]=useState(userData.defaultAddress?userData.defaultAddress:''); 


const [formData, setFormData] = useState({
  country: "",
  state: "",
  city:"",
  land_mark: "",
  address1: "",
  address2: "",
  postal_code: "",
  platform:"Web"
});

/////////////////////////////////////////////  Edit Address ////////////////////////////////////////
const [show3, setShow3] = useState(false);
const handleClose3 = () => setShow3(false);
const handleShow3 = () => setShow3(true);


  const [formEditData, setFormEditData] = useState({
    country: "",
    state: "",
    city:"",
    land_mark: "",
    address1: "",
    address2: "",
    postal_code: "",
    platform:"Web",
  });

  const handleEdit = (particularData) => {
    setFormEditData({
      country: particularData?.country,
      state: particularData?.state,
      city: particularData?.city,
      land_mark: particularData?.land_mark,
      address1: particularData?.address1,
      address2: particularData?.address2,
      postal_code: particularData?.postal_code,
      latitude: particularData?.latitude,
      longitude: particularData?.longitude,
      platform: 'Web',
      address_id:particularData?._id,
    });
handleShow3();

  }
const handleMarkAsDefault = async (id) =>{
  try {
    const response = await axios.post(
      apiBaseUrl+"users/set-default-addresss",
      {addressId : id},
      {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
      }
    );
   if(response.data.status){
    const sessionData =  getSession('user');
    const newSession = {...sessionData, defaultAddress : id}
    setSessionForLoginUser('user',newSession)
    setDefaultAddresss(id);
    return true;
   }
  } catch (error) {
    console.log(error);
    return false; 
  }
}

  const postEditData = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl+"users/edit_address",
        formEditData,
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          },
        }
      );
     return response.data;
    } catch (error) {
      console.error(error);
    }
  };

const handleEditChange = async(e) => {
  const { name, value } = e.target;
  if(name==='country'){
    const jsonObject = JSON.parse(value);
    const country= await  getSate(jsonObject.id);
    setState(country)
    setFormEditData((prevFormData) => ({
      ...prevFormData,
      [name]: jsonObject.name,
    }));
  }else if(name==='state'){
    const jsonObject = JSON.parse(value);
    const state= await  getCity(jsonObject.id);

setCity(state)
    setFormEditData((prevFormData) => ({
      ...prevFormData,
      [name]: jsonObject.name,
    }));
  }else if(name==='city'){
    const jsonObject = JSON.parse(value);
    setFormEditData((prevFormData) => ({
      ...prevFormData,
      [name]: jsonObject.name,
    }));
  }else {
  setFormEditData((prevValues) => ({
    ...prevValues,
    [name]: value,      
  }));
  }
  setErrors2((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error message for the corresponding field
    }));

}
const handleEditSubmit = (e) => {
  e.preventDefault();
  validationSchemaForEditAddress
    .validate(formEditData, { abortEarly: false })
    .then(() => {
      postEditData().then((response)=>{
        console.log(response,"inside")
          if(response.status===true){
            setResult("Updated")
              handleClose3();
              handleShow2();
          }
      }).catch((error) => {

          console.error(error);
      })
      console.log("Form is valid. Submitting...");
    })
    .catch((err) => {
      // Validation failed
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors2(validationErrors);
    });
};

// handleDelete


const deleteAddress = async () => {
  try {
    const response = await axios.post(
      apiBaseUrl+'users/delete-addresss',
      {
        "addressId": deleteId
      },
      {
        headers: {
            Authorization: token,
          'Content-Type': 'application/json'
        }
      }
    );
return response.data
  } catch (error) {
    console.error(error);
  }
};

const handleDelete=()=>{
  deleteAddress().then((data)=>{
if(data.status===true){
  window.location.href = '/user/My_address';
}
  }).catch((error)=>{
      console.error(error);
  })
}

const handleState=()=>{
  navigate('/vendor/edit_service' , {state:data});
}
// end 

/////////////////////////////////////////////// end Edit Address ///////////////////////////////////


  const handleChange = async (e) => {
    const { name, value } = e.target;
    
  if(name==='country'){
    const jsonObject = JSON.parse(value);
    const country= await  getSate(jsonObject.id);
    setState(country)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: jsonObject.name,
    }));
  }else if(name==='state'){
    const jsonObject = JSON.parse(value);
    const state= await  getCity(jsonObject.id);

setCity(state)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: jsonObject.name,
    }));
  }else if(name==='city'){
    const jsonObject = JSON.parse(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: jsonObject.name,
    }));
  }else {
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,      
    }));
  }

    setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", 
      }));

  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = apiBaseUrl+'users/address-list';
        const response = await axios.get(url, {
          headers: {
            'Authorization': token
          }
        });

        // Do something with the response data
        setData(response.data.response.result)
       
       
      } catch (error) {
        // Handle errors
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  
  useEffect(() => {
    console.log('inOutside:', data);
    if (data) {
      // Perform data update logic here
      userData.addressData = data;
      console.log('userData:', userData);
      setSessionForLoginUser('user', userData);
    }
  }, [data]);  

  const postData = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl+"users/address",
        formData,
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          },
        }
      );
     return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    validationSchemaForAddress
      .validate(formData, { abortEarly: false })
      .then(() => {
        postData().then((response)=>{
            if(response.status===true){
              setResult('Added')
                handleClose();
                handleShow2();
            }
        }).catch((error) => {
            console.error(error);
        })
        console.log("Form is valid. Submitting...");
      })
      .catch((err) => {
        // Validation failed
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countries = await getCountry();
        setCountry(countries);
      } catch (error) {
        // Handle any errors here
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  return(
    <>
    <Header/>
    <div className="dashboard">
      <div className="container">
        <div className="row mb_view_center">
          <UserDashboard/>
            <div className="col-md-12 col-lg-9">
              <div className="right_side">
                <div className="row mb_view_center mx-0">
                  <div className="col-md-8">
                    <h1 className="my_order">My Address Records</h1>
                    <h2 className="order_details"></h2>
                  </div>
                  <div className="col-md-4 ">
                    <div class="button-container--addressnew"  onClick={handleShow}>
                      <button class="button button1" >Add New Address</button><button class="button button2">Add New Address</button>
                    </div>
                  </div>
                </div>
                <div className="row mx-0 mt-3 mb-2">
                  {data.map((item,index)=>(
                    <div className="col-md-4 mb-4 " key={index}>
                      <div className="address_details">
                        <div className="address_card">
                          
                          <input title="Default Address" name="default" type="radio" checked={defaultAddress === item._id ? true : false} disabled />

                          <span><button className="addres_num" onClick={()=>{handleMarkAsDefault(item._id)}}>Mark as default</button></span>
                          
                        </div>
                        <p> {item.land_mark},{item.address1} , {item.city} ,{item.state} ,{item.postal_code} ,{item.country} </p>
                        <div className="d-flex justify-content-between address_user mt-3">
                          <div class="button-container" onClick={()=>{handleEdit(item)}}>
                            <button class="button button1" id="btn-otp-resend">Edit Address</button><button class="button button2">Edit Address</button>
                          </div>
                          <buttton id="btn-dlete" onClick={()=>handleShow5(item._id)}><img src={process.env.PUBLIC_URL + "/images/delete-btn.png"} alt="dlete-btn"/></buttton>
                        </div>               
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="mb-0">
        <div className="download_aap">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-12">
                <img src={process.env.PUBLIC_URL + "../app_img.png"} alt="app"  className="app_img" /> 
              </div>
              <div className="col-md-6 col-sm-12 pt-4">
                <h1>Download the app</h1>
                <p>Get the app. Get things done. Compare prices, read reviews and book top-rated services. All in one free app.</p>
              </div>
              <div className="col-md-3 col-sm-12">
                <ul>
                  <li> <img src={process.env.PUBLIC_URL + "../androied.png"} alt="app"  className="app_img" /> </li>
                  <li> <img src={process.env.PUBLIC_URL + "../ios.png"} alt="app"  className="app_img" /> </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />   
      <Modal show={show} onHide={handleClose}>
        <div className="model_header">
          <div className="header_viewes">
            <p> Add New Address</p>
          </div>
          <div className="">
            <img src={process.env.PUBLIC_URL + "../images/cross.png"} alt="app" className="cross_icon" onClick={handleClose} />
          </div>
        </div>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="row mx-0" id="userAddress">
              <div className="col-md-6">
                <select  name="country" className="select_options" onChange={handleChange} >
                  <option>Select Country</option>
                  {country.map((item, index)=>(
                    <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                  ))}
                </select>
                {errors.country && <p className="error-message">{errors.country}</p>}
              </div>
              <div className="col-md-6">
                <select name="state" className="select_options" onChange={handleChange} >
                  <option>Select State</option>
                    {state.map((item, index)=> (           
                      <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                    ))} 
                </select>
                {errors.state && <p className="error-message">{errors.state}</p>}
              </div>
              <div className="col-md-6">
                <select name="city" className="select_options" onChange={handleChange}>
                  <option>Select City</option>
                    {city.map((item, index)=>(
                      <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                    ))}
                </select>
                {errors.city && <p className="error-message">{errors.city}</p>}
              </div>
              <div className="col-md-6">
                <input type="text" placeholder="land_mark" className="ladnmark"   name="land_mark"  value={formData.land_mark} onChange={handleChange} />
                {errors.land_mark && <p className="error-message">{errors.land_mark}</p>}
              </div>
              <div className="col-md-12">
                <input type="text" name="address1" placeholder="Address Line 1" className="address_line" value={formData.address1} onChange={handleChange} />
                {errors.address1 && <p className="error-message">{errors.address1}</p>}
              </div>
              <div className="col-md-12">
                <input type="text" name="address2" placeholder="Address Line 2" className="address_line" value={formData.address2} onChange={handleChange} />
                {errors.address2 && <p className="error-message">{errors.address2}</p>}
              </div>
              <div className="col-md-6">
                <input type="text" placeholder="Postal Code" className="ladnmark" name="postal_code" value={formData.postal_code} onChange={handleChange} />
                {errors.postal_code && <p className="error-message">{errors.postal_code}</p>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="model_submit" >
              <button  className=" button submit_close" type="submit">Submit</button>
              <button  className="button submit_close_hover" type="submit">Submit</button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={show2} onHide={handleClose2} centered style={{ width: "351px", marginLeft: "40%", borderRadius: "34px !important"}} ClassName="custom-modal-dialog" >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose2}>
            <img src={process.env.PUBLIC_URL + "/images/user/cross.png"} alt="playstore" />
            <img src={process.env.PUBLIC_URL + "/images/user/X.png"} alt="playstore" />
          </div>
          <img src={process.env.PUBLIC_URL + "/images/user/cong.gif"} alt="playstore" className="cong-done-json" style={{ width: "150px" }} />
          <div className="congrats-profile-created">
            <h6> <br /> <span> Address {result} Successfully Done</span> </h6>
            <div class="button-container" style={{ width: "200px" }}>
              <button className="button button1">Proceed To Next</button>
              <button className="button button2" onClick={()=>{window.location.href = '/user/My_address';}}>Proceed To Next</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* ////////////////////////////////////// edit address//////////////////////// */}
      <Modal show={show3} onHide={handleClose3}>
        <div className="model_header">
          <div className="header_viewes">
            <p>Edit Address</p>
          </div>
          <div className="">
            <img src={process.env.PUBLIC_URL + "../images/cross.png"} alt="app" className="cross_icon" onClick={handleClose3} />
          </div>
        </div>
        <Form onSubmit={handleEditSubmit}>
          <Modal.Body>
            <div className="row mx-0" id="userAddress">
              <div className="col-md-6">
                <select name="country" className="select_options" value={formEditData.country} placeholder={formEditData.country} onChange={handleEditChange} >
                  <option disabled selected>{formEditData.country}</option>
                    {country.map((item, index)=>(
                      <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                    ))}
                </select>
                {errors2.country && <p className="error-message">{errors2.country}</p>}
              </div>
              <div className="col-md-6">
                <select name="state" className="select_options" value={formEditData.state} placeholder={formEditData.state} onChange={handleEditChange} >
                  <option disabled selected >{formEditData.state}</option>
                  {state.map((item, index)=> (           
                    <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                  ))} 
                </select>
                {errors2.state && <p className="error-message">{errors2.state}</p>}
              </div>
              <div className="col-md-6">
                <select name="city" className="select_options" value={formEditData.city} onChange={handleEditChange}>
                  <option disabled selected>{formEditData.city} </option>
                  {city.map((item, index)=>(
                    <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                  ))}
                </select>
                {errors2.city && <p className="error-message">{errors2.city}</p>}
              </div>
              <div className="col-md-6">
                <input type="text" placeholder="land_mark" className="ladnmark"   name="land_mark"  value={formEditData.land_mark} onChange={handleEditChange} />
                {errors2.land_mark && <p className="error-message">{errors2.land_mark}</p>}
              </div>
              <div className="col-md-12">
                <input type="text" name="address1" placeholder="Address Line 1" className="address_line" value={formEditData.address1} onChange={handleEditChange} />
                {errors2.address1 && <p className="error-message">{errors2.address1}</p>}
              </div>
              <div className="col-md-12">
                <input type="text" name="address2" placeholder="Address Line 2" className="address_line" value={formEditData.address2} onChange={handleEditChange} />
                {errors2.address2 && <p className="error-message">{errors2.address2}</p>}
              </div>
              <div className="col-md-6">
                <input type="text" placeholder="Postal Code" className="ladnmark" name="postal_code" value={formEditData.postal_code} onChange={handleEditChange} />
                {errors2.postal_code && <p className="error-message">{errors2.postal_code}</p>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="model_submit" >
              <button  className=" button submit_close" type="submit">Submit</button>
              <button  className="button submit_close_hover" type="submit">Submit</button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton>
        <Modal.Title><h6>Are you sure you want to delete this address? </h6>  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <img src={process.env.PUBLIC_URL + "/images/delete_icon.png" } className="delete_images"></img>
          </Modal.Body>
        <Modal.Footer>
          <div className="proceed_next mt-4 mb-4">
            <div class="button-container edit_proceed mt-4 mb-4" style={{marginRight:"5px"}} onClick={handleClose5} >
                <button class="button button1" id="btn-otp-resend">No</button><button class="button button2">No</button>
            </div>
            <div class="button-container proccess_next mt-4 mb-4 mr-0" >
              <button class="button button1" onClick={handleDelete}> Yes </button><button class="button button2" onClick={handleDelete}>  Yes</button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {/* /////////////////////////////////////////end edit address ////////////////// */}
    </>
    )
}

export default My_address;
