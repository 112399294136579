import Header from "../../components/header";
import Footer from "../../components/footer";
import React, { useEffect, useState } from 'react';
import axios from "axios";
import "./privacy.css";
import { apiBaseUrl } from "../../config/constant";
const Privacy_policy = () =>{
const p_pravacypolicy = JSON.parse(localStorage.getItem('page_pravacypolicy'));
const [term, setTerm] = useState(p_pravacypolicy?p_pravacypolicy:[]);
useEffect(() => {
  
   const fetchData = async () => {
     try {
       const response = await axios.get(
         apiBaseUrl+'common/web-get-privacypolicy-page-data',
         {
           headers: {
             key: 'd1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed',
             'Content-Type': 'application/json',
           },
         }
       );
       setTerm(response.data.response.result.privacyPolicy);
       localStorage.setItem('page_pravacypolicy', JSON.stringify(response?.data?.response?.result?.privacyPolicy))
     } catch (error) {
       console.error(error);
     }
   };
 
   fetchData();
 }, []);  
    return(
        <>
        <Header />
        <div className="privacy">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div>
                  <div className="privacy_policy" key={"index"} >
                    <h1 id="jdkkon">{term[0]?.title?term[0]?.title:"Privacy & Policy"}</h1>
                    <div dangerouslySetInnerHTML={ { __html: term[0]?.description}}>

                    </div>
                  </div>
                </div>
              </div>        
            </div>
          </div>
        </div>
        <Footer />
        </>
    );
};

export default Privacy_policy;