import React, { Component } from "react";
import Slider from "react-slick";
export default class About_usmobileslider extends Component {
    render() {
        const settings = {
          dots: true,
          dotsClass: 'slick-dots  customDotsClass',
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          arrows:false,
          initialSlide: 1,
          autoplay:true,
    
          responsive: [
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                initialSlide: 3,
                dots: true,
              }
            },
            
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1.5,
                slidesToScroll: 1.5,
                initialSlide: 1.5,
                dots: true,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1.5,
                slidesToScroll: 1.5,
                dots: true,
              }
            }
          ]
        };
        return (
          <div>
    
            <Slider {...settings}>
     
            <div>
            <div className="slider_square">
                        <h1 className="exclisive_features--subtitle">01</h1>
                        <p className="exclisive_features--content">Find a local handyman using a specific search service</p>
            </div>
            </div>
    
    <div>
    <div className="slider_square">
        <h1 className="exclisive_features--subtitle">02</h1>
        <p className="exclisive_features--content">A list of providers near you will be displayed, depending on the service selected.</p>
    </div>
    </div>
   
    <div>
    <div className="slider_square">
        <h1 className="exclisive_features--subtitle">03</h1>
        <p className="exclisive_features--content">You select the provider according to your needs</p>
    </div>
    </div>
     

    <div>
    <div className="slider_square">
        <h1 className="exclisive_features--subtitle">04</h1>
        <p className="exclisive_features--content">Chat with your selected provider to coordinate job details.</p>
    </div>
    </div>
    <div>
    <div className="slider_square">
                        <h1 className="exclisive_features--subtitle">05</h1>
                        <p className="exclisive_features--content">If the provider is suitable for your job details, you can book</p>
                    </div>
    </div>
    
          
            </Slider>
          </div>
        );
      }
}


