import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
// import './dashboard.css';
import ReactApexChart from 'react-apexcharts';
import React, { useState, useEffect } from 'react';
import DashboardAsWorker from "../../components/DashboardAsWorker";

const WorkerDashboard = () =>{
  const seriesData = [
    {
      name: 'series1',
      data: [31, 40, 28, 51, 42, 109, 100],
    },
    
  ];
  
  const chartOptions = {
    chart: {
      height: 350,
      type: 'area',
      zoom: {
        enabled: false, 
      },
      toolbar: {
        show: false, 
      },
      background: '#f7f7f7',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      colors: ['#4EA863', '#4EA863'],
    },
    xaxis: {
      type: 'datetime',
      categories: [
        '2023-01-01T00:00:00.000Z',
        '2023-02-01T00:00:00.000Z',
        '2023-03-01T00:00:00.000Z',
        '2023-04-01T00:00:00.000Z',
        '2023-05-01T00:00:00.000Z',
        '2023-06-01T00:00:00.000Z',
        '2023-07-01T00:00:00.000Z',
      ],
      labels: {
        datetimeUTC: false,
        format: 'MMM', // Format the labels as month names (Jan, Feb, etc.)
      },
    },
    fill: {
      colors: ['#4EA863', '#D9D9D9'], // Change area colors
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
  };

    const [progress, setProgress] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
          if (progress < 100) {
            setProgress(progress + 10);
          } else {
            clearInterval(interval);
          }
        }, 1000);
    
        return () => {
          clearInterval(interval);
        };
      }, [progress]);

    
      
      
return(
    <>
    <Header />
   <div className="dashboard">
    <div className="container">
        <div className="row mx-0">
        <DashboardAsWorker/>
            <div className="col-md-9" id="mb_bottom_margin">
             <div className="vendors_right_form">
              <div className="row mx-0">
                <div className="col-md-6">
                  <div className="vendors_form">
                  <div className="row mx-0">
                    <div className="col-md-6 col-6 pl-0">
                     <h1>Daily Revenue</h1>
                    </div>
                    <div className="col-md-6 col-6">
                       <div className="conaitner-view-details">
                        <button className="button view-details">View All</button>
                        <button className="button view-detail1">View All</button>
                       </div>
                    </div>
                    <ReactApexChart options={chartOptions} series={seriesData} type="area" height={350} />
                  </div>
                  </div>
                </div>
                <div className="col-md-6">
                <div className="vendors_form">
                  <div className="row mx-0 px-0">
                    <div className="col-md-6">
                     <h1>Monthly Revenue</h1>
                    </div>
                    <div className="col-md-6">
                       <div className="conaitner-view-details">
                        <button className="button view-details">View All</button>
                        <button className="button view-detail1">View All</button>
                       </div>
                    </div>
                    <ReactApexChart options={chartOptions} series={seriesData} type="area" height={350} />
                  </div>
                  </div>
                </div>
              </div>

              <div className="row mx-0">
                <div className="col-md-12 pr-0">
                  <ul className="vendor_dashboard_view">
              

                    <li>
                    <Link to="/worker/profile">
                      <div className="vendor_dashbaord_box_view">
                      <img src={process.env.PUBLIC_URL + "/vendor_dashboard/user.png"} alt="right_qutation" />
                        <p>Profile</p>
                      </div>
                      </Link>
                    </li>
                                        

                    <li>
                                 
                  <Link to="/worker/revenue"> 
                      <div className="vendor_dashbaord_box_view">
                      <img src={process.env.PUBLIC_URL + "/vendor_dashboard/card.png"} alt="right_qutation" />
                        <p>Revenue Records</p>
                      </div>
                      </Link>
                    </li>
      
                    <li>
                        
                    <Link to="/worker/orderhistory">
                      <div className="vendor_dashbaord_box_view">
                      <img src={process.env.PUBLIC_URL + "/vendor_dashboard/delete.png"} alt="right_qutation" />
                        <p>Orders History</p>
                      </div>
                      </Link>
                    </li>
              
           
                 <li>
                 <Link to='/worker/services'>
                      <div className="vendor_dashbaord_box_view">
                      <img src={process.env.PUBLIC_URL + "/vendor_dashboard/box.png"} alt="right_qutation" />
                        <p>Services</p>
                      </div>
                      </Link>
                    </li>
            
          
                    <li>
                    <Link to='/worker/chats'>
                      <div className="vendor_dashbaord_box_view">
                      <img src={process.env.PUBLIC_URL + "/vendor_dashboard/message.png"} alt="right_qutation" />
                        <p>Chats</p>
                      </div>
                      </Link>
                    </li>
           
                  </ul>
                </div>
              </div>
             </div>
            </div>
        </div>
    </div>
   </div>
    <Footer />
    </>
);
};

export default WorkerDashboard;
