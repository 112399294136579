import React, { useCallback, useEffect, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import axios from "axios";
import { apiBaseUrl, homePageApiUrl, sevicePageData } from "../config/constant";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ServicePage = () => {
  const p_service = JSON.parse(localStorage.getItem("p_servicePageService"));
  const sp_pageData = JSON.parse(localStorage.getItem("sp_servicePageData"));
  const sp_bannerImageData = JSON.parse(localStorage.getItem("sp_bannerImage"));
  const [services, setServices] = useState(p_service ? p_service : []);
  const [pageData, setPageData] = useState(sp_pageData ? sp_pageData : []);
  const [bannerImage, setBannerImage] = useState(
    sp_bannerImageData ? sp_bannerImageData : ""
  );

  async function fetchData() {
    try {
      const response = await axios.post(apiBaseUrl + homePageApiUrl, null, {
        headers: {
          key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
        },
      });
      setServices(response.data.response.result.services);
      localStorage.setItem(
        "p_servicePageService",
        JSON.stringify(response?.data?.response?.result?.services)
      );
    } catch (error) {
      console.error(error);
    }
  }
  //Get CMS Data
  async function fetchPageData() {
    try {
      const response = await axios.get(apiBaseUrl + sevicePageData, {
        headers: {
          key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
        },
      });
      setPageData(response.data.response.result);
      localStorage.setItem(
        "sp_servicePageData",
        JSON.stringify(response?.data?.response?.result)
      );
      setBannerImage(response.data.response.result.banner[0]?.image);
      localStorage.setItem(
        "sp_bannerImage",
        JSON.stringify(response?.data?.response?.result?.banner[0]?.image)
      );
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
    fetchPageData();
  }, []);
  return (
    <>
      <Header />
      <div
        className="contact_us"
        style={{ backgroundImage: `url(${bannerImage})` }}
      >
        <div className="container">
          <div className="row mx-0 ">
            <div className="col-md-12 col-lg-5">
              <div className="about_banner_content">
                {pageData?.banner?.length && (
                  <>
                    <h1>
                      {pageData.banner[0].title[0]}
                      <br />
                      {pageData.banner[0].title[1]}
                      <br />
                      <span> "{pageData.banner[0].title[2]}" </span>{" "}
                      {pageData.banner[0].title[3]}
                    </h1>
                    <p>
                      {pageData.banner[0].title[4]}
                      <br />
                      {pageData.banner[0].title[5]}
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-8"></div>
          </div>
        </div>
      </div>

      {pageData?.pageheadings?.length > 0 && (
        <>
          <div className="inner_section mt-3 mb-1">
            <div className="container">
              <div className="row mx-0">
                <div className="col-md-12">
                  <h1>
                    Services <span> We Serve</span>{" "}
                  </h1>
                  <p>{pageData.pageheadings[0].heading[0].description}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="container service_details">
        <div className="row  mb_margin">
          {services.map((item, index) => (
            <div className="col-md-4 col-lg-3 col-sm-12 mt-4 mb-3" key={index}>
              <div className="most-popular-service-card">
                <Link to={`/user_service_list/${item._id}`}>
                  <LazyLoadImage
                    src={item.service_image}
                    alt="most-popular service card"
                  />
                  <div className="d-flex">
                    <div className="card-mps">
                      <LazyLoadImage
                        src={item.icon}
                        alt="most-popular service card"
                      />
                    </div>
                    <h3>{item.service_name}</h3>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <section className="mb-0 mt-0">
        <div className="download_aap">
          <div className="container">
            <div className="row mx-0">
              <div className="col-md-3 col-lg-3 col-xl-3">
                <LazyLoadImage
                  src={process.env.PUBLIC_URL + "./app_img.png"}
                  alt="app"
                  className="app_img_upload"
                />
              </div>
              <div className="col-md-12 col-lg-12 col-xl-5 pt-4">
                <h1>Download the app</h1>
                {pageData?.pageheadings?.length > 0 && (
                  <p>{pageData?.pageheadings[0].heading[1]?.description}</p>
                )}
              </div>
              <div className="col-md-12 col-lg-12 col-xl-4">
                <ul>
                  <li>
                    {" "}
                    <LazyLoadImage
                      src={process.env.PUBLIC_URL + "./images/google_pay.png"}
                      alt="app"
                      className="app_img"
                    />{" "}
                  </li>
                  <li>
                    {" "}
                    <LazyLoadImage
                      src={process.env.PUBLIC_URL + "./images/btns_apple.png"}
                      alt="app"
                      className="app_img"
                    />{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ServicePage;
