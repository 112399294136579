import { useState, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import LeftnavbarIndividual from "../../components/leftnavbaraIndividual";
import { setSession, getSession} from "../../utils/helper";
import axios from 'axios';
import { Link,useNavigate} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { apiBaseUrl } from "../../config/constant";
// Other imports...

const accept_order = async (id) => {
  
  try {
    let token = getSession("token");
    
    const response = await axios.post(
      apiBaseUrl+'order/job-accept',
      {
        jobId:id, 
      },
      {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      }
    );
    window.location.reload();
    console.log(response.data.response.result);
  } catch (error) {
    console.error(error);
  }
};

const Individuallasttesttask = () =>{
  const [reject,setreject] = useState(false);
  const[reject_id,setreject_id] = useState(null); 
const [activeButton, setActiveButton] = useState('');
const [processid,setId_process] = useState({
  jobId: "",
  rejectReason: "",
});
const handleButtonClick = (buttonName) => {
  setActiveButton(buttonName);
};

const handleClose = () =>{
   
  setPaymentSelection(false);
  setsuccess(false);


}
const [textareaValue, setTextareaValue] = useState(''); 
const navigate = useNavigate();
const [reject_list,setreject_list] = useState('');
const [selectedItem, setSelectedItem] = useState(null);  


  useEffect(() => {
    const fetchData = async () => {
      
      try {
        let token = getSession("token");
       
        const response = await axios.post(
          apiBaseUrl+'order/get-provider-job-list',
          {
          // id: id
          }, 
          {
            headers: {
              'Authorization': token,
              'Content-Type': 'application/json'
            }
          }
        );
        const rejectJobs = response.data.response.result.filter(job => job.job_status === 'Processing');

console.log(rejectJobs)
       
       setreject_list(rejectJobs);
        
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, []);

  const viewdetails = (order) =>{
    console.log(order);
     setSelectedItem(order);
     navigate('/Individual/Individuallatest_detail', {state:order});
    }
   

    const reject_order = async (processid) =>{
      alert(processid);
      try {
        let token = getSession("token");
        
        const response = await axios.post(
          apiBaseUrl+'order/job-reject',
          {
            jobId:processid,
            rejectReason:textareaValue, 
          },
    
         
          {
            headers: {
              'Authorization': token,
              'Content-Type': 'application/json'
            }
          }
        );
        if (response.data && response.data.status && response.data.status === true) {
          window.location.reload();
          // navigate('/individual/orderhistory');
          setData(response.data.response.result);
          console.log(response.data.response.result);
        } else {
          
          console.log(response.data);
          
          alert("Job verification code is wrong. Please try again.");
        }
        console.log(response.data.response.result);
      } catch (error) {
        console.error(error);
      } 
    }

    const reject_popup = (id) =>{
      setId_process(id);
      alert(id);
      setreject(true);
    
    
    } 
    return(
        <>
        <Header />
   <div className="dashboard">
    <div className="container">
        <div className="row mx-0">
        <LeftnavbarIndividual></LeftnavbarIndividual>
            <div className="col-md-9">
             <div className="vendors_right_form">
          
               <div className="row  mx-0">
                <div className="col-md-4">
                <p className="sub_heading">Order History</p>
                  <p className="main_heading">Your Order Records & Details.</p>
                </div>
                <div className="col-md-8">
                <ul className="user_list">
      <li>
        <div className="latest_container">
          <button
            className={`button latest_btn ${activeButton === 'latest_btn' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('latest_btn')}
          >
            <Link to="/individual/latesttasklist">Latest ( New )</Link>
          </button>
          <button
            className={`button latest_buttn ${activeButton === 'latest_buttn' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('latest_buttn')}
          >
            <Link to="/individual/latesttasklist">Latest ( New )</Link>
          </button>
        </div>
      </li>
      <li>
        <div className="assigned_container">
          <button
            className={`button Assigned_btn ${activeButton === 'Assigned_btn' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('Assigned_btn')}
          >
            <Link to="/individual/IndividualToday">Today</Link>
          </button>
          <button
            className={`button Assigned_button ${activeButton === 'Assigned_button' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('Assigned_button')}
          >
            <Link to="/individual/IndividualToday">Today</Link>
          </button>
        </div>
      </li>
      <li>
        <div className="complete_container">
          <button
            className={`button Completed ${activeButton === 'Completed' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('Completed')}
          >
            <Link to="/individual/Individualcompeleorder">Completed</Link>
          </button>
          <button
            className={`button Completed_button ${activeButton === 'Completed_button' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('Completed_button')}
          >
            <Link to="/individual/Individualcompeleorder">Completed</Link>
          </button>
        </div>
      </li>
      <li>
        <div className="reject_container">
          <button
            className={`button reject_btn ${activeButton === 'reject_btn' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('reject_btn')}
          >
            <Link to="/individual/Individualreject">Rejected</Link>
          </button>
          <button
            className={`button Reject_button ${activeButton === 'Reject_button' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('Reject_button')}
          >
            <Link to="/individual/Individualreject">Rejected</Link>
          </button>
        </div>
      </li>
    </ul>
              
                </div>
               </div>


               <div className="row  mx-0">
               {reject_list && reject_list.map((order,index) => (
                <div className="col-md-6" key={index}>
              
                  <div className="book_slot">
                  <div className="border_bottom row mx-0">
                    <div className="col-md-6 col-6">
                      <div className="date_booking">
                        <p>Booking Slot Date</p>
                        <h1>{order.service_date}</h1>
                        </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="date_booking">
                          <p>Booking Slot Time</p>
                            <h1>{order.service_time}</h1>
                          </div>
                        </div>
                      </div>
                      <div className="border_bottom row mx-0">
                        <div className="date_booking">
                       <p>Address</p>
                       <h1>
                      {order?.service_location?.address1}, {order?.service_location?.address2},
                      {order?.service_location?.land_mark}, {order?.service_location?.city},
                      {order?.service_location?.state}, {order?.service_location?.country}
                    </h1>
                       </div>
                      </div>
                      <div className="border_bottom row mx-0">
                    <div className="col-md-6 col-6">
                      <div className="date_booking ">
                        <p className="postpayment">Payment</p>
                        <h2>{order.amount}-/</h2>
                        </div>
                        </div>
                        <div className="col-md-6 col-6 px-0">
                          <div className="prepaid">
                          {order.paymentStatus === "success" ? (
    <button className="pre-paid">Prepaid Payment</button>
  ) : (
    <button className="pre-paid">Postpaid Payment</button>
  )}
                          </div>
                        </div>
                      </div>
                      <div className="border_bottom row mx-0">

  <>
  <div className="border_bottom row mx-0">
                      {order.job_status === "Processing" && (
  <>
    <div className="col-md-4 col-4 px-0">
      <div className="assign-worker-container" onClick={() => accept_order(order._id)}>
        <button className="button assign-worker">Accept</button>
        <button className="button assign-worker1">Accept</button>
      </div>
    </div>
    <div className="col-md-4 col-4 px-0">
      <div className="assign-viewer-container" onClick={() => reject_popup(order._id)}>
        <button className="button assign-View-Detail">Reject</button>
        <button className="button assign-View-Detail1">Reject</button>
      </div>
    </div>
    <div className="col-md-4 col-4 px-0">
      <div className="assign-viewer-container"  onClick={()=> viewdetails(order)}>
        <button className="button assign-View-Detail"><Link to="/Individual/Individuallatest_detail"> View Detail </Link></button>
        <button className="button assign-View-Detail1"><Link to="/Individual/Individuallatest_detail"> View Detail </Link></button>
      </div>
      </div>
  </>
)}






                      </div>
  </>


                      </div>
                  </div>
                    
                </div>
                ))}
               </div>

           
             </div>
            </div>
        </div>
    </div>
   </div>
    <Footer />
    <Modal 


show={reject}
         onHide={handleClose}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose}>
            <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
          

            <div className="congrats-profile-created  well_done" style={{marginTop:"0px"}}>
           
         <p>Reason For Reject</p>
              
              <textarea    value={textareaValue} onChange={(e) => setTextareaValue(e.target.value)} className="add_reasons"></textarea>
              <div class="button-container mt-3" style={{ width: "150px" }}   onClick={() => reject_order(processid)}>
                <button className="button button1 s_btn" > <Link style={{ textDecoration: "none",color: "#fff !important"}} to='/individual/orderhistory'> Submit
                  </Link></button>
                <button className="button button2">
                  <Link style={{ textDecoration: "none", color: "#ffffff" }} to='/individual/orderhistory'>Submit
                  </Link>
                </button>
              </div>
          
            </div>
          </Modal.Body>
        </Modal> 
        </>
    );
};

export default Individuallasttesttask;
