import Footer from "../../components/footer";
import Header from "../../components/header";
import { Link } from "react-router-dom";
import './dashboard.css';
import React, { useState, useEffect } from 'react';
import Dashbordslider from "../../slider/dashbordslider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar} from '@fortawesome/fontawesome-free-solid';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';


const Complete_order = ()=>{
   const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    return(
     <>
     <Header />
     
     <div className="dashboard">
    <div className="container">
        <div className="row">
            <div className="col-md-3">
             <div className="user_profile">


             {/* <div>
      <svg width="120" height="120">
        <circle
          cx="60"
          cy="60"
          r="50"
          stroke="#e6e6e6"
          strokeWidth="10"
          fill="none"
        />
        <circle
          cx="60"
          cy="60"
          r="50"
          stroke="#4287f5"
          strokeWidth="10"
          fill="none"
          strokeDasharray={`${progress}, 314`}
        />
      </svg>
    </div> */}
            <div className="row mx-0">
                <div className="col-3">
                <img src={process.env.PUBLIC_URL + "../circle_user.png"} alt="app"  className="app_img" />
                </div>
                <div className="col-9 px-0">
                    <h1>Harpreet Kaur</h1>
                    <p><span> <img src={process.env.PUBLIC_URL + "../mobile.png"} alt="app"  className="app_img" /></span>+917982691137</p>
                    <p><span> <img src={process.env.PUBLIC_URL + "../mail.png"} alt="app"  className="app_img" /></span>harpreet.kaur@algosoft.co</p>
                </div>
            </div>
               
             </div>
             <div className="user_listing">
                <h1>Account Information</h1>
                <ul>
                    <li><div><Link>My Profile</Link> </div></li>
                    <li> <div><Link>Order History</Link> </div></li>
                    <li><div> <Link>My Addresses</Link></div></li>
                    <li><div><Link>Chats</Link></div></li>
                    <li><div><Link>Feedbacks</Link></div></li>
                    <li><div><Link>Logout</Link></div></li>
                </ul>
            </div>
            </div>
            <div className="col-md-9">
            <div className="row">
                    <div className="col-ms-12">
                        <h1 className="my_order">My Orders </h1>
                        <h2 className="order_details">Order Details</h2>
                    </div>
                </div>
             <div className="right_side  mt-3">
               <div className="row">
                <div className="col-8">
                  <div className="btn_es_align">
                  <div className="order_detail">
                    <button className="button button_orderdetails_one">Order Detail</button>
                    <button className="button button_orderdetails--hover">Order Detail</button>
                    </div>
                    <div  className="rate_container" onClick={handleShow}>
                    <button className="button button_rate">Rate</button>
                    <button className="button button_rate--hover">Rate</button>
                    </div>
                    <div className="cancel_book">
                    <button className="button button_cancel_book">Re-Order</button>
                    <button className="button button_cancel--hover">Re-Order</button>
                    </div>
                  </div>

                  <div className="vertification_code">
                        <p className="">Verification Code</p>
                        <div className="numberes">
                        <input type="text" placeholder="6"/>
                        <input type="text" placeholder="7"/>
                        <input type="text" placeholder="8"/>
                        <input type="text" placeholder="1"/>
                        <input type="text" placeholder="0"/>
                        <input type="text" placeholder="2"/>
                        </div>
                    </div>
                    <div className="card_details">
                        <div className="card_heading">
                            <h1>Service Location</h1>
                        </div>
                        <div className="card_content">
                          
                            <span><img src={process.env.PUBLIC_URL + "../images/card_location.png"} alt="app"  /> 
                            
                                <p>Gwarinpa-900108, Abuja, Federal Capital Territory,Nigeria</p></span>
                          
                        </div>
                    </div>
                    <div className="card_details">
                        <div className="card_heading">
                            <h1>Selected Provider</h1>
                        </div>
                        <div className="card_content">
                        <div className="select_provider">   
                            <div><img src={process.env.PUBLIC_URL + "../images/worker.png"} alt="app"  className="worker_img"/> </div>
                            <div className="select_provider_content">
                                <h1>Benjamin</h1>  
                                <p>Gwarinpa-900108, Abuja, Federal Capital Territory,Nigeria</p>
                               
                                </div>
                          
                          <div>
                            <span><img src={process.env.PUBLIC_URL + "../images/Star.png"} className="star_img"/><p>4.6</p></span>
                          </div>
                          </div>
                        </div>
                    </div>
                    <div className="card_details">
                        <div className="card_heading">
                            <h1>Slot Date & Timing</h1>
                        </div>
                        <div className="card_content">
                          <ul>
                            <li><span>Service Date</span> <p>04 April, 2023</p></li>
                            <li><span>Service Timing</span> <p>12:00 PM</p></li>
                          </ul>
                        </div>
                    </div>
                    <div className="card_details mb-2">
                        <div className="card_heading">
                            <h1>Slot Date & Timing</h1>
                        </div>
                        <div className="card_content">
                            <h2>Plumber, Sink Repairing </h2>
                            <p className="my-2">It is a long established fact that a read will be distracted by the read able of a page. It is a long established fact that a read will be distracted.</p>
                          <div className="plumbering_img">
                            <img src={process.env.PUBLIC_URL + "../images/plumber.png"} />
                            <img src={process.env.PUBLIC_URL + "../images/plumber.png"} />
                            <img src={process.env.PUBLIC_URL + "../images/plumber.png"} />
                          </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="payment_details">
                     <h1>Payment Detail</h1>
                     <ul className="mt-2"> 
                        <li className="pt-0"><p>Total Amount</p> <span>₦ 920</span></li>
                        <li><p>Discount</p> <span className="text_change_color">₦ 110</span></li>
                        <li><p>Other Charges</p> <span>Free</span></li>
                        <li className="pb-0 bottom_none"><p className="text_change_color">Amount Paid</p> <span className="text_change_color">₦ 810</span></li>
                     </ul>
                    </div>
                    <div className="feed_backes_rates">
                    <h1>Feedback</h1>
                        <div className="feedbackes_border">
                            <div className="feedbacks_users">
                           
                                <div className="">
                                <img src={process.env.PUBLIC_URL + "../images/rating.png"} />
                                <span>4.0</span>
                                </div>
                                <div>
                             
                             
                                    <p>3 days ago</p>
                                </div>
                            </div>
                            <p>It is a long established fact that reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem  is that it has a more-or-less normal distribution of letters, as opposed.</p>
                        </div>
                    </div>
                </div>
               </div>
                
             </div>
            </div>
        </div>
    </div>
   </div>

    <div className="container-fluid other_details px-0">
        <div className="row">
          
            <div className="col-md-3">

            </div>
            <div className="col-md-9 px-0">
            <h1 className="other_Details"> Other Orders</h1>
                <div className="dash_slider">
            <Dashbordslider />
            </div>
            </div>
        </div>
    </div>
              
              
            
  
   <section className="mb-0">
    <div className="download_aap">
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                <img src={process.env.PUBLIC_URL + "../app_img.png"} alt="app"  className="app_img" />
                 
                </div>
                <div className="col-md-6 pt-4">
                    <h1>Download the app</h1>
                    
                    <p>Get the app. Get things done. Compare prices, read reviews and book top-rated services. All in one free app.</p>
                </div>
                <div className="col-md-3">
                   <ul>
                    <li> <img src={process.env.PUBLIC_URL + "../androied.png"} alt="app"  className="app_img" /> </li>
                    <li> <img src={process.env.PUBLIC_URL + "../ios.png"} alt="app"  className="app_img" /> </li>
                    
                 
                   </ul>
                </div>
            </div>
        </div>
    </div>
</section>

     <Footer />
     <Modal show={show} onHide={handleClose} id="model_rating" className=" slideInUp">
    <div className="model_header">
        <div className="header_viewes">
            <h1>Your Valuable Feedback</h1>
        </div>
         <div className="">
         <img src={process.env.PUBLIC_URL + "../images/model_cross.png"} alt="app" className="cross_icon" onClick={handleClose} />
         </div>
        </div>
         
        <Modal.Body className="py-0">
          <Form>
            <div className="row mx-0">
               
                <div className="col-md-12">
                    <div className="text_rating">
                    <p >Rate Us</p>
                    <div className="multiple_stars_content">
                     <ul>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow" /></li>
                        <li><FontAwesomeIcon icon={faStar} className="star_yellow"  /></li>
                        <li><FontAwesomeIcon icon={faStar} className="star" /></li>
                        <li><FontAwesomeIcon icon={faStar} className="star" /></li>
                        <li><FontAwesomeIcon icon={faStar} className="star" /></li>
                     </ul>
                    </div>
                     <textarea></textarea>
                     </div> 
                </div>
                
            </div>
           
          </Form>
        </Modal.Body>
        <Modal.Footer >
            <div className="model_submit" onClick={handleClose}>
          <button  className=" button submit_close">Submit</button>
          <button  className="button submit_close_hover">Submit</button>
          </div>
        </Modal.Footer>
      </Modal>
     </>
    );
}

export default Complete_order;
