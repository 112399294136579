import Header from "../../components/header";
import Footer from "../../components/footer";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import "./privacy.css";
import { apiBaseUrl } from "../../config/constant";

const Term_condition = () =>{
  const p_terms = JSON.parse(localStorage.getItem('page_terms'));
   const [term, setTerm] = useState(p_terms?p_terms:[]);
 
   useEffect(() => {
      console.log(term);
     const fetchData = async () => {
       try {
         const response = await axios.get(
           apiBaseUrl+'common/web-get-termcondition-page-data',
           {
             headers: {
               key: 'd1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed',
               'Content-Type': 'application/json',
             },
           }
         );
   
         setTerm(response.data.response.result.termsCondition);
         localStorage.setItem('page_terms', JSON.stringify(response?.data?.response?.result?.termsCondition))
         
       } catch (error) {
         console.error(error);
       }
     };
   
     fetchData();
   }, [term]);
   
   return (
     <>
       <Header />
       <div className="privacy">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <h1>Terms and Conditions</h1>
        {term.length > 0 && <p>{term[0].title1}</p>}
        <div>
  {term.map((item, index) => {
    return (
      <div className="privacy_policy" key={index}>
        {item.heading.map((headingItem, headingIndex) => (
          <React.Fragment key={headingIndex}>
            <h2>{headingItem?.title ?? 'Title Not Available'}</h2>
            <p>{headingItem?.description ?? 'Description Not Available'}</p>
          </React.Fragment>
        ))}
      </div>
    );
  })}
</div>
      </div>
    </div>
  </div>
</div>

       <Footer />
     </>
   );
                   }   
export default Term_condition;