import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link, useNavigate } from "react-router-dom";
import { setSession, getSession } from "../../utils/helper";
import DashboardAsWorker from "../../components/DashboardAsWorker";
import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/fontawesome-free-solid";
import { validationSchema } from "../../components/validators/paymentFormValidator";
import { apiBaseUrl } from "../../config/constant";
import animationData from "../../animation_wait.json";
import { PaystackButton } from "react-paystack";
import Lottie from "react-lottie";
import {validateJobStartDateAndTime} from "../../components/common";

const WorkerOrderHistory = () => {
  const token = getSession("token");
  const navigate = useNavigate();
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const [activeTab, setActiveTab] = useState("latest");
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [Completed, setCompleted] = useState([]);
  const [reject, setReject] = useState([]);
  const [today, setToday] = useState([]);
  const [success, setsuccess] = useState(false);
  const [success4, setsuccess4] = useState(false);
  const [checkResult, setCheckResult] = useState(true);
  const [jobId, setJobId] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [showReject, setShowReject] = useState(false);
  const [error, setError] = useState("");
  const [locationName, setLocationName] = useState("");
  const [uploadpic, setuploadpic] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [Verifyotp, setverifyotp] = useState(false);
  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
  const [paymentpopup, setpaymentpopup] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [errors, setErrors] = useState({ amount: "", payment_option: "" });
  const [amount, setAmount] = useState();
  const [success2, setSuccess2] = useState(false);
  const [jobStartTimeError, setJobStartTimeError] = useState(false);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // /////////////////////////////////////////////// start job ///////////////////////////////////////
  const [show, setShow] = useState(false);
  const [jodid, setjobid] = useState({
    jobId: "",
    code: "",
  });

  const [payment_selection, setPaymentSelection] = useState({
    id: "",
    paymentStatus: "",
    amount: "",
    discount: "",
    paidAmount: "",
  });

  useEffect(() => {
    setAmount(payment_selection.amount);
  }, [payment_selection.amount]);

  const start_job = async (id) => {
    const isValidate = await validateJobStartDateAndTime(id);
    if(isValidate.status===true){
      setjobid(id);
      setShow(true);
    }else{
      setJobStartTimeError(true);
    }
  };
  const Uploadingfunction = () => {
    setuploadpic(true);
    setShow(false);
  };

  const handleUpload = () => {
    setverifyotp(true);
    setuploadpic(false);
  };
  const handleImageChange2 = (event) => {
    const file = event.target.files[0];
    setSelectedImage2(file);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };
  const handleShow = async (jodid) => {
    const formData = new FormData();
    const enteredOtp = otpDigits.join("");
    formData.append("jobId", jodid);
    formData.append("code", enteredOtp);
    formData.append("first_pic", selectedImage);
    formData.append("second_pic", selectedImage2);

    try {
      let token = getSession("token");
      const response = await axios.post(
        apiBaseUrl + "order/job-start",
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (
        response.data &&
        response.data.status &&
        response.data.status === true
      ) {
        window.location.reload();
        // navigate('/individual/orderhistory');

        setData(response.data.response.result);
      } else {
        alert("Job verification code is wrong. Please try again.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const finishjob_popup = (data = {}) => {
    if (data && data.paymentStatus === "Success") {
      setsuccess4(true);
      setpaymentpopup(false);
    } else {
      setpaymentpopup(true);
      setsuccess4(false);
    }

    setPaymentSelection({
      ...payment_selection,
      ["paymentStatus"]: data.paymentStatus,
      ["amount"]: data.amount,
      ...(data.discount
        ? { ["discount"]: data.discount }
        : { ["discount"]: 0 }),
      ["id"]: data._id,
    });
  };
  const Proceed_pay = async () => {
    try {
      let token = getSession("token");

      const response = await axios.post(
        apiBaseUrl + "order/job-finish",
        {
          jobId: payment_selection.id,
          updateIp: 1,
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      {
        if (response.data.status) {
          setsuccess4(true);
          setpaymentpopup(false);
        } else {
          setsuccess4(false);
          setpaymentpopup(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  ////////////////////////////////////////////end job /////////////////////////////////////////////////////

  // //////////////////////////////////// order history by MD ////////////////////////////////

  const handleOtpInputChange = (index, value) => {
    const newOtpDigits = [...otpDigits];
    newOtpDigits[index] = value;
    setOtpDigits(newOtpDigits);

    if (index < inputRefs.length - 1 && value !== "") {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleClose = () => {
    // setShow(false);
    setSuccess2(false);
    setverifyotp(false);
    // setuploadpic(false);
    // setPaymentSelection(false);
    setsuccess(false);
    setShowReject(false);
    window.location.reload();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          apiBaseUrl + "order/worker-job-list",
          {
            order_search: "",
            limit: "",
            skip: "",
          },
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        setData2(response.data.response.result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const fetchHistoryData = (order_search) => {
    return axios.post(
      apiBaseUrl + "order/worker-job-list",
      {
        order_search: order_search,
        limit: "",
        skip: "",
      },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
  };

  useEffect(() => {
    // Fetch data for "Completed"

    fetchHistoryData();
    fetchHistoryData("Reject")
      .then((response) => {
        setReject(response.data.response.result);

        // After Completed data is fetched, fetch data for "Reject"
        return fetchHistoryData("Today");
      })
      .then((response) => {
        setToday(response.data.response.result);
        // After Reject data is fetched, fetch data for "Assigned"
        return fetchHistoryData("Complete");
      })
      .then((response) => {
        setCompleted(response.data.response.result);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const AcceptJob = async (id) => {
    try {
      const response = await axios.post(
        apiBaseUrl + "order/job-accept",
        {
          jobId: id,
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const RejectJob = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl + "order/job-reject",
        {
          jobId: jobId,
          rejectReason: rejectReason,
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAcceptJob = (order) => {
    if (order.job_status === "Assign") {
      AcceptJob(order._id)
        .then((data) => {
          if (data.status === true) {
            setsuccess(true);
          }
        })
        .catch((error) => {
          // Handle error, if needed
          console.error("Error:", error);
        });
    } else if (order.job_status === "Accept") {
      start_job(order);
    } else if (order.job_status === "Start") {
      finishjob_popup(order);
      // setpaymentpopup(true)
    }
  };

  const handleSubmitRejectJob = () => {
    setCheckResult(false);
    RejectJob()
      .then((data) => {
        if (data.status === true) {
          setsuccess(true);
        }
      })
      .catch((error) => {
        // Handle error, if needed
        console.error("Error:", error);
      });
  };

  const handleRejectJob = (id) => {
    setShowReject(true);
    setJobId(id);
  };

  const handleItemClick = (item, id) => {
    if (id === 1) {
      navigate("/worker/latest_details", { state: item });
    } else if (id === 2) {
      navigate("/worker/today_details", { state: item });
    } else if (id === 4) {
      navigate("/worker/latest_details", { state: item });
    }
  };
  // ----------------------------------------------------------------

  ///////////////////////////////////////end order history /////////////////////////////////

  ////////////////////////////onilne payment //////////////////////////////////
  const [updatePayment, setUpdatePayment] = useState({
    jobId: "",
    finalPaidAmount: "",
    paymentMode: "Online",
    paymentStatus: "initialized",
    updateIp: ":1",
  });

  // console.log(updatePayment, "updatePayment");

  const callPaymentGetApi = async () => {
    const updatePayment = {
      jobId: payment_selection.id,
    };
    try {
      const response = await axios.post(
        apiBaseUrl + "order/get-job-details",
        updatePayment,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(
        "________________________________________",
        response.data.response.result.paymentStatus
      );
      const data = response.data.response.result;
      // setPaymentData(data)
      // if(data.paymentStatus==="Success"){

      if (data.paymentStatus === "Success") {
        setSuccess2(false);
        Proceed_pay();
      }
      // }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  // useEffect(()=>{
  const callPaymentUpdateApi = async (value) => {
    try {
      const response = await axios.post(
        apiBaseUrl + "order/payment-update",
        updatePayment,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      if (value === "cash") {
        console.log(updatePayment);
        alert("inside");
        // Proceed_pay();
      } else {
        if (response?.data?.status === true) {
          setpaymentpopup(false);
          setSuccess2(true);
        }
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };
  // callPaymentUpdateApi();
  // },[updatePayment])

  const validateField = (fieldName, value) => {
    try {
      validationSchema.validateSyncAt(fieldName, { [fieldName]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handlePaymentChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setErrors({});
    if (name === "amount") {
      setAmount(value);
    }
    if (name === "payment_option") {
      if (value === "Cash") {
        setSelectedPayment(value);
      } else if (value === "Online") {
        setSelectedPayment(value);
        setUpdatePayment((prevSelection) => ({
          ...prevSelection,
          finalPaidAmount: amount,
          jobId: payment_selection.id,
        }));
      }
    }
    // console.log(event.target.value);
  };

  // console.log(payment_selection,"__________________________-")
  const handleSubmitPayment = (event) => {
    event.preventDefault();

    validateField("amount", amount);
    validateField("payment_option", selectedPayment);
    if (selectedPayment === "Cash") {
      const value = "cash";
      callPaymentUpdateApi(value);
    }
    if (updatePayment) {
      callPaymentUpdateApi();
    }
    // Additional submission logic if the form is valid
    if (!errors.amount && !errors.payment_option) {
      console.log("Form submitted with valid values");
    }
  };
  ////////////////////////////End payment //////////////////////////////////

  return (
    <>
      <Header />
      <div className="dashboard">
        <div className="container">
          <div className="row mx-0">
            <DashboardAsWorker />
            <div className="col-md-9">
              <div className="vendors_right_form">
                <div className="row  mx-0">
                  <div className="col-md-4">
                    <p className="sub_heading">Your Order Records & Details.</p>
                  </div>
                  <div className="col-md-8">
                    <ul className="user_list">
                      <li>
                        <div className="latest_container">
                          <button
                            className={`button latest_btn ${
                              activeTab === "latest" ? "active_latest" : ""
                            }`}
                            onClick={() => handleTabClick("latest")}
                          >
                            Latest (New)
                          </button>
                          <button
                            className={`button latest_buttn ${
                              activeTab === "latest" ? "active_latest" : ""
                            }`}
                            onClick={() => handleTabClick("latest")}
                          >
                            Latest (New)
                          </button>
                        </div>
                      </li>
                      <li>
                        <div className="assigned_container">
                          <button
                            className={`button Assigned_btn ${
                              activeTab === "today" ? "active_latest" : ""
                            }`}
                            onClick={() => handleTabClick("today")}
                          >
                            Today
                          </button>
                          <button
                            className={`button Assigned_button ${
                              activeTab === "today" ? "active_latest" : ""
                            }`}
                            onClick={() => handleTabClick("today")}
                          >
                            Today
                          </button>
                        </div>
                      </li>
                      <li>
                        <div className="complete_container">
                          <button
                            className={`button Completed ${
                              activeTab === "completed" ? "active_latest" : ""
                            }`}
                            onClick={() => handleTabClick("completed")}
                          >
                            Completed
                          </button>
                          <button
                            className={`button Completed_button ${
                              activeTab === "completed" ? "active_latest" : ""
                            }`}
                            onClick={() => handleTabClick("completed")}
                          >
                            Completed
                          </button>
                        </div>
                      </li>
                      <li>
                        <div className="reject_container">
                          <button
                            className={`button reject_btn ${
                              activeTab === "reject" ? "active_latest" : ""
                            }`}
                            onClick={() => handleTabClick("reject")}
                          >
                            Rejected
                          </button>
                          <button
                            className={`button Reject_button ${
                              activeTab === "reject" ? "active_latest" : ""
                            }`}
                            onClick={() => handleTabClick("reject")}
                          >
                            Rejected
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div id="tabContent">
                  {activeTab === "latest" && (
                    <div className="tabContent">
                      {" "}
                      <div className="row  mx-0">
                        {data2.map((order, index) => (
                          <div className="col-md-6">
                            <div className="book_slot">
                              <div className="border_bottom row mx-0">
                                <div className="col-md-6 col-6">
                                  <div className="date_booking">
                                    <p>Booking Date</p>
                                    <h1>{order.service_date}</h1>
                                  </div>
                                </div>
                                <div className="col-md-6 col-6">
                                  <div className="date_booking">
                                    <p>Booking Time</p>
                                    <h1>{order.service_time}</h1>
                                  </div>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                                <div className="date_booking">
                                  <p>Address</p>
                                  <h1>
                                    {order.service_location?.address1},{" "}
                                    {order.service_location?.address2},
                                    {order.service_location?.land_mark},{" "}
                                    {order.service_location?.city},
                                    {order.service_location?.state},{" "}
                                    {order.service_location?.country}
                                  </h1>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                                <div className="col-md-6 col-6">
                                  <div className="date_booking ">
                                    <p className="postpayment">Payment</p>
                                    <h2>₦{order.amount}/-</h2>
                                  </div>
                                </div>
                                <div className="col-md-6 col-6 px-0">
                                  <div className="prepaid">
                                    {order.paymentStatus === "success" ? (
                                      <button className="pre-paid">
                                        Prepaid Payment
                                      </button>
                                    ) : (
                                      <button className="pre-paid">
                                        Postpaid Payment
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0 d-flex justify-content-around">
                                {order.job_status === "Complete" ||
                                order.job_status === "Reject" ? (
                                  <div className="col-md-4 col-4 px-0">
                                    <div className="assign-worker-container">
                                      <button className="button assign-workr blur-effect">
                                        {" "}
                                        {order.job_status === "Complete"
                                          ? " Completed "
                                          : "Rejected"}
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <div className="col-md-4 col-4 px-0">
                                      <div className="assign-worker-container">
                                        <button
                                          className="button assign-worker"
                                          onClick={() => {
                                            handleAcceptJob(order);
                                          }}
                                        >
                                          {order.job_status === "Assign"
                                            ? "Accept"
                                            : order.job_status === "Accept"
                                            ? "Start Job" 
                                            : "Job Finish"}
                                        </button>
                                        <button
                                          className="button assign-worker1"
                                          onClick={() => {
                                            handleAcceptJob(order);
                                          }}
                                        >
                                          {order.job_status === "Assign"
                                            ? "Accept"
                                            : order.job_status === "Accept"
                                            ? "Start Job"
                                            : "Job Finish"}
                                        </button>
                                      </div>
                                    </div>
                                    {order.job_status === "Start" ||
                                    order.job_status === "Accept" ||
                                    order.job_status === "Reject" ? (
                                      ""
                                    ) : (
                                      <div className="col-md-4 col-4 px-0">
                                        <div className="assign-viewer-container">
                                          <button
                                            className="button assign-View-Detail"
                                            onClick={() => {
                                              handleRejectJob(order._id);
                                            }}
                                          >
                                            Reject
                                          </button>
                                          <button
                                            className="button assign-View-Detail1"
                                            onClick={() => {
                                              handleRejectJob(order._id);
                                            }}
                                          >
                                            Reject
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                                <div className="col-md-4 col-4 px-0">
                                  <div className="assign-viewer-container">
                                    <button
                                      className="button assign-View-Detail"
                                      onClick={() => handleItemClick(order, 1)}
                                    >
                                      View Details
                                    </button>
                                    <button
                                      className="button assign-View-Detail1"
                                      onClick={() => handleItemClick(order, 1)}
                                    >
                                      View Details
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {activeTab === "today" && (
                    <div className="tabContent">
                      <div className="row">
                        {today.map((order, index) => (
                          <div className="col-md-6">
                            <div className="book_slot">
                              <div className="border_bottom row mx-0">
                                <div className="col-md-6 col-6">
                                  <div className="date_booking">
                                    <p>Booking Slot Date</p>
                                    <h1>04 January 2022</h1>
                                  </div>
                                </div>
                                <div className="col-md-6 col-6">
                                  <div className="date_booking">
                                    <p>Booking Slot Time</p>
                                    <h1>04:00 PM</h1>
                                  </div>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                                <div className="date_booking">
                                  <p>Address</p>
                                  <h1>
                                    {order.service_location?.address1},{" "}
                                    {order.service_location?.address2},
                                    {order.service_location?.land_mark},{" "}
                                    {order.service_location?.city},
                                    {order.service_location?.state},{" "}
                                    {order.service_location?.country}
                                  </h1>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                                <div className="col-md-6 col-6">
                                  <div className="date_booking ">
                                    <p className="postpayment">Payment</p>
                                    <h2>#{order.amount}-/</h2>
                                  </div>
                                </div>
                                <div className="col-md-6 col-6 px-0">
                                  <div className="prepaid">
                                    {order.paymentStatus === "success" ? (
                                      <button className="pre-paid">
                                        Prepaid Payment
                                      </button>
                                    ) : (
                                      <button className="pre-paid">
                                        Postpaid Payment
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                                <div className="col-md-4 col-4 px-0">
                                  <div
                                    className="assign-worker-container"
                                    onClick={() => start_job(order)}
                                  >
                                    <button className="button assign-worker">
                                      Start Job
                                    </button>
                                    <button className="button assign-worker1">
                                      Start Job
                                    </button>
                                  </div>
                                </div>
                                <div className="col-md-4 col-4 px-0">
                                  <div className="assign-viewer-container">
                                    <button
                                      className="button assign-View-Detail"
                                      onClick={() => {
                                        handleRejectJob(order._id);
                                      }}
                                    >
                                      {" "}
                                      Reject{" "}
                                    </button>
                                    <button
                                      className="button assign-View-Detail1"
                                      onClick={() => {
                                        handleRejectJob(order._id);
                                      }}
                                    >
                                      {" "}
                                      Reject{" "}
                                    </button>
                                  </div>
                                </div>
                                <div className="col-md-4 col-4 px-0">
                                  <div className="assign-viewer-container">
                                    <button
                                      className="button assign-View-Detail"
                                      onClick={() => handleItemClick(order, 2)}
                                    >
                                      {" "}
                                      View Detail{" "}
                                    </button>
                                    <button
                                      className="button assign-View-Detail1"
                                      onClick={() => handleItemClick(order, 2)}
                                    >
                                      {" "}
                                      View Detail{" "}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {activeTab === "completed" && (
                    <div className="tabContent">
                      <div className="row">
                        {Completed.map((item, index) => (
                          <div className="col-md-6" key={index}>
                            <div className="assign_task">
                              <div className="border_bottom row mx-0">
                                <div className="col-md-6 col-6">
                                  <div className="date_booking">
                                    <p>Booking Slot Date</p>
                                    <h1>{item.service_date}</h1>
                                  </div>
                                </div>
                                <div className="col-md-6 col-6">
                                  <div className="date_booking">
                                    <p>Booking Slot Time</p>
                                    <h1>{item.service_time}</h1>
                                  </div>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                                <div className="date_booking">
                                  <p>Address</p>
                                  <h1>
                                    {" "}
                                    {item.service_location?.address1},{" "}
                                    {item.service_location?.address2},
                                    {item.service_location?.land_mark},{" "}
                                    {item.service_location?.city},
                                    {item.service_location?.state},{" "}
                                    {item.service_location?.country}
                                  </h1>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                                <div className="col-md-7 col-7">
                                  <div className="date_booking ">
                                    <p className="postpayment">Payment</p>
                                    <h2>#{item.paidAmount}-/</h2>
                                  </div>
                                </div>
                                <div className="col-md-5 col-5 px-0">
                                  <button className="complete_prepaid">
                                    {item.paymentStatus}
                                  </button>
                                </div>
                              </div>
                              <div className="full_border row mx-0">
                                <div className="col-md-12 col-12">
                                  <div className="row mx-0">
                                    <div className="col-md-2">
                                      <img
                                        src={
                                          BaseUrl +
                                          item.providerData.profile_pic
                                        }
                                      />
                                    </div>
                                    <div className="col-md-7">
                                      <h1>{item.providerData.fullName}</h1>
                                      <p>{item.providerData.address1}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="user_rating">
                                        <FontAwesomeIcon
                                          icon={faStar}
                                          className="star_yellow"
                                        />
                                        <span>4.6</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                                {/* <div className="col-md-6 col-6 px-0">
                        <div className="assign-worker-container">
                          <button className="button assign-worker">Assign To Worker</button>
                          <button className="button assign-worker1">Assign To Worker</button>
                        </div>
                        </div> */}
                                <div className="col-md-12 col-12 px-0">
                                  <div className="assign-viewer-container">
                                    <button className="button assign-View-Detail">
                                      <Link to="/vendor/completed_order_detail">
                                        {" "}
                                        View Detail{" "}
                                      </Link>
                                    </button>
                                    <button className="button assign-View-Detail1">
                                      <Link to="/vendor/completed_order_detail">
                                        {" "}
                                        View Detail{" "}
                                      </Link>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {activeTab === "reject" && (
                    <div className="tabContent">
                      <div className="row">
                        {reject.map((item, index) => (
                          <div className="col-md-6">
                            <div className="assign_task">
                              <div className="border_bottom row mx-0">
                                <div className="col-md-6 col-6">
                                  <div className="date_booking">
                                    <p>Booking Slot Date</p>
                                    <h1>{item.service_date}</h1>
                                  </div>
                                </div>
                                <div className="col-md-6 col-6">
                                  <div className="date_booking">
                                    <p>Booking Slot Time</p>
                                    <h1>{item.service_time}</h1>
                                  </div>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                                <div className="date_booking">
                                  <p>Address</p>
                                  <h1>
                                    {" "}
                                    {item?.service_location?.land_mark},
                                    {item?.service_location?.address1} ,{" "}
                                    {item?.service_location?.city} ,
                                    {item?.service_location?.state} ,
                                    {item?.service_location?.postal_code} ,
                                    {item?.service_location?.country}
                                  </h1>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                                <div className="col-md-6 col-6">
                                  <div className="date_booking ">
                                    <p className="postpayment">Payment</p>
                                    <h2>{item.amount}-/</h2>
                                  </div>
                                </div>
                                <div className="col-md-6 col-6 px-0">
                                  {/* <button className="complete_prepaid">{item.job_status}</button> */}
                                  {item.paymentStatus === "success" ? (
                                    <button className="complete_prepaid">
                                      Prepaid Payment
                                    </button>
                                  ) : (
                                    <button className="complete_prepaid">
                                      Postpaid Payment
                                    </button>
                                  )}
                                </div>
                              </div>

                              <div className="border_bottom row mx-0">
                                <div className="col-md-12 col-12 px-0">
                                  <div className="assign-viewer-container">
                                    <button
                                      className="button assign-View-Detail"
                                      onClick={() => handleItemClick(item, 4)}
                                    >
                                      {" "}
                                      View Detail{" "}
                                    </button>
                                    <button
                                      className="button assign-View-Detail1"
                                      onClick={() => handleItemClick(item, 4)}
                                    >
                                      {" "}
                                      View Detail{" "}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Time Model */}
      <Modal
        show={jobStartTimeError}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div id="lottie-animation"></div>
          <div
            className="congrats-profile-created"
            style={{ marginTop: "0px", color:"red" }}
          >
            <h5>Job not started after booking date & time.</h5>
          </div>
        </Modal.Body>
      </Modal>
      {/* END */}
      <Modal
        show={success}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created  well_done"
            style={{ marginTop: "0px" }}
          >
            <img
              src={process.env.PUBLIC_URL + "../images/greeting.gif"}
              alt="playstore"
              className="gif"
            />
            {checkResult ? (
              <>
                {" "}
                <h2>Job Accepted!</h2>
                <p>Please Continue Your Work</p>{" "}
              </>
            ) : (
              <h2>Job Rejected!</h2>
            )}

            <div
              class="button-container"
              style={{ width: "150px" }}
              onClick={handleClose}
            >
              <button className="button button1">
                {" "}
                <Link
                  style={{ textDecoration: "none", color: "#ffffff" }}
                  to="/worker/orderhistory"
                >
                  {" "}
                  Proceed To Next
                </Link>
              </button>
              <button className="button button2">
                <Link
                  style={{ textDecoration: "none", color: "#ffffff" }}
                  to="/worker/orderhistory"
                >
                  {" "}
                  Proceed To Next
                </Link>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showReject}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created  well_done"
            style={{ marginTop: "0px" }}
          >
            <p>Reason For Reject</p>

            <textarea
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
              className="add_reasons"
            ></textarea>
            <div
              class="button-container mt-3"
              style={{ width: "150px" }}
              onClick={handleSubmitRejectJob}
            >
              <button className="button button1 s_btn"> Submit</button>
              <button className="button button2">Submit</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ////////////////////////////////////////////////////////////start job //////////////////////////////// */}

      <Modal
        show={show}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div id="lottie-animation"></div>
          <div
            className="congrats-profile-created"
            style={{ marginTop: "0px" }}
          >
            {error ? (
              <p>{error}</p>
            ) : locationName ? (
              <h6>{locationName}</h6>
            ) : (
              <p>
                Geolocation is disabled. Please enable geolocation in your
                browser settings.
              </p>
            )}

            <p>Use your device location to set your current location. </p>
            <div
              class="button-container"
              style={{ width: "200px" }}
              onClick={Uploadingfunction}
            >
              <button className="button button1">Use Current Location</button>
              <button className="button button2">
                <Link style={{ textDecoration: "none", color: "#ffffff" }}>
                  Use Current Location
                </Link>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* //////////////////////upload pic */}
      <Modal
        show={uploadpic}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created"
            style={{ marginTop: "0px" }}
          >
            <h2>Upload Photos</h2>
            <p>Upload 2 selfie images with your service location.</p>
            <div>
              <ul className="images_uploads">
                <li>
                  <div>
                    {selectedImage ? (
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        alt="Selected"
                      />
                    ) : (
                      <div>
                        <label
                          htmlFor="file-input"
                          style={{ display: "block", marginBottom: "10px" }}
                        >
                          <div className="uploading_img">
                            <img
                              src={process.env.PUBLIC_URL + "../images/add.png"}
                              alt="playstore"
                              className="addimgicon"
                            />
                          </div>
                        </label>
                        <input
                          type="file"
                          id="file-input"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                        />
                      </div>
                    )}
                  </div>
                </li>
                <li>
                  <div>
                    {selectedImage2 ? (
                      <img
                        src={URL.createObjectURL(selectedImage2)}
                        alt="Selected"
                      />
                    ) : (
                      <div>
                        <label
                          htmlFor="file-input2"
                          style={{ display: "block", marginBottom: "10px" }}
                        >
                          <div className="uploading_img">
                            <img
                              src={process.env.PUBLIC_URL + "../images/add.png"}
                              alt="playstore"
                              className="addimgicon"
                            />
                          </div>
                        </label>
                        <input
                          type="file"
                          id="file-input2"
                          onChange={handleImageChange2}
                          style={{ display: "none" }}
                        />
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="button-container"
              style={{ width: "200px" }}
              onClick={handleUpload}
            >
              <button className="button button1">Submit Images</button>
              <button className="button button2">
                <Link style={{ textDecoration: "none", color: "#ffffff" }}>
                  Submit Images
                </Link>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* end uploa puic */}

      {/* verifty otp  */}
      <Modal
        show={Verifyotp}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created"
            style={{ marginTop: "0px" }}
          >
            <h2>Verify Yourself</h2>
            <p>
              Enter the 6-digit code that we sent to customer after order
              confirmation.
            </p>
            <div>
              <ul className="verify_otp">
                {otpDigits.map((digit, index) => (
                  <li key={index}>
                    <input
                      type="text"
                      className="input_verify"
                      maxLength="1"
                      value={digit}
                      onChange={(e) =>
                        handleOtpInputChange(index, e.target.value)
                      }
                      ref={inputRefs[index]}
                    />
                  </li>
                ))}
              </ul>
            </div>

            <div
              class="button-container"
              style={{ width: "150px" }}
              onClick={() => handleShow(jodid)}
            >
              <button className="button button1">Submit</button>
              <button className="button button2">
                <Link style={{ textDecoration: "none", color: "#ffffff" }}>
                  Submit
                </Link>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* end verify otp */}

      <Modal
        show={paymentpopup}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created"
            style={{ marginTop: "0px" }}
          >
            <div className="payment_details">
              <h1>Payment Detail</h1>

              <div className="vendor_payment_status">
                <input
                  placeholder="Payment Status"
                  className="payment_status"
                  value={payment_selection.paymentStatus}
                />

                {payment_selection.paymentStatus === "Success" ? (
                  <button className="prepaid_post">Postpaid Payment</button>
                ) : (
                  <button className="prepaid_post">Prepaid Payment</button>
                )}
              </div>
              {
                <ul className="mt-2">
                  <li className="pt-0">
                    <p>Total Amount</p>{" "}
                    <span>₦ {payment_selection.amount}</span>
                  </li>
                  <li>
                    <p>Discount</p>{" "}
                    <span className="text_change_color">
                      ₦ {payment_selection.discount}
                    </span>
                  </li>
                  <li>
                    <p>Other Charges</p>{" "}
                    <span>
                      {" "}
                      <p>Free</p>{" "}
                    </span>
                  </li>
                  <li className="pb-0 bottom_none">
                    <p className="text_change_color">Amount Paid</p>{" "}
                    <span className="text_change_color">
                      ₦{payment_selection.amount}
                    </span>
                  </li>
                </ul>
              }
            </div>
            <div className="col-md-12">
              <input
                type="number"
                placeholder="Enter Amount"
                name="amount"
                className="select_options"
                value={amount}
                onChange={handlePaymentChange}
              />
              {errors.amount && <div className="error">{errors.amount}</div>}
            </div>
            <div className="col-md-12">
              <select
                name="payment_option"
                className="select_options"
                value={selectedPayment}
                onChange={handlePaymentChange}
              >
                <option style={{ display: "none" }} value="">
                  Select Payment Mode
                </option>
                <option value="Cash">Cash</option>
                <option value="Online">Online</option>
              </select>
              {errors.payment_option && (
                <div className="error">{errors.payment_option}</div>
              )}
            </div>

            {/* onClick={() => Proceed_pay()} */}

            <div
              class="button-container"
              style={{ width: "150px" }}
              onClick={handleSubmitPayment}
            >
              <button className="button button1">Proceed To Pay</button>
              <button className="button button2">
                <Link style={{ textDecoration: "none", color: "#ffffff" }}>
                  Proceed To Pay
                </Link>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* /////////////////////////////////////////////////////////end job ///////////////////////////////////////// */}

      <Modal
        show={success2}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created  well_done"
            style={{ marginTop: "0px" }}
          >
            <Lottie options={lottieOptions} />

            <>
              {" "}
              <h2>Payment Initiated!</h2>
              <p>Please Wait While Customer Pays ! Then Check Status</p>{" "}
            </>

            <div
              class="button-container"
              style={{ width: "150px" }}
              onClick={callPaymentGetApi}
            >
              <button className="button button1"> Proceed To Next</button>
              <button className="button button2">Proceed To Next</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={success4}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created  well_done"
            style={{ marginTop: "0px" }}
          >
            <img
              src={process.env.PUBLIC_URL + "../images/greeting.gif"}
              alt="playstore"
              className="gif"
            />
            {checkResult ? (
              <>
                {" "}
                <h2>Job Completed!</h2>
                <p>Thanks For Completing</p>{" "}
              </>
            ) : (
              <h2>The Job!</h2>
            )}

            <div
              class="button-container"
              style={{ width: "150px" }}
              onClick={handleClose}
            >
              <button className="button button1">
                {" "}
                <Link
                  style={{ textDecoration: "none", color: "#ffffff" }}
                  to="/worker/orderhistory"
                >
                  {" "}
                  Proceed To Next
                </Link>
              </button>
              <button className="button button2">
                <Link
                  style={{ textDecoration: "none", color: "#ffffff" }}
                  to="/worker/orderhistory"
                >
                  {" "}
                  Proceed To Next
                </Link>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Footer />
    </>
  );
};

export default WorkerOrderHistory;
