import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import React, { useState,useEffect,useRef } from 'react';
import LeftnavbarIndividual from "../../components/leftnavbaraIndividual";
import { setSession, getSession} from "../../utils/helper";
import axios from 'axios';
import { BaseUrl, apiBaseUrl } from "../../config/constant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar} from '@fortawesome/fontawesome-free-solid';
import { getAgoTime, getAgoTime2 } from "../../components/common";

const Todaylist_details = ()=>{
  const navigate = useNavigate();   
  const[success,setsuccess] = useState(false);
const [paymentpopup,setpaymentpopup] = useState(false);
const [selectedImage, setSelectedImage] = useState(null);
const [selectedImage2, setSelectedImage2] = useState(null);
const [locationName, setLocationName] = useState('');
const [textareaValue, setTextareaValue] = useState('');
const dataRef = useRef();
const [payment_selection, setPaymentSelection] = useState(false);
const[Verifyotp,setverifyotp]=useState(false);
const [error, setError] = useState('');
const [show, setShow] = useState(false);
const [uploadpic, setuploadpic] = useState(false);
const [reject,setreject] = useState(false);
const location = useLocation();
const data = location.state;    
const base_url = '';
const [job_idprocess,setjob_idprocess] = useState('');
const [ratingStar, setRatingStar] = useState(0);
const maxRating = 5;
const [processid,setId_process] = useState({
  jobId: "",
  rejectReason: "",
});
const [timestamp, setTimestamp] = useState();
const [timestamp2, setTimestamp2] = useState();
const handleShowRate =()=>{
  setShowRate(true) 
}
const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
const [jodid,setjobid]= useState({
  jobId: "",
  code: "",
});
  const finishjob_popup = (data = {}) =>{

    if(data && data.paymentStatus === 'Success'){
      setsuccess(true);
      setpaymentpopup(false);
    }else{
      setpaymentpopup(true);
      setsuccess(false);
    }
    setPaymentSelection({...payment_selection, 
      ['paymentStatus']:data.paymentStatus,
    ['amount']:data.amount,
    ...(data.discount? {['discount']:data.discount}:{['discount']:0}),
    ['id']:data._id,
    
   })
  }
  const handleClose = () =>{
    setShow(false);
    setverifyotp(false);
    setuploadpic(false);
    setPaymentSelection(false);
    setsuccess(false);
  setreject(false);
  
  } 
 
  const Uploadingfunction = () => {
    
    setuploadpic(true);
    
    //dataRef.current = { ...dataRef.current, /* update the necessary properties */ };
    //setShow(false);
    console.log(data);
  };
  
  // const Uploadingfunction = ()=>{
   
  //   setShow(false);
  // }
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    console.log("Selected Image 2:", file);
    setSelectedImage(file);
  };
  const handleImageChange2 = (event) => {
    const file = event.target.files[0];
    console.log("Selected Image:", file);
    setSelectedImage2(file);
  };
  
const handleUpload = () =>{
  console.log(data);
  setverifyotp(true);
  console.log(data);
  // dataRef.current = { ...dataRef.current, /* update the necessary properties */ };
  // setuploadpic(false);
}
const handleOtpInputChange = (index, value) => {
  const newOtpDigits = [...otpDigits];
  newOtpDigits[index] = value;
  setOtpDigits(newOtpDigits);
};

const handleReloadClick = () => {
  window.location.reload();
};
// start_job 
const start_job = (id) =>{
  setjobid(id);
  alert(id);
  setShow(true);
  
} 
// geolocation
useEffect(() => {
  const fetchData = async () => {
    
    try {
      let token = getSession("token");
      console.log(data);
      const response = await axios.post(
        apiBaseUrl+'order/get-provider-job-list',
        {
          data:data._id
         
        }, 
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );
      setjob_idprocess(data);
      console.log(response.data);
//         const rejectJobs = response;

// console.log(rejectJobs)
     
//setreject_list(response.data.response.result);
      
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, []);


useEffect(() => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;

        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
          );

          if (!response.ok) {
            throw new Error('Location not found or no data available for this location.');
          }

          const data_geoloaction = await response.json();
          setLocationName(data_geoloaction.display_name);
        } catch (error) {
          setError('Location not found or no data available for this location.');
        }
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          setError('Geolocation access denied. Please enable geolocation in your browser settings.');
        } else {
          setError('Error getting geolocation: ' + error.message);
        }
      }
    );
  } else {
    setError('Geolocation is not supported by this browser.');
  }
}, []);

useEffect(() => {
  dataRef.current = data;
}, [data]);
 
const Proceed_pay = async ()  =>{

  try {
    let token = getSession("token");
    
    const response = await axios.post(
      apiBaseUrl+'order/job-finish',
      {
        jobId:payment_selection.id,   
        updateIp:1,
      },
      {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      }
    );
    
    console.log(response.data.status);
    {if(response.data.status){
      setsuccess(true);
      setpaymentpopup(false);
    }else{
      setsuccess(false);
      setpaymentpopup(true);
    }
  }
    console.log(response.data.response.result);
  } catch (error) {
    console.error(error);
  }
}

const handleShow = async () => {
  
  const formData = new FormData();
  const enteredOtp = otpDigits.join("");
  formData.append('jobId', jodid);
  formData.append('code', enteredOtp);
  formData.append('first_pic', selectedImage);
  formData.append('second_pic', selectedImage2);

  try {
    let token = getSession("token");
    const response = await axios.post(
      apiBaseUrl+'order/job-start',
      formData,
      {
        headers: {
          'Authorization': token,
          "Content-Type": "multipart/form-data"
        }
      }
    );
console.log(response);
console.log(response.data);
console.log(response.data.status);


    if (response.data && response.data.status && response.data.status === true) {
      navigate('/individual/IndividualToday');
      alert(hello);
      //setData(response.data.response.result);
      console.log(response.data.response.result);
    } else {
      console.log(response.data);
      alert("Job verification code is wrong. Please try again.");
    }
  } catch (error) {
    console.error(error);
  }
};
const reject_order = async (data) =>{
 
  try {
    let token = getSession("token");
    
    const response = await axios.post(
      apiBaseUrl+'order/job-reject',
      {
        jobId:data._id,
        rejectReason:textareaValue, 
      },

     
      {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      }
    );
    if (response.data && response.data.status && response.data.status === true) {
      navigate('/individual/IndividualToday');
      setData(response.data.response.result);
      console.log(response.data.response.result);
    } else {
      
      console.log(response.data);
      
      alert("Job verification code is wrong. Please try again.");
    }
    console.log(response.data.response.result);
  } catch (error) {
    console.error(error);
  } 
}

const reject_popup = (data) =>{
  setId_process(data);
  setreject(true);
} 

    return(
        <>
         <Header />
   <div className="dashboard">
    <div className="container">
        <div className="row mx-0">
        <LeftnavbarIndividual></LeftnavbarIndividual>
            <div className="col-md-9">
            <div className="right_side  mt-3">
               <div className="row mx-0" >
                <div className="col-8">
                <div className="btn_es_align">
                <button className="arrow_down"><Link to="/individual/orderhistory"><img src={process.env.PUBLIC_URL + "/vendor_dashboard/order_detail/order_details.png"} /></Link></button>
                    
                {data?.job_status === "Accept" && (
  <>
    <div className="col-md-4 col-4 px-0">
    <div className="assign-worker-container" onClick={() => start_job(data._id)}>
      <button className="button assign-worker">Start Job</button>
      <button className="button assign-worker1">Start Job</button>
    </div>
  </div>
  <div className="col-md-4 col-4 px-0">
      <div className="assign-viewer-container" onClick={() => reject_popup(data)}>
        <button className="button assign-View-Detail">Reject</button>
        <button className="button assign-View-Detail1">Reject</button>
      </div>
    </div>
  
  </>
)}

{data?.job_status === "Start" && (
  <>
    <div className="col-md-4 col-4 px-0">
    <div className="assign-worker-container" onClick={() => finishjob_popup(data)}>
      <button className="button assign-worker">Job Finish</button>
      <button className="button assign-worker1">Job Finish</button>
    </div>
  </div>
  <div className="col-md-4 col-4 px-0">
      <div className="assign-viewer-container" onClick={() => reject_popup(data)}>
        <button className="button assign-View-Detail" disabled>Reject</button>
        <button className="button assign-View-Detail1" disabled>Reject</button>
      </div>
    </div>
 
  </>
)}
                 
                  </div>
                 

                 
                    <div className="card_details">
                        <div className="card_heading">
                            <h1>Service Location</h1>
                        </div>
                        <div className="card_content">
                          
                            <span><img src={process.env.PUBLIC_URL + "/images/card_location.png"} alt="app"  /> 
                            
                            <p>{job_idprocess?.service_location?.address1}, {job_idprocess?.service_location?.address2},
                      {job_idprocess?.service_location?.land_mark}, {job_idprocess?.service_location?.city},
                      {job_idprocess?.service_location?.state}, {job_idprocess?.service_location?.country}</p></span>
                          
                        </div>
                    </div>
                    <div className="card_details">
                        <div className="card_heading">
                            <h1>Selected Provider</h1>
                        </div>
                        <div className="card_content">
                        <div className="select_provider">   
                            <div> 
                            {job_idprocess?.providerData && (
  <img src={base_url + job_idprocess.providerData.profile_pic} alt="app" className="worker_img" />
)}
                               
                               </div>
                               <div className="select_provider_content">
  {job_idprocess?.providerData ? (
    <>
      <h1>{job_idprocess.providerData.fullName}</h1>
      <p>
        {job_idprocess.providerData.address1}, {job_idprocess.providerData.address2},
        {job_idprocess.providerData.land_mark}, {job_idprocess.providerData.city},
        {job_idprocess.providerData.state}, {job_idprocess.providerData.country}
      </p>
    </>
  ) : (
    <p>Loading provider data...</p>
  )}
</div>
                          
                          <div>
                            <span><img src={process.env.PUBLIC_URL + "../images/Star.png"} className="star_img"/><p>4.6</p></span>
                          </div>
                          </div>
                        </div>
                    </div>
                    <div className="card_details">
                        <div className="card_heading">
                            <h1>Slot Date & Timing</h1>
                        </div>
                        <div className="card_content">
                          <ul>
                            <li><span>Service Date</span> <p>{job_idprocess?.service_date}</p></li>
                            <li><span>Service Timing</span> <p>{job_idprocess?.service_time}</p></li>
                          </ul>
                        </div>
                    </div>
                    <div className="card_details mb-2">
                        <div className="card_heading">
                            <h1>Service Detail</h1>
                        </div>
                        <div className="card_content">
                            <h2> {job_idprocess?.service_name},{job_idprocess?.sub_service_name} </h2>
                            <p className="my-2">It is a long established fact that reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem is that it has a more-or-less normal distribution of letters, as opposed.</p>
                           { job_idprocess?.jobImage?
                          <div className="plumbering_img">
                          <img src={base_url + job_idprocess?.jobImage} alt="app" className="worker_img" /> 
                            {/* <img src={process.env.PUBLIC_URL + "/images/plumber.png"} />
                            <img src={process.env.PUBLIC_URL + "/images/plumber.png"} /> */}
                          </div>
                          :""}
                        </div>
                    </div>
                </div>
                <div className="col-4">
                
                    <div className="payment_details mt-2">
                     <h1>Payment Detail</h1>
                     
                     <div className="vendor_payment_status">
                   
                     <input placeholder="Payment Status"  className="payment_status"/>
                     {job_idprocess?.paymentStatus === "Initialize"?(
                      <button className="prepaid_post">Postpaid Payment</button>
                     ):(
                      <button className="prepaid_post">Postpaid Payment</button>
                     )}
                     
                     </div>
                     <ul className="mt-2"> 
                        <li className="pt-0"><p>Total Amount</p> <span>₦ {job_idprocess?.amount}</span></li>
                        <li><p>Discount</p> <span className="text_change_color">₦ {job_idprocess?.discount}</span></li>
                        <li><p>Other Charges</p> <span>Free</span></li>
                        <li className="pb-0 bottom_none"><p className="text_change_color">Amount Paid</p> <span className="text_change_color">₦ {job_idprocess?.paidAmount}</span></li>
                     </ul>
                    </div>  
                    {job_idprocess?.userFeedback?
                      <>
<div className="payment_details  mt-4">
                      <h1>Feedback</h1>
                      </div>
                     <div className="feedback_reviwes">
                        <div className="review_header">
                            <div className="rating">
                                <div className="rating_left_side">
                                
                                <div>
      {Array.from({ length: maxRating }).map((_, index) => (
        <FontAwesomeIcon
          key={index}
          icon={faStar}
          style={{ color: index < job_idprocess?.userFeedback?.star ? "#FFCC00" : "grey" }}
        />
      ))}
    </div>
                                  
                                </div>
                                <div className="rating_right_side">
                                <p>{getAgoTime2(timestamp2)} </p>
                            </div>
                            </div>
                          
                        </div>
                        <div className="review_content">
                        
                       <p>{job_idprocess?.userFeedback?.message}</p>
                          </div>
                        <div className="reviw_inner_content">
                        <div>
                        {/* <img src={process.env.PUBLIC_URL + "../images/worker.png"} alt="app"/> */}
                         </div>
                        
                                
                                <div className="review_content">
                                <div className="rating">
                                <div className="rating_left_side">
                                
                                <div>
      {Array.from({ length: maxRating }).map((_, index) => (
        <FontAwesomeIcon
          key={index}
          icon={faStar}
          style={{ color: index < job_idprocess?.userFeedback?.star ? "#FFCC00" : "grey" }}
        />
      ))}
    </div>
                                  
                                </div>
                                <div className="rating_right_side">
                                <p>{getAgoTime(timestamp)} </p>
                            </div>
                            </div>
                                <div className="review_footer">
                                {job_idprocess?.userFeedback?.reply? <p>{job_idprocess?.userFeedback?.reply} </p>:
                                <div class="reviewer_btn mt-3 mb-2" onClick={handleShowRate}>
                    <button class="button button1">Reply To Feedback</button>
                    <button class="button button2">Reply To Feedback</button>
                    </div>
                                }
                    
                     </div>
                     
                            </div>
                          <div>
                        </div>
                     </div>
            
                    </div>
</>: ""
                     }                         
                </div>
               </div>
                
             </div>
            </div>
        </div>
    </div>
   </div>
    <Footer />

{/* live location track popup*/}  
    <Modal
          show={show}
          onHide={handleClose}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose}>
              <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
          
            <div id="lottie-animation"></div>
            <div className="congrats-profile-created" style={{marginTop:"0px"}}>
            
      {error ? (
        <p>{error}</p>
      ) : locationName ? (
        <h6>{locationName}</h6>
      ) : (
        <p>
          Geolocation is disabled. Please enable geolocation in your browser settings.
        </p>
      )}
   
           
              <p>Use your device location to set your current location. </p>
              <div class="button-container" style={{ width: "200px" }} onClick={Uploadingfunction}>
                <button className="button button1">Use Current Location</button>
                <button className="button button2">
                  <Link style={{ textDecoration: "none", color: "#ffffff" }}>Use Current Location
                  </Link>
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

{/* uploading track popup*/}  
        <Modal
         show={uploadpic}
         onHide={handleClose}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose}>
              <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
          

            <div className="congrats-profile-created" style={{marginTop:"0px"}}> 
              <h2>Upload Photos</h2>
              <p>Upload 2 selfie images with your service location.</p>
              <div>
                <ul className="images_uploads">
                  <li>
                  <div>
      {selectedImage ? (
        <img src={URL.createObjectURL(selectedImage)} alt="Selected" />
      ) : (
        <div>
          <label htmlFor="file-input" style={{ display: 'block', marginBottom: '10px' }}>
          <div className="uploading_img">
            <img
                src={process.env.PUBLIC_URL + "../images/add.png"}
                alt="playstore"  className="addimgicon"
              />
            </div>
          </label>
          <input
            type="file"
            id="file-input"
            onChange={handleImageChange}
            style={{ display: 'none' }}
          />
        </div>
      )}
    </div>
              </li>
                  <li>
                  <div>
      {selectedImage2 ? (
        <img src={URL.createObjectURL(selectedImage2)} alt="Selected" />
      ) : (
        <div>
          <label htmlFor="file-input2" style={{ display: 'block', marginBottom: '10px' }}>
            <div className="uploading_img">
            <img
                src={process.env.PUBLIC_URL + "../images/add.png"}
                alt="playstore"  className="addimgicon"
              />
            </div>
         
          </label>
          <input
            type="file"
            id="file-input2"
            onChange={handleImageChange2}
            style={{ display: 'none' }}
          />
        </div>
      )}
    </div>
              </li>
                </ul>
              </div>
              <div class="button-container" style={{ width: "200px" }}  onClick={handleUpload}>
                <button className="button button1">Submit Images</button>
                <button className="button button2">
                  <Link style={{ textDecoration: "none", color: "#ffffff" }}>Submit Images
                  </Link>
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>   


        <Modal

show={Verifyotp}
         onHide={handleClose}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose}>
              <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
          

            <div className="congrats-profile-created" style={{marginTop:"0px"}}> 
              <h2>Verify Yourself</h2>
              <p>Enter the 6-digit code that we sent to customer after order confirmation.</p>
              <div>
              <ul className="verify_otp">
    {otpDigits.map((digit, index) => (
      <li key={index}>
        <input
          type="text"
          className="input_verify"
          maxLength="1"
          value={digit}
          onChange={(e) => handleOtpInputChange(index, e.target.value)}
        />
      </li>
    ))}
  </ul>
              </div>
              
              <div class="button-container" style={{ width: "150px" }}   onClick={() => handleShow(jodid)}>
                <button className="button button1" >Submit</button>
                <button className="button button2">
                  <Link style={{ textDecoration: "none", color: "#ffffff" }}>Submit 
                  </Link>
                </button>
              </div>
              <p>Resend Code</p>
            </div>
          </Modal.Body>
        </Modal> 

        <Modal

show={paymentpopup}
         onHide={handleClose}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose}>
            <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
          

            <div className="congrats-profile-created" style={{marginTop:"0px"}}> 
            <div className="payment_details">
                     <h1>Payment Detail</h1>
                     
                     <div className="vendor_payment_status">
                   
                     <input placeholder="Payment Status"  className="payment_status" value={payment_selection.paymentStatus} />

                     {
  payment_selection.paymentStatus === "Success" ? (
    <button className="prepaid_post">Postpaid Payment</button>
  ) : (
    <button className="prepaid_post">Prepaid Payment</button>
  )
}
                    
                     </div>
                     { 
                          <ul className="mt-2"> 
                          <li className="pt-0"><p>Total Amount</p> <span>₦ {payment_selection.amount}</span></li>
                          <li><p>Discount</p> <span className="text_change_color">₦ {payment_selection.discount}</span></li>
                          <li><p>Other Charges</p>         <span> <p>Free</p> </span></li>
                          <li className="pb-0 bottom_none"><p className="text_change_color">Amount Paid</p> <span className="text_change_color">₦{payment_selection.amount}</span></li>
                       </ul>
                     }
                   
                    </div>
              
              
              <div class="button-container" style={{ width: "150px" }}     onClick={() => Proceed_pay(data)} >
                <button className="button button1" >Proceed To Pay</button>
                <button className="button button2">
                  <Link style={{ textDecoration: "none", color: "#ffffff" }}>Proceed To Pay
                  </Link>
                </button>
              </div>
              <p>Resend Code</p>
            </div>
          </Modal.Body>
        </Modal> 

        <Modal 


show={success}
         onHide={handleClose}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose}>
            <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
          

            <div className="congrats-profile-created  well_done" style={{marginTop:"0px"}}>
            <img
                src={process.env.PUBLIC_URL + "../images/greeting.gif"}
                alt="playstore" className="gif"
              />
              <h2>Well Done!</h2> 
         <p>You was complete your
Job Successfully</p>
{/* <div class="button-container" style={{ width: "150px" }} onClick={handleReloadClick}>        */}
              <div class="button-container" style={{ width: "150px" }}  onClick={() => Proceed_pay(data)}>
                <button className="button button1" > <Link style={{ textDecoration: "none"}} to='/individual/orderhistory'> Back to Home
                  </Link></button>
                <button className="button button2">
                  <Link style={{ textDecoration: "none", color: "#ffffff" }} to='/individual/orderhistory'> Back to Home
                  </Link>
                </button>
              </div>
          
            </div>
          </Modal.Body>
        </Modal> 


        <Modal 


show={reject}
         onHide={handleClose}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose}>
            <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
          

            <div className="congrats-profile-created  well_done" style={{marginTop:"0px"}}>
           
         <p>Reason For Reject</p>
              
              <textarea    value={textareaValue} onChange={(e) => setTextareaValue(e.target.value)} className="add_reasons"></textarea>
              <div class="button-container mt-3" style={{ width: "150px" }}   onClick={() => reject_order(processid)}>
                <button className="button button1 s_btn" > <Link style={{ textDecoration: "none",color: "#fff !important"}} to='/individual/orderhistory'> Submit
                  </Link></button>
                <button className="button button2">
                  <Link style={{ textDecoration: "none", color: "#ffffff" }} to='/individual/orderhistory'>Submit
                  </Link>
                </button>
              </div>
          
            </div>
          </Modal.Body>
        </Modal> 
    <div>
   
      </div>
        </>
    );
};

export default Todaylist_details;