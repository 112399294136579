import React,{useEffect,useState} from 'react'
import { Link ,NavLink } from 'react-router-dom';
import { getSessionForLoginUser ,logout } from '../utils/helper';
import { BaseUrl } from '../config/constant';
import Button from 'react-bootstrap/Button';
import {isUerPrifileComplete,isAdressUpdated,verifyUserPhone,verifyUserPhoneOTP,verifyUserEmail,verifyUserEmailOTP,checkProfilePercentage,validateuserToken } from './common';
import Modal from 'react-bootstrap/Modal';


const UserDashboard = () =>{
const data2=getSessionForLoginUser('user');
const [isopen, setopen] = useState(false);
const toggleMenu = () => { setopen(!isopen) };

const [inputValues, setInputValues] = useState(Array(6).fill(''));
const [value, setValue]=useState('');
const [showOtpBlock,setshowOtpBlock]=useState(false)
const [phoneVerify,setPhoneVerify]=useState(false);
const [emailVerify,setEmailVerify]=useState(false);
const [show,setShow]=useState(false)
const [errorMsg,setErrorMsg]=useState('')

const [countdown, setCountdown] = useState(60);
const [isCounting, setIsCounting] = useState(false);

const handleClose=()=>{
    setShow(false)
  } 
  const handleVerifyMobileClose=()=>{
    setPhoneVerify(false);
    setshowOtpBlock(false);
    setErrorMsg('');
    setInputValues(Array(6).fill(''));
  }
  const handleVerifyEmailClose=()=>{
    setEmailVerify(false);
    setshowOtpBlock(false);
    setErrorMsg('');
    setInputValues(Array(6).fill(''));
  }
  const handleInputChange = (event, index) => {
    const value = event.target.value;
    if (/^\d+$/.test(value) && value.length === 1) {
      const newInputValues = [...inputValues];
      newInputValues[index] = value;
      setInputValues(newInputValues);
      if (index < 5) {
        document.getElementById(`input-${index + 1}`).focus();
      }
    }
  };
  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace') {
      const newInputValues = [...inputValues];
      newInputValues[index] = value;
      setInputValues(newInputValues);
      if(index > 0){
        document.getElementById(`input-${index - 1}`).focus();
      }
    }
  
  };
  const resendPhoneVerifyOTP = async()=>{
    verifyUserPhone(data2.token);
    setCountdown(60);
    setIsCounting(true);
  }
  const resendEmailVerifyOTP = async()=>{
    verifyUserEmail(data2.token);
    setCountdown(60);
    setIsCounting(true);
  }
  const handleVerifyPhoneSubmit=async()=>{
    if (data2.token) { 
      if(!showOtpBlock){
        const data = await verifyUserPhone(data2.token);
        setshowOtpBlock(true)
        setCountdown(60);
      setIsCounting(true);
      }else{
        const otp = inputValues.join('');
        const resp = await verifyUserPhoneOTP(otp,data2.token)
        if(resp){
          handleVerifyMobileClose();
          checkProfilePercentage('user');
        } else {
          setErrorMsg('Please enter correct OTP');    
        }
      }
    }else{
      setErrorMsg('Please try afer some time.');
    }
  }

  const handleVerifyEmailSubmit=async()=>{
    if (data2.token) { 
      if(!showOtpBlock){
        const data = await verifyUserEmail(data2.token);
        setshowOtpBlock(true)
        setCountdown(60);
        setIsCounting(true);
      }else{
        const otp = inputValues.join('');
        const respounse = await verifyUserEmailOTP(otp,data2.token);
        if(respounse){ 
          handleVerifyEmailClose(); 
          checkProfilePercentage('user');
        }
        else{ setErrorMsg('Please enter correct OTP'); }
      }
    }else{
      setErrorMsg('Please try afer some time.');
    }
  }

const handleLogout=()=>{
    logout();
    window.location = "/";
}

const handleChange=async(e)=>{
    const {value, name}= e.target;
    setErrorMsg('')
    setValue(value);
  }
const validateuser = async ()=>{
  try {
    const data = JSON.parse(await validateuserToken(data2?.token));
    if(data?.status === 401 || data?.message === "Unauthorized access."){
      handleLogout();
    }
  } catch (error) {
    if(error?.response?.status === 401 || error?.response?.statusText === "Unauthorized"){
      handleLogout();
    }
  }
}


useEffect(()=>{
  try {
    validateuser(); 
    isUerPrifileComplete();
    isAdressUpdated();
    checkProfilePercentage('user');
  } catch (error) {
    if(error?.response?.status === 401 || error?.response?.statusText === "Unauthorized"){
      handleLogout();
    }
  }
},[])

//for 60 secong Counter
useEffect(() => {
  let countdownInterval;
  // console.log(isCounting);
  if (isCounting) {
    countdownInterval = setInterval(() => {
      if (countdown > 0) {
        setCountdown((prevCountdown) => prevCountdown - 1);
      } else {
        clearInterval(countdownInterval);
        setIsCounting(false);
      }
    }, 1000);
  } else {
    clearInterval(countdownInterval);
  }

  return () => {
    clearInterval(countdownInterval);
  };
}, [countdown, isCounting]);

return(
    <>
    <div className="col-md-12 col-lg-3 "> 
        <div className="user_profile">
            <div className="row mx-0">
                <div className="col-3">
                    {/* <img src={BaseUrl+data2.profile_pic} alt="app"  className="app_img_profile_pic" /> */}
                    <img src={data2.profile_pic?BaseUrl+data2?.profile_pic:"../images/avatar-default-icon.png"}  className="app_img_profile_pic" id="user_side_bar" />
                </div>
                <div className="col-9 px-0">
                    <h1>{data2.fullName}</h1>
                    <p><span> <img src={process.env.PUBLIC_URL + "/mobile.png"} alt="app"  className="app_img" /></span>{data2.phone}</p>
                    <p><span> <img src={process.env.PUBLIC_URL + "/mail.png"} alt="app"  className="app_img" /></span>{data2.email.slice(0,20)}</p>
                </div>
            </div>
        </div>
        <div className="user_listing">
            <>
            
            {data2.isMobileVerified? '':(<>
              <h1 onClick={()=>{setPhoneVerify(true)}}  className="verify">Verify Phone Number <span> <Button  aria-label="bar" id="verify"> +5 % </Button></span></h1>
            </> )}
            {data2.isEmailVerified? '':(<>
                <h1 onClick={()=>{setEmailVerify(true)}}  className="verify">Verify Email <span> <Button aria-label="bar" id="verify"> +5 % </Button></span></h1>
            </> )}
            {data2.isProfileComplete? '':(<>
                <NavLink to="/user/my_profile" style={{textDecoration:"none"}}  className="verify"><h1>Verify Document<span> <Button  aria-label="bar" id="verify"> +5 % </Button></span></h1></NavLink>
            </> )}
            {data2.isAdress? '':(<>
                <NavLink to="/user/My_address" style={{textDecoration:"none"}}  className="verify"><h1>Add Address<span> <Button aria-label="bar" id="verify"> +5 % </Button></span></h1></NavLink>
            </> )}

            <h1 id="account_information">Account Information <span>  <button className="bar_icon_toggle" aria-label="bar"  onClick={toggleMenu}>
            <i className={`fa ${isopen ? "fa-times" : "fa-bars"}`}></i>
                </button></span></h1>  
            </>
            <div className={isopen ? "toggled" : ""} style={{ padding: 0 ,margin:0}}>
                <ul>   
                    <NavLink to="/user/my_profile" style={{textDecoration:"none"}}>  <li> <div> My Profile </div></li>  </NavLink>  
                    <NavLink to="/user_dashboard/order_history" style={{textDecoration:"none"}} >  <li><div> Order History</div></li> </NavLink>  
                    <NavLink to="/user/My_address" style={{textDecoration:"none"}}>  <li><div> My Addresses </div></li></NavLink> 
                    <NavLink to="/user/chat" style={{textDecoration:"none"}}>    <li><div>Chats</div></li>   </NavLink> 
                    <NavLink to="/user/feedback" style={{textDecoration:"none"}}>   <li><div>Feedbacks</div></li> </NavLink>  
                    <li onClick={handleLogout}><div className='logout'>Logout</div></li>
                </ul>
            </div>
        </div>
    </div>  

      {/* Verify Phone Model */}
      <Modal show={phoneVerify} onHide={handleVerifyMobileClose} size='sm' centered>
        <Modal.Header closeButton>
          <h6>Verify Your Phone</h6>
        </Modal.Header>
        <Modal.Body>   
          <div className="">
            <div className={`wallet-enter-Amount form-group ${!showOtpBlock ? '' : 'blur-effect'}`} >
              <input type="number" className='col-12' value={data2.phone} name="phone" id="phone" oninput="allow_alphabets(this)" readOnly />
            </div>
            
            {showOtpBlock? 
            <>
              <div className='otp-wallet-box'>
                {inputValues.map((value, index) => (
                  <input key={index} type="text" id={`input-${index}`} value={value} onChange={(event) => handleInputChange(event, index)} onKeyDown={(event) => handleKeyDown(event, index)} maxLength={1} className='input-box' />
                ))}
              </div> 
              {isCounting ? (
              <p className='error'> {' Time Left :'} {countdown} </p>
              ) : (
              <div
                  className='button-container'
                  style={{ width: '176px', marginTop: '15px' }}
                  onClick={resendPhoneVerifyOTP} >
                  <button className='button button1' id='btn-otp-resend'>
                    Resend Code
                  </button>
                  <button className='button button2'>Resend Code</button>
              </div>
            )}
              </>: ""}
            <p className='error'>{errorMsg}</p>
            <div className="assign-worker-container" id="assign_work_list" onClick={handleVerifyPhoneSubmit}>
              <button className="button assign-worker">{!showOtpBlock?'Proceed To Next' : 'Verify Otp'}</button>
              <button className="button assign-worker1">{!showOtpBlock?'Proceed To Next' : 'Verify Otp'}</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* End of Function */}
      
      {/* Verify Email Model */}
      <Modal show={emailVerify} onHide={handleVerifyEmailClose} size='sm' centered>
        <Modal.Header closeButton>
          <h6>Verify Your Email</h6>
        </Modal.Header>
        <Modal.Body>   
          <div className="">
            <div className={`wallet-enter-Amount form-group ${!showOtpBlock ? '' : 'blur-effect'}`} >
              <input type="email" className='col-12' value={data2.email} name="email" id="email" readOnly />
            </div>
            
            {showOtpBlock? 
            <>
              <div className='otp-wallet-box'>
                {inputValues.map((value, index) => (
                  <input key={index} type="text" id={`input-${index}`} value={value} onChange={(event) => handleInputChange(event, index)} onKeyDown={(event) => handleKeyDown(event, index)} maxLength={1} className='input-box' />
                ))}
              </div>
              {isCounting ? (
                <p className='error'> {' Time Left :'} {countdown} </p>
                ) : (
                <div
                    className='button-container'
                    style={{ width: '176px', marginTop: '15px' }}
                    onClick={resendEmailVerifyOTP} >
                    <button className='button button1' id='btn-otp-resend'>
                      Resend Code
                    </button>
                    <button className='button button2'>Resend Code</button>
                </div>
              )} </>
               : ""}
            <p className='error'>{errorMsg}</p>
            <div className="assign-worker-container" id="assign_work_list" onClick={handleVerifyEmailSubmit}>
              <button className="button assign-worker">{!showOtpBlock?'Proceed To Next' : 'Verify Otp'}</button>
              <button className="button assign-worker1">{!showOtpBlock?'Proceed To Next' : 'Verify Otp'}</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* End of Function */}
    </>
);
};

export default UserDashboard;
