//logout

export function logout() {
  sessionStorage.clear(); // Clear all session data
  // Redirect the user to the login page or perform other necessary actions
}

// set session data 
export function setSession(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  
  // Get session data
  export function getSession(key) {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }


  export function setSessionForLoginUser(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  
  // Get session data
  export function getSessionForLoginUser(key) {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }

    // Get session for worker data
  export function setSessionForLoginWorker(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  
  // Get session data
  export function getSessionForLoginWorker(key) {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }
  
  
  export function setSessionForAddress(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  
  // Get session data
  export function getSessionForAddress(key) {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }


  export function setSessionForLoginCompany(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  
  // Get session data
  export function getSessionForLoginCompany(key) {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }

  
  export function setSessionForIndividual(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  
  // Get session data
  export function getSessionForIndividual(key) {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }
  