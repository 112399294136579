import React, { Component } from "react";
import Slider from "react-slick";
export default class About_usmobiefeaturesslider extends Component {
    render() {
        const settings = {
          dots: true,
          dotsClass: 'slick-dots  customDotsClass',
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          arrows:false,
          initialSlide: 1,
          autoplay:true,
    
          responsive: [
             
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                initialSlide: 3,
                dots: true,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1.5,
                slidesToScroll: 1.5,
                initialSlide: 1.5,
                dots: true,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1.5,
                slidesToScroll: 1.5,
                dots: true,
              }
            }
          ]
        };
        return (
          <div>
    
            <Slider {...settings}>
     
            <div>
            <div className="slider_square">
                        <h1 className="exclisive_features--subtitle">06</h1>
                        <p className="exclisive_features--content">Doqwik Offers Multiple Seamless Payment Options</p>
                    </div>
            </div>
    
    <div>
    <div className="slider_square">
                        <h1 className="exclisive_features--subtitle">07</h1>
                        <p className="exclisive_features--content">Provide feature allows users to schedule, cancel appointments</p>
                    </div>
    </div>
   
    <div>
    <div className="slider_square">
                        <h1 className="exclisive_features--subtitle">08</h1>
                        <p className="exclisive_features--content">Users rate theprovider based on the services it provides according to</p>
                    </div>
    </div>
     

    <div>
    <div className="slider_square">
                        <h1 className="exclisive_features--subtitle">09</h1>
                        <p className="exclisive_features--content">Assign job with the provider at the date, time & place you desire</p>
                    </div>
    </div>
    <div>
    <div className="slider_square">
                        <h1 className="exclisive_features--subtitle">10</h1>
                        <p className="exclisive_features--content">If the provider is suitable for your job details, you can book</p>
                    </div>
    </div>
    
          
            </Slider>
          </div>
        );
      }
}


