import React,{useState} from 'react'
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import './validator/validator.css';
import * as yup from "yup";
import {validationSchema } from "../components/validator/validator"


  

const LoginModal = ({pageData}) => {
  const data=pageData?.pageheadings  ;
  
  const title = data && data[0] && data[0].heading && data[0].heading[0] && data[0].heading[0].title;
const description=data && data[0] && data[0].heading && data[0].heading[0] && data[0].heading[0].description;

  const [errors, setErrors] = useState({});
  const [checkBlur, setBlur] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    gender: "",
    skill:"",
    social_media_handle:"",
    business_social_media_handle:"",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setBlur(true)
      // Validation succeeded, continue with form submission or further processing
      // ...
      // console.log("Form data:", formData);
      postLoginData()
      .then((data) => {
        // Process the returned data here
        if(data.status === true){
       Congratulation();
              joinclose();
        }
       
      })
      .catch((error) => {
        // Handle the error here
      });
    } catch (validationErrors) {
      // Validation failed, handle the errors
      const errors = {};
      validationErrors.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
      setErrors(errors);
    }
  };
   
  const postLoginData = async () => {
    try {
      const response = await axios.post(
        'https://moneysaverz.com:6300/front/v1/waitlist/capture-lead',
        {
          name: formData.fullName,
          email: formData.email,
          phone: formData.phone,
          gender:formData.gender,
          skill :formData.skill,
          social_media_handle:formData.social_media_handle,
          business_social_media_handle: formData.business_social_media_handle
        },
        {
          headers: {
            key: 'd1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed',
            'Content-Type': 'application/json',
          },
        }
      );
      return(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  


  const [isNavvisible, setIsVisible] = useState(true);
  const [openjoin, setopenjoin] = useState(false);

  const joinclose = () => setopenjoin(false);
  const joinshow = () => setopenjoin(true);

  const [click, setclick] = useState(false);
  const [isopen, setopen] = useState(false);

  const toggleMenu = () => {
    setopen(!isopen);
  };
  const Congratulation = () => setclick(true);
  const Congratulationclose = () => setclick(false);

  const handleGenderSelection = (selectedGender) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      gender: selectedGender,
    }));
  };
  return (
    <>
     <section className="mb-0">
    <div className="download_aap">
        <div className="container">
            <div className="row">
            <div className="col-md-3 col-lg-3 col-xl-3">
                <img src={process.env.PUBLIC_URL + "./images/app_img.png"} alt="app"  className="app_img_contact " />
                 
                </div>
                <div className="col-md-12 col-lg-12 col-xl-5 pt-4">
                    <h1>{title}   </h1>
                    <p>{description}</p>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-4">
                   <ul>
                   <li> <img src={process.env.PUBLIC_URL + "./images/google_pay.png"} alt="app"  className="app_img" onClick={joinshow} /> </li>
                    <li> <img src={process.env.PUBLIC_URL + "./images/btns_apple.png"} alt="app"  className="app_img" onClick={joinshow}/> </li>
                    
                 
                   </ul>
                </div>
            </div>
        </div>
    </div>
</section>
      <Modal show={openjoin} onHide={joinclose} className="join_modl">
<button  className="cross_btn" onClick={joinclose}> X </button>
        

        <Modal.Body>
          <div className="text_align_center">
          <img src={process.env.PUBLIC_URL + "/images/logo.png"} className="logo_mobile"/> <br />
          <b className="join_app_model">Join The DoQwik Waitlist</b>
            <div  className={!checkBlur ? "pop_up_model" : "blur-effect pop_up_model"}>
              <input
                type="text"
                className={` ${errors.fullName ? 'InputError' : ''}`}
                onChange={handleChange}
                placeholder="Full Name"
                name="fullName"
                id="name"
                oninput="allow_alphabets(this)"
                required
              />
     {errors.fullName && <p className="errors">{errors.fullName}</p>}
              <input
                type="email"
                className={` ${errors.email ? 'InputError' : ''}`}
                placeholder="Email Address"
                name="email"
                id="email"
                onChange={handleChange}
                required
              />
         {errors.email && <p className="errors">{errors.email}</p>}
              <input
                type="number"
                className={` ${errors.phone ? 'InputError' : ''}`}
                placeholder="Phone Number"
                onChange={handleChange}
                maxlength="10"
                minlength="10"
                name="phone"
                id="phone"
                onkeypress="return onlyNumberKey(event)"
                required
              />
                       {errors.phone && <p className="errors">{errors.phone}</p>}

                       
                       <input
                type="text"
                className={` ${errors.email ? 'InputError' : ''}`}
                placeholder="Skill/Work"
                name="skill"
                id="skill"
                onChange={handleChange}
                required
              />
         {errors.skill && <p className="errors">{errors.skill}</p>}
                       <input
                type="text"
                className={` ${errors.email ? 'InputError' : ''}`}
                placeholder="Enter Social Media Handle "
                name="social_media_handle"
                id="social_media_handle"
                onChange={handleChange}
                required
              />
         {errors.social_media_handle && <p className="errors">{errors.social_media_handle}</p>}

         
         <input
                type="text"
                className={` ${errors.email ? 'InputError' : ''}`}
                placeholder="Enter Business Social Media Handle "
                name="business_social_media_handle"
                id="business_social_media_handle"
                onChange={handleChange}
                required
              />
         {errors.business_social_media_handle && <p className="errors">{errors.business_social_media_handle}</p>}
              <p className="fill_form mb-0">Select One Option</p>
              <div className="user_selection">
  <div
    className="user_name"
    onClick={() => handleGenderSelection("Male")}
  >
    <input
      type="radio"
      name="gender"
      value="Male"
      checked={formData.gender === "Male"}
      onChange={handleChange}
      className="radio_btns"
    />
    <label>Male</label>
  </div>
  <div
    className="user_name"
    onClick={() => handleGenderSelection("Female")}
  >
    <input
      type="radio"
      name="gender"
      value="Female"
      checked={formData.gender === "Female"}
      onChange={handleChange}
      className="radio_btns"
    />
    <label>Female</label>
  </div>
</div>
              {/* <div className="user_selection">
                <div className="user_name"   onClick={handleChange}>
                  <input
                    type="radio"
                    name="gender"
                    value="Male"
                    // checked={gender === "male"}
                    onChange={handleChange}
                    className="radio_btns"
                  />
                  <label>Male</label>
                </div>
                <div className="user_name" onClick={handleChange}>
                  <input
                    type="radio"
                    name="gender"
                    value="Female"
                    // checked={gender === "female"}
                    onChange={handleChange}
                    className="radio_btns"
                  />
                  <label>Female</label>
                </div>
              </div> */}
              {errors.gender && <p className="errors">{errors.gender}</p>}
            </div>
          </div>{" "}
        </Modal.Body>
        <Modal.Footer>
          <div
            className="button-container"
            onClick={(e) => {
              // Congratulation();
              // joinclose();
              handleSubmit(e);
            }}
          >
            <button className="button button1">Submit</button>
            <button className="button button2">Submit</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default LoginModal
