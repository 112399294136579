import React from "react"; 
import { useState,useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import DashboardAsVendor from "../../components/dashboardAsVendor";
import { Link } from "react-router-dom";
import axios from "axios";
import { getSession } from "../../utils/helper";
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import { BaseUrl, apiBaseUrl } from "../../config/constant";

const Service_details = ()=>{
    const token = getSession("token");
    const { key } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const[subServices,setSubServices]=useState([])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.post(
              apiBaseUrl+'provider/get-company-single-services',
              {
                id: key
              },
              {
                headers: {
                    Authorization: token,
                  'Content-Type': 'application/json'
                }
              }
            );
    
            console.log(response.data)
            setData(response.data.response.result)
            setSubServices(response.data.response.result.subServices)
          } catch (error) {
            console.error(error);
          }
        };
        fetchData();
      }, []);             
    

      const deleteService = async () => {
        try {
          const response = await axios.post(
            apiBaseUrl+'provider/delete-company-services',
            {
              id: key
            },
            {
              headers: {
                  Authorization: token,
                'Content-Type': 'application/json'
              }
            }
          );
return response.data
        } catch (error) {
          console.error(error);
        }
      };


const handleDelete=()=>{
    deleteService().then((data)=>{
if(data.status===true){
    navigate("/vendor/service");
}
    }).catch((error)=>{
        console.error(error);
    })
}

const handleState=()=>{
    navigate('/vendor/edit_service' , {state:data});
}

    return(
        <>
        <Header></Header>
        <div className="dashboard">
    <div className="container">

        <div className="row mx-0">
         <DashboardAsVendor/>
            <div className="col-md-12 col-lg-9">
             <div className="vendors_right_forms">
            
             <div className="right_side">
               <div className="row mx-0" >
                <div className="col-12">
                  <div className="btn_es_align mb-3">
                   <button className="arrow_down"><Link to="/vendor/service"><img src={process.env.PUBLIC_URL + "/vendor_dashboard/order_detail/order_details.png"} /></Link></button>
                   <div className="edit_Service" id="edit-Service">
                            <button className="button edit_Service_first" onClick={handleState}> 
    Edit Service</button>
                            <button className="button edit_Service_second"  onClick={handleState}>  
   Edit Service</button>
                         </div> 
                    <div className="cancel_book">
                    <button className="button button_cancel_book" onClick={handleShow}>Delete Service</button>
                    <button className="button button_cancel--hover" onClick={handleShow}>Delete Service </button>
                    </div>
                  </div> 
                </div>
                </div>
               
                <div className="row">
            <div className="mergin_left">
              <div className="row">
                <div className="col-md-4 col-lg-3">
                    <h1 className="services_deatils_heading">Service</h1>
                    <div className="user_name">
    {data?.serviceList?.serviceData ? (
        <input
            type="text"
            value={data?.serviceList?.serviceData?.service_name}
            className="unix_slon"
            style={{ width: "200px" }}
        />
    ) : (
        <div className="custom-block" style={{ width: "200px", height: "39px" }}>
            {/* Content for the custom block */}
        </div>
    )}
</div>

                </div>
                {/* {data.category?           <div className="col-md-8 col-lg-9"> 
                <h1 className="services_deatils_heading">Gender</h1>
                <div className="user_selection">
                    <div className="user_name text-center" style={{paddingLeft:"25px"}}> <label>{data.category}</label></div>
                    <div className="user_name"><input type="radio" className="radio_btns" /><label>Female</label></div>
                    <div className="user_name"><input type="radio" className="radio_btns" /><label>Both</label></div>
                    </div>
                </div>:""} */}
      
                
              </div>
              <div className="row mt-3">
                <div className="col">
                    <h1 className="services_deatils_heading">Sub-Service</h1>
                    {data?.serviceList?.subServices?
                <div className="">
                    <ul className="mb-0" id="company_service">
        <li>{data?.serviceList?.subServices?.sub_service_name}</li>
                    </ul>
                    </div>
                    :         <div class="custom-block" style={{width:"170px",height:"39px"}}>
            
                </div>}
                </div>
                
              </div>

           
            <div className="row">
            {/* <div className="col-md-12"> */}
           
            <div className='col-12 work_list_category' id="service_company_details">
                        <h2 className="my-2">Service ( Work Gallery )</h2>
                    <ul className="scroll_workerGallery">
                    {data?.servicesGallary?.slice(0,3).map((item, index)=>(
                        <li key={index}>
                            <div className='service_category_list'>
                                <img src={BaseUrl+item.before_work_pic} />
                                <img src={BaseUrl+item.after_work_pic } />
                            </div>
                        </li>
                    ))}
                    </ul> 
                    </div>
                {/* </div>         */}

      </div>

            <div className="row">
          <div className="col-md-12">
              <h1 className="services_deatils_heading">Price</h1>
          
          <div className="user_selection">
            <div className="price">
            <span><img src={process.env.PUBLIC_URL + "/vendor_dashboard/nan.png"} /></span><input type="text" value={data?.serviceList?.price} style={{width:"200px"}}/>
            </div>
              </div>
          </div>
          
      
      </div>
                
             </div>
             </div>
            </div>
        </div>
    </div>
   </div>
   </div>
   </div>
        <Footer></Footer>
       


        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><h5>  Are you sure you want to delete this service? </h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <img src={process.env.PUBLIC_URL + "/images/delete_icon.png" } className="delete_images"></img>
          </Modal.Body>
        <Modal.Footer>
        <div className="proceed_next mt-0 mb-2">
 
  <div class="button-container edit_proceed mt-4 mb-4" style={{marginRight:"5px"}} onClick={handleClose}><button class="button button1" id="btn-otp-resend" >No</button><button class="button button2">No</button></div>

           <div class="button-container proccess_next mt-4 mb-4 mr-0" ><button class="button button1" onClick={handleDelete}> Yes </button><button class="button button2" onClick={handleDelete}>  Yes</button></div>
           </div>
   
   
      

        </Modal.Footer>
      </Modal>
        </>
    );
};

export default Service_details;
