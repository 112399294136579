import React,{useState,useEffect , useRef} from 'react'
import Header from '../../components/header';
import Footer from '../../components/footer';
import LeftnavbarIndividual from '../../components/leftnavbaraIndividual';
import axios from 'axios';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { validationSchemaForCompanyUpdate } from '../../components/validators/vendorFormValidator';
import { setSessionForIndividual, getSessionForIndividual,getSession  } from '../../utils/helper';
import Modal from "react-bootstrap/Modal";
import { BaseUrl, apiBaseUrl } from '../../config/constant';
import { getCountry,getSate,getCity,getBanks,handleLogout,checkProfilePercentage,checkDocumentVerification,getProviderServiceList } from "../../components/common";
import Select from 'react-select';

const IndividualProfile = () => {
const  vendor  = getSessionForIndividual('individual')
const [show,setShow]=useState(false);
const imageInputRef = useRef(null);
const [checkBlur, setBlur] = useState(true);
const [errors, setErrors] = useState("");
const [selectedImage, setSelectedImage] = useState(null);
const [show2,setShow2]=useState(false);
const[ state, setState ]=useState([]);
const[city, setCity]=useState([]);
const [country, setCountry] = useState([])

const [banks, setBanks]=useState([]);
const [selectedBank, setSelectedBank] = useState(null);
const handleBankChange = (selectedOption) => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    bankName: selectedOption.value,
  }));
  setSelectedBank(selectedOption);
};

const handleImageSelect = (event) => {
  const file = event.target.files[0];
  setFormData((prevFormData) => ({
    ...prevFormData,
    profile_pic: file,
  }));
  setSelectedImage(file);
};
const handleCaptureButtonClick = () => {
  console.log('call');
  if (imageInputRef.current) {
    imageInputRef.current.click(); // Check if imageInputRef.current is not null
  }
};
const  handleClose=()=>{setShow(false);  setBlur(true)}
const token = getSession('token')
const percentage = vendor?.profileComplete || 70
const [formData, setFormData] = useState({
  fullName: vendor?.fullName,
  country: vendor?.country,
  state: vendor?.state,
  city: vendor?.city,
  address1: vendor?.address1,
  address2: vendor?.address2,
  land_mark: vendor?.land_mark,
  postal_code: vendor?.postal_code,
  bankAcountNumber: vendor?.bankAcountNumber,
  platform: "Web",
  profile_pic: vendor?.profile_pic,
  bankName : vendor?.bankName,
  tinNumber: vendor?.tinNumber,
  gender:vendor?.gender || "Other"
});

const fetchData = async () => {
  try {
    const response = await axios.post(
      apiBaseUrl+"provider/update-profile",
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
const handleChange = async (e) => {
  const { name, value } = e.target;
  if(name==='country'){
    const jsonObject = JSON.parse(value);
    const country= await  getSate(jsonObject.id);
    setState(country)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: jsonObject.name,
    }));
  }else if(name==='state'){
    const jsonObject = JSON.parse(value);
    const state= await  getCity(jsonObject.id);
    setCity(state)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: jsonObject.name,
    }));
  }else if(name==='city'){
    const jsonObject = JSON.parse(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: jsonObject.name,
    }));
  }else{
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }
};

const handleSubmit = (e) => {
  e.preventDefault();
  if(checkBlur){
    setBlur(false)
    return;
  }else{ 
  validationSchemaForCompanyUpdate
    .validate(formData, { abortEarly: false })
    .then(() => {
      setBlur(true);
      fetchData()
      .then((data) => {
        if(data.status === true){
          setSessionForIndividual('individual',data.response.result);
          checkProfilePercentage('individual');
          checkDocumentVerification();
          getProviderServiceList(token);
          setShow(true)
        }  
      })
      .catch((error) => {
        console.log(error)
      });
    })
  .catch((validationErrors) => {
      // Validation failed, extract and handle the errors
      const newErrors = {};
      validationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      console.log(newErrors);
      setErrors(newErrors);
    });
  }
};
const openImageInput = () => {
  if (imageInputRef.current) {
    imageInputRef.current.click();
  }
};
const handleDelete=()=>{
  setShow2(true)
}
const handleDeleteAccount = async () => {
  try {
    setShow2(false)
    const response = await axios.post(
      apiBaseUrl+"common/delete-request",
      {}, // Send an empty object as the request data
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json", // Use "Content-Type" instead of "content-type"
        },
      }
    );
    if(response.data.status===true){
      handleLogout();
    }

    // Assuming the response contains the data you need
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
useEffect(() => {
  const fetchData = async () => {
    try {
      const countries = await getCountry();
      setCountry(countries);
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };
  getBanks().then((bankData)=>{
    setBanks(bankData);
  });
  fetchData();
}, []);
const selectgender = async(value)=>{
  setFormData((prevFormData) => ({
    ...prevFormData,
    gender:value
  }));
}
return (
<>
  <Header />
    <div className="dashboard" style={{marginTop:"105px"}}>
      <div className="container">
        <div className="row mt-4 pt-4 mb_view_center">
          <LeftnavbarIndividual/>
          <div className="col-md-12 col-lg-9">
            <div className="form_book_appintment ">
              <div style={{ height: "70px" }} >
                <div className='row'>
                  <div className='col-md-1 col-3'>
                    <div className='v-profile-image'>
                      <div className="add-pro-img">
                        {selectedImage && selectedImage ? (
                          <div className="image-container" onClick={handleCaptureButtonClick} >
                            {/* <img className="circle-img" src={process.env.PUBLIC_URL + "/images/user/circle.png"} alt="profile-add" />
                            <div className="overlay">
                              {selectedImage && (
                              <img src={URL.createObjectURL(selectedImage)} alt="Selected" />)}
                            </div> */}
                            <img src={URL.createObjectURL(selectedImage)} alt="Selected" className="circle-img66" /> 
                            <div className="overlay">
                              {selectedImage && (<img src={URL.createObjectURL(selectedImage)} alt="Selected" />)}
                            </div>
                          </div>
                        ) : (
                        <>
                          <div className={!checkBlur ?"":"blur-effect"} style={{ width: '78px', margin: 'auto', height: '98px' }} >
                            <div onClick={handleCaptureButtonClick}>

                            <CircularProgressbar value={percentage} strokeWidth={6} styles={buildStyles({strokeLinecap: 'butt',pathColor: `rgba(86,209,98, 1, ${percentage / 100})`,textColor: '#24A831',trailColor: '#d6d6d6',})} id="circularbar_dashboard" />
                            <img src={vendor.profile_pic?vendor.profile_pic:BaseUrl+"../images/avatar-default-icon.png"} alt="profile-image" className="app_img_profile_pic" id="dashborad_pro" style={{ width: "60px" }} />
                            <div className='circular_value'>
                              <p className='percentage_value' id="percntage_vendor_dash">{`${percentage}%`}</p>
                            </div>
                                                          
                            </div>
                            <div class="button-container" id='delete_btn_profile' >
                              <button className="button button1" onClick={handleDelete}>Delete Profile</button>
                              <button className="button button2" onClick={handleDelete}>Delete Profile</button>
                            </div>
                          </div>
                          <input type="file" accept="image/*" style={{ display: "none" }} onChange={handleImageSelect} ref={imageInputRef} id="imageInput" />
                        </>
                        )}
                        <input type="file" accept="image/*" style={{ display: "none" }} onChange={handleImageSelect} ref={imageInputRef} />
                      </div>       
                    </div>
                  </div>
                  <div className='col-md-8 col-9 pr-0'>
                    <p className="fill_form" id="fill_form">
                      <span>Complete Your Profile Details. </span> <br />
                    </p>
                  </div>
                  <div className='col-md-3 col-sm-12'>
                    <div className="image-containe">
                      <div class="button-container" style={{width:"186px", marginTop:"-40px"}}>
                        <button className="button button1" onClick={handleSubmit}>   {checkBlur? <> Update Profile</> : <>Save Profile</> }</button>
                        <button className="button button2" onClick={handleSubmit}>   {checkBlur? <> Update Profile</> : <>Save Profile</> }</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 mb_margin  ">
                <div className="col-md-4">
                  <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                    <input type="text" placeholder="Full Name" name="fullName" id="name" oninput="allow_alphabets(this)" value={formData.fullName} onChange={handleChange } />
                  </div>
                  {errors.fullName && (<div className="error">{errors.fullName}</div>)}
                </div>
                <div className="col-md-4">
                  <div className='form_group blur-effect'>
                    <input type="text" placeholder="Email Address" name="email" id="email" value={vendor?.email} onChange={handleChange } />
                  </div>
                  {errors.email && (<div className="error">{errors.email}</div>)}
                </div>
                <div className="col-md-4">
                  <div className="form_group blur-effect">
                    <input type="text" placeholder="Mobile Number" maxlength="10" minlength="10" name="phone" id="phone" onkeypress="return onlyNumberKey(event)" value={vendor?.phone} onChange={handleChange } />
                  </div>
                  {errors.phone && <div className="error">{errors.phone}</div>}
                </div>
              </div>
              <div className="row mb_margin ">
                <div className="col-md-4">
                  <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                    <div className="custom-select-wrapper">
                      <select className={`custom-select ${errors.city ? "VendorInputError" : ""}`} name="country" onChange={handleChange}>
                        <option>{formData.country}</option>
                        {country.map((item, index)=>(
                          <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                        ))}
                      </select>
                      <img src={process.env.PUBLIC_URL + "/images/user/selectArrow.png"} alt="arrow" className="arrow-image" />
                    </div>
                    {errors.country && (<div className="error">{errors.country}</div>)}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                    <div className="custom-select-wrapper">
                      <select className={`custom-select ${errors.city ? "VendorInputError" : ""}`} name="state" onChange={handleChange} >
                        <option>{formData.state}</option>
                        {state.map((item, index)=> (           
                          <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                        ))} 
                      </select>
                      <img src={process.env.PUBLIC_URL + "/images/user/selectArrow.png"} alt="arrow" className="arrow-image" />
                    </div>
                    {errors.state && <div className="error">{errors.state}</div>}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                    <div className="custom-select-wrapper">
                      <select className={`custom-select ${ errors.city ? "VendorInputError" : ""}`} name="city" onChange={handleChange} >
                        <option>{formData.city}</option>
                        {city.map((item, index)=>(
                          <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                        ))}
                      </select>
                      <img src={process.env.PUBLIC_URL + "/images/user/selectArrow.png"} alt="arrow" className="arrow-image" />
                    </div>
                    {errors.city && <div className="error">{errors.city}</div>}
                  </div>
                </div>
              </div>
              <div className="row mb_margin ">
                <div className="col-md-6">
                  <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                    <input type="text" placeholder="Landmark" name="land_mark" id="name" oninput="allow_alphabets(this)" value={formData.land_mark} onChange={handleChange} />
                  </div>
                  {errors.land_mark && (<div className="error">{errors.land_mark}</div>)}
                </div>         
                <div className="col-md-6">
                  <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                    <input type="text" placeholder="Address Line 1 " name="address1" id="name" oninput="allow_alphabets(this)" value={formData.address1} onChange={handleChange } />
                  </div>
                  {errors.address1 && (<div className="error">{errors.address1}</div>)}
                </div>
              </div>
              <div className='row mb_margin '>
                <div className="col-md-6">
                  <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                    <input type="text" placeholder="Address Line 2" name="address2" id="name" oninput="allow_alphabets(this)" value={formData.address2} onChange={handleChange } />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                    <input type="text" placeholder="Postal Code" maxlength="10" minlength="10" name="postal_code" id="phone" onkeypress="return onlyNumberKey(event)" value={formData.postal_code} onChange={handleChange } />
                  </div>
                  {errors.postal_code && (<div className="error">{errors.postal_code}</div>)}
                </div>
              </div>

              <div className={`row mb_margin ${!checkBlur?'form_group':'blur-effect form_group'}`}>
                <div className="col-md-12">
                  <p className="fill_form mb-0"><span>Gender</span></p>
                </div>
                <div className=" d-flex user_selection">
                  <div class="user_name" onClick={()=>{selectgender('Male')}}>
                    <input
                      type="radio"
                      name="gender"
                      value="Male"
                      checked={formData.gender === 'Male'}
                    />
                    <label>Male</label>
                  </div>
                  <div className="user_name" onClick={()=>{selectgender('Female')}}>
                    <input
                      type="radio"
                      name="gender"
                      value="Female"
                      checked={formData.gender === 'Female'}
                    />
                    <label>Female</label>
                  </div>
                  <div className="user_name" onClick={()=>{selectgender('Other')}}>
                    <input
                      type="radio"
                      name="gender"
                      value="Other"
                      checked={formData.gender === 'Other'}
                    />
                    <label>Other</label>
                  </div>
                </div>
                {errors.gender && <div className="error">{errors.gender}</div>}
              </div>

            </div>
              <div className="form_book_appintment mt-4 mb-4 pb-4">
                <div className="d-flex justify-content-between"style={{ height: "70px" }} >
                  <p className="fill_form"> 
                    <span> Verification </span><br/>Upload Your Individual’s Profile Details.
                  </p>
                </div>
                <div>
                  <div className="row mb_margin ">
                    {/* <div className="col-md-4">
                      <div className="blur-effect form_group">
                        <input type="text" placeholder="Company Name" name="name" id="name" oninput="allow_alphabets(this)" value={vendor?.companyName} />
                      </div>
                      {errors.companyName && (<div className="error">{errors.companyName}</div>)}
                    </div> */}
                    {/* <div className="col-md-4">
                      <div className="blur-effect form_group">
                        <input type="text" placeholder="Company Phone No." name="name" id="name" oninput="allow_alphabets(this)" value={vendor?.companyPhone} />
                      </div>
                      {errors.companyRegNo && (<div className="error">{errors.companyPhone}</div>)}
                    </div> */}
                    {/* <div className="col-md-4">
                      <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                        <input type="text" placeholder="Company Registration No." name="companyRegNo" id="name" oninput="allow_alphabets(this)" value={formData.companyRegNo} onChange={handleChange }/>
                      </div>
                      {errors.companyRegNo && (<div className="error">{errors.companyRegNo}</div>)}
                    </div> */}
                    <div className="col-md-4"> 
                      <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                        <input type="text" placeholder="Tax Identification No." name="tinNumber" id="name" oninput="allow_alphabets(this)" value={formData.tinNumber} onChange={handleChange } />
                      </div>
                      {errors.tinNumber && (<div className="error">{errors.tinNumber}</div>)}
                    </div>
                    <div className="col-md-4">
                      <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                        <Select
                          options={banks.map((bank) => ({ value: bank.name, label: bank.name }))}
                          onChange={handleBankChange}
                          value={selectedBank?selectedBank:{value: vendor.bankName, label: vendor.bankName }}
                          isSearchable={true} // Enable search
                          placeholder="Select Bank"
                        />
                      </div>
                      {errors.companyRegNo && (<div className="error">{errors.companyRegNo}</div>)}
                    </div>
                    
                    <div className="col-md-4">
                      <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                        <input type="text" placeholder="BVN Number" name="bvnNo" id="bvnNo" value={formData.companyRegNo} onChange={handleChange }/>
                      </div>
                      {errors.companyRegNo && (<div className="error">{errors.companyRegNo}</div>)}
                    </div>

                    <div className="col-md-4">
                      <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                        <input type="text" placeholder="Bank Account No. " name="bankAcountNumber" id="name" oninput="allow_alphabets(this)" value={formData.bankAcountNumber} onChange={handleChange } />
                      </div>
                      {errors.bankAcountNumber && (<div className="error">{errors.bankAcountNumber}</div>)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  <div className="moile_view">
    <div class="button-container" style={{width:"186px", marginTop:"-40px"}}>
      <button className="button button1" onClick={handleSubmit}>Update Profile</button>
      <button className="button button2" onClick={handleSubmit}>Update Profile</button>
    </div>  
  </div>
  <Footer />
  <Modal show={show} onHide={handleClose} centered style={{ width: "351px",marginLeft: "40%",borderRadius: "34px !important" }} ClassName="custom-modal-dialog" >
    <Modal.Body>
      <div className="cross-X" onClick={handleClose}>
        <img src={process.env.PUBLIC_URL + "../images/user/cross.png"} alt="playstore" />
        <img src={process.env.PUBLIC_URL + "../images/user/X.png"} alt="playstore" />
      </div>
      <div className="congrats-profile-created  well_done" style={{marginTop:"0px"}}>
        <img src={process.env.PUBLIC_URL + "../images/greeting.gif"} alt="playstore" className="gif" />
        <h2>Profile Updated!</h2> 
        <div class="button-container" style={{ width: "150px" }} onClick={handleClose}>
          <button className="button button1" >  Proceed To Next</button>
          <button className="button button2"> Proceed To Next</button>
        </div>
      </div>
      </Modal.Body>
    </Modal> 

    <Modal show={show2} onHide={()=>{setShow2(false)}}>
      <Modal.Header closeButton>
        <Modal.Title> <h6>Are you sure you want to delete this profile?</h6> </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <img src={process.env.PUBLIC_URL + "/images/delete_icon.png" } className="delete_images"></img>
        </Modal.Body>
      <Modal.Footer>
        <div className="proceed_next mt-0 mb-2">
          <div class="button-container edit_proceed mt-4 mb-4" style={{marginRight:"5px"}} onClick={()=>{setShow2(false)}}>
            <button class="button button1" id="btn-otp-resend">No</button>
            <button class="button button2">No</button>
          </div>
          <div class="button-container proccess_next mt-4 mb-4 mr-0" >
            <button class="button button1" onClick={handleDeleteAccount}> Yes </button>
            <button class="button button2" onClick={handleDeleteAccount}>  Yes</button>
          </div>
        </div>
    </Modal.Footer>
  </Modal>
</>
)}

export default IndividualProfile
