import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import DashboardAsVendor from "../../components/dashboardAsVendor";
import { useLocation} from "react-router-dom";
import "./vendor.css";
const Reject_orderdetail = () =>{
    const location = useLocation();
    const data = location.state;console.log(data,"rejct_dat");
    return(
        <>
          <Header />
        <div className="dashboard">
    <div className="container">
        <div className="row mx-0">
         <DashboardAsVendor/>
            <div className="col-md-9">
             <div className="vendors_right_forms">
             <div className="row">
                    <div className="col-ms-12">
                        <h1 className="my_order">Order History </h1>
                        <h2 className="order_details">Your Order Records & Details.</h2>
                    </div>
                </div>
             <div className="right_side  mt-3">
               <div className="row mx-0" >
                <div className="col-8">
                  <div className="btn_es_align">
                   <button className="arrow_down"><Link to="/vendor/order-history"><img src={process.env.PUBLIC_URL + "/vendor_dashboard/order_detail/order_details.png"} /></Link></button>
                  </div>

                 
                    <div className="card_details">
                        <div className="card_heading">
                            <h1>Service Location</h1>
                        </div>
                        <div className="card_content">
                          
                            <span><img src={process.env.PUBLIC_URL + "/images/card_location.png"} alt="app"  /> 
                            <p>{data?.service_location?.land_mark},{data?.service_location?.address1} , {data?.service_location?.city} ,{data?.service_location?.state} ,{data?.service_location?.postal_code} ,{data?.service_location?.country}</p></span>
                          
                        </div>
                    </div>
                  
                    <div className="card_details">
                        <div className="card_heading">
                            <h1>Slot Date & Timing</h1>
                        </div>
                        <div className="card_content">
                          <ul>
                            <li><span>Service Date</span> <p>{data.service_date}</p></li>
                            <li><span>Service Timing</span> <p>{data.service_time}</p></li>
                          </ul>
                        </div>
                    </div>
                    <div className="card_details mb-2">
                        <div className="card_heading">
                            <h1>Service Detail</h1>
                        </div>
                        <div className="card_content">
                            <h2>Plumber, Sink Repairing </h2>
                            <p className="my-2">It is a long established fact that a read will be distracted by the read able of a page. It is a long established fact that a read will be distracted.</p>
                          <div className="plumbering_img">
                            <img src={process.env.PUBLIC_URL + "/images/plumber.png"} />
                            <img src={process.env.PUBLIC_URL + "/images/plumber.png"} />
                            <img src={process.env.PUBLIC_URL + "/images/plumber.png"} />
                          </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                
                    <div className="payment_details">
                     <h1>Payment Detail</h1>
                     
                     <div className="vendor_payment_status">
                   
                     <input placeholder="Payment Status"  className="payment_status"/>
                     <button className="prepaid_post">{data.job_status}</button>
                     </div>
                     <ul className="mt-2"> 
                        <li className="pt-0"><p>Total Amount</p> <span>₦ {data.amount}</span></li>
                        <li><p>Discount</p> <span className="text_change_color">₦ {data.discount_amount?data.discount_amount: "0"}</span></li>
                        <li><p>Other Charges</p>         <span>{data.other_charge === '0' ? <p>Free</p> : data.other_charge?data.other_charge :"0"}</span></li>
                        <li className="pb-0 bottom_none"><p className="text_change_color">Amount Paid</p> <span className="text_change_color">₦{data.amount}</span></li>
                     </ul>
                    </div>
                    
                </div>
               </div>
                
             </div>
             </div>
            </div>
        </div>
    </div>
   </div>
        <Footer />
        </>
    );
};

export default Reject_orderdetail;