import { key,PAYSTACK_SK_KEY,PAYSTACK_PK_KEY,apiBaseUrl } from "../config/constant";
import { logout,setSession,getSession } from "../utils/helper";
import axios from "axios";

export  const  timeDifference=(timestamp)=> {
    const currentTimestamp = Date.now();
    const difference = currentTimestamp - timestamp;
  
    // Convert milliseconds to seconds, minutes, hours, and days
    const seconds = Math.floor(difference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
  
    if (weeks > 0) {
      return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (seconds > 0) {
      return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
    } else {
      return 'Just now';
    }
}

export   const handleLogout=()=>{
    logout();
    localStorage.removeItem('p_vendorService');
    localStorage.removeItem('p_service_list');

    window.location = "/";
}
export function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  return date.toLocaleString(); 
}
// /////////////////////////  get country details //////////////////////////////

export const getCountry = async () => {
  try {
    const headers = { key: key };
    const apiUrl = apiBaseUrl+'common/get-country';
    const response = await axios.post(apiUrl, null, { headers });
    const data = response.data.response.result;
    return data;
  } catch (error) {
    console.error('Error fetching country data:', error);
    throw error; 
  }
};
// ////////////////////////        end              /////////////////
// /////////////////////////  get state details //////////////////////////////
export const getSate = async (countryId) => {
  try {
    // Define the headers as an object
    const headers = {
      key: key, 
    };

    // Define the API endpoint URL
    const apiUrl = apiBaseUrl+'common/get-state';

    const response = await axios.post(apiUrl, 
      {

        country_id: countryId
      }
      , { headers });

    // Handle the response data here
    const data = response.data.response.result;
    return data;
  } catch (error) {
    // Handle errors here
    console.error('Error fetching country data:', error);
    throw error; // Rethrow the error to propagate it to the caller
  }
};
// ////////////////////////////// end////////////////////////////////
export const getCity = async (countryId) => {
  try {
    // Define the headers as an object
    const headers = {
      key: key, 
    };

    // Define the API endpoint URL
    const apiUrl = apiBaseUrl+'common/get-city';

    const response = await axios.post(apiUrl, 
      {

        state_id: countryId
      }
      , { headers });

    // Handle the response data here
    const data = response.data.response.result;
    return data;
  } catch (error) {
    // Handle errors here
    console.error('Error fetching country data:', error);
    throw error; // Rethrow the error to propagate it to the caller
  }
};

export const getWalletData= async(auth)=>{
// alert(auth)
 try {

  const headers = {
    Authorization: auth, 
  };
  const apiUrl = apiBaseUrl+'/provider/get-wallet-data';
  const response = await axios.get(apiUrl,{ headers });
return response.data;
 }
 catch(error){
  console.error('Error fetching country data:', error);
  throw error; 
 } 
}

export const loginUserCheck= async (auth)=>{
  try{
    const headers={
      Authorization: auth, 
    };
    const apiUrl = apiBaseUrl+'provider/verify-user';
    const response =await axios.post(apiUrl,null,{headers});
    return response.data;
  }
  catch(error)
  { console.error(error) }
}

export const  VerifyUser=async(otp,auth)=>{
  try{
  const headers={
    Authorization: auth, 
  };
  const apiUrl= apiBaseUrl+'provider/verify-otp';
  const response =await axios.post(apiUrl,{otp : otp},{headers});
  return response;
  }catch(error){
    console.error(error)
    return error?.response?.data;
  }
}

export const sentWithdrawRequest = async(auth, amount)=>{
  try{
    const headers={
      Authorization: auth, 
    };
    const apiUrl= apiBaseUrl+'provider/sent-withdraw-request';
    const response =await axios.post(apiUrl,{amount : amount, platform : 'web'},{headers});
    return response;
  }catch(error){
    console.error(error)
    return error?.response?.data;
  }
}

export function isNumeric(value) {
  return !isNaN(value) && isFinite(value);
}
export function isEmail(value) {
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i; // Basic email regex pattern
  return emailPattern.test(value);
}

const fetchGeoLocationData = async (latitude, longitude) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDgD56S6ZLFVJgzD8GPnMDPnV6vrhXGroY`
    );
    if (response.data.results.length > 0) {
      let country, state, city, address, postalCode ="";
      const addressComponents = response.data.results[0].address_components;
      for (const component of addressComponents) {
        if (component.types.includes('country')) {
          country = component.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          state = component.long_name;
        } else if (component.types.includes('locality')) {
          city = component.long_name;
        } else if (component.types.includes("sublocality_level_1")) {
          address = component.long_name;
        } else if (component.types.includes('postal_code')) {
          postalCode = component.long_name;
        }
      }
      const resultData = {
        'address': address,
        'city': city,
        'state': state,
        'country': country,
        'latitude': latitude,
        'longitude': longitude,
        'postalCode' : postalCode,
      };
      return resultData;
    } else {
      // console.error("No results found for the provided coordinates.");
      return null;
    }
  } catch (error) {
    console.error('Error fetching geolocation data:', error);
    return null;
  }
};

export const getGeoLocation=()=>{
  try {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const resultData = await fetchGeoLocationData(latitude, longitude);
            resolve(resultData);
          },
          (error) => {
            resolve('')
          }
        );
      } else { resolve('') }
    });
  } catch (error) { return false; }
}

// /////////////////////////  get banks List //////////////////////////////
export const getBanks = async () => {
  try {
    const headers = { Authorization: "Bearer "+PAYSTACK_PK_KEY, Accept : "application/json" };
    // Define the API endpoint URL
    const apiUrl = 'https://api.paystack.co/bank?country=nigeria';
    const response = await axios.get(apiUrl,{ }, { headers });
    const data = response.data.data;
    return data; 
  } catch (error) {
    // Handle errors here
    console.error('Error fetching country data:', error);
    throw error; // Rethrow the error to propagate it to the caller
  }
};
// ////////////////////////////// end////////////////////////////////

// /////////////////////////  get banks List //////////////////////////////
export const verifyBVN = async (account_number,bank_code) => {
  try {
    return true;
    const headers = { 'Accept': 'application/json', 'Authorization': `Bearer ${PAYSTACK_SK_KEY}` }
    const apiUrl = `https://api.paystack.co/bank/resolve?account_number=${account_number}&bank_code=${bank_code}`;
    const response = await axios.get(apiUrl, { headers });
    return true;
    // const data = response.data.data;
    // return data; 
  } catch (error) {
    // Handle errors here
    console.error('Error fetching country data:', error);
    return false;
    // throw error; // Rethrow the error to propagate it to the caller
  }
};
// ////////////////////////////// end////////////////////////////////

// /////////////////////////  Verify Email //////////////////////////////
export const verifyPhone = async (auth) => {
  try {
    const headers = { Authorization: auth };
    const apiUrl = `${apiBaseUrl}provider/verify-phone`;
    const response = await axios.post(apiUrl,{}, { headers });
    
    return response.data.response.result; 
  } catch (error) {
    console.error('Error fetching country data:', error);
    return false; // Rethrow the error to propagate it to the caller
  }
};
// ////////////////////////////// end////////////////////////////////
// /////////////////////////  Verify Phone OTP //////////////////////////////
export const verifyPhoneOTP = async (otp,auth) => {
  try {
    const headers = { Authorization: auth };
    const apiUrl = `${apiBaseUrl}provider/verify-phone-otp`;
    const response = await axios.post(apiUrl,{otp : otp}, { headers });
    if(response.status === 200 || response.status === '200'){
      // let sessionData;
      if(getSession('vendor')){
        const sessionData = getSession('vendor');
        const newSessionData = {...sessionData, isMobileVerified : 1};
        setSession('vendor',newSessionData);
        return true; 
      } else{
        const sessionData = getSession('individual');
        const newSessionData = {...sessionData, isMobileVerified : 1};
        setSession('individual',newSessionData);
        return true; 
      }
    } else{
      return false;
    }
  } catch (error) {
    console.error('Error fetching country data:', error);
    return false; // Rethrow the error to propagate it to the caller
  }
};
// ////////////////////////////// end////////////////////////////////
// /////////////////////////  Verify Email //////////////////////////////
export const verifyEmail = async (auth) => {
  try {
    const headers = { Authorization: auth };
    const apiUrl = `${apiBaseUrl}provider/verify-email`;
    const response = await axios.post(apiUrl,{}, { headers });
    return response.data.response.result; 
  } catch (error) {
    console.error('Error fetching country data:', error);
    return false; // Rethrow the error to propagate it to the caller
  }
};
// ////////////////////////////// end////////////////////////////////
// /////////////////////////  Verify Email OTP //////////////////////////////
export const verifyEmailOTP = async (otp,auth) => {
  try {
    const headers = { Authorization: auth };
    const apiUrl = `${apiBaseUrl}provider/verify-email-otp`;
    const response = await axios.post(apiUrl,{otp : otp}, { headers });
    if(response.status === 200 || response.status === '200'){
      if(getSession('vendor')){
        const sessionData =  getSession('vendor');
        const newSessionData = {...sessionData, isEmailVerified : 1};
        setSession('vendor',newSessionData); 
        return true;
      } else{
        const sessionData =  getSession('individual');
        const newSessionData = {...sessionData, isEmailVerified : 1};
        setSession('individual',newSessionData); 
        return true;
      }
    } else {
      return false;
    }
    
  } catch (error) {
    console.error('Error fetching country data:', error);
    return false; // Rethrow the error to propagate it to the caller
  }
};
// ////////////////////////////// end////////////////////////////////

// /////////////////////////  Provider Update Profile //////////////////////////////
export const providerUpdateProfile = async (params,auth,type='') => {
  try {
    const headers = { Authorization: auth };
    const apiUrl = `${apiBaseUrl}provider/update-profile-details`;
    const response = await axios.post(apiUrl,params, { headers });
    if(response.status === '200' || response.status === 200){
      if(type === 'BVN'){
        if(getSession('vendor')){
          const sessionData =  getSession('vendor');
          const newSessionData = {...sessionData, isBVNVerify : 1, ...(params?.bankName?{bankName:params.bankName}:null)};
          setSession('vendor',newSessionData);
        } else{
          const sessionData =  getSession('individual');
          const newSessionData = {...sessionData, isBVNVerify : 1, ...(params?.bankName?{bankName:params.bankName}:null)};
          setSession('individual',newSessionData);
        }
      }
    }
    return true; 
  } catch (error) {
    console.error('Error fetching country data:', error);
    return false; // Rethrow the error to propagate it to the caller
  }
};
// ////////////////////////////// end////////////////////////////////
// /////////////////////////  Provider Update Profile //////////////////////////////
export const getProviderServiceList = async (auth) => {
  try {
    const headers = { Authorization: auth };
    const apiUrl = `${apiBaseUrl}provider/check-provider-service`;
    const response = await axios.get(apiUrl, { headers });
    if(response.status === '200' || response.status === 200){
      const count = response?.data?.response?.result;
      if(count > 0){
        if(getSession('vendor')){
          const sessionData =  getSession('vendor');
          const newSessionData = {...sessionData, serviceAddded : 1};
          setSession('vendor',newSessionData); 
        } else{
          const sessionData =  getSession('individual');
          const newSessionData = {...sessionData, serviceAddded : 1};
          setSession('individual',newSessionData); 
        }
      }else{
        if(getSession('vendor')){
          const sessionData =  getSession('vendor');
          const newSessionData = {...sessionData, serviceAddded : null};
          setSession('vendor',newSessionData); 
        } else{
          const sessionData =  getSession('individual');
          const newSessionData = {...sessionData, serviceAddded : null};
          setSession('individual',newSessionData); 
        } 
      }
      return true; 
    }else{
      return false; 
    }
  } catch (error) {
    console.error('Error fetching country data:', error);
    return false; // Rethrow the error to propagate it to the caller
  }
};
// ////////////////////////////// end////////////////////////////////
export const checkDocumentVerification = async () =>{
  if(getSession('vendor')){
    const sessionData =  getSession('vendor');
    if(sessionData.certificate){
      const newSessionData = {...sessionData, isUploadDocument : 1};
      setSession('vendor',newSessionData);
    }
    return true;
  } else{
    const sessionData =  getSession('individual');
    if(sessionData.bankAcountNumber){
      const newSessionData = {...sessionData, isUploadDocument : 1};
      setSession('individual',newSessionData);
      return true;
    }
  }
  
}
// /////////////////////////  Check user isCompleteProfile //////////////////////////////
export const isUerPrifileComplete = () => {
try {
  const userData = getSession('user');
  if(userData.documentType && userData.documentNo && userData.country && userData.profile_pic){
    const newSessionData = {...userData, isProfileComplete : 1};
    setSession('user',newSessionData);
    return false;
  }else{
    return true;
  }
} catch (error) {
  console.log(error);
  return false;
}
}
// ////////////////////////////// end////////////////////////////////
// /////////////////////////  Check user isCompleteProfile //////////////////////////////
export const isAdressUpdated = () => {
  try {
    const userData = getSession('user');
    if(userData.addressData && userData.addressData.length > 0 ){
      const newSessionData = {...userData, isAdress : 1};
      setSession('user',newSessionData);
      return false;
    }else{
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
  }
  // ////////////////////////////// end////////////////////////////////
// /////////////////////////  Verify User Email //////////////////////////////
export const verifyUserPhone = async (auth) => {
  try {
    const headers = { Authorization: auth };
    const apiUrl = `${apiBaseUrl}users/verify-phone`;
    const response = await axios.post(apiUrl,{}, { headers });
    console.log(response);
    return response.data.response.result; 
  } catch (error) {
    console.error('Error fetching country data:', error);
    return false; // Rethrow the error to propagate it to the caller
  }
};
// ////////////////////////////// end////////////////////////////////
// /////////////////////////  Verify Phone OTP //////////////////////////////
export const verifyUserPhoneOTP = async (otp,auth) => {
  try {
    const headers = { Authorization: auth };
    const apiUrl = `${apiBaseUrl}users/verify-phone-otp`;
    const response = await axios.post(apiUrl,{otp : otp}, { headers });
    if(response.status === 200 || response.status === '200'){
      const sessionData = getSession('user');
      const newSessionData = {...sessionData, isMobileVerified : 1};
      setSession('user',newSessionData);
      return true;
    } else{
      return false;
    }
  } catch (error) {
    console.error('Error fetching country data:', error);
    return false; // Rethrow the error to propagate it to the caller
  }
};
// ////////////////////////////// end////////////////////////////////
// /////////////////////////  Verify Email //////////////////////////////
export const verifyUserEmail = async (auth) => {
  try {
    const headers = { Authorization: auth };
    const apiUrl = `${apiBaseUrl}users/verify-email`;
    const response = await axios.post(apiUrl,{}, { headers });
    return response.data.response.result; 
  } catch (error) {
    console.error('Error fetching country data:', error);
    return false; // Rethrow the error to propagate it to the caller
  }
};
// ////////////////////////////// end////////////////////////////////

// /////////////////////////  Verify Email OTP //////////////////////////////
export const verifyUserEmailOTP = async (otp,auth) => {
  try {
    const headers = { Authorization: auth };
    const apiUrl = `${apiBaseUrl}users/verify-email-otp`;
    const response = await axios.post(apiUrl,{otp : otp}, { headers });
    if(response.status === 200 || response.status === '200'){
      const sessionData =  getSession('user');
        const newSessionData = {...sessionData, isEmailVerified : 1};
        setSession('user',newSessionData);
        return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching country data:', error);
    return false; // Rethrow the error to propagate it to the caller
  }
};
// ////////////////////////////// end////////////////////////////////

export const checkProfilePercentage = async (token) => {
  if(token){
    const sessionData =  getSession(token);
    if(sessionData && sessionData.profileComplete){
      let percentage = 0;
      if(sessionData.isMobileVerified === 1){ 
        percentage = percentage + 5;
      }
      if(sessionData.isEmailVerified === 1){
        percentage = percentage + 5;
      }
      if(sessionData.userType === 'Company'){
        if(sessionData.isBVNVerify === 1){
          percentage = percentage + 5;
        }
        if(sessionData.workers.length > 0){
          percentage = percentage + 5;
        }
        const profilePercentage = 75 + percentage;
        
        const newSessionData = {...sessionData, profileComplete : profilePercentage};
        setSession(token,newSessionData);
        // console.log('profilePercentage',profilePercentage);
        return true;
      } else if(sessionData.userType === 'Individual'){
        if(sessionData.isBVNVerify === 1){
          percentage = percentage + 5;
        }
        const profilePercentage = 80 + percentage;
        const newSessionData = {...sessionData, profileComplete : profilePercentage};
        setSession(token,newSessionData);
        return true;
      } else {
        if(sessionData?.addressData && sessionData?.addressData.length > 0 ){ percentage = percentage + 5; }
        if(sessionData?.documentNo && sessionData?.documentType){ percentage = percentage + 5; }
        const profilePercentage = 80 + percentage;
        const newSessionData = {...sessionData, profileComplete : profilePercentage};
        setSession(token,newSessionData);
      }
    } else{
      return false;
    }
  } else{
    return false;
  }
  
}
export const handleGoogleLoginByUser = async(email) =>{
  try {
    if(email){
      const headers = { key: key };
      const apiUrl = `${apiBaseUrl}users/get-google-auth-key`;
      const response = await axios.post(apiUrl,{email : email}, { headers });
      if(response.status === 200 || response.status === '200'){
        console.log(response?.data?.response?.result);
        return response?.data?.response?.result;
        // const sessionData =  getSession('user');
        // const newSessionData = {...sessionData, isEmailVerified : 1};
        // setSession('user',newSessionData);
        // return true;
      } else {
        return false;
      }
    }else{
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const handleUserLoginWithGoogle = async(token='') =>{
  try {
    if(token){
      const headers = { Authorization: token };
      const apiUrl = `${apiBaseUrl}users/google-login`;
      const response = await axios.post(apiUrl,{}, { headers });
      if(response.status === 200 || response.status === '200'){
        console.log('user Data',response?.data?.response?.result);
        if(response?.data?.response?.result?.flag && response?.data?.response?.result?.flag === 'new_user'){
          setSession('user',response?.data?.response?.result);
          return 'new_user';
        } else{
          setSession('user',response?.data?.response?.result);
          setSession('token',response?.data?.response?.result.token);
          return true;
        }
        // const sessionData =  getSession('user');
        // const newSessionData = {...sessionData, isEmailVerified : 1};
        // setSession('user',newSessionData);
      } else {
        return false;
      }
    }else{
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}
//Request new Sub Service
export const requestNewSubservice = async (options) =>{
  try {
    const {token, service, subservice}= options;
    if(!token){
      return JSON.stringify({ status : false, message : "Token required" });
    } else if(!service){
      return JSON.stringify({ status : false, message : "Service is required" });
    } else if(!subservice){
      return JSON.stringify({ status : false, message : "Sub-Service is required" });
    } else {
      const headers = { Authorization: token };
      const apiUrl = `${apiBaseUrl}provider/request-new-sub-service`;
      const response = await axios.post(apiUrl,
        { service : service, subservice : subservice }, 
        { headers });
      if(response.status === 200 || response.status === '200'){
        return JSON.stringify({ status : true, message : "Request sent" });
      } else {
        console.log(response);
        return JSON.stringify({ status : false, message : "Request not Sent" });
      }
    }
  } catch (error) {
    return JSON.stringify({ status : false, message : error?.response?.data?.statusMessage });
  }
}
/****************************************
 * *** Switch Online Status fo worker ***
 ****************************************/
export const chnageWorkerOnlineStatus = async (options) => {
  try {
    const {token, worker_id, status}= options;
    if(!token){
      return JSON.stringify({ status : false, message : "Token required" });
    } else if(!worker_id){
      return JSON.stringify({ status : false, message : "Worker is required" });
    } else if(!status){
      return JSON.stringify({ status : false, message : "Status is required" });
    } else {
      const headers = { Authorization: token };
      const apiUrl = `${apiBaseUrl}provider/switch-worker-online-status`;
      const response = await axios.post(apiUrl,
        { workder_id : worker_id, status : status }, 
        { headers });
      if(response.status === 200 || response.status === '200'){
        // console.log('response',response);
        return JSON.stringify({ status : true, message : "Request sent" });
      } else {
        return JSON.stringify({ status : false, message : "Request not Sent" });
      }
    }
  } catch (error) {
    return JSON.stringify({ status : false, message : error?.response?.data?.statusMessage });
  }
}

/****************************************
 * *** Validate Job Start Date and Time ***
 ****************************************/

export const validateJobStartDateAndTime = (options) => {
  const currentDate = new Date();
  let serviceDate;
  if (options.service_date.includes('/')) {

  const [day, month, year] = options.service_date.split('/').map(part => parseInt(part, 10));
  const adjustedYear = year < 100 ?  year : year; // Adjust the year if it's a two-digit year
  serviceDate = new Date(adjustedYear, month - 1, day); // Month is zero-indexed
  }else{
    const [day, month, year] = options.service_date.split('-').map(part => parseInt(part, 10));
    const adjustedYear = year < 100 ? 2000+ year : year; // Adjust the year if it's a two-digit year
    serviceDate = new Date(adjustedYear, month - 1, day); // Month is zero-indexed
  }
  // Parse service time
  const [hours, minutes] = options.service_time.split(':').map(part => parseInt(part, 10));

  // Set the time part of both dates
  currentDate.setHours(hours, minutes, 0, 0);
  serviceDate.setHours(hours, minutes, 0, 0);

  if (currentDate > serviceDate) {
    return { status: true, message: "Current date and time are later than the service date and time." };
  } else if (currentDate < serviceDate) {
    return { status: false, message: "You are not allowed to start the job before the service date and time." };
  } else {
    return { status: true, message: "Current date and time are the same as the service date and time." };
  }
};





export  const formatTime = (time) => {
  const [hour, minute] = time.split(':');
  const formattedHour = (hour % 12 === 0 ? 12 : hour % 12).toString().padStart(2, '0');
  const formattedMinute = minute.padStart(2, '0');
  const period = hour < 12 ? 'AM' : 'PM';
  return `${formattedHour}:${formattedMinute} ${period}`;
};


export     const generateTimes = () => {
  const currentTime = new Date();
  let currentHour = currentTime.getHours();
  const currentMinute = currentTime.getMinutes();
  let nextHour = currentHour + 1;

  if (currentMinute > 30) {
    nextHour += 1; // Move to the next hour if the current minute is more than 30
  }

  const dynamicTimes = [];

  while (nextHour <= 23) {
    const hour = nextHour < 10 ? `0${nextHour}` : `${nextHour}`;
    dynamicTimes.push(`${hour}:00`);
    dynamicTimes.push(`${hour}:30`);
    nextHour += 1;
  } 
  return dynamicTimes
};

/****************************************
 * ** Get User Details & Validate user **
 ****************************************/
export const validateuserToken = async (token) => {
  try {
    if(!token){
      return JSON.stringify({ status : false, message : "Token required" });
    } else {
      const headers = { Authorization: token };
      const apiUrl = `${apiBaseUrl}users/get-user-data`;
      const response = await axios.get(apiUrl, 
        { headers });
      if(response.status === 200 || response.status === '200'){
        return JSON.stringify({ status : true, message : "Request sent" });
      } else {
        return JSON.stringify({ status : false, message : "Request not Sent" });
      }
    }
  } catch (error) {
    if(error?.response?.status === 401 || error?.response?.statusText === "Unauthorized"){
      return JSON.stringify({ status : 401, message : error?.response?.data?.statusMessage });
    }
    return JSON.stringify({ status : false, message : error?.response?.data?.statusMessage });
  }
}
/****************************************
 * ** Get Provider/Worker Details & Validate Provider/Worker **
 ****************************************/
export const validateProviderToken = async (token) => {
  try {
    if(!token){
      return JSON.stringify({ status : false, message : "Token required" });
    } else {
      const headers = { Authorization: token };
      const apiUrl = `${apiBaseUrl}provider/get-user-data`;
      const response = await axios.get(apiUrl, 
        { headers });
      if(response.status === 200 || response.status === '200'){
        return JSON.stringify({ status : true, message : "Request sent" });
      } else {
        return JSON.stringify({ status : false, message : "Request not Sent" });
      }
    }
  } catch (error) {
    if(error?.response?.status === 401 || error?.response?.statusText === "Unauthorized"){
      return JSON.stringify({ status : 401, message : error?.response?.data?.statusMessage });
    }
    return JSON.stringify({ status : false, message : error?.response?.data?.statusMessage });
  }
}

export function capitalizeEachWord(str) {
  return str.replace(/\b\w/g, (match) => match.toUpperCase());
}

export   const getAgoTime2 = (timestamp) => {
  const now = new Date();
  const timeDifference = now - timestamp;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} days ago`;
  } else if (hours > 0) {
    return `${hours} hours ago`;
  } else if (minutes > 0) {
    return `${minutes} minutes ago`;
  } else {
    return `${seconds} seconds ago`;
  }
};
export   const getAgoTime = (timestamp) => {
  const now = new Date();
  const timeDifference = now - timestamp;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} days ago`;
  } else if (hours > 0) {
    return `${hours} hours ago`;
  } else if (minutes > 0) {
    return `${minutes} minutes ago`;
  } else {
    return `${seconds} seconds ago`;
  }
};

// valide email or not
export function isValidEmail(email) {
  // Regular expression pattern for valid email addresses
  var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(email);
}


