import React, { useState ,useRef, useEffect } from "react";
import "./userRegister.css";
import { Container } from "react-bootstrap";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Modal from "react-bootstrap/Modal";
import { Link,useNavigate,useLocation } from "react-router-dom";
import {userRegisterationSchema } from "../../components/validators/vendorFormValidator";
import axios from "axios";
import { setSession , setSessionForLoginUser,getSession } from "../../utils/helper";
import { isNumeric,isEmail,getCountry,getSate,getCity,getGeoLocation } from "../../components/common";
import { BaseUrl, apiBaseUrl } from "../../config/constant";

const CreateProfile = () => {
const navigate = useNavigate();
const location = useLocation();
const temp = location.state
const searchParams = new URLSearchParams(location.search);
const receivedData = searchParams.get('data');
const [isSectionVisible, setSectionVisible] = useState(false);
const [checkBlur, setBlur] = useState(false);
const [show, setShow] = useState(false);
const [errors, setErrors] = useState("");
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
const [country, setCountry] = useState([]);
const[ state, setState ]=useState([]);
const[city, setCity]=useState([]);
const [geoLocData, setGeoLocData] =useState([]);
const [success, setSuccess] = useState("");
const [isRequestSent, setRequestSent] = useState(false);

const profileDetailsHandler = () => {
  if (!isSectionVisible) {
    setSectionVisible(true);
    setBlur(false);
  } else { setBlur(!checkBlur) }
};

///////////////////////////////////////// API Call ////////////////////////////////
const certificateImage = new File([''], 'profile_pic.png', { type: 'image/png' });
// console.log(receivedData);
const [formData, setFormData] = useState({
  fullName: "",
  email: typeof receivedData === 'string' && !isNaN(receivedData) && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(receivedData) ? receivedData:'' ||temp?.email,
  phone: typeof receivedData === 'number' && !isNaN(receivedData) ? receivedData : '',
  country: "",
  state: "",
  city: "",
  address1: "",
  address2: "",
  land_mark: "",
  postal_code: "",
  // document: certificateImage,
  platform: "Web",
  documentType: "",
  documentNo: "",
  gender: "",
  profile_pic: null ,
  userType:"User",
  isEmailAvailable: temp?temp.isEmailAvailable: null
});
// console.log(formData)
const fetchData = async () => {
  try {
    
    const form = new FormData();
    form.append("fullName", formData.fullName);
    form.append("email", formData.email);
    form.append("phone", formData.phone);
    form.append("country", formData.country);
    form.append("gender", formData.gender);
    form.append("state", formData.state);
    form.append("city", formData.city);
    form.append("land_mark", formData.land_mark);
    form.append("postal_code", formData.postal_code);
    form.append("address1", formData.address1);
    form.append("address2", formData.address2);
    // form.append("latitude", formData.latitude);
    // form.append("longitude", formData.longitude);
    form.append("platform", formData.platform);
    // form.append("profile_pic", formData.profile_pic); 
    if(!isRequestSent){
      setRequestSent(true);
      const response = await axios.post(
        apiBaseUrl+"users/user-register",
         formData,
         {
           headers: {
             key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
             "Content-Type": "multipart/form-data",
           },
         }
       );
       return(response.data)
    }else{
      const response = await axios.post(
        apiBaseUrl+"users/user-register",
         formData,
         {
           headers: {
             key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
             "Content-Type": "multipart/form-data",
           },
         }
       );
       return(response.data)
    }
  } catch (error) {
    setRequestSent(false);
    console.error(error);
  }
};
const handleChange = async (e) => {
  const { name, value } = e.target;
  if(name==='country'){
    const jsonObject = JSON.parse(value);
    const country= await  getSate(jsonObject.id);
    setState(country)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: jsonObject.name,
    }));
  }else if(name==='state'){
    const jsonObject = JSON.parse(value);
    const state= await  getCity(jsonObject.id);
    setCity(state)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: jsonObject.name,
    }));
  }else if(name==='city'){
    const jsonObject = JSON.parse(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: jsonObject.name,
    }));
  }else if(name==='phone'){
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    const checkPhone = value.length > 9;
      if(checkPhone){
        await checkDuplicatePhone(value);
      } else{
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear the error message for the corresponding field
        }));
      }
  } else if(name==='email'){
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    const checkEmail = String(value).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if(checkEmail){
      await checkDuplicateEmail(value);
    } else{
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Clear the error message for the corresponding field
      }));
    }
  }else {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));}
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", 
    }));
};

//CHeck Duplicate Email
const checkDuplicateEmail = async (email) => {
  try {

    const response = await axios.post(
      apiBaseUrl+"users/check-user-email",
      {email : email},
      {
        headers: {
          key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed"
        },
      }
    );
    if(response?.data?.responseCode === 200 || response?.data?.responseCode === '200'){
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "", 
        isEmailAvailable : ""
      }));

      setSuccess((prevSuccess) => ({
        ...prevSuccess,
        email: "Available", 
      }));

      setFormData((prevFormData) => ({
        ...prevFormData,
        isEmailAvailable: 1,
        email : email
      }));
    }
    return true;
  } catch (error) {
    console.log(error?.response?.data?.statusMessage);
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: error?.response?.data?.statusMessage, 
      isEmailAvailable : error?.response?.data?.statusMessage,
    }));
    setSuccess((prevSuccess) => ({
      ...prevSuccess,
      email: "", 
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      isEmailAvailable: 0,
      email : email
    }));
    console.log(errors);
    return false;

  }
}  //End of function

//CHeck Duplicate Email
const checkDuplicatePhone = async (phone) => {
  try {
    const response = await axios.post(
      apiBaseUrl+"users/check-user-phone",
      {phone : phone},
      {
        headers: {
          key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed"
        },
      }
    );
    if(response?.data?.responseCode === 200 || response?.data?.responseCode === '200'){
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: "", 
        isPhoneAvailable : ""
      }));

      setSuccess((prevSuccess) => ({
        ...prevSuccess,
        phone: "Available", 
      }));

      setFormData((prevFormData) => ({
        ...prevFormData,
        isPhoneAvailable: 1,
      }));
    }
    return true;
  } catch (error) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      companyPhone: error?.response?.data?.statusMessage, 
      isPhoneAvailable : ""
    }));
    setSuccess((prevSuccess) => ({
      ...prevSuccess,
      phone: "", 
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      isPhoneAvailable: 0,
    }));
    return false;
  }
}  //End of function
const handleSubmit = (e) => {
  e.preventDefault();
  userRegisterationSchema
    .validate(formData, { abortEarly: false })
    .then(() => {
      setBlur(true);
      fetchData()
      .then((data) => {
       if(data.status === true){
        const token=data.response.result.token;  
        const userData=data.response.result;
          if(token){
            setSession("token", token);
            setSessionForLoginUser('user',userData)
          } else{
            const token1=data.response.result.userData.token;  
            const userData1=data.response.result.userData;
            if(token1){
              setSession("token", token1);
              setSessionForLoginUser('user',userData1);
              navigate('/user/my_profile');
            }
          }
          if (isSectionVisible && checkBlur) {
            handleShow();
          }
       }
     })
     .catch((error) => { console.log(error) });   
    })
    .catch((validationErrors) => {
      // Validation failed, extract and handle the errors
      const newErrors = {};
      validationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      if(newErrors.email){
        newErrors['isEmailAvailable'] = '';
      }
      if(newErrors.phone){
        newErrors['isPhoneAvailable'] = '';
      }
      // Check if fullName and email fields are filled and validated
      const isFullNameValid = !newErrors.fullName;
      const isEmailValid = !newErrors.email;
      const isPhoneValid = !newErrors.phone;

      if (isFullNameValid && isEmailValid && isPhoneValid) {
        profileDetailsHandler();
      }
      // Update the state with the new errors
      console.log(newErrors);
      setErrors(newErrors);
    });
};

///////////////////////////////////////// End of API Call //////////////////////////////

  // profile Image Capture
  const imageInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageSelect = (event) => {
    const file = event.target.files[0];

    setFormData((prevFormData) => ({
      ...prevFormData,
  profile_pic: file,
    }));
    setSelectedImage(file);
  };

  const handleCaptureButtonClick = () => {
    // Trigger the file input click event
    imageInputRef.current.click();
  };
  // end profile Image Capture
useEffect(() => {
  const isSetUserData = getSession('user');
  const isSetUserToken = getSession('token');
  sessionStorage.removeItem('user');
  if(isSetUserData && isSetUserToken){
    navigate('/user/my_profile');
  }


  const fetchData = async () => {
    try {
      const countries = await getCountry();
      setCountry(countries);
      fetchLocationData(countries);
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };
  const fetchLocationData = async (country) => {
    return new Promise(async (resolve, reject) => {
      try {
        const locData = await getGeoLocation();
        setGeoLocData(locData)
        if(locData && locData.country){
          
        }
        setFormData((prevFormData) => ({
          ...prevFormData,
          address1: locData?.address,
          land_mark : locData?.address,
          postal_code : locData?.postalCode,
          country : locData?.country,
          state : locData?.state,
          city : locData?.city
        }));

        if(locData?.country){
          //Set State
          const matchingCountries = country.filter(country => country.name.includes(locData.country));
          // console.log(matchingCountries);
          if(matchingCountries && matchingCountries.length >0 ){
            const states = await  getSate(matchingCountries[0].id);
            setState(states);
            //End 

            //Set City
            const matchingState = states.filter(state => state.name.includes(locData.state));
            // console.log('matchingState',matchingState);
            const cities= await  getCity(matchingState[0].id);
            setCity(cities);
            //End
          }
        }
        resolve(locData);
      } catch (error) {
        // Handle any errors here
        console.error('Error fetching location data:', error);
        reject(error);
      }
    });
  };
  fetchData();
  const checkEmail = String(receivedData).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    if(!checkEmail){
      checkDuplicatePhone(receivedData);
      setFormData((prevFormData) => ({
        ...prevFormData,
        isEmailVerify: 1,
        phone: receivedData,
      }));
    }else{
      checkDuplicateEmail(receivedData);
      setFormData((prevFormData) => ({
        ...prevFormData,
        isPhoneVerify: 1,
        email:receivedData
      }));
    } 
}, []);
const selectgender = async(value)=>{
  setFormData((prevFormData) => ({
    ...prevFormData,
    gender:value
  }));
}
return (
  <>
    <Header />
    <div className="create-profile" >
      <div className="form_book_appintment">
        <div className="d-flex justify-content-between" style={{ height: "70px" }} >
          <p className="fill_form">
            <span>User's Details </span> <br /> Complete Your User’s Profile Details.
          </p>
        </div>
        <div className="row">
          <div className="col">
            <div className={`form_group ${errors.fullName ? "VendorInputError" : ""}`} >
              <input type="text" onChange={handleChange} placeholder="Full Name" name="fullName" id="name" oninput="allow_alphabets(this)" />
            </div>
            {errors.fullName && (<div className="error">{errors.fullName}</div>)}
          </div>
          <div className="col">
            <div className={`form_group ${errors.email ? "VendorInputError" : ""} ${errors.isEmailAvailable ? "VendorInputError" : ""} ${success.email ? "VendorInputSuccess" : ""}`} >
              <input type="text" onChange={handleChange} placeholder="Email Address" name="email" id="email" value={isEmail(receivedData)?receivedData:formData.email} />
            </div>
            {errors.email && <div className="error">{errors.email}</div>}
            {errors.isEmailAvailable && <div className="error">{errors.isEmailAvailable}</div>}
          </div>
          <div className="col">
            <div className={`form_group blur-effect ${errors.phone ? "VendorInputError" : ""} ${errors.isPhoneAvailable ? "VendorInputError" : ""} ${success.phone ? "VendorInputSuccess" : ""}`} >
            <input type="number" placeholder="Mobile Number" maxlength="10" minlength="10" name="phone" id="phone" onkeypress="return onlyNumberKey(event)" value={receivedData} />
          </div>
          {errors.phone && <div className="error">{errors.phone}</div>}
        </div>
        <div className="col-sm-12 col-xs-12 col-md-3 col-lg-3">
          <div className="form_group">
            <div className="custom-select-wrapper">
              <select className={`custom-select ${errors.city ? "VendorInputError" : ""}`} name="country" onChange={handleChange} >
                <option>Select Country</option>
                {country.map((item, index)=>(
                  <option selected={item.name === formData.country} key={index} value={JSON.stringify(item)}>{item.name}</option>
                ))}
              </select>
              <img src={process.env.PUBLIC_URL + "/images/user/selectArrow.png"} alt="arrow" className="arrow-image" />
            </div>
            {errors.country && (<div className="error">{errors.country}</div>)}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-xs-12 col-md-3 col-lg-3">
          <div className="form_group">
            <div className="custom-select-wrapper">
              <select className={`custom-select ${errors.city ? "VendorInputError" : ""}`} name="state" onChange={handleChange} >
                <option>Select State</option>
                {state.map((item, index)=> (           
                  <option selected={item.name === formData.state} key={index} value={JSON.stringify(item)}>{item.name}</option>
                ))} 
              </select>
              <img src={process.env.PUBLIC_URL + "/images/user/selectArrow.png"} alt="arrow" className="arrow-image" />
            </div>
            {errors.state && <div className="error">{errors.state}</div>}
          </div>
        </div>
        <div className="col-sm-12 col-xs-12 col-md-3 col-lg-3">
          <div className="form_group">
            <div className="custom-select-wrapper">
              <select className={`custom-select ${errors.city ? "VendorInputError" : ""}`} name="city" onChange={handleChange} >
                <option>Select City</option>
                {city.map((item, index)=>(
                  <option selected={item.name === formData.city} key={index} value={JSON.stringify(item)}>{item.name}</option>
                ))}
              </select>
              <img src={process.env.PUBLIC_URL + "/images/user/selectArrow.png"} alt="arrow" className="arrow-image" />
            </div>
            {errors.city && <div className="error">{errors.city}</div>}
          </div>
        </div>
        <div className="col-sm-12 col-xs-12 col-md-3 col-lg-3">
          <div className={`form_group ${errors.land_mark ? "VendorInputError" : ""}`}>
            <input type="text" onChange={handleChange} placeholder="Landmark" name="land_mark" id="name" oninput="allow_alphabets(this)" />
          </div>
          {errors.land_mark && (<div className="error">{errors.land_mark}</div>)}
        </div>
        <div className="col-sm-12 col-xs-12 col-md-3 col-lg-3">
          <div className={`form_group ${errors.postal_code ? "VendorInputError" : ""}`}>
            <input type="number" onChange={handleChange} placeholder="Postal Code" maxLength={6} name="postal_code" id="postal_code" onkeypress="return onlyNumberKey(event)" />
          </div>
          {errors.postal_code && (<div className="error">{errors.postal_code}</div>)}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className={`form_group ${ errors.address1 ? "VendorInputError" : ""}`} >
            <input type="text" onChange={handleChange} placeholder="Address Line 1 " name="address1" id="address1" oninput="allow_alphabets(this)" />
          </div>
          {errors.address1 && (<div className="error">{errors.address1}</div>)}
        </div>
        <div className="col">
          <div className={`form_group ${errors.address2 ? "VendorInputError" : ""}`} >
            <input type="text" onChange={handleChange} placeholder="Address Line 2" name="address2" id="address2" oninput="allow_alphabets(this)" />
          </div>
          {errors.address2 && (<div className="error">{errors.address2}</div>)}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <p className="fill_form mb-0">Select One Option</p>
        </div>
        <div className="d-flex user_selection">
          <div class="user_name" onClick={()=>{selectgender('Male')}}>
            <input type="radio" name="gender" value="Male"checked={formData.gender === 'Male'} />
            <label>Male</label>
          </div>
          <div className="user_name" onClick={()=>{selectgender('Female')}}>
            <input type="radio" name="gender" value="Female" checked={formData.gender === 'Female'} />
            <label>Female</label>
          </div>
          <div className="user_name" onClick={()=>{selectgender('Other')}}>
            <input type="radio" name="gender" value="Other" checked={formData.gender === 'Other'} />
            <label>Other</label>
          </div>
        </div>
        {errors.gender && <div className="error">{errors.gender}</div>}
      </div>
    </div>
        {/* end varification section */}
        {checkBlur && (
          <div
            class="button-container mt-4 mb-4"
            id="Edit_registration_btn"
            onClick={()=>{setBlur(false)}}
          >
            <button class="button button1" >
              Edit Details
            </button>
            <button class="button button2">Edit Details</button>
          </div>
        )}
        <div
          className="button-container mt-4 mb-4 mb_btn_view"
          style={{ marginRight: "0" }}
        >
          <button className="button button1"> Proceed To Next</button>
          <button className="button button2" onClick={handleSubmit}>
            {" "}
            Proceed To Next
          </button>
        </div>
      </div>
     

      <section className="mb-0">
    <div className="download_aap">
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                <img src={process.env.PUBLIC_URL + "./app_img.png"} alt="app"  className="app_img_contact " />
                 
                </div>
                <div className="col-md-6 pt-4">
                    <h1>Download the app</h1>
                    <p>Get the app. Get things done. Compare prices, read reviews and book top-rated services. All in one free app.</p>
                </div>
                <div className="col-md-3">
                   <ul>
                    <li> <img src={process.env.PUBLIC_URL + "./androied.png"} alt="app"  className="app_img" /> </li>
                    <li> <img src={process.env.PUBLIC_URL + "./ios.png"} alt="app"  className="app_img" /> </li>
                    
                 
                   </ul>
                </div>
            </div>
        </div>
    </div>
</section>
      <Footer />

      <Modal
          show={show}
          onHide={handleClose}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose}>
              <img
                src={process.env.PUBLIC_URL + "images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "images/user/X.png"}
                alt="playstore"
              />
            </div>
            <img
              src={process.env.PUBLIC_URL + "images/user/cong.gif"}
              alt="playstore"
              className="cong-done-json"
              style={{ width: "150px" }}
            />

            <div className="congrats-profile-created">
              <h6>
                Congratulations
                <br />
                <span> Profile successfully Done</span>
              </h6>
              <p>You are now ready for using the Doqwik app services.</p>
              <div class="button-container " id='registration-btn'>
                <button className="button button1">Proceed To Next</button>
                <button className="button button2">
                  <Link
                    to="/user/my_profile"
                    style={{ textDecoration: "none", color: "#ffffff" }}
                  >
                    {" "}
                    Proceed To Next{" "}
                  </Link>
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
    </>
  );
};

export default CreateProfile;
