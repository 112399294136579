import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
// import './dashboard.css';
import React, { useState, useEffect } from 'react';
import DashboardAsVendor from "../../components/dashboardAsVendor";
const Vendorassigntask = () =>{
    return (
        <>
         <Header />
   <div className="dashboard">
    <div className="container">
        <div className="row mx-0">
         <DashboardAsVendor/>
            <div className="col-md-9">
             <div className="vendors_right_form">
          
               <div className="row  mx-0">
                <div className="col-md-4">
                <p className="sub_heading">Order History</p>
                  <p className="main_heading">Your Order Records & Details.</p>
                </div>
                <div className="col-md-8">
                  <ul className="user_list">
                    <li>
                      <div className="latest_container">
                        <button className="button latest_btn ">Latest ( New ) </button>
                        <button className="button latest_buttn">Latest ( New ) </button>
                        </div>
                    </li>
                    <li>
                    <div className="assigned_container">
                        <button className="button Assigned_btn active_latest">Assigned</button>
                        <button className="button Assigned_button">Assigned</button>
                      </div>
                    </li>
                    <li>
                    <div className="complete_container">
                        <button className="button Completed">Completed </button>
                        <button className="button Completed_button">Completed</button>
                    </div>
                    </li>
                    <li>
                    <div className="reject_container">
                        <button className="button reject_btn">Reject</button>
                        <button className="button Reject_button">Reject</button>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>


               <div className="row  mx-0">
                <div className="col-md-6">
                  <div className="book_slot">
                  <div className="border_bottom row mx-0">
                    <div className="col-md-6 col-6">
                      <div className="date_booking">
                        <p>Booking Slot Date</p>
                        <h1>04 January 2022</h1>
                        </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="date_booking">
                            <p>Booking Slot Time</p>
                            <h1>04:00 PM</h1>
                          </div>
                        </div>
                      </div>
                      <div className="border_bottom row mx-0">
                        <div className="date_booking">
                       <p>Address</p>
                       <h1> Gwarinpa-900108, Abuja, Federal Capital Territory, Nigeria</h1>
                       </div>
                      </div>
                      <div className="border_bottom row mx-0">
                    <div className="col-md-12 col-12">
                    <div className="col-md-6 col-6 px-0">
                          <div className="status">
                            <button className="pre-paid">Prepaid Payment</button>
                          
                          </div>
                        </div>
                        </div>
                       
                      </div>
                      <div className="border_bottom row mx-0">
                      <div className="col-md-6 col-6 px-0">
                        <div className="assign-worker-container">
                          <button className="button assign-worker">Assign To Worker</button>
                          <button className="button assign-worker1">Assign To Worker</button>
                        </div>
                        </div>
                        <div className="col-md-6 col-6 px-0">
                        <div className="assign-viewer-container">
                          <button className="button assign-View-Detail"><Link to="/vendor/latestvendor"> View Detail </Link></button>
                          <button className="button assign-View-Detail1"><Link to="/vendor/latestvendor"> View Detail </Link></button>
                        </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="book_slot">
                  <div className="border_bottom row mx-0">
                    <div className="col-md-6 col-6">
                      <div className="date_booking">
                        <p>Booking Slot Date</p>
                        <h1>04 January 2022</h1>
                        </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="date_booking">
                          <p>Booking Slot Time</p>
                            <h1>04:00 PM</h1>
                          </div>
                        </div>
                      </div>
                      <div className="border_bottom row mx-0">
                        <div className="date_booking">
                       <p>Address</p>
                       <h1> Gwarinpa-900108, Abuja, Federal Capital Territory, Nigeria</h1>
                       </div>
                      </div>
                      <div className="border_bottom row mx-0">
                    <div className="col-md-6 col-6">
                      <div className="date_booking ">
                        <p className="postpayment">Payment</p>
                        <h2>#900 -/</h2>
                        </div>
                        </div>
                        <div className="col-md-6 col-6 px-0">
                          <div className="prepaid">
                            <button className="pre-paid">Prepaid Payment</button>
                          
                          </div>
                        </div>
                      </div>
                      <div className="border_bottom row mx-0">
                      <div className="col-md-6 col-6 px-0">
                        <div className="assign-worker-container">
                          <button className="button assign-worker">Assign To Worker</button>
                          <button className="button assign-worker1">Assign To Worker</button>
                        </div>
                        </div>
                        <div className="col-md-6 col-6 px-0">
                        <div className="assign-viewer-container">
                          <button className="button assign-View-Detail"><Link to="/vendor/latestvendor"> View Detail </Link></button>
                          <button className="button assign-View-Detail1"><Link to="/vendor/latestvendor"> View Detail </Link></button>
                        </div>
                        </div>
                      </div>
                  </div>
                </div>
               </div>

               <div className="row  mx-0">
                <div className="col-md-6">
                  <div className="book_slot">
                  <div className="border_bottom row mx-0">
                    <div className="col-md-6 col-6">
                      <div className="date_booking">
                        <p>Booking Slot Date</p>
                        <h1>04 January 2022</h1>
                        </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="date_booking">
                          <p>Booking Slot Time</p>
                            <h1>04:00 PM</h1>
                          </div>
                        </div>
                      </div>
                      <div className="border_bottom row mx-0">
                        <div className="date_booking">
                       <p>Address</p>
                       <h1> Gwarinpa-900108, Abuja, Federal Capital Territory, Nigeria</h1>
                       </div>
                      </div>
                      <div className="border_bottom row mx-0">
                    <div className="col-md-6 col-6">
                      <div className="date_booking ">
                        <p className="postpayment">Payment</p>
                        <h2>#900 -/</h2>
                        </div>
                        </div>
                        <div className="col-md-6 col-6 px-0">
                          <div className="prepaid">
                            <button className="pre-paid">Prepaid Payment</button>
                          
                          </div>
                        </div>
                      </div>
                      <div className="border_bottom row mx-0">
                      <div className="col-md-6 col-6 px-0">
                        <div className="assign-worker-container">
                          <button className="button assign-worker">Assign To Worker</button>
                          <button className="button assign-worker1">Assign To Worker</button>
                        </div>
                        </div>
                        <div className="col-md-6 col-6 px-0">
                        <div className="assign-viewer-container">
                          <button className="button assign-View-Detail"><Link to="/vendor/latestvendor"> View Detail </Link></button>
                          <button className="button assign-View-Detail1"><Link to="/vendor/latestvendor"> View Detail </Link></button>
                        </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="book_slot">
                  <div className="border_bottom row mx-0">
                    <div className="col-md-6 col-6">
                      <div className="date_booking">
                        <p>Booking Slot Date</p>
                        <h1>04 January 2022</h1>
                        </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="date_booking">
                          <p>Booking Slot Time</p>
                            <h1>04:00 PM</h1>
                          </div>
                        </div>
                      </div>
                      <div className="border_bottom row mx-0">
                        <div className="date_booking">
                       <p>Address</p>
                       <h1> Gwarinpa-900108, Abuja, Federal Capital Territory, Nigeria</h1>
                       </div>
                      </div>
                      <div className="border_bottom row mx-0">
                    <div className="col-md-6 col-6">
                      <div className="date_booking ">
                        <p className="postpayment">Payment</p>
                        <h2>#900 -/</h2>
                        </div>
                        </div>
                        <div className="col-md-6 col-6 px-0">
                          <div className="prepaid">
                            <button className="pre-paid">Prepaid Payment</button>
                          
                          </div>
                        </div>
                      </div>
                      <div className="border_bottom row mx-0">
                      <div className="col-md-6 col-6 px-0">
                        <div className="assign-worker-container">
                          <button className="button assign-worker">Assign To Worker</button>
                          <button className="button assign-worker1">Assign To Worker</button>
                        </div>
                        </div>
                        <div className="col-md-6 col-6 px-0">
                        <div className="assign-viewer-container">
                          <button className="button assign-View-Detail"><Link to="/vendor/latestvendor"> View Detail </Link></button>
                          <button className="button assign-View-Detail1"><Link to="/vendor/latestvendor"> View Detail </Link></button>
                        </div>
                        </div>
                      </div>
                  </div>
                </div>
               </div>
             </div>
            </div>
        </div>
    </div>
   </div>
    <Footer />
        </>
    );
};

export default Vendorassigntask;