import { useState, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import LeftnavbarIndividual from "../../components/leftnavbaraIndividual";
import { setSession, getSession} from "../../utils/helper";
import axios from 'axios';
import Modal from "react-bootstrap/Modal";
import { Link,useNavigate} from "react-router-dom";
import { apiBaseUrl } from "../../config/constant";
// Other imports...

const IndividualToday = () =>{
  const handleReloadClick = () => {
    window.location.reload();
  };
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);  
  const [error, setError] = useState('');
  const [processid,setId_process] = useState({
    jobId: "",
    rejectReason: "",
  });
  const [data, setData] = useState('');
  const [uploadpic, setuploadpic] = useState(false);
  const [locationName, setLocationName] = useState('');
  const [show, setShow] = useState(false);
  const [textareaValue, setTextareaValue] = useState(''); 
  const [reject,setreject] = useState(false);
  const [activeButton, setActiveButton] = useState('');
  const[success,setsuccess] = useState(false);
  const [paymentpopup,setpaymentpopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
const [selectedImage2, setSelectedImage2] = useState(null);
const [jodid,setjobid]= useState({
  jobId: "",
  code: "",
});

const handleOtpInputChange = (index, value) => {
  const newOtpDigits = [...otpDigits];
  newOtpDigits[index] = value;
  setOtpDigits(newOtpDigits);
};

const[Verifyotp,setverifyotp]=useState(false);
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };  
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    console.log("Selected Image 2:", file);
    setSelectedImage(file);
  };

  const handleImageChange2 = (event) => {
    const file = event.target.files[0];
    console.log("Selected Image:", file);
    setSelectedImage2(file);
  };
  const handleUpload = () =>{
    setverifyotp(true);
    setuploadpic(false);
  }
  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
const [reject_list,setreject_list] = useState('');
const Uploadingfunction = ()=>{
  setuploadpic(true);
  setShow(false);
}
const handleShow = async (jodid) => {

  const formData = new FormData();
  const enteredOtp = otpDigits.join("");
  formData.append('jobId', jodid);
  formData.append('code', enteredOtp);
  formData.append('first_pic', selectedImage);
  formData.append('second_pic', selectedImage2);

  try {
    let token = getSession("token");
    const response = await axios.post(
      apiBaseUrl+'order/job-start',
      formData,
      {
        headers: {
          'Authorization': token,
          "Content-Type": "multipart/form-data"
        }
      }
    );

    if (response.data && response.data.status && response.data.status === true) {
       window.location.reload();
      navigate('/individual/orderhistory');
      setData(response.data.response.result);
    } else {
      console.log(response.data);
      alert("Job verification code is wrong. Please try again.");
    }
  } catch (error) {
    console.error(error);
  }
};



const [payment_selection, setPaymentSelection] = useState({
  id:'',
  paymentStatus: '',
  amount:'',
  discount:'',
  paidAmount:'',
});  
  useEffect(() => {
    const fetchData = async () => {
      
      try {
        let token = getSession("token");
       
        const response = await axios.post(
          apiBaseUrl+'order/get-provider-job-list',
          {
            "order_search" : "Today"
          }, 
          {
            headers: {
              'Authorization': token,
              'Content-Type': 'application/json'
            }
          }
        );
    
       console.log();
//         const rejectJobs = response;

// console.log(rejectJobs)
       
setreject_list(response.data.response.result);
        
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, []);


  const reject_popup = (id) =>{
    setId_process(id);
    alert(id);
    setreject(true);
  
  
  } 
  
  const start_job = (id) =>{
    setjobid(id);
    alert(id);
    setShow(true);
    
  } 
  
    const reject_order = async (processid) =>{
    alert(processid);
    try {
      let token = getSession("token");
      
      const response = await axios.post(
        apiBaseUrl+'order/job-reject',
        {
          jobId:processid,
          rejectReason:textareaValue, 
        },
  
       
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.data && response.data.status && response.data.status === true) {
        window.location.reload();
        navigate('/individual/orderhistory');
        setData(response.data.response.result);
        console.log(response.data.response.result);
      } else {
        
        console.log(response.data);
        
        alert("Job verification code is wrong. Please try again.");
      }
      console.log(response.data.response.result);
    } catch (error) {
      console.error(error);
    } 
  }
  const handleClose = () =>{
    setpaymentpopup(false)
    setPaymentSelection(false);
    setsuccess(false);
 
  
  } 
  const finishjob_popup = (data = {}) =>{

    if(data && data.paymentStatus === 'Success'){
      //alert(JSON.stringify(data));
      setsuccess(true);
      setpaymentpopup(false);
    }else{
      setpaymentpopup(true);
      setsuccess(false);
    }
    setPaymentSelection({...payment_selection, 
      ['paymentStatus']:data.paymentStatus,
    ['amount']:data.amount,
    ...(data.discount? {['discount']:data.discount}:{['discount']:0}),
    ['id']:data._id,
    
   })
  }

  const Proceed_pay = async ()  =>{
 
    console.log(payment_selection);
    try {
      let token = getSession("token");
      
      const response = await axios.post(
        apiBaseUrl+'order/job-finish',
        {
          jobId:payment_selection.id,   
          updateIp:1,
        },
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log(response.data.status);
      {if(response.data.status){
        setsuccess(true);
        setpaymentpopup(false);
      }else{
        setsuccess(false);
        setpaymentpopup(true);
      }
    }
      console.log(response.data.response.result);
    } catch (error) {
      console.error(error);
    }
  }
  const viewdetails = (order) =>{
    console.log(order);
     setSelectedItem(order);
     navigate('/Individua/todaydetails', {state:order});
    }
    return(
        <>
        <Header />
   <div className="dashboard">
    <div className="container">
        <div className="row mx-0">
        <LeftnavbarIndividual></LeftnavbarIndividual>
            <div className="col-md-9">
             <div className="vendors_right_form todaylist">
          
               <div className="row  mx-0">
                <div className="col-md-4">
                <p className="sub_heading">Order History</p>
                  <p className="main_heading">Your Order Records & Details.</p>
                </div>
                <div className="col-md-8">
                <ul className="user_list">
      <li>
        <div className="latest_container">
          <button
            className={`button latest_btn ${activeButton === 'latest_btn' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('latest_btn')}
          >
            <Link to="/individual/orderhistory">Latest ( New )</Link>
          </button>
          <button
            className={`button latest_buttn ${activeButton === 'latest_buttn' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('latest_buttn')}
          >
            <Link to="/individual/orderhistory">Latest ( New )</Link>
          </button>
        </div>
      </li>
      <li>
        <div className="assigned_container">
          <button
            className={`button Assigned_btn ${activeButton === 'Assigned_btn' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('Assigned_btn')}
          >
            <Link to="/individual/IndividualToday">Today</Link>
          </button>
          <button
            className={`button Assigned_button ${activeButton === 'Assigned_button' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('Assigned_button')}
          >
            <Link to="/individual/IndividualToday">Today</Link>
          </button>
        </div>
      </li>
      <li>
        <div className="complete_container">
          <button
            className={`button Completed ${activeButton === 'Completed' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('Completed')}
          >
            <Link to="/individual/Individualcompeleorder">Completed</Link>
          </button>
          <button
            className={`button Completed_button ${activeButton === 'Completed_button' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('Completed_button')}
          >
            <Link to="/individual/Individualcompeleorder">Completed</Link>
          </button>
        </div>
      </li>
      <li>
        <div className="reject_container">
          <button
            className={`button reject_btn ${activeButton === 'reject_btn' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('reject_btn')}
          >
            <Link to="/individual/Individualreject">Rejected</Link>
          </button>
          <button
            className={`button Reject_button ${activeButton === 'Reject_button' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('Reject_button')}
          >
            <Link to="/individual/Individualreject">Rejected</Link>
          </button>
        </div>
      </li>
    </ul>
              
                </div>
               </div>


               <div className="row  mx-0">
               {reject_list && reject_list.map((order,index) => (
                <div className="col-md-6" key={index}>
              
                  <div className="book_slot">
                  <div className="border_bottom row mx-0">
                    <div className="col-md-6 col-6">
                      <div className="date_booking">
                        <p>Booking Date</p>
                        <h1>{order.service_date}</h1>
                        </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="date_booking">
                          <p>Booking Time</p>
                            <h1>{order.service_time}</h1>
                          </div>
                        </div>
                      </div>
                      <div className="border_bottom row mx-0">
                        <div className="date_booking">
                       <p>Address</p>
                       <h1>
                      {order.service_location.address1}, {order.service_location.address2},
                      {order.service_location.land_mark}, {order.service_location.city},
                      {order.service_location.state}, {order.service_location.country}
                    </h1>
                       </div>
                      </div>
                      <div className="border_bottom row mx-0">
                    <div className="col-md-6 col-6">
                      <div className="date_booking ">
                        <p className="postpayment">Payment</p>
                        <h2>{order.amount}-/</h2>
                        </div>
                        </div>
                        <div className="col-md-6 col-6 px-0">
                          <div className="prepaid">
                          {order.paymentStatus === "success" ? (
    <button className="pre-paid">Prepaid Payment</button>
  ) : (
    <button className="pre-paid">Postpaid Payment</button>
  )}
                          </div>
                        </div>
                      </div>
                      <div className="border_bottom row mx-0">

  <>
    <div className="col-md-12 col-12 px-0 complete_details">
    <div className="border_bottom row mx-0">
                    

{order.job_status === "Accept" && (
  <>
    <div className="col-md-4 col-4 px-0">
    <div className="assign-worker-container" id="indivdualthrbtn"  onClick={() => start_job(order._id)}>
      <button className="button assign-worker">Start Job</button>
      <button className="button assign-worker1">Start Job</button>
    </div>
  </div>
  <div className="col-md-4 col-4 px-0">
      <div className="assign-viewer-container" id="containnerViewWidth" onClick={() => reject_popup(order._id)}>
        <button className="button assign-View-Detail">Reject</button>
        <button className="button assign-View-Detail1">Reject</button>
      </div>
    </div>
    <div className="col-md-4 col-4 px-0">
      <div className="assign-viewer-container" id="containnerViewWidth"  onClick={()=> viewdetails(order)}>
      <button className="button assign-View-Detail"><Link to="/Individua/todaydetails"> View Detail </Link></button>
        <button className="button assign-View-Detail1"><Link to="/Individua/todaydetails"> View Detail </Link></button>
      </div>
      </div>
  </>
)}

{order.job_status === "Start" && (
  <>
    <div className="col-md-6 col-6 px-0">
    <div className="assign-worker-container" onClick={() => finishjob_popup(order)}>
      <button className="button assign-worker">Job Finish</button>
      <button className="button assign-worker1">Job Finish</button>
    </div>
  </div>
  {/* <div className="col-md-4 col-4 px-0">
      <div className="assign-viewer-container" >
        <button className="button assign-View-Detail" disabled>Reject</button>
        <button className="button assign-View-Detail1" disabled>Reject</button>
      </div>
    </div> */}
    <div className="col-md-6 col-6 px-0">
      <div className="assign-viewer-container" onClick={()=> viewdetails(order)}>
        <button className="button assign-View-Detail"><Link to="/Individua/todaydetails"> View Detail </Link></button>
        <button className="button assign-View-Detail1"><Link to="/Individua/todaydetails"> View Detail </Link></button>
      </div>
      </div>
  </>
)}



                      </div>
      </div>
  </>

                      </div>
                  </div>
                    
                </div>
                ))}
               </div>

           
             </div>
            </div>
        </div>
    </div>
   </div>
   <Modal
          show={show}
          onHide={handleClose}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose}>
              <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
          
            <div id="lottie-animation"></div>
            <div className="congrats-profile-created" style={{marginTop:"0px"}}>
            
      {error ? (
        <p>{error}</p>
      ) : locationName ? (
        <h6>{locationName}</h6>
      ) : (
        <p>
          Geolocation is disabled. Please enable geolocation in your browser settings.
        </p>
      )}
   
           
              <p>Use your device location to set your current location. </p>
              <div class="button-container" style={{ width: "200px" }} onClick={Uploadingfunction}>
                <button className="button button1">Use Current Location</button>
                <button className="button button2">
                  <Link style={{ textDecoration: "none", color: "#ffffff" }}>Use Current Location
                  </Link>
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>


        <Modal
         show={uploadpic}
         onHide={handleClose}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose}>
              <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
          

            <div className="congrats-profile-created" style={{marginTop:"0px"}}> 
              <h2>Upload Photos</h2>
              <p>Upload 2 selfie images with your service location.</p>
              <div>
                <ul className="images_uploads">
                  <li>
                  <div>
      {selectedImage ? (
        <img src={URL.createObjectURL(selectedImage)} alt="Selected" />
      ) : (
        <div>
          <label htmlFor="file-input" style={{ display: 'block', marginBottom: '10px' }}>
          <div className="uploading_img">
            <img
                src={process.env.PUBLIC_URL + "../images/add.png"}
                alt="playstore"  className="addimgicon"
              />
            </div>
          </label>
          <input
            type="file"
            id="file-input"
            onChange={handleImageChange}
            style={{ display: 'none' }}
          />
        </div>
      )}
    </div>
              </li>
                  <li>
                  <div>
      {selectedImage2 ? (
        <img src={URL.createObjectURL(selectedImage2)} alt="Selected" />
      ) : (
        <div>
          <label htmlFor="file-input2" style={{ display: 'block', marginBottom: '10px' }}>
            <div className="uploading_img">
            <img
                src={process.env.PUBLIC_URL + "../images/add.png"}
                alt="playstore"  className="addimgicon"
              />
            </div>
         
          </label>
          <input
            type="file"
            id="file-input2"
            onChange={handleImageChange2}
            style={{ display: 'none' }}
          />
        </div>
      )}
    </div>
              </li>
                </ul>
              </div>
              <div class="button-container" style={{ width: "200px" }}  onClick={handleUpload}>
                <button className="button button1">Submit Images</button>
                <button className="button button2">
                  <Link style={{ textDecoration: "none", color: "#ffffff" }}>Submit Images
                  </Link>
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>   


        <Modal

show={Verifyotp}
         onHide={handleClose}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose}>
              <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
          

            <div className="congrats-profile-created" style={{marginTop:"0px"}}> 
              <h2>Verify Yourself</h2>
              <p>Enter the 6-digit code that we sent to customer after order confirmation.</p>
              <div>
              <ul className="verify_otp">
    {otpDigits.map((digit, index) => (
      <li key={index}>
        <input
          type="text"
          className="input_verify"
          maxLength="1"
          value={digit}
          onChange={(e) => handleOtpInputChange(index, e.target.value)}
        />
      </li>
    ))}
  </ul>
              </div>
              
              <div class="button-container" style={{ width: "150px" }}   onClick={() => handleShow(jodid)}>
                <button className="button button1" >Submit</button>
                <button className="button button2">
                  <Link style={{ textDecoration: "none", color: "#ffffff" }}>Submit 
                  </Link>
                </button>
              </div>
              <p>Resend Code</p>
            </div>
          </Modal.Body>
        </Modal> 

        <Modal

show={paymentpopup}
         onHide={handleClose}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose}>
            <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
          

            <div className="congrats-profile-created" style={{marginTop:"0px"}}> 
            <div className="payment_details">
                     <h1>Payment Detail</h1>
                     
                     <div className="vendor_payment_status">
                   
                     <input placeholder="Payment Status"  className="payment_status" value={payment_selection.paymentStatus} />

                     {
  payment_selection.paymentStatus === "Success" ? (
    <button className="prepaid_post">Postpaid Payment</button>
  ) : (
    <button className="prepaid_post">Prepaid Payment</button>
  )
}
                    
                     </div>
                     { 
                          <ul className="mt-2"> 
                          <li className="pt-0"><p>Total Amount</p> <span>₦ {payment_selection.amount}</span></li>
                          <li><p>Discount</p> <span className="text_change_color">₦ {payment_selection.discount}</span></li>
                          <li><p>Other Charges</p>         <span> <p>Free</p> </span></li>
                          <li className="pb-0 bottom_none"><p className="text_change_color">Amount Paid</p> <span className="text_change_color">₦{payment_selection.amount}</span></li>
                       </ul>
                     }
                   
                    </div>
                    <div className="col-md-12">
                <select
                  name="city"
                  className="select_options"
                  // value={formData2.city}
                  // onChange={}
                >
                  <option>Select Payment Mode</option>
                  <option>Cash</option>
                  <option>Online</option>
         
                </select>
          
              </div>
              
              
              <div class="button-container" style={{ width: "150px" }}     onClick={() => Proceed_pay()} >
                <button className="button button1" >Proceed To Pay</button>
                <button className="button button2">
                  <Link style={{ textDecoration: "none", color: "#ffffff" }}>Proceed To Pay
                  </Link>
                </button>
              </div>
              <p>Resend Code</p>
            </div>
          </Modal.Body>
        </Modal> 

        <Modal 


show={success}
         onHide={handleClose}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose}>
            <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
          

            <div className="congrats-profile-created  well_done" style={{marginTop:"0px"}}>
            <img
                src={process.env.PUBLIC_URL + "../images/greeting.gif"}
                alt="playstore" className="gif"
              />
              <h2>Well Done!</h2> 
         <p>You was complete your
Job Successfully</p>
{/* <div class="button-container" style={{ width: "150px" }} onClick={handleReloadClick}> */}
              <div class="button-container" style={{ width: "150px" }}  onClick={() => Proceed_pay()}>
                <button className="button button1" > <Link style={{ textDecoration: "none"}} to='/individual/orderhistory'> Back to Home
                  </Link></button>
                <button className="button button2">
                  <Link style={{ textDecoration: "none", color: "#ffffff" }} to='/individual/orderhistory'> Back to Home
                  </Link>
                </button>
              </div>
          
            </div>
          </Modal.Body>
        </Modal> 


        <Modal 


show={reject}
         onHide={handleClose}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose}>
            <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
          

            <div className="congrats-profile-created  well_done" style={{marginTop:"0px"}}>
           
         <p>Reason For Reject</p>
              
              <textarea    value={textareaValue} onChange={(e) => setTextareaValue(e.target.value)} className="add_reasons"></textarea>
              <div class="button-container mt-3" style={{ width: "150px" }}   onClick={() => reject_order(processid)}>
                <button className="button button1 s_btn" > <Link style={{ textDecoration: "none",color: "#fff !important"}} to='/individual/orderhistory'> Submit
                  </Link></button>
                <button className="button button2">
                  <Link style={{ textDecoration: "none", color: "#ffffff" }} to='/individual/orderhistory'>Submit
                  </Link>
                </button>
              </div>
          
            </div>
          </Modal.Body>
        </Modal> 
    <Footer />
        </>
    );
};

export default IndividualToday;
