module.exports = {
 

  // apiBaseUrl: "http://localhost:3300/front/v1/",          //Api base user  -- local
  // apiBaseUrl: "https://69.49.228.170:3300/front/v1/", //Api base user  -- server
  // apiBaseUrl: "https://moneysaverz.com:3300/front/v1/", //Api base user  -- server
  // BaseUrl:"https://moneysaverz.com:3300/",
  // apiBaseUrl:"https://16.171.90.145/front/v1/" , // for hosting
  apiBaseUrl:"https://doqwik.ng/front/v1/",
  BaseUrl: "",
  //Common API
  homePageApiUrl: "common/web-get-homepage-data",
  sevicePageData: "common/web-get-service-page-data",
  seviceetailsPageData: "common/web-get-servicedetails-page-data",
  contactPageData: "common/web-get-contsctus-page-data",
  PAYSTACK_PK_KEY : "pk_test_bf755297dbb8d47a792a4eb9e153eab8fe4a1613",
  PAYSTACK_SK_KEY : "sk_test_1195b5fd94876777dbc45d37260b75e788bd4d62",

  
//admin API
ADMIN_URL_HOME: 'https://moneysaverz.com/doqwik_admin/admin/assets/front/img/banner/',

// vendor/provider login API 
vendorLoginApiUrl:"provider/login",
vendorOtpUrl :"provider/verify-login-otp",
vendorRegistraionAsComapnyUrl:"provider/provider-register",

// user login Api
key:"d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
userLogin :"front/v1/users/login",
API_KEY:`AIzaSyDgD56S6ZLFVJgzD8GPnMDPnV6vrhXGroY` ,

  //User API
  userLoginApiUrl: "users/user-login",
  userCheckEmailApiUrl: "users/user-check-email",
  userCheckMobileApiUrl: "users/user-check-mobile",
  userRegisterApiUrl: "users/user-register",
  userForgotPasswordApiUrl: "users/user-forgot-password",
  userResetPasswordApiUrl: "users/user-reset-password",

  userAccountApiUrl: "users/get-my-account",

  userEventsApiUrl: "users/get-my-events",
  userEventDetailsApiUrl: "users/get-my-event-details",

  userCreateEventPageApiUrl: "users/get-create-event-page-data",
  userCreateEventApiUrl: "users/put-create-event",
  userSendEventCanelRequestApiUrl: "users/send-my-event-cancel-request",
  userEditEventApiUrl: "users/put-update-event",

  userCreatePrivateEventPageApiUrl: "users/get-create-private-event-page-data",
  userCreatePrivateEventApiUrl: "users/put-create-private-event",

  userSendVerificationOTPApiUrl: "users/send-otp-for-verification",
  userCheckVerificationOTPApiUrl: "users/check-otp-for-verification",
  sendMyEventCancelRequestByOrganiser: "users/sendMyEventCancelRequestByOrganiser",
  userAttendingApiUrl: "users/get-my-attending",
  sendContactMailToOrganiser: "users/sendContactMailToOrganiser",

  fBDImageBaseUrl: "https://doolally.in/uploads/beverage/",
  eventImageBaseUrl: "https://doolally.in/uploads/events/",
  eventCategoryImageBaseUrl: process.env.PUBLIC_URL + "/events/",
  eventShareBaseUrl: "http://localhost:3000/event-details/",

  communityManagerNumber: "9930415379",

  createEventDoolallyFee: 300,
  userFrom: "Web",

  getRegList: "users/get-reg-list",

  razorpayKey: "rzp_test_f2FovlSQrHRgeN",

  //F&B section start 1/10/23

  fnbMenuData: "get-restaurant-menus",

  EVENT: {
    ACTIVE: 1,
    NOT_ACTIVE: 0,
    WAITING: 0,
    APPROVED: 1,
    DECLINED: 2,
    CANCEL_REVIEW: 1,
    CANCEL_FINAL: 2,

    FREE: 1,
    PAID: 2,
    PAID_NO_PINT: 3,
    DOOLALLY_FEE: 4,
    PRIVATE: 5,
    STATUS_YES: 1,
    STATUS_NO: 2,
  },
  EH_GATEWAY_CHARGE: 5.26,
  DOOLALLY_GATEWAY_CHARGE: 2.29,
  INSTAMOJO_GATEWAY_CHARGE: 3,
  PAYTM_GATEWAY_CHARGE: 5,
  OLD_DOOLALLY_FEE: 250,
  NEW_DOOLALLY_FEE: 300,

  perPageShowData: 6,
  
  EVENT_START_HR : 10,
  EVENT_END_HR : 20,
};

