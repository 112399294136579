import { useState, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import LeftnavbarIndividual from "../../components/leftnavbaraIndividual";
import { setSession, getSession} from "../../utils/helper";
import axios from 'axios';
import { Link,useNavigate} from "react-router-dom";
import { apiBaseUrl } from "../../config/constant";
// Other imports...

const Individualreject_order = () =>{
  const [selectedItem, setSelectedItem] = useState(null);  
const navigate = useNavigate();
const [reject_list,setreject_list] = useState('');
  
  useEffect(() => {
    const fetchData = async () => {
      
      try {
        let token = getSession("token");
       
        const response = await axios.post(
          apiBaseUrl+'order/get-provider-job-list',
          {
          // id: id
          }, 
          {
            headers: {
              'Authorization': token,
              'Content-Type': 'application/json'
            }
          }
        );
        const rejectJobs = response.data.response.result.filter(job => job.job_status === 'Reject');

console.log(rejectJobs)
       
       setreject_list(rejectJobs);
        
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, []);

  const viewdetails = (order) =>{
    console.log(order);
     setSelectedItem(order);
     navigate('/Individua/Individualreject_orderdetail', {state:order});
    }
    return(
        <>
        <Header />
   <div className="dashboard">
    <div className="container">
        <div className="row mx-0">
        <LeftnavbarIndividual></LeftnavbarIndividual>
            <div className="col-md-9">
             <div className="vendors_right_form">
          
               <div className="row  mx-0">
                <div className="col-md-4">
                <p className="sub_heading">Order History</p>
                  <p className="main_heading">Your Order Records & Details.</p>
                </div>
                <div className="col-md-8">
                <ul className="user_list">
                    <li>
                      <div className="latest_container">
                        <button className="button latest_btn "> <Link to="/individual/orderhistory">Latest ( New )  </Link></button>
                        <button className="button latest_buttn"><Link to="/individual/orderhistory">Latest ( New )  </Link> </button>
                        </div>
                    </li>
                    <li>
                    <div className="assigned_container">
                        <button className="button Assigned_btn "><Link to="/individual/IndividualToday">Today </Link> </button>
                        <button className="button Assigned_button"><Link to="/individual/IndividualToday">Today</Link></button>
                      </div>
                    </li>
                    <li>
                    <div className="complete_container"  >
                        <button className="button Completed "> <Link to="/individual/Individualcompeleorder">
        Completed
          </Link> </button>
                        <button className="button Completed_button"> <Link to="/individual/Individualcompeleorder">
                        Completed
          </Link></button>
                    </div>
                    </li>
                    <li>
      <div className="reject_container">
        <button className="button reject_btn active_latest">
          <Link to="/individual/Individualreject">
            Rejected
          </Link>
        </button>
        <button className="button Reject_button">
          <Link to="/individual/Individualreject">
            Rejected
          </Link>
        </button>
      </div>
    </li>
                 
                
                  </ul>
                </div>
               </div>


               <div className="row  mx-0">
               {reject_list && reject_list.map((order,index) => (
                <div className="col-md-6" key={index}>
              
                  <div className="book_slot">
                  <div className="border_bottom row mx-0">
                    <div className="col-md-6 col-6">
                      <div className="date_booking">
                        <p>Booking Slot Date</p>
                        <h1>{order.service_date}</h1>
                        </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="date_booking">
                          <p>Booking Slot Time</p>
                            <h1>{order.service_time}</h1>
                          </div>
                        </div>
                      </div>
                      <div className="border_bottom row mx-0">
                        <div className="date_booking">
                       <p>Address</p>
                       <h1>
      {order.service_location?.address1}, {order.service_location?.address2},
      {order.service_location?.land_mark}, {order.service_location?.city},
      {order.service_location?.state}, {order.service_location?.country}
    </h1>
                       </div>
                      </div>
                      <div className="border_bottom row mx-0">
                    <div className="col-md-6 col-6">
                      <div className="date_booking ">
                        <p className="postpayment">Payment</p>
                        <h2>{order.amount}-/</h2>
                        </div>
                        </div>
                        <div className="col-md-6 col-6 px-0">
                          <div className="prepaid">
                          {order.paymentStatus === "success" ? (
    <button className="pre-paid">Prepaid Payment</button>
  ) : (
    <button className="pre-paid">Postpaid Payment</button>
  )}
                          </div>
                        </div>
                      </div>
                      <div className="border_bottom row mx-0">
{order.job_status === "Reject" && (
  <>
    <div className="col-md-12 col-12 px-0 complete_details">
      <div className="assign-viewer-container" onClick={()=> viewdetails(order)}>
        <button className="button assign-View-Detail"><Link to="/Individua/Individualreject_orderdetail">View Details </Link></button>
        <button className="button assign-View-Detail1"><Link to="/Individua/Individualreject_orderdetail">View Details </Link></button>
      </div>
      </div>
  </>
)}

                      </div>
                  </div>
                    
                </div>
                ))}
               </div>

           
             </div>
            </div>
        </div>
    </div>
   </div>
    <Footer />
        </>
    );
};

export default Individualreject_order;
