import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/header';
import Footer from '../../components/footer';
import LeftnavbarIndividual from '../../components/leftnavbaraIndividual';
import axios from 'axios';
import { setSession, getSession} from "../../utils/helper";
import { apiBaseUrl } from '../../config/constant';
const Individual_Service = () => {
  const [data_Id, setdata_Id] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = getSession('token');
        const response = await axios.get(
          apiBaseUrl+'provider/get-individual-services-list',
          {
            headers: {
              Authorization: token,
              'Content-Type': 'application/json',
            },
          }
        );
        setdata_Id(response?.data?.response?.result);
        console.log()
        sessionStorage.setItem('individual_service',response?.data?.response?.result[0]?.serviceData._id);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const viewDetails = (item) => {
    navigate('/individual/service_details', { state: item });
  };

  const editView = (item) => {
    navigate('/Individual/service_edit', { state: item });
  };

  return (
    <>
      <Header />
      <div className="dashboard">
        <div className="container">
          <div className="row mx-0 worklist_dashboard">
            <LeftnavbarIndividual />
            <div className="col-md-9">
              <div className="vendors_right_formses">
                <div className="row mx-0">
                  <div className="col-md-8">
                    <h1 className="my_order mt-2">Your Company Service Records</h1>
                  </div>
                  <div className="col-md-4">
                  <Link to="/individual/service_Selections">
                    <div className="assign-worker-container" id="services">
                      <button className="button assign-worker">
                        Add New Service
                      </button>
                      <button className="button assign-worker1">Add New Service</button>
                    </div>
                  </Link>
                  </div>
                </div>
                <div className="row service_outer_box mt-2 mx-0">
                  {data_Id.map((item) => (
                    <div className="col-md-4" key={item?._id}>
                      <div className="row mx-0 chat_box">
                        <div className="col-md-4 px-0">
                          <div className="services_background">
                            <img src={item?.serviceData?.icon} alt="app" className="service_icon" />
                            <p>{item?.serviceData?.service_name}</p>
                          </div>
                        </div>
                        <div className="col-md-8 px-0">
                          
                          <span>
                            Sub-Service:
                            <p> {item.subServices.sub_service_name}</p>
                          </span>
                          <span>
                            Price: <p> ₦ {item.price} /- </p>
                          </span>
                        </div>
                        <div className="col-md-6 px-0">
                          <div className="edit_Service" onClick={() => editView(item)}>
                            <button className="button edit_Service_first">Edit Service</button>
                            <button className="button edit_Service_second">Edit Service</button>
                          </div>
                        </div>
                        <div className="col-md-6 px-0">
                          <div className="View_Detail" onClick={() => viewDetails(item)}>
                            <button className="button view_details_first"> View Detail</button>
                            <button className="button view_details_second"> View Detail</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Individual_Service;
