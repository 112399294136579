import React from "react";
import Slider from "react-slick";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
const ServiceImage = (props) => {

  const{popularservice} = props;

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    dotsClass: 'slick-dots  customDotsClass',
    slidesToShow: 4.94 ,
    autoplay:true,
    // slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 3.76 ,
          // slidesToScroll: 2,
          // initialSlide: 0,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          // slidesToScroll: 2,
          // initialSlide: 2
          dots: false,
          arrows:false,
          marging:20,
        }
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 2.7,
          // slidesToScroll: 2,
          // initialSlide: 2
          dots: true,
          arrows:false,
          marging:20,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  };

  return (
    <div>
      <Slider {...settings}>
      { popularservice && popularservice.map((item, index)=>{
          return (
            <Link to={`/user_service_list/${item._id}`}>
          <div className="most-popular-service-card" key={index}>
          <LazyLoadImage src={item.service_image} alt="most-popular service card" />
          <div className="d-flex">
            <div className="card-mps">
            <LazyLoadImage src={item.icon} alt="most-popular service card" />
            </div>
            <h3>{item.service_name}</h3>
          </div>
        </div>
        </Link>
        )
        })
     
       }
      

  
      </Slider>
    </div>
  );
}

export default ServiceImage;
