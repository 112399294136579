import Header from "../../components/header";
import Footer from "../../components/footer";
import React, { useState,useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { getSession } from "../../utils/helper";
import { BaseUrl } from "../../config/constant";
import axios from "axios";
import DashboardAsWorker from "../../components/DashboardAsWorker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar} from '@fortawesome/fontawesome-free-solid';
import { timeDifference } from "../../components/common";
import { Link , useNavigate} from "react-router-dom";

const WorkerFeedback = () =>{
    const token = getSession('token');
    const navigate = useNavigate();
    const [ratingStar, setRatingStar] = useState(0);
    const maxRating = 5;
    const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(BaseUrl+'front/v1/order/get-feedback', {
          headers: {
            'Authorization': token,
             'Content-Type': 'application/json',
          },
        });

        console.log(response)
        setData(response.data.response.result);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    

    fetchData();
  }, []);

  const handleViewDetails=(details)=>{
    navigate('/worker/latest_details', { state: details });
  }

    return(
        <>
        
         <Header></Header>
         <div className="dashboard">
    <div className="container">
        <div className="row mb_view_center">
<DashboardAsWorker/>
            <div className="col-md-9">
          
             <div className="right_side">
             <div className="row">
                    <div className="col-md-12">

                        <h1 className="my_order">Review</h1>

                        <h2 className="order_details">Feedbacks to providers</h2>
                    </div>
                </div>
             
                <div className="row mx-0 mt-3 mb-2">
                   
                {data?.map((item,index)=>(
                        <div className="col-md-6">
                    <div className="feedback_reviwes">
                        <div className="review_header">
                            <div className="rating">
                                <div className="rating_left_side">
                                                      {Array.from({ length: maxRating }).map((_, index) => (
        <FontAwesomeIcon
          key={index}
          icon={faStar}
          style={{ color: index < item?.userFeedback?.star ? "#FFCC00" : "grey" }}
        />
      ))}
              
                                </div>
                                <div className="rating_right_side">
                                <p>{timeDifference(item.userFeedback.feedbackAt)}</p>
                            </div>
                            </div>
                          
                        </div>
                        <div className="review_content">
                        <p>{item.userFeedback.message}</p>
                        </div>
                        <div className="reviw_inner_content" onClick={handleShow}>
                        <div><img src={BaseUrl+item.providerData.profile_pic} alt="app"/> </div>
                            <div className="reviwer_details" >
                                <h1>{item.providerData.fullName}</h1>  
                                <p> {item.providerData.address1}</p>
                               
                                </div>
                          
                          <div>
                            <span className="reviews_star"><img src={process.env.PUBLIC_URL + "../images/Star.png"} className="star_img"/><p>{item.providerData.averageRating? item.providerData.averageRating:"0.0"}</p></span>
                        </div>
                     </div>
                     <div className="review_footer">
                     <div class="reviewer_btn mt-3 mb-2" onClick={()=>{handleViewDetails(item)}}>
                    <button class="button button1">View Detail</button>
                    <button class="button button2">View Detail</button>
                    </div>
                     </div>
                    </div>
                    </div>

                    ))}
                   
                   
                

                    {/* <div className="col-md-6">
                    <div className="feedback_reviwes  mb-0">
                        <div className="review_header">
                            <div className="rating">
                                <div className="rating_left_side">
                                <img src={process.env.PUBLIC_URL + "../images/rating.png"} alt="app"  className="app_img" /><p>4.0</p>
                                  
                                </div>
                                <div className="rating_right_side">
                                <p>3 days ago</p>
                            </div>
                            </div>
                          
                        </div>
                        <div className="review_content">
                        <p>It is a long established fact that reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem  is that it has a more-or-less normal distribution of letters, as opposed.</p>
                        </div>
                        <div className="reviw_inner_content" onClick={handleShow}>
                        <div><img src={process.env.PUBLIC_URL + "../images/worker.png"} alt="app"/> </div>
                            <div className="reviwer_details">
                                <h1>Benjamin</h1>  
                                <p>Gwarinpa-900108, Nigeria</p>
                               
                                </div>
                          
                          <div>
                            <span className="reviews_star"><img src={process.env.PUBLIC_URL + "../images/Star.png"} className="star_img"/><p>4.6</p></span>
                        </div>
                     </div>
                     <div className="review_footer">
                     <div class="reviewer_btn mt-3 mb-2">
                    <button class="button button1">View Detail</button>
                    <button class="button button2">View Detail</button>
                    </div>
                     </div>
                    </div>
                    </div> */}
                   
                 
                </div>
              
                
             </div>
            </div>
        </div>
    </div>
   </div>

   
   <section className="mb-0">
    <div className="download_aap">
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                <img src={process.env.PUBLIC_URL + "../app_img.png"} alt="app"  className="app_img" />
                 
                </div>
                <div className="col-md-6 pt-4">
                    <h1>Download the app</h1>
                    <p>Get the app. Get things done. Compare prices, read reviews and book top-rated services. All in one free app.</p>
                </div>
                <div className="col-md-3">
                   <ul>
                    <li> <img src={process.env.PUBLIC_URL + "../androied.png"} alt="app"  className="app_img" /> </li>
                    <li> <img src={process.env.PUBLIC_URL + "../ios.png"} alt="app"  className="app_img" /> </li>
                    
                 
                   </ul>
                </div>
            </div>
        </div>
    </div>
</section>
         <Footer></Footer>
         <Modal show={show} onHide={handleClose}  className="chat_user_boxses slide-up" id="chatbox">
    <div className="model_header">
        <div className="header_box">
        <img src={process.env.PUBLIC_URL + "/images/user_chat.png"} alt="app"  className="app_img" />
           <p>Benjamin</p>
        </div>
         <div className="">
         <img src={process.env.PUBLIC_URL + "/images/model_cross.png"} alt="app" className="cross_icon" onClick={handleClose} />
         </div>
        </div>
        
        <Modal.Body className="py-0" id="#style-4">
          <Form>
            <div className="row mx-0">
               
                <div className="col-md-12 px-0">
                    <div className="client_box">
                        <p>It is a long established fact that a read will be distracted by the read able of a page when looking at its layout.</p>
                    </div>
                    <img src={process.env.PUBLIC_URL + "/images/chat_box.png"}  className="chat_box_img"/>
                    <div className="chat_boxex">
                       
                    <div className="user_box">
                        <p>It is a long established fact that a read will be distracted by the read able of a page when looking at its layout.</p>
                    </div>
                    </div>
                </div>
                
            </div>
           
          </Form>
        </Modal.Body>
        <Modal.Footer >
            <div className="chat_model_footer">
            <button>+</button>
            <input type="text" className="user_enter_name" placeholder="Write Your Message here..."/>
            </div>
       
        </Modal.Footer>
      </Modal>
        </>
    )
};
export default WorkerFeedback;