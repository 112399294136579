import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./common.css";
import { Navbar, Nav, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, NavLink, useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  vendorLoginApiUrl,
  apiBaseUrl,
  vendorOtpUrl,
  userLogin,
  key,
  BaseUrl,
} from "../config/constant";
import axios from "axios";
import "./validators/formValidator.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  validationSchema,
  otpSchema,
  validationSchemaForInvidual,
  validationSchemaForUser,
  otpSchemaUser,
} from "./validators/vendorFormValidator";

import {
  validationSchemaForWorker,
  otpSchemaWorker,
} from "./validators/workerValidator";
import {
  setSession,
  getSession,
  getSessionForLoginUser,
  setSessionForLoginUser,
  setSessionForLoginCompany,
  getSessionForLoginCompany,
  getSessionForLoginWorker,
  setSessionForLoginWorker,
  setSessionForIndividual,
  getSessionForIndividual,
} from "../utils/helper";
import { useLocation } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  getCountries,
} from "react-phone-number-input";
import countryData from "country-data";
import { GoogleLogin } from "react-google-login";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signInWithRedirect,
} from "firebase/auth";
import {
  capitalizeEachWord,
  handleGoogleLoginByUser,
  handleUserLoginWithGoogle,
  isValidEmail,
} from "./common";
import { OAuthProvider } from "firebase/auth";
import { jwtDecode } from "jwt-decode";

const Header = forwardRef((props, ref) => {
  const workerData = getSessionForLoginWorker("worker");
  const [flagGoogleAppleLogin, setFlagGoogleAppleLogin] = useState(false);
  const [emailData, setEmailData] = useState();
  const currentAddress = props.currentAddress;
  const firebaseConfig = {
    apiKey: "AIzaSyDgOrOWNfPAYcdnNxY8ayUnpBbiKeIPpb8",
    authDomain: "doqwik-5cfd7.firebaseapp.com",
    databaseURL: "https://doqwik-5cfd7-default-rtdb.firebaseio.com",
    projectId: "doqwik-5cfd7",
    storageBucket: "doqwik-5cfd7.appspot.com",
    messagingSenderId: "653049587149",
    appId: "1:653049587149:web:388edd9192e246d6035084",
    measurementId: "G-D3JKDKSBP6",
  };
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();
  const location = useLocation();
  const navigate = useNavigate();
  const [isopen, setopen] = useState(false);
  const userData = getSessionForLoginUser("user");
  const VendorData = getSessionForLoginCompany("vendor");
  const individualData = getSessionForIndividual("individual");
  const [value, setValue] = useState();
  useImperativeHandle(ref, () => ({
    openModal: handleShowUserLogin, // Corrected the method name here
    openCompanyModel: handleShowVendorLogin,
    openIndividualModel: handleShowIndividualLogin,
  }));
  const inputRefs = useRef([]);
  const toggleMenu = () => {
    setopen(!isopen);
  };

  const token = getSession("token");
  // console.log(token)
  const { buttonLabel, className } = props;
  const [show, setShow] = useState(false);
  const [emailAlreadyRegistered, setEmailAlreadyRegistered] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [timeLeft, setTimeLeft] = useState(0); // Set the initial countdown time in seconds
  const [isResendEnabled, setIsResendEnabled] = useState(true);
  const [showOTP, setShowOTP] = useState(false);
  const [showLocation, setLocationShow] = useState(false);
  const [getCurrentLocation, setGetcurrentLocation] = useState(false);
  const [showForProvider, setShowForProvider] = useState(false);
  const [checkBlur, setBlur] = useState(false);
  const [name, setName] = useState("");
  const [workerMobileNum, setWorkerMobileNum] = useState("");
  const [show8, setShow8] = useState(false);
  const [countryIcon, setCountryIcon] = useState();
  const [selectedCountry, setSelectedCountry] = useState("NG");
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);
  const [setSelectedCountryDefault, selectedCountryDefault] = useState("");
  // for provider
  const handleCloseProvider = () => setShowForProvider(false);
  const handleShowProvider = () => setShowForProvider(true);

  // end provider

  const handleClose = () => {
    setShow(false);
    setEmailAlreadyRegistered(false);
  };
  const handleClose3 = () => setLocationShow(false);
  const handleShow = () => setShow(true);

  // for otp
  const handleCloseOTP = () => setShowOTP(false);
  const handleShowOTP = () => setShowOTP(true);
  // end otp

  // for Location

  const handleShowLocation = () => setLocationShow(true);
  const handleCurrentLocation = () => setGetcurrentLocation(true);
  // end Location

  // vendor login page
  const [showforVendorLogin, setShowforVendorLogin] = useState(false);

  const handleCloseVendorLogin = () => setShowforVendorLogin(false);
  const handleShowVendorLogin = () => setShowforVendorLogin(true);

  // end vendor login page

  // vendor otp
  const [showVendorOTP, setShowVendorOTP] = useState(false);
  const handleCloseVendorOTP = () => {
    setShowVendorOTP(false);
  };
  const handleShowVendorOTP = () => setShowVendorOTP(true);
  const [otpError, setOtpError] = useState("");
  const [vendorOtp, setVendorOtp] = useState("");
  const [valueOtpToVarify, setValueOtpToVarify] = useState();
  const [valueOtpToVarify4, setValueOtpToVarify4] = useState();
  const [userType, setUserType] = useState();
  const [country, setCountry] = useState([]);
  const [countryCode, setCountryCode] = useState("+234");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          apiBaseUrl + "common/get-country",
          null,
          {
            headers: {
              key: key,
            },
          }
        );

        setCountry(response.data.response.result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleOtpChange = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const updatedOtp = vendorOtp.split("");
      updatedOtp[index] = value;
      setVendorOtp(updatedOtp.join(""));
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    if (value && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };
  // fetchOtp

  const fetchOtpData = async () => {
    const formattedValue = (
      formValues.mobileNumber ||
      individualMobileNum ||
      workerMobileNum
    )?.replace(countryCode, "");
    const numericOnlyMobileNum = formattedValue?.replace(/\D/g, "");
    try {
      const response = await axios.post(
        apiBaseUrl + vendorOtpUrl,
        {
          mobile: numericOnlyMobileNum,
          otp: vendorOtp || IndividualOtp || workerOtp,
        },
        {
          headers: {
            key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      // console.error(error);
      if (
        error?.response?.data?.responseCode === 400 ||
        error?.response?.data?.responseCode === "400"
      ) {
        setOtpInError(error?.response?.data?.statusMessage);
      }
    }
  };
  // end
  const validateOtp = async () => {
    try {
      await otpSchema.validate(vendorOtp);
      setOtpError("");
      if (vendorOtp === vendorOtp) {
        fetchOtpData()
          .then((data) => {
            if (data.status === true) {
              const token = data.response.result.token;
              const userType = data.response.result.userType;
              if (token) {
                setSession("token", token);
                if (userType === "Company") {
                  setSessionForLoginCompany("vendor", data.response.result);
                  sessionStorage.setItem(
                    "vendor_service",
                    data?.response?.service
                  );
                  navigate("/vendor/my-profile");
                } else {
                  handleCloseOTP();
                  handleShow8();
                }
              } else {
                handleCloseVendorOTP();
                handleCloseVendorLogin();
                handleShowVendorLocation();
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setOtpError("OTP is not correct");
      }
    } catch (error) {
      setOtpError(error.message);
      console.log("OTP is invalid: ", error.message);
    }
  };
  // end vendor otp

  // vendor location
  const [showVendorLocation, setShowVendorLocation] = useState(false);

  const handleCloseVendorLocation = () => setShowVendorLocation(false);
  const handleShowVendorLocation = () => setShowVendorLocation(true);

  // end vendor location

  const [progress, setProgress] = useState(25);
  const handleNextClick = () => {
    // Update the progress state
    setProgress(progress + 25); // Assuming each click moves the line forward by 25%
  };

  // APi calls
  const fetchData = async () => {
    const formattedValue = (
      formValues.mobileNumber || individualMobileNum
    )?.replace(countryCode, "");
    const numericOnlyMobileNum = formattedValue?.replace(/\D/g, "");
    if (individualMobileNum) {
      setIndividualMobileNum(numericOnlyMobileNum);
    }
    if (numericOnlyMobileNum.length > 9 || individualMobileNum.length > 9) {
      setValue(numericOnlyMobileNum);
      try {
        const response = await axios.post(
          apiBaseUrl + vendorLoginApiUrl,
          {
            mobile: numericOnlyMobileNum,
            userType: userType,
            country_code: countryCode || "234",
          },
          {
            headers: {
              key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
              "Content-Type": "application/json",
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error(error);
        if (error?.response?.data.statusCode === "requestalreadysent") {
          // setValueOtpToVarify('123456')
          handleCloseVendorLogin();
          handleShowVendorOTP();
        }
      }
    } else {
      setBlur(false);
      setErrors((preError) => ({
        ...preError,
        mobileNumber: "Please enter corrrect mobile number",
      }));
    }
  };

  // end api calls

  // form validation

  const [formValues, setFormValues] = useState({
    mobileNumber: "",
  });

  const [errors, setErrors] = useState({});
  // const countryCode="+234"
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobileNumber") {
      const formattedValue = value?.replace(countryCode, "");
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));

      // Remove e.preventDefault() from here
    }

    if (name === "individualMobileNum") {
      const formattedValue = value?.replace(countryCode, "");
      // const numericOnlyMobileNum = formattedValue?.replace(/\D/g, '');
      setIndividualMobileNum(value);
    }

    if (name === "userMobileNum") {
      const formattedValue = value?.replace(countryCode, "");
      setUserMobileNum(formattedValue);
      setValue(formattedValue);
    }

    if (name === "workerMobileNum") {
      const formattedValue = value?.replace(countryCode, "");
      setWorkerMobileNum(formattedValue);
      setError_in_login((prevError) => ({
        ...prevError,
        [name]: "",
      }));
    }
  };

  const handleSubmit = (take) => {
    if (take === true) {
      handleGoogleLogin()
        .then((data) => {
          if (data.token) {
            if (data.userType === "Company") {
              setSession("token", data.token);
              // if (userType === 'Company') {
              setSessionForLoginCompany("vendor", data);
              navigate("/vendor/my-profile");
              setFlagGoogleAppleLogin(false);
            } else {
              // alert('Please logn as a Indivudual.')
              setErrors((prevErrors) => ({
                ...prevErrors,
                mobileNumber: "Please logn as a Indivudual",
              }));
            }
          } else if (data.flag === "new_user") {
            navigate(
              `/create-company-profile?data=${encodeURIComponent(data.phone)}`
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setErrors({});
      validationSchema
        .validate(formValues, { abortEarly: false })
        .then(() => {
          setBlur(true);
          fetchData()
            .then((data) => {
              if (data.status === true) {
                handleCloseVendorLogin();
                handleShowVendorOTP();
              }
            })
            .catch((error) => {
              setBlur(false);
              console.log(error);
            });
        })
        .catch((validationErrors) => {
          const newErrors = {};
          validationErrors.inner.forEach((error) => {
            newErrors[error.path] = error.message;
          });
          setBlur(false);
          setErrors(newErrors);
        });
    }
  };

  const [currentLocation, setcurrentLocation] = useState();

  useEffect(() => {
    // getCurrentLocation1();
  }, []);

  ///////////////////////////////////individual ///////////////////////////////////
  const [showforIndividualLogin, setShowforIndividualLogin] = useState(false);
  const handleCloseIndividualLogin = () => setShowforIndividualLogin(false);
  const handleShowIndividualLogin = () => setShowforIndividualLogin(true);
  const [error_in_login, setError_in_login] = useState({});
  const [error_user_login, setError_user_login] = useState({});
  const [individualMobileNum, setIndividualMobileNum] = useState("");

  const handleSubmitForIndividualLogin = (userType = "") => {
    if (userType === "Individual") {
      setFlagGoogleAppleLogin(true);
      handleGoogleLogin().then((data) => {
        if (data) {
          if (data?.userType === "Individual") {
            if (data?.token) {
              setSession("token", data.token);
              setSessionForIndividual("individual", data);
              sessionStorage.setItem(
                "individual_service",
                data?.response?.service
              );
              navigate("/individual/dashboard");
            }
          } else if (data.flag) {
            navigate(
              `/individual/create-profile?data=${encodeURIComponent(
                data.phone
              )}`
            );
          }
        } else {
          setEmailError("This email is already registered, Try another one ");
          handleCloseIndividualLogin();
          setEmailAlreadyRegistered(true);
        }
      });
    } else {
      setError_in_login({});
      validationSchemaForInvidual
        .validate({ individualMobileNum }, { abortEarly: false })
        .then(() => {
          setBlur(true);
          fetchData()
            .then((data) => {
              if (data?.status === true) {
                // setValueOtpToVarify2('123456')
                handleCloseIndividualLogin();
                handleShowIndividualOTP();
                setBlur(false);
              }
            })
            .catch((error) => {
              setBlur(false);
              console.log(error);
            });
        })
        .catch((validationErrors) => {
          const newErrors = {};
          validationErrors.inner.forEach((error) => {
            newErrors[error.path] = error.message;
          });
          console.log(newErrors);
          setBlur(false);
          setError_in_login(newErrors);
        });
    }
  };

  // for individual otp
  const [showIndividualOTP, setShowIndividualOTP] = useState(false);
  const handleCloseIndividualOTP = () => setShowIndividualOTP(false);
  const handleShowIndividualOTP = () => setShowIndividualOTP(true);
  const [otpInError, setOtpInError] = useState("");
  const [IndividualOtp, setIndividualOtp] = useState("");
  const [valueOtpToVarify2, setValueOtpToVarify2] = useState();

  const handleOtpChangeForIndividual = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const updatedOtp = IndividualOtp.split("");
      updatedOtp[index] = value;
      setIndividualOtp(updatedOtp.join(""));
    }
    setOtpInError("");
    if (value && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const validateOtp2 = async () => {
    try {
      await otpSchema.validate(IndividualOtp, { abortEarly: false });

      if (IndividualOtp === IndividualOtp) {
        fetchOtpData()
          .then((data) => {
            if (data.status === true) {
              const token = data.response.result.token;
              const userType = data.response.result.userType;
              if (token) {
                setSession("token", token);
                if (userType === "Individual") {
                  setSessionForIndividual("individual", data.response.result);
                  sessionStorage.setItem(
                    "individual_service",
                    data?.response?.service
                  );
                  navigate("/individual/dashboard");
                } else {
                  handleCloseIndividualOTP();
                  handleShow8();
                }
              } else {
                handleCloseIndividualOTP();
                handleShowIndividualLocation();
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setOtpError("OTP is not correct");
      }
    } catch (error) {
      setOtpInError("OTP must be exactly 6 characters");
    }
  };
  // end
  // for location
  const [showIndividualLocation, setShowIndividualLocation] = useState(false);

  const handleCloseIndividualLocation = () => setShowIndividualLocation(false);
  const handleShowIndividualLocation = () => setShowIndividualLocation(true);
  // end

  /////////////////////////////////End Individual ///////////////////////////////

  //////////////////////////////////// User ////////////////////////////////////
  const [showforUserLogin, setShowforUserLogin] = useState(false);
  const handleCloseUserLogin = () => setShowforUserLogin(false);
  const handleShowUserLogin = () => setShowforUserLogin(true);
  const [userMobileNum, setUserMobileNum] = useState("");
  const [otpUser, setOtpUser] = useState("");

  const [showUserOTP, setShowUserOTP] = useState(false);
  const handleCloseUserOTP = () => setShowUserOTP(false);
  const handleShowUserOTP = () => setShowUserOTP(true);
  const [otpUserError, setOtpUserError] = useState("");
  const [userOtp, setUserOtp] = useState("");

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const [valueOtpToVarify3, setValueOtpToVarify3] = useState("");
  const fetchLoginUser = async () => {
    const formattedValue = userMobileNum?.replace(countryCode, "");
    const numericOnlyMobileNum = formattedValue?.replace(/\D/g, "");
    setUserMobileNum(numericOnlyMobileNum);
    try {
      const response = await axios.post(
        apiBaseUrl + "users/login",
        {
          mobile: numericOnlyMobileNum,
          country_code: countryCode || "234",
        },
        {
          headers: {
            key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      console.log(error.response.data);
      if (error?.response?.data.statusCode === "requestalreadysent") {
        setValueOtpToVarify3("123456");
        handleCloseUserLogin();
        handleShowUserOTP();
      }
    }
  };

  const handleSubmitForUserLogin = () => {
    setError_user_login({});
    validationSchemaForUser
      .validate({ userMobileNum }, { abortEarly: false })
      .then(() => {
        // setBlur(true)
        fetchLoginUser()
          .then((data) => {
            if (data?.status === true) {
              // setValueOtpToVarify3('123456')
              handleCloseUserLogin();
              handleShowUserOTP();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((validationErrors) => {
        const newErrors = {};
        validationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setError_user_login(newErrors);
      });
  };

  // otp user

  const fetchOtpUserData = async () => {
    const formattedValue = userMobileNum?.replace(countryCode, "");
    const numericOnlyMobileNum = formattedValue?.replace(/\D/g, "");
    try {
      // console.log(userMobileNum, 'otp')
      const response = await axios.post(
        apiBaseUrl + "users/verify-login-otp",
        {
          mobile: numericOnlyMobileNum,
          otp: userOtp,
        },
        {
          headers: {
            key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      // console.error(error);
      if (
        error?.response?.data?.responseCode === 400 ||
        error?.response?.data?.responseCode === "400"
      ) {
        setOtpInError(error?.response?.data?.statusMessage);
      }
    }
  };

  const handleOtpChangeForUser = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const updatedOtp = userOtp.split("");
      updatedOtp[index] = value;
      setUserOtp(updatedOtp.join(""));
    }
    setOtpUserError("");
    if (value && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const validateOtp3 = async () => {
    try {
      await otpSchemaUser.validate(userOtp, { abortEarly: false });
      if (userOtp === userOtp) {
        fetchOtpUserData()
          .then((data) => {
            // console.log(data.response.result.token)
            if (data.status === true) {
              const token = data.response.result.token;
              const userData = data.response.result;
              setSessionForLoginUser("user", userData);
              if (token) {
                setSession("token", token);
                if (location.pathname === "/provider_profile") {
                  window.location.href = `${location.pathname}`;
                } else {
                  window.location.href = "/user_dashboard/order_history";
                }
              } else handleShowUserLocation();
              handleCloseUserOTP();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setOtpInError("OTP is not correct");
      }
    } catch (error) {
      const errorMessages = error.inner.reduce((messages, err) => {
        messages[err.path] = err.message;
        return messages;
      }, {});
      setOtpInError(errorMessages);
      console.log("OTP is invalid: ", errorMessages);
    }
  };
  // user location
  const [showUserLocation, setShowUserLocation] = useState(false);
  const handleCloseUserLocation = () => setShowUserLocation(false);
  const handleShowUserLocation = () => setShowUserLocation(true);

  ////////////////////////////////////End User ////////////////////////////////

  // worker /////////////////////////////////////////////////////////

  const [showForWorker, setShowforWorker] = useState(false);
  const handleCloseWorker = () => setShowforWorker(false);
  const handleShowWorker = () => setShowforWorker(true);
  const [showforWorkerLogin, setShowforWorkerLogin] = useState(false);
  const handleCloseWorkerLogin = () => setShowforWorkerLogin(false);
  const handleShowWorkerLogin = () => setShowforWorkerLogin(true);
  const [error_worker_login, setError_worker_login] = useState({});
  const [showWorkerOTP, setShowWorkerOTP] = useState(false);
  const handleCloseWorkerOTP = () => setShowWorkerOTP(false);
  const handleShowWorkerOTP = () => setShowWorkerOTP(true);
  const [workerOtp, setWorkerOtp] = useState("");
  const [otpWorkerError, setOtpWorkerError] = useState("");
  const [showWorkerLocation, setShowWorkerLocation] = useState(false);
  const handleCloseWorkerLocation = () => setShowWorkerLocation(false);
  const handleShowWorkerLocation = () => setShowWorkerLocation(true);

  const fetchDataforWorker = async (worker) => {
    const formattedValue = workerMobileNum?.replace(countryCode, "");
    const numericOnlyMobileNum = formattedValue?.replace(/\D/g, "");
    try {
      const response = await axios.post(
        apiBaseUrl + vendorLoginApiUrl,
        {
          mobile: numericOnlyMobileNum,
          userType: worker,
          country_code: countryCode || "234",
        },
        {
          headers: {
            key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);

      // if (error?.response?.data.statusCode === 'requestalreadysent') {
      //   // setValueOtpToVarify4('123456')
      //   handleCloseWorkerLogin()
      //   handleShowWorkerOTP()
      // }
    }
  };

  const handleSubmitForWorkerLogin = () => {
    setError_worker_login({});
    validationSchemaForWorker
      .validate({ workerMobileNum }, { abortEarly: false })
      .then(() => {
        // setBlur(true)
        fetchDataforWorker("worker")
          .then((data) => {
            if (data?.status === true) {
              // setValueOtpToVarify4('123456')
              handleCloseWorkerLogin();
              handleShowWorkerOTP();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((validationErrors) => {
        const newErrors = {};
        validationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        // console.log(newErrors);
        setError_in_login(newErrors);
      });
  };

  const handleOtpChangeForWorker = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const updatedOtp = workerOtp.split("");
      updatedOtp[index] = value;
      setWorkerOtp(updatedOtp.join(""));
    }
    setOtpWorkerError("");
    if (value && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const validateOtp4 = async () => {
    try {
      await otpSchemaWorker.validate(workerOtp, { abortEarly: false });

      if (workerOtp === workerOtp) {
        fetchOtpData()
          .then((data) => {
            if (data.status === true) {
              const token = data.response.result.token;
              const userType = data.response.result.userType;
              if (token) {
                if (userType === "Worker") {
                  setSessionForLoginWorker("worker", data.response.result);
                  setSession("token", token);
                  navigate("/worker/dashboard");
                } else {
                  handleCloseWorkerOTP();
                  handleShow8();
                }
              } else {
                handleCloseWorkerOTP();
                handleShowWorkerLocation();
                setOtpWorkerError("Invalid Credential");
                handleShow8();
              }
            } else {
              setOtpWorkerError(data.statusMessage);
              alert("invalid credential");
            }
          })
          .catch((error) => {
            setOtpWorkerError("Invalid Credential");
            handleCloseWorkerOTP();
            handleShow8();
            console.log(error);
          });
      } else {
        setOtpError("OTP is not correct");
      }
    } catch (error) {
      const errorMessages = error.inner.reduce((messages, err) => {
        messages[err.path] = err.message;
        return messages;
      }, {});
      setOtpInError(errorMessages);
      console.log("OTP is invalid: ", errorMessages);
    }
  };
  // ----------------------------------------------------------- country code/flag-------------------------------------------------------------------------
  const countries = getCountries();
  const [defaultCountryData, setDefaultCountryData] = useState(null);

  useEffect(() => {
    // Find the details of the default country
    const selectedCountryData = countryData.countries.all.find(
      (country) => country.alpha2 === selectedCountry
    );
    setDefaultCountryData(selectedCountryData);
    const countryCode = selectedCountryData?.countryCallingCodes[0];
    setCountryCode(selectedCountryData?.countryCallingCodes[0]);
  }, [selectedCountry, countries]);

  // console.log(defaultCountryData, 'defaultCountryData')
  const handleCountryChange = (country) => {
    setSelectedCountry(country);
  };
  // console.log(countryCode, 'countrycode')
  // -----------------------------------------------------------end country code/flag-------------------------------------------------------------------------
  const [isSearchBoxVisible, setSearchBoxVisible] = useState(true);

  const handleToggleSearchBox = () => {
    setSearchBoxVisible(!isSearchBoxVisible);
  };

  const handleModalClick = (e) => {
    // Prevent clicks inside the modal from closing it
    e.stopPropagation();
  };

  const startTimer = () => {
    setIsResendEnabled(false);
    setTimeLeft(60); // Reset the countdown time to the initial value
  };

  useEffect(() => {
    let countdown;

    if (timeLeft > 0) {
      countdown = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);
    } else {
      setIsResendEnabled(true);
    }

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(countdown);
    };
  }, [timeLeft]);

  const formattedTime = `${Math.floor(timeLeft / 60)
    .toString()
    .padStart(2, "0")}:${(timeLeft % 60).toString().padStart(2, "0")}`;

  const responseGoogle = (response) => {
    console.log(response);
  };

  async function GoogleAndAppleLogin(auth) {
    try {
      const apiUrl = apiBaseUrl + "provider/google-login";
      const response = await axios.post(apiUrl, null, {
        headers: {
          Authorization: auth,
        },
      });
      // console.log(data,"________________")
      return response.data.response.result;
    } catch (error) {
      // Handle any errors here
      // console.error('Error:', error);
      // alert(error.response.data.statusMessage);
      // window.location.reload()
    }
  }

  async function fetchemailForGoogleLogin(email) {
    try {
      const response = await axios.post(
        apiBaseUrl + "provider/get-google-auth-key",
        {
          email: email,
        },
        {
          headers: {
            key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
            "Content-Type": "application/json",
          },
        }
      );
      // console.log('Response:', response.data);
      const auth = response.data.response.result;
      if (response.data.status === true) {
        const data = await GoogleAndAppleLogin(auth);
        return data;
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  }

  async function generateUserAuth(email) {}
  // google login through fire=base
  const handleGoogleLogin = async (userType = "") => {
    try {
      const userCredential = await signInWithPopup(auth, googleProvider);
      // Access user data from the UserCredential object
      const user = userCredential.user;
      if (userType === "user") {
        const token = await handleGoogleLoginByUser(user.email);
        const data = await handleUserLoginWithGoogle(token);
        if (data && data !== "new_user") {
          if (location.pathname === "/provider_profile") {
            window.location.href = `${location.pathname}`;
          } else {
            window.location.href = "/user/my_profile";
          }
        } else {
          setValue(user.email);
          handleShowUserLocation();
          setShowforUserLogin(false);
        }
        // console.log('FData',data);
      } else if (userType === "worker") {
        // console.log(user.email);
        const data = await fetchemailForGoogleLogin(user.email);
        if (data.userType === "Worker") {
          setSessionForLoginWorker("worker", data);
          setSession("token", data.token);
          navigate("/worker/dashboard");
        } else {
          setError_in_login((preError) => ({
            ...preError,
            workerMobileNum: "Email is not register.",
          }));
        }
      } else {
        const data = await fetchemailForGoogleLogin(user.email);
        if (data) {
          // console.log(data,"ooooooooooooooooooooooooooooooooooooooo")
          return data;
        }
      }
    } catch (err) {
      console.error("Google login error:", err);
    }
  };

  const provider = new OAuthProvider("apple.com");

  const handleAppleLogin = async (whoUser) => {
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = OAuthProvider.credentialFromResult(result);
      const idToken = credential.idToken;
      const decoded = idToken ? jwtDecode(idToken) : null;
      // console.log(decoded.email, ",,,,,,,decoded");
      if (!isValidEmail(decoded?.email)) {
        alert("The email pattern is wrong");
        return;
      }
      let logindata = await fetchemailForGoogleLogin(decoded.email);

      switch (whoUser) {
        case "company":
          if (whoUser === "company") {
            const token = logindata.token;
            const userType = logindata.userType;
            if (token) {
              if (userType === "Company") {
                setSession("token", token);
                setSessionForLoginCompany("vendor", logindata);
                // sessionStorage.setItem('vendor_service', data?.response?.service)
                navigate("/vendor/my-profile");
              }
            } else if (userType === "Individual" || userType === "Worker") {
              setEmailError(
                `This Email Id Already Registered with ${userType}`
              );
              setEmailAlreadyRegistered(true);
              // alert();
            } else {
              const temp = {
                isEmailAvailable: 1,
                email: decoded.email,
              };
              navigate("/create-company-profile", { state: temp });
            }
          }
          break;
        case "individual":
          if (whoUser === "individual") {
            const userType = logindata.userType;
            const token = logindata.token;
            if (userType === "Individual") {
              setSession("token", token);
              setSessionForIndividual("individual", logindata);
              // sessionStorage.setItem('individual_service', data?.response?.service);
              navigate("/individual/dashboard");
            } else if (userType === "Company" || userType === "Worker") {
              setEmailError(
                `This Email Id Already Registered with ${userType}`
              );
              setEmailAlreadyRegistered(true);
            } else {
              const temp = {
                isEmailAvailable: 1,
                email: decoded.email,
              };
              navigate("/individual/create-profile", { state: temp });
            }
          }
          break;
        case "user":
          if (whoUser === "user") {
            const userType = logindata.userType;
            const token = logindata.token;
            if (userType === "User") {
              setSession("token", token);
              setSessionForLoginUser("user", logindata);
              navigate("/user/my_profile");
            } else {
              const temp = {
                isEmailAvailable: 1,
                email: decoded.email,
              };
              navigate("/create_user_profile", { state: temp });
            }
          }
          break;
        case "worker":
          if (whoUser === "worker") {
            const userType = logindata.userType;
            const token = logindata.token;
            if (userType === "Worker") {
              setSession("token", token);
              setSessionForLoginWorker("worker", logindata);
              navigate("/worker/dashboard");
            } else {
              setEmailError(
                `This Email Id Already Registered with ${userType}`
              );
              setEmailAlreadyRegistered(true);
            }
          }
          break;
        default:
          alert("Invalid user type");
      }
    } catch (err) {
      console.error(err, "......errrr");
    }
  };

  // Implement similar functions for other user types (individual, user, worker)

  const handleClosePopup = () => {
    console.log("clicked");
    setWorkerOtp("");
    setIndividualOtp("");
    setFlagGoogleAppleLogin(false);
    setValue("");
    setFormValues((prevValues) => ({
      ...prevValues,
      mobileNumber: "",
    }));
    setBlur(false);
    setShow8(false);
  };
  return (
    <>
      <div className=" header d-flex justify-content-between">
        <div className="logo">
          <Link to="/">
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/logo.png"}
              alt="logo"
            />{" "}
          </Link>
        </div>

        <ul>
          <div
            className={`d-flex serach_box_hide_show ${
              isSearchBoxVisible ? "showSerch" : "hideSerch"
            }`}
          >
            <li>
              <Link to="/" style={{ textDecoration: "none", color: "#000000" }}>
                {" "}
                Home{" "}
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                style={{ textDecoration: "none", color: "#000000" }}
              >
                {" "}
                Services{" "}
              </Link>
            </li>

            <li>
              <Link
                to="/contact"
                style={{ textDecoration: "none", color: "#000000" }}
              >
                Contact{" "}
              </Link>
            </li>
          </div>
          <div
            className={`search_box_globally ${
              isSearchBoxVisible ? "hideSerch " : "showSerch"
            }`}
          >
            <input type="text" placeholder="Search" />
          </div>
          <li>
            {userData ? (
              <Link to="/user/my_profile">
                <button className="joinAsProvider">
                  {" "}
                  {userData.fullName
                    ? capitalizeEachWord(userData.fullName)
                    : ""}
                </button>
              </Link>
            ) : VendorData ? (
              <Link to="/vendor/my-profile">
                <button className="joinAsProvider">
                  {VendorData.fullName
                    ? capitalizeEachWord(VendorData.fullName)
                    : ""}
                </button>
              </Link>
            ) : workerData ? (
              <Link to="/worker/dashboard">
                <button className="joinAsProvider">
                  {workerData.fullName
                    ? capitalizeEachWord(workerData.fullName)
                    : ""}
                </button>
              </Link>
            ) : individualData ? (
              <Link to="/individual/dashboard">
                <button className="joinAsProvider">
                  {individualData.fullName
                    ? capitalizeEachWord(individualData.fullName)
                    : ""}
                </button>
              </Link>
            ) : (
              <>
                <button className="joinAsProvider" onClick={handleShowProvider}>
                  Join As Provider
                </button>
                <button className="Log-sign-in" onClick={handleShowWorker}>
                  Login / Sign Up
                </button>
              </>
            )}
          </li>
        </ul>
      </div>
      <div className="mobile_view header">
        <div className="logo">
          <Link to="/">
            <img
              alt="logo"
              src={process.env.PUBLIC_URL + "/images/logo.png"}
              className="logo_mobile"
            />{" "}
          </Link>
          <div className="bar_icon">
            <img
              alt="search_icon"
              src={process.env.PUBLIC_URL + "/images/icroundsearch.svg"}
              className="search_icon"
            />

            <button
              className="bar_icon_toggle"
              aria-label="bar"
              onClick={toggleMenu}
            >
              <i className={`fa ${isopen ? "fa-times" : "fa-bars"}`}></i>
            </button>
          </div>
        </div>

        <div className={isopen ? "toggled" : ""}>
          <ul>
            {userData ? (
              <div id="sidebar-headre" className=" offcanvas-header">
                <div className="offcanvas-title h5">
                  <div className="urer_profle_data d-flex justify-content-between">
                    <div className="">
                      <img
                        src={BaseUrl + userData.profile_pic}
                        alt="app"
                        className="app_img_profile_pic"
                        id="mb_pro_pic"
                      />

                      {/* <img src={process.env.PUBLIC_URL+'/images/about_inner_section.png'} alt='profile_poc' /> */}
                    </div>
                    <div className="">
                      <h1>{userData.fullName}</h1>
                      <p>{userData.address1}</p>
                      <Link
                        to="/user/my_profile"
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        <button className="viewProfile-btn">
                          View Profile
                        </button>{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : VendorData ? (
              <div id="sidebar-headre" className=" offcanvas-header">
                <div className="offcanvas-title h5">
                  <div className="urer_profle_data d-flex justify-content-between">
                    <div className="">
                      <img
                        src={BaseUrl + VendorData.profile_pic}
                        alt="app"
                        className="app_img_profile_pic"
                        id="mb_pro_pic"
                      />

                      {/* <img src={process.env.PUBLIC_URL+'/images/about_inner_section.png'} alt='profile_poc' /> */}
                    </div>
                    <div className="">
                      <h1>{VendorData.fullName}</h1>
                      <p>{VendorData.address1}</p>
                      <Link
                        to="/vendor/my-profile"
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        <button className="viewProfile-btn">
                          View Profile
                        </button>{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : workerData ? (
              <div id="sidebar-headre" className=" offcanvas-header">
                <div className="offcanvas-title h5">
                  <div className="urer_profle_data d-flex justify-content-between">
                    <div className="">
                      <img
                        src={BaseUrl + workerData.profile_pic}
                        alt="app"
                        className="app_img_profile_pic"
                        id="mb_pro_pic"
                      />

                      {/* <img src={process.env.PUBLIC_URL+'/images/about_inner_section.png'} alt='profile_poc' /> */}
                    </div>
                    <div className="">
                      <h1>{workerData.fullName}</h1>
                      <p>{workerData.address1}</p>
                      <Link
                        to="/worker/profile"
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        <button className="viewProfile-btn">
                          View Profile
                        </button>{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : individualData ? (
              <div id="sidebar-headre" className=" offcanvas-header">
                <div className="offcanvas-title h5">
                  <div className="urer_profle_data d-flex justify-content-between">
                    <div className="">
                      <img
                        src={BaseUrl + individualData.profile_pic}
                        alt="app"
                        className="app_img_profile_pic"
                        id="mb_pro_pic"
                      />

                      {/* <img src={process.env.PUBLIC_URL+'/images/about_inner_section.png'} alt='profile_poc' /> */}
                    </div>
                    <div className="">
                      <h1> {individualData.fullName}</h1>
                      <p>{individualData.address1}</p>
                      <Link
                        to="/individual/profile"
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        <button className="viewProfile-btn">
                          View Profile
                        </button>{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <li className="header_text">
              <Link to="/" style={{ textDecoration: "none", color: "#000000" }}>
                {" "}
                Home{" "}
              </Link>{" "}
            </li>
            <li className="header_text">
              <Link
                to="/services"
                style={{ textDecoration: "none", color: "#000000" }}
              >
                {" "}
                Services{" "}
              </Link>{" "}
            </li>
            <li className="header_text">
              <Link
                to="/about-us"
                style={{ textDecoration: "none", color: "#000000" }}
              >
                About
              </Link>
            </li>
            <li className="header_text">
              {" "}
              <Link
                to="/getapp"
                style={{ textDecoration: "none", color: "#000000" }}
              >
                Get The App
              </Link>{" "}
            </li>
            <li className="header_text">
              <Link
                to="/contact"
                style={{ textDecoration: "none", color: "#000000" }}
              >
                Contact{" "}
              </Link>{" "}
            </li>
            <li className="header_text">
              <Link
                to="/term_condtion"
                style={{ textDecoration: "none", color: "#000000" }}
              >
                {" "}
                Terms & Conditions
              </Link>{" "}
            </li>
            <li className="header_text">
              {" "}
              <Link
                to="/privacy_policy"
                style={{ textDecoration: "none", color: "#000000" }}
              >
                {" "}
                Privacy Policy
              </Link>{" "}
            </li>
            <li className="header_text">
              <Link
                to="/Faq"
                style={{ textDecoration: "none", color: "#000000" }}
              >
                {" "}
                Help
              </Link>
            </li>
            <li className="header_text">
              <Link
                to="/Faq"
                style={{ textDecoration: "none", color: "#000000" }}
              >
                Safety
              </Link>
            </li>
          </ul>
        </div>

        {individualData || VendorData || userData || workerData ? (
          ""
        ) : (
          <div className="footer_button">
            <button className="joinAsProvider" onClick={handleShowProvider}>
              Join As Provider
            </button>

            <button className="Log-sign-in" onClick={handleShowWorker}>
              Login / Sign Up
            </button>
          </div>
        )}
      </div>

      {/* MOdal for signUp/login */}

      <Modal show={show} onHide={handleClose} centered size="lg" id="signup">
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>
          <div className="login/signup d-flex">
            <div className="ls-baner left_banner mb_display_none">
              {/* <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/user/leftBanner.png"}
                alt="banner"
              /> */}
              <div className="bnaer-text">
                <p className="fs-4 fw-bold">
                  Get Expert <br />
                  Professional Services <br /> At Your Door Step.
                </p>
              </div>
            </div>
            <div className="lg-sg-main">
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/logo.png"}
                alt="logo"
              />
              <h4>Welcome To DoQwik </h4>
              <div className="footer-line" id="lg-sg-line">
                {" "}
              </div>
              <span
                className=" dots-quick-aff-trust"
                style={{ height: "42px" }}
              >
                <p> Quick</p>{" "}
                <LazyLoadImage
                  src={process.env.PUBLIC_URL + "/images/user/dot.png"}
                  alt="logo"
                />{" "}
                <p>Affordable</p>{" "}
                <LazyLoadImage
                  src={process.env.PUBLIC_URL + "/images/user/dot.png"}
                  alt="logo"
                />{" "}
                <p>Trusted</p>
              </span>
              <div className="footer-line" id="lg-sg-line">
                {" "}
              </div>

              <label id="label-mob-num">Mobile Number</label>
              <div className="take-Mo-num">
                <LazyLoadImage
                  src={process.env.PUBLIC_URL + "/images/user/icon1.png"}
                  alt="logo"
                  onClick={handleSubmit}
                />
                <select>
                  <option> </option>
                </select>
                <input
                  type="text"
                  placeholder="Enter Mobile Number"
                  name="mobileNumber"
                  value={formValues.mobileNumber}
                  onChange={handleInputChange}
                />
                {errors.mobileNumber && <p>{errors.mobileNumber}</p>}
              </div>

              <LazyLoadImage
                className="or-line"
                src={process.env.PUBLIC_URL + "/images/user/or-line.png"}
                alt="logo"
              />
              <div className="d-flex sign-with-google">
                <LazyLoadImage
                  src={process.env.PUBLIC_URL + "/images/user/google.png"}
                  alt="logo"
                />
                <LazyLoadImage
                  src={process.env.PUBLIC_URL + "/images/user/apple.png"}
                  alt="logo"
                  // onClick={() => {
                  //   handleClose()
                  //   handleShowOTP()
                  // }}
                />
              </div>

              <div id="lg-sg-lst">
                <p>
                  By clicking continue with Google and Apple, you agree to the{" "}
                  <Link to="/term_condition">
                    <span>Terms of Use</span>
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy-policy">
                    <span>Privacy Policy</span>
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*End MOdal for signUp/login */}

      {/* MOdal for OTP*/}
      <Modal show={showOTP} onHide={handleCloseOTP} centered size="lg">
        <Modal.Body>
          <div className="cross-X" onClick={handleCloseOTP}>
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>
          <div className="login/signup d-flex">
            <div className="ls-baner left_banner left_banner mb_display_none">
              {/* <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/user/leftBanner.png"}
                alt="banner"
              /> */}
              <div className="bnaer-text">
                <p className="fs-4 fw-bold">
                  Get Expert <br />
                  Professional Services <br /> At Your Door Step.
                </p>
              </div>
            </div>
            <div className="lg-sg-main">
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/logo.png"}
                alt="logo"
              />
              <h4>Verification</h4>
              <p id="varification-title">
                Enter the 6-digit code that we sent to your phone number you
                provided to us
              </p>
              <label id="label-mob-num">Enter OTP</label>
              <div className="otp-slot">
                <input type="text" value={6} />
                <input type="text" value={7} />
                <input type="text" value={8} />
                <input type="text" value={1} />
                <input type="text" value={0} />
                <input type="text" value={2} />
              </div>

              <div className="button-container" style={{ width: "133px" }}>
                <button className="button button1">Submit</button>
                <button className="button button2">Submit</button>
              </div>

              <div
                className={
                  checkBlur
                    ? "button-container"
                    : " button-container blur-effect"
                }
                style={{ width: "176px", marginTop: "15px" }}
                onClick={() => {
                  if (timeLeft <= 0) {
                    handleSubmit();
                    startTimer();
                    setBlur(true);
                  }
                }}
              >
                <button className="button button1" id="btn-otp-resend">
                  Resend Code
                </button>
                <button className="button button2">Resend Code</button>
              </div>
              <p className="error">
                {" "}
                {timeLeft > 0 ? <div>Time left: {formattedTime}</div> : ""}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* End MOdal for OTP*/}

      {/* get location of user modal*/}

      <Modal
        show={showLocation}
        onHide={handleClose3}
        className="modal-content-wrapper"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose3}>
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div>
            {/* Your other components and content */}
            <div
              className="color-line"
              style={{
                width: `${progress}%`,
                height: "11px",
                backgroundColor: "#C30000",
                transition: "width 0.3s ease-in-out",
                borderRadius: "0px 100px 100px 0px",
              }}
            ></div>

            {/* <button onClick={handleNextClick}>Next</button> */}
          </div>
          <LazyLoadImage
            src={process.env.PUBLIC_URL + "/images/logo.png"}
            alt="playstore"
            className="cong-done-json"
            id="cong-done-json"
          />

          {/*  Allow to take current Location */}

          {!getCurrentLocation ? (
            <div>
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/user/gifLocation.gif"}
                alt="location-searching"
                className="location-seacrh"
              />
              <div className="congrats-profile-created">
                <p>Hi!! Nice to meet you.</p>
                <h2>See Services Around You</h2>
                <div className="button-container" style={{ width: "198px" }}>
                  <button className="button button1">
                    {" "}
                    <LazyLoadImage
                      src={
                        process.env.PUBLIC_URL + "/images/user/location2.png"
                      }
                      alt="playstore"
                      className="btn-location-icon"
                    />
                    <Link to="/create-profile"></Link>Your Current Location
                  </button>
                  <button
                    className="button button2"
                    onClick={() => {
                      handleCurrentLocation();
                      handleNextClick();
                    }}
                  >
                    {" "}
                    <LazyLoadImage
                      src={
                        process.env.PUBLIC_URL + "/images/user/location2.png"
                      }
                      alt="playstore"
                      className="btn-location-icon"
                    />
                    Your Current Location
                  </button>
                </div>
                <div
                  className="button-container"
                  style={{ width: "190px", marginTop: "15px" }}
                >
                  <button
                    className="button button1"
                    id="btn-otp-resend"
                    onClick={handleNextClick}
                  >
                    Some Other Location
                  </button>
                  <button className="button button2" onClick={handleNextClick}>
                    Some Other Location
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="searching-location">
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/user/gifLocation2.gif"}
                alt="serching-location"
              />

              <div className="congrats-profile-created">
                <h6>
                  Location For Services <br />
                  <span> Gwarinpa, Abuja, Nigeria</span>
                </h6>
                <p>
                  {" "}
                  Gwarinpa-900108, Abuja, Federal Capital Territory, Nigeria
                </p>
                <div className="button-container" style={{ width: "200px" }}>
                  <button className="button button1">Proceed To Next</button>
                  <button className="button button2">
                    <Link
                      to="/create-profile"
                      style={{ textDecoration: "none", color: "#ffffff" }}
                    >
                      Proceed To Next
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* End Allow to take current Location */}
        </Modal.Body>
      </Modal>

      {/* end get location of user */}

      {/* /////////////////////////////////////////////////////////////// vendor login ///////////////////////////////////////// */}

      {/* register as vendor / join as a provider */}

      <Modal show={showForProvider} onHide={handleCloseProvider} centered>
        <Modal.Body>
          <div
            className="cross-X"
            id="cross_adjust"
            onClick={handleCloseProvider}
          >
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>
          <LazyLoadImage
            src={process.env.PUBLIC_URL + "/images/logo.png"}
            alt="playstore"
            className="cong-done-json"
            id="cong-done-json"
          />
          {/*  Allow to take current Location */}
          <div style={{ marginTop: "50px" }}>
            <div className="congrats-profile-created">
              <h2
                className="text-cente"
                style={{ padding: "3%", lineHeight: "30px" }}
              >
                Choose an option to continue <br></br>with DoQwik
              </h2>
              <div className="button-container" style={{ width: "199px" }}>
                <button className="button button1">
                  {" "}
                  <Link to="/create-profile"></Link>Join As A Company
                </button>
                <button
                  className="button button2"
                  onClick={() => {
                    handleCloseProvider();
                    const temp = formValues.mobileNumber;
                    handleInputChange({
                      target: { name: "mobileNumber", temp },
                    });
                    handleShowVendorLogin();
                  }}
                >
                  {" "}
                  Join As A Company
                </button>
              </div>
              <div
                className="button-container"
                style={{ width: "190px", marginTop: "15px" }}
              >
                <button
                  className="button button1"
                  id="btn-otp-resend"
                  onClick={() => {
                    handleShowIndividualLogin();
                    handleInputChange({
                      target: {
                        name: "individualMobileNum",
                        individualMobileNum,
                      },
                    });
                    handleCloseProvider();
                  }}
                >
                  {" "}
                  Join As An Individual
                </button>
                <button
                  className="button button2"
                  onClick={() => {
                    handleShowIndividualLogin();
                    handleInputChange({
                      target: {
                        name: "individualMobileNum",
                        individualMobileNum,
                      },
                    });
                    handleCloseProvider();
                  }}
                >
                  {" "}
                  Join As An Individual
                </button>
              </div>
            </div>
          </div>
          {/* End Allow to take current Location */}
        </Modal.Body>
      </Modal>

      {/* end as a provider */}

      {/* modal for vendor login */}

      <Modal
        show={showforVendorLogin}
        onHide={handleCloseVendorLogin}
        centered
        size="lg"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleCloseVendorLogin}>
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>
          <div className="login/signup d-flex">
            <div className="ls-baner company_left mb_display_none">
              <div className="bnaer-text">
                <p className="fs-4 fw-bold">
                  Get Expert <br />
                  Professional Services <br /> At Your Door Step.
                </p>
              </div>
            </div>
            <div className="lg-sg-main ">
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/logo.png"}
                alt="logo"
              />
              {/* Company */}
              <h4>Welcome To DoQwik </h4>
              <div className="footer-line" id="lg-sg-line">
                {" "}
              </div>
              <label id="label-mob-num">Mobile Number</label>
              <div
                className={
                  errors.mobileNumber
                    ? "error_vn_login take-Mo-num"
                    : !checkBlur
                    ? "take-Mo-num "
                    : "take-Mo-num  blur-effect"
                }
                id="df"
              >
                <div className="d-flex submit_btn_Block">
                  <p>{countryCode} </p>
                  <PhoneInput
                    placeholder="Enter Mobile Number"
                    type="tel"
                    inputMode="numeric"
                    name="mobileNumber"
                    value={formValues.mobileNumber}
                    onChange={(value) =>
                      handleInputChange({
                        target: { name: "mobileNumber", value },
                      })
                    }
                    onKeyDown={(e) => {
                      // Check if the pressed key is Enter (key code 13)
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit();
                      }
                    }}
                    pattern="[0-9]*"
                    // maxLength={10}
                    required
                    defaultCountry={selectedCountry}
                    onCountryChange={handleCountryChange} // Handle country change event
                  />
                  <div className="submit-btn-container" onClick={handleSubmit}>
                    <img
                      src={process.env.PUBLIC_URL + "/images/icon/submit.png"}
                      alt="btn"
                      className="submit-btn "
                    />
                  </div>
                </div>
              </div>
              {errors.mobileNumber && (
                <p className="login-error">{errors.mobileNumber}</p>
              )}
              <LazyLoadImage
                className="or-line"
                src={process.env.PUBLIC_URL + "/images/user/or-line.png"}
                alt="logo"
              />
              <div className="d-flex sign-with-google">
                <LazyLoadImage
                  src={process.env.PUBLIC_URL + "/images/user/google.png"}
                  alt="logo"
                  onClick={() => {
                    const count = true;
                    handleSubmit(count);
                  }}
                />
                <LazyLoadImage
                  src={process.env.PUBLIC_URL + "/images/user/apple.png"}
                  alt="logo"
                  onClick={() => {
                    handleAppleLogin("company");
                  }}
                  // onClick={() => {
                  //   handleCloseVendorLogin()
                  //   handleShowVendorOTP()
                  // }}
                />
              </div>

              <div id="lg-sg-lst" className="login_para">
                <p>
                  By clicking continue with Google and Apple, you agree to the{" "}
                  {/* <span>Terms of Use And Privacy Policy.</span> */}
                  <Link to="/term_condition">
                    <span>Terms of Use</span>
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy-policy">
                    <span>Privacy Policy</span>
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* end vendor login */}

      {/* vender otp modal */}

      <Modal
        show={showVendorOTP}
        onHide={handleCloseVendorOTP}
        centered
        size="lg"
      >
        <Modal.Body>
          <div
            className="cross-X"
            onClick={() => {
              window.location.reload();
            }}
          >
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>
          <div className="login/signup d-flex">
            <div className="ls-baner company_otp left_banner mb_display_none">
              {/* <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/otpVerifyCompany.png"}
                alt="banner"
                style={{width:"100%"}}
              /> */}
              <div className="bnaer-text">
                <p className="fs-4 fw-bold">
                  Get Expert <br />
                  Professional Services <br /> At Your Door Step.
                </p>
              </div>
            </div>
            <div className="lg-sg-main">
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/logo.png"}
                alt="logo"
              />
              <h4>Verification</h4>
              <p id="varification-title">
                Enter the 6-digit code that we sent to your phone number you
                provided to us
              </p>
              <label id="label-mob-num">Enter OTP</label>
              <div className="otp-slot">
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={vendorOtp[0] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChange(0, e.target.value)}
                  ref={(input) => (inputRefs.current[0] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={vendorOtp[1] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChange(1, e.target.value)}
                  ref={(input) => (inputRefs.current[1] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={vendorOtp[2] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChange(2, e.target.value)}
                  ref={(input) => (inputRefs.current[2] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={vendorOtp[3] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChange(3, e.target.value)}
                  ref={(input) => (inputRefs.current[3] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={vendorOtp[4] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChange(4, e.target.value)}
                  ref={(input) => (inputRefs.current[4] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={vendorOtp[5] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChange(5, e.target.value)}
                  ref={(input) => (inputRefs.current[5] = input)}
                />
              </div>
              {otpError && <div className="otp-error">{otpError}</div>}

              <div className="button-container" style={{ width: "133px" }}>
                <button className="button button1">Submit</button>
                <button className="button button2" onClick={validateOtp}>
                  Submit
                </button>
              </div>

              <div
                className={
                  checkBlur
                    ? "button-container"
                    : " button-container blur-effect"
                }
                style={{ width: "176px", marginTop: "15px" }}
                onClick={() => {
                  if (timeLeft <= 0) {
                    handleSubmit();
                    startTimer();
                    setBlur(true);
                  }
                }}
              >
                <button className="button button1" id="btn-otp-resend">
                  Resend Code
                </button>
                <button className="button button2">Resend Code</button>
              </div>
              <p className="error">
                {" "}
                {timeLeft > 0 ? <div>Time left: {formattedTime}</div> : ""}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end  modal */}

      {/* vendor location modal */}

      <Modal
        show={showVendorLocation}
        onHide={handleCloseVendorLocation}
        className="modal-content-wrapper"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleCloseVendorLocation}>
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div>
            {/* Your other components and content */}
            <div
              className="color-line"
              style={{
                width: `${progress}%`,
                height: "11px",
                backgroundColor: "#C30000",
                transition: "width 0.3s ease-in-out",
                borderRadius: "0px 100px 100px 0px",
              }}
            ></div>

            {/* <button onClick={handleNextClick}>Next</button> */}
          </div>
          <LazyLoadImage
            src={process.env.PUBLIC_URL + "/images/logo.png"}
            alt="playstore"
            className="cong-done-json"
            id="cong-done-json"
          />

          {/*  Allow to take current Location */}

          {!getCurrentLocation ? (
            <div>
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/user/gifLocation.gif"}
                alt="location-searching"
                className="location-seacrh"
              />
              <div className="congrats-profile-created">
                {/* Company Page */}
                <p>Hi!! Nice to meet you.</p>
                <h2>See Services Around You</h2>
                <div className="button-container" style={{ width: "230px" }}>
                  <button className="button button1">
                    {" "}
                    <LazyLoadImage
                      src={
                        process.env.PUBLIC_URL + "/images/user/location2.png"
                      }
                      alt="playstore"
                      className="btn-location-icon"
                    />
                    <Link
                      to={`/create-company-profile?data=${encodeURIComponent(
                        value
                      )}`}
                    ></Link>
                    Your Current Location
                  </button>
                  <button
                    className="button button2"
                    onClick={() => {
                      handleCurrentLocation();
                      handleNextClick();
                    }}
                  >
                    {" "}
                    <LazyLoadImage
                      src={
                        process.env.PUBLIC_URL + "/images/user/location2.png"
                      }
                      alt="playstore"
                      className="btn-location-icon"
                    />
                    Your Current Location
                  </button>
                </div>
                <div
                  className="button-container"
                  style={{ width: "190px", marginTop: "15px" }}
                >
                  <Link
                    to={`/create-company-profile?data=${encodeURIComponent(
                      value
                    )}`}
                  >
                    <button
                      className="button button1"
                      id="btn-otp-resend"
                      onClick={handleNextClick}
                    >
                      Some Other Location
                    </button>
                    <button
                      className="button button2"
                      onClick={handleNextClick}
                    >
                      Some Other Location
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="searching-location">
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/user/gifLocation2.gif"}
                alt="serching-location"
              />

              <div className="congrats-profile-created">
                <h6>
                  Location For Services <br />
                  <span> </span>
                </h6>
                <p> {currentAddress ? currentAddress : ""} </p>
                <div className="button-container" style={{ width: "200px" }}>
                  <button className="button button1">Proceed To Next</button>
                  <button className="button button2">
                    <Link
                      to={`/create-company-profile?data=${encodeURIComponent(
                        value
                      )}`}
                      style={{ textDecoration: "none", color: "#ffffff" }}
                    >
                      Proceed To Next
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* End Allow to take current Location */}
        </Modal.Body>
      </Modal>

      {/* end vendor location modal */}

      {/* /////////////////////////////////////////////////////////////// individual logins /////////////////////////////////////////////////// */}

      <Modal
        show={showforIndividualLogin}
        onHide={handleCloseIndividualLogin}
        centered
        size="lg"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleCloseIndividualLogin}>
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>
          <div className="login/signup d-flex">
            <div className="ls-baner worker_individual mb_display_none">
              <div className="bnaer-text individual_text">
                <p className="fs-4 fw-bold">
                  Expand your service<br></br>
                  and get work with the help of<br></br>
                  <span className="sky-blue">DoQwik Platform.</span>
                </p>
              </div>
            </div>
            <div className="lg-sg-main">
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/logo.png"}
                alt="logo"
              />
              {/* Individual */}
              <h4>Welcome To DoQwik</h4>
              <div className="footer-line" id="lg-sg-line">
                {" "}
              </div>
              <span
                className=" dots-quick-aff-trust"
                style={{ height: "42px" }}
              >
                <p> Quick</p>{" "}
                <img
                  src={process.env.PUBLIC_URL + "/images/user/dot.png"}
                  alt="logo"
                />{" "}
                <p>Affordable</p>{" "}
                <img
                  src={process.env.PUBLIC_URL + "/images/user/dot.png"}
                  alt="logo"
                />{" "}
                <p>Trusted</p>
              </span>
              <div className="footer-line" id="lg-sg-line">
                {" "}
              </div>
              {/* <span id='label-mob-num'>Login As Individual</span> */}
              <label id="label-mob-num">Mobile Number</label>
              <div
                className={
                  error_in_login.individualMobileNum
                    ? "error_vn_login take-Mo-num"
                    : !checkBlur
                    ? "take-Mo-num "
                    : "take-Mo-num  blur-effect"
                }
                id="df"
              >
                <div className="d-flex submit_btn_Block">
                  <p>{countryCode} </p>
                  <PhoneInput
                    placeholder="Enter Mobile Number"
                    type="tel"
                    inputMode="numeric"
                    name="individualMobileNum"
                    value={formValues.mobileNumber}
                    onChange={(value) =>
                      handleInputChange({
                        target: { name: "individualMobileNum", value },
                      })
                    }
                    pattern="[0-9]*"
                    required
                    defaultCountry={selectedCountry}
                    onCountryChange={handleCountryChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmitForIndividualLogin();
                      }
                    }}
                  />
                  <div
                    className="submit-btn-container"
                    onClick={handleSubmitForIndividualLogin}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/images/icon/submit.png"}
                      alt="btn"
                      className="submit-btn "
                    />
                  </div>
                </div>
              </div>
              {Object.keys(error_in_login).map((key) => (
                <p key={key} className="login-error">
                  {error_in_login[key]}
                </p>
              ))}

              <LazyLoadImage
                className="or-line"
                src={process.env.PUBLIC_URL + "/images/user/or-line.png"}
                alt="logo"
              />
              <div className="d-flex sign-with-google">
                <LazyLoadImage
                  src={process.env.PUBLIC_URL + "/images/user/google.png"}
                  alt="logo"
                  onClick={() => {
                    setFlagGoogleAppleLogin(true);
                    handleSubmitForIndividualLogin("Individual");
                  }}
                />
                <LazyLoadImage
                  src={process.env.PUBLIC_URL + "/images/user/apple.png"}
                  alt="logo"
                  onClick={() => {
                    handleAppleLogin("individual");
                  }}
                  // onClick={() => {
                  //   handleClose()
                  //   otpindividualClick()
                  // }}
                />
              </div>

              <div id="lg-sg-lst">
                <p>
                  By clicking continue with Google and Apple, you agree to the{" "}
                  {/* <span>Terms of Use And Privacy Policy.</span> */}
                  <Link to="/term_condition">
                    <span>Terms of Use</span>
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy-policy">
                    <span>Privacy Policy</span>
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* individual otp */}

      <Modal
        show={showIndividualOTP}
        onHide={handleCloseIndividualOTP}
        centered
        size="lg"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleCloseIndividualOTP}>
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>
          <div className="login/signup d-flex">
            <div className="ls-baner worker_otp left_banner mb_display_none">
              {/* <LazyLoadImage src={process.env.PUBLIC_URL + "/images/otpverifyIndividual.png"} alt="banner" className="individual" /> */}
              <div className="bnaer-text individual_text">
                <p
                  className="fs-4 fw-bold"
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  Expand your service<br></br>
                  and get work with the help of <br />
                  <span style={{ color: "rgba(46, 49, 146, 1)" }}>
                    {" "}
                    DoQwik Platform.
                  </span>
                </p>
              </div>
            </div>
            <div className="lg-sg-main">
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/logo.png"}
                alt="logo"
              />
              <h4>Verification</h4>
              <p id="varification-title">
                Enter the 6-digit code that we sent to your phone number you
                provided to us
              </p>
              <label id="label-mob-num">Enter OTP</label>
              <div className="otp-slot">
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={IndividualOtp[0] || ""}
                  maxLength="1"
                  onChange={(e) =>
                    handleOtpChangeForIndividual(0, e.target.value)
                  }
                  ref={(input) => (inputRefs.current[0] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={IndividualOtp[1] || ""}
                  maxLength="1"
                  onChange={(e) =>
                    handleOtpChangeForIndividual(1, e.target.value)
                  }
                  ref={(input) => (inputRefs.current[1] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={IndividualOtp[2] || ""}
                  maxLength="1"
                  onChange={(e) =>
                    handleOtpChangeForIndividual(2, e.target.value)
                  }
                  ref={(input) => (inputRefs.current[2] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={IndividualOtp[3] || ""}
                  maxLength="1"
                  onChange={(e) =>
                    handleOtpChangeForIndividual(3, e.target.value)
                  }
                  ref={(input) => (inputRefs.current[3] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={IndividualOtp[4] || ""}
                  maxLength="1"
                  onChange={(e) =>
                    handleOtpChangeForIndividual(4, e.target.value)
                  }
                  ref={(input) => (inputRefs.current[4] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={IndividualOtp[5] || ""}
                  maxLength="1"
                  onChange={(e) =>
                    handleOtpChangeForIndividual(5, e.target.value)
                  }
                  ref={(input) => (inputRefs.current[5] = input)}
                />
              </div>
              {otpInError && <div className="otp-error">{otpInError}</div>}

              <div className="button-container" style={{ width: "133px" }}>
                <button className="button button1" onClick={validateOtp2}>
                  {" "}
                  Submit{" "}
                </button>
                <button className="button button2" onClick={validateOtp2}>
                  {" "}
                  Submit{" "}
                </button>
              </div>
              <div
                className={
                  !checkBlur
                    ? "button-container"
                    : " button-container blur-effect"
                }
                style={{ width: "176px", marginTop: "15px" }}
                onClick={() => {
                  if (timeLeft <= 0) {
                    handleSubmitForIndividualLogin();
                    startTimer();
                    setBlur(true);
                  }
                }}
              >
                <button className="button button1" id="btn-otp-resend">
                  {" "}
                  Resend Code{" "}
                </button>
                <button className="button button2">Resend Code</button>
              </div>
              <p className="error">
                {" "}
                {timeLeft > 0 ? <div>Time left: {formattedTime}</div> : ""}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end */}

      {/* individual get location */}
      <Modal
        show={showIndividualLocation}
        onHide={handleCloseIndividualLocation}
        className="modal-content-wrapper"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleCloseVendorLocation}>
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div>
            {/* Your other components and content */}
            <div
              className="color-line"
              style={{
                width: `${progress}%`,
                height: "11px",
                backgroundColor: "#C30000",
                transition: "width 0.3s ease-in-out",
                borderRadius: "0px 100px 100px 0px",
              }}
            ></div>

            {/* <button onClick={handleNextClick}>Next</button> */}
          </div>
          <LazyLoadImage
            src={process.env.PUBLIC_URL + "/images/logo.png"}
            alt="playstore"
            className="cong-done-json"
            id="cong-done-json"
          />

          {/*  Allow to take current Location */}

          {!getCurrentLocation ? (
            <div>
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/user/gifLocation.gif"}
                alt="location-searching"
                className="location-seacrh"
              />
              {/* Individual */}
              <div className="congrats-profile-created">
                <p>Hi!! Nice to meet you.</p>
                <h2>See Services Around You</h2>
                <div className="button-container" style={{ width: "230px" }}>
                  <button className="button button1">
                    {" "}
                    <LazyLoadImage
                      src={
                        process.env.PUBLIC_URL + "/images/user/location2.png"
                      }
                      alt="playstore"
                      className="btn-location-icon"
                    />
                    <Link
                      to={`/individual/create-profile?data=${encodeURIComponent(
                        individualMobileNum
                      )}`}
                    ></Link>
                    Your Current Location
                  </button>
                  <button
                    className="button button2"
                    onClick={() => {
                      handleCurrentLocation();
                      handleNextClick();
                    }}
                  >
                    {" "}
                    <LazyLoadImage
                      src={
                        process.env.PUBLIC_URL + "/images/user/location2.png"
                      }
                      alt="playstore"
                      className="btn-location-icon"
                    />
                    Your Current Location
                  </button>
                </div>
                <div
                  className="button-container"
                  style={{ width: "190px", marginTop: "15px" }}
                >
                  <Link
                    to={`/individual/create-profile?data=${encodeURIComponent(
                      individualMobileNum
                    )}`}
                  >
                    <button
                      className="button button1"
                      id="btn-otp-resend"
                      onClick={handleNextClick}
                    >
                      Some Other Location
                    </button>
                    <button
                      className="button button2"
                      onClick={handleNextClick}
                    >
                      Some Other Location
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="searching-location">
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/user/gifLocation2.gif"}
                alt="serching-location"
              />

              <div className="congrats-profile-created">
                <h6>
                  Location For Services <br />
                  <span>
                    {" "}
                    {/* {currentLocation.city} {currentLocation.region}{" "}
                    {currentLocation.country_name} */}
                  </span>
                </h6>
                <p>
                  {" "}
                  {/* {currentLocation.city}-{currentLocation.postal},{" "}
                  {currentLocation.region} <br />
                  Capital {currentLocation.country_capital},{" "}
                  {currentLocation.country_name} */}
                  {currentAddress ? currentAddress : ""}
                </p>
                <div className="button-container" style={{ width: "200px" }}>
                  <button className="button button1">Proceed To Next</button>
                  <button className="button button2">
                    <Link
                      to={`/individual/create-profile?data=${encodeURIComponent(
                        individualMobileNum
                      )}`}
                      style={{ textDecoration: "none", color: "#ffffff" }}
                    >
                      Proceed To Next
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* End Allow to take current Location */}
        </Modal.Body>
      </Modal>
      {/* Enn individual get location */}

      {/* /////////////////////////////////////////////////////////////// End individual logins /////////////////////////////////////////////////// */}

      {/* //////////////////////////////////////////////////////// Login user ///////////////////////////// */}

      <Modal
        show={showforUserLogin}
        onHide={handleCloseUserLogin}
        centered
        size="lg"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleCloseUserLogin}>
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>
          <div className="login/signup d-flex ">
            <div className="ls-baner worker_individual mb_display_none">
              {/* <LazyLoadImage src={process.env.PUBLIC_URL + "/images/individual_pop.png"} alt="banner" className="individual"/> */}

              <div className="bnaer-text individual_text">
                <p className="fs-4 fw-bold">
                  Expand your service<br></br>
                  and get work with the help of<br></br>
                  <span className="sky-blue">DoQwik Platform.</span>
                </p>
              </div>
            </div>
            <div className="lg-sg-main">
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/logo.png"}
                alt="logo"
              />
              {/* User Login */}
              <h4>Welcome To DoQwik</h4>
              <div className="footer-line" id="lg-sg-line">
                {" "}
              </div>
              <label id="label-mob-num">Mobile Number</label>
              <div
                className={
                  error_user_login.userMobileNum
                    ? "error_vn_login take-Mo-num"
                    : "take-Mo-num"
                }
                id="df"
              >
                <div className="d-flex submit_btn_Block">
                  <p>{countryCode} </p>
                  <PhoneInput
                    placeholder="Enter Mobile Number"
                    type="tel"
                    inputMode="numeric"
                    name="userMobileNum"
                    value={formValues.mobileNumber}
                    onChange={(value) =>
                      handleInputChange({
                        target: { name: "userMobileNum", value },
                      })
                    }
                    pattern="[0-9]*"
                    required
                    defaultCountry={selectedCountry}
                    onCountryChange={handleCountryChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmitForUserLogin();
                      }
                    }}
                  />
                  <div
                    className="submit-btn-container"
                    onClick={handleSubmitForUserLogin}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/images/icon/submit.png"}
                      alt="btn"
                      className="submit-btn "
                    />
                  </div>
                </div>
              </div>
              {Object.keys(error_user_login).map((key) => (
                <p key={key} className="login-error">
                  {error_user_login[key]}
                </p>
              ))}

              <LazyLoadImage
                className="or-line"
                src={process.env.PUBLIC_URL + "/images/user/or-line.png"}
                alt="logo"
              />
              <div className="d-flex sign-with-google">
                {
                  <LazyLoadImage
                    src={process.env.PUBLIC_URL + "/images/user/google.png"}
                    alt="logo"
                    onClick={() => {
                      handleGoogleLogin("user");
                    }}
                  />
                }
                <LazyLoadImage
                  src={process.env.PUBLIC_URL + "/images/user/apple.png"}
                  alt="logo"
                  onClick={() => {
                    handleAppleLogin("user");
                  }}
                  // onClick={() => {
                  //   handleClose()
                  //   otpindividualClick()
                  // }}
                />
              </div>

              <div id="lg-sg-lst">
                <p>
                  By clicking continue with Google and Apple, you agree to the{" "}
                  {/* <span>Terms of Use And Privacy Policy.</span> */}
                  <Link to="/term_condition">
                    <span>Terms of Use</span>
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy-policy">
                    <span>Privacy Policy</span>
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* //////////////////////////////////////////////////////// OTP user ///////////////////////////// */}

      <Modal show={showUserOTP} onHide={handleCloseUserOTP} centered size="lg">
        <Modal.Body>
          <div
            className="cross-X"
            onClick={() => {
              handleCloseUserOTP();
              window.location.reload();
            }}
          >
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>
          <div className="login/signup d-flex">
            <div className="ls-baner worker_otp left_banner mb_display_none">
              {/* <LazyLoadImage src={process.env.PUBLIC_URL + "/images/otpverifyIndividual.png"} alt="banner" className="individual" /> */}
              <div className="bnaer-text individual_text">
                <p
                  className="fs-4 fw-bold"
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  Expand your service<br></br>
                  and get work with the help of <br />
                  <span style={{ color: "rgba(46, 49, 146, 1)" }}>
                    {" "}
                    DoQwik Platform.
                  </span>
                </p>
              </div>
            </div>
            <div className="lg-sg-main">
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/logo.png"}
                alt="logo"
              />
              <h4>Verification</h4>
              <p id="varification-title">
                Enter the 6-digit code that we sent to your phone number you
                provided to us
              </p>
              <label id="label-mob-num">Enter OTP</label>
              <div className="otp-slot">
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={userOtp[0] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChangeForUser(0, e.target.value)}
                  ref={(input) => (inputRefs.current[0] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={userOtp[1] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChangeForUser(1, e.target.value)}
                  ref={(input) => (inputRefs.current[1] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={userOtp[2] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChangeForUser(2, e.target.value)}
                  ref={(input) => (inputRefs.current[2] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={userOtp[3] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChangeForUser(3, e.target.value)}
                  ref={(input) => (inputRefs.current[3] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={userOtp[4] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChangeForUser(4, e.target.value)}
                  ref={(input) => (inputRefs.current[4] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={userOtp[5] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChangeForUser(5, e.target.value)}
                  ref={(input) => (inputRefs.current[5] = input)}
                />
              </div>
              {otpInError && <div className="otp-error">{otpInError}</div>}

              <div className="button-container" style={{ width: "133px" }}>
                <button className="button button1" onClick={validateOtp3}>
                  Submit
                </button>
                <button className="button button2" onClick={validateOtp3}>
                  Submit
                </button>
              </div>

              <div
                className={
                  checkBlur
                    ? "button-container"
                    : " button-container blur-effect"
                }
                style={{ width: "176px", marginTop: "15px" }}
                onClick={() => {
                  if (timeLeft <= 0) {
                    handleSubmitForUserLogin();
                    startTimer();
                    setBlur(true);
                  }
                }}
              >
                <button className="button button1" id="btn-otp-resend">
                  Resend Code
                </button>
                <button className="button button2">Resend Code</button>
              </div>
              <p className="error">
                {" "}
                {timeLeft > 0 ? <div>Time left: {formattedTime}</div> : ""}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* location user */}

      <Modal
        show={showUserLocation}
        onHide={handleCloseUserLocation}
        className="modal-content-wrapper"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleCloseUserLocation}>
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div>
            {/* Your other components and content */}
            <div
              className="color-line"
              style={{
                width: `${progress}%`,
                height: "11px",
                backgroundColor: "#C30000",
                transition: "width 0.3s ease-in-out",
                borderRadius: "0px 100px 100px 0px",
              }}
            ></div>

            {/* <button onClick={handleNextClick}>Next</button> */}
          </div>
          <LazyLoadImage
            src={process.env.PUBLIC_URL + "/images/logo.png"}
            alt="playstore"
            className="cong-done-json"
            id="cong-done-json"
          />

          {/*  Allow to take current Location */}

          {!getCurrentLocation ? (
            <div>
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/user/gifLocation.gif"}
                alt="location-searching"
                className="location-seacrh"
              />
              {/* User  */}
              <div className="congrats-profile-created">
                <p>Hi!! Nice to meet you.</p>
                <h2>See Services Around You</h2>
                <div className="button-container" style={{ width: "230px" }}>
                  <button className="button button1">
                    {" "}
                    <LazyLoadImage
                      src={
                        process.env.PUBLIC_URL + "/images/user/location2.png"
                      }
                      alt="playstore"
                      className="btn-location-icon"
                    />
                    <Link
                      to={`/create_user_profile?data=${encodeURIComponent(
                        value
                      )}`}
                    ></Link>
                    Your Current Location
                  </button>
                  <button
                    className="button button2"
                    onClick={() => {
                      handleCurrentLocation();
                      handleNextClick();
                    }}
                  >
                    {" "}
                    <LazyLoadImage
                      src={
                        process.env.PUBLIC_URL + "/images/user/location2.png"
                      }
                      alt="playstore"
                      className="btn-location-icon"
                    />
                    Your Current Location
                  </button>
                </div>
                <div
                  className="button-container"
                  style={{ width: "190px", marginTop: "15px" }}
                >
                  <Link
                    to={`/create_user_profile?data=${encodeURIComponent(
                      value
                    )}`}
                  >
                    <button
                      className="button button1"
                      id="btn-otp-resend"
                      onClick={handleNextClick}
                    >
                      Some Other Location
                    </button>
                    <button
                      className="button button2"
                      onClick={handleNextClick}
                    >
                      Some Other Location
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="searching-location">
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/user/gifLocation2.gif"}
                alt="serching-location"
              />

              <div className="congrats-profile-created">
                <h6>
                  Location For Services <br />
                  <span> </span>
                </h6>
                <p> {currentAddress ? currentAddress : ""}</p>
                <div className="button-container" style={{ width: "200px" }}>
                  <button className="button button1">Proceed To Next</button>
                  <button className="button button2">
                    <Link
                      to={`/create_user_profile?data=${encodeURIComponent(
                        userMobileNum
                      )}`}
                      style={{ textDecoration: "none", color: "#ffffff" }}
                    >
                      Proceed To Next
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* End Allow to take current Location */}
        </Modal.Body>
      </Modal>
      {/* ////////////////////////////////////////////////////////  end ///////////////////////////////////////  */}

      {/* ///////////////////////////////////////////////////////////start worker //////////////////////////////// */}

      <Modal show={showForWorker} onHide={handleCloseWorker} centered>
        <Modal.Body>
          <div
            className="cross-X"
            id="cross_adjust"
            onClick={handleCloseWorker}
          >
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>

          <LazyLoadImage
            src={process.env.PUBLIC_URL + "/images/logo.png"}
            alt="playstore"
            className="cong-done-json"
            id="cong-done-json"
          />
          {/*  Allow to take current Location */}
          <div style={{ marginTop: "50px" }}>
            <div className="congrats-profile-created">
              <h2
                className="text-cente"
                style={{ padding: "3%", lineHeight: "30px" }}
              >
                Choose an option to continue <br></br>with DoQwik
              </h2>
              <div className="button-container" style={{ width: "190px" }}>
                <button
                  className="button button1"
                  onClick={() => {
                    handleShowUserLogin();
                    handleCloseWorker();
                  }}
                >
                  {" "}
                  Join As A User
                </button>
                <button
                  className="button button2"
                  onClick={() => {
                    handleShowUserLogin();
                    handleCloseWorker();
                  }}
                >
                  {" "}
                  Join As A User
                </button>
              </div>
              <div
                className="button-container"
                style={{ width: "190px", marginTop: "15px" }}
              >
                <button
                  className="button button1"
                  id="btn-otp-resend"
                  onClick={() => {
                    handleShowWorkerLogin();
                    handleCloseWorker();
                  }}
                >
                  Join As A Worker
                </button>
                <button
                  className="button button2"
                  onClick={() => {
                    handleShowWorkerLogin();
                    handleCloseWorker();
                  }}
                >
                  Join As A Worker
                </button>
              </div>
            </div>
          </div>
          {/* End Allow to take current Location */}
        </Modal.Body>
      </Modal>
      {/* //////////////////////////////////////////worker login //////////////////////////////////////// */}
      <Modal
        show={showforWorkerLogin}
        onHide={handleCloseWorkerLogin}
        centered
        size="lg"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleCloseWorkerLogin}>
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>
          <div className="login/signup d-flex">
            <div className="ls-baner worker_individual mb_display_none">
              <div className="bnaer-text individual_text">
                <p className="fs-4 fw-bold">
                  Expand your service<br></br>
                  and get work with the help of<br></br>
                  <span className="sky-blue">DoQwik Platform. </span>
                </p>
              </div>
            </div>
            <div className="lg-sg-main">
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/logo.png"}
                alt="logo"
              />
              {/* Worker */}
              <h4>Welcome To DoQwik</h4>
              <div className="footer-line" id="lg-sg-line">
                {" "}
              </div>
              <label id="label-mob-num">Mobile Number</label>
              <div
                className={
                  error_worker_login.workerMobileNum
                    ? "error_vn_login take-Mo-num blur-effect"
                    : !checkBlur
                    ? "take-Mo-num "
                    : "take-Mo-num  blur-effect"
                }
                id="df"
              >
                <div className="d-flex submit_btn_Block">
                  <p>{countryCode} </p>
                  <PhoneInput
                    placeholder="Enter Mobile Number"
                    type="tel"
                    inputMode="numeric"
                    name="workerMobileNum"
                    value={formValues.mobileNumber}
                    onChange={(value) =>
                      handleInputChange({
                        target: { name: "workerMobileNum", value },
                      })
                    }
                    required
                    defaultCountry={selectedCountry}
                    onCountryChange={handleCountryChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmitForWorkerLogin();
                      }
                    }}
                  />
                  <div
                    className="submit-btn-container"
                    onClick={handleSubmitForWorkerLogin}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/images/icon/submit.png"}
                      alt="btn"
                      className="submit-btn "
                    />
                  </div>
                </div>
              </div>
              {error_in_login.workerMobileNum && (
                <p style={{ color: "red" }}>{error_in_login.workerMobileNum}</p>
              )}

              <LazyLoadImage
                className="or-line"
                src={process.env.PUBLIC_URL + "/images/user/or-line.png"}
                alt="logo"
              />
              {/* <div
                className="d-flex sign-with-google"
                onClick={() => {
                  handleGoogleLogin("worker");
                }}
              >
                <LazyLoadImage
                  src={process.env.PUBLIC_URL + "/images/user/google.png"}
                  alt="logo"
                />
                      <LazyLoadImage
                  src={process.env.PUBLIC_URL + "/images/user/apple.png"}
                  alt="logo"
                  onClick={() => {
                    handleAppleLogin("worker");
                  }}
                />
              </div> */}

              <div className="d-flex sign-with-google">
                <LazyLoadImage
                  src={process.env.PUBLIC_URL + "/images/user/google.png"}
                  alt="logo"
                  onClick={() => {
                    handleGoogleLogin("worker");
                  }}
                />
                <LazyLoadImage
                  src={process.env.PUBLIC_URL + "/images/user/apple.png"}
                  alt="logo"
                  onClick={() => {
                    handleAppleLogin("worker");
                  }}
                />
              </div>

              <div id="lg-sg-lst">
                <p>
                  By clicking continue with Google and Apple, you agree to the{" "}
                  <Link to="/term_condition">
                    <span>Terms of Use</span>
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy-policy">
                    <span>Privacy Policy</span>
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* end worker login ///////////////////////////////////////////////// */}

      {/* //////// worker otp  */}

      <Modal
        show={showWorkerOTP}
        onHide={handleCloseWorkerOTP}
        centered
        size="lg"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleCloseWorkerOTP}>
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>
          <div className="login/signup d-flex">
            <div className="ls-baner worker_otp left_banner mb_display_none">
              {/* <LazyLoadImage src={process.env.PUBLIC_URL + "/images/otpverifyIndividual.png"} alt="banner" className="individual" /> */}
              <div className="bnaer-text individual_text">
                <p
                  className="fs-4 fw-bold"
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  Expand your service<br></br>
                  and get work with the help of <br />
                  <span style={{ color: "rgba(46, 49, 146, 1)" }}>
                    {" "}
                    DoQwik Platform.
                  </span>
                </p>
              </div>
            </div>
            <div className="lg-sg-main">
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/logo.png"}
                alt="logo"
              />
              <h4>Verification</h4>
              <p id="varification-title">
                Enter the 6-digit code that we sent to your phone number you
                provided to us
              </p>
              <label id="label-mob-num">Enter OTP</label>
              <div className="otp-slot">
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={workerOtp[0] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChangeForWorker(0, e.target.value)}
                  ref={(input) => (inputRefs.current[0] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={workerOtp[1] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChangeForWorker(1, e.target.value)}
                  ref={(input) => (inputRefs.current[1] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={workerOtp[2] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChangeForWorker(2, e.target.value)}
                  ref={(input) => (inputRefs.current[2] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={workerOtp[3] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChangeForWorker(3, e.target.value)}
                  ref={(input) => (inputRefs.current[3] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={workerOtp[4] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChangeForWorker(4, e.target.value)}
                  ref={(input) => (inputRefs.current[4] = input)}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={workerOtp[5] || ""}
                  maxLength="1"
                  onChange={(e) => handleOtpChangeForWorker(5, e.target.value)}
                  ref={(input) => (inputRefs.current[5] = input)}
                />
              </div>
              {otpWorkerError && (
                <div className="otp-error">{otpWorkerError}</div>
              )}

              <div className="button-container" style={{ width: "133px" }}>
                <button className="button button1" onClick={validateOtp4}>
                  Submit
                </button>
                <button className="button button2" onClick={validateOtp4}>
                  Submit
                </button>
              </div>

              <div
                className={
                  checkBlur
                    ? "button-container"
                    : " button-container blur-effect"
                }
                style={{ width: "176px", marginTop: "15px" }}
                onClick={() => {
                  if (timeLeft <= 0) {
                    handleSubmitForWorkerLogin();
                    startTimer();
                    setBlur(true);
                  }
                }}
              >
                <button className="button button1" id="btn-otp-resend">
                  {" "}
                  Resend Code{" "}
                </button>
                <button className="button button2">Resend Code</button>
              </div>
              <p className="error">
                {" "}
                {timeLeft > 0 ? <div>Time left: {formattedTime}</div> : ""}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* //////////////////// end worker otp */}

      {/* ////////////////////////////////////////////////////////// end worker /////////////////////////////////// */}

      <Modal
        show={show8}
        onHide={handleClosePopup}
        centered
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div
            className="cross-X"
            onClick={() => {
              handleClosePopup();
            }}
          >
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <LazyLoadImage
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>
          <div
            className="congrats-profile-created  well_done"
            style={{ marginTop: "0px" }}
          >
            {otpWorkerError ? (
              <h2> {otpWorkerError} </h2>
            ) : (
              <>
                <h2>Try Another Number!</h2>
                <p>Mobile number is not register.</p>
              </>
            )}

            <div
              className="button-container"
              style={{ width: "150px" }}
              onClick={() => {
                handleClosePopup();
              }}
            >
              <button className="button button1"> Proceed To Next</button>
              <button className="button button2">Proceed To Next</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* email already regiatred  popup */}

      <Modal
        show={emailAlreadyRegistered}
        onHide={handleClose}
        size="sm"
        centered
      >
        <Modal.Body>{emailError}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              // window.location.reload();
              handleClose()
            }}
          >
            Proceed to Next
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default Header;
