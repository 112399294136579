import React from "react";
import Slider from "react-slick";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
const customDotsClass = 'custom-dots-class';

const ServiceSlider = (props) => {

const {featuredService}= props;
const settings = {
  dots: false,
  dotsClass: 'slick-dots  customDotsClass',
  infinite: true,
  speed: 1000,
  arrows:true,
  marging:20,
  initialSlide: 6,
  slidesToShow: 6,
  autoplay:true,
  responsive: [
    // {
    //   breakpoint: 1280,
    //   settings: {
    //     slidesToShow: 5,
    //     // slidesToScroll: 2,
    //     // initialSlide: 2
    //     dots: false,
    //     arrows:true,
    //     marging:20,
    //   }
    // },
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 2,
        arrows:true,
        marging:20,
      }
    },

  
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 2,
        initialSlide: 2,
        arrows:true,
        marging:20,
      }
    },

    {
      breakpoint: 1480,
      settings: {
        slidesToShow: 6,
        // slidesToScroll: 2,
        // initialSlide: 2
        dots: false,
        arrows:true,
        marging:10,
      }
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 5,
        // slidesToScroll: 2,
        // initialSlide: 2
        dots: false,
        arrows:true,
        marging:10,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        // slidesToScroll: 2,
        // initialSlide: 2
        dots: false,
        arrows:false,
        marging:20,
      }
    },
    {
      breakpoint: 740,
      settings: {
        slidesToShow: 2.7,
        // slidesToScroll: 2,
        // initialSlide: 2
        dots: true,
        arrows:false,
        marging:20,
      }
    },
    
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1.5,
        initialSlide: 1.5,
        dots: true,
        arrows:false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1.5,
        dots: true,
        arrows:false,
      }
    }
  ]

  // responsive: [{
  //   breakpoint: 1280,
  //   settings: {
  //     slidesToShow: 5,
  //     slidesToScroll: 5,
  //     initialSlide: 5
  //   }
  // }],

 
 
 
};


  return (
    <div>
      <Slider {...settings}>
      {/* {services.map((service) => (
  service.sub_services.map((subService, index) => (
    <div key={index}>
      <div className="ts-internal">
      <LazyLoadImage src={subService.sub_service_image} alt="Background Image" />
        <div className="pic-title">
          <h1 style={{ wordBreak: 'break-word', fontSize: "16px", color: "black" }}>{subService.sub_service_name}</h1>
          <p></p>
        </div>
        <button className="button">Button</button>
      </div>
    </div>
  ))
))} */}
{/* {services.map((service) => (
  service.sub_services.map((subService, index) => (
    <div key={index}>
      <div className="ts-internal">
      <LazyLoadImage src={subService.sub_service_image} alt="Background Image" />
        <div className="pic-title">
          <h1 style={{ wordBreak: 'break-word', fontSize: "16px", color: "black" }}>{subService.sub_service_name}</h1>
          <p></p>
        </div>
        <button className="button">Button</button>
      </div>
    </div>
  ))
))} */}

{featuredService && featuredService.map((service,index) => (
    <div key={index}>
    <Link to={`/user_service_list/${service._id}`}>
      <div className="ts-internal">
      <LazyLoadImage src={service.featured_img} alt="Background Image" />
        <div className="pic-title">
          <h1 style={{ wordBreak: 'break-word', fontSize: "16px", color: "black" }}>{service.service_name}</h1>
          <p></p>
        </div>
        <button className="button">Button</button>
      </div>
      </Link>
    </div>
  ))
  }

      </Slider>
    </div>
  );
}

export default ServiceSlider;
