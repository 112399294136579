import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import './dashboard.css';
import React,{useState, useEffect } from 'react';
import Order_details from "./order_details";
import Compelete_order from "./complete_order";
import { getSession } from "../../utils/helper";
import axios from "axios";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getSessionForLoginUser } from "../../utils/helper";
import UserDashboard from "../../components/dashboardAsUser";
import { BaseUrl, apiBaseUrl } from "../../config/constant";

const Dashboard = () =>{
    const data2=getSessionForLoginUser('user');
    const navigate = useNavigate();
    const formatDate = (dateString) => {
        const date = moment(dateString, 'DD-MM-YYYY');
        return date.format('DD MMM YYYY');
      };
      const formatTime = (timeString) => {
        const time = moment(timeString, 'HH:mm');
        return time.format('hh:mm A');
      };
    const token = getSession("token");
    const [progress, setProgress] = useState(0);
    const [data,setData]=useState([]);

    const fetchUserHistoryData = async () => {
        try {
          const response = await axios.post(
            apiBaseUrl+"order/get-user-job-list",
            {
              limit: "",
              skip: "",
            },
            {
              headers: {
                Authorization: token,
                "Content-Type": "application/json",
              },
            }
          );
   
          setData(response.data.response.result);
        } catch (error) {
          console.error(error);
        }
      };
    console.log(data,"$55")
      useEffect(() => {
        fetchUserHistoryData()
      }, []);
    useEffect(() => {
        const interval = setInterval(() => {
          if (progress < 100) {
            setProgress(progress + 10);
          } else {
            clearInterval(interval);
          }
        }, 1000);
    
        return () => {
          clearInterval(interval);
        };
      }, [progress]);

      const [selectedItem, setSelectedItem] = useState(null);

      const handleItemClick = (item) => {
        setSelectedItem(item);
        navigate('/user/Order_details' , {state:item});
      };
    
return(
    <>
    <Header />
   <div className="dashboard">
    <div className="container">
        <div className="row mb_view_center">
<UserDashboard/>
            <div className="col-md-9">
             <div className="right_side">
                <div className="row mx-0">
                    <div className="col-md-6">
                        <h1>Your Order Records & Details.</h1>
                     
                    </div>
                </div>
                <div className="row mx-0" >
{data.map((item,index)=>(

                    <div className="col-md-6" key={index}>
                        <div className="order-history-box">
                            <div className="row">
                                <div className="col-3">
                                {/* <img src={process.env.PUBLIC_URL + "./hisytory_user.png"} alt="app"  className="app_img" /> */}
                                <img src={BaseUrl+item?.providerData?.profile_pic} alt="profile-image"  className="user_id_dashbaord" />
                                </div>
                                <div className="col px-0">
                                <button className={`process ${item.job_status === 'complete' ? 'complete-class' : ''}`}>
  {item.job_status}
</button>


                                    <h1>{item.service_name},{item.sub_service_name}.</h1>
                                </div>
                            </div>
                            <hr />

                            <div className="row mx-0">
                                <div className="col-md-12 mb-2">
                                <p><img src={process.env.PUBLIC_URL + "/location.png"} alt="app" />  {item?.service_location?.land_mark},{item?.service_location?.address1},{item?.service_location?.city},{item?.service_location?.state},{item?.service_location?.postal_code},{item?.service_location?.country} </p>
                    
                                </div>
                                <div className="date_options">
                                    <p>{item.service_date} at {item.service_time}</p>
                                    <span>₦ {item.amount}/-</span>
                                </div>
                                {item.job_status==='Reject' || item.job_status==='Complete' ? '' :     <div className="vertification_code">
                                  <p className="">Verification Code</p>
                                  <div className="numberes">
            {String(item.verificationCode).split('').map((char, index) => (
              <input
                key={index}
                type="text"
                placeholder={char}
              />
            ))}
          </div>


                                </div>}
                            
                                <button className="view_details"  onClick={() => handleItemClick(item)}>View Detail</button>
                            </div>
                        </div>
                    </div>
            
         
))}
                
</div>
                {/* <div className="row mx-0">
                    <div className="col-md-6">
                        <div className="order-history-box">
                            <div className="row">
                                <div className="col-md-3">
                                <img src={process.env.PUBLIC_URL + "./hisytory_user.png"} alt="app"  className="app_img" />
                                </div>
                                <div className="col-md-9 px-0">
                                    <button className="process">Process </button>
                                    <h1>Appliance Repair, AC Installation.</h1>
                                </div>
                            </div>
                            <hr />

                            <div className="row mx-0">
                                <div className="col-md-12 mb-2">
                                <p><img src={process.env.PUBLIC_URL + "./location.png"} alt="app" /> Gwarinpa-900108, Abuja, Federal Capital Territory, Nigeria</p>
                                </div>
                                <div className="date_options">
                                    <p>22 Dec 2022 at 10:00 PM</p>
                                    <span>₦ 230/-</span>
                                </div>
                                <div className="vertification_code">
                                  <p className="">Verification Code</p>
                                  <div className="numberes">
                                   <input type="text" placeholder="6"/>
                                   <input type="text" placeholder="7"/>
                                   <input type="text" placeholder="8"/>
                                   <input type="text" placeholder="1"/>
                                   <input type="text" placeholder="0"/>
                                   <input type="text" placeholder="2"/>
                                  </div>
                                </div>
                                <button className="view_details"><Link to="/user/Order_details">View Detail</Link></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                    <div className="order-history-box">
                            <div className="row">
                                <div className="col-md-3">
                                <img src={process.env.PUBLIC_URL + "./hisytory_user.png"} alt="app"  className="app_img" />
                                </div>
                                <div className="col-md-9 px-0">
                                <button className="order_compelte">Order Complete</button>
                                    <h1>Appliance Repair, AC Installation.</h1>
                                </div>
                            </div>
                            <hr />

                            <div className="row mx-0">
                                <div className="col-md-12 mb-2">
                                <p><img src={process.env.PUBLIC_URL + "./location.png"} alt="app" /> Gwarinpa-900108, Abuja, Federal Capital Territory, Nigeria</p>
                                </div>
                                <div className="date_options">
                                    <p>22 Dec 2022 at 10:00 PM</p>
                                    <span>₦ 230/-</span>
                                </div>
                                <div className="vertification_code">
                                  <p className="">Verification Code</p>
                                  <div className="numberes">
                                   <input type="text" placeholder="6"/>
                                   <input type="text" placeholder="7"/>
                                   <input type="text" placeholder="8"/>
                                   <input type="text" placeholder="1"/>
                                   <input type="text" placeholder="0"/>
                                   <input type="text" placeholder="2"/>
                                  </div>
                                </div>
                                <div className="details_btns">
                                <button className="view_detail"> <Link to="/order_compelete">View Detail</Link></button>
                                <button className="rate">Rate</button>
                                <button className="Reorder">Reorder</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
             </div>
            </div>
        </div>
    </div>
   </div>
   <section className="mb-0">
    <div className="download_aap">
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                <img src={process.env.PUBLIC_URL + "/images/app_img.png"} alt="app"  className="app_img" />
                 
                </div>
                <div className="col-md-6 pt-4">
                    <h1>Download the app</h1>
                    <p>Get the app. Get things done. Compare prices, read reviews and book top-rated services. All in one free app.</p>
                </div>
                <div className="col-md-3">
                   <ul>
                    <li> <img src={process.env.PUBLIC_URL + "/androied.png"} alt="app"  className="app_img" /> </li>
                    <li> <img src={process.env.PUBLIC_URL + "/images/ios.png"} alt="app"  className="app_img" /> </li>
                    
                 
                   </ul>
                </div>
            </div>
        </div>
    </div>
</section>
    <Footer />
    </>
);
};

export default Dashboard;
