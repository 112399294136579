import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Modal from "react-bootstrap/Modal";
import { getSessionForLoginUser, getSession, getSessionForLoginWorker,setSessionForLoginWorker,logout } from "../../utils/helper";
import axios from "axios";
import DashboardAsWorker from "../../components/DashboardAsWorker";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { BaseUrl, apiBaseUrl } from "../../config/constant";
import { getCountry,getSate,getCity } from "../../components/common";

const WorkerProfile = () => {
  const vendor = getSessionForLoginWorker("worker");
  const percentage = vendor?.profileComplete || 70;
  const token = getSession("token");
  const [show,setShow]=useState(false);
  const [show2,setShow2]=useState(false);
  const [show3,setShow3]=useState(false);
  const [checkBlur, setBlur] = useState(true);
  const [country, setCountry] = useState([])
  const[ state, setState ]=useState([]);
  const[city, setCity]=useState([])
  const  handleClose=()=>{setShow(false);  setBlur(true)}
  const  handleClose2=()=>{setShow3(false);  setShow2(false)}
  const [formData, setFormData] = useState({
    fullName: vendor.fullName,
    email: vendor.email,
    phone: vendor.phone,
    country: vendor.country,
    state: vendor.state,
    city: vendor.city,
    address1: vendor.address1,
    address2: vendor.address2,
    land_mark: vendor.land_mark,
    postal_code: vendor.postal_code,
    companyRegNo: vendor.companyRegNo,
    tinNumber: vendor.tinNumber,
    bankAcountNumber: vendor.bankAcountNumber,
    platform: "Web v1",
    identificationNumber:vendor.identificationNumber,
    identificationType:vendor.identificationType
//     profile_pic:""
// certificate""
  });

  const handleChange = async(e) => {
    const { name, value } = e.target;
    if(name==='country'){
    
      const jsonObject = JSON.parse(value);
      const country= await  getSate(jsonObject.id);
      setState(country)
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: jsonObject.name,
      }));
    }else if(name==='state'){
      const jsonObject = JSON.parse(value);
      const state= await  getCity(jsonObject.id);
  
  setCity(state)
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: jsonObject.name,
      }));
    }else if(name==='city'){
      const jsonObject = JSON.parse(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: jsonObject.name,
      }));
    }else {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }
  };
// const handleChange= (e) =>{
// const name = e.target.name;
// const value = e.target.value;
// }
  const handleSubmit = (e) => {
    e.preventDefault();
if(checkBlur){
  setBlur(false)
  return;
}else{
    // Make the API request to update the profile
    axios
      .post(
        apiBaseUrl+"provider/update-profile",
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/x-www-form-urlencoded", // You may need to adjust the Content-Type based on the server's requirements
          },
        }
      )
      .then((response) => {
        // Handle success response here if needed
        setSessionForLoginWorker('worker',response.data.response.result);
        setShow(true)
  //  window.reload();
      })
      .catch((error) => {
        // Handle error response here if needed
        console.error("Error updating profile:", error);
      });
    }
  };

    const handleLogout=()=>{
    logout();
    window.location = "/";
}

  const handleDeleteAccount = async () => {
    try {
      setShow2(false)
      const response = await axios.post(
        apiBaseUrl+"common/delete-request",
        {
          platform:"Web",
          ip:"  "
        }, // Send an empty object as the request data
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json", // Use "Content-Type" instead of "content-type"
          },
        }
      );
      if(response.data.status===true){

        handleLogout();
      }
  
      // Assuming the response contains the data you need
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const countries = await getCountry();
        setCountry(countries);
      } catch (error) {
        // Handle any errors here
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);



  return (
    <>
      <Header />
      <div className="dashboard" style={{ marginTop: "105px" }}>
        <div className="container">
          <div className="row mt-4 pt-4 mb_view_center">
          <DashboardAsWorker/>
            <div className="col-md-9 ">
              <div className="form_book_appintment ">
                <div
                  className="d-flex justify-content-between"
                  style={{ height: "70px" }}
                >
                  <div className="d-flex">
                    <div className="v-profile-imag">
                    <div style={{ width: '69px',margin:'auto' ,height:'98px'}}>
      <CircularProgressbar  value={percentage} 
        strokeWidth={6}
        styles={buildStyles({
          strokeLinecap: 'butt', // Use 'butt' for a round image, 'round' for a circular image
          pathColor: `rgba(86,209,98, 1, ${percentage / 100})`,
          textColor: '#24A831',
          trailColor: '#d6d6d6',
         
        })}
        id="circularbar_dashboard"
    />
                          <img src={vendor?.profile_pic?BaseUrl+vendor?.profile_pic :'https://doqwik.ng/images/avatar-default-icon.png'}  alt="app"  className="app_img_profile_pic"    id="dashborad_pro" />
                <div className='circular_value'>
       <p  className='percentage_value' id="percntage_vendor_dash" > {`${percentage}%`} </p>
       </div>
       <div
                        class="button-container"
                    onClick={()=>{setShow3(true)}}
                        id='delete_btn_profile'
                      >
                        <button
                          className="button button1"         
                        >
                       Delete Profile
                        </button>
                        <button
                          className="button button2"
                        >
              Delete Profile
                        </button>
                      </div>
       </div>
                      {/* <img src={""+vendor.profile_pic } alt="profile-image"/> */}
                    </div>
                    <p className="fill_form" id="fill_form">
                      <span>  Complete Your Profile Details. </span> <br />
                    
                    </p>
                  </div>

                  <div className="add-pro-img">
                    <div className="image-containe">
                      <div
                        class="button-container"
                        style={{ width: "186px", marginTop: "-40px" }}
                      >
                        <button
                          className="button button1"
                          onClick={handleSubmit}
                        >
                        {checkBlur? <> Update Profile</> : <>Save Profile</> }
                        </button>
                        <button
                          className="button button2"
                          onClick={handleSubmit}
                        >
                 {checkBlur? <> Update Profile</> : <>Save Profile</> }
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
   
                <div className="row">
                  <div className="col">
                    <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                      <input
                        type="text"
                        placeholder="Full Name"
                        name="fullName"
                        id="name"
                        oninput="allow_alphabets(this)"
                        value={formData.fullName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                      <input
                        type="text"
                        placeholder="Email Address"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                      <input
                        type="text"
                        placeholder="Mobile Number"
                        maxlength="10"
                        minlength="10"
                        name="phone"
                        id="phone"
                        onkeypress="return onlyNumberKey(event)"
                        value={formData.phone}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-xs-12 col-md-4 col-lg-4">
                    <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                      <div className="custom-select-wrapper">
                        <select class="custom-select"   name="country"       onChange={handleChange}>
                        <option>{formData.country}</option>
                    {country.map((item, index)=>(
    <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                    ))}
                        </select>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/user/selectArrow.png"
                          }
                          alt="arrow"
                          className="arrow-image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-xs-12 col-md-4 col-lg-4">
                  <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                      <div className="custom-select-wrapper">
                        <select class="custom-select"              onChange={handleChange}     name="state">
                        <option>{formData.state}</option>
                    
                    {state.map((item, index)=> (           
                     <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                   ) )} 
                        </select>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/user/selectArrow.png"
                          }
                          alt="arrow"
                          className="arrow-image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-xs-12 col-md-4 col-lg-4">
                  <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                      <div className="custom-select-wrapper">
                        <select class="custom-select"        onChange={handleChange}  name="city">
                        <option>{formData.city}</option>
                    {city.map((item, index)=>(
                      <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                    ))}
                        </select>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/user/selectArrow.png"
                          }
                          alt="arrow"
                          className="arrow-image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 col-lg-4 col-xs-12 col-sm-12 ">
                  <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                      <input
                        type="text"
                        placeholder="Landmark"
                        name="name"
                        id="name"
                        oninput="allow_alphabets(this)"
                        value={formData.land_mark}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xs-12 col-sm-12 ">
                  <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                      <input
                        type="text"
                        placeholder="Address Line 1 "
                        name="name"
                        id="name"
                        oninput="allow_alphabets(this)"
                        value={formData.address1}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                <div className="col-md-4 col-lg-4 col-xs-12 col-sm-12 ">
                  <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                      <input
                        type="text"
                        placeholder="Address Line 2"
                        name="name"
                        id="name"
                        oninput="allow_alphabets(this)"
                        value={formData.address2}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xs-12 col-sm-12 ">
        <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                      <input
                        type="text"
                        placeholder="Postal Code"
                        maxlength="10"
                        minlength="10"
                        name="phone"
                        id="phone"
                        onkeypress="return onlyNumberKey(event)"
                        value={formData.postal_code}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form_book_appintment mt-4 mb-4 pb-4">
                <div
                  className="d-flex justify-content-between"
                  style={{ height: "70px" }}
                >
                  <p className="fill_form">
                    <span> Verification </span> <br />
                    Complete Your Verification Details.
                  </p>
                </div>
                {/* Complete Your Verification Details. */}
                <div>
                  <div className="row">
                    <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 user_selection">

          <ul className="subService-category">
       
              <li className='active'>
         {formData.identificationType}
        </li>

          </ul>
                    </div>
                  
            
                    <div className="col-5">
                    <div className={!checkBlur ?"form_group":"blur-effect form_group"}>
                        <input
                          type="text"
                          placeholder="Identification Card No. "
                          name="name"
                          id="name"
                          oninput="allow_alphabets(this)"
                          value={formData.identificationNumber}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div
                        class="button-container web_display_block"
                        style={{ width: "186px", marginTop: "-40px" }}
                      >
                        <button
                          className="button button1"
                          onClick={handleSubmit}
                        >
                        {checkBlur? <> Update Profile</> : <>Save Profile</> }
                        </button>
                        <button
                          className="button button2"
                          onClick={handleSubmit}
                        >
                 {checkBlur? <> Update Profile</> : <>Save Profile</> }
                        </button>
                      </div>
      <Footer />


      <Modal show={show}
         onHide={handleClose}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose}>
            <img
                src={process.env.PUBLIC_URL + "../images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "../images/user/X.png"}
                alt="playstore"
              />
            </div>
          

            <div className="congrats-profile-created  well_done" style={{marginTop:"0px"}}>
            <img
                src={process.env.PUBLIC_URL + "../images/greeting.gif"}
                alt="playstore" className="gif"
              />
        
          <h2>Profile Updated!</h2> 
         {/* <p>Please Continue Your Work</p> </>:<h2>Job Rejected!</h2>  */}
       
         
              
              <div class="button-container" style={{ width: "150px" }} onClick={handleClose}>
                <button className="button button1" >  Proceed To Next
               </button>
                <button className="button button2">
      Proceed To Next
             
                </button>
              </div>
          
            </div>
          </Modal.Body>
        </Modal> 

        <Modal show={show2} onHide={()=>{setShow2(false)}}>
        <Modal.Header closeButton>
          <Modal.Title> Are you sure you want to delete this profile? </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <img src={process.env.PUBLIC_URL + "/images/delete_icon.png" } className="delete_images"></img>
          </Modal.Body>
        <Modal.Footer>
        <div className="proceed_next mt-0 mb-2">
 
  <div class="button-container edit_proceed mt-4 mb-4" style={{marginRight:"5px"}} onClick={()=>{setShow2(false)}} ><button class="button button1" id="btn-otp-resend">No</button><button class="button button2">No</button></div>

           <div class="button-container proccess_next mt-4 mb-4 mr-0" ><button class="button button1" onClick={handleDeleteAccount}> Yes </button><button class="button button2" onClick={handleDeleteAccount}>  Yes</button></div>
           </div>
        </Modal.Footer>
      </Modal>

      <Modal show={show3} onHide={()=>{setShow3(false)}}>
      <Modal.Header closeButton>
        <Modal.Title> <h6>Are you sure you want to delete this profile?</h6> </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <img src={process.env.PUBLIC_URL + "/images/delete_icon.png" } className="delete_images"></img>
        </Modal.Body>
      <Modal.Footer>
        <div className="proceed_next mt-0 mb-2">
          <div class="button-container edit_proceed mt-4 mb-4" style={{marginRight:"5px"}} onClick={()=>{setShow3(false)}}>
            <button class="button button1" id="btn-otp-resend">No</button>
            <button class="button button2">No</button>
          </div>
          <div class="button-container proccess_next mt-4 mb-4 mr-0" >
            <button class="button button1" onClick={handleDeleteAccount}> Yes </button>
            <button class="button button2" onClick={handleDeleteAccount}>  Yes</button>
          </div>
        </div>
    </Modal.Footer>
  </Modal>
    </>
  );
};

export default WorkerProfile;
