
import Header from './../../components/header';
import Footer from './../../components/footer';
import { Container} from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import './get_app.css';
import axios from "axios";
import { apiBaseUrl } from '../../config/constant';

const Getapp = ()=>{
  const [get_app,setget_app] = useState([]);
  const [bannerheading,setbanerheading] = useState(['']);
  const [easy_selector,seteasy_selector] = useState(['']);

  useEffect(() => {
  
    const fetchData = async () => {
      try {
        const response = await axios.get(
          apiBaseUrl+'common/web-get-getapp-page-data',
          {
            headers: {
              key: 'd1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed',
              'Content-Type': 'application/json',
            },
          }
        );
  
        setget_app(response.data.response.result.top_banner);
        setbanerheading(response.data.response.result.pageheadings);
        seteasy_selector(response.data.response.result.getstep);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, []);  

    return(
    <>
    <Header />
    <div className='getapp'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-3 col-lg-2 col-xl-3'>
                    <img src={process.env.PUBLIC_URL + "/images/getapp/ladies.png" } className='getapp_img' />
                </div>
                <div className='col-md-6 col-lg-8 col-xl-5'>
                    <div className='expert_main'>
                    <div>
  {get_app && get_app.map((item, index) => (
    <div key={index}>
      <h1>{item.title[0]}</h1>
     <p id='mb-margin-getApp'>{item.title[1]}
    {item.title[2]}
    {item.title[3]}
    {item.title[4]}
      {item.title[5]}</p>
    </div>
  ))}
</div>
</div>
                    
                </div>
                <div className='col-md-3 col-lg-2 col-xl-4'>
                    <div className='rotation_img'>
                    {/* {get_app.length > 0 && <p>{get_app[0].iamges
                    }</p>} */}
                    <img src={process.env.PUBLIC_URL +  "./images/get_pp_mobile.png"} className='getapp_imges' />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="inner_section">
       <div className='container'>
            <div className='row mb_view_center'>
                <div className='col-md-0 col-lg-2'></div>
                <div className='col-md-12 col-lg-8'>
                <h1>
  {bannerheading && bannerheading.length > 0 && bannerheading[0].heading && bannerheading[0].heading.length > 0 && (
    <>
      {bannerheading[0].heading[0].title.split(" ").map((word, index) => {
        if (index === 3) {
          return <React.Fragment key={index}>{word} <br></br> </React.Fragment>;
        } else if (index > 6 || index < 8) {
          return <span key={index}>{word} </span>;
        } else {
          return <React.Fragment key={index}>{word} </React.Fragment>;
        }
      })}
    </>
  )}
</h1>

<div>
  <p>
    {bannerheading && bannerheading.length > 0 && bannerheading[0]?.heading && bannerheading[0]?.heading.length > 0 && (
      <>{bannerheading[0]?.heading[0]?.description}</>
    )}
  </p>
</div>

                </div>
                <div className='col-md-0 col-lg-2'></div>
            </div>
        </div>    
        <div className='section'>
        <div className='container'>
            <div className='row mb_view_center'>
             
               
            {easy_selector && easy_selector.map((section, index) => (
  <div className='col-md-12 col-lg-12 col-xl-6' key={index}>
    <div className="selector">
      <div className="overlay"></div>
      <div className="selector_img">
        <img src={section.image_1} className="user_location" alt="Section Image" />
      </div>
      <div className="selector_section">
        <div className="selector_inner--section">
          <span>{section?.title?.[0]}</span>
          <h1>{section?.title?.[1]}</h1>
          <p>{section?.title?.[2]}</p>
        </div>
      </div>
    </div>
  </div>
))}


                   
        
            </div>
        </div>
        </div>
        
    </div>

    <div className="homepage" id="app-sectionID get_app_inner_section">
<div className="app-section ">



      <img className="app-section-child" alt="" src="/rectangle-11.svg" />

      <img className="app-section-item" alt="" src="/ellipse-5.svg" />
      <img className="mobile-user-1-icon" alt="" src="/images/index_app_img.png" />
      <b className="download-the-app">Download the App</b>
      <div className="get-the-app-container">
      <div>
  <p className="get-expert" >
  {bannerheading && bannerheading.length > 0 && bannerheading[0]?.heading && bannerheading[0]?.heading.length > 0 && (
  <>
    {bannerheading[0]?.heading[1]?.description.split(" ").map((word, index, array) => (
      <React.Fragment key={index}>
        {index === array.length - 4 && <br />}
        {word + " "}
      </React.Fragment>
    ))}
  </>
)}
    {/* {bannerheading && bannerheading.length > 0 && bannerheading[0]?.heading && bannerheading[0]?.heading.length > 0 && (
      <>{bannerheading[0]?.heading[1]?.description}</>
    )} */}
  </p>
</div>
        
        {/* <p className="get-expert">All in one free app.</p> */}
      </div>
      <div className="book-professional-experts-container">
    
      <p className="get-expert" >
      {bannerheading && bannerheading.length > 0 && bannerheading[0]?.heading && bannerheading[0]?.heading.length > 0 && (
  <>
    {bannerheading[0]?.heading[1]?.title.split(" ").map((word, index, array) => (
      <React.Fragment key={index}>
        {index === array.length - 4 && <br />}
        {word + " "}
      </React.Fragment>
    ))}
  </>
)}



  </p>
      </div>
      <div >
      <img className="btns-1-icon" alt="" src="/images/buttones.png"  />
      </div>
      {/* <img className="mask-group-icon6" alt="" src="/mask-group4@2x.png" /> */}
      
      <img
        className="mobile-mockup-1-icon"
        alt=""
        src="/images/m-test.png"
      />
    </div>
</div>

<div className="mobile">
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-12 col-lg-12 col-xl-4  light_color_left_side">
      <img
        className="mobile_left_side" alt="" src="/images/m-test.png"
      />
      </div>
      <div className="col-md-12 col-lg-12 col-xl-4 white-color">
      <b >Download the App</b>
      <div >
      <div className="book_expert">
      {bannerheading && bannerheading.length > 0 && bannerheading[0]?.heading && bannerheading[0]?.heading.length > 0 && (
  <>
    {bannerheading[0]?.heading[1]?.title.split(" ").map((word, index, array) => (
      <React.Fragment key={index}>
        {index === array.length - 4 && <br />}
        {word + " "}
      </React.Fragment>
    ))}
  </>
)}

      </div>
        <p className="get_app_para">
        {bannerheading && bannerheading.length > 0 && bannerheading[0]?.heading && bannerheading[0]?.heading.length > 0 && (
  <>
    {bannerheading[0]?.heading[1]?.description.split(" ").map((word, index, array) => (
      <React.Fragment key={index}>
        {index === array.length - 4 && <br />}
        {word + " "}
      </React.Fragment>
    ))}
  </>
)}</p>
      </div>
      <div >
      <img className="button_app_download" alt="" src="/images/buttones.png" />
      </div>
      </div>
      <div className="col-md-12 col-lg-12 col-xl-4 light_color_right_side">
      <img className="mobile_right_side" alt="" src="/images/index_app_img.png" />
</div>
    </div>
  </div>
</div>
<div className="stay-update ">
<Container>
<div className="d-flex">
<div style={{width:"50%"}}>
<h4>
Stay up to date with our Newsletter
</h4>
<p>
Keep up our latest news and events. Subscribe our newsletter
</p>
</div>

<div className="btn-bottom d-flex justify-content-between">
      <input
        type="email"
        placeholder="Enter your Email Address"
      />
      <button>Subscribe Now</button>
    
    </div>
</div>

</Container>
</div>
   
    <Footer />
   
    </>
    );
};

export default Getapp;
