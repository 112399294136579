import Header from "../../components/header";
import Footer from "../../components/footer";
import React, { useCallback, useEffect, useState ,useRef} from "react";
import axios from "axios";
import './providerlist.css'
import { faStar} from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ServiceImage from "../../slider/serviceImage";
import { setSessionForAddress } from "../../utils/helper";
import { BaseUrl, apiBaseUrl, homePageApiUrl,seviceetailsPageData } from "../../config/constant";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { getSessionForLoginUser } from "../../utils/helper";
const Providerlist = ()=>{
  //Load Local storage
  const service_details_pageData = JSON.parse(localStorage.getItem('service_details_page'));
  const service_details_bannerImageData = JSON.parse(localStorage.getItem('service_details_bannerImage'));
  //End
  const { key} = useParams();
  const userData= getSessionForLoginUser('user');
  const [SERVICENAME, setSERVICENAME] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state; 

  const searchParams = new URLSearchParams(location.search);
  const maxRating = 5;
  // const key = searchParams.get('data');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [userList, setUserList] = useState([]);
  const[popularservice,setpopularservice] = useState([]);

  const [pageData , setPageData] = useState(service_details_pageData?service_details_pageData:[]);
  const [bannerImage , setBannerImage] = useState(service_details_bannerImageData?service_details_bannerImageData:'');

  async function fetchPageData() {
    try {
      const response = await axios.get(apiBaseUrl+seviceetailsPageData, {
        headers: {
          key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
        },
      });
      setPageData(response.data.response.result);
      localStorage.setItem('service_details_page',JSON.stringify(response?.data?.response?.result))
      setBannerImage(response.data.response.result.banner[0].image)
      localStorage.setItem('service_details_bannerImage',JSON.stringify(response?.data?.response?.result))
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          apiBaseUrl+'users/web-service-provider-list',
          {
            service_id:key,
            city:"" || data
          },
         
          {
            headers: {
              key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
              'Content-Type': 'application/json'
            }
          }
        );
        setpopularservice(response.data.response.popularService)
        setUserList(response.data.response.result);
        setSERVICENAME(response.data.response.services_name);
        // console.log(response.data.response.services_name);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    fetchPageData();
    window.scrollTo(0, 0);
  }, [key]);

    const percentage = 70;
    // const profileImage = 'http://localhost:3000/vendor_dashboard/my%20Profile.png';
    const [activeTab, setActiveTab] = useState('');

    useEffect(() => {
      if (userList.length > 0) {
        setActiveTab(userList[0].serviceName);
      }
    }, [userList]);

    const handleTabChange = (item) => {
      setActiveTab(item.serviceName);
   
      
    };

    const filteredProviders = userList.filter(
      (service) => service.serviceName === activeTab
    );

// const headerRef = useRef();

// const openHeaderModal = () => {
//   // Access the Header component's method to open the modal
//   if (headerRef.current) {
//   headerRef.current.openModal();
//   }
// };
const handleId=(item)=>{
  setSessionForAddress('pid', item._id);
  navigate('/provider_profile' , {state:{item,filteredProviders,activeTab }});
}

// console.log(userList);
return(
<>
<Header  />


<div className="about_us privider_list" style={{ backgroundImage: `url(${bannerImage})` }}>
    <div className="container">
        <div className="row mb_view_center ">
            <div className="col-md-12 col-lg-12">
            <div className="about_banner_content provider_list_es">

            {pageData?.banner?.length && (
              <>
                <h1>{pageData.banner[0].title[0]}</h1>
                <p>{pageData.banner[0].title[1]}</p>
              </>
              )}
            </div>
      
            </div>
            <div className="col-md-7 col-lg-7">
           
            </div>
        </div>
    </div>
</div>
{pageData?.pageheadings?.length > 0 && (
  
  <>
    <div className="h-most-Popular-service mt-5" >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          <h4 className="choose_cleanser">{
          (pageData.pageheadings[0].heading[0].title).replace("{SERVICE NAME}", SERVICENAME)
          } 
         </h4>
            <p>{pageData.pageheadings[0].heading[0].description}</p>
          </div>
        </div>
      </div>
    </div>
  </>
)}
<div className="services_tabs">
<div>
      <ul className="nav nav-tabs">
      {userList.map((item,index)=>(
        <li className="nav-item" key={index}>
          <button
            className={`nav-link ${activeTab === item.serviceName ? 'active' : ''}`}
            onClick={() => handleTabChange(item)}
          >
         {item.serviceName}
          </button>
        </li>
      ))}

      </ul>

      <div className="tab-content">
        <div
          className={`tab-pane fade ${activeTab  ? 'show active' : ''}`}
          id="tab1"
        >
       <div className="provider_list">
    <div className="container">
        <div className="row">
            <h1>Providers Of {activeTab}</h1>
        </div>
     <div className="row">
        {filteredProviders[0]?.providerList?.map((item, index) => (
          <div className="col-md-4 col-lg-2 col-sm-6" onClick={()=>{handleId(item)}} key={index}>
          {/* <Link to={`/provider_profile?data=${encodeURIComponent(item._id)}`}>  */}
  <ul className="lisinting_content">
                <li>
                    <div className="provider_list_box" id="provider_box">
                    <div style={{ width: '100px',margin:'auto' ,height:'98px'}}>
      <CircularProgressbar  value={percentage} 
        strokeWidth={6}
        styles={buildStyles({
          strokeLinecap: 'butt', // Use 'butt' for a round image, 'round' for a circular image
          pathColor: `rgba(86,209,98, 1, ${percentage / 100})`,
          textColor: '#24A831',
          trailColor: '#d6d6d6',
         
        })}
    />
     
      <img  className="provider_user_img"
         src={BaseUrl+item.profile_pic}
        alt="Your Image"
        style={{
          width: '63px',
          height: '65px',
          position: 'absolute',
          transform: 'translate(-119%, 16%)',
          // border-radius:'50%',
         
        }}
      />
      <div className='circular_value'>
       <p  className='percentage_value' > {`${percentage}%`} </p>
       </div>
                    </div>
                        <h1>{item.fullName}</h1>
                        <p id="provider_address"> {item.state},{item.city},{item.country}</p>
                        {/* <p>{item.postal_code}</p> */}
                        
                         <span className="user_list_list">
                         <div className="multiple_stars_content">
                     <ul>
                       <li>
                       {item.star? Array.from({ length: maxRating }).map((_, index) => (
                            <FontAwesomeIcon
                              key={index}
                              icon={faStar}
                              className={
                                index < item.star
                                  ? "star_yellow"
                                  : "star"
                              }
                            />
                          )) :       Array.from({ length: maxRating }).map((_, index) => (
                            <FontAwesomeIcon
                              key={index}
                              icon={faStar}
                              className={
                                index < 0
                                  ? "star_yellow"
                                  : "star"
                              }
                            />
                          ))} 
              
                       </li>
                           </ul>
                    </div>
                    <p> {item.star?  item.star : ""}</p>
                         </span>
                         {userData?
              <button onClick={handleShow}>
                <img
                  src={process.env.PUBLIC_URL + "/images/chat.png"}
                  alt="app"
                />
                <p>Chat</p>
              </button>
              :""}
                    </div>
                </li>
           
            </ul>
            {/* </Link> */}
            </div>
))}

           </div>

   
        </div>

</div>
        </div>
  
 
    
      </div>
    </div>
</div>
<div className="most_popular_services h-most-Popular-service mt-4">
    <div className="container">
        <div className="row">
            <div className="col-md-8"><h4> Our <span>  Most Popular </span> Services</h4></div>
          
            <div className="col-md-4">
            <div class="button-container services_btn" style={{marginLeft:"44%"}}>
            <Link to='/services'>
            <button class="button button1">View More Services</button>
  <button class="button button2">View More Services</button>
            </Link>

</div>
            </div>
        </div>
    </div>
<div className="mt-4" style={{paddingLeft:"7%"}} >
<ServiceImage popularservice={popularservice} />

</div>
</div>


<section className="my-0">
    <div className="download_aap">
        <div className="container">
            <div className="row mb_view_center">
                <div className="col-md-3 col-lg-3">
                <img src={process.env.PUBLIC_URL + "/app_img.png"} alt="app"  className="app_img_contact " />
                 
                </div>
                <div className="col-md-12 col-lg-5 pt-4">
                    <h1>Download the app</h1>
                    {pageData?.pageheadings?.length > 0 && (
                      <p>{pageData?.pageheadings[0].heading[1]?.description}</p>
                    )}
                </div>
                <div className="col-md-12 col-lg-4">
                   <ul>
                   <li> <img src={process.env.PUBLIC_URL + "/images/google_pay.png"} alt="app"  className="app_img"  /> </li>
                    <li> <img src={process.env.PUBLIC_URL + "/images/btns_apple.png"} alt="app"  className="app_img" /> </li>
                    
                 
                   </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<Footer />

<Modal show={show} onHide={handleClose}  className="chat_user_boxses slide-up" id="chatbox">
    <div className="model_header">
        <div className="header_box">
        <img src={process.env.PUBLIC_URL + "../images/user_chat.png"} alt="app"  className="app_img" />
           <p>Benjamin</p>
        </div>
         <div className="">
         <img src={process.env.PUBLIC_URL + "../images/model_cross.png"} alt="app" className="cross_icon" onClick={handleClose} />
         </div>
        </div>
        
        <Modal.Body className="py-0" id="#style-4">
          <Form>
            <div className="row mx-0">
               
                <div className="col-md-12 px-0">
                    <div className="client_box">
                        <p>It is a long established fact that a read will be distracted by the read able of a page when looking at its layout.</p>
                    </div>
                    <img src={process.env.PUBLIC_URL + "../images/chat_box.png"}  className="chat_box_img"/>
                    <div className="chat_boxex">
                       
                    <div className="user_box">
                        <p>It is a long established fact that a read will be distracted by the read able of a page when looking at its layout.</p>
                    </div>
                    </div>
                </div>
                
            </div>
           
          </Form>
        </Modal.Body>
        <Modal.Footer >
            <div className="chat_model_footer">
            <button>+</button>
            <input type="text" className="user_enter_name" placeholder="Write Your Message here..."/>
            </div>
       
        </Modal.Footer>
      </Modal>
</>
);
};

export default Providerlist;
