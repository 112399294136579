import React, { useState, useRef, useEffect } from "react";
// import "./userRegister.css";
import { Container } from "react-bootstrap";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { vendorRegistrationSchema } from "../../components/validators/vendorFormValidator";
import {
  vendorRegistraionAsComapnyUrl,
  apiBaseUrl,
} from "../../config/constant";
import axios from "axios";
import { setSession ,setSessionForLoginCompany } from "../../utils/helper";
import { useLocation } from 'react-router-dom';
import { getCountry,getSate,getCity } from "../../components/common";
import './createCompanyProfile'
import { isNumeric,isEmail,getGeoLocation,getBanks,verifyBVN } from "../../components/common";

const CreateCompanyProfile = () => {
const location = useLocation();
const temp=location.state;
const searchParams = new URLSearchParams(location.search);
const receivedData = searchParams.get('data');
const imageInputRef = useRef(null);
const imageInputRef2 = useRef(null);
const [isSectionVisible, setSectionVisible] = useState(false);
const [checkBlur, setBlur] = useState(false);
const [checkBlur2, setBlur2] = useState(false);
const [show, setShow] = useState(false);
const [errors, setErrors] = useState("");
const [success, setSuccess] = useState("");
const [country, setCountry] = useState([]);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
const [ state, setState ]=useState([]);
const [city, setCity]=useState([]);
const [vcDetails, setVCDetials]=useState(false);
const [geoLocData, setGeoLocData] =useState([]);
const [banks, setBanks] =useState([]);

///////////////////////////// input field value get ////////////////////////////
  const [formData, setFormData] = useState({
    fullName: "",
    email: typeof receivedData === 'string' && !isNaN(receivedData) && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(receivedData) ? receivedData:'' || temp?.email,
    phone: typeof receivedData === 'number' && !isNaN(receivedData) ? receivedData : '',
    country: "",
    state: "",
    city: "",
    address1:"",
    address2: "",
    land_mark: "",
    postal_code: "",
    userType: "Company",
    companyRegNo: "",
    tinNumber: "",
    bankName: "",
    bankCode: "",
    bankAcountNumber: "",
    latitude: "",
    longitude: "",
    platform: "Web",
    companyName: "",
    companyPhone:  typeof receivedData === 'number' && !isNaN(receivedData) ? receivedData : '',
    documentType: "",
    documentNo: "",
    ownerBVN: "",
    gender: "",
    profilePic: null,
    certificate: null,
    isEmailAvailable : temp?temp?.isEmailAvailable:null ,
    isPhoneAvailable : null,
    isMobileVerified : null,
    isEmailVerified : null
  });


  const fetchData = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl+"provider/provider-register",
        formData,
        {
          headers: {
            key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
//CHeck Duplicate Email
  const checkDuplicateEmail = async (email) => {
    try {

      const response = await axios.post(
        apiBaseUrl+"provider/check-provider-email",
        {email : email},
        {
          headers: {
            key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed"
          },
        }
      );
      if(response?.data?.responseCode === 200 || response?.data?.responseCode === '200'){
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "", 
          isEmailAvailable : ""
        }));
  
        setSuccess((prevSuccess) => ({
          ...prevSuccess,
          email: "Available", 
        }));

        setFormData((prevFormData) => ({
          ...prevFormData,
          isEmailAvailable: 1,
          email : email
        }));
      }
      return true;
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: error?.response?.data?.statusMessage, 
        isEmailAvailable : ""
      }));
      setSuccess((prevSuccess) => ({
        ...prevSuccess,
        email: "", 
      }));
      setFormData((prevFormData) => ({
        ...prevFormData,
        isEmailAvailable: 0,
        email : email
      }));
      return false;

    }
  }  //End of function

  //CHeck Duplicate Email
  const checkDuplicatePhone = async (phone) => {
    try {

      const response = await axios.post(
        apiBaseUrl+"provider/check-provider-phone",
        {phone : phone},
        {
          headers: {
            key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed"
          },
        }
      );
      if(response?.data?.responseCode === 200 || response?.data?.responseCode === '200'){
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: "", 
          isPhoneAvailable : ""
        }));
  
        setSuccess((prevSuccess) => ({
          ...prevSuccess,
          phone: "Available", 
        }));

        setFormData((prevFormData) => ({
          ...prevFormData,
          isPhoneAvailable: 1,
        }));
      }
      return true;
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyPhone: error?.response?.data?.statusMessage, 
        isPhoneAvailable : ""
      }));
      setSuccess((prevSuccess) => ({
        ...prevSuccess,
        phone: "", 
      }));
      setFormData((prevFormData) => ({
        ...prevFormData,
        isPhoneAvailable: 0,
      }));
      return false;
    }
  }  //End of function

  const selectgender = async(value)=>{
    setFormData((prevFormData) => ({
      ...prevFormData,
      gender:value
    }));
  }

  const handleChange = async(e) => {
    const { name, value } = e.target;
    if(name === 'country'){
      const jsonObject = JSON.parse(value);
      const country= await  getSate(jsonObject.id);
      setState(country)
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: jsonObject.name,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name] : ""
      }));
    } else if(name === 'state'){
      const jsonObject = JSON.parse(value);
      const state= await  getCity(jsonObject.id);
      setCity(state)
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: jsonObject.name,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name] : ""
      }));
    } else if(name === 'city'){
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name] : ""
      }));
      const jsonObject = JSON.parse(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: jsonObject.name,
      }));
    } else if(name === 'email'){
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      const checkEmail = String(value).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      if(checkEmail){
        await checkDuplicateEmail(value);
      } else{
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear the error message for the corresponding field
        }));
      }
    } else if(name === 'companyPhone'){
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        phone:value
      }));
      const checkPhone = value.length > 9;
      if(checkPhone){
        await checkDuplicatePhone(value);
      } else{
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear the error message for the corresponding field
        }));
      }
    } else if (name ==='bankName'){
      const jsonObject = JSON.parse(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: jsonObject.name,
        bankCode : jsonObject.code
      }));

      if(value.length > 8){
        // const isBVNVerify = await verifyBVN('0551234987','MTN');
        // console.log(isBVNVerify);
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name] : ""
      }));
    } else if(name === 'ownerBVN'){
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      if(formData.bankCode && value.length > 8){
        // const isBVNVerify = await verifyBVN('0551234987','MTN');
        // const isBVNVerify = await verifyBVN(value,formData.bankCode);
        console.log(isBVNVerify);
      }
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name] : ""
      }));
    } else{
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name] : ""
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    vendorRegistrationSchema
    .validate(formData, { abortEarly: false })
    .then(() => {
      // setBlur(true);
      if(checkBlur){
        fetchData()
        .then((data) => {
          if(data.status === true){
          const token=data.response.result.token;  
          setSessionForLoginCompany('vendor',data.response.result);
          if(token){
            setSession("token", token);
          } 
          handleShow()
          }
        })
        .catch((error) => {
          console.log(error)
        });
      }else{ setBlur(true) }
    })
   .catch((validationErrors) => {
      const newErrors = {};
      const validationFilds = [ 'fullName','companyName', 'email', 'companyPhone', 'isPhoneAvailable', 'gender','country', 'state', 'city', 'postal_code', 'land_mark' ]
      validationErrors.inner.forEach((error) => {
        if(!vcDetails){
          if(validationFilds.includes(error.path)){
            newErrors[error.path] = error.message;  
          }
        }
      });
      const isFullNameValid = !newErrors.companyName;
      const isPhoneValid = !newErrors.companyPhone;
      const isEmailAvailable = !newErrors.isEmailAvailable;
      const isPhoneAvailable = !newErrors.isPhoneAvailable;
      if (isFullNameValid && isPhoneValid && isEmailAvailable && isPhoneAvailable) {
        profileDetailsHandler();

      }else{
        if(newErrors.email){ newErrors.isEmailAvailable = ''; }
        if(newErrors.companyPhone){ newErrors.isPhoneAvailable =''; }
      }
      console.log('bankName',newErrors);
      setErrors(newErrors);
    });
  }; 
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const countries = await getCountry();
        setCountry(countries);
        const banks = await getBanks();
        setBanks(banks);
      } catch (error) {
        // Handle any errors here
        console.error('Error fetching data:', error);
      }
    };
    const fetchLocationData = async () => {
      return new Promise(async (resolve, reject) => {
        try {
          const locData = await getGeoLocation();
          setGeoLocData(locData)
          if(locData && locData.country){
            
          }
          setFormData((prevFormData) => ({
            ...prevFormData,
            address1: locData?.address,
            land_mark : locData?.address,
            postal_code : locData?.postalCode,
            country : locData?.country,
            state : locData?.state,
            city : locData?.city
          }));

          //Set State
          if(locData?.country){
            const matchingCountries = country.filter(country => country.name.includes(locData.country));
            if(matchingCountries && matchingCountries.length >0 ){
              const states = await  getSate(matchingCountries[0].id);
              setState(states);
              //End 
              //Set City
              const matchingState = states.filter(state => state.name.includes(locData.state));
              const cities= await  getCity(matchingState[0].id);
              setCity(cities);
              //End
            }
            resolve(locData);
          }else{
            resolve('');
          }
        } catch (error) {
          // Handle any errors here
          console.error('Error fetching location data:', error);
          reject(error);
        }
      });
    };
    
    fetchLocationData();
    fetchData();
    const checkEmail = String(receivedData).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    if(!checkEmail){
      checkDuplicatePhone(receivedData);
      setFormData((prevFormData) => ({
        ...prevFormData,
        isPhoneVerify: 1,
        isMobileVerified : 1,
        phone: receivedData,
        companyPhone : receivedData
      }));
    }else{
      checkDuplicateEmail(receivedData);
      setFormData((prevFormData) => ({
        ...prevFormData,
        isEmailVerify: 1,
        isEmailVerified:1,
        email:receivedData
      }));
    } 
  }, []);
  //////////////////////////////end ////////////////////////////////////////////////
  const profileDetailsHandler = () => {
    if (!isSectionVisible) {
      setVCDetials(true);
      setSectionVisible(true);
      // setBlur(false);
    }
    // else {
    //   setBlur(true);
    // }

    if (isSectionVisible && checkBlur) {
      // setBlur(true);
      if (checkBlur) {
        handleShow();
      }
    }
  };
  // profile Image Capture
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageSelect = (event) => {
    const file = event.target.files[0];

    setFormData((prevFormData) => ({
      ...prevFormData,
      certificate: file,
    }));
    setSelectedImage(file);
  };

  const handleCaptureButtonClick = () => {
    // Trigger the file input click event
    imageInputRef.current.click();
  };
  // end profile Image Capture

  //  CAC Capture
  const [selectedImage2, setSelectedImage2] = useState(null);
  const handleImageSelect2 = (event) => {
    const file = event.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      profile_pic: file,
    }));
    setSelectedImage2(file);
  };

  const handleCaptureButtonClick2 = () => {
    
    // Trigger the file input click event
    imageInputRef2.current.click();
  };

  const cancelSelectedImage = () => {
    setSelectedImage2(null)
  }
  // END CAC Capture
  return (
    <>
      <Header />
      <div className="create-profile">
        {/* Varification section */}
        <div className="form_book_appintment">
          <div className="d-flex justify-content-between" style={{ height: "70px" }} >
            <p className="fill_form">
              <span>Company Details </span> <br />
              Complete Your Company’s Profile Details.
            </p>
          </div>
          <div className="row">
            <div className="col">
              <div className={`form_group ${errors.fullName ? "VendorInputError" : ""}`} >
                <input type="text" onChange={handleChange} placeholder="Full Name" name="fullName" id="fullName" oninput="allow_alphabets(this)" />
              </div>
              {errors.fullName && (<div className="error">{errors.fullName}</div>)}
            </div>
            <div className="col">
              <div className={`form_group ${errors.companyName ? "VendorInputError" : ""}`} >
                <input type="text" onChange={handleChange} placeholder="Your Company's Name" name="companyName" id="name" oninput="allow_alphabets(this)" />
              </div>
              {errors.companyName && (<div className="error">{errors.companyName}</div>)}
            </div>

            <div className="col">
              <div className={`form_group ${errors.email ? "VendorInputError" : ""} ${success.email ? "VendorInputSuccess" : ""} ${isEmail(receivedData)?"form_group blur-effect":""}`} >
                <input type="text" onChange={handleChange} placeholder="Email Address" name="email" id="email" value={isEmail(receivedData)?receivedData:formData.email} />
              </div>
              {errors.email && <div className="error">{errors.email}</div>}
              {errors.isEmailAvailable && <div className="error">{errors.isEmailAvailable}</div>}
              {/* {success.email && <div className="success">{success.email}</div>} */}
            </div>
            
            <div className="col">
              {/* <div className={isNumeric(receivedData)?`form_group blur-effect:"" ${errors.companyPhone ? "VendorInputError" : ""}`:'form_group'} > */}
              <div className={`form_group ${errors.companyPhone ? "VendorInputError" : ""} ${errors.isPhoneAvailable ? "VendorInputError" : ""} ${success.phone ? "VendorInputSuccess" : ""} ${isNumeric(receivedData)?"form_group blur-effect":""}`} >
                <input type="text" onChange={handleChange} placeholder="Company Mobile Number" name="companyPhone" id="phone" onkeypress="return onlyNumberKey(event)" value={isNumeric(receivedData)?receivedData:formData.companyPhone} />
              </div>
              {errors.companyPhone && <div className="error">{errors.companyPhone}</div>}
              {errors.isPhoneAvailable && <div className="error">{errors.isPhoneAvailable}</div>}
              {/* {success.phone && <div className="success">{success.phone}</div>} */}
            </div>

            
          </div>

          <div className="row">
            <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
              <div className="form_group">
                <div className="custom-select-wrapper">
                  <select
                    className={`custom-select ${errors.city ? "VendorInputError" : ""}`} name="country" onChange={handleChange} >
                    <option>Select Country</option>
                    {country.map((item, index)=>(
                      <option selected={item.name === formData.country} key={index} value={JSON.stringify(item)}>{item.name}</option>
                    ))}
                  </select>
                  <img
                    src={process.env.PUBLIC_URL + "/images/user/selectArrow.png"}
                    alt="arrow"
                    className="arrow-image"
                  />
                </div>
                {errors.country && (
                  <div className="error">{errors.country}</div>
                )}
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
              <div className="form_group">
                <div className="custom-select-wrapper">
                  <select className={`custom-select  ${errors.city ? "VendorInputError" : ""}`} name="state" onChange={handleChange} >
                    <option>Select State</option>
                   {state.map((item, index)=> (           
                    <option selected={item.name === formData.state} key={index} value={JSON.stringify(item)}>{item.name}</option>
                   ))} 
                  </select>
                  <img src={process.env.PUBLIC_URL + "/images/user/selectArrow.png"} alt="arrow" className="arrow-image" />
                </div>
                {errors.state && <div className="error">{errors.state}</div>}
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
              <div className="form_group">
                <div className="custom-select-wrapper">
                  <select className={`custom-select ${errors.city ? "VendorInputError" : ""}`} name="city" onChange={handleChange} >
                    <option>Select City</option>
                    {city.map((item, index)=>(
                      <option selected={item.name === formData.city} key={index} value={JSON.stringify(item)}>{item.name}</option>
                    ))}
                  </select>
                  <img src={process.env.PUBLIC_URL + "/images/user/selectArrow.png"} alt="arrow" className="arrow-image" />
                </div>
                {errors.city && <div className="error">{errors.city}</div>}
              </div>
            </div>

            <div className="col">
              <div className={`form_group ${errors.land_mark ? "VendorInputError" : ""}`} >
                <input type="text" onChange={handleChange} placeholder="Landmark" name="land_mark" id="land_mark" oninput="allow_alphabets(this)" value={formData.land_mark} />
              </div>
              {errors.land_mark && ( <div className="error">{errors.land_mark}</div>)}
            </div>
            <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
              <div className={`form_group ${errors.postal_code ? "VendorInputError" : ""}`} >
                <input type="number" onChange={handleChange} placeholder="Postal Code" maxLength={6} name="postal_code" id="postal_code" onkeypress="return onlyNumberKey(event)" value={formData.postal_code} />
              </div>{errors.postal_code && (<div className="error">{errors.postal_code}</div>)}
            </div>

            <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
              <div className={`form_group ${errors.address1 ? "VendorInputError" : ""}`} >
                <input type="text" onChange={handleChange} placeholder="Address Line 1 " name="address1" id="address1" oninput="allow_alphabets(this)" value={formData.address1} />
              </div>
              {errors.address1 && (<div className="error">{errors.address1}</div>)}
            </div>

            <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
              <div
                className={`form_group ${errors.address2 ? "VendorInputError" : ""}`} >
                <input type="text" onChange={handleChange} placeholder="Address Line 2" name="address2" id="address2" oninput="allow_alphabets(this)" />
              </div>{errors.address2 && (<div className="error">{errors.address2}</div>)}
            </div>

          </div>
          <div className="row">
            <div className="col-md-12">
              <p className="fill_form mb-0">Select One Option</p>
            </div>
            <div className=" d-flex user_selection">
              <div class="user_name" onClick={()=>{selectgender('Male')}}>
                <input
                  type="radio"
                  name="gender"
                  value="Male"
                  checked={formData.gender === 'Male'}
                />
                <label>Male</label>
              </div>
              <div className="user_name" onClick={()=>{selectgender('Female')}}>
                <input
                  type="radio"
                  name="gender"
                  value="Female"
                  checked={formData.gender === 'Female'}
                />
                <label>Female</label>
              </div>
              <div className="user_name" onClick={()=>{selectgender('Other')}}>
                <input
                  type="radio"
                  name="gender"
                  value="Other"
                  checked={formData.gender === 'Other'}
                />
                <label>Other</label>
              </div>
            </div>
            {errors.gender && <div className="error">{errors.gender}</div>}
          </div>
        </div>

        {/* {isSectionVisible && (
          <>
            <div className="form_book_appintment mt-4">
              <div className="d-flex justify-content-between" style={{ height: "70px" }} >
                <p className="fill_form">
                  <span> Verification </span> <br />
                  Complete Your Company’s Profile Details.
                </p>
              </div>
              <div className={!checkBlur ? "" : "blur-effect"}>
                <div className="row gx-sm-0 gx-xs-0">
                  <div className="col-md-3">
                    <div className={`form_group ${errors.companyRegNo ? "VendorInputError" : ""}`} >
                      <input type="number" placeholder="Enter Comapany Registration No. " name="companyRegNo" id="companyRegNo" oninput="allow_alphabets(this)" onChange={handleChange} />
                    </div>
                    {errors.companyRegNo && (<div className="error">{errors.companyRegNo}</div>)}
                  </div>
                  <div className="col-md-3">
                    <div className={`form_group ${errors.tinNumber ? "VendorInputError" : ""}`} >
                      <input type="number" onChange={handleChange} placeholder="Enter Tax Identification No." name="tinNumber" id="tinNumber" oninput="allow_alphabets(this)" />
                    </div>
                    {errors.tinNumber && (<div className="error">{errors.tinNumber}</div>)}
                  </div>
                  <div className="col-md-3">

                    <div className="form_group">
                      <div className="custom-select-wrapper">
                        <select className={`custom-select  ${errors.bankName ? "VendorInputError" : ""}`} name="bankName" onChange={handleChange} >
                          <option>Select Bank</option>
                        {banks.map((item, index)=> (           
                          <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                        ))} 
                        </select>
                        <img src={process.env.PUBLIC_URL + "/images/user/selectArrow.png"} alt="arrow" className="arrow-image" />
                      </div>
                    </div>
                    {errors.bankName && <div className="error">{errors.bankName}</div>}

                  </div>
                  <div className="col-md-3">
                    <div className={`form_group ${errors.ownerBVN ? "VendorInputError" : ""}`} >
                      <input type="number" onChange={handleChange} placeholder="Enter Bank Verification No. ." name="ownerBVN" id="ownerBVN" oninput="allow_alphabets(this)" />
                    </div>
                    {errors.ownerBVN && (<div className="error">{errors.ownerBVN}</div>)}
                  </div>
                  <div className="col-md-3">
                    <div className={`form_group ${errors.certificate ? "VendorInputError" : ""}`} >
                      <div class="button-container">
                        <button className="button button1" onClick={handleCaptureButtonClick2} >
                          Upload CAC Certificate
                        </button>
                        <button className="button button2" onClick={handleCaptureButtonClick2} >
                          Upload CAC Certificate
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className={`form_group ${errors.certificate ? "VendorInputError" : ""}`} >
                    {selectedImage2 ? (
                      <div className="wallpaper-icon-ur d-flex justify-content-around " id="img-upload" >
                        <img src={process.env.PUBLIC_URL + "images/user/wallpaper.png"} alt="futerMob" className="walpaper-icon" />
                          <p> {selectedImage2.name.slice(0, 15) } </p>
                        <img src={process.env.PUBLIC_URL + "images/user/X-red.png"} alt="futerMob" className="walpaper-icon-cross-icon" onClick={cancelSelectedImage } style={{cursor:"pointer"}} />
                      </div>
                      ) : (<></>)}
                      <input type="file" accept="image/*" style={{ display: "none" }} onChange={handleImageSelect2} ref={imageInputRef2} />
                    </div>
                    {errors.certificate && (<div className="error">{errors.certificate}</div>)}
                  </div>
                </div>
                <div className={!checkBlur ? "" : "blur-effect"}></div>
              </div>
            </div>


            <div className="form_book_appintment" style={{marginTop:"30px"}}>
          <div
            className="d-flex justify-content-between"
            style={{ height: "70px" }}
          >
            <p className="fill_form">
              <span>Company Details </span> <br />
              Complete Your Company’s Profile Details.
            </p>
          </div>

          <div className="row gx-sm-0 gx-xs-0">
            <div className="col">
              <div
                className={`form_group ${
                  errors.fullName ? "VendorInputError" : ""
                }`}
              >
                <input
                  type="text"
                  onChange={handleChange}
                  placeholder="Enter Full Name(Owner's Name)"
                  name="fullName"
                  id="name"
                  oninput="allow_alphabets(this)"
                />
              </div>
              {errors.fullName && (
                <div className="error">{errors.fullName}</div>
              )}
            </div>
       
            <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
            <div
                className={`form_group ${errors.phone ? "VendorInputError" : ""}`}>
                <input
                  type="text"
                  onChange={handleChange}
                  placeholder="Mobile Number"
                  maxlength="10"
                  minlength="10"
                  name="phone"
                  id="phone"
                  onkeypress="return onlyNumberKey(event)"
                  value={isNumeric(receivedData)?receivedData:formData.phone}
                />
              </div>
              {errors.phone && <div className="error">{errors.phone}</div>}
            </div>
            <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
              <div className="form_group">
                <div className="custom-select-wrapper">
                  <select
                    className={`custom-select ${
                      errors.documentType ? "VendorInputError" : ""
                    }`}
                    name="documentType"
                    onChange={handleChange}
                  >
                    <option>Select NIN</option>
                    <option>National Identification Card</option>
                    <option>Passport</option>
                    <option>Driving Licence</option>
                  </select>
                  <img
                    src={process.env.PUBLIC_URL + "/images/user/selectArrow.png"}
                    alt="arrow"
                    className="arrow-image"
                  />
                </div>
                {errors.documentType && (
                  <div className="error">{errors.documentType}</div>
                )}
              </div>
            </div>
                  <div className="col">
                    <div
                      className={`form_group ${
                        errors.documentNo ? "VendorInputError" : ""
                      }`}
                    >
                      <input
                        type="text"
                        onChange={handleChange}
                        placeholder="Enter Identification No."
                        name="documentNo"
                        id="documentNo"
                        oninput="allow_alphabets(this)"
                      />
                    </div>
                    {errors.documentNo && (
                      <div className="error">{errors.documentNo}</div>
                    )}
                  </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <p className="fill_form mb-0">Select One Option</p>
            </div>
            <div className=" d-flex user_selection">
              <div class="user_name">
                <input
                  type="radio"
                  name="gender"
                  value="Male"
                  // checked={gender === "male"}
                  onChange={handleChange}
                />
                <label>Male</label>
              </div>
              <div className="user_name">
                <input
                  type="radio"
                  name="gender"
                  value="Female"
                  // checked={gender === "female"}
                  onChange={handleChange}
                />
                <label>Female</label>
              </div>
            </div>
            {errors.gender && <div className="error">{errors.gender}</div>}
          </div>
        </div>
          </>
       
       
       
        )} */}

        {/* end varification section */}

        {checkBlur && (
          <div
            class="button-container mt-4 mb-4"
            onClick={()=>{setBlur(false)}}
            id="Edit_registration_btn"
          >
            <button class="button button1" id="btn-otp-resend">
              Edit Details
            </button>
            <button class="button button2">Edit Details</button>
          </div>
        )}
        <div
          className="button-container mt-4 mb-4 mb_btn_view" 
          style={{ marginRight: "0" }}

        >
          <button className="button button1"> Proceed To Next</button>
          <button
            className="button button2"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            {" "}
            Proceed To Next
          </button>
        </div>
      </div>
      <section className="mb-0">
        <div className="download_aap">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <img
                  src={process.env.PUBLIC_URL + "./app_img.png"}
                  alt="app"
                  className="app_img_contact"
                />
              </div>
              <div className="col-md-6 pt-4">
                <h1>Download the app</h1>
                <p>
                  Get the app. Get things done. Compare prices, read reviews and
                  book top-rated services. All in one free app.
                </p>
              </div>
              <div className="col-md-3">
                <ul>
                  <li>
                    {" "}
                    <img
                      src={process.env.PUBLIC_URL + "./androied.png"}
                      alt="app"
                      className="app_img"
                    />{" "}
                  </li>
                  <li>
                    {" "}
                    <img
                      src={process.env.PUBLIC_URL + "./ios.png"}
                      alt="app"
                      className="app_img"
                    />{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      <Modal
        show={show}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "images/user/X.png"}
              alt="playstore"
            />
          </div>
          <img
            src={process.env.PUBLIC_URL + "images/user/cong.gif"}
            alt="playstore"
            className="cong-done-json"
            style={{ width: "150px" }}
          />

          <div className="congrats-profile-created">
            <h6>
              Congratulations
              <br />
              <span> Profile successfully Done</span>
            </h6>
            <p>You are now ready for using the Doqwik app services.</p>
            <div class="button-container" style={{ width: "200px" }}>
              <button className="button button1">Proceed To Next</button>
              <button className="button button2">
                <Link
                  to="/vendor/my-profile"
                  style={{ textDecoration: "none", color: "#ffffff" }}
                >
                  {" "}
                  Proceed To Next{" "}
                </Link>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateCompanyProfile;
