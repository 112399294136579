import Header from "../../components/header";
import Footer from "../../components/footer";
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect ,useRef} from "react";
import Form from 'react-bootstrap/Form';
import LeftnavbarIndividual from "../../components/leftnavbaraIndividual";
import { getSessionForIndividual } from "../../utils/helper";
import {
    getDatabase,
    ref,
    onValue,
    set
  } from "firebase/database";
  import { firebaseWeb } from "../../firebase";
  import { BaseUrl } from "../../config/constant";
  import { SendUserInfo ,RecieveUserInfo ,SendMessage ,ReceiveMessage} from "../../components/FirebaseChat.js/ChatList";
import { ChatWithSelectedUser } from "../../components/FirebaseChat.js/ChatBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck} from "@fortawesome/fontawesome-free-solid";

const Individualchat = ()=>{
    const  vendor  = getSessionForIndividual('individual');
    let currentDate = null; 
    const chatContainerRef = useRef(null);
    const fileInputRef = useRef(null);
    const [imageSrc, setImageSrc] = useState("");
    const [message, setMessage] = useState("");
    const [show, setShow] = useState(false);
    const [selectUserToChat,setSelectUserToChat]=useState()
    const handleClose = () => setShow(false);
    const [chatData, setChatData] = useState([]);
    const [chatList,setChatList]=useState([])
    const database = getDatabase(firebaseWeb);
    useEffect(() => {
        try {
          const userId = vendor?._id; // Replace with your user ID
          const dbRef = ref(database, `users/${userId}`);
          const unsubscribe = onValue(dbRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
              // Data exists, process it
              let messages = Object.entries(data).map(([key, messageData]) => {
                return {
                  sender: messageData.sender,
                  receiver: messageData.receiver,
                  message: messageData.message,
                  image: messageData.image,
                  createdAt: messageData.createdAt,
                  userInfo: JSON.parse(messageData.userInfo), 
                };
              });
    
              // Sort messages by createdAt in descending order
              messages.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
    
              setChatList(messages);
            } else {
              // Data doesn't exist or is empty, handle accordingly
              setChatList([]);
            }
          });
    
          // Clean up the listener when the component unmounts
          return () => {
            unsubscribe();
          };
        } catch (error) {
          console.error(error);
          // Handle the error using your Alert component or other error-handling method
        }
      }, []);

      const handleShow = async (item) => {
        try {
          setSelectUserToChat(item)
          const chatMessages = await ChatWithSelectedUser(vendor, item);
          setChatData(chatMessages);
          setShow(true);
        } catch (error) {
          console.error('Error fetching chat messages:', error);
          // Handle the error (e.g., display an error message)
        }
      };

      const handleButtonClick = () => {
        // Trigger the file input dialog
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      };
    
      const handleFileChange = (e) => {
        const file = e.target.files[0];
    
        if (file) {
          const reader = new FileReader();
    
          reader.onload = (e) => {
            const base64Image = e.target.result;
            setImageSrc(base64Image);
          };
    
          reader.readAsDataURL(file);
        }
      };
      const handleCloseImg=()=>{
        setImageSrc("")
      }
      

      const SendMessage1 = async (event) => {
        event.preventDefault();
      
        if (!message.trim() && !imageSrc) {
          alert("Enter a valid message or select an image");
          return;
        }
        const createdAt = new Date().getTime();
        const data=await SendMessage(vendor._id,selectUserToChat,message,imageSrc,createdAt);
        SendUserInfo(vendor._id,selectUserToChat,message,imageSrc,selectUserToChat,createdAt);
        RecieveUserInfo(vendor?._id,selectUserToChat,message,imageSrc,vendor,createdAt);
        ReceiveMessage(vendor?._id,selectUserToChat,message,imageSrc,createdAt)
        const chatMessages = await ChatWithSelectedUser(vendor, selectUserToChat);
        setChatData(chatMessages);
        setMessage('')
      };
    // end
    return(
        <>
        <Header />
        <div className="dashboard">
    <div className="container">
        <div className="row mx-0">
        <LeftnavbarIndividual></LeftnavbarIndividual>
            <div className="col-md-9">
             <div className="vendors_right_formses">
             <div className="row mx-0">
                    <div className="col-md-12">
                        
                        <h1 className="my_order mt-2">Chats </h1>
                        <h2 className="order_details">Chat with customers</h2>
                       
                    </div>
                </div>
             <div className="row chat_box_content mt-2 mx-0">
             {chatList.map((chats, index) => (
                    <div className="col-md-6 " key={index}>
                      <div className="row mx-0 chat_box chatList" onClick={()=>{handleShow(chats.userInfo)}}>
                        <div className="col-4 ">
                        {chats?.userInfo?.profile_pic?   <img
                            src={BaseUrl + chats?.userInfo?.profile_pic}
                            alt="app"
                            className="worklist"
                            style={{maxHeight:"100px"}}
                          /> : ""}
                        
                        </div>
                        <div className="col-8">
                          <h1>{chats.userInfo.name||chats.userInfo.fullName}</h1>
                          <p>{chats.userInfo.address || chats.userInfo.address1}</p>
                          <div className="chat_message">{chats.image?<img src={chats.image} alt="image" className="chatsImg"/>: chats.message}</div>
                        </div>
                      </div>
                    </div>
                  ))}
              
               
             </div>
         
             </div>
            </div>
        </div>
    </div>
   </div>
   <Modal
        show={show}
        onHide={handleClose}
        className="chat_user_boxses slide-up"
        id="chatbox"
      >
        <div className="model_header">
          <div className="header_box">
            <img
              src={BaseUrl+selectUserToChat?.profile_pic}
              alt="app"
              className="app_img"
              id="chatpro"
            />
            <p>{selectUserToChat?.name|| selectUserToChat?.fullName}</p>
          </div>
          <div className="">
            <img
              src={process.env.PUBLIC_URL + "/images/model_cross.png"}
              alt="app"
              className="cross_icon"
              onClick={handleClose}
            />
          </div>
        </div>

        <Modal.Body className="py-0" id="#style-4">
          <Form className="chatBox_height" ref={chatContainerRef}>
            <div className="row mx-0">
              <div className="col-md-12 px-0">
              {chatData.slice().reverse().map((message, index, array) => {
  const isSender = message.sender === array[index - 1]?.sender;
  const isReceiver = message.receiver === array[index - 1]?.receiver;
  
  // Format the timestamp to get the date (e.g., "02/09/20")
  const messageDate = new Date(message.createdAt).toLocaleDateString(undefined, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });

  // Check if the current date is different from the previous message's date
  const shouldDisplayDate = messageDate !== currentDate;
  
  // Update the current date to the new message's date
  currentDate = messageDate;

  return (
    <div key={index} className="col-md-12 px-0">
      {shouldDisplayDate && <div className="date_on_chat">{messageDate==="Invalid Date"? '' : messageDate}</div>}
      {message.sender === vendor._id ? (
        <div className="chat_boxex">
          <div className="user_box">
          <p className="timeStampBlock">
  {message.message ? (
    <>
      {message.message}
      <span className="timestamp">
      {new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
      <FontAwesomeIcon icon={faCheck}  className="fa_check"/>
    
      </span>
    </>
  ) : message.image ? (
    <>
    <img src={message.image} alt='sender_image' style={{ width: "100%" }} />
<span className="timestamp" id="timeStamp_receiver">
    {new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })} 
    {/* <img src={process.env.PUBLIC_URL+"/images/icon/check.png"} alt="check"/> */}
    {/* <FontAwesomeIcon icon="fa-regular fa-check" /> */}
    <FontAwesomeIcon icon={faCheck}  className="fa_check"/>
    </span>
</>
  ) : (
    ""
  )}
</p>
          </div>
        </div>
      ) : (
        
        <div className="client_box">
          
          {message.image ? (
            <>
                <img src={message.image} alt='receiver_image' style={{ width: "100%" }} />
            <span className="timestamp" id="timeStamp_receiver">
                {new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
         
                </span>
             
            </>
        
          ) : (
            <p className="timeStampBlock">
            {message.message ? (
              <>
                {message.message}
                <span className="timestamp" id="timeStamp_receiver">
                {new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
                </span>
              </>
            ) : message.image ? (
              <img src={message.image} alt="sender_image" />
            ) : (
              ""
            )}
          </p>
          )}
        </div>
      )}
    </div>
  );
})}

                {/* <div className="client_box sender">
                        <p>It is a long established fact that a read will be distracted by the read able of a page when looking at its layout.</p>
                    </div>
                    <img src={process.env.PUBLIC_URL + "/images/chat_box.png"}  className="chat_box_img"/>
                    <div className="chat_boxex receiver">
                       
                    <div className="user_box">
                        <p>It is a long established fact that a read will be distracted by the read able of a page when looking at its layout.</p>
                    </div>
                    </div> */}
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="chat_model_footer">
            <button onClick={handleButtonClick}>+</button>

            {imageSrc ? (
              <div className="image_chat_box">
                {imageSrc && (
                  <img
                    src={imageSrc}
                    alt="Preview"
                    style={{ maxWidth: "100%", maxHeight: "300px" }}
                  />
                )}
                <span className="closeImgChat" onClick={handleCloseImg}>✖</span>
                <button className="sendImg" onClick={SendMessage1}>send</button>
              </div>
            ) : (
              <input
                type="text"
                className="user_enter_name"
                placeholder="Write Your Message here..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    SendMessage1(e);
                  }
                }}
              />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
          </div>
        </Modal.Footer>
      </Modal>
        <Footer />
        </>
    );
};

export default Individualchat;
