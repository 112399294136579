import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";

import axios from "axios";
import "react-circular-progressbar/dist/styles.css";
import { validationSchemaForDeleteRequest } from "../../components/validators/vendorFormValidator";
import Modal from "react-bootstrap/Modal";
import { apiBaseUrl,key } from "../../config/constant";


const DeleteRequest = () => {
  const [show, setShow] = useState(false);
  
  const [checkBlur, setBlur] = useState(false);
  const [errors, setErrors] = useState("");

  const [show2, setShow2] = useState(false);
  
  const handleClose = () => {
    setShow(false);
    setBlur(true);
  };
  
  const [formData, setFormData] = useState({
    phone : "",
    email : "",
    reason : ""
  });
 
  const handleChange = async (e) => {
    setErrors("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkBlur) {
      setBlur(false);
      return;
    } else {
      validationSchemaForDeleteRequest
        .validate(formData, { abortEarly: false })
        .then(() => {
          setBlur(true);
          setShow2(true);
        })
        .catch((validationErrors) => {
          const newErrors = {};
          validationErrors.inner.forEach((error) => {
            newErrors[error.path] = error.message;
          });
          setErrors(newErrors);
        });
    }
  };
  
  const handleDeleteAccount = async () => {
    try {
      setShow2(false);
      const response = await axios.post(
        apiBaseUrl + "common/delete-request-without-login",
        formData,
        {
          headers: {
            key: key
          },
        }
      );
      if (response.data.status === true) {
        setShow(true);
        setFormData({
          phone : "",
          email : "",
          reason : " "
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <>
      <Header />
      <div className="dashboard" style={{ marginTop: "105px" }}>
        <div className="container">
          <div className="mb_view_center">
            <div className="col-md-12">
              <div className="form_book_appintment " style={{marginBottom: "20px"}}>
                <div >
                  <div className="row">
                    <div className="col-md-8 col-9 pr-0">
                      <p className="fill_form mb-0"> <span>Delete Request Form : </span> </p>
                    </div>
                  </div>
                </div>
                
                <div className="row mt-12 mb_margin ">

                  <div className="col-md-12">
                    <div className={ !checkBlur ? "form_group" : "blur-effect form_group" } >
                      <p className="fill_form mb-0"><span style={{color :"red"}}>*</span> <span>Phone Number : </span> </p>
                      <input type="number" placeholder="Enter phone number" name="phone" autocomplete="off" id="phone" value={formData.phone} onChange={handleChange} />
                    </div>
                    {errors.phone && (<div className="error">{errors.phone}</div>)}
                  </div>

                  <div className="col-md-12">
                    <div className={ !checkBlur ? "form_group" : "blur-effect form_group" } >
                      <p className="fill_form mb-0"> <span style={{color :"red"}}>*</span> <span>Email Number : </span> </p>
                      <input type="text" placeholder="Enter email address" name="email" autocomplete="off" id="email" value={formData.email} onChange={handleChange} />
                    </div>
                    {errors.email && (<div className="error">{errors.email}</div>)}
                  </div>
                </div>

                <div className="row mt-12 mb_margin ">
                  <div className="col-md-12">
                    <div className={ !checkBlur ? "form_group" : "blur-effect form_group" } >
                      <p className="fill_form mb-0"> <span>Reason : </span> </p>
                      <textarea name="reason" id="reason" placeholder="Please describe reason" onChange={handleChange}>{formData.reason}</textarea>
                    </div>
                  </div>
                </div>

                <div className="row mt-12 mb_margin ">
                  <div className="image-containe">
                    <div class="button-container">
                      <button className="button button1" onClick={handleSubmit}>   {checkBlur? <> Send Request</> : <>Send Request</> }</button>
                      <button className="button button2" onClick={handleSubmit}>   {checkBlur? <> Send Request</> : <>Send Request</> }</button>
                    </div>
                  </div>
                </div> 

                <div className="moile_view">
                  <div
                    class="button-container"
                    style={{ width: "186px", marginTop: "-40px" }}
                  >
                    <button className="button button1" onClick={handleSubmit}>
                      {" "}
                      {checkBlur ? <> Send Request</> : <>Send Request</>}
                    </button>
                    <button className="button button2" onClick={handleSubmit}>
                      {" "}
                      {checkBlur ? <> Send Request</> : <>Send Request</>}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    {/* Request Sent Success Popup */}
      <Modal
        show={show}
        onHide={()=>{
          handleClose();
          setShow2(false);
          setBlur(false);
          setFormData({
            phone : "",
            email : "",
            reason : ""
          });}
        }
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created  well_done"
            style={{ marginTop: "0px" }}
          >
            <img
              src={process.env.PUBLIC_URL + "../images/greeting.gif"}
              alt="playstore"
              className="gif"
            />

            <h2>Resuest Sent Sucessfully!</h2>
            {/* <p>Please Continue Your Work</p> </>:<h2>Job Rejected!</h2>  */}

            <div
              class="button-container"
              style={{ width: "150px" }}
              onClick={()=>{
                handleClose();
                setShow2(false);
                setBlur(false);
                setFormData({
                  phone : "",
                  email : "",
                  reason : ""
                });}
              }
            >
              <button className="button button1"> Proceed To Next</button>
              <button className="button button2">Proceed To Next</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* End of Popup */}

      <Modal
        show={show2}
        onHide={() => {
          setShow2(false);
          setFormData({
            phone : "",
            email : "",
            reason : ""
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h6>Are you sure you want to delete this profile? </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={process.env.PUBLIC_URL + "/images/delete_icon.png"}
            className="delete_images"
          ></img>
        </Modal.Body>
        <Modal.Footer>
          <div className="proceed_next mt-0 mb-2">
            <div
              class="button-container edit_proceed mt-4 mb-4"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setShow2(false);
                setBlur(false);
              }}
            >
              <button class="button button1" id="btn-otp-resend">
                No
              </button>
              <button class="button button2">No</button>
            </div>

            <div class="button-container proccess_next mt-4 mb-4 mr-0">
              <button class="button button1" onClick={handleDeleteAccount}>
                {" "}
                Yes{" "}
              </button>
              <button class="button button2" onClick={handleDeleteAccount}>
                {" "}
                Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteRequest;
