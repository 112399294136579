import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import DashboardAsWorker from "../../components/DashboardAsWorker";

const WorkerChat = () =>{
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    return(
        <>
     <Header />
     <div className="dashboard">
    <div className="container">
        <div className="row">
     <DashboardAsWorker/>
            <div className="col-md-9">
          
             <div className="right_side">
             <div className="row">
                    <div className="col-md-12">
                        <h1 className="my_order">Chats</h1>

                        <h2 className="order_details">Chat with providers</h2>
                    </div>
                </div>
             
                <div className="row mx-0 mt-3 mb-2">
                    <div className="col-md-6">
                    <div className="address_details my-2" >
                        <div className="select_provider">
                        <div><img src={process.env.PUBLIC_URL + "../images/worker.png"} alt="app"  className="worker_img"/> </div>
                            <div className="user_deatils_address">
                                <h1>Benjamin</h1>  
                                <p>Gwarinpa-900108, Nigeria</p>
                                <button className="plumber_btn" onClick={handleShow}>Plumber </button>
                                </div>
                          <div>
                            <span><img src={process.env.PUBLIC_URL + "../images/Star.png"} className="star_img" /><p>4.6</p></span>
                          </div>
                        </div>
                     
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="address_details my-2">
                        <div className="select_provider ">
                        <div><img src={process.env.PUBLIC_URL + "../images/worker.png"} alt="app"  className="worker_img"/> </div>
                        <div className="user_deatils_address">
                                <h1>Benjamin</h1>  
                                <p>Gwarinpa-900108, Nigeria</p>
                                <button className="plumber_btn" onClick={handleShow}>Plumber </button>
                                </div>
                          
                          <div>
                            <span><img src={process.env.PUBLIC_URL + "../images/Star.png"} className="star_img" /><p>4.6</p></span>
                          </div>
                        </div>
                       
                    </div>
                    </div>

                    <div className="col-md-6">
                    <div className="address_details my-2">
                        <div className="select_provider">
                        <div><img src={process.env.PUBLIC_URL + "../images/worker.png"} alt="app"  className="worker_img"/> </div>
                        <div className="user_deatils_address">
                                <h1>Benjamin</h1>  
                                <p>Gwarinpa-900108, Nigeria</p>
                                <button className="plumber_btn" onClick={handleShow}>Plumber </button>
                                </div>
                          
                          <div>
                            <span><img src={process.env.PUBLIC_URL + "../images/Star.png"} className="star_img" /><p>4.6</p></span>
                          </div>
                        </div>
                       
                    </div>
                    </div>

                    <div className="col-md-6">
                    <div className="address_details my-2">
                        <div className="select_provider">
                        <div><img src={process.env.PUBLIC_URL + "../images/worker.png"} alt="app"  className="worker_img"/> </div>
                        <div className="user_deatils_address">
                                <h1>Benjamin</h1>  
                                <p>Gwarinpa-900108, Nigeria</p>
                                <button className="plumber_btn" onClick={handleShow}>Plumber </button>
                                </div>
                          
                          <div>
                            <span><img src={process.env.PUBLIC_URL + "../images/Star.png"} className="star_img" /><p>4.6</p></span>
                          </div>
                        </div>
                       
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="address_details my-2">
                        <div className="select_provider">
                        <div><img src={process.env.PUBLIC_URL + "../images/worker.png"} alt="app"  className="worker_img"/> </div>
                        <div className="user_deatils_address">
                                <h1>Benjamin</h1>  
                                <p>Gwarinpa-900108, Nigeria</p>
                                <button className="plumber_btn" onClick={handleShow}>Plumber </button>
                                </div>
                          
                          <div>
                            <span><img src={process.env.PUBLIC_URL + "../images/Star.png"} className="star_img" /><p>4.6</p></span>
                          </div>
                        </div>
                       
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="address_details my-2">
                        <div className="select_provider">
                        <div><img src={process.env.PUBLIC_URL + "../images/worker.png"} alt="app"  className="worker_img"/> </div>
                        <div className="user_deatils_address">
                                <h1>Benjamin</h1>  
                                <p>Gwarinpa-900108, Nigeria</p>
                                <button className="plumber_btn" onClick={handleShow}>Plumber </button>
                                </div>
                          
                          <div>
                            <span><img src={process.env.PUBLIC_URL + "../images/Star.png"} className="star_img" /><p>4.6</p></span>
                          </div>
                        </div>
                       
                    </div>
                    </div>
                 
                </div>
              
                
             </div>
            </div>
        </div>
    </div>
   </div>

   
   <section className="mb-0">
    <div className="download_aap">
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                <img src={process.env.PUBLIC_URL + "../app_img.png"} alt="app"  className="app_img" />
                 
                </div>
                <div className="col-md-6 pt-4">
                    <h1>Download the app</h1>
                    <p>Get the app. Get things done. Compare prices, read reviews and book top-rated services. All in one free app.</p>
                </div>
                <div className="col-md-3">
                   <ul>
                    <li> <img src={process.env.PUBLIC_URL + "../androied.png"} alt="app"  className="app_img" /> </li>
                    <li> <img src={process.env.PUBLIC_URL + "../ios.png"} alt="app"  className="app_img" /> </li>
                    
                 
                   </ul>
                </div>
            </div>
        </div>
    </div>
</section>
     <Footer/>
     <Modal show={show} onHide={handleClose}  className="chat_user_boxses slide-up" id="chatbox">
    <div className="model_header">
        <div className="header_box">
        <img src={process.env.PUBLIC_URL + "../images/user_chat.png"} alt="app"  className="app_img" />
           <p>Benjamin</p>
        </div>
         <div className="">
         <img src={process.env.PUBLIC_URL + "../images/model_cross.png"} alt="app" className="cross_icon" onClick={handleClose} />
         </div>
        </div>
        
        <Modal.Body className="py-0" id="#style-4">
          <Form>
            <div className="row mx-0">
               
                <div className="col-md-12 px-0">
                    <div className="client_box">
                        <p>It is a long established fact that a read will be distracted by the read able of a page when looking at its layout.</p>
                    </div>
                    <img src={process.env.PUBLIC_URL + "../images/chat_box.png"}  className="chat_box_img"/>
                    <div className="chat_boxex">
                       
                    <div className="user_box">
                        <p>It is a long established fact that a read will be distracted by the read able of a page when looking at its layout.</p>
                    </div>
                    </div>
                </div>
                
            </div>
           
          </Form>
        </Modal.Body>
        <Modal.Footer >
            <div className="chat_model_footer">
            <button>+</button>
            <input type="text" className="user_enter_name" placeholder="Write Your Message here..."/>
            </div>
       
        </Modal.Footer>
      </Modal>
        </>
    );
}
export default WorkerChat;