import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect, useRef } from "react";
import { setSession, getSession } from "../../utils/helper";
import axios from "axios";
import DashboardAsWorker from "../../components/DashboardAsWorker";
import { BaseUrl, apiBaseUrl } from "../../config/constant";

const WorkerLatestDetails = () => {
  const [success, setsuccess] = useState(false);
  const location = useLocation();
  const workerOrderDetail = location.state;
  const [data2,setData2]=useState(workerOrderDetail);
  const [data, setData] = useState([]);
  const token = getSession("token");
  const [paymentpopup, setpaymentpopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [locationName, setLocationName] = useState("");
  const [textareaValue, setTextareaValue] = useState("");
  const [processid, setId_process] = useState({
    jobId: "",
    rejectReason: "",
  });
  const [payment_selection, setPaymentSelection] = useState(false);
  const [Verifyotp, setverifyotp] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);

  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
  const [jodid, setjobid] = useState({
    jobId: "",
    code: "",
  });
  const [uploadpic, setuploadpic] = useState(false);
  const [orderHistory, setOrderHistory] = useState("");
  const [reject, setreject] = useState(false);
  const base_url = BaseUrl;
  console.log(data);

  const finishjob_popup = (data = {}) => {
    if (data && data.paymentStatus === "Success") {
      setsuccess(true);
      setpaymentpopup(false);
    } else {
      setpaymentpopup(true);
      setsuccess(false);
    }
    setPaymentSelection({
      ...payment_selection,
      ["paymentStatus"]: data.paymentStatus,
      ["amount"]: data.amount,
      ...(data.discount
        ? { ["discount"]: data.discount }
        : { ["discount"]: 0 }),
      ["id"]: data._id,
    });
  };
  const handleClose = () => {
    setShow(false);
    setverifyotp(false);
    setuploadpic(false);
    setPaymentSelection(false);
    setsuccess(false);
    setreject(false);
  };
  const Uploadingfunction = () => {
    setuploadpic(true);
    setShow(false);
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };
  const handleImageChange2 = (event) => {
    const file = event.target.files[0];
    console.log("Selected Image:", file);
    setSelectedImage2(file);
  };

  const handleUpload = () => {
    setverifyotp(true);Proceed_pay
    setuploadpic(false);
  };
  const handleReloadClick = () => {
    window.location.reload();
  };

  const handleOtpInputChange = (index, value) => {
    const newOtpDigits = [...otpDigits];
    newOtpDigits[index] = value;
    setOtpDigits(newOtpDigits);
  };
  
  const Proceed_pay = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl+"order/job-finish",
        {
          jobId: payment_selection.id,
          updateIp: 1,
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      {
        if (response.data.status) {
          setsuccess(true);
          setpaymentpopup(false);
        } else {
          setsuccess(false);
          setpaymentpopup(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const start_job = (id) =>{
    setjobid(id);
    setShow(true);
    
  } 

  const handleShow = async (jodid) => {
  
    const formData = new FormData();
    const enteredOtp = otpDigits.join("");
      formData.append('jobId', jodid);
      formData.append('code', enteredOtp);
      formData.append('first_pic', selectedImage);
     formData.append('second_pic', selectedImage2);
  
    try {
      let token = getSession("token");
      const response = await axios.post(
        apiBaseUrl+'order/job-start',
        formData,
        {
          headers: {
            'Authorization': token,
            "Content-Type": "multipart/form-data"
            
          }
        }
      );
      if (response.data && response.data.status && response.data.status === true) {
        
        navigate('/worker/orderhistory');
     
        setData(response.data.response.result);
      } else {
        alert("Job verification code is wrong. Please try again.");
      }
    } catch (error) {
      console.error(error);
    }
    
  };
  ////////////////////////////////////////////// by MD ///////////////////////////////////////

  const [rejectReason, setRejectReason] = useState("");
  const [checkResult, setCheckResult] = useState(true);

  

  const AcceptJob = async (id) => {
    try {
      const response = await axios.post(
        apiBaseUrl+"order/job-accept",
        {
          jobId: id,
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const RejectJob = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl+"order/job-reject",
        {
          jobId: data._id,
          rejectReason: rejectReason,
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAcceptJob = (order) => {
    if(order.job_status==="Assign"){
      AcceptJob(order._id)
        .then((data) => {
          if(data.status===true){
    setsuccess(true)
          }
        })
        .catch((error) => {
          // Handle error, if needed
          console.error('Error:', error);
        });
    }else if(order.job_status==="Accept"){
      start_job(order._id)
    }else if(order.job_status==="Start"){
      finishjob_popup(order)
    }
    
    };
  

  const handleRejectJob = () => {
    setCheckResult(false);
    RejectJob()
      .then((data) => {
        if (data.status === true) {
          setsuccess(true);
        }
      })
      .catch((error) => {
        // Handle error, if needed
        console.error("Error:", error);
      });
  };

  /////////////////////////////////////////end ////////////////////////////////////////////////
  return (
    <>
      <Header />
      <div className="dashboard">
        <div className="container">
          <div className="row mx-0">
            <DashboardAsWorker />
            <div className="col-md-9">
              <div className="right_side  mt-3">
                <div className="row mx-0">
                  <div className="col">
                    <div className="btn_es_align">
                      <button className="arrow_down">
                        <Link to="/worker/orderhistory">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/vendor_dashboard/order_detail/order_details.png"
                            }
                          />
                        </Link>
                      </button>
                      {data2.job_status === "Assign" ? (
                        <>
                          <div className="reschudle">
                            <button
                              className="button button_cancel_book"
                              onClick={() => { handleAcceptJob(data2) }}
                            >
                              Accept
                            </button>
                            <button
                              className="button button_cancel--hover"
                              onClick={() => { handleAcceptJob(data2) }}
                            >
                              Accept
                            </button>
                          </div>

                          <div className="cancel_book">
                            <button
                              className="button button_cancel_book"
                              onClick={() => setreject(true)}
                            >
                              Reject{" "}
                            </button>
                            <button
                              className="button button_cancel--hover"
                              onClick={() => setreject(true)}
                            >
                              Reject{" "}
                            </button>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {data2.job_status === "Start" && (
                        <>
                          <div className="col-md-2">
                            <div
                              className="assign-worker-container"
                              onClick={() => finishjob_popup(data2)}
                            >
                              <button className="button assign-worker">
                                Job Finish
                              </button>
                              <button className="button assign-worker1">
                                Job Finish
                              </button>
                            </div>
                          </div>
                          
                          <div className="col">
                            <div className="assign-viewer-container">
                              <button className="button assign-View-Detail">
                                Reject
                              </button>
                              <button className="button assign-View-Detail1">
                                Reject
                              </button>
                            </div>
                          </div>
                        </>
                      )}


                      
                      {data2.job_status === "Accept" && (
                        <>
                        {data2.job_status === "Start"  ? "" :
                          <div className="col-md-2">
                            <div
                              className="assign-worker-container"
                              onClick={() => start_job(data2._id)}
                            >
                              <button className="button assign-worker">
                                Start Job
                              </button>
                              <button className="button assign-worker1">
                                Start Job 
                              </button>
                            </div>
                          </div>
                        }
                          {data2.job_status === "Start" || data2.job_status === "Accept" ? "" : 
                          <div className="col">
                            <div className="assign-viewer-container">
                              <button className="button assign-View-Detail">
                                Reject
                              </button>
                              <button className="button assign-View-Detail1">
                                Reject
                              </button>
                            </div>
                          </div>
                        }
                        </>
                      )}
                    </div>

                    <div className="card_details">
                      <div className="card_heading">
                        <h1>Service Location</h1>
                      </div>
                      <div className="card_content">
                        <span>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/card_location.png"
                            }
                            alt="app"
                          />

                          <p>
                            {data2.service_location?.address1},{" "}
                            {data2.service_location?.address2},
                            {data2.service_location?.land_mark},{" "}
                            {data2.service_location?.city},
                            {data2.service_location?.state},{" "}
                            {data2.service_location?.country}
                          </p>
                        </span>
                      </div>
                    </div>
                    <div className="card_details">
                      <div className="card_heading">
                        <h1>Selected Provider</h1>
                      </div>
                      <div className="card_content">
                        <div className="select_provider">
                          <div>
                            {" "}
                            <img
                              src={base_url + data2.providerData.profile_pic}
                              alt="app"
                              className="worker_img"
                            />{" "}
                          </div>
                          <div className="select_provider_content">
                            <h1>{data2.providerData.fullName}</h1>
                            <p>
                              {data2.providerData.address1},{" "}
                              {data2.providerData.address2},
                              {data2.providerData.land_mark},{" "}
                              {data2.providerData.city},{data2.providerData.state}
                              , {data2.providerData.country}
                            </p>
                          </div>

                          <div>
                            <span>
                              <img
                                src={
                                  process.env.PUBLIC_URL + "../images/Star.png"
                                }
                                className="star_img"
                              />
                              <p>{data2.providerData.averageRating? data2.providerData.averageRating:"0.0"}</p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card_details">
                      <div className="card_heading">
                        <h1>Slot Date & Timing</h1>
                      </div>
                      <div className="card_content">
                        <ul>
                          <li>
                            <span>Service Date</span>{" "}
                            <p>{data2?.service_date}</p>
                          </li>
                          <li>
                            <span>Service Timing</span>{" "}
                            <p>{data2?.service_time}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card_details mb-2">
                      <div className="card_heading">
                        <h1>Service Detail</h1>
                      </div>
                      <div className="card_content">
                        <h2>
                          {" "}
                          {data2.service_name},{data2.sub_service_name}{" "}
                        </h2>
                        <p className="my-2">
                        {data2.job_desc}
                        </p>
                        {data2 && data2.jobImage? 
                        <div className="plumbering_img">
                          <img
                            src={base_url + data2.jobImage}
                            alt="app"
                            className="worker_img"
                          />
                        </div>:""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        show={show}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div id="lottie-animation"></div>
          <div
            className="congrats-profile-created"
            style={{ marginTop: "0px" }}
          >
            {error ? (
              <p>{error}</p>
            ) : locationName ? (
              <h6>{locationName}</h6>
            ) : (
              <p>
                Geolocation is disabled. Please enable geolocation in your
                browser settings.
              </p>
            )}

            <p>Use your device location to set your current location. </p>
            <div
              class="button-container"
              style={{ width: "200px" }}
              onClick={Uploadingfunction}
            >
              <button className="button button1">Use Current Location</button>
              <button className="button button2">
                <Link style={{ textDecoration: "none", color: "#ffffff" }}>
                  Use Current Location
                </Link>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={uploadpic}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created"
            style={{ marginTop: "0px" }}
          >
            <h2>Upload Photos</h2>
            <p>Upload 2 selfie images with your service location.</p>
            <div>
              <ul className="images_uploads">
                <li>
                  <div>
                    {selectedImage ? (
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        alt="Selected"
                      />
                    ) : (
                      <div>
                        <label
                          htmlFor="file-input"
                          style={{ display: "block", marginBottom: "10px" }}
                        >
                          <div className="uploading_img">
                            <img
                              src={process.env.PUBLIC_URL + "../images/add.png"}
                              alt="playstore"
                              className="addimgicon"
                            />
                          </div>
                        </label>
                        <input
                          type="file"
                          id="file-input"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                        />
                      </div>
                    )}
                  </div>
                </li>
                <li>
                  <div>
                    {selectedImage2 ? (
                      <img
                        src={URL.createObjectURL(selectedImage2)}
                        alt="Selected"
                      />
                    ) : (
                      <div>
                        <label
                          htmlFor="file-input2"
                          style={{ display: "block", marginBottom: "10px" }}
                        >
                          <div className="uploading_img">
                            <img
                              src={process.env.PUBLIC_URL + "../images/add.png"}
                              alt="playstore"
                              className="addimgicon"
                            />
                          </div>
                        </label>
                        <input
                          type="file"
                          id="file-input2"
                          onChange={handleImageChange2}
                          style={{ display: "none" }}
                        />
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="button-container"
              style={{ width: "200px" }}
              onClick={handleUpload}
            >
              <button className="button button1">Submit Images</button>
              <button className="button button2">
                <Link style={{ textDecoration: "none", color: "#ffffff" }}>
                  Submit Images
                </Link>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={Verifyotp}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created"
            style={{ marginTop: "0px" }}
          >
            <h2>Verify Yourself</h2>
            <p>
              Enter the 6-digit code that we sent to customer after order
              confirmation.
            </p>
            <div>
              <ul className="verify_otp">
                {otpDigits.map((digit, index) => (
                  <li key={index}>
                    <input
                      type="text"
                      className="input_verify"
                      maxLength="1"
                      value={digit}
                      onChange={(e) =>
                        handleOtpInputChange(index, e.target.value)
                      }
                    />
                  </li>
                ))}
              </ul>
            </div>

            <div
              class="button-container"
              style={{ width: "150px" }}
              onClick={() => handleShow(jodid)}
            >
              <button className="button button1">Submit</button>
              <button className="button button2">
                <Link style={{ textDecoration: "none", color: "#ffffff" }}>
                  Submit
                </Link>
              </button>
            </div>
            <p>Resend Code</p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={paymentpopup}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created"
            style={{ marginTop: "0px" }}
          >
            <div className="payment_details">
              <h1>Payment Detail</h1>

              <div className="vendor_payment_status">
                <input
                  placeholder="Payment Status"
                  className="payment_status"
                  value={payment_selection.paymentStatus}
                />

                {payment_selection.paymentStatus === "Success" ? (
                  <button className="prepaid_post">Postpaid Payment</button>
                ) : (
                  <button className="prepaid_post">Prepaid Payment</button>
                )}
              </div>
              {
                <ul className="mt-2">
                  <li className="pt-0">
                    <p>Total Amount</p>{" "}
                    <span>₦ {payment_selection.amount}</span>
                  </li>
                  <li>
                    <p>Discount</p>{" "}
                    <span className="text_change_color">
                      ₦ {payment_selection.discount}
                    </span>
                  </li>
                  <li>
                    <p>Other Charges</p>{" "}
                    <span>
                      {" "}
                      <p>Free</p>{" "}
                    </span>
                  </li>
                  <li className="pb-0 bottom_none">
                    <p className="text_change_color">Amount Paid</p>{" "}
                    <span className="text_change_color">
                      ₦{payment_selection.amount}
                    </span>
                  </li>
                </ul>
              }
            </div>
            <div className="col-md-12">
              <input
                type="number"
                placeholder="Enter Amount"
                name="city"
                className="select_options"
              // value={formData2.city}
              // onChange={}
              />
            </div>
            <div className="col-md-12">
              <select
                name="city"
                className="select_options"
              // value={formData2.city}
              // onChange={}
              >
                <option>Select Payment Mode</option>
                <option>Cash</option>
                <option>Online</option>
              </select>
            </div>

            <div
              class="button-container"
              style={{ width: "150px" }}
              onClick={() => Proceed_pay()}
            >
              <button className="button button1">Proceed To Pay</button>
              <button className="button button2">
                <Link style={{ textDecoration: "none", color: "#ffffff" }}>
                  Proceed To Pay
                </Link>
              </button>
            </div>
            <p>Resend Code</p>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={success}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created  well_done"
            style={{ marginTop: "0px" }}
          >
            <img
              src={process.env.PUBLIC_URL + "../images/greeting.gif"}
              alt="playstore"
              className="gif"
            />
            {checkResult ? (
              <>
                {" "}
                <h2>Job Accepted!</h2>
                <p>Please Continue Your Work</p>{" "}
              </>
            ) : (
              <h2>Job Rejected!</h2>
            )}

            <div class="button-container" style={{ width: "150px" }}>
              <button className="button button1">
                {" "}
                <Link
                  style={{ textDecoration: "none", color: "#ffffff" }}
                  to="/worker/orderhistory"
                >
                  {" "}
                  Proceed To Next
                </Link>
              </button>
              <button className="button button2">
                <Link
                  style={{ textDecoration: "none", color: "#ffffff" }}
                  to="/worker/orderhistory"
                >
                  {" "}
                  Proceed To Next
                </Link>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={reject}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created  well_done"
            style={{ marginTop: "0px" }}
          >
            <p>Reason For Reject</p>

            <textarea
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
              className="add_reasons"
            ></textarea>
            <div
              class="button-container mt-3"
              style={{ width: "150px" }}
              onClick={handleRejectJob}
            >
              <button className="button button1 s_btn"> Submit</button>
              <button className="button button2">Submit</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div></div>
    </>
  );
};

export default WorkerLatestDetails;
