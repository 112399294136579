import React,{useState,useEffect} from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import { apiBaseUrl, contactPageData } from "../../config/constant";
import {
  faPhone,
  faMapMarker,
  faEnvelope,
} from "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import "./contact.css";
import { ContactvalidationSchema, validationSchema } from "../../components/validator/validator";
import '../../components/validator/validator.css';
import axios from "axios";
import LoginModal from "../../components/loginModal";


const Contact = () => {
  //Fetch from Local Storage
  const c_page_data = JSON.parse(localStorage.getItem('C_pageData'));
  const c_banner_data = JSON.parse(localStorage.getItem('C_bannerData'));
  //End
  const email = "contact@doqwik.ng";
  const [errors, setErrors] = useState({});
  const [checkBlur, setBlur] = useState(false);
  const [click, setclick] = useState(false);
  const Congratulation = () => setclick(true);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    inquiry: "",
    email: "",
    message: "",
  });

  const [pageData , setPageData] = useState(c_page_data?c_page_data:[]);
  const [bannerImage , setBannerImage] = useState(c_banner_data?c_banner_data:[]);
  async function fetchPageData() {
    try {
      const response = await axios.get(apiBaseUrl+contactPageData, {
        headers: {
          key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
        },
      });
      setPageData(response.data.response.result);
      localStorage.setItem('C_pageData', JSON.stringify(response?.data?.response?.result))
      setBannerImage(response.data.response.result.banner[0].image)
      localStorage.setItem('C_bannerData',JSON.stringify(response.data.response.result.banner[0].image))

    } catch (error) {
      console.error(error);
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error message for the corresponding field
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await ContactvalidationSchema.validate(formData, { abortEarly: false });
      setBlur(true)
      postLoginData()
      .then((data) => {
        if(data.status === true){
            Congratulation();
                 
             }
      })
    } catch (validationErrors) {
      // Validation failed, handle the errors
      const errors = {};
      validationErrors.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
      setErrors(errors);
    }
  };

  const postLoginData = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl+'common/contactus-form',
        {
          firstName: formData.firstName,
          lastName:formData.lastName,
          inquiry: formData.inquiry,
          email: formData.email,
          message: formData.message
      
        },
        {
          headers: {
            key: 'd1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed',
            'Content-Type': 'application/json',
          },
        }
      );
      return(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPageData();
    window.scrollTo(0, 0);
  }, []);
  const responseGoogle = (response) => {
    // console.log(response);
    // Handle the response from Google Sign-In
  };
  const handleClosse = () => {
    setFormData(()=>({
      firstName: "",
      lastName: "",
      inquiry: "",
      email: "",
      message: "",
    }));
    setclick(false);
  }
  return (
    <>
      <Header  />
      <div className="contact_us" style={{ backgroundImage: `url(${bannerImage})` }}>
        <div className="container">
          <div className="row" id="contact_us_sections">
            <div className="col-md-12 col-lg-12 col-xl-5">
              <div className="about_banner_content">
              {pageData?.banner?.length && (
              <>
                <h1>
                {pageData.banner[0].title[0]} 
                <br /> {pageData.banner[0].title[1]} <br />
                  <span> {pageData.banner[0].title[2]?'"'+pageData.banner[0].title[2]+'"':""}</span>{" "}
                </h1>
                <p>
                {pageData.banner[0].title[2]}
                  <br />
                  {pageData.banner[0].title[3]}
                  <br />
                  {pageData.banner[0].title[4]}
                </p>
              </>
              )}
                
              </div>
            </div>
            <div className="col-md-8"></div>
          </div>
        </div>
      </div>
      <section>
        <div className="contact_us_strips">
          <div className="container">
            <div className="row mx-0">
              <div className="col-lg-6 col-md-12">
                <h1>Office Address</h1>
                <p className='d-flex'>
                  <span>
                    <img src={process.env.PUBLIC_URL + "../images/contact/map_icon.png"} className="location_contact_us" />
                  </span>
                  <a href="https://goo.gl/maps/iue4FKXfTqNhvtMy9" target='_blank' dangerouslySetInnerHTML={ { __html: pageData.generalData?.address}}>
                  </a>
                </p>
              </div>
              <div className="col-lg-3 col-md-12">
                <h1>Email Address</h1>
                <p>
                  <span>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <a href={`mailto:${email}`} >{(pageData.generalData?.email_id?pageData.generalData?.email_id:"contact@doqwik.ng")}</a>{" "}
                </p>
              </div>
              <div className="col-lg-3 col-md-12">
                <h1>Contact Number</h1>
                <p>
                  <span>
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="contact_mobile_icon"
                    />
                  </span>
                  <a href="tel:+2347082774380">{(pageData.generalData?.contact_no?pageData.generalData?.contact_no:"+2347082774380")}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-0 col-lg-6 col-xl-6">
              <img src={process.env.PUBLIC_URL + "./images/contact/contact_left.png"} alt="app" className="contact_us_img" />
            </div>
            <div className="col-md-12 col-lg-12 col-xl-6" id="contchtform">
              <div className="contact_us_form">
                <h2>Contact Form</h2>
                <div className="row mx-0" >
                  <div className="col-md-6">
                    <input
                      type="text"
                      onChange={handleChange}
                      className={` ${errors.firstName ? 'InputError' : ''}`}
                      placeholder="First Name"
                      value={formData.firstName?formData?.firstName:""}
                      name="firstName"
                    />
                    {errors.firstName && <p className="errors contact-error">{errors.firstName}</p>}
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      onChange={handleChange}
                      className={` ${errors.lastName ? 'InputError' : ''}`}
                      placeholder="Last Name"
                      value={formData.lastName?formData.lastName:''}
                      name="lastName"
                    />
                    {errors.lastName && <p className="errors contact-error">{errors.lastName}</p>}
                  </div>
                  <div className="col-md-12">
                    <select
                      onChange={handleChange}
                      className={` ${errors.inquiry? 'InputError' : ''}`}
                      placeholder="Inquiry"
                      name="inquiry"
                      value={formData.inquiry?formData.inquiry:''}
                    >
                      <option>Select Inquiry Reason</option>
                      <option>About App Information</option>
                      <option>Join as a Company</option>
                      <option>Join as a Individual.</option>
                    </select>
                    {errors.inquiry && <p className="errors contact-error-select">{errors.inquiry}</p>}
                  </div>
                  <div className="col-md-12 ">
                    <input
                      type="email"
                      onChange={handleChange}
                      className={` ${errors.email? 'InputError' : ''}`}
                      placeholder="Email Address"
                      name="email"
                      value={formData.email?formData.email:''}
                    />
                    {errors.email && <p className="errors contact-error">{errors.email}</p>}
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      placeholder="Type Your Message Here (If Any)..."
                      className="mt-0"
                      onChange={handleChange}
                      name="message"
                      value={formData.message?formData.message:''}
                    />
                    {errors.message && <p className="errors contact-error">{errors.message}</p>}
                  </div>
                  <div className="col-md-12">
                    <div className="button-container">
                      <button className="button button1" onClick={handleSubmit}>Submit Inquiry</button>
                      <button className="button button2 " onClick={handleSubmit}>Submit Inquiry</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="map_img">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.9899387409737!2d3.308865774908717!3d6.648167693346504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b9133cdec79ef%3A0x43301a589b788ae4!2s425%2F427%20Old%20Abeokuta%20Rd%2C%20Abule%20Egba%20102212%2C%20Lagos%2C%20Nigeria!5e0!3m2!1sen!2sin!4v1686572645082!5m2!1sen!2sin"
                width="100%"
                height="300"
                allowFullScreen
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    <LoginModal pageData={pageData}/>
      <Footer></Footer>




  <Modal show={click} centered
    style={{
      width: "351px",
      marginLeft: "40%",
      borderRadius: "34px !important",
    }}
    ClassName="custom-modal-dialog" >
    <Modal.Body>
      <div className="cross-X" onClick={()=>{handleClosse()}}>
        <img src={process.env.PUBLIC_URL + "../images/user/cross.png"} alt="playstore" />
        <img src={process.env.PUBLIC_URL + "../images/user/X.png"} alt="playstore" />
      </div>
      <div className="congrats-profile-created  well_done" style={{marginTop:"0px"}}>
        <img src={process.env.PUBLIC_URL + "../images/greeting.gif"} alt="playstore" className="gif" />
        <p> Message has been sent successfully.</p>
        <div class="button-container" style={{ width: "150px" }} onClick={()=>{handleClosse()}}>
          <button className="button button1" > Proceed To Next</button>
          <button className="button button2"> Proceed To Next</button>
        </div>
      </div>
    </Modal.Body>
  </Modal> 
    </>
  );
};

export default Contact;
