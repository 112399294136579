import React, { useState, useEffect } from 'react';
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import DashboardAsVendor from "../../components/dashboardAsVendor";

const Completeorder_details = () =>{
 return(
    <>
     <Header />
        <div className="dashboard">
    <div className="container">
        <div className="row mx-0">
         <DashboardAsVendor/>
            <div className="col-md-9">
             <div className="vendors_right_forms">
             <div className="row">
                    <div className="col-ms-12">
                        <h1 className="my_order">Order History </h1>
                        <h2 className="order_details">Your Order Records & Details.</h2>
                    </div>
                </div>
             <div className="right_side  mt-3">
               <div className="row mx-0" >
                <div className="col-8">
                  <div className="btn_es_align">
                   <button className="arrow_down"><Link to="/vendor/ordercomplete"><img src={process.env.PUBLIC_URL + "/vendor_dashboard/order_detail/order_details.png"} /></Link></button>
                  </div>

                 
                    <div className="card_details">
                        <div className="card_heading">
                            <h1>Service Location</h1>
                        </div>
                        <div className="card_content">
                          
                            <span><img src={process.env.PUBLIC_URL + "/images/card_location.png"} alt="app"  /> 
                            
                                <p>Gwarinpa-900108, Abuja, Federal Capital Territory,Nigeria</p></span>
                          
                        </div>
                    </div>
                    <div className="card_details">
                        <div className="card_heading">
                            <h1>Selected Provider</h1>
                        </div>
                        <div className="card_content">
                        <div className="select_provider">   
                            <div><img src={process.env.PUBLIC_URL + "../images/worker.png"} alt="app"  className="worker_img"/> </div>
                            <div className="select_provider_content">
                                <h1>Benjamin</h1>  
                                <p>Gwarinpa-900108, Abuja, Federal Capital Territory,Nigeria</p>
                               
                                </div>
                          
                          <div>
                            <span><img src={process.env.PUBLIC_URL + "../images/Star.png"} className="star_img"/><p>4.6</p></span>
                          </div>
                          </div>
                        </div>
                    </div>
                    <div className="card_details">
                        <div className="card_heading">
                            <h1>Slot Date & Timing</h1>
                        </div>
                        <div className="card_content">
                          <ul>
                            <li><span>Service Date</span> <p>04 April, 2023</p></li>
                            <li><span>Service Timing</span> <p>12:00 PM</p></li>
                          </ul>
                        </div>
                    </div>
                    <div className="card_details mb-2">
                        <div className="card_heading">
                            <h1>Service Detail</h1>
                        </div>
                        <div className="card_content">
                            <h2>Plumber, Sink Repairing </h2>
                            <p className="my-2">It is a long established fact that a read will be distracted by the read able of a page. It is a long established fact that a read will be distracted.</p>
                          <div className="plumbering_img">
                            <img src={process.env.PUBLIC_URL + "/images/plumber.png"} />
                            <img src={process.env.PUBLIC_URL + "/images/plumber.png"} />
                            <img src={process.env.PUBLIC_URL + "/images/plumber.png"} />
                          </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                <div className="work_status_processing">
                     <input placeholder="Work Status"  className="payment_status"/>
                            <button className="processing">Processing</button>
                          
                          </div>
                    <div className="payment_details mt-2">
                     <h1>Payment Detail</h1>
                     
                     <div className="vendor_payment_status">
                   
                     <input placeholder="Payment Status"  className="payment_status"/>
                     <button className="prepaid_post">Prepaid Payment</button>
                     </div>
                     <ul className="mt-2"> 
                        <li className="pt-0"><p>Total Amount</p> <span>₦ 920</span></li>
                        <li><p>Discount</p> <span className="text_change_color">₦ 110</span></li>
                        <li><p>Other Charges</p> <span>Free</span></li>
                        <li className="pb-0 bottom_none"><p className="text_change_color">Amount Paid</p> <span className="text_change_color">₦ 810</span></li>
                     </ul>
                    </div>
                    <div className="feed_backes_rates">
                    <h1>Feedback</h1>
                        <div className="feedbackes_border">
                            <div className="feedbacks_users">
                           
                                <div className="">
                                <img src={process.env.PUBLIC_URL + "../images/rating.png"} />
                                <span>4.0</span>
                                </div>
                                <div>
                             
                             
                                    <p>3 days ago</p>
                                </div>
                            </div>
                            <p>It is a long established fact that reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem  is that it has a more-or-less normal distribution of letters, as opposed.</p>
                        </div>
                    </div>
                    
                </div>
               </div>
                
             </div>
             </div>
            </div>
        </div>
    </div>
   </div>
        <Footer />
    </>
 )   
}

export default Completeorder_details;