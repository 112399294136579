import React,{useState,useEffect} from 'react'
import { Link ,NavLink } from 'react-router-dom'
import {  getSessionForLoginWorker ,logout} from '../utils/helper';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import BaseComponent from 'bootstrap/js/dist/base-component';
import { BaseUrl } from '../config/constant';
import { validateProviderToken } from '../components/common';


const DashboardAsWorker = ()=>{
    const vendor =  getSessionForLoginWorker('worker');
    const percentage = vendor?.profileComplete || 70;

    const [isopen, setopen] = useState(false);

    const toggleMenu = () => {
      setopen(!isopen);
    };
    const handleLogout=()=>{
        logout();
        window.location = "/";
    }
    const validateuser = async ()=>{
      try {
        const data = JSON.parse(await validateProviderToken(vendor?.token));
        if(data?.status === 401 || data?.message === "Unauthorized access."){
          handleLogout();
        }
      } catch (error) {
        if(error?.response?.status === 401 || error?.response?.statusText === "Unauthorized"){
          handleLogout();
        }
      }
    }
    useEffect(()=>{
      try {
        validateuser();
      } catch (error) {
        if(error?.response?.status === 401 || error?.response?.statusText === "Unauthorized"){
          handleLogout();
        }
      }
    },[]);
 return(
    <>
    <div className="col-md-3">
             <div className="user_profile">
            <div className="row mx-0">
                <div className="col-3">
                <div style={{ width: '69px',margin:'auto' ,height:'98px'}}>
      <CircularProgressbar  value={percentage} 
        strokeWidth={6}
        styles={buildStyles({
          strokeLinecap: 'butt', // Use 'butt' for a round image, 'round' for a circular image
          pathColor: `rgba(86,209,98, 1, ${percentage / 100})`,
          textColor: '#24A831',
          trailColor: '#d6d6d6',
         
        })}
        id="circularbar_dashboard"
    />
                <img src={vendor?.profile_pic?BaseUrl+vendor?.profile_pic :'https://doqwik.ng/images/avatar-default-icon.png'} alt="app"  className="app_img_profile_pic"    id="dashborad_pro" style={{height:"48px"}} />
                <div className='circular_value'>
       <p  className='percentage_value' id="percntage_vendor_dash" > {`${percentage}%`} </p>
       </div>
       </div>
                </div>
                <div className="col-9 px-0" >
                <div style={{paddingLeft:"10px"}}>
                <h1>    {vendor?.fullName}</h1>
                    <p><span> <img src={process.env.PUBLIC_URL + "/images/vendor/mobile.png"} alt="app"  className="app_img" /></span>{vendor.phone}</p>
                    <p><span> <img src={process.env.PUBLIC_URL + "/images/vendor/mail.png"} alt="app"  className="app_img" /></span>{vendor.email}</p>
                </div>
                </div> 
            </div>
               
             </div>
             <div className="user_listing">
             <h1>Account Information <span>  <button className="bar_icon_toggle" aria-label="bar"  onClick={toggleMenu}>
              <i className={`fa ${isopen ? "fa-times" : "fa-bars"}`}></i>
            </button></span></h1> 

            <div className={isopen ? "toggled" : ""} style={{ padding: 0 ,margin:0}}>
                <ul>

                <NavLink to="/worker/dashboard" style={{textDecoration:"none"}}> <li> <div>Dashboard </div></li> </NavLink> 
           
                  <NavLink to="/worker/profile" style={{textDecoration:"none"}}>  <li> <div> My Profile </div></li>  </NavLink>  
                  <NavLink to="/worker/orderhistory" style={{textDecoration:"none"}} >  <li><div> Order</div></li> </NavLink>  
                     <NavLink to="/worker/services" style={{textDecoration:"none"}}> <li><div>Services</div></li>   </NavLink>
              <NavLink to="/worker/revenue"  style={{textDecoration:"none"}}>  <li><div>Revenue Records</div></li> </NavLink>    
              {/* <NavLink to="/worker/chats" style={{textDecoration:"none"}}>    <li><div>Chats</div></li>   </NavLink>    */}
                <NavLink to="/worker/feedbacks" style={{textDecoration:"none"}}>   <li><div>Feedbacks</div></li> </NavLink>  
                <li onClick={handleLogout}><div className='logout'>Logout</div></li>       
                </ul>
                </div>
            </div>

    
            </div>
    </>
 )
}
export default DashboardAsWorker;