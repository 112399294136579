import React, { Component } from "react";
import Slider from "react-slick";


const customDotsClass = 'custom-dots-class';



export default class Aboutslider extends Component {
  render() {
    const settings = {
      dots: false,
      dotsClass: 'slick-dots  customDotsClass',
      infinite: true,
      speed: 500,
      slidesToShow: 2.3,
      slidesToScroll: 2,
      arrows:false

    };
    return (
      <div>

        <Slider {...settings}>
 

        <div className="t-main-content">
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis.
<ul className="d-flex">
  <li> 
  <img src={process.env.PUBLIC_URL + 'images/worker.png'} alt="Background Image" />
  </li>
  <li className="d-flex" id="testimonial-name"><span>
  <img src={process.env.PUBLIC_URL + '/images/string1.png'} alt="Background Image" />
  </span> Benjamin  <span>  <img src={process.env.PUBLIC_URL + '/images/string.png'} alt="Background Image" /></span></li>
</ul>
</div>
          <div className="t-main-content">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis.
<ul className="d-flex">
  <li> 
  <img src={process.env.PUBLIC_URL + 'images/worker.png'} alt="Background Image" />
  </li>
  <li className="d-flex" id="testimonial-name"><span>
  <img src={process.env.PUBLIC_URL + '/images/string1.png'} alt="Background Image" />
  </span> Benjamin  <span>  <img src={process.env.PUBLIC_URL + '/images/string.png'} alt="Background Image" /></span></li>
</ul>
</div>
          <div className="t-main-content">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis.
<ul className="d-flex">
  <li> 
  <img src={process.env.PUBLIC_URL + 'images/worker.png'} alt="Background Image" />
  </li>
  <li className="d-flex" id="testimonial-name"><span>
  <img src={process.env.PUBLIC_URL + '/images/string1.png'} alt="Background Image" />
  </span> Benjamin  <span>  <img src={process.env.PUBLIC_URL + '/images/string.png'} alt="Background Image" /></span></li>
</ul>
</div>

      
        </Slider>
      </div>
    );
  }
}