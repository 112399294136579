import Header from "../../components/header";
import Footer from "../../components/footer";
import React, { useEffect, useState } from 'react';
import {useParams ,useNavigate,useLocation } from 'react-router-dom';

import axios from 'axios';
import { setSession, getSession} from "../../utils/helper";
import { apiBaseUrl } from "../../config/constant";
const Edit_Service = ()=>{

const location = useLocation();
const data_id = location.state; 
console.log(data_id);
// const { id } = useParams(); 
 const navigate = useNavigate();
 const [options, setoptions] = useState('');  
 console.log(options);
 const [data, setData] = useState({
    serviceData: {
     service_name : "",
    },
    subServices: {
      sub_service_name: "",
    },
    price: "",
    category: "",
  });
  
    
  
  useEffect(() => {
        const fetchData = async () => {
          try {
           
            let token = getSession("token");
           
            const response = await axios.post(
                apiBaseUrl+'provider/get-individual-single-services',
              {
                id:data_id._id
              },
              {
                headers: {
                  'Authorization': token,
                  'Content-Type': 'application/json'
                }
              }
            );
            setoptions(response.data.response.result.category);
            setData(response.data.response.result
              );
           console.log(response.data.response.result
            );
           
          } catch (error) {
            console.error(error);
       
            
          }
        };
    
        fetchData();
      }, []);

      const handleOptionChange = (event) => {
        setoptions(event.target.value);
      }
     
      const update_Service = async (id) =>{
        try {
        
          let token = getSession("token");
          console.log(token);
          console.log(id);
          console.log(data.price); 
          console.log(options);
         
          const response = await axios.post(
            apiBaseUrl+'provider/add-services-by-individual',
            {
      
              price: data.price,
              category: options,
              services:data.serviceData._id,
              sub_services:data.subServices._id,
              },
            {
              headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
              }
            }
          );
         
          console.log(response.data.response.result);
          navigate('/individual/services');
         
        } catch (error) {
          console.error(error);
         
        }
      } 
     
      const handlePriceChange = (event) => {
        // Update the 'price' state with the new value typed by the user
        setData((prevData) => ({
          ...prevData,
          price: event.target.value,
        }));
      };
      
    
    return(
        <>
     <Header />
        <div className="dashboard">
    <div className="container">
        <div className="row mx-0">
        
            <div className="col-md-12">
           
           <div className="form_book_appintment_next_form">
           <div className="row">
         <p className="fill_form mb-0"><span> Specialisation </span> <br /> Add Your Working Service.</p>
              
              <form >
              <div className="row">
                <div className="col-md-3">
                    <h1>Service</h1>
                    <div className="user_name"><input type="text"   placeholder="Unisex Salon" className="unix_slon" value={data.serviceData.service_name} /></div>
                </div>
               {/* <div className="col-md-9">
               {data.serviceData.isCategory === "Y" ? (
               <div className="user_selection edit_srvice_Selection">
               <h1>Gender</h1>
                 
                            <ul>
                              <div className="user_name">
                                <input
                                  type="radio"
                                  className="radio_btns"
                                  value="Male"
                                  name="gender_selection"
                                  checked={options  === 'Male'}
                                  onChange={handleOptionChange}
                                />
                                <label>Male</label>
                              </div>
                              <div className="user_name">
                                <input
                                  type="radio"
                                  className="radio_btns"
                                  value="Female"
                                  name="gender_selection"
                                  checked={options  === 'Female'}
                                  onChange={handleOptionChange}
                                />
                                <label>Female</label>
                              </div>
                              <div className="user_name">
                                <input
                                  type="radio"
                                  className="radio_btns"
                                  value="Both"
                                  name="gender_selection"
                                  checked={options  === 'Both'}
                                  onChange={handleOptionChange}
                                />
                                <label>Both</label>
                              </div>
                            </ul>
                            </div>
                 
                 ) : null}           
               </div> */}
               </div>
                
                  
                      <div className="row">
                              <div className="col-md-12">
                                  <h1 className="mt-2">Sub-Service</h1>
                              
                              <div className="user_selection">
                                  <ul >
                                    <li className="selected_subservice">{data.subServices.sub_service_name}</li >
                                  </ul>
                                  </div>
                              </div>
                              
                            </div>
                            <div className="row">
                        <div className="col-md-12">
                            <h1>Price</h1>
                        
                        <div className="user_selection">
                      
                          <div className="price">
                          
                          <span><img src={process.env.PUBLIC_URL + "/vendor_dashboard/nan.png"} /></span><input type="text" placeholder="Enter Service Amount" value={data.price} onChange={handlePriceChange}/>
                      
                          </div>
                        
                            </div>
                        </div>
                        
                      </div>
                  
                                  
                  
                 
                  </form>
              

            </div>
           </div>
        
          

  


   </div>          
          
         

<div className="proceed_next mt-4 mb-4">
 
           <div class="button-container proccess_next mt-4 mb-4 mr-0" onClick={() => update_Service(data._id)} ><button class="button button1" > Submit</button><button class="button button2">Submit</button></div>
           </div>
            </div>
        </div>
    </div>
   
        <Footer />
        </>
    )
}
export default Edit_Service