import { useState, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import LeftnavbarIndividual from "../../components/leftnavbaraIndividual";
import { setSession, getSession} from "../../utils/helper";
import axios from 'axios';
import { Link,useNavigate} from "react-router-dom";
import { apiBaseUrl } from "../../config/constant";
// Other imports...

const Individualcompeleorder = () =>{
  const [activeButton, setActiveButton] = useState('');
  const [reject_list,setreject_list] = useState('');
const [selectedItem, setSelectedItem] = useState(null);  
const navigate = useNavigate();
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  useEffect(() => {
    const fetchData = async () => {
      
      try {
        let token = getSession("token");
       
        const response = await axios.post(
          apiBaseUrl+'order/get-provider-job-list',
          {
          // id: id
          }, 
          {
            headers: {
              'Authorization': token,
              'Content-Type': 'application/json'
            }
          }
        );
        const rejectJobs = response.data.response.result.filter(job => job.job_status === 'Complete');

console.log(rejectJobs)
       
       setreject_list(rejectJobs);
        
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, []);

 const viewdetails = (order) =>{
 console.log(order);
  setSelectedItem(order);
  navigate('/individual/Individualcompleteorder_detail', {state:order});
 }
    return(
        <>
        <Header />
   <div className="dashboard">
    <div className="container">
        <div className="row mx-0">
        <LeftnavbarIndividual></LeftnavbarIndividual>
            <div className="col-md-9">
             <div className="vendors_right_form">
          
               <div className="row  mx-0">
                <div className="col-md-4">
                <p className="sub_heading">Order History</p>
                  <p className="main_heading">Your Order Records & Details.</p>
                </div>
                <div className="col-md-8">
                <ul className="user_list">
      <li>
        <div className="latest_container">
          <button
            className={`button latest_btn ${activeButton === 'latest_btn' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('latest_btn')}
          >
            <Link to="/individual/orderhistory">Latest ( New )</Link>
          </button>
          <button
            className={`button latest_buttn ${activeButton === 'latest_buttn' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('latest_buttn')}
          >
            <Link to="/individual/orderhistory">Latest ( New )</Link>
          </button>
        </div>
      </li>
      <li>
        <div className="assigned_container">
          <button
            className={`button Assigned_btn ${activeButton === 'Assigned_btn' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('Assigned_btn')}
          >
            <Link to="/individual/IndividualToday">Today</Link>
          </button>
          <button
            className={`button Assigned_button ${activeButton === 'Assigned_button' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('Assigned_button')}
          >
            <Link to="/individual/IndividualToday">Today</Link>
          </button>
        </div>
      </li>
      <li>
        <div className="complete_container">
          <button
            className={`button Completed ${activeButton === 'Completed' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('Completed')}
          >
            <Link to="/individual/Individualcompeleorder">Completed</Link>
          </button>
          <button
            className={`button Completed_button ${activeButton === 'Completed_button' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('Completed_button')}
          >
            <Link to="/individual/Individualcompeleorder">Completed</Link>
          </button>
        </div>
      </li>
      <li>
        <div className="reject_container">
          <button
            className={`button reject_btn ${activeButton === 'reject_btn' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('reject_btn')}
          >
            <Link to="/individual/Individualreject">Rejected</Link>
          </button>
          <button
            className={`button Reject_button ${activeButton === 'Reject_button' ? 'active_latest' : ''}`}
            onClick={() => handleButtonClick('Reject_button')}
          >
            <Link to="/individual/Individualreject">Rejected</Link>
          </button>
        </div>
      </li>
    </ul>
              
                </div>
               </div>


               <div className="row  mx-0">
               {reject_list && reject_list.map((order,index) => (
                <div className="col-md-6" key={index}>
              
                  <div className="book_slot">
                  <div className="border_bottom row mx-0">
                    <div className="col-md-6 col-6">
                      <div className="date_booking">
                        <p>Booking Slot Date</p>
                        <h1>{order.service_date}</h1>
                        </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="date_booking">
                          <p>Booking Slot Time</p>
                            <h1>{order.service_time}</h1>
                          </div>
                        </div>
                      </div>
                      <div className="border_bottom row mx-0">
                        <div className="date_booking">
                       <p>Address</p>
                       <h1>
      {order.service_location?.address1}, {order.service_location?.address2},
      {order.service_location?.land_mark}, {order.service_location?.city},
      {order.service_location?.state}, {order.service_location?.country}
    </h1>
                       </div>
                      </div>
                      <div className="border_bottom row mx-0">
                    <div className="col-md-6 col-6">
                      <div className="date_booking ">
                        <p className="postpayment">Payment</p>
                        <h2>{order.amount}-/</h2>
                        </div>
                        </div>
                        <div className="col-md-6 col-6 px-0">
                          <div className="prepaid">
                          {order.paymentStatus === "success" ? (
    <button className="pre-paid">Prepaid Payment</button>
  ) : (
    <button className="pre-paid">Postpaid Payment</button>
  )}
                          </div>
                        </div>
                      </div>
                      <div className="border_bottom row mx-0">

  <>
    <div className="col-md-12 col-12 px-0 complete_details">
      <div className="assign-viewer-container" onClick={()=> viewdetails(order)}>
        <button className="button assign-View-Detail"><Link to="/individual/Individualcompleteorder_detail">View Details </Link></button>
        <button className="button assign-View-Detail1"><Link to="/individual/Individualcompleteorder_detail">View Details </Link></button>
      </div>
      </div>
  </>


                      </div>
                  </div>
                    
                </div>
                ))}
               </div>

           
             </div>
            </div>
        </div>
    </div>
   </div>
    <Footer />
        </>
    );
};

export default Individualcompeleorder;
