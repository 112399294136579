import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import './dashboard.css';
import React, { useState, useEffect } from 'react';
import Dashbordslider from "../../slider/dashbordslider";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { getSession } from "../../utils/helper";
import UserDashboard from "../../components/dashboardAsUser";
import { apiBaseUrl } from "../../config/constant";

const Order_cancel = ()=>{
    const token = getSession("token");
    const location = useLocation();
    const data = location.state;
    const { key } = useParams();
    const [cancelReason, setCancelReason] = useState('');
    const [error, setError] = useState('');

    const fetchCheckOutData = async () => {
        try {
          const response = await axios.post(
            apiBaseUrl+'order/job-cancel-by-user',
            {
              jobId:key,
              rejectReason:cancelReason
             
          },
            {
              headers: {
                Authorization: token,
                'Content-Type': 'application/json'
              }
              
            }
      
          );
    return response.data;
        } catch (error) {
          console.error(error);
        }
      };
    
      const handleChange = (event) => {
        setCancelReason(event.target.value);
        setError('');
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
    
        if (!cancelReason) {
          setError('Please select a cancellation reason');
          return;
        }else{
            fetchCheckOutData()   .then((data) => {
                console.log(data,"chdata")
                if (data.status === true) {
                  window.location = "/user_dashboard/order_history";
                }
              })
              .catch((error) => {
                console.error(error);
              });
        }
    
        // Handle the form submission with the selected cancelReason value
        console.log(cancelReason);
      };
    return(
    <>
    <Header/>
    <div className="dashboard">
    <div className="container">
        <div className="row">
      <UserDashboard/>
            <div className="col-md-9">
            <div className="row">
                    <div className="col-ms-12">
                        <h1 className="my_order">My Orders </h1>
                        <h2 className="order_details">Order Details</h2>
                    </div>
                </div>
             <div className="right_side  mt-3">
               <div className="row">
                <div className="col-8">
                  <div className="btn_es_align">
                    <div>
                      <Link to='/user_dashboard/order_history'>
                      <button className="button_cancel_book active_btn">Order Detail</button>
                      </Link>

                    {/* <button className="button_orderdetails--hover">Order Detail</button> */}
                    </div>
                 
                    <div>
                    <button className=" button_orderdetails_one">Cancel Booking</button>
                    {/* <button className="button_cancel--hover">Cancel Booking</button> */}
                    </div>
                  </div> 
                </div>
                <div className="col-4">
                    
                </div>
               </div>
               <div className="row">
                <div className="col-md-12">
                <div className="cancellation">
                    <h1>Service Cancellation Reason</h1>
                    <div className="cancel_order"><span><input type="radio" value="Hired someone else outside doQwik" onChange={handleChange} checked={cancelReason === 'Hired someone else outside doQwik'}/></span> <p>Hired someone else outside doQwik. </p></div>
                    <div className="cancel_order"><span><input type="radio" value="Concerned about service hygiene." onChange={handleChange} checked={cancelReason === 'Concerned about service hygiene.'}/></span> <p>Concerned about service hygiene. </p></div>
                    <div className="cancel_order"><span><input type="radio" value="Service not required at the booked time." onChange={handleChange} checked={cancelReason === 'Service not required at the booked time.'}/></span> <p>Service not required at the booked time. </p></div>
                    <div className="cancel_order"><span><input type="radio" value="My society/building has restricted entry of outsiders." onChange={handleChange} checked={cancelReason === 'My society/building has restricted entry of outsiders.'}/></span> <p>My society/building has restricted entry of outsiders.</p></div>
                    <div className="cancel_order"><span><input type="radio" value="Professional not assigned" onChange={handleChange} checked={cancelReason === 'Professional not assigned'}/></span> <p>Professional not assigned</p></div>
                    <div className="cancel_order"><span><input type="radio" value="Placed the request by mistake" onChange={handleChange} checked={cancelReason === 'Placed the request by mistake'}/></span> <p>Placed the request by mistake</p></div>
                    <div className="cancel_order"><span><input type="radio" value="Other" onChange={handleChange} checked={cancelReason === 'Other'} /></span> <p>Other</p></div>
                  </div>
                   
        {error && <p className="error-message">{error}</p>}
                </div>
                <div class="button-container mt-4 mb-4">
                    <button class="button button1">Cancel Appointment</button>
                    <button class="button button2" onClick={handleSubmit}>Cancel Appointment</button>
                    </div>
               </div>
                
             </div>
            </div>
        </div>
    </div>
   </div>

    <div className="container-fluid other_details px-0">
        <div className="row">
          
            <div className="col-md-3">

            </div>
            {/* <div className="col-md-9 px-0">
            <h1 className="other_Details"> Other Orders</h1>
                <div className="dash_slider">
            <Dashbordslider />
            </div>
            </div> */}
        </div>
    </div>
              
              
            
  
   <section className="mb-0">
    <div className="download_aap">
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                <img src={process.env.PUBLIC_URL + "/app_img.png"} alt="app"  className="app_img" />
                 
                </div>
                <div className="col-md-6 pt-4">
                    <h1>Download the app</h1>
                    <p>Get the app. Get things done. Compare prices, read reviews and book top-rated services. All in one free app.</p>
                </div>
                <div className="col-md-3">
                   <ul>
                    <li> <img src={process.env.PUBLIC_URL + "/androied.png"} alt="app"  className="app_img" /> </li>
                    <li> <img src={process.env.PUBLIC_URL + "/ios.png"} alt="app"  className="app_img" /> </li>
                    
                 
                   </ul>
                </div>
            </div>
        </div>
    </div>
</section>

    <Footer />   
    </>
    )
}

export default Order_cancel;
