import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link, useNavigate } from "react-router-dom";
import "./vendor.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar} from '@fortawesome/fontawesome-free-solid'
import DashboardAsVendor from "../../components/dashboardAsVendor";
import React, { useState, useEffect } from 'react';
import { getSession } from "../../utils/helper";
import axios from "axios";
import { BaseUrl, apiBaseUrl } from "../../config/constant";

const WorkerList = () =>{
  const token = getSession("token");
  const [data,setData]=useState([]);
  const navigate = useNavigate();


  const fetchWorkerListData = async () => {
    try {
      const response = await axios.get(
        apiBaseUrl+"provider/company_worker-list",
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      const workerList = response.data.response.result.workerList;
      console.log('workerList',workerList);
      setData(workerList)
      // console.log(response.data.response.result, "servicesseke");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchWorkerListData()
  }, []);
  return(
  <>
    <Header />

   <div className="dashboard">
    <div className="container">
        <div className="row mx-0 worklist_dashboard">
         <DashboardAsVendor/>
         <div className="col-md-12 col-lg-9">
             <div className="vendors_right_form">
             <div className="row  mx-0">
                <div className="col-md-6">
                <p className="sub_heading">Your Company Workers Records & Details.</p>
                
                </div>
                <div className="col-md-6">
                <div className="assign-worker-container" id="add_provider">
                <Link to="/vendor/add_new_provider">  <button className="button assign-worker">Add New Worker</button> </Link>
                      <Link to="/vendor/add_new_provider"><button className="button assign-worker1">Add New Worker</button> </Link>    
                        </div>
                </div>
               </div>
             <div className="row mx-0">
             {data?.map((item, index) => (
  <div className="col-md-12 col-lg-6" key={index}>
    <div className="worker-list-card">
      <div className="row mx-0">
        <div className="col-md-3 col-4">
          <img src={item.profile_pic?item.profile_pic:'https://doqwik.ng/images/avatar-default-icon.png'} alt="profile-pic"  className="user_locations"/>
        </div>
        <div className="col-md-9 col-8 px-0">
          <div className="worklist_username row mx-0">
            <div className="col-md-8 col-8">
              <h1>{item.fullName}</h1>
              <p>{item.address2}</p>
            </div>
            <div className="col-md-4 col-4">
              <span className="user_star">
                {item.averageRating && (<><FontAwesomeIcon icon={faStar} className="star_yellow" /> {item.averageRating?item.averageRating:''}</>)}
              </span>
            </div>
           
          </div>
        </div>
        <div className="row worker_list mx-0 mt-2">
              <div className="col-md-6 col-6 px-0">
                <div className="assign-worker-container" id="assign_work_list" onClick={()=>{navigate('/vendor/worker-assign-job', { state: item._id })}}>
                  {/* <Link to={{ pathname: '/vendor/worker-assign-job', state: item._id }}> */}
                  <button className="button assign-worker">Assign Job List {}</button>
                  <button className="button assign-worker1">Assign Job List</button>
                  {/* </Link> */}
                </div>
              </div>
              <div className="col-md-6 col-6 px-0">
                <div className="assign-viewer-container" id="view_details">
           
                <button className="button assign-View-Detail"><Link to={`/vendor/workdetails/${item._id}`}> View Detail </Link></button>
                          <button className="button assign-View-Detail1">  <Link to={`/vendor/workdetails/${item._id}`}> View Detail </Link></button>
                </div>
              </div>
            </div>
      </div>
    </div>
  </div>
))}

         
              
             </div>
             
               
             </div>
            </div>
        </div>
    </div>
   </div>
    <Footer />
     </>
    );
};

export default WorkerList;