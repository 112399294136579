import { useState,useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import DashboardAsVendor from "../../components/dashboardAsVendor";
import { Link } from "react-router-dom";
import axios from "axios";
import { getSession } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { apiBaseUrl } from "../../config/constant";

const Vendorservice = ()=>{
const verdorService = JSON.parse(localStorage.getItem('p_vendorService'));
const [data,setData] = useState(verdorService);
const token = getSession("token");
const navigate = useNavigate();
   useEffect(() => {
      const fetchData = async () => {
        try {
          const url = apiBaseUrl+'provider/get-company-services-list';
          const response = await axios.get(url, {
            headers: { 'Authorization': token }
          });
          setData(response.data.response.result)
          localStorage.setItem('p_vendorService', JSON.stringify(response?.data?.response?.result));
          sessionStorage.setItem('vendor_service',response?.data?.response?.result[0].serviceData._id);
        } catch (error) {
          // Handle errors
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, []);
    const handleEdit=(item)=>{
      // console.log('item',item);
      navigate('/vendor/edit_service' , {state:item});
    }
return(
  <>
    <Header />
    <div className="dashboard">
      <div className="container">
        <div className="row mx-0 worklist_dashboard">
          <DashboardAsVendor/>
          <div className="col-md-12 col-lg-9">
            <div className="vendors_right_formses">
              <div className="row mx-0">
                <div className="col-md-8">        
                  <h1 className="my_order mt-2">Your Company Service Records & Details.</h1>
                  </div>
                    <div className="col-md-4">
                    <Link to="/vendor/service_selection">
                      <div className="assign-worker-container" id="services">
                          <button className="button assign-worker">Add New Service </button>
                          <button className="button assign-worker1">Add New Service</button>
                      </div>
                      </Link>
                    </div>
                    
                </div>
             <div className="row service_outer_box mt-2 mx-0">
             {data?.map((item,index)=>(
                <div className="col-md-6 col-lg-4 col-sm-12">
                    <div className="row mx-0 chat_box">
                    <div className=" col-4 px-0">
                        {/* <img src={process.env.PUBLIC_URL + "/vendor_dashboard/servcies_img.png"} alt="app" className="service_icon"  /> */}
                        {/* <img src={item?.subServices?.sub_service_icon} alt="app" className="service_icon"  /> */}
                      <ul className="all_services" style={{paddingLeft:"0rem"}}>
                        <li>
                          <img src={item?.subServices?.sub_service_icon} alt="icon"/>
                          <p>{item?.subServices?.service_name}</p>
                        </li>
                      </ul>
                        </div>
                        <div className="col-8  " > 
                       {/* {item.category? <span>Gender:<p> {item.category}</p></span> :<br/>} */}
                           
                           <span> Service Type:</span>
                           <span> <p> {item?.subServices?.sub_service_name}</p></span>
                           <span>Price:<p>  ₦ {item.price}/-</p></span>
                          
                        </div>
                        <div className="col-6 px-0">
                         <div className="edit_Service">
                            <button className="button edit_Service_first">Edit Service</button>
                            <button className="button edit_Service_second" onClick={()=>{handleEdit(item)}}>Edit Service</button>
                         </div>
                        </div>
                        <div className="col-6 px-0">
                        <div className="View_Detail">
                            <button className="button view_details_first"><Link to={`/vendor/service_details/${item._id}`}>View Detail</Link></button>
                            <button className="button view_details_second"><Link to={`/vendor/service_details/${item._id}`}>View Detail</Link></button>
                         </div>
                        </div>
                    </div>
                </div>

                ))}

       
      
                {/* <div className="col-md-4">
                <div className="row mx-0 chat_box">
                        <div className="col-md-4 px-0">
                        <img src={process.env.PUBLIC_URL + "/vendor_dashboard/servcies_img.png"} alt="app" className="service_icon"  />
                      
                        </div>
                        <div className="col-md-8 px-0">
                       
                           <span>Category:<p> Female</p></span>
                           <span> Sub-Service: <p> Manicure</p></span>
                           <span>Price:<p>  ₦ 235/-</p></span>
                          
                        </div>
                        <div className="col-md-6 px-0">
                         <div className="edit_Service">
                            <button className="button edit_Service_first"><Link to="/vendor/service_selection">Edit Service</Link></button>
                            <button className="button edit_Service_second"><Link to="/vendor/service_selection">Edit Service</Link></button>
                         </div>
                        </div>
                        <div className="col-md-6 px-0">
                        <div className="View_Detail">
                            <button className="button view_details_first"><Link to="/vendor/service_selection">View Detail</Link></button>
                            <button className="button view_details_second"><Link to="/vendor/service_selection">View Detail</Link></button>
                         </div>
                        </div>
                    </div>
                </div> */}
               
               
             </div>
         
             </div>
            </div>
        </div>
    </div>
   </div>
        <Footer />
        </>
    );
};

export default Vendorservice;