import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import "./dashboard.css";
import React, { useState, useEffect,useRef } from "react";
import Dashbordslider from "../../slider/dashbordslider";
import { useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { validationSlotSchema } from "../../components/validators/userFormValidator";
import axios from "axios";
import { getSession } from "../../utils/helper";
import UserDashboard from "../../components/dashboardAsUser";
import { apiBaseUrl,BaseUrl } from "../../config/constant";
import Lottie from "react-lottie";
import animationData from "../../animation_wait.json";
import { PaystackButton } from "react-paystack";
import { getSessionForLoginUser } from "../../utils/helper";
import { faStar} from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { formatTime, generateTimes, timeDifference } from "../../components/common";
import { Row,Col } from "react-bootstrap";
import { setSessionForAddress } from "../../utils/helper";

const Order_details = () => {
  const scrollControlRef1 = useRef(null);
  const scrollControlRef2 =useRef(null)
  const navigate = useNavigate();
  const userData = getSessionForLoginUser("user");
  const location = useLocation();
  const data = location.state;

  const [ratingStar, setRatingStar] = useState(0);
  const maxRating = 5;
  const token = getSession("token");
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [profileData, setProfileData] = useState([]);
  const [serviceData, setServicesData] = useState([]);
  const [times, setTimes] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show6, setShow6] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3= () => setShow3(true);
  const handleClose6 = () => setShow6(false);
  const handleShow6= () => setShow6(true);
  const [rating, setRating] = useState(0);
  const [errors, setErrors] = useState([]);
  const [show, setShow] = useState(false);
  const preMOnth = () => {
    sub(currentDate, { months: 1 });
  };
  const handleShow = () => {
    setShow(true);
    setFormData((prevValues) => ({
      ...prevValues,
      service_date: dates[0]?.date,      
    }));
  };

  const [selectedItem, setSelectedItem] = useState(0);
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [selectedTimeItem, setSelectedTimeItem] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [data2, setData2] = useState([]);
  const [show2, setShow2] = useState(false);
  const [success2, setSuccess2] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [showRate,setShowRate]=useState(false);
const [errorsForRating,setErrorsForRating]=useState('');
const [reorder,setReorder]=useState([])
const [jobDetails, setJobDetails] = useState(null);
const [latestAddress,setLatestAddress]=useState('')

const timeReple=jobDetails?.userFeedback?.replayAt
const timeReple2=jobDetails?.userFeedback?.feedbackAt

useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.post(apiBaseUrl+'order/get-job-details', {
                jobId: data._id
            }, {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            }
            );
          
            setJobDetails(response.data.response.result);
            setReorder(response)
        } catch (error) {
            console.error('Error:', error);
        }
    };

    fetchData();
}, []); 

  const [formDataforRate, setFormDataforRate] = useState({
    jobId: data._id,
    message: "",
    star: rating,
  });

  const handleClose = () => {
    setShow(false);
    setShowRate(false)
  };
  const handleShowRate =()=>{
    setShowRate(true) 
  }
  
  const handleShow2 = () => {
    setShow2(true);
  };
  const handleClose2 = () => {
    setShow2(false);
    setSuccess2(false);
  };
  const selectedTime = (index, time) => {
    setSelectedTimeItem(index);
    setFormData((prevFormData) => ({
      ...prevFormData,
      service_time: time,
    }));
  };
  const [formData, setFormData] = useState({
    jobId: data._id,
    locationID: latestAddress?._id??data?.service_location?._id,
    service_date: dates[0]?.date,
    service_time: "",
    service_type: "Home Cleaning",
    // worker_id: key,
  });

  const verificationCode = `${jobDetails?.verificationCode}`;
  // APi calls

  const rescheduleSlotData = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl+"order/job-reschedule-by-user",
        formData, // Use the formData as the data object
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);
      alert(error?.response?.data?.statusMessage);
    }
  };

  // end Api calls

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSlotSchema.validate(formData, { abortEarly: false });
      setFormErrors({});
      rescheduleSlotData()
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            handleClose()
            handleShow6()
            // window.location.href = "/user_dashboard/order_history";
          }
        })
        .catch((error) => {
          console.error(error);
        });

      // Validation succeeded, proceed with form submission
    } catch (validationErrors) {
      // Validation failed, handle the errors
      const errors = {};
      validationErrors.inner.forEach((error) => {
        errors[error.path] = error.message;
      });

      setFormErrors(errors);
    }
  };
  const handleDateClick = (index, date) => {
    setSelectedItem(index);

    // Format the selected date as "YY-MM-DD"
    const formattedDate =
      date.slice(6, 8) + "-" + date.slice(3, 5) + "-" + date.slice(0, 2);
    setSelectedDate(formattedDate);
    setFormData((prevData) => ({
      ...prevData,
      service_date: formattedDate,
    }));
  };

  useEffect(() => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const currentDay = currentDate.getDate();

    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const daysInNextMonth = new Date(year, month + 2, 0).getDate()
    const totalDays = daysInMonth + daysInNextMonth - currentDay; 
    const generatedDates = [];

    for (
      let i = currentDay, count = 0;
      count < totalDays && count < 10;
      i++, count++
    ) {
      const date = new Date(year, month, i);
      const day = date.toLocaleString("en-us", { weekday: "short" });
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      });

      generatedDates.push({ day, date: formattedDate });
    }

    setDates(generatedDates);
  }, []);

  const fetchData = async () => {
    try {
      const url = apiBaseUrl+"users/address-list";
      const response = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      })
      setData2(response.data.response.result);

    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    fetchData();
    setTimes(generateTimes  ());
  }, []);

  const handleRadioClick = (item) => {
    setSelectedItemId(item?._id);
    setLatestAddress(item);
    handleClose2();
    setShow(true)
  };
  // ----------------------------------------------------------payment ----------------------------------------
  const publicKey = "pk_test_bf755297dbb8d47a792a4eb9e153eab8fe4a1613"; // Replace with your actual Paystack public key
  const [paymentStatus, setPaymentStatus] = useState(null);

  const fetchData2 = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl+"order/payment-success",
        {
          jobID: data._id,
          txn_no: "4652XCSZSD452",
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
            Cookie: "ci_session=237e92c3d4f23a83ef0ec45e02edb8df5c01afba",
          },
        }
      );
      if (response.data.status === true) {
        setSuccess2(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePaymentSuccess = (response) => {
    // setPaymentStatus('success');

    fetchData2();
  };

  const handlePaymentFailure = () => {
    setPaymentStatus("failure");
  };

  const componentProps = {
    email: userData.email, // Customer's email
    amount: paymentData.finalPaidAmount, // Amount in kobo (100000 kobo = 1000 NGN)
    publicKey,
    text: "Pay Now",
    onSuccess: handlePaymentSuccess,
    onClose: handlePaymentFailure,
  };

  const callPaymentGetApi = async () => {
    const updatePayment = {
      jobId: data._id,
    };
    try {
      const response = await axios.post(
        apiBaseUrl + "order/get-job-details",
        updatePayment,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("API response:", response.data);
      const data = response.data.response.result;
      setPaymentData(data);
      if (
        data.finalPaidAmount &&
        data.paymentStatus === "initialized" &&
        data.job_status === "Start"
      ) {
        setSuccess2(true);
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  useEffect(() => {
    callPaymentGetApi();
  }, []);


    
 
    const FeedbackForm  = async () => {
      
  if(formDataforRate.message){
      const headers = {
        Authorization: token,
          "Content-Type": "application/json",
      };

  
      try {
        const response = await axios.post(apiBaseUrl+"order/submit-feedback", formDataforRate, { headers });
        if(response.data.status===true){
          handleClose()
          handleShow3()
        }
        
        console.log("Response3:", response.data);
        
      } catch (error) {
        console.error("Error:", error);
      }
    }else setErrorsForRating('Please Filled Your Feedback')
  };

    const handleTextareaChange = (e) => {
      setErrorsForRating("")
      const messageValue = e.target.value;
      setFormDataforRate((prevData) => ({
        ...prevData,
        message: messageValue,
      }));
    };
  // ------------------------------------------------------------end ------------------------------------------
  // const jsonPath = process.env.PUBLIC_URL + '/images/animation_wait.json';

  const handleStarClick = (clickedRating) => {
    setRating(clickedRating);
    setFormDataforRate((prevData) => ({
      ...prevData,
      star: clickedRating,
    }));
  };

  const getUserList = async (service_id,city) => {
    const response = await axios.post(
      apiBaseUrl+'users/web-service-provider-list',
      {
        service_id:service_id,
        city:city
      },
      {
        headers: {
          key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
          'Content-Type': 'application/json'
        }
      }
    );
    return response?.data?.response?.result
  }

  const handleReorder= async (jobItem)=>{
    setSessionForAddress('pid', jobItem.providerData._id);
    const activeTab = jobItem.sub_service_name;
    const filteredProviders = await getUserList(jobItem?.service_type?.serviceData,jobItem?.providerData?.city);
    const item = jobItem.providerData;
    ('/provider_profile' , {state:{item,filteredProviders,activeTab }});
  }
    // -----------------------------------------------mouse scroll----------------------------


    const [isMouseDown, setIsMouseDown] = useState(false);
    const [startX, setStartX] = useState(null);
    const [scrollLeft, setScrollLeft] = useState(0);
  
    const handleMouseDown = (e, scrollRef) => {
      setIsMouseDown(true);
      setStartX(e.pageX - scrollRef.current.offsetLeft);
      setScrollLeft(scrollRef.current.scrollLeft);
    };
  
    const handleMouseUp = () => {
      setIsMouseDown(false);
    };
  
    const handleMouseMove = (e, scrollRef) => {
      if (!isMouseDown) return;
      const x = e.pageX - scrollRef.current.offsetLeft;
      const walk = (x - startX) * 3; // Adjust the multiplier for faster/slower scrolling
      scrollRef.current.scrollLeft = scrollLeft - walk;
    };
    // ----------------------------------------------end-----------------------------------------
  return (
    <>
      <Header />
      <div className="dashboard" id="order_details_dashboard">
        <div className="container">
          <div className="row mb_view_center">
            <UserDashboard />
            <div className="col-md-9">
            <div className="row">
                    <div className="col-ms-12">
                        <h1 className="my_order">My Orders </h1>
                        <h2 className="order_details">Order Details</h2>
                    </div>
                </div>
              <div className="right_side  mt-3">
       
  {jobDetails && jobDetails?.job_status!=="Reject"?
  <div className="btn_es_align  mb-0">
    <button className="arrow_down " onClick={()=>{navigate(-1)}}>
  <img
    src={
      process.env.PUBLIC_URL +
      "/vendor_dashboard/order_detail/order_details.png"
    }
  />

</button>
  <div className="reschudles" id="reschedule_order">
    {jobDetails?.job_status === "Complete" ? (

    
      <>
      {/* <div  className={!checkBlur ? "pop_up_model" : "blur-effect pop_up_model"}> */}
        <button className={!jobDetails?.userFeedback? "button button_reschdule" : "button button_reschdule blur-effect"}    disabled={isButtonDisabled}>
      Rate
        </button>
        <button className={!jobDetails?.userFeedback? "button button_reschdule--hover" : "button button_reschdule--hover blur-effect"} 
          onClick={handleShowRate}
           disabled={isButtonDisabled}
        >
      Rate
        </button>
      </>
    ) : jobDetails?.job_status==='Start'|| jobDetails?.job_status==='Accept' ? "" :(
      <>
        <button className="button button_reschdule">
          Reschedule
        </button>
        <button
          className="button button_reschdule--hover"
          onClick={handleShow}
        >
          Reschedule
        </button>
      </>
    )}
  </div>

  <div className="cancel_book">
  {jobDetails && jobDetails?.job_status === "Complete"  ? 
    <div onClick={()=>{handleReorder(jobDetails)}} >
      {" "}
      <button className="button button_cancel_book">
       Re-Order
      </button>
      <button className="button button_cancel--hover">
      Re-Order
      </button>
    </div> : 
    jobDetails?.job_status==='Start'|| jobDetails?.job_status==='Accept' ? ""
     :
     <Link to={`/user/Order_cancel/${jobDetails?._id}`}>
      {" "}
      <button className="button button_cancel_book">
        Cancel Booking
      </button>
      <button className="button button_cancel--hover">
        Cancel Booking
      </button>
    </Link>
    }
  </div>
</div>
:  <button className="arrow_down " onClick={()=>{navigate(-1)}}>
  <img
    src={
      process.env.PUBLIC_URL +
      "/vendor_dashboard/order_detail/order_details.png"
    }
  />

</button>}
              
                  <Row className="mb_view_center" mx={0}>
                    <Col md={8}>
               
                    

                    <div className="vertification_code">
                      <p className="">Verification Code</p>
                      <div className="numberes">
                {[...verificationCode].map((char, index) => (
                    <input key={index} type="text" placeholder={char} />
                ))}
            </div>
                    </div>
                    <div className="card_details">
                      <div className="card_heading">
                        <h1>Service Location</h1>
                      </div>
                      <div className="card_content">
                        <span>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "../images/card_location.png"
                            }
                            alt="app"
                          />

                          <p>
                            {" "}
                            {jobDetails?.service_location?.land_mark},
                            {jobDetails?.service_location?.address1} ,{" "}
                            {jobDetails?.service_location?.city} ,
                            {jobDetails?.service_location?.state} ,
                            {jobDetails?.service_location?.postal_code} ,
                            {jobDetails?.service_location?.country}
                          </p>
                        </span>
                      </div>
                    </div>
                    <div className="card_details">
                      <div className="card_heading">
                        <h1>Selected Provider</h1>
                      </div>
                      <div className="card_content">
                        <div className="select_provider">
                          <div>
                            <img
                              src={
                                BaseUrl+
                                jobDetails?.providerData?.profile_pic
                              }
                              alt="app"
                              className="worker_img"
                            />{" "}
                          </div>
                          <div className="select_provider_content">
                            <h1>{jobDetails?.providerData?.fullName} </h1>
                            <p>
                              {" "}
                              {jobDetails?.providerData?.land_mark},
                              {jobDetails?.providerData?.address1} ,{" "}
                              {jobDetails?.providerData?.city} ,
                              {jobDetails?.providerData?.state} ,
                              {jobDetails?.providerData?.postal_code} ,
                              {jobDetails?.providerData?.country}
                            </p>
                          </div>

                          <div>
                            <span>
                              <img
                                src={
                                  process.env.PUBLIC_URL + "../images/Star.png"
                                }
                                className="star_img"
                              />
                              <p>{jobDetails?.providerData.averageRating?jobDetails?.providerData.averageRating:"0.0"}</p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card_details">
                      <div className="card_heading">
                        <h1>Slot Date & Timing</h1>
                      </div>
                      <div className="card_content">
                        <ul>
                          <li>
                            <span>Service Date</span> <p>{jobDetails?.service_date}</p>
                          </li>
                          <li>
                            <span>Service Timing</span>{" "}
                            <p>{jobDetails?.service_time}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card_details mb-2">
                      <div className="card_heading">
                        <h1>Service Detail</h1>
                      </div>
                      <div className="card_content">
                        <h2>{jobDetails?.service_name},{jobDetails?.sub_service_name} </h2>
                        <p className="my-2">
                         {jobDetails?.job_desc}
                        </p>
                        {jobDetails?.jobImage?    <div className="plumbering_img">
                          <img
                            src={BaseUrl+jobDetails?.jobImage  }
                            alt="service_image"
                          />
                       
                        </div>:"" }
                     
                      </div>
                    </div>
             
                    </Col>
               
                 <Col md={4}>
              
                    <div className="payment_details ">
                      <h1>Payment Detail</h1>
                      <ul className="mt-2">
                        <li className="pt-0">
                          <p>Total Amount</p> <span>₦ {jobDetails?.amount}</span>
                        </li>
                        <li>
                          <p>Discount</p>{" "}
                          <span className="text_change_color">
                            ₦{" "}
                            {jobDetails?.discount_amount ? jobDetails?.discount_amount : "0"}
                          </span>
                        </li>
                        <li>
                          <p>Other Charges</p> <span>Free</span>
                        </li>
                        <li className="pb-0 bottom_none">
                          <p className="text_change_color">Amount Paid</p>{" "}
                          <span className="text_change_color">
                            ₦ {jobDetails?.paidamount}
                          </span>
                        </li>
                      </ul>
                    </div>

                    {jobDetails?.userFeedback?
                    <>
                    <div className="payment_details  mt-4">
                      <h1>Feedback</h1>
                      </div>
                     <div className="feedback_reviwes">
                        <div className="review_header">
                            <div className="rating">
                                <div className="rating_left_side">
                                
                                {Array.from({ length: maxRating }).map((_, index) => (
        <FontAwesomeIcon
          key={index}
          icon={faStar}
          style={{ color: index < jobDetails?.userFeedback?.star ? "#FFCC00" : "grey" }}
        />
      ))}
                                  
                                </div>
                                <div className="rating_right_side">
                                <p>{timeDifference(jobDetails?.userFeedback?.feedbackAt)} </p>
                            </div>
                            </div>
                          
                        </div>
                        <div className="review_content">
                       <p>{jobDetails?.userFeedback?.message}</p>
                          </div>
                        <div className="reviw_inner_content">
                        <div>
                        {/* <img src={process.env.PUBLIC_URL + "../images/worker.png"} alt="app"/> */}
                         </div>
                        {jobDetails?.userFeedback?.reply?           <div className="review_content">
                                    <h1 id="paraH1">{jobDetails?.providerData?.fullName}</h1>  
                                <p id="paraP"> {jobDetails?.providerData?.address1} </p>
                        <p id="parap2">{jobDetails?.userFeedback?.reply}</p>
                        </div>:""}
                                
                     
                          <div>
                            {/* <span className="reviews_star"><img src={process.env.PUBLIC_URL + "../images/Star.png"} className="star_img"/><p>4.6</p></span> */}
                        </div>
                     </div>
            
                    </div>
                    </>
                    :""}
              
                 </Col>
           
                    </Row>
               
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid other_details px-0">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-9 px-0">
            <h1 className="other_Details"> Other Orders</h1>
            <div className="dash_slider">
              <Dashbordslider />
            </div>
          </div>
        </div>
      </div> */}

      <section className="mb-0">
        <div className="download_aap">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <img
                  src={process.env.PUBLIC_URL + "../app_img.png"}
                  alt="app"
                  className="app_img"
                />
              </div>
              <div className="col-md-6 pt-4">
                <h1>Download the app</h1>
                <p>
                  Get the app. Get things done. Compare prices, read reviews and
                  book top-rated services. All in one free app.
                </p>
              </div>
              <div className="col-md-3">
                <ul>
                  <li>
                    {" "}
                    <img
                      src={process.env.PUBLIC_URL + "../androied.png"}
                      alt="app"
                      className="app_img"
                    />{" "}
                  </li>
                  <li>
                    {" "}
                    <img
                      src={process.env.PUBLIC_URL + "../ios.png"}
                      alt="app"
                      className="app_img"
                    />{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal show={show} className="join_model">
        <Modal.Body className="pt-0">
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div className="">
            <h4 className="assign_title pt-3 pb-3">Assign work to provider</h4>

            <div className="get_location_slot d-flex justify-content-between">
              <div>
                <h2>Service Location</h2>
                <p>
                  {" "}
                  {latestAddress ? (
  <>
    {latestAddress?.land_mark}, {latestAddress?.address1}, {latestAddress?.city}, {latestAddress
    ?.state}, {latestAddress?.postal_code}, {latestAddress?.country}
  </>
) : (
  <>
    {jobDetails?.service_location.land_mark}, {jobDetails?.service_location.address1}, {jobDetails?.service_location.city}, {jobDetails?.service_location.state}, {jobDetails?.service_location.postal_code}, {jobDetails?.service_location.country}
  </>
)}

                
                </p>
              </div>
              <div className="slot_change_address" onClick={()=>{handleShow2(); handleClose()}}>
                <p>Change Address</p>
              </div>
            </div>

            <div className="d-flex justify-content-between date-picked">
              <p>Service Date</p>
            </div>

            <div className="calender">
              <div className="calender-header d-flex justify-content-around mb-4">
                <img
                  src={process.env.PUBLIC_URL + "/images/arrow_right.png"}
                  alt=""
                  onClick={preMOnth}
                  id="calender-year-arrow"
                />
                {/* <span> August , 2023</span> */}
                <img
                  src={process.env.PUBLIC_URL + "/images/arrow_left.png"}
                  alt=""
                  id="calender-year-arrow"
                />
              </div>
              <ul
                className="d-flex justify-content-between mt-2 border_date"
                id="scroll-control"
                ref={scrollControlRef1}
                onMouseDown={(e) => handleMouseDown(e, scrollControlRef1)}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onMouseMove={(e) => handleMouseMove(e, scrollControlRef1)}
              >
                {dates.map((date, index) => (
                  <li
                    key={index}
                    className={selectedItem === index ? "activDate" : ""}
                    onClick={() => handleDateClick(index, date.date)}
                  >
                    <p>{date.day}</p>
                    <h1>{date.date.slice(0, 2)}</h1>
                  </li>
                ))}
              </ul>
              {formErrors.selectedDate && (
                <div className="error">{formErrors.selectedDate}</div>
              )}

              <p id="service-time">Service Time</p>

              <ul
                className="d-flex justify-content-between  calendar_content border_date time-pick"
                id="scroll-control"
                ref={scrollControlRef2}
onMouseDown={(e) => handleMouseDown(e, scrollControlRef2)}
onMouseUp={handleMouseUp}
onMouseLeave={handleMouseUp}
onMouseMove={(e) => handleMouseMove(e, scrollControlRef2)}
              >
      {times.map((time, index) => (
        <li
          key={index}
          className={selectedTimeItem === index ? "activDate" : ""}
          onClick={() => selectedTime(index, formatTime(time).split(" ")[0]+(' ')+formatTime(time).split(" ")[1])}
        >
          <h2>{formatTime(time).split(" ")[0]}</h2>
          <p className="mb-0">{formatTime(time).split(" ")[1]}</p>
        </li>
      ))}
    </ul>
            </div>

            <div className="row">
              {/* <div className="col-12">
      <div className="form_group">
        <div className="custom-select-wrapper">
        <select
  className={`custom-select ${
    formErrors.service_type ? "VendorInputError" : ""
  }`}
  name="service_type"
  onChange={handleChange}
  value={formData.service_type}
>
            <option>Select Service Type</option>
            <option>Home Cleaning</option>

          </select>
          <img
            src={process.env.PUBLIC_URL + "/images/user/selectArrow.png"}
            alt="arrow"
            className="arrow-image"
          />
        </div>
        {errors.state && <div className="error">{errors.state}</div>}
      </div>
    </div>

    <div className="col-12">
    <div className="form_group">
<textarea
  className={`form-control ${
    formErrors.job_desc ? 'VendorInputError' : ''
  }`}
  name="job_desc"
  onChange={handleChange}
  value={formData.job_desc}
  rows={4}
></textarea>
{formErrors.job_desc && <div className="error">{formErrors.job_desc}</div>}
</div>
</div> */}
              <div
                class="button-container"
                style={{ width: "90%", marginLeft: "6%" }}
              >
                <button className="button button1" onClick={handleSubmit}>
                  Proceed To Book
                </button>
                <button className="button button2" onClick={handleSubmit}>
                  Proceed To Book
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Body>
          <div
            className=""
            onClick={() => {
              handleClose2();
              handleShow();
            }}
            style={{ position: "absolute", right: "21px", top: "7px" }}
          >
            <img
              src={process.env.PUBLIC_URL + "../images/cross.png"}
              alt="app"
              className="cross_icon"
            />
          </div>
          <h6>Select your location</h6>{" "}
          <div className="row mx-0 mt-3 mb-2 modal_size">
            {data2.map((item, index) => (
              <div className="col-6 mb-3" key={index}>
                <div className="address_details">
                  <div className="address_card">
                    <input
                      type="radio"
                      onClick={() => handleRadioClick(item)}
                      checked={selectedItemId === item._id}
                    />
                    <span>
                      <button className="addres_num">
                        Address {index + 1}
                      </button>
                    </span>
                  </div>
                  <p>
                    {item.land_mark}, {item.address1}, {item.city}, {item.state}
                    , {item.postal_code}, {item.country}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={success2}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created  well_done"
            style={{ marginTop: "0px" }}
          >
            {/* <img
                src={process.env.PUBLIC_URL + "../images/greeting.gif"}
                alt="playstore" className="gif"
              /> */}
            <Lottie options={lottieOptions} />

            <div>
              {" "}
              <h2> Payment Requested: ₦ {paymentData.finalPaidAmount}</h2>
              <p>Please Pay By Clicking Button Given Below</p>
            </div>

            <div class="button-container" style={{ width: "150px" }}>
              <button
                className="button button1"
                onClick={() => {
                  <PaystackButton {...componentProps} />;
                }}
              >
                {" "}
                Pay Now
              </button>
              <PaystackButton {...componentProps} className="button button2" />
            </div>
          </div>
        </Modal.Body>
      </Modal>
{/* feedback popup */}
<Modal show={showRate} onHide={handleClose} id="model_rating" className=" slideInUp">
    <div className="model_header">
        <div className="header_viewes">
            <h1>Your Valuable Feedback</h1>
        </div>
         <div className="">
         <img src={process.env.PUBLIC_URL + "../images/model_cross.png"} alt="app" className="cross_icon" onClick={handleClose} />
         </div>
        </div>
         
        <Modal.Body className="py-0">
          <Form>
            <div className="row mx-0">
               
                <div className="col-md-12">
                    <div className="text_rating">
                    <p>Rate Us</p>
      <div className="multiple_stars_content">
        <ul>
          {[1, 2, 3, 4, 5].map((index) => (
            <li
              key={index}
              onClick={() => handleStarClick(index)}
            >
              <FontAwesomeIcon
                icon={faStar}
                className={index <= rating ? "star_yellow" : "star"}
              />
            </li>
          ))}
        </ul>
      </div>
      <textarea
        name="message"
        placeholder="Feedback message"
        value={formData.message}
        onChange={handleTextareaChange}
      />
      {errorsForRating? <p className="error" id="error_feedback">{errorsForRating}</p> :""}
                     </div> 
                </div>
                
            </div>
           
          </Form>
        </Modal.Body>
        <Modal.Footer >
            <div className="model_submit" onClick={FeedbackForm}>
          <button  className=" button submit_close">Submit</button>
          <button  className="button submit_close_hover">Submit</button>
          </div>
        </Modal.Footer>
      </Modal>
      <Footer />
      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
        size="sm" // Add this line to set the size to small (sm)
        centered 
      >
        <div className="cross-X" onClick={()=>{handleClose3();        window.location.reload();}} style={{position:"absolute" , right:"0", }}>
            <img
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>
<Modal.Body>
Thanks For Your Feedback
</Modal.Body>
<Modal.Footer>
<div className="proceed_next mt-4 mb-4">
 

          <div class="button-container proccess_next mt-4 mb-4 mr-0" ><button class="button button1"  onClick={()=>{ window.location.reload()}}> Proceed To Next</button><button class="button button2" onClick={ ()=>{window.location.reload()}} > Proceed To Next</button></div>
          </div>
</Modal.Footer>
</Modal>
<Modal show={show6}
         onHide={handleClose6}
          centered
          style={{
            width: "351px",
            marginLeft: "40%",
            borderRadius: "34px !important",
          }}
          ClassName="custom-modal-dialog"
        >
          <Modal.Body>
            <div className="cross-X" onClick={handleClose6}>
            <img
                src={process.env.PUBLIC_URL + "/images/user/cross.png"}
                alt="playstore"
              />
              <img
                src={process.env.PUBLIC_URL + "/images/user/X.png"}
                alt="playstore"
              />
            </div>
          

            <div className="congrats-profile-created  well_done" style={{marginTop:"0px"}}>
            <img
                src={process.env.PUBLIC_URL + "/images/greeting.gif"}
                alt="playstore" className="gif"
              />
        
          <h2>Service Reschedule Updated!</h2> 
         {/* <p>Please Continue Your Work</p> </>:<h2>Job Rejected!</h2>  */}
       
         
              
              <div class="button-container" style={{ width: "150px" }} onClick={()=>{window.location.href = "/user_dashboard/order_history"}}>
                <button className="button button1" >  Proceed To Next
               </button>
                <button className="button button2">
      Proceed To Next
             
                </button>
              </div>
          
            </div>
          </Modal.Body>
        </Modal> 
    </>
  );
};

export default Order_details;
