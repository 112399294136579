import "./privacy.css";

import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';
import Footer from "../../components/footer";
import Header from "../../components/header";
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { apiBaseUrl } from "../../config/constant";
const Faq = () => {
  const p_faqs = JSON.parse(localStorage.getItem('page_faqs'));

    const [faqData, setFaqData] = useState(p_faqs?p_faqs:[]);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            apiBaseUrl+'common/web-get-helpsafety-page-data',
            {
              headers: {
                key: 'd1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed',
                'Content-Type': 'application/json',
              },
            }
          );  
          setFaqData(response.data.response.result.pageheadings);
          localStorage.setItem('page_faqs', JSON.stringify(response?.data?.response?.result?.pageheadings))
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchData();
    }, []);
  
    return (
      <>
        <Header />
        <div className="container" id="faqMobile">
          <div className="row">
            <div className="col-md-12">
              <div className="privacy">
                <h1 className="mb-4 faq_heading">FAQ for the Handyman Marketplace App</h1>
                <div id='gfg'>
                <MDBAccordion>
  {faqData[0]?.heading.map((faq, index) => (
    <React.Fragment key={index}>
      <MDBAccordionItem
        collapseId={`gfg${index + 1}`}
        headerTitle={faq?.title ?? 'Title Not Available'}
   
      >
        <p>{faq?.description ?? 'Description Not Available'}</p>
      </MDBAccordionItem>
    </React.Fragment>
  ))}
</MDBAccordion>


                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  };
  
  export default Faq;
