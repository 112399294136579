import Header from "../../components/header";
import Footer from "../../components/footer";
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getSession } from "../../utils/helper";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { EditWorkerSchema } from "../../components/validators/vendorFormValidator";
import { BaseUrl, apiBaseUrl } from "../../config/constant";
import { getCity, getCountry, getSate } from "../../components/common";

const EditWorker = () => {
  const imageInputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const token = getSession("token");
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [checkBlur, setBlur] = useState(false);
  const [checkBlur2, setBlur2] = useState(false);
  const [show, setShow] = useState(false);
  const [services, setServices] = useState([]);
  const [errors, setErrors] = useState("");
  const [selectedServiceData, setSelectedServiceData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(false);
  const [state, setState ]=useState([]);
  const [city, setCity]=useState([])
  const [country, setCountry] = useState([])

  const handleClose = () => {
    setShow(false);
    navigate("/vendor/company-workers");
  };
  const handleShow = () => setShow(true);

  const profileDetailsHandler = () => {
    if (!isSectionVisible) {
      setSectionVisible(true);
      setBlur(false);
    } else {
      setBlur(true);
    }

    if (isSectionVisible && checkBlur) {
      setBlur2(true);
      if (checkBlur2) {
        handleShow();
      }
    }
  };

  //   form data field

  const certificateImage = new File([""], "profile_pic.png", {
    type: "image/png",
  });
  const [formData, setFormData] = useState({
    workerId: data._id,
    fullName: data.fullName,
    email: data.email,
    phone: data.phone,
    country: data.country,
    state: data.state,
    city: data.city,
    address1: data.address1,
    address2: data.address2,
    land_mark: data.land_mark,
    postal_code: data.postal_code,
    platform: "Web",
    profile_pic: data.profile_pic,
  });

  // formData for service field

  const [formServiceData, setFormServiceData] = useState({
    workerData: "",
    services: [], // Initialize as an empty array
  });

  // end
  // end form data field
  // Fetch API

  const fetchData = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl + "provider/edit-worker",
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  // end fetch api
  // form handle change
  const handleChange = async (e) => {
    const { name, value } = e.target;
    if(name==='country'){
      const jsonObject = JSON.parse(value);
      const country= await  getSate(jsonObject.id);
      setState(country)
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: jsonObject.name,
      }));
    }else if(name==='state'){
      const jsonObject = JSON.parse(value);
      const state= await  getCity(jsonObject.id);

setCity(state)
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: jsonObject.name,
      }));
    }else if(name==='city'){
      const jsonObject = JSON.parse(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: jsonObject.name,
      }));
    }else if(name==='email'){
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setSuccess((prevSuccess) => ({
        ...prevSuccess,
        email: "", 
      }));
      const checkEmail = String(value).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      if(checkEmail){
        await checkDuplicateEmail(value);
      } else{
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear the error message for the corresponding field
        }));
      }
    }else if(name==='phone'){
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setSuccess((prevSuccess) => ({
        ...prevSuccess,
        phone: "", 
      }));

      const checkPhone = value.length > 9;
      if(checkPhone){
        await checkDuplicatePhone(value);
      } else{
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear the error message for the corresponding field
        }));
      }

    } else{
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Clear the error message for the corresponding field
      }));
  }
};
  // end form handle change
  // handle form Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    EditWorkerSchema.validate(formData, { abortEarly: false })
      .then(() => {
        fetchData()
          .then((data) => {
            console.log(data);
            if (data.status === true) {
              handleShow();
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      })
      .catch((validationErrors) => {
        const newErrors = {};
        validationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        const isFullNameValid = !newErrors.fullName;
        const isPhoneValid = !newErrors.phone;
        const isdocumentTypeValid = !newErrors.documentType;
        const isdocumentNoValid = !newErrors.documentNo;

        if (
          isFullNameValid &&
          isdocumentTypeValid &&
          isPhoneValid &&
          isdocumentNoValid
        ) {
          profileDetailsHandler();
        }

        // Update the state with the new errors
        setErrors(newErrors);
      });
  };
  // end handle form submit

  // profile Image Capture
  const [selectedImage, setSelectedImage] = useState(null);

  const handleCaptureButtonClick = () => {
    imageInputRef.current.click();
  };

  const handleImageSelect = (event) => {
    const file = event.target.files[0];

    setFormData((prevFormData) => ({
      ...prevFormData,
      profile_pic: file,
    }));
    setSelectedImage(file);
  };

  //  ///////////////////////////////////// upload image of Work //////////////////////

  const handleImageGallery = (event) => {
    const file = event.target.files[0];
    const name = event.target.name;
    const reader = new FileReader();

    // const [galleryImgPre, setGalleryImgPre] = useState({})
    reader.onloadend = () => {
      // After reading the file, set it as the image source
      setGalleryData((prevFormData) => ({
        ...prevFormData,
        [name]: file,
      }));
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      // If no file selected, set the property to null or any default value you want
      setGalleryData((prevFormData) => ({
        ...prevFormData,
        [name]: null,
      }));
    }
  };
  const [galleryData, setGalleryData] = useState({
    worker_id: "",
    services: "",
    before_work_pic: "",
    after_work_pic: "",
    user_type: "worker",
  });

  const postImageData = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl + "provider/worker-service-gallary",
        galleryData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const countries = await getCountry();
        setCountry(countries);
      } catch (error) {
        // Handle any errors here
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  // ///////////////////////////////////// ENd //////////////////////////////////////////////////////////////

  return (
    <>
      <Header />
      {/* 
      <div className="dashboard mt-4">
        <div className="container">
          <div className="row mx-0 worklist_dashboard"> */}
      {/* <DashboardAsVendor /> */}
      {/* <div className="col-md-9"> */}
      <div className="create-profile">
        <div className="form_book_appintment">
          <div
            className="d-flex justify-content-between"
            style={{ height: "70px" }}
          >
            <p className="fill_form"> Update Your Workers’s Details.</p>

            <div className="add-pro-img">
              {selectedImage && selectedImage ? (
                <div
                  className="image-container"
                  onClick={handleCaptureButtonClick}
                >
                  <img
                    className="circle-img"
                    src={process.env.PUBLIC_URL + "/images/user/circle.png"}
                    alt="profile-add"
                  />
                  <div className="overlay">
                    {selectedImage && (
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        alt="Selected"
                        id="circle_uploaded_image"
                      />
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={`image-container ${
                      errors.profile_pic ? "VendorInputError" : ""
                    }`}
                    onClick={handleCaptureButtonClick}
                  >
                    <img
                      className="circle-img"
                      src={ formData?.profile_pic?BaseUrl + formData.profile_pic: "https://doqwik.ng/images/avatar-default-icon.png"}
                      alt="profile-add"
                      id="circle_uploaded_image"
                      style={{ margin: "-0.8px" }}
                    />
                    {/* <div className="overlay">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/user/addprofile.png"
                                }
                                alt="overlay"
                              />
                            </div> */}
                  </div>
                  {errors.profile_pic && (
                    <div
                      className="error"
                      style={{ position: "absolute", top: "40px" }}
                    >
                      {errors.profile_pic}
                    </div>
                  )}
                </>
              )}
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageSelect}
                ref={imageInputRef}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 col-lg-3">
              <div
                className={`form_group ${
                  errors.fullName ? "VendorInputError" : ""
                }`}
              >
                <input
                  type="text"
                  onChange={handleChange}
                  placeholder="Full Name"
                  name="fullName"
                  id="name"
                  value={formData.fullName}
                  oninput="allow_alphabets(this)"
                />
              </div>
              {errors.fullName && (
                <div className="error">{errors.fullName}</div>
              )}
            </div>
            <div className="col-md-4 col-lg-3">
              <div
                className={`form_group ${
                  errors.email ? "VendorInputError" : ""
                }`}
              >
                <input
                  type="text"
                  onChange={handleChange}
                  placeholder="Email Address"
                  value={formData.email}
                  name="email"
                  id="email"
                />
              </div>
              {errors.email && <div className="error">{errors.email}</div>}
            </div>
            <div className="col-md-4 col-lg-3">
              <div
                className={`form_group ${
                  errors.phone ? "VendorInputError" : ""
                }`}
              >
                <input
                  type="number"
                  onChange={handleChange}
                  placeholder="Mobile Number"
                  maxlength="10"
                  minlength="10"
                  name="phone"
                  value={data.phone}
                  id="phone"
                  onkeypress="return onlyNumberKey(event)"
                />
              </div>
              {errors.phone && <div className="error">{errors.phone}</div>}
            </div>
            <div className="col-md-4 col-lg-3">
            <div className="form_group">
                <div className="custom-select-wrapper">
                  <select className={`custom-select ${errors.city ? "VendorInputError" : ""}`} name="country" onChange={handleChange} >
                    <option>Select Country</option>
                    {country.map((item, index)=>(
                      <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                    ))}
                  </select>
                  <img src={ process.env.PUBLIC_URL + "/images/user/selectArrow.png"} alt="arrow" className="arrow-image" />
                </div>
                {errors.country && (<div className="error">{errors.country}</div>)}
              </div>
            </div>

            <div className="col-md-4 col-lg-3">
            <div className="form_group">
                <div className="custom-select-wrapper">
                  <select className={`custom-select ${errors.city ? "VendorInputError" : ""}`} name="state" onChange={handleChange} >
                    <option>Select State</option>
                    {state.map((item, index)=> (           
                      <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                    ))} 
                  </select>
                  <img src={process.env.PUBLIC_URL +"/images/user/selectArrow.png"}alt="arrow" className="arrow-image"/>
                </div>
                {errors.state && (<div className="error">{errors.state}</div>)}
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
            <div className="form_group">
                <div className="custom-select-wrapper">
                  <select className={`custom-select ${errors.city ? "VendorInputError" : ""}`} name="city" onChange={handleChange} >
                    <option>Select City</option>
                    {city.map((item, index)=>(
                      <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                    ))}
                  </select>
                  <img src={process.env.PUBLIC_URL +"/images/user/selectArrow.png"} alt="arrow" className="arrow-image" />
                </div>
                {errors.city && (<div className="error">{errors.city}</div>)}
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div
                className={`form_group ${
                  errors.land_mark ? "VendorInputError" : ""
                }`}
              >
                <input
                  type="text"
                  onChange={handleChange}
                  placeholder="Landmark"
                  name="land_mark"
                  id="name"
                  value={formData.land_mark}
                  oninput="allow_alphabets(this)"
                />
              </div>
              {errors.land_mark && (
                <div className="error">{errors.land_mark}</div>
              )}
            </div>
            <div className="col-md-6 col-lg-3">
              <div
                className={`form_group ${
                  errors.postal_code ? "VendorInputError" : ""
                }`}
              >
                <input
                  type="number"
                  onChange={handleChange}
                  placeholder="Postal Code"
                  maxLength={6}
                  name="postal_code"
                  id="postal_code"
                  value={formData.postal_code}
                  onkeypress="return onlyNumberKey(event)"
                />
              </div>
              {errors.postal_code && (
                <div className="error">{errors.postal_code}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div
                className={`form_group ${
                  errors.address1 ? "VendorInputError" : ""
                }`}
              >
                <input
                  type="text"
                  onChange={handleChange}
                  placeholder="Address Line 1 "
                  name="address1"
                  id="address1"
                  value={formData.address1}
                  oninput="allow_alphabets(this)"
                />
              </div>
              {errors.address1 && (
                <div className="error">{errors.address1}</div>
              )}
            </div>

            <div className="col-md-6">
              <div
                className={`form_group ${
                  errors.address2 ? "VendorInputError" : ""
                }`}
              >
                <input
                  type="text"
                  onChange={handleChange}
                  placeholder="Address Line 2"
                  name="address2"
                  id="address2"
                  value={formData.address2}
                  oninput="allow_alphabets(this)"
                />
              </div>
              {errors.address2 && (
                <div className="error">{errors.address2}</div>
              )}
            </div>
          </div>
        </div>

        <div className="">
          <></>
        </div>
        {/* Varification section */}
        {/* {isSectionVisible && ( */}

        {checkBlur2 && (
          <div
            class="button-container mt-4 mb-4"
            style={{ width: "190px", float: "left" }}
          >
            <button class="button button1" id="btn-otp-resend">
              Edit Details
            </button>
            <button class="button button2">Edit Details</button>
          </div>
        )}
        <div
          className="button-container mt-4 mb-4"
          style={{ marginRight: "0" }}
        >
          <button className="button button1" onClick={handleSubmit}>
            {" "}
            Proceed To Next
          </button>
          <button className="button button2" onClick={handleSubmit}>
            {" "}
            Proceed To Next
          </button>
        </div>
      </div>
      {/* </div>
          </div>
        </div>
      </div> */}

      <section className="mb-0">
        <div className="download_aap">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <img
                  src={process.env.PUBLIC_URL + "../app_img.png"}
                  alt="app"
                  className="app_img"
                />
              </div>
              <div className="col-md-6 pt-4">
                <h1>Download the app</h1>
                <p>
                  Get the app. Get things done. Compare prices, read reviews and
                  book top-rated services. All in one free app.
                </p>
              </div>
              <div className="col-md-3">
                <ul>
                  <li>
                    {" "}
                    <img
                      src={process.env.PUBLIC_URL + "../androied.png"}
                      alt="app"
                      className="app_img"
                    />{" "}
                  </li>
                  <li>
                    {" "}
                    <img
                      src={process.env.PUBLIC_URL + "../ios.png"}
                      alt="app"
                      className="app_img"
                    />{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      <Modal
        show={show}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>
          <img
            src={process.env.PUBLIC_URL + "/images/user/cong.gif"}
            alt="playstore"
            className="cong-done-json"
            style={{ width: "150px" }}
          />

          <div className="congrats-profile-created">
            <h6>
              Congratulations
              <br />
              <span> Worker has added!</span>
            </h6>

            <div class="button-container" style={{ width: "200px" }}>
              <button className="button button1">Proceed To Next</button>
              <button className="button button2">
                <Link
                  to="/vendor/company-workers"
                  style={{ textDecoration: "none", color: "#ffffff" }}
                >
                  {" "}
                  Proceed To Next{" "}
                </Link>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created  well_done"
            style={{ marginTop: "0px" }}
          >
            <img
              src={process.env.PUBLIC_URL + "../images/greeting.gif"}
              alt="playstore"
              className="gif"
            />

            <h2>Profile Updated!</h2>
            {/* <p>Please Continue Your Work</p> </>:<h2>Job Rejected!</h2>  */}

            <div
              class="button-container"
              style={{ width: "150px" }}
              onClick={handleClose}
            >
              <button className="button button1"> Proceed To Next</button>
              <button className="button button2">Proceed To Next</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditWorker;
