import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link, useNavigate } from "react-router-dom";

import "../DashboardVendor/vendor.css";
import { setSession, getSession } from "../../utils/helper";
import LeftnavbarIndividual from "../../components/leftnavbaraIndividual";
import "./individual.css";
import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Alert } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiBaseUrl } from "../../config/constant";
import animationData from "../../animation_wait.json";
import { validationSchema } from "../../components/validators/paymentFormValidator";
import { validateJobStartDateAndTime } from "../../components/common";

const IndividualOrderHistory = () => {
  const token = getSession("token");
  const [activeButton, setActiveButton] = useState("");
  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
  const inputRefs = otpDigits.map(() => useRef(null));
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();
  const [reject_id, setreject_id] = useState(null);
  const [success, setsuccess] = useState(false);
  const [paymentpopup, setpaymentpopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [locationName, setLocationName] = useState("");
  const [textareaValue, setTextareaValue] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");
  const [errors, setErrors] = useState({ amount: "", payment_option: "" });
  const [amount, setAmount] = useState();
  const [success2, setSuccess2] = useState(false);
  const [processid, setId_process] = useState({
    jobId: "",
    rejectReason: "",
  });
  const [payment_selection, setPaymentSelection] = useState({
    id: "",
    paymentStatus: "",
    amount: "",
    discount: "",
    paidAmount: "",
  });
  console.log(payment_selection,"----")
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const notify = () => {
    toast.success("Job Added", {
      position: "top-right", // You can customize the position
      autoClose: 3000, // Time duration in milliseconds
      hideProgressBar: false, // Display a progress bar
      closeOnClick: true, // Close the notification on click
      pauseOnHover: true, // Pause the timer on hover
      draggable: true,
    });
  };
  const [Verifyotp, setverifyotp] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [data, setData] = useState("");
  const [jobStartTimeError, setJobStartTimeError] = useState(false);

  // const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
  const [jodid, setjobid] = useState({
    jobId: "",
    code: "",
  });
  const [uploadpic, setuploadpic] = useState(false);
  const [orderHistory, setOrderHistory] = useState("");
  const [reject, setreject] = useState(false);

  const handleOtpInputChange = (index, value) => {
    const newOtpDigits = [...otpDigits];
    newOtpDigits[index] = value;
    setOtpDigits(newOtpDigits);
    if (index < otpDigits.length - 1 && value.length === 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleImageChange2 = (event) => {
    const file = event.target.files[0];
    console.log("Selected Image:", file);
    setSelectedImage2(file);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    console.log("Selected Image 2:", file);
    setSelectedImage(file);
  };

  const handleReloadClick = () => {
    window.location.reload();
  };

  const start_job = async (order) => {
    const isValidate = await validateJobStartDateAndTime(order);
    console.log(isValidate,"---")
    if(isValidate.status ===true ){
      setjobid(order._id);
      setShow(true);
    }else{
      setJobStartTimeError(true);
    }
  };

  const handleUpload = () => {
    setverifyotp(true);
    setuploadpic(false);
  };
  const handleClose = () => {
    setShow(false);
    setSuccess2(false);
    setverifyotp(false);
    setuploadpic(false);
    setPaymentSelection(false);
    setsuccess(false);
    setreject(false);
    setpaymentpopup(false);
    setJobStartTimeError(false);
  };
  const Uploadingfunction = () => {
    setuploadpic(true);
    setShow(false);
  };

  const finishjob_popup = (data = {}) => {
    if (data && data.paymentStatus === "Success") {
      setsuccess(true);
      setpaymentpopup(false);
    } else {
      setpaymentpopup(true);
      setsuccess(false);
    }
    setPaymentSelection({
      ...payment_selection,
      ["paymentStatus"]: data.paymentStatus,
      ["amount"]: data.amount,
      ...(data.discount
        ? { ["discount"]: data.discount }
        : { ["discount"]: 0 }),
      ["id"]: data._id,
    });
  };
  // geolocation
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
            );

            if (!response.ok) {
              throw new Error(
                "Location not found or no data available for this location."
              );
            }

            const data = await response.json();
            setLocationName(data.display_name);
          } catch (error) {
            setError(
              "Location not found or no data available for this location."
            );
          }
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            setError(
              "Geolocation access denied. Please enable geolocation in your browser settings."
            );
          } else {
            setError("Error getting geolocation: " + error.message);
          }
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  }, []);
  // job_list api
  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = getSession("token");

        const response = await axios.post(
          apiBaseUrl + "order/get-provider-job-list",
          {
            // id: id
          },
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        setData(response.data.response.result);
        setOrderHistory(response.data.response.result);
        // console.log(response.data.response.result);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  //  job_ finish api
  const Proceed_pay = async () => {
    console.log(payment_selection);
    try {
      let token = getSession("token");

      const response = await axios.post(
        apiBaseUrl + "order/job-finish",
        {
          jobId: payment_selection.id,
          updateIp: 1,
          finalPaidAmount: "",
          paymentMode: "",
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data.status);
      {
        if (response.data.status) {
          setsuccess(true);
          setpaymentpopup(false);
        } else {
          setsuccess(false);
          setpaymentpopup(true);
        }
      }
      console.log(response.data.response.result);
    } catch (error) {
      console.error(error);
    }
  };
  const accept_order = async (id) => {
    try {
      let token = getSession("token");

      const response = await axios.post(
        apiBaseUrl + "order/job-accept",
        {
          jobId: id,
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Order accepted successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      window.location.reload();
      console.log(response.data.response.result);
    } catch (error) {
      console.error(error);
    }
  };
  // job_start api fetch api data
  const handleShow = async (jodid) => {

    const formData = new FormData();
    const enteredOtp = otpDigits.join("");
    formData.append("jobId", jodid);
    formData.append("code", enteredOtp);
    formData.append("first_pic", selectedImage);
    formData.append("second_pic", selectedImage2);

    // alert(jodid);
    // try {
    //   let token = getSession("token");
    //   const response = await axios.post(
    //     apiBaseUrl + "order/job-start",
    //     formData,
    //     {
    //       headers: {
    //         Authorization: token,
    //         "Content-Type": "multipart/form-data",
    //       },
    //     }
    //   );

    //   if (
    //     response.data &&
    //     response.data.status &&
    //     response.data.status === true
    //   ) {
    //     window.location.reload();
    //     // navigate('/individual/orderhistory');
    //     setData(response.data.response.result);
    //     //console.log(response.data.response.result);
    //   } else {
    //     console.log(response.data);
    //     // alert("Job verification code is wrong. Please try again.");
    //     console.log('Job verification code is wrong. Please try again');
    //   }
    // } catch (error) {
    //   console.error(error);
    // }

  };

  // reject_api fetch data
  const reject_popup = (id) => {
    setId_process(id);
    // alert(id);
    setreject(true);
  };

  const reject_order = async (processid) => {
    //alert(processid);
    try {
      let token = getSession("token");

      const response = await axios.post(
        apiBaseUrl + "order/job-reject",
        {
          jobId: processid,
          rejectReason: textareaValue,
        },

        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      if (
        response.data &&
        response.data.status &&
        response.data.status === true
      ) {
        window.location.reload();
        // navigate('/individual/orderhistory');
        setData(response.data.response.result);
        console.log(response.data.response.result);
      } else {
        console.log(response.data);

        alert("Job verification code is wrong. Please try again.");
      }
      console.log(response.data.response.result);
    } catch (error) {
      console.error(error);
    }
  };

  const viewdetails = (order) => {
    console.log(order);
    setSelectedItem(order);
    navigate("/individual/Individualcompleteorder_detail", { state: order });
  };
  const reject_viewdetails = (order) => {
    console.log(order);
    setSelectedItem(order);
    navigate("/Individua/Individualreject_orderdetail", { state: order });
  };

  const today_viewdeatils = (order) => {
    console.log(order);
    setSelectedItem(order);
    navigate("/Individua/todaydetails", { state: order });
  };

  const accept_view = (order) => {
    setSelectedItem(order);
    navigate("/Individual/Individuallatest_detail", { state: order });
  };

  // --------------------------------------------------------------------------------------payment field

  const [updatePayment, setUpdatePayment] = useState({
    jobId: "",
    finalPaidAmount: "",
    paymentMode: "Online",
    paymentStatus: "initialized",
    updateIp: ":1",
  });

  const callPaymentGetApi = async () => {
    const updatePayment = {
      jobId: payment_selection.id,
    };
    try {
      const response = await axios.post(
        apiBaseUrl + "order/get-job-details",
        updatePayment,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("API response:", response.data);
      const data = response.data.response.result;
      // setPaymentData(data)
      // if(data.paymentStatus==="Success"){
      setSuccess2(false);
      Proceed_pay();
      // }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  useEffect(() => {
    const callPaymentUpdateApi = async () => {
      try {
        const response = await axios.post(
          apiBaseUrl + "order/payment-update",
          updatePayment,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data, "API response:");
        if (response?.data?.status === true) {
          setpaymentpopup(false);
          setSuccess2(true);
        }
      } catch (error) {
        console.error("API error:", error);
      }
    };
    callPaymentUpdateApi();
  }, [updatePayment]);
  const validateField = (fieldName, value) => {
    try {
      validationSchema.validateSyncAt(fieldName, { [fieldName]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handlePaymentChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "amount") {
      setAmount(value);
      setErrors({});
    }
    if (name === "payment_option") {
      if (value === "Cash") {
      } else if (value === "Online") {
        setSelectedPayment(value);
        setUpdatePayment((prevSelection) => ({
          ...prevSelection,
          finalPaidAmount: amount,
          jobId: payment_selection.id,
        }));
      }
    }
    // console.log(event.target.value);
  };

  const handleSubmitPayment = (event) => {
    event.preventDefault();

    validateField("amount", amount);
    validateField("payment_option", selectedPayment);

    // Additional submission logic if the form is valid
    if (!errors.amount && !errors.payment_option) {
      if (selectedPayment === "Cash") {
        Proceed_pay();
      }
      console.log("Form submitted with valid values");
    }
  };

  useEffect(() => {
    setAmount(payment_selection.amount);
  }, [payment_selection.amount]);

  return (
    <>
      <Header />
      <div className="dashboard">
        <div className="container">
          <div className="row mx-0">
            <LeftnavbarIndividual></LeftnavbarIndividual>
            <div className="col-md-12 col-lg-9">
              <div className="vendors_right_form">
                <div className="row  mx-0">
                  <div className="col-md-4 mt-2 px-2">
                    <h1 className="sub_heading">
                      Your Order Records & Details.
                    </h1>
                  </div>
                  <div className="col-md-8">
                    <ul className="user_list">
                      <li>
                        <div className="latest_container">
                          <button
                            className={`button latest_btn ${
                              activeButton === "latest_btn"
                                ? "active_latest"
                                : ""
                            }`}
                            onClick={() => handleButtonClick("latest_btn")}
                          >
                            <Link to="/individual/orderhistory">
                              Latest ( New )
                            </Link>
                          </button>
                          <button
                            className={`button latest_buttn ${
                              activeButton === "latest_buttn"
                                ? "active_latest"
                                : ""
                            }`}
                            onClick={() => handleButtonClick("latest_buttn")}
                          >
                            <Link to="/individual/orderhistory">
                              Latest ( New )
                            </Link>
                          </button>
                        </div>
                      </li>
                      <li>
                        <div className="assigned_container">
                          <button
                            className={`button Assigned_btn ${
                              activeButton === "Assigned_btn"
                                ? "active_latest"
                                : ""
                            }`}
                            onClick={() => handleButtonClick("Assigned_btn")}
                          >
                            <Link to="/individual/IndividualToday">Today</Link>
                          </button>
                          <button
                            className={`button Assigned_button ${
                              activeButton === "Assigned_button"
                                ? "active_latest"
                                : ""
                            }`}
                            onClick={() => handleButtonClick("Assigned_button")}
                          >
                            <Link to="/individual/IndividualToday">Today</Link>
                          </button>
                        </div>
                      </li>
                      <li>
                        <div className="complete_container">
                          <button
                            className={`button Completed ${
                              activeButton === "Completed"
                                ? "active_latest"
                                : ""
                            }`}
                            onClick={() => handleButtonClick("Completed")}
                          >
                            <Link to="/individual/Individualcompeleorder">
                              Completed
                            </Link>
                          </button>
                          <button
                            className={`button Completed_button ${
                              activeButton === "Completed_button"
                                ? "active_latest"
                                : ""
                            }`}
                            onClick={() =>
                              handleButtonClick("Completed_button")
                            }
                          >
                            <Link to="/individual/Individualcompeleorder">
                              Completed
                            </Link>
                          </button>
                        </div>
                      </li>
                      <li>
                        <div className="reject_container">
                          <button
                            className={`button reject_btn ${
                              activeButton === "reject_btn"
                                ? "active_latest"
                                : ""
                            }`}
                            onClick={() => handleButtonClick("reject_btn")}
                          >
                            <Link to="/individual/Individualreject">
                              Rejected
                            </Link>
                          </button>
                          <button
                            className={`button Reject_button ${
                              activeButton === "Reject_button"
                                ? "active_latest"
                                : ""
                            }`}
                            onClick={() => handleButtonClick("Reject_button")}
                          >
                            <Link to="/individual/Individualreject">
                              Rejected
                            </Link>
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="row  mx-0">
                  {orderHistory &&
                    orderHistory.map((order, index) => (
                      <div className="col-md-6" key={index}>
                        <div className="book_slot">
                          <div className="border_bottom row mx-0">
                            <div className="col-md-6 col-6">
                              <div className="date_booking">
                                <p>Booking Date</p>
                                <h1>{order.service_date}</h1>
                              </div>
                            </div>
                            <div className="col-md-6 col-6">
                              <div className="date_booking">
                                <p>Booking Time</p>
                                <h1>{order.service_time}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="border_bottom row mx-0">
                            <div className="date_booking">
                              <p>Address</p>
                              <h1>
                                {order.service_location?.address1},{" "}
                                {order.service_location?.address2},
                                {order.service_location?.land_mark},{" "}
                                {order.service_location?.city},
                                {order.service_location?.state},{" "}
                                {order.service_location?.country}
                              </h1>
                            </div>
                          </div>
                          <div className="border_bottom row mx-0">
                            <div className="col-md-6 col-6">
                              <div className="date_booking ">
                                <p className="postpayment">Payment</p>
                                <h2>{order.amount}-/</h2>
                              </div>
                            </div>
                            <div className="col-md-6 col-6 px-0">
                              <div className="prepaid">
                                {order.paymentStatus === "success" ? (
                                  <button className="pre-paid">
                                    Prepaid Payment
                                  </button>
                                ) : (
                                  <button className="pre-paid">
                                    Postpaid Payment
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="border_bottom order-btn-individual   row mx-0">
                            {order.job_status === "Processing" && (
                              <>
                                <div className="col-md-4 col-4 px-0">
                                  <div
                                    className="assign-worker-btn"
                                    onClick={() => accept_order(order._id)}
                                  >
                                    <button className="button assign-worker">
                                      Accept
                                    </button>
                                    <button className="button assign-worker1">
                                      Accept
                                    </button>
                                  </div>
                                </div>
                                <div
                                  className="col-md-4 col-4 px-0"
                                  id="reject-btn-indOrdeD"
                                >
                                  <div
                                    className="assign-viewer-container"
                                    onClick={() => reject_popup(order._id)}
                                  >
                                    <button className="button assign-View-Detail">
                                      Reject
                                    </button>
                                    <button className="button assign-View-Detail1">
                                      Reject
                                    </button>
                                  </div>
                                </div>
                                <div
                                  className="col-md-4 col-4 px-0"
                                  onClick={() => accept_view(order)}
                                >
                                  <div className="assign-viewer-container">
                                    <button className="button assign-View-Detail">
                                      <Link to="/Individual/Individuallatest_detail">
                                        {" "}
                                        View Detail{" "}
                                      </Link>
                                    </button>
                                    <button className="button assign-View-Detail1">
                                      <Link to="/Individual/Individuallatest_detail">
                                        {" "}
                                        View Detail{" "}
                                      </Link>
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}

                            {order.job_status === "Accept" && (
                              <>
                                <div className="col-md-4 col-4 px-0">
                                  <div
                                    className="assign-worker-btn"
                                    onClick={() => start_job(order)}
                                  >
                                    <button className="button assign-worker">
                                      Start Job
                                    </button>
                                    <button className="button assign-worker1">
                                      Start Job
                                    </button>
                                  </div>
                                </div>
                                <div className="col-md-4 col-4 px-0">
                                  <div
                                    className="assign-viewer-container"
                                    onClick={() => reject_popup(order._id)}
                                  >
                                    <button className="button assign-View-Detail">
                                      Reject
                                    </button>
                                    <button className="button assign-View-Detail1">
                                      Reject
                                    </button>
                                  </div>
                                </div>
                                <div className="col-md-4 col-4 px-0">
                                  <div
                                    className="assign-viewer-container"
                                    onClick={() => today_viewdeatils(order._id)}
                                  >
                                    <button className="button assign-View-Detail">
                                      <Link to="/individual/latesttasklist">
                                        {" "}
                                        View Detail{" "}
                                      </Link>
                                    </button>
                                    <button className="button assign-View-Detail1">
                                      <Link to="/individual/latesttasklist">
                                        {" "}
                                        View Detail{" "}
                                      </Link>
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}

                            {order.job_status === "Start" && (
                              <>
                                <div className="col-md-6 col-6 px-0">
                                  <div
                                    className="assign-worker-btn"
                                    onClick={() => finishjob_popup(order)}
                                  >
                                    <button className="button assign-worker">
                                      Job Finish
                                    </button>
                                    <button className="button assign-worker1">
                                      Job Finish
                                    </button>
                                  </div>
                                </div>
                                {/* <div className="col-md-4 col-4 px-0">
      <div className="assign-viewer-container" >
        <button className="button assign-View-Detail" disabled>Reject</button>
        <button className="button assign-View-Detail1" disabled>Reject</button>
      </div>
    </div> */}
                                <div className="col-md-6 col-6 px-0">
                                  <div
                                    className="assign-viewer-container"
                                    onClick={() => today_viewdeatils(order._id)}
                                  >
                                    <button className="button assign-View-Detail">
                                      <Link to="/individual/latesttasklist">
                                        {" "}
                                        View Detail{" "}
                                      </Link>
                                    </button>
                                    <button className="button assign-View-Detail1">
                                      <Link to="/individual/latesttasklist">
                                        {" "}
                                        View Detail{" "}
                                      </Link>
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}

                            {order.job_status === "Complete" && (
                              <>
                                <div
                                  className="col-md-12 col-12 px-0 complete_details"
                                  onClick={() => viewdetails(order)}
                                >
                                  <div className="assign-viewer-container">
                                    <button className="button assign-View-Detail">
                                      <Link to="/individual/Individualcompleteorder_detail">
                                        {" "}
                                        View Detail{" "}
                                      </Link>
                                    </button>
                                    <button className="button assign-View-Detail1">
                                      <Link to="/individual/Individualcompleteorder_detail">
                                        {" "}
                                        View Detail{" "}
                                      </Link>
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}

                            {order.job_status === "Reject" && (
                              <>
                                <div
                                  className="col-md-12 col-12 px-0 complete_details"
                                  onClick={() => reject_viewdetails(order)}
                                >
                                  <div className="assign-viewer-container">
                                    <button className="button assign-View-Detail">
                                      <Link to="/Individua/Individualreject_orderdetail">
                                        Rejected{" "}
                                      </Link>
                                    </button>
                                    <button className="button assign-View-Detail1">
                                      <Link to="/Individua/Individualreject_orderdetail">
                                        Rejected{" "}
                                      </Link>
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Time Model */}
      <Modal
        show={jobStartTimeError}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div id="lottie-animation"></div>
          <div
            className="congrats-profile-created"
            style={{ marginTop: "0px", color:"red" }}
          >
            <h5>Job not started  after booking date & time.</h5>
          </div>
        </Modal.Body>
      </Modal>
      {/* END */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div id="lottie-animation"></div>
          <div
            className="congrats-profile-created"
            style={{ marginTop: "0px" }}
          >
            {error ? (
              <p>{error}</p>
            ) : locationName ? (
              <h6>{locationName}</h6>
            ) : (
              <p>
                Geolocation is disabled. Please enable geolocation in your
                browser settings.
              </p>
            )}

            <p>Use your device location to set your current location. </p>
            <div
              class="button-container"
              style={{ width: "200px" }}
              onClick={Uploadingfunction}
            >
              <button className="button button1">Use Current Location</button>
              <button className="button button2">
                <Link style={{ textDecoration: "none", color: "#ffffff" }}>
                  Use Current Location
                </Link>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={uploadpic}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created"
            style={{ marginTop: "0px" }}
          >
            <h2>Upload Photos</h2>
            <p>Upload 2 selfie images with your service location.</p>
            <div>
              <ul className="images_uploads">
                <li>
                  <div>
                    {selectedImage ? (
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        alt="Selected"
                      />
                    ) : (
                      <div>
                        <label
                          htmlFor="file-input"
                          style={{ display: "block", marginBottom: "10px" }}
                        >
                          <div className="uploading_img">
                            <img
                              src={process.env.PUBLIC_URL + "../images/add.png"}
                              alt="playstore"
                              className="addimgicon"
                            />
                          </div>
                        </label>
                        <input
                          type="file"
                          id="file-input"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                        />
                      </div>
                    )}
                  </div>
                </li>
                <li>
                  <div>
                    {selectedImage2 ? (
                      <img
                        src={URL.createObjectURL(selectedImage2)}
                        alt="Selected"
                      />
                    ) : (
                      <div>
                        <label
                          htmlFor="file-input2"
                          style={{ display: "block", marginBottom: "10px" }}
                        >
                          <div className="uploading_img">
                            <img
                              src={process.env.PUBLIC_URL + "../images/add.png"}
                              alt="playstore"
                              className="addimgicon"
                            />
                          </div>
                        </label>
                        <input
                          type="file"
                          id="file-input2"
                          onChange={handleImageChange2}
                          style={{ display: "none" }}
                        />
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="button-container"
              style={{ width: "200px" }}
              onClick={handleUpload}
            >
              <button className="button button1">Submit Images</button>
              <button className="button button2">
                <Link style={{ textDecoration: "none", color: "#ffffff" }}>
                  Submit Images
                </Link>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={Verifyotp}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created"
            style={{ marginTop: "0px" }}
          >
            <h2>Verify Yourself</h2>
            <p>
              Enter the 6-digit code that we sent to customer after order
              confirmation.
            </p>
            <div>
              <ul className="verify_otp">
                {otpDigits.map((digit, index) => (
                  <li key={index}>
                    <input
                      type="text"
                      className="input_verify"
                      maxLength="1"
                      value={digit} // Display the value from the state
                      onChange={(e) =>
                        handleOtpInputChange(index, e.target.value)
                      }
                      ref={inputRefs[index]}
                    />
                  </li>
                ))}
              </ul>
            </div>

            <div
              class="button-container"
              style={{ width: "150px" }}
              onClick={() => handleShow(jodid)}
            >
              <button className="button button1">Submit</button>
              <button className="button button2">
                <Link style={{ textDecoration: "none", color: "#ffffff" }}>
                  Submit
                </Link>
              </button>
            </div>
            {/* <p>Resend Code</p> */}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={paymentpopup}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created"
            style={{ marginTop: "0px" }}
          >
            <div className="payment_details">
              <h1>Payment Detail</h1>

              <div className="vendor_payment_status">
                <input
                  placeholder="Payment Status"
                  className="payment_status"
                  value={payment_selection.paymentStatus}
                />

                {payment_selection.paymentStatus === "Success" ? (
                  <button className="prepaid_post">Postpaid Payment</button>
                ) : (
                  <button className="prepaid_post">Prepaid Payment</button>
                )}
              </div>
              {
                <ul className="mt-2">
                  <li className="pt-0">
                    <p>Total Amount</p>{" "}
                    <span>₦ {payment_selection.amount}</span>
                  </li>
                  <li>
                    <p>Discount</p>{" "}
                    <span className="text_change_color">
                      ₦ {payment_selection.discount}
                    </span>
                  </li>
                  <li>
                    <p>Other Charges</p>{" "}
                    <span>
                      {" "}
                      <p>Free</p>{" "}
                    </span>
                  </li>
                  <li className="pb-0 bottom_none">
                    <p className="text_change_color">Amount Paid</p>{" "}
                    <span className="text_change_color">
                      ₦{payment_selection.amount}
                    </span>
                  </li>
                </ul>
              }
            </div>
            <div className="col-md-12">
              <input
                type="number"
                placeholder="Enter Amount"
                name="amount"
                className="select_options"
                value={amount}
                onChange={handlePaymentChange}
              />
              {errors.amount && <div className="error">{errors.amount}</div>}
            </div>
            <div className="col-md-12">
              <select
                name="payment_option"
                className="select_options"
                value={selectedPayment}
                onChange={handlePaymentChange}
              >
                <option style={{ display: "none" }} value="">
                  Select Payment Mode
                </option>
                <option value="Cash">Cash</option>
                <option value="Online">Online</option>
              </select>
              {errors.payment_option && (
                <div className="error">{errors.payment_option}</div>
              )}
            </div>

            {/* onClick={() => Proceed_pay()} */}

            <div
              class="button-container"
              style={{ width: "150px" }}
              onClick={handleSubmitPayment}
            >
              <button className="button button1">Proceed To Pay</button>
              <button className="button button2">
                <Link style={{ textDecoration: "none", color: "#ffffff" }}>
                  Proceed To Pay
                </Link>
              </button>
            </div>
            {/* <p>Resend Code</p> */}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={success}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created  well_done"
            style={{ marginTop: "0px" }}
          >
            <img
              src={process.env.PUBLIC_URL + "../images/greeting.gif"}
              alt="playstore"
              className="gif"
            />
            <h2>Well Done!</h2>
            <p>You was complete your Job Successfully</p>

            <div
              class="button-container"
              style={{ width: "150px" }}
              onClick={handleReloadClick}
            >
              <button className="button button1">
                {" "}
                <Link
                  style={{ textDecoration: "none" }}
                  to="/individual/orderhistory"
                >
                  {" "}
                  Back to Home
                </Link>
              </button>
              <button className="button button2">
                <Link
                  style={{ textDecoration: "none", color: "#ffffff" }}
                  to="/individual/orderhistory"
                >
                  {" "}
                  Back to Home
                </Link>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={reject}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created  well_done"
            style={{ marginTop: "0px" }}
          >
            <p>Reason For Reject</p>

            <textarea
              value={textareaValue}
              onChange={(e) => setTextareaValue(e.target.value)}
              className="add_reasons"
            ></textarea>
            <div
              class="button-container mt-3"
              style={{ width: "150px" }}
              onClick={() => reject_order(processid)}
            >
              <button className="button button1 s_btn">
                {" "}
                <Link
                  style={{ textDecoration: "none", color: "#fff !important" }}
                  to="/individual/orderhistory"
                >
                  {" "}
                  Submit
                </Link>
              </button>
              <button className="button button2">
                <Link
                  style={{ textDecoration: "none", color: "#ffffff" }}
                  to="/individual/orderhistory"
                >
                  Submit
                </Link>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={success2}
        onHide={handleClose}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created  well_done"
            style={{ marginTop: "0px" }}
          >
            {/* <Lottie options={lottieOptions} /> */}

            <>
              {" "}
              <h2>Payment Initiated!</h2>
              <p>Please Wait While Customer Pays ! Then Check Status</p>{" "}
            </>

            <div
              class="button-container"
              style={{ width: "150px" }}
              onClick={callPaymentGetApi}
            >
              <button className="button button1"> Proceed To Next</button>
              <button className="button button2">Proceed To Next</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
};

export default IndividualOrderHistory;
