import React from "react";
import Slider from "react-slick";
import { faStar } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-circular-progressbar/dist/styles.css";
import { BaseUrl, apiBaseUrl } from "../config/constant";
import { getSessionForLoginUser } from "../utils/helper";

const Providerslider = (props) => {
  const { similarProvider } = props;
  const userData= getSessionForLoginUser('user');
  const percentage = 70;
  const maxRating = 5;
  // const profileImage = "http://localhost:3000/vendor_dashboard/my%20Profile.png";

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 3.76,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        {similarProvider[0]?.providerList?.map((item, index) => (
          <div key={index}>
            <div className="provider_list_box">
              <div
                style={{
                  width: "100px",
                  margin: "auto",
                  height: "98px",
                }}
              >
                <CircularProgressbar
                  value={percentage}
                  strokeWidth={6}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: `rgba(86,209,98, 1, ${percentage / 100})`,
                    textColor: "#24A831",
                    trailColor: "#d6d6d6",
                  })}
                />

                <LazyLoadImage
                  src={BaseUrl+item.profile_pic}
                  
                  alt="Your Image"
                  style={{
                    width: "63px",
                    height: "65px",
                    position: "absolute",
                    transform: "translate(19%, -116%)",
                    borderRadius: "50%",
                  }}
                />
                <div className="circular_value">
                  <p className="percentage_value">{`${percentage}%`}</p>
                </div>
              </div>
              <h1>{item.fullName}</h1>
              <p> {item.city}, {item.state}, {item.country}</p>

              <span className="user_list_list">
                <div className="multiple_stars_content">
                  <ul>
                    <li>
                    {item.star? Array.from({ length: maxRating }).map((_, index) => (
                      <FontAwesomeIcon key={index} icon={faStar} className={ index < item.star ? "star_yellow" : "star"} />
                          )) : Array.from({ length: maxRating }).map((_, index) => (
                            <FontAwesomeIcon key={index} icon={faStar} className={ index < 0 ? "star_yellow" : "star" }
                            />
                          ))} 
                    </li>
                  </ul>
                </div>
                <p>    {item.star?  item.star : ""}</p>
              </span>
              {userData?
              <button>
                <img src={process.env.PUBLIC_URL + "/images/chat.png"} alt="app" />
                <p>Chat</p>
              </button>
              :""}
            </div>
          </div>
        ))}

        {similarProvider[0]?.providerList?.map((item, index) => (
          <div key={index}>
            <div className="provider_list_box">
              <div
                style={{
                  width: "100px",
                  margin: "auto",
                  height: "98px",
                }}
              >
                <CircularProgressbar
                  value={percentage}
                  strokeWidth={6}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: `rgba(86,209,98, 1, ${percentage / 100})`,
                    textColor: "#24A831",
                    trailColor: "#d6d6d6",
                  })}
                />

                <LazyLoadImage
                  src={BaseUrl+item.profile_pic}
                  
                  alt="Your Image"
                  style={{
                    width: "63px",
                    height: "65px",
                    position: "absolute",
                    transform: "translate(19%, -116%)",
                    borderRadius: "50%",
                  }}
                />
                <div className="circular_value">
                  <p className="percentage_value">{`${percentage}%`}</p>
                </div>
              </div>
              <h1>{item.fullName}</h1>
              <p> {item.address}, {item.city},{item.country}</p>

              <span className="user_list_list">
                <div className="multiple_stars_content">
                  <ul>
                    <li>
                    {item.star?       Array.from({ length: maxRating }).map((_, index) => (
                            <FontAwesomeIcon
                              key={index}
                              icon={faStar}
                              className={
                                index < item.star
                                  ? "star_yellow"
                                  : "star"
                              }
                            />
                          )) :       Array.from({ length: maxRating }).map((_, index) => (
                            <FontAwesomeIcon
                              key={index}
                              icon={faStar}
                              className={
                                index < 0
                                  ? "star_yellow"
                                  : "star"
                              }
                            />
                          ))} 
              
                    </li>
                    
                  </ul>
                </div>
                <p>    {item.star?  item.star : "0"}</p>
              </span>
              {userData?
              <button>
                <img
                  src={process.env.PUBLIC_URL + "/images/chat.png"}
                  alt="app"
                />
                <p>Chat</p>
              </button>
              :""}
            </div>
          </div>
        ))}
        {similarProvider[0]?.providerList?.map((item, index) => (
          <div key={index}>
            <div className="provider_list_box">
              <div
                style={{
                  width: "100px",
                  margin: "auto",
                  height: "98px",
                }}
              >
                <CircularProgressbar
                  value={percentage}
                  strokeWidth={6}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: `rgba(86,209,98, 1, ${percentage / 100})`,
                    textColor: "#24A831",
                    trailColor: "#d6d6d6",
                  })}
                />

                <LazyLoadImage
                  src={BaseUrl+item.profile_pic}
                  
                  alt="Your Image"
                  style={{
                    width: "63px",
                    height: "65px",
                    position: "absolute",
                    transform: "translate(19%, -116%)",
                    borderRadius: "50%",
                  }}
                />
                <div className="circular_value">
                  <p className="percentage_value">{`${percentage}%`}</p>
                </div>
              </div>
              <h1>{item.fullName}</h1>
              <p> {item.address}, {item.city},{item.country}</p>

              <span className="user_list_list">
                <div className="multiple_stars_content">
                  <ul>
                    <li>
                    {item.star?       Array.from({ length: maxRating }).map((_, index) => (
                            <FontAwesomeIcon
                              key={index}
                              icon={faStar}
                              className={
                                index < item.star
                                  ? "star_yellow"
                                  : "star"
                              }
                            />
                          )) :       Array.from({ length: maxRating }).map((_, index) => (
                            <FontAwesomeIcon
                              key={index}
                              icon={faStar}
                              className={
                                index < 0
                                  ? "star_yellow"
                                  : "star"
                              }
                            />
                          ))} 
              
                    </li>
                    
                  </ul>
                </div>
                <p>    {item.star?  item.star : "0"}</p>
              </span>
              {userData?
              <button>
                <img
                  src={process.env.PUBLIC_URL + "/images/chat.png"}
                  alt="app"
                />
                <p>Chat</p>
              </button>
              :""}
            </div>
          </div>
        ))}
        {similarProvider[0]?.providerList?.map((item, index) => (
          <div key={index}>
            <div className="provider_list_box">
              <div
                style={{
                  width: "100px",
                  margin: "auto",
                  height: "98px",
                }}
              >
                <CircularProgressbar
                  value={percentage}
                  strokeWidth={6}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: `rgba(86,209,98, 1, ${percentage / 100})`,
                    textColor: "#24A831",
                    trailColor: "#d6d6d6",
                  })}
                />

                <LazyLoadImage
                  src={BaseUrl+item.profile_pic}
                  
                  alt="Your Image"
                  style={{
                    width: "63px",
                    height: "65px",
                    position: "absolute",
                    transform: "translate(19%, -116%)",
                    borderRadius: "50%",
                  }}
                />
                <div className="circular_value">
                  <p className="percentage_value">{`${percentage}%`}</p>
                </div>
              </div>
              <h1>{item.fullName}</h1>
              <p> {item.address}, {item.city},{item.country}</p>

              <span className="user_list_list">
                <div className="multiple_stars_content">
                  <ul>
                    <li>
                    {item.star?       Array.from({ length: maxRating }).map((_, index) => (
                            <FontAwesomeIcon
                              key={index}
                              icon={faStar}
                              className={
                                index < item.star
                                  ? "star_yellow"
                                  : "star"
                              }
                            />
                          )) :       Array.from({ length: maxRating }).map((_, index) => (
                            <FontAwesomeIcon
                              key={index}
                              icon={faStar}
                              className={
                                index < 0
                                  ? "star_yellow"
                                  : "star"
                              }
                            />
                          ))} 
              
                    </li>
                    
                  </ul>
                </div>
                <p>    {item.star?  item.star : "0"}</p>
              </span>
              {userData?
              <button>
                <img
                  src={process.env.PUBLIC_URL + "/images/chat.png"}
                  alt="app"
                />
                <p>Chat</p>
              </button>
              :""}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Providerslider;
