import React from "react"; 
import { useState,useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import DashboardAsVendor from "../../components/dashboardAsVendor";
import { Link } from "react-router-dom";
import axios from "axios";
import { getSession } from "../../utils/helper";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { BaseUrl, apiBaseUrl } from "../../config/constant";

const EditService = ()=>{
    const location = useLocation();
    const data = location.state || {};
    const token = getSession("token");
    const navigate = useNavigate();
    const[subServices,setSubServices]=useState([])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData,setFormData]=useState({
      services: data?.serviceData?._id ||data?.serviceList?.serviceData?._id ,
      sub_services: data?.subServices?._id || data?.serviceList?.subServices?._id ,
      category:data.category,
      price: data?.price || data?.serviceList?.price
    })

      const handleCategoryChange = (event) => {
        const selectedCategory = event.target.value;
        setFormData((prevFormData) => ({
          ...prevFormData,
          category: selectedCategory
        }));
      };

      const handleInputChange=(e)=>{
        const value=e.target.value;
        const name= e.target.name;
        
        if(name==="price"){
          setFormData((prevFormData) => ({
            ...prevFormData,
            price: value
          }));
        }
          }
        
// console.log(formData,"formDat")
          const addServiceData = async () => {
            try {
              const response = await axios.post(
                apiBaseUrl+"provider/addedit-services-by-company",
                formData,
                {
                  headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                  },
                }
              );
              return response.data
            } catch (error) {
              console.error(error);
            }
          };
                  


const handleSubmit=()=>{
    addServiceData().then((data)=>{
if(data.status===true){
    navigate("/vendor/service");
}
    }).catch((error)=>{
        console.error(error);
    })
}
console.log(data?.serviceList);

    return(
        <>
        <Header></Header>
        <div className="dashboard">

        <div className="container">

<div className="row mx-0">
 <DashboardAsVendor/>
    <div className="col-md-12 col-lg-9">
     <div className="vendors_right_forms">
    
     <div className="right_side">
       <div className="row mx-0" >
        <div className="col-12">
          <div className="btn_es_align mb-3">
           <button className="arrow_down"><Link to="/vendor/service"><img src={process.env.PUBLIC_URL + "/vendor_dashboard/order_detail/order_details.png"} /></Link></button>
    
          </div>
        </div>
        </div>
       
        <div className="row">
    <div className="padding_edit_service">
      <div className="row">
        <div className="col-md-4 col-lg-4">
            <h1 className="services_deatils_heading">Service</h1>
            <div className="user_name" style={{width:"200px"}}><input type="text"  value={data?.serviceData?.service_name || data?.serviceList?.serviceData?.service_name  } className="unix_slon"/></div>
        </div>
        {/* {data.category?  
        <div className="col-md-12 col-lg-8">
        <h1 className="services_deatils_heading">Gender</h1>
        <div className="user_selection_id">
      <div className="user_name">
        <input
          type="radio"
          className="radio_btns"
          value="Male"
          checked={formData.category === "Male"}
          onChange={handleCategoryChange}
          readOnly
        />
        <label>Male</label>
      </div>
      <div className="user_name">
        <input
          type="radio"
          className="radio_btns"
          value="Female"
          checked={formData.category === "Female"}
          onChange={handleCategoryChange}
        />
        <label>Female</label>
      </div>
      <div className="user_name">
        <input
          type="radio"
          className="radio_btns"
          value="Both"
          checked={formData.category === "Both"}
          onChange={handleCategoryChange}
        />
        <label>Both</label>
      </div>
    </div>
        </div>
        :""  
        } */}

      </div>
      <div className="row mt-2">
        <div className="col-md-3">
            <h1 className="services_deatils_heading">Sub-Service</h1>
        
        <div className="blur-effect ">
            <ul className="mb-0" id="company_service" >
                {/* {subServices.map((item,index)=>{
         <li>{item.service_name}
            </li>
                })} */}
                <li >{data?.subServices?.sub_service_name || data?.serviceList?.subServices?.sub_service_name}</li>
               
                {/* <li className="user_Selective">Manicure</li>
                <li>Threading</li>
                <li>De-tan</li>
                <li>Nail Studio</li>
                <li>Waxing</li>
                <li>Pedicure</li>
                <li>Facial</li>
                <li>Face Bleach</li> */}
            </ul>
            </div>
        </div>
        
      </div>

   
    <div className="row">
    <div className="col-md-12">
   
    <div className='work_list_category'>
                <h2 className="my-2">Service ( Work Gallery )</h2>
                <ul>
                    {data?.servicesGallary?.slice(0,3).map((item, index)=>(
                        <li key={index}>
                            <div className='service_category_list'>
                                <img src={BaseUrl+item.before_work_pic} />
                                <img src={BaseUrl+item.after_work_pic } />
                            </div>
                        </li>
                    ))}
                    </ul> 
            </div>
        </div>        

</div>

<div className="row">
          <div className="col-md-3">
              <h1 className="services_deatils_heading">Price</h1>
          
          <div className="user_selection">
            <div className="price">
            <span><img src={process.env.PUBLIC_URL + "/vendor_dashboard/nan.png"} /></span><input type="text" name="price" value={formData?.price} style={{width:"200px"}} onChange={handleInputChange}/>
            </div>
              </div>
          </div>
          
      
      </div>
        
     </div>
     </div>
    </div>
</div>
<div className="proceed_next mt-4 mb-4">

           <div class="button-container mt-4 mb-4 mr-0" onClick={handleSubmit}><button class="button button1">  Proceed To Next</button><button class="button button2">  Proceed To Next</button></div>
           </div>
</div>
</div>
</div>
   </div>
        <Footer></Footer>
       


        {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Are you sure you want to delete this service? </Modal.Title>
        </Modal.Header>
        <Modal.Body>{data?.serviceData?.service_name}({key})</Modal.Body>
        <Modal.Footer>
        <div className="proceed_next mt-4 mb-4">
 
  <div class="button-container edit_proceed mt-4 mb-4" style={{marginRight:"5px"}} ><button class="button button1" id="btn-otp-resend">No</button><button class="button button2">No</button></div>

           <div class="button-container proccess_next mt-4 mb-4 mr-0" ><button class="button button1" onClick={handleDelete}> Yes </button><button class="button button2" onClick={handleDelete}>  Yes</button></div>
           </div>
   
   
      

        </Modal.Footer>
      </Modal> */}
        </>
    );
};

export default EditService;
