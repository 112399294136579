import React, { useEffect, useState } from 'react';

import Header from "../../components/header";
import Footer from "../../components/footer";
import './about.css';
import Aboutslider from "../../slider/aboutSlider";
import Testimonial from "../../slider/testimonial";
import About_usmobileslider from "../../slider/about_usmobileslider";
import About_usmobiefeaturesslider from "../../slider/about_usmobilefeaturslider";
import Modal from "react-bootstrap/Modal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import axios from "axios";
import { validationSchema } from "../../components/validator/validator";
import { apiBaseUrl, homePageApiUrl } from "../../config/constant";

const About = () =>{
const A_headinfData = JSON.parse(localStorage.getItem('About_heading'));
const A_testimonialData = JSON.parse(localStorage.getItem('About_testimonial'));
const A_bannerTitleData = JSON.parse(localStorage.getItem('About_bannerTitle'));
const A_featuresData = JSON.parse(localStorage.getItem('About_features'));
const A_banerheadingData = JSON.parse(localStorage.getItem('About_banerheading'));
const A_bannerImageData = JSON.parse(localStorage.getItem('About_bannerImage'));
const A_pageContent1Data = JSON.parse(localStorage.getItem('About_pageContent1'));
const A_pageContent2Data = JSON.parse(localStorage.getItem('About_pageContent2'));
const A_pageContent3Data = JSON.parse(localStorage.getItem('About_pageContent3'));

  const [data, setData] = useState(null);
  const [headings, setHeadings] = useState(A_headinfData?A_headinfData:[]);
  const [testimonial , setTestimonial] = useState(A_testimonialData?A_testimonialData:[]);
  const [bannertitles, setBannertitles] = useState(A_bannerTitleData?A_bannerTitleData:[]);
  const [pageContent1, setpageContent1] = useState(A_pageContent1Data?A_pageContent1Data:'');
  const [pageContent2, setpageContent2] = useState(A_pageContent2Data?A_pageContent2Data:'');
  const [pageContent3, setpageContent3] = useState(A_pageContent3Data?A_pageContent3Data:'');
  const [bannerheading,setbanerheading] = useState(A_banerheadingData?A_banerheadingData:'');
  const [bannerImage , setBannerImage] = useState(A_bannerImageData?A_bannerImageData:'');
  const [features,setfeatures] = useState(A_featuresData?A_featuresData:[]);
  async function fetchData() {
    try {
      const response = await axios.post(apiBaseUrl+homePageApiUrl, null, {
        headers: {
          key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
        },
      });
      setHeadings(response.data.response.result.pageheadings);
      localStorage.setItem('About_heading', JSON.stringify(response?.data?.response?.result?.pageheadings))
      setTestimonial(response.data.response.result.testmonial);
      localStorage.setItem('About_testimonial',JSON.stringify(response?.data?.response?.result?.testmonial))
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          apiBaseUrl+'common/web-get-aboutus-page-data',
          {
            headers: {
              key: 'd1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed',
              'Content-Type': 'application/json',
            },
          }
        );

        setBannertitles(response.data.response.result.banner);
        localStorage.setItem('About_bannerTitle',JSON.stringify(response?.data?.response?.result?.banner))
        setfeatures(response.data.response.result.features);
        localStorage.setItem('About_features',JSON.stringify(response?.data?.response?.result?.features))
        setbanerheading(response.data.response.result.pageheadings);
        localStorage.setItem('About_banerheading',JSON.stringify(response?.data?.response?.result?.pageheadings))
        setBannerImage(response.data.response.result.banner[0]?.image);
        localStorage.setItem('About_bannerImage',JSON.stringify(response?.data?.response?.result?.banner[0]?.image))
        let pageContent = response.data.response.result.content;
        setpageContent1(pageContent.description[0]);
        setpageContent2(pageContent.description[1]);
        setpageContent3(pageContent.description[2]);
        localStorage.setItem('About_pageContent1',JSON.stringify(pageContent.description[0]));
        localStorage.setItem('About_pageContent2',JSON.stringify(pageContent.description[1]));
        localStorage.setItem('About_pageContent3',JSON.stringify(pageContent.description[2]));
        const banner = response.data;
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);


// console.log(pageContent);
    const [errors, setErrors] = useState({});
    const [checkBlur, setBlur] = useState(false);
    const [formData, setFormData] = useState({
      fullName: "",
      email: "",
      phone: "",
      gender: "",
      skill:"",
      social_media_handle:"",
      business_social_media_handle:""
    });
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await validationSchema.validate(formData, { abortEarly: false });
        setBlur(true)
        // Validation succeeded, continue with form submission or further processing
        // ...
        // console.log("Form data:", formData);
        postLoginData()
        .then((data) => {
          // Process the returned data here

          if(data.status === true){
      
         Congratulation();
                joinclose();
          }
         
        })
        .catch((error) => {
          // Handle the error here
        });
      } catch (validationErrors) {
        // Validation failed, handle the errors
        const errors = {};
        validationErrors.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
        setErrors(errors);
      }
    };
     
    const postLoginData = async () => {
      try {
        const response = await axios.post(
          'https://moneysaverz.com:6300/front/v1/waitlist/capture-lead',
          {
            name: formData.fullName,
            email: formData.email,
            phone: formData.phone,
            gender:formData.gender,
            skill :formData.skill,
            social_media_handle:formData.social_media_handle,
            business_social_media_handle: formData.business_social_media_handle
          },
          {
            headers: {
              key: 'd1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed',
              'Content-Type': 'application/json',
            },
          }
        );
        return(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    
  
  
    const [isNavvisible, setIsVisible] = useState(true);
    const [openjoin, setopenjoin] = useState(false);
  
    const joinclose = () => setopenjoin(false);
    const joinshow = () => setopenjoin(true);
  
    const [click, setclick] = useState(false);
    const [isopen, setopen] = useState(false);



    const Congratulation = () => setclick(true);
    const Congratulationclose = () => setclick(false);  

    const handleGenderSelection = (selectedGender) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        gender: selectedGender,
      }));
    };
return (
    <>
     {/* <div>
     <div>
      {data ? (
        
        <pre>{JSON.stringify(data, null, 2)}</pre>
      ) : (
        
        <p>Loading...</p>
      )}
 
    </div>
    </div> */}
<Header/>
<div className="about_us" style={{ backgroundImage: `url(${bannerImage})` }}>
    <div className="container">
        <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-4">
            <div className="about_banner_content">
            <div>
            {bannertitles && bannertitles.map((item,index) => {
  return (
    <div key={index}>
    
   <h1>{item.title[0]}</h1>
   <p>{item.title[1]}</p>
    </div>
  );
})}
  </div>
               
            </div>
            </div>
            <div className="col-md-8">

            </div>
        </div>
    </div>
</div>
<section>
    <div className="container">
        <div className="row">
            <div className="col-md-1 col-lg-1 col-xl-1 col-0">
            <img src={process.env.PUBLIC_URL + "./left_qutation.png"} alt="qutation" className="left_qutation" />
               
            </div>
            <div className="col-md-12  col-lg-12 col-xl-10 col-12">
                <h1 className="section_heading">

                {bannerheading && bannerheading.length > 0 && (
  <>
    {bannerheading[0].heading[0].title.split(" ").map((word, index) => {
      if (index === 0) {
        return <React.Fragment key={index} >{word}  </React.Fragment> ;
      } else if (index < 2) {
        return <span key={index}>{word} </span>;
      } else {
        return <React.Fragment key={index}>{word} </React.Fragment>;
      }
    })}
  </>
)}</h1>
                  
               
                <p className="inner--content">
                  {bannerheading.length > 0 && (
              <>{bannerheading[0].heading[0].description}</>
            )}
                 
                  </p>
                
                </div>
                <div className="col-md-1 col-lg-1 col-xl-1 col-0">
                  <img src={process.env.PUBLIC_URL + "./right_qutation.png"} alt="right_qutation" className="right_qutation" />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-5 col-lg-5 col-xl-5 ">
                <img src={process.env.PUBLIC_URL + "./about_inner_section.png"} alt="about_section" className="about_img_inner"/>
            </div>

            <div className="col-md-12 col-12 col-lg-12 col-xl-7 pt-2">
              <h1 className="section_heading--leftalign">Knowledge about <span className="sub_heading--inner_section">DoQwik</span></h1>
              <div className="line_headline">   
                <img src={process.env.PUBLIC_URL + "./heading_line.png"} alt="heading_line"  className="my-2 " style={{width:"200px" , height:"5px"}}/>
              </div>
                <p className="inner--content--left mt-2">{pageContent1?pageContent1:""}</p>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <p className="inner--content mt-2">
                  {pageContent2?pageContent2:""}
                  <br/>
                  {pageContent1?pageContent1:""}
                </p>
            </div>
        </div>
    </div>
   
</section>

<section>
    <div className="exclisive_features">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
               
                <h1 className="section_heading--leftalign">
                  
                {bannerheading && bannerheading.length > 0 && (
  <>
    {bannerheading[0].heading[1].title.split(" ").map((word, index) => {
      if (index === 0) {
        return <React.Fragment key={index} >{word}  </React.Fragment> ;
      } else if (index < 2) {
        return <span key={index}>{word} </span>;
      } else {
        return <React.Fragment key={index}>{word} </React.Fragment>;
      }
    })}
  </>
)}
                </h1>
                <p className="inner--content">  {bannerheading.length > 0 && (
              <>{bannerheading[0].heading[1].description}</>
            )}</p>
                </div>
            </div>
            <div className="row mt-4 all_shapes">
             
            <div className="col-md-1"></div>
                <div className="col-md-2">
                    <div className="upper-round">
                        <h1 className="exclisive_features--subtitle">01</h1>
                        {features && features.length > 0 && (
                        <p className="exclisive_features--content">
                        <>{features[9].description}</>
                        </p>
                        )}
                    </div>
                </div>
                <div className="col-md-2">
                <div className="square">
                        <h1 className="exclisive_features--subtitle">02</h1>
                        {features && features.length > 0 && (
                        <p className="exclisive_features--content">
                        <>{features[8].description}</>
                        </p>
                        )}
                    </div>
                </div>
                <div className="col-md-2">
                <div className="oval">
                        <h1 className="exclisive_features--subtitle">03</h1>
                        {features && features.length > 0 && (
                        <p className="exclisive_features--content">
                        <>{features[7].description}</>
                        </p>
                        )}
                    </div>
                </div>
                <div className="col-md-2">
                <div className="square">
                        <h1 className="exclisive_features--subtitle">04</h1>
                        {features && features.length > 0 && (
                        <p className="exclisive_features--content">
                        <>{features[6].description}</>
                        </p>
                        )}
                    </div>
                </div>
                <div className="col-md-2">
                <div className="bottom-round">
                        <h1 className="exclisive_features--subtitle">05</h1>
                        {features && features.length > 0 && (
                        <p className="exclisive_features--content">
                        <>{features[5].description}</>
                        </p>
                        )}
                    </div>
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-1"></div>
                <div className="col-md-2">
                <div className="bottom-round">
                        <h1 className="exclisive_features--subtitle">06</h1>
                        {features && features.length > 0 && (
                        <p className="exclisive_features--content">
                        <>{features[4].description}</>
                        </p>
                        )}
                    </div>
                </div>
                <div className="col-md-2">
                <div className="oval">
                        <h1 className="exclisive_features--subtitle">07</h1>
                        {features && features.length > 0 && (
                        <p className="exclisive_features--content">
                        <>{features[3].description}</>
                        </p>
                        )}
                    </div>
                </div>
                <div className="col-md-2">
                <div className="square">
                        <h1 className="exclisive_features--subtitle">08</h1>
                        {features && features.length > 0 && (
                        <p className="exclisive_features--content">
                        <>{features[2].description}</>
                        </p>
                        )}
                    </div>
                </div>
                <div className="col-md-2">
                <div className="oval">
                        <h1 className="exclisive_features--subtitle">09</h1>
                        {features && features.length > 0 && (
                        <p className="exclisive_features--content">
                        <>{features[1].description}</>
                        </p>
                        )}
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="upper-round">
                        <h1 className="exclisive_features--subtitle">10</h1>
                        {features && features.length > 0 && (
                        <p className="exclisive_features--content">
                        <>{features[0].description}</>
                        </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
   <div className="mobile_view_shapes ">
    <About_usmobileslider></About_usmobileslider>
    <div className="features_about_page">
    <About_usmobiefeaturesslider></About_usmobiefeaturesslider>
    </div>
    </div>
</section>
{/* <section className="about_service testimonial">
<div className="container-fluid">
    <div className="row">
        <div className="col-ms-12">
        <h4>
        

            {headings.length > 0 && (
  <>
    {headings[0].heading[4].title.split(" ").map((word, index) => {
      if (index === 0) {
        return <React.Fragment key={index} >{word}  </React.Fragment> ;
      } else if (index < 2) {
        return <span key={index}>{word} </span>;
      } else {
        return <React.Fragment key={index}>{word} </React.Fragment>;
      }
    })}
  </>
)}
            
          </h4>
          <p>
            Our customers send us bunch of smiles with our services and love of
            them.
          </p>
          <Testimonial Testimonial={testimonial}/>
        </div>
    </div>
</div>
</section> */}
<div className="testimonial">
        
        </div>
<section className="mb-0">
    <div className="download_aap">
        <div className="container">
            <div className="row dots_margin_mb">
                <div className="col-md-3 col-lg-3 col-xl-3">
                <LazyLoadImage src={process.env.PUBLIC_URL + "./app_img.png"} alt="app"  className="app_img_upload" />
                 
                </div>
                <div className="col-md-12 col-lg-12 col-xl-5 pt-4">
                    <h1>Download the app</h1>
                    <p>Get the app. Get things done. Compare prices, read reviews and book top-rated services. All in one free app.</p>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-4">
                <ul>
                   <li>  <LazyLoadImage src={process.env.PUBLIC_URL + "./images/google_pay.png"} alt="app"  className="app_img" /> </li>
                    <li>  <LazyLoadImage src={process.env.PUBLIC_URL + "./images/btns_apple.png"} alt="app"  className="app_img" /> </li>
                    
                 
                   </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<Footer />

<Modal show={openjoin} onHide={joinclose} className="join_mode">
<button className="cross_btn" onClick={joinclose}> X </button>
        

        <Modal.Body>
          <div className="text_align_center">
          <img src={process.env.PUBLIC_URL + "/images/logo.png"} className="logo_mobile"/> <br />
          <b className="join_app_model">Join The DoQwik Waitlist</b>
            <div  className={!checkBlur ? "pop_up_model" : "blur-effect pop_up_model"}>
              <input
                type="text"
                className={` ${errors.fullName ? 'InputError' : ''}`}
                onChange={handleChange}
                placeholder="Full Name"
                name="fullName"
                id="name"
                oninput="allow_alphabets(this)"
                required
              />
     {errors.fullName && <p className="errors">{errors.fullName}</p>}
              <input
                type="email"
                className={` ${errors.email ? 'InputError' : ''}`}
                placeholder="Email Address"
                name="email"
                id="email"
                onChange={handleChange}
                required
              />
         {errors.email && <p className="errors">{errors.email}</p>}
              <input
                type="number"
                className={` ${errors.phone ? 'InputError' : ''}`}
                placeholder="Phone Number"
                onChange={handleChange}
                maxlength="10"
                minlength="10"
                name="phone"
                id="phone"
                onkeypress="return onlyNumberKey(event)"
                required
              />
                       {errors.phone && <p className="errors">{errors.phone}</p>}

                       <input
                type="text"
                className={` ${errors.email ? 'InputError' : ''}`}
                placeholder="Skill/Work"
                name="skill"
                id="skill"
                onChange={handleChange}
                required
              />
         {errors.skill && <p className="errors">{errors.skill}</p>}
                       <input
                type="text"
                className={` ${errors.email ? 'InputError' : ''}`}
                placeholder="Enter Social Media Handle "
                name="social_media_handle"
                id="social_media_handle"
                onChange={handleChange}
                required
              />
         {errors.social_media_handle && <p className="errors">{errors.social_media_handle}</p>}

         
         <input
                type="text"
                className={` ${errors.email ? 'InputError' : ''}`}
                placeholder="Enter Business Social Media Handle "
                name="business_social_media_handle"
                id="business_social_media_handle"
                onChange={handleChange}
                required
              />
         {errors.business_social_media_handle && <p className="errors">{errors.business_social_media_handle}</p>}
              
              <p className="fill_form mb-0">Select One Option</p>

              <div className="user_selection">
  <div
    className="user_name"
    onClick={() => handleGenderSelection("Male")}
  >
    <input
      type="radio"
      name="gender"
      value="Male"
      checked={formData.gender === "Male"}
      onChange={handleChange}
      className="radio_btns"
    />
    <label>Male</label>
  </div>
  <div
    className="user_name"
    onClick={() => handleGenderSelection("Female")}
  >
    <input
      type="radio"
      name="gender"
      value="Female"
      checked={formData.gender === "Female"}
      onChange={handleChange}
      className="radio_btns"
    />
    <label>Female</label>
  </div>
</div>
       
              {errors.gender && <p className="errors">{errors.gender}</p>}
            </div>
          </div>{" "}
        </Modal.Body>
        <Modal.Footer>
          <div
            className="button-container"
            onClick={(e) => {
              // Congratulation();
              // joinclose();
              handleSubmit(e);
            }}
          >
            <button className="button button1">Submit</button>
            <button className="button button2">Submit</button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={click}  className="join_model">
        <Modal.Header>
        <img src={process.env.PUBLIC_URL + "/images/logo.png"} className="logo_mobile"/> 
        <img src={process.env.PUBLIC_URL + "/images/model_cross.png"} onClick={()=>{window.location = "/";}} className="close_btn" /><br />
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="text_align_center">
        
          <p className="join_app_model_text">Congratulations you have joined the DoQwik Waitlist.</p>
       
            </div> </Modal.Body>
        <Modal.Footer>
        
        </Modal.Footer>
      </Modal>

    </>
    );
};

export default About;