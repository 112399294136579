import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./vendor.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/fontawesome-free-solid";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import DashboardAsVendor from "../../components/dashboardAsVendor";
import Latestvendor_details from "./latestvendor_details";
import { getSession } from "../../utils/helper";
import axios from "axios";
import { BaseUrl, apiBaseUrl } from "../../config/constant";

const VendorOrderHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const worker_Id = location.state;
  const token = getSession("token");
  const [data, setData] = useState([]);
  const [Completed, setCompleted] = useState([]);
  const [reject, setReject] = useState([]);
  const [assigned, setAssigned] = useState([]);
  const [workerData, setWorkerData] = useState([]);
  const [show, setShow] = useState(false);
  const [show3, setShow3] = useState(false);
  const [workerId, setWorkerId] = useState();
  const handleClose = () => setShow(false);
  const handleShow3 = () => setShow3(true);
  const handleClose3 = () => setShow3(false);
  const [jobId, setJobId] = useState("");
  const [responseData, setResponseData] = useState(null);
  const handleShow = (id) => {
    setShow(true);
    setJobId(id);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          apiBaseUrl + "order/get-job-details",
          { jobId: data._id },
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        setJobDetails(response.data.response.result);
        // setReorder(response)
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const fetchHistoryData = (order_search) => {
    return axios.post(
      apiBaseUrl + "order/worker-job-list-by-company",
      {
        userId: worker_Id,
        order_search: order_search,
        limit: "",
        skip: "",
      },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
  };

  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const response = await axios.post(
          apiBaseUrl + "order/worker-job-list-by-company",
          { userId: worker_Id },
          {
            headers: {
              Authorization: token,
              "content-type": "application/json",
            },
          }
        );

        setData(response.data.response.result); // Assuming the response contains the data you need
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData2();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl + "order/assign-job-to-worker",
        {
          jobId: jobId,
          workerID: workerId,
          updateIp: ":1",
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      setResponseData(response?.data?.response?.result);
      console.log(response);
      return response?.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Fetch data for "Completed"
    fetchHistoryData("Reject")
      .then((response) => {
        setReject(response.data.response.result);

        // After Completed data is fetched, fetch data for "Reject"
        return fetchHistoryData("Assign");
      })
      .then((response) => {
        setAssigned(response.data.response.result);
        // After Reject data is fetched, fetch data for "Assigned"
        return fetchHistoryData("Complete");
      })
      .then((response) => {
        setCompleted(response.data.response.result);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleItemClick = (item, id) => {
    if (id === 1) {
      navigate("/vendor/order_details", { state: item });
    } else if (id === 2) {
      console.log(item);
      navigate("/vendor/order_details", { state: item });
    } else if (id === 4) {
      navigate("/vendor/order_details", { state: item });
    }
  };

  const [activeTab, setActiveTab] = useState("latest");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleAssign = (item) => {
    console.log(item, "ll");
    setWorkerId(item?._id);
    fetchData()
      .then((data) => {
        if (data.status === true) {
          handleClose();
          handleShow3();
        }
      })
      .catch((error) => {
        // Handle error, if needed
        console.error("Error:", error);
      });
  };

  const handleViewDetails = (details) => {
    // console.log(details)
    navigate("/vendor/order_details", { state: details });
  };

  return (
    <>
      <Header />
      <div className="dashboard">
        <div className="container">
          <div className="row mx-0">
            <DashboardAsVendor />
            <div className="col-md-12 col-lg-9">
              <div className="vendors_right_form">
                <div className="row  mx-0">
                  <div className="col-md-6 col-lg-4">
                    <p className="sub_heading">Your Order Records & Details.</p>
                  </div>
                  <div className="col-md-8 col-lg-8">
                    <ul className="user_list latest_new">
                      <li>
                        <div className="latest_container">
                          <button
                            className={`button latest_btn ${
                              activeTab === "latest" ? "active_latest" : ""
                            }`}
                            onClick={() => handleTabClick("latest")}
                          >
                            Latest (New)
                          </button>
                          <button
                            className={`button latest_buttn ${
                              activeTab === "latest" ? "active_latest" : ""
                            }`}
                            onClick={() => handleTabClick("latest")}
                          >
                            Latest (New)
                          </button>
                        </div>
                      </li>
                      <li>
                        <div className="assigned_container">
                          <button
                            className={`button Assigned_btn ${
                              activeTab === "assigned" ? "active_latest" : ""
                            }`}
                            onClick={() => handleTabClick("assigned")}
                          >
                            Assigned
                          </button>
                          <button
                            className={`button Assigned_button ${
                              activeTab === "assigned" ? "active_latest" : ""
                            }`}
                            onClick={() => handleTabClick("assigned")}
                          >
                            Assigned
                          </button>
                        </div>
                      </li>
                      <li>
                        <div className="complete_container">
                          <button
                            className={`button Completed ${
                              activeTab === "completed" ? "active_latest" : ""
                            }`}
                            onClick={() => handleTabClick("completed")}
                          >
                            Completed
                          </button>
                          <button
                            className={`button Completed_button ${
                              activeTab === "completed" ? "active_latest" : ""
                            }`}
                            onClick={() => handleTabClick("completed")}
                          >
                            Completed
                          </button>
                        </div>
                      </li>
                      <li>
                        <div className="reject_container">
                          <button
                            className={`button reject_btn ${
                              activeTab === "reject" ? "active_latest" : ""
                            }`}
                            onClick={() => handleTabClick("reject")}
                          >
                            Rejected
                          </button>
                          <button
                            className={`button Reject_button ${
                              activeTab === "reject" ? "active_latest" : ""
                            }`}
                            onClick={() => handleTabClick("reject")}
                          >
                            Rejected
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div id="tabContent">
                  {activeTab === "latest" && (
                    <div className="tabContent">
                      {" "}
                      <div className="row  mx-0">
                        {data.map((item, index) => (
                          <div className="col-md-12 col-lg-6" key={index}>
                            <div className="book_slot">
                              <div className="border_bottom row mx-0">
                                <div className="col-md-6 col-6">
                                  <div className="date_booking">
                                    <p>Booking Slot Date</p>
                                    <h1>{item.service_date}</h1>
                                  </div>
                                </div>
                                <div className="col-md-6 col-6">
                                  <div className="date_booking">
                                    <p>Booking Slot Time</p>
                                    <h1>{item.service_time}</h1>
                                  </div>
                                </div>
                              </div>
                              <div className="border_bottom row ">
                                <div className="date_booking">
                                  <p>Address</p>
                                  <h1>
                                    {" "}
                                    {item?.service_location?.land_mark},
                                    {item?.service_location?.address1} ,{" "}
                                    {item?.service_location?.city} ,
                                    {item?.service_location?.state} ,
                                    {item?.service_location?.postal_code} ,
                                    {item?.service_location?.country}
                                  </h1>
                                </div>
                              </div>

                              <div className="border_bottom row mx-0">
                                <div className="col-md-6 col-6">
                                  <div className="date_booking ">
                                    <p className="postpayment">Payment</p>
                                    <h2>₦{item.amount}/-</h2>
                                  </div>
                                </div>
                                <div className="col-md-6 col-6 px-0">
                                  <div className="prepaid">
                                    <button className="pre-paid">
                                      {item.paymentStatus === "Initialize" ? (
                                        <>Postpaid</>
                                      ) : (
                                        item.paymentStatus
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                                <div className="col-md-6 col-6 px-0">
                                  {item?.job_status === "Complete" ||
                                  item?.job_status === "Reject" ||
                                  item?.job_status === "Accept" ||
                                  item?.job_status === "Start" ? (
                                    <div>
                                      <div
                                        className="assign-worker-container"
                                        style={{ width: "100%" }}
                                      >
                                        <button className="button assign-workr blur-effect">
                                          {" "}
                                          {(() => {
                                            switch (item.job_status) {
                                              case "Complete":
                                                return "Completed";
                                              case "Accept":
                                                return "Accepted";
                                              case "Reject":
                                                return "Rejected";
                                              case "Start":
                                                return "Started";

                                              default:
                                                return "Unknown Status"; // Add a default case for unknown statuses
                                            }
                                          })()}
                                        </button>
                                      </div>
                                    </div>
                                  ) : item?.job_status === "Assign" ? (
                                    <div className="assign-worker-container">
                                      <button className="button assign-workr blur-effect">
                                        {" "}
                                        Assigned
                                      </button>
                                    </div>
                                  ) :<>{''}</>}
                                </div>
                                <div className="col-md-6 col-6 px-0">
                                  <div className="assign-viewer-container">
                                    <button
                                      className="button assign-View-Detail"
                                      onClick={() => handleItemClick(item, 1)}
                                    >
                                      View Details{" "}
                                    </button>
                                    <button
                                      className="button assign-View-Detail1"
                                      onClick={() => handleItemClick(item, 1)}
                                    >
                                      {" "}
                                      View Details
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {activeTab === "assigned" && (
                    <div className="tabContent">
                      <div className="row">
                        {assigned.map((item, index) => (
                          <div className="col-md-12 col-lg-6" key={index}>
                            <div className="assign_task">
                              <div className="border_bottom row mx-0">
                                <div className="col-md-6 col-6">
                                  <div className="date_booking">
                                    <p>Booking Slot Date</p>
                                    <h1>{item.service_date}</h1>
                                  </div>
                                </div>
                                <div className="col-md-6 col-6">
                                  <div className="date_booking">
                                    <p>Booking Slot Time</p>
                                    <h1>{item.service_time}</h1>
                                  </div>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                                <div className="date_booking">
                                  <p>Address</p>
                                  <h1>
                                    {" "}
                                    {item?.service_location?.land_mark},
                                    {item?.service_location?.address1} ,{" "}
                                    {item?.service_location?.city} ,
                                    {item?.service_location?.state} ,
                                    {item?.service_location?.postal_code} ,
                                    {item?.service_location?.country}
                                  </h1>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                                <div className="col-md-12 col-12">
                                  <div className="prepaid">
                                    <p>Work Status</p>{" "}
                                    <button className="pre-paid">
                                      {item.job_status === "Assign"
                                        ? "Assigned"
                                        : item.job_status}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              
                              <div className="border_bottom row mx-0">
                                
                                <div className="col-md-12 col-12 px-0">
                                  <div className="assign-viewer-container">
                                    <button
                                      className="button assign-View-Detail"
                                      onClick={() => {
                                        handleItemClick(item, 2);
                                      }}
                                    >
                                      {" "}
                                      View Detail{" "}
                                    </button>
                                    <button
                                      className="button assign-View-Detail1"
                                      onClick={() => {
                                        handleItemClick(item, 2);
                                      }}
                                    >
                                      {" "}
                                      View Detail{" "}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {activeTab === "completed" && (
                    <div className="tabContent">
                      <div className="row">
                        {Completed?.map((item, index) => (
                          <div className="col-md-12 col-lg-6" key={index}>
                            <div className="assign_task">
                              <div className="border_bottom row mx-0">
                                <div className="col-md-6 col-6">
                                  <div className="date_booking">
                                    <p>Booking Slot Date</p>
                                    <h1>{item?.service_date}</h1>
                                  </div>
                                </div>
                                <div className="col-md-6 col-6">
                                  <div className="date_booking">
                                    <p>Booking Slot Time</p>
                                    <h1>{item?.service_time}</h1>
                                  </div>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                                <div className="date_booking">
                                  <p>Address</p>
                                  {item?.service_location?.land_mark},
                                  {item?.service_location?.address1} ,{" "}
                                  {item?.service_location?.city} ,
                                  {item?.service_location?.state} ,
                                  {item?.service_location?.postal_code} ,
                                  {item?.service_location?.country}
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                                <div className="col-md-7 col-7">
                                  <div className="date_booking ">
                                    <p className="postpayment">Payment</p>
                                    <h2>₦{item.amount}/-</h2>
                                  </div>
                                </div>
                                <div className="col-md-5 col-5 px-0">
                                  <button className="complete_prepaid">
                                    {item.paymentStatus === "Initialize" ? (
                                      <>Postpaid</>
                                    ) : (
                                      item.paymentStatus
                                    )}
                                  </button>
                                </div>
                              </div>
                              <div className="full_border row mx-0">
                                <div className="col-md-12 col-12">
                                  <div className="row mx-0">
                                    <div className="col-md-2">
                                      <img
                                        src={
                                          BaseUrl +
                                          item?.providerData?.profile_pic
                                        }
                                      />
                                    </div>
                                    <div className="col-md-7">
                                      <h1>{item?.providerData?.fullName}</h1>
                                      <p>{item?.providerData?.address1}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="user_rating">
                                        <FontAwesomeIcon
                                          icon={faStar}
                                          className="star_yellow"
                                        />
                                        <span>
                                          {item?.providerData?.averagerating
                                            ? item?.providerData?.averagerating
                                            : "0.0"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                               
                                <div className="col-md-12 col-12 px-0">
                                  <div
                                    className="assign-viewer-container"
                                    onClick={() => {
                                      handleViewDetails(item);
                                    }}
                                  >
                                    <button className="button assign-View-Detail">
                                      {/* <Link to="/vendor/completed_order_detail"> */}
                                      View Detail {/* </Link> */}
                                    </button>
                                    <button className="button assign-View-Detail1">
                                      {" "}
                                      View Detail{" "}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {activeTab === "reject" && (
                    <div className="tabContent">
                      <div className="row">
                        {reject.map((item, index) => (
                          <div className="col-md-12 col-lg-6">
                            <div className="assign_task">
                              <div className="border_bottom row mx-0">
                                <div className="col-md-6 col-6">
                                  <div className="date_booking">
                                    <p>Booking Slot Date</p>
                                    <h1>{item.service_date}</h1>
                                  </div>
                                </div>
                                <div className="col-md-6 col-6">
                                  <div className="date_booking">
                                    <p>Booking Slot Time</p>
                                    <h1>{item.service_time}</h1>
                                  </div>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                                <div className="date_booking">
                                  <p>Address</p>
                                  <h1>
                                    {" "}
                                    {item?.service_location?.land_mark},
                                    {item?.service_location?.address1} ,{" "}
                                    {item?.service_location?.city} ,
                                    {item?.service_location?.state} ,
                                    {item?.service_location?.postal_code} ,
                                    {item?.service_location?.country}
                                  </h1>
                                </div>
                              </div>
                              <div className="border_bottom row mx-0">
                                <div className="col-md-7 col-7">
                                  <div className="date_booking ">
                                    <p className="postpayment">Payment</p>
                                    <h2>{item.amount}-/</h2>
                                  </div>
                                </div>
                                <div className="col-md-5 col-5 px-0">
                                  <button className="complete_prepaid">
                                    {item.paymentStatus === "Initialize" ? (
                                      <>Postpaid</>
                                    ) : (
                                      item.paymentStatus
                                    )}
                                  </button>
                                </div>
                              </div>

                              <div className="border_bottom row mx-0">
                                {/* <div className="col-md-6 col-6 px-0">
                        <div className="assign-worker-container">
                          <button className="button assign-worker">Assign To Worker</button>
                          <button className="button assign-worker1">Assign To Worker</button>
                        </div>
                        </div> */}
                                <div className="col-md-12 col-12 px-0">
                                  <div className="assign-viewer-container">
                                    <button
                                      className="button assign-View-Detail"
                                      onClick={() => handleItemClick(item, 4)}
                                    >
                                      {" "}
                                      View Detail{" "}
                                    </button>
                                    <button
                                      className="button assign-View-Detail1"
                                      onClick={() => handleItemClick(item, 4)}
                                    >
                                      {" "}
                                      View Detail{" "}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
      <Footer />
    </>
  );
};

export default VendorOrderHistory;
