import {Routes, Route,useNavigationType, useLocation,} from "react-router-dom";
import Home from "./pages/home/home";
import Term_condition from './pages/term&Condition/term_condition'; 
import Privacy_policy from './pages/term&Condition/Prvicay_policy';
import Faq from "./pages/term&Condition/faq";
// user 
import ServicePage from "./pages/service-page";
import { useEffect,useState } from "react";
import CreateProfile from "./pages/registerAsUser/createProfile";
import CreateCompanyProfile from "./pages/registerAsVendor/createCompanyProfile";
import About from './pages/about/about';
import OrderHistory from "./pages/DashboardUser/orderHIstory";
import VendorProfile from "./pages/DashboardVendor/vendorProfile";
import Getapp from "./pages/getapp/getapp";
import Contact from "./pages/contact_us/contact";
import Providercheckout from "./pages/ProviderList/providercheckout";
import VendorDashboard from "./pages/DashboardVendor/vendorDashboard";
import {BrowserView,MobileView,isBrowser,isMobile,} from "react-device-detect";
import VendorOrderHistory from './pages/DashboardVendor/vendorOrderHistory';
import WorkerAssignJob from './pages/DashboardVendor/workerOrderHistory';
import Order_details from "./pages/DashboardUser/order_details";
import Latestvendor_details from "./pages/DashboardVendor/latestvendor_details";
import WorkerList from "./pages/DashboardVendor/workerlist";
import Vendorassigntask from "./pages/DashboardVendor/vendorassigntask";
import Order_cancel from "./pages/DashboardUser/order_cancel";
import My_address from './pages/DashboardUser/my_address';
import Chat from "./pages/DashboardUser/chat";
import Feedback from "./pages/DashboardUser/feedback";
import Complete_order from "./pages/DashboardUser/complete_order";
import Vendorworkdetails from "./pages/DashboardVendor/vendorworkerdetails";
import Assigndetail from "./pages/DashboardVendor/assigndetail";
import RejectDetails from "./pages/DashboardVendor/reject_orderdetail";
import CompletedOrderDetails from "./pages/DashboardVendor/completeorder_detail";
import EditService from "./pages/DashboardVendor/editService" 
import UserProfile from "./pages/DashboardUser/user_profile";
import Revenuerecode from "./pages/DashboardVendor/revenuerecode";

//vendor 
import EditWorker from "./pages/DashboardVendor/EditWorker";
import VendorFeedback from "./pages/DashboardVendor/VendorFeedback";
import Vendorchat from "./pages/DashboardVendor/vendorChats";

// individual
import CreateIndividualProfile from "./pages/registerAsIndividual/createIndividualProfile";
import Vendorservice from "./pages/DashboardVendor/vendorservice";
import Service_Selection from "./pages/DashboardVendor/services_selection";
import Service_details from "./pages/DashboardVendor/service_detail";
import AddNewProvider from "./pages/DashboardVendor/addNewProvider";
import Providerlist from "./pages/ProviderList/providerList";
import Providerprofile from "./pages/ProviderList/providerProfile";
import Individualfeedbacks from "./pages/Dashboardindividual/IndividualFeedback";
import Individualservice_details from "./pages/Dashboardindividual/Individualservice_detail";
import Individual_Service from "./pages/Dashboardindividual/Individual_Service";
import Individualservice_Selection from './pages/Dashboardindividual/Individualservices_selection';
import IndividualOrderHistory from './pages/Dashboardindividual/IndividualOrderHistory';
import Edit_Service from "./pages/Dashboardindividual/Edit_service";
import IndividualDashboard from "./pages/Dashboardindividual/IndividualDashboard";
import IndividualProfile from "./pages/Dashboardindividual/IndividualProfile";
import Individualassigntask from './pages/Dashboardindividual/IndividualToday';
import Individualreject_order from "./pages/Dashboardindividual/Individualreject_order";
import Individualreject_orderdetail from "./pages/Dashboardindividual/Individualreject_orderdetail";
import Individualcompeleorder from './pages/Dashboardindividual/Individualcompleteorder';
import Individualcompleteorder_detail from "./pages/Dashboardindividual/Individualcompleteorder_detail";
import IndividualToday from "./pages/Dashboardindividual/IndividualToday";
import Individuallasttesttask from './pages/Dashboardindividual/Individuallastesttask';
import Todaylist_details from "./pages/Dashboardindividual/Todaylist_details";
import Individuallatest_detail from "./pages/Dashboardindividual/Individuallatest_detail";
import Individualrevenueorder from "./pages/Dashboardindividual/Individualrevenueorder";
import Individualchat from "./pages/Dashboardindividual/Individualchat";
// end
// worker
import WorkerDashboard from "./pages/workerDashboard/Dashboard";
import WorkerProfile from "./pages/workerDashboard/workerProfile";
import WorkerOrderHistory from "./pages/workerDashboard/workerOrderHistory";
import WorkerLatestDetails from "./pages/workerDashboard/WorkerLatestDetails";
import WorkerTodayOrderDetails from "./pages/workerDashboard/workerTodayOrderDetails";
import WorkerRevenue from "./pages/workerDashboard/Revenue";
import WorkerFeedback from "./pages/workerDashboard/workerFeedback";
import WorkerService from "./pages/workerDashboard/workerService";
import WorkerChat from "./pages/workerDashboard/workerChat";
//end worker

import UserDeleteRequest from "./pages/DashboardUser/deleteRequest";
function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/service-page":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  // bottom to top button
  const [isVisible, setIsVisible] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = (scrollTop / scrollHeight) * 100;

      setIsVisible(scrolled > 10); // Adjust the threshold as needed
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
    
    function topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }

  return (
    <>
      {isVisible ? (
        <button
        onClick={topFunction}
        id="btnArrowUp">
          <img src={process.env.PUBLIC_URL + "/images/up-arrow.png" } alt="bottom-to-top-btn" style={{width:"80%"}}/>
        </button>
        ) : (
        ""
      )}
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/services" element={<ServicePage />} />
      <Route path="/user_service_list/:key" element={<Providerlist/>} />
      <Route path="/provider_checkout" element={<Providercheckout />} />
      <Route path="/create_user_profile" element={<CreateProfile />} />
      <Route path="/about-us" element={<About/> } />
      <Route path="/contact" element={<Contact></Contact>}></Route>
      <Route path="/getapp" element={<Getapp />}/>
      <Route path="/create-company-profile" element={<CreateCompanyProfile />} />
      <Route path="/privacy-policy" element={<Privacy_policy></Privacy_policy>}></Route>
      <Route path="/term_condition" element={<Term_condition></Term_condition>}></Route>
      <Route path="/faq" element={<Faq></Faq>}></Route>

        {/* start user*/}
        <Route path="/user/my_profile" element={<UserProfile /> } />
        <Route path="/user_dashboard/order_history" element={<OrderHistory/> } />
        <Route path="/user/order-detail" element={<Order_details /> } />
        <Route path="/user/Order_details" element={<Order_details></Order_details>}></Route>
      <Route path="/user/Order_cancel/:key" element={<Order_cancel></Order_cancel>}></Route>
      <Route path="/user/My_address" element={<My_address></My_address>}></Route>
      <Route path="/user/chat" element={<Chat></Chat>}></Route>
      <Route path="/user/feedback" element={<Feedback/> }></Route>
      <Route path="/user/order_compelete" element={<Complete_order></Complete_order>}></Route>

      <Route path="/provider_profile" element={<Providerprofile /> } />
          {/* enduser */}
        {/* start vndor */}
      <Route path="/vendor/dashboard" element={<VendorDashboard/> } />
      <Route path="/vendor/revenuerecord" element={<Revenuerecode /> } />
      <Route path="/vendor/my-profile" element={<VendorProfile/> } />
       <Route path="/vendor/order-history" element={<VendorOrderHistory/>}></Route>
       <Route path="/vendor/worker-assign-job" element={<WorkerAssignJob/>}></Route>

       
       <Route path="/vendor/order_details" element={<Latestvendor_details/>}></Route>
       <Route path="/vendor/company-workers" element={<WorkerList />}></Route>
       <Route path="/vendor/add_new_provider" element={<AddNewProvider />}></Route>
       <Route path="/vendor/assign-task" element={<Vendorassigntask />}></Route>
       <Route path="/vendor/assigndetail" element={<Assigndetail />}></Route>
       <Route path="/vendor/reject_detail" element={<RejectDetails />}></Route>
       <Route path="/vendor/completed_order_detail" element={<CompletedOrderDetails/>}></Route>
       <Route path="/vendor/workdetails/:key" element={<Vendorworkdetails></Vendorworkdetails>}></Route>
       <Route path="/vendor/service" element={<Vendorservice />}></Route>
       <Route path="/vendor/service_selection" element={<Service_Selection></Service_Selection>}></Route>
       <Route path="/vendor/service_details/:key" element={<Service_details></Service_details>}></Route>
       <Route path="/vendor/edit_service" element={<EditService/>}></Route>
       <Route path="/vendor/feedbacks" element={<VendorFeedback/>}></Route>
       <Route path="/vendor/edit_worker" element={<EditWorker/>} ></Route>
         <Route path="/vendor/chats" element={<Vendorchat/> } />

      {/* end vndor */}

      {/* ////////////////////////////////// Individual ////////////////////////////// */}
      <Route path="/individual/create-profile" element={<CreateIndividualProfile />} />
      <Route path="/individual/dashboard" element={<IndividualDashboard />} />
      <Route path="/individual/profile" element={<IndividualProfile />} />
      <Route path="/individual/services" element={<Individual_Service />} />
     <Route path="/individual/service_Selections" element={<Individualservice_Selection></Individualservice_Selection>}></Route>
     <Route path="/individual/orderhistory" element={<IndividualOrderHistory></IndividualOrderHistory>}></Route>
     <Route path="/individual/service_details" element={<Individualservice_details></Individualservice_details>}></Route>
     <Route path="/individual/revenueorder" element={<Individualrevenueorder></Individualrevenueorder>}></Route>
     <Route path="/individual/feedbacks" element={<Individualfeedbacks></Individualfeedbacks>}></Route>
     {/* <Route path="/individual/service/:id" element={<Individual_Service />}></Route> */}
     <Route path="Individual/service_edit" element={<Edit_Service></Edit_Service>}></Route>
     <Route path="/individual/IndividualToday" element={<IndividualToday></IndividualToday>}></Route>
     <Route path="/individual/Individualreject" element={<Individualreject_order></Individualreject_order>}></Route> 
     <Route path="/individual/Individualcompeleorder" element={<Individualcompeleorder></Individualcompeleorder>}></Route>
     <Route path="/individual/latesttasklist" element={<Individuallasttesttask></Individuallasttesttask>}></Route>
     <Route path="/individual/Individualcompleteorder_detail" element={<Individualcompleteorder_detail></Individualcompleteorder_detail>}></Route>
     <Route path="/Individua/todaydetails" element={<Todaylist_details></Todaylist_details>}></Route>
     <Route path="/Individua/Individualreject_orderdetail" element={<Individualreject_orderdetail></Individualreject_orderdetail>}></Route>
     <Route path="/Individual/Individuallatest_detail" element={<Individuallatest_detail></Individuallatest_detail>}></Route>
     <Route path="/individual/chat" element={<Individualchat/> } />
        {/* ////////////////////////////////// End Individual ////////////////////////////// */}

        {/* //////////////////////////worker .////////////////////////// */}
        <Route path="/worker/dashboard" element={<WorkerDashboard />} />
        <Route path="/worker/profile" element={<WorkerProfile/> } />
        <Route path="/worker/orderhistory" element={<WorkerOrderHistory/> } />
        <Route path="/worker/latest_details" element={<WorkerLatestDetails/> } />
        <Route path='/worker/today_details' element={<WorkerTodayOrderDetails/>}/>
        <Route path="/worker/revenue" element={<WorkerRevenue/> } />
        <Route path="/worker/feedbacks" element={<WorkerFeedback/> } />
        <Route path="/worker/services" element={<WorkerService/> } />
        <Route path="/worker/chats" element={<WorkerChat/> } />
        {/* ////////////////////////end worker */}

      <Route path="/user/delete-request" element={<UserDeleteRequest/>} />

    </Routes>
    </>

  );
}
export default App;
