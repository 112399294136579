import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./home.css";
import Header from "../../components/header";
import HomeBanner from "../../slider/homeBanner";
import { Container, Row, Col } from "react-bootstrap";
import ServiceImage from "../../slider/serviceImage";
import Testimonial from "../../slider/testimonial";
import Footer from "../../components/footer";
import ServiceSlider from "../../slider/serviceSlider";
import axios from "axios";
import { apiBaseUrl, homePageApiUrl } from "../../config/constant";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Homemobile_banner from "../../slider/homemobile_banner";
import Modal from "react-bootstrap/Modal";

const Home = () => {
  const divRef = useRef(null);

  //Locat storage data
  const h_banner = JSON.parse(localStorage.getItem("home_bannerData"));
  const h_heading = JSON.parse(localStorage.getItem("home_headingData"));
  const h_services = JSON.parse(localStorage.getItem("home_servicesData"));
  const h_reason = JSON.parse(localStorage.getItem("home_reasonData"));
  const h_testiminial = JSON.parse(
    localStorage.getItem("home_testiminialData")
  );
  const h_bottomBanner = JSON.parse(
    localStorage.getItem("home_bottomBannerData")
  );
  const h_featuredService = JSON.parse(
    localStorage.getItem("home_featuredServiceData")
  );
  const h_popularservice = JSON.parse(
    localStorage.getItem("home_popularserviceData")
  );
  const h_general = JSON.parse(localStorage.getItem("home_generalData"));

  //End
  const navigate = useNavigate();
  const [bannerData, setBannerData] = useState(h_banner ? h_banner : []);
  const [headings, setHeadings] = useState(h_heading ? h_heading : []);
  const [services, setServices] = useState(h_services ? h_services : []);
  const [reasonToChoose, setReasonToChoose] = useState(
    h_reason ? h_reason : []
  );
  const [testimonial, setTestimonial] = useState(
    h_testiminial ? h_testiminial : []
  );
  const [bottomBanner, setBottomBanner] = useState(
    h_bottomBanner ? h_bottomBanner : []
  );
  const [featuredService, setfeaturedService] = useState(
    h_featuredService ? h_featuredService : []
  );
  const [popularservice, setpopularservice] = useState(
    h_popularservice ? h_popularservice : []
  );
  const [data, setData] = useState([]);

  const [city, setCity] = useState("Lagos");
  const [currentAddress, setCurrentAddress] = useState("");

  const [showFullContent, setShowFullContent] = useState(false);
  const [showFullContent_first, setShowFullContent_first] = useState(false);
  const [showFullContent_second, setShowFullContent_second] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false);
  const [value, setValue] = useState("");
  const [searchSubservices, setSearchSubservices] = useState("");

  // subscribe data////
  const [show2, setShow2] = useState(false);
  const headerRef = useRef();
  const openHeaderCompanyModal = () => {
    // console.log(headerRef);
    if (headerRef.current) {
      headerRef.current.openCompanyModel();
    }
    // headerRef.current.openCompanyModel();
  };
  const handleShow2 = () => {
    setShow2(true);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const handleSubscribe = async () => {
    if (!email) {
      setEmailError("Please enter an email address");
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      try {
        const response = await postEmailData();

        if (response.status === true) {
          Congratulation();
        }
      } catch (error) {
        // Handle the error if the subscription fails
        console.error("Error:", error);
      }

      handleShow2();

      // Reset the input field and error message
      setEmail("");
      setEmailError("");
    }
  };
  const validateEmail = (email) => {
    // A simple email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const postEmailData = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl + "common/subscribe-newsletter",
        {
          email: email,
        },
        {
          headers: {
            key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  async function fetchData() {
    try {
      const response = await axios.post(apiBaseUrl + homePageApiUrl, null, {
        headers: {
          key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
        },
      });
      setBannerData(response.data.response.result.top_banner);
      localStorage.setItem(
        "home_bannerData",
        JSON.stringify(response?.data?.response?.result?.top_banner)
      );
      setHeadings(response.data.response.result.pageheadings);
      localStorage.setItem(
        "home_headingData",
        JSON.stringify(response?.data?.response?.result?.pageheadings)
      );
      setServices(response.data.response.result.services);
      localStorage.setItem(
        "home_servicesData",
        JSON.stringify(response?.data?.response?.result?.services)
      );
      setReasonToChoose(response.data.response.result.reason);
      localStorage.setItem(
        "home_reasonData",
        JSON.stringify(response?.data?.response?.result?.reason)
      );
      setTestimonial(response.data.response.result.testmonial);
      localStorage.setItem(
        "home_testiminialData",
        JSON.stringify(response?.data?.response?.result?.testmonial)
      );
      setBottomBanner(response.data.response.result.bottom_banner);
      localStorage.setItem(
        "home_bottomBannerData",
        JSON.stringify(response?.data?.response?.result?.bottom_banner)
      );
      setfeaturedService(response.data.response.result.featuredService);
      localStorage.setItem(
        "home_featuredServiceData",
        JSON.stringify(response?.data?.response?.result?.featuredService)
      );
      setpopularservice(response.data.response.result.popularService);
      localStorage.setItem(
        "home_popularserviceData",
        JSON.stringify(response?.data?.response?.result?.popularService)
      );
    } catch (error) {
      console.error(error);
    }
  }

  const [isMobileView, setIsMobileView] = React.useState(false);
  React.useEffect(() => {
    const checkViewportWidth = () => {
      const isMobile = window.innerWidth <= 768;
      setIsMobileView(isMobile);
    };

    checkViewportWidth();
    window.addEventListener("resize", checkViewportWidth);

    return () => {
      window.removeEventListener("resize", checkViewportWidth);
    };
  }, []);

  const [combinedArray, setCombinedArray] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filtered = combinedArray.filter((suggestion) =>
      suggestion.combinedString.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSuggestions(filtered);
  }, []);
  const handleInputChange = (e) => {
    const newArray = services.map((service) => {
      const serviceName = service.service_name;
      const subServiceNames = service.sub_services
        .map((subService) => subService.sub_service_name)
        .join(", ");
      // Collect the _id values as well
      const serviceId = service._id;
      // Construct an object with the desired properties
      return {
        combinedString: `${serviceName} ${subServiceNames}`,
        serviceId,
      };
    });

    setCombinedArray(newArray);
    const inputValue = e.target.value;

    setValue(inputValue);
    setIsSuggestionsVisible(true); // Show suggestions when input changes
    // Filter suggestions based on inputValue and update filteredSuggestions state
    const filtered = combinedArray.filter((suggestion) =>
      suggestion.combinedString.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredSuggestions(filtered);
  };
  const addServiceData = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "common/get-general-data", {
        headers: {
          key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
          "Content-Type": "application/json",
        },
      });
      setData(response.data.response);
      localStorage.setItem(
        "home_generalData",
        JSON.stringify(response?.data?.response)
      );
      // console.log(response.data,"hii");
    } catch (error) {
      console.error(error);
    }
  };
  // --------------------------------getting location of the user

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

    // Make API call to Google Maps Geocoding API
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDgD56S6ZLFVJgzD8GPnMDPnV6vrhXGroY`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Check if there are any results
        if (data.results.length > 0) {
          setCurrentAddress(data?.plus_code.compound_code);
          // console.log(currentAddress,"dtappppppppppppppppppppppppppppp")
          const addressComponents = data.results[0].address_components;

          // Extract the city name from the address components
          const cityComponent = addressComponents.find((component) =>
            component.types.includes("locality")
          );

          if (cityComponent) {
            const cityName = cityComponent.long_name;
            // console.log(`City: ${cityName}`);
            setCity(cityName);
          }
        } else {
          console.log("No results found.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function error() {
    console.log("Unable to retrieve your location");
  }
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
    addServiceData();
  }, []);
  const handleSelect = (selectedValue) => {
    setSearchSubservices(selectedValue.serviceId);
    setValue(selectedValue.combinedString);
    setIsSuggestionsVisible(false); // Hide suggestions after selecting
  };
  // --------------------------------------------------

  // ---------------------handle Search------------------------
  const handleSearch = () => {
    if (!searchSubservices) {
    } else navigate(`/user_service_list/${searchSubservices}`, { state: city });
  };
  // -------------------------end ----------------------
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setIsSuggestionsVisible(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  return (
    <>
      <Header ref={headerRef} currentAddress={currentAddress} />

      <div style={{ width: "100%" }}>
        {/* <div className="homepage"> */}
        {/* <img className="homepage-child" alt="" src="/rectangle-10.svg" /> */}
        {!isMobileView && (
          <HomeBanner style={{ zIndex: "-1" }} bannerData={bannerData} />
        )}
        <div className="mobile_view_home_banner">
          <Homemobile_banner style={{ zIndex: "-1" }} bannerData={bannerData}></Homemobile_banner>
        </div>

        {/* <HomeBanner style={{ zIndex: "-1" }} bannerData={bannerData} /> */}
        {/* <img className="mask-group-icon5" alt="" src="/mask-group3@2x.png" /> */}
        {/* // </div> */}
        <div className="search-bx">
          <p>
            Experienced, hand-picked Professionals to serve you at your
            doorstep.
          </p>
          <div className="d-flex" ref={divRef}>
            <div className="serch-select" id="serach_service_input">
              <input
                type="text"
                placeholder="Which service you are looking for ?"
                value={value}
                onChange={handleInputChange}
              />
              {isSuggestionsVisible && (
                <ul>
                  {filteredSuggestions.map((suggestion, index) => (
                    <li key={index} onClick={() => handleSelect(suggestion)}>
                      {suggestion.combinedString}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="serch-select blur-effect" id="serach_service_input">
              <input type="text" placeholder={city} />
            </div>
            <button onClick={handleSearch}> Search</button>
          </div>
        </div>
        <div className="homepage" id="app-sectionID">
          <div className="app-section">
            <LazyLoadImage
              className="app-section-child"
              alt=""
              src="/rectangle-11.svg"
            />
            <LazyLoadImage
              className="app-section-item"
              alt=""
              src="/ellipse-5.svg"
            />
            <LazyLoadImage
              className="mobile-user-1-icon"
              alt=""
              src="/images/index_app_img.png"
            />

            <b className="download-the-app">Download the App</b>
            <div className="get-the-app-container">
              <p className="get-expert">
                {headings?.length > 0 && (
                  <>{headings[0].heading[0].description}</>
                )}
              </p>
              {/* <p className="get-expert">All in one free app.</p> */}
            </div>
            <div className="book-professional-experts-container">
              <p className="get-expert">
                {headings.length > 0 && (
                  <>
                    {headings[0].heading[0].title
                      .split(" ") // Split the title into an array of words
                      .slice(0, 3) // Take the first three words
                      .join(" ")}{" "}
                    {/* Join the first three words back into a string */} <br />
                    {headings[0].heading[0].title
                      .split(" ")
                      .slice(3) // Take the rest of the words
                      .join(" ")}{" "}
                    {/* Join the rest of the words back into a string */}
                  </>
                )}
              </p>
            </div>
            <LazyLoadImage
              className="btns-1-icon"
              alt=""
              src="/btns-1@2x.png"
            />
            {/* <img className="mask-group-icon6" alt="" src="/mask-group4@2x.png" /> */}

            <LazyLoadImage
              className="mobile-mockup-1-icon"
              alt=""
              src="/images/m-test.png"
            />
          </div>
        </div>

        <div className="container-chatbox">
          <div className="chatbox_wh">
            <a
              href={`https://wa.me/${data?.result?.whatsapp_no}`}
              target="_blank"
            >
              <LazyLoadImage
                src={process.env.PUBLIC_URL + "/images/whatsApp.png"}
                alt="WhatsApp"
              />
            </a>
          </div>
          {/* <div className="chatbox_rt">
    <img src={process.env.PUBLIC_URL + "/images/robot.png"} alt="Robot" />
  </div> */}
        </div>

        <div className="h-most-Popular-service">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h4 className="text_headings">
                  {headings.length > 0 && (
                    <>
                      {headings[0].heading[1].title
                        .split(" ")
                        .map((word, index) => {
                          if (index === 0) {
                            return (
                              <React.Fragment key={index}>
                                {word}{" "}
                              </React.Fragment>
                            );
                          } else if (index < 3) {
                            return <span key={index}>{word} </span>;
                          } else {
                            return (
                              <React.Fragment key={index}>
                                {word}{" "}
                              </React.Fragment>
                            );
                          }
                        })}
                    </>
                  )}
                </h4>
                <p>
                  {headings.length > 0 && (
                    <>{headings[0].heading[1].description}</>
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-4" style={{ paddingLeft: "8%" }}>
            <ServiceImage popularservice={popularservice} />
          </div>

          <div
            className="button-container services_btn"
            id="view_more_service_homepage"
          >
            <button class="button button1">
              <Link to="/services"> View More Services</Link>
            </button>
            <button class="button button2">
              <Link to="/services"> View More Services</Link>
            </button>
          </div>
        </div>

        <div className=" t-featured-service">
          <h4>
            {headings.length > 0 && (
              <>
                {headings[0].heading[2].title.split(" ").map((word, index) => {
                  if (index < 2) {
                    return <React.Fragment key={index}>{word} </React.Fragment>;
                  } else {
                    return <span key={index}>{word} </span>;
                  }
                })}
              </>
            )}
          </h4>
          <p>
            {headings.length > 0 && <>{headings[0].heading[2].description}</>}
          </p>
          <div className="test-tp">
            <div className="top-service">
              <ServiceSlider featuredService={featuredService} />
            </div>
          </div>
        </div>

        <div className="whyDoQwick">
          <img
            className="whyDoQwick"
            src={process.env.PUBLIC_URL + "images/bgMan.png"}
            alt="Background Image"
          />
        </div>

        <div className="whyDoQwick2">
          <div className="container">
            <h4 className="m-title">
              <span>
                {" "}
                {headings.length > 0 && <>{headings[0].heading[3].title}</>}
              </span>
              <img src={process.env.PUBLIC_URL + "/images/logo.png"} />
            </h4>
            <p className="m-subtitle">
              {headings.length > 0 && <>{headings[0].heading[3].description}</>}
            </p>
            <div className=" d-flex">
              <ul className="hover-effect">
                <li>
                  <h4>
                    <span>.</span> Expertise at Your Fingertips
                  </h4>

                  {showFullContent ? (
                    <p>
                      Our app connects you with a vast network of highly skilled
                      professionals who are masters of their trades. Whether you
                      need a craftsman, technician, or specialist,{" "}
                    </p>
                  ) : (
                    <p>
                      {" "}
                      DoQwik has the right expert to tackle any task with
                      precision and finesse. DoQwik brings a world of expertise
                      to your doorstep.{" "}
                    </p>
                  )}
                  <div className="read_more_div">
                    <button
                      onClick={() => setShowFullContent(!showFullContent)}
                      className="read_more"
                    >
                      {showFullContent ? "Read Less" : "Read More"}
                    </button>
                  </div>
                </li>
              </ul>

              <ul className="hover-effect">
                <li>
                  <h4>
                    <span>.</span> Fast and Efficient Service
                  </h4>
                  {showFullContent_first ? (
                    <p>
                      {" "}
                      prompt and efficient service. Say goodbye to long wait
                      times and hello to professionals who show up on time,
                      ready to work their magic and get the job done swiftly.{" "}
                    </p>
                  ) : (
                    <p>
                      Time is of the essence, and DoQwik understands that. Our
                      app is designed to streamline the process from start to
                      finish, ensuring{" "}
                    </p>
                  )}

                  <button
                    onClick={() =>
                      setShowFullContent_first(!showFullContent_first)
                    }
                    className="read_more"
                  >
                    {showFullContent_first ? "Read Less" : "Read More"}
                  </button>
                </li>
              </ul>

              <ul className="hover-effect">
                <li>
                  <h4>
                    <span>.</span> Hassle-Free Booking
                  </h4>
                  {showFullContent_second ? (
                    <p>
                      , and book an appointment in just a few taps. No more
                      lengthy phone calls or complicated forms—DoQwik makes the
                      booking process seamless and stress-free.{" "}
                    </p>
                  ) : (
                    <p>
                      With DoQwik, booking a handyman service is a breeze. Our
                      user-friendly app allows you to effortlessly browse
                      through services, select your desired professional{" "}
                    </p>
                  )}

                  <button
                    onClick={() =>
                      setShowFullContent_second(!showFullContent_second)
                    }
                    className="read_more"
                  >
                    {showFullContent_second ? "Read Less" : "Read More"}
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div></div>
        </div>

        <div className="testimonial">
          <h4>
            {/* What <span> People Think</span> About Our Services */}

            {headings.length > 0 && (
              <>
                {headings[0].heading[4].title.split(" ").map((word, index) => {
                  if (index === 0) {
                    return <React.Fragment key={index}>{word} </React.Fragment>;
                  } else if (index < 2) {
                    return <span key={index}>{word} </span>;
                  } else {
                    return <React.Fragment key={index}>{word} </React.Fragment>;
                  }
                })}
              </>
            )}
          </h4>
          <p>
            Our customers send us bunch of smiles with our services and love of
            them.
          </p>
          <Testimonial Testimonial={testimonial} />
        </div>
        {bottomBanner.map((item, index) => (
          <div className="joinDoqwik2" key={index}>
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-lg-5 col-xl-5 px-0">
                  {!isMobileView && (
                    <img src={item.image} alt="Background Image" />
                  )}
                </div>
                <div className="col-md-12 col-lg-12 col-xl-7" id="joinDpqdnkj">
                  <h4>
                    {item.title.map((word, wordIndex) => {
                      if (wordIndex === 1) {
                        return (
                          <span key={wordIndex} className="different-color">
                            {word}
                          </span>
                        );
                      } else if (wordIndex < 2) {
                        return (
                          <React.Fragment key={wordIndex}>
                            {word}{" "}
                          </React.Fragment>
                        );
                      }
                    })}
                  </h4>
                  <p>{item.title[2]}</p>
                  <button onClick={openHeaderCompanyModal}>
                    Join As A Company
                  </button>
                </div>
              </div>
            </div>

            <div></div>
          </div>
        ))}
        {/* <div className="joinDoqwik2 ">
  <div className="container">
  <div className="row">
    <div className="col-md-5 col-lg-5 col-xl-5 px-0">
    {!isMobileView && (
<img src={process.env.PUBLIC_URL + 'images/join_aap.webp'} alt="Background Image" />
)}
    </div>
    <div className="col-md-12 col-lg-12 col-xl-7">
      <div className="join_heading">
      <h4>
  Join DoQwik as a company<span> To Open Your Business. </span>
  </h4>
  <p>
  What ever work you do, we will find you the jobs you want.
  </p>
  <button  onClick={joinshow} >Join As A Company</button>
      </div>
   
    </div>
  </div>
  </div>
</div> */}

        <div className="stay-update ">
          <Container>
            <div className="d-flex">
              <div style={{ width: "50%" }}>
                <h4>Stay up to date with our Newsletter</h4>
                <p>
                  Keep up with our latest services. Subscribe to our newsletter
                </p>
              </div>

              <div>
                <div className="btn-bottom d-flex justify-content-between">
                  <input
                    type="email"
                    placeholder="Enter your Email Address"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <button onClick={handleSubscribe}>Subscribe Now</button>
                </div>
                {emailError && (
                  <p
                    className="error-message error-subscribe"
                    id="subscribe-email"
                  >
                    {emailError}
                  </p>
                )}
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Footer data={data} />

      <Modal
        show={show2}
        centered
        style={{
          width: "351px",
          marginLeft: "40%",
          borderRadius: "34px !important",
        }}
        ClassName="custom-modal-dialog"
      >
        <Modal.Body>
          <div
            className="cross-X"
            onClick={() => {
              window.location.reload();
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "../images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "../images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div
            className="congrats-profile-created  well_done"
            style={{ marginTop: "0px" }}
          >
            <img
              src={process.env.PUBLIC_URL + "../images/greeting.gif"}
              alt="playstore"
              className="gif"
            />

            <p>
              Thank you for subscribing to DoQwik Services, We will keep you
              posted on our updates.
            </p>
            <div
              class="button-container"
              style={{ width: "150px" }}
              onClick={() => {
                window.location.reload();
              }}
            >
              <button className="button button1"> Proceed To Next</button>
              <button className="button button2">Proceed To Next</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Home;

// for back opacity .banner-text-background2,
// .mask-group2 {
