import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import "./vendor.css";
import VendorOrderHistory from "./vendorOrderHistory";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import DashboardAsVendor from "../../components/dashboardAsVendor";
import { useLocation } from "react-router-dom";
import { getSession } from "../../utils/helper";
import { apiBaseUrl, BaseUrl } from "../../config/constant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar} from '@fortawesome/fontawesome-free-solid';
import axios from "axios";
import { getAgoTime, getAgoTime2 } from "../../components/common";

const Latestvendor_details = () => {
  const location = useLocation();
  const data = location.state;
  const token = getSession("token");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [workerId, setWorkerId] = useState();
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [workerData, setWorkerData] = useState([]);
  const [jobDetails, setJobDetails] = useState(null);
  const [showRate,setShowRate]=useState(false);
  const handleShowRate =()=>{
    setShowRate(true) 
  }


  // const [timestamp, setTimestamp] = useState(1693224893591); // Replace with your actual timestamp

const timeReple=jobDetails?.userFeedback?.replayAt
const timeReple2=jobDetails?.userFeedback?.feedbackAt
// console.log(timeReple,"PP")
  const [timestamp, setTimestamp] = useState();
  const [timestamp2, setTimestamp2] = useState();

useEffect(()=>{
  setTimestamp(timeReple)
  setTimestamp2(timeReple2)

},[timeReple])
  useEffect(() => {
    const interval = setInterval(() => {
      setTimestamp(prevTimestamp => prevTimestamp + 1000); // Simulating real-time updates
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const intervalUserSide = setInterval(() => {
      setTimestamp2(prevTimestamp => prevTimestamp + 1000); // Simulating real-time updates
    }, 1000);

    return () => clearInterval(intervalUserSide);
  }, []);



  const handleClose4=()=>{
    setShowRate(false);
  }
  const [errorsForRating,setErrorsForRating]=useState('');
  const [rating, setRating] = useState(0);
  const [result, setResult] = useState(
    "Are you sure you want to reject this order?"
  );
  const [responseData, setResponseData] = useState(null);
  const[formValues, setFormValues]=useState({
    jobId : data._id,
    message : "Thank You!"
})

const [formDataforRate, setFormDataforRate] = useState({
  jobId: data._id,
  message: "",
  star: rating,
});

const [formData, setFormData] = useState({
  jobId: data._id,
  locationID: data._id,
  service_date: "",
  service_time: "",
  service_type: "Home Cleaning",
  // worker_id: key,
});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          apiBaseUrl + "order/get-job-details",
          {
            jobId: data._id,
          },
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
        setJobDetails(response.data.response.result);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchWorkerData = async () => {
      try {
        const response = await axios.post(
          apiBaseUrl+"provider/company-service-worker-list",
          {
            serviceId: "6487f65e9eb0047604344bd3",
          },
          {
            headers: {
              Authorization: token,
              "content-type": "application/json",
            },
          }
        );
        console.log(response, "kkk");
        setWorkerData(response?.data?.response?.result);

        // Assuming the response contains the data you need
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchWorkerData();
  }, []);

  const fetchData = async (id) => {
    try {
      const response = await axios.post(
        apiBaseUrl+"order/assign-job-to-worker",
        {
          jobId: data._id,
          workerID: workerId || id,
          updateIp: ":1",
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      setResponseData(response?.data?.response?.result);
      console.log(response);
      return response?.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      if(error.response.data.responseCode===400){
        alert(error.response.data.statusMessage)
      }
    }
  };

  const fetchDataReject = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl+"order/job-reject",
        {
          jobId: data._id,
          rejectReason: "Other",
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error );
    }
  };

  const handleAssign = (id) => {
    setWorkerId(id);
    fetchData(id)
      .then((data) => {
        if (data.status === true) {
          handleClose();
          handleShow3();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleReject = () => {
    fetchDataReject()
      .then((data) => {
        if (data.status === true) {
          // handleClose();
          window.location.href ="/vendor/order-history";
        
        }
      })
      .catch((error) => {
        // Handle error, if needed
        console.error("Error:", error);
      });
  };
   const handleChangeFeedback=(e)=>{
const name=e.target.name;
const value=e.target.value
  const updatedFormValues = {
      ...formValues,
      [name]: value
    };
    setFormValues(updatedFormValues);
   }

   const dataFeedbackPost = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl + "/front/v1/order/reply-feedback",
        formValues,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      // setJobDetails(response.data.response.result);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleReplyFeedback=()=>{
    dataFeedbackPost()
  }

  const handleStarClick = (clickedRating) => {
    setRating(clickedRating);
    setFormDataforRate((prevData) => ({
      ...prevData,
      star: clickedRating,
    }));
  };
  const handleTextareaChange = (e) => {
    setErrorsForRating("")
    const messageValue = e.target.value;
    setFormDataforRate((prevData) => ({
      ...prevData,
      message: messageValue,
    }));
  };  


  
  const FeedbackForm  = async () => {
      
    if(formDataforRate.message){
        const headers = {
          Authorization: token,
            "Content-Type": "application/json",
        };
        try {
          const response = await axios.post(apiBaseUrl+"order/reply-feedback", formDataforRate, { headers });
          console.log("Response3:", response.data);
          if(response.data.status===true){
            handleClose4()
            handleShow3()
          }     
        } catch (error) {
          console.error("Error:", error);
        }
      }else setErrorsForRating('Please Filled Your Feedback')
    };

    // //////////////////////////////////////////review in star/////////////

    const [ratingStar, setRatingStar] = useState(0);
    const maxRating = 5;
    useEffect(() => {
      // Update the rating value based on the API data
      setRatingStar(jobDetails?.userFeedback?.star);
    }, [jobDetails?.userFeedback?.star]);
  return (
    <>
      <Header />
      <div className="dashboard">
        <div className="container">
          <div className="row mx-0">
            <DashboardAsVendor />
            <div className="col-md-12 col-lg-9">
              <div className="vendors_right_forms">
                <div className="row">
                  <div className="col-ms-12">
                    <h1 className="my_order">Order Details </h1>
                  </div>
                </div>
                <div className="right_side  mt-3">
                  <div className="row mx-0">
                    <div className="col-md-12 col-lg-8">
                      <div className="btn_es_align mob-none">
                        <button className="arrow_down">
                          <Link to="/vendor/order-history">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/vendor_dashboard/order_detail/order_details.png"
                              }
                            />
                          </Link>
                        </button>
                        { jobDetails && jobDetails?.job_status==="Complete" || jobDetails?.job_status==="Reject" || jobDetails?.job_status==="Assign" || jobDetails?.job_status==="Accept" || jobDetails?.job_status==="Start" ? "":
                        jobDetails?
                        <div className="reschudle">
                          <button
                            className="button button_reschdule"
                            onClick={handleShow}
                          >
                            Assign To Worker
                          </button>
                          <button
                            className="button button_reschdule--hover"
                            onClick={handleShow}
                          >
                            Assign To Worker
                          </button>
                        </div>:""
                        
                        }
                        {jobDetails && jobDetails?.job_status==="Complete" || jobDetails?.job_status==="Reject" || jobDetails?.job_status==="Accept" || jobDetails?.job_status==="Start" ? "" :
                         jobDetails?
                        <div className="cancel_book">
                          <button
                            className="button button_cancel_book"
                            onClick={handleShow2}
                          >
                            Reject Order
                          </button>
                          <button
                            className="button button_cancel--hover"
                            onClick={handleShow2}
                          >
                            Reject Order
                          </button>
                        </div>
                        :""
                        }
                      </div>

                      <div className="card_details">
                        <div className="card_heading">
                          <h1>Service Location</h1>
                        </div>
                        <div className="card_content">
                          <span>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/card_location.png"
                              }
                              alt="app"
                            />

                            <p> 
                              {data?.service_location?.land_mark},
                              {data?.service_location?.address1},
                              {data?.service_location?.city},
                              {data?.service_location?.state},
                              {data?.service_location?.postal_code},
                              {data?.service_location?.country}
                            </p>
                          </span>
                        </div>
                      </div>
                      <div className="card_details">
                      <div className="card_heading">
                        <h1>Selected Provider</h1>
                      </div>
                      <div className="card_content">
                        <div className="select_provider">
                          { jobDetails?.providerData?.profile_pic? 
                          <div>
                            <img
                              src={
                                BaseUrl+
                                jobDetails?.providerData?.profile_pic
                              }
                              alt="app"
                              className="worker_img"
                            />{" "}
                          </div>
                          :""}
                          <div className="select_provider_content">
                            <h1>{jobDetails?.providerData?.fullName} </h1>
                            <p>
                              {" "}
                              {jobDetails?.providerData?.land_mark},
                              {jobDetails?.providerData?.address1},
                              {jobDetails?.providerData?.city},
                              {jobDetails?.providerData?.state},
                              {jobDetails?.providerData?.postal_code},
                              {jobDetails?.providerData?.country}
                            </p>
                          </div>

                          <div>
                            <span>
                              <img
                                src={
                                  process.env.PUBLIC_URL + "../images/Star.png"
                                }
                                className="star_img"
                              />
                              <p>{jobDetails?.providerData.averageRating?jobDetails?.providerData.averageRating:"0.0"}</p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                      <div className="card_details">
                        <div className="card_heading">
                          <h1>Slot Date & Timing</h1>
                        </div>
                        <div className="card_content">
                          <ul>
                            <li>
                              <span>Service Date</span>{" "}
                              <p> {data.service_date}</p>
                            </li>
                            <li>
                              <span>Service Timing</span>{" "}
                              <p>{data.service_time}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card_details mb-2">
                        <div className="card_heading">
                          <h1>Service Detail</h1>
                        </div>
                        <div className="card_content">
                        {jobDetails?.service_name? <h2> {jobDetails?.service_name},{jobDetails?.sub_service_name}  </h2> : ""}

                        {jobDetails?.job_desc}
                        {jobDetails?.jobImage?
                          <div className="plumbering_img">
                          <img
                            src={BaseUrl+jobDetails?.jobImage  }
                            alt="service_image"
                          />
                          </div>
                          : ""
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                    <div className="work_status_processing">
                     <input placeholder="Work Status"  className="payment_status"/>
                            <button className="processing">{data.job_status==='Assign'? 'Assigned'  : data.job_status==='Reject'? 'Rejected':data.job_status}</button>
                          
                          </div>
                      <div className="payment_details" style={{marginTop:"20px"}}>
                        <h1>Payment Detail</h1>
                        <div className="vendor_payment_status">
                          <input
                            placeholder="Payment Status"
                            className="payment_status"
                            readOnly
                          />
                          <button className="prepaid_post">
                            {data.paymentStatus==='Initialize'? 'Postpaid': data.paymentStatus}
                          </button>
                        </div>
                        <ul className="mt-2">
                          <li className="pt-0">
                            <p>Total Amount</p> <span>₦ {data.amount}</span>
                          </li>
                          <li>
                            <p>Discount</p>{" "}
                            <span className="text_change_color">
                              ₦{" "}
                              {data.discount_amount
                                ? data.discount_amount
                                : "0"}
                            </span>
                          </li>
                          <li>
                            <p>Other Charges</p>
                            <span>
                              {data.other_charge === "0" ? (
                                <p>Free</p>
                              ) : (
                                data.other_charge
                              )}
                            </span>
                          </li>
                          <li className="pb-0 bottom_none">
                            <p className="text_change_color">Amount Paid</p>{" "}
                            <span className="text_change_color">
                              ₦ {data.amount}
                            </span>
                          </li>
                        </ul>
                      </div>

                     {jobDetails?.userFeedback?
                      <>
<div className="payment_details  mt-4">
                      <h1>Feedback</h1>
                      </div>
                     <div className="feedback_reviwes">
                        <div className="review_header">
                            <div className="rating">
                                <div className="rating_left_side">
                                
                                <div>
      {Array.from({ length: maxRating }).map((_, index) => (
        <FontAwesomeIcon
          key={index}
          icon={faStar}
          style={{ color: index < jobDetails?.userFeedback?.star ? "#FFCC00" : "grey" }}
        />
      ))}
    </div>
                                  
                                </div>
                                <div className="rating_right_side">
                                <p>{getAgoTime2(timestamp2)} </p>
                            </div>
                            </div>
                          
                        </div>
                        <div className="review_content">
                        
                       <p>{jobDetails?.userFeedback?.message}</p>
                          </div>
                        <div className="reviw_inner_content">
                        <div>
                        {/* <img src={process.env.PUBLIC_URL + "../images/worker.png"} alt="app"/> */}
                         </div>
                        
                                
                                <div className="review_content">
                                <div className="rating">
                                <div className="rating_left_side">
                                
                                <div>
      {Array.from({ length: maxRating }).map((_, index) => (
        <FontAwesomeIcon
          key={index}
          icon={faStar}
          style={{ color: index < jobDetails?.userFeedback?.star ? "#FFCC00" : "grey" }}
        />
      ))}
    </div>
                                  
                                </div>
                                <div className="rating_right_side">
                                <p>{getAgoTime(timestamp)} </p>
                            </div>
                            </div>
                                <div className="review_footer">
                                {jobDetails?.userFeedback?.reply? <p>{jobDetails?.userFeedback?.reply} </p>:
                                <div class="reviewer_btn mt-3 mb-2" onClick={handleShowRate}>
                    <button class="button button1">Reply To Feedback</button>
                    <button class="button button2">Reply To Feedback</button>
                    </div>
                                }
                    
                     </div>
                                {/* <div className=" comment-box mt-3" >
                        <input
                          type="text"
                          name="message"
                          placeholder="Type your comment here"
                          onChange={handleChangeFeedback}
                        />
                        <img
                          src={process.env.PUBLIC_URL + "/images/sent.png"}
                          alt="ads"
                          style={{
                            width: "18%",
                            height: "15px",
                            marginTop: "18px",
                            paddingRight: "14px",
                          }}
                          onClick={handleReplyFeedback}
                        />
                      </div> */}
                            </div>
                          <div>
                            {/* <span className="reviews_star"><img src={process.env.PUBLIC_URL + "../images/Star.png"} className="star_img"/><p>4.6</p></span> */}
                        </div>
                     </div>
            
                    </div>
</>: ""
                     }

                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Modal show={show} onHide={handleClose} id="worker" size="sm">
        <div className="model_header">
          <div className="header_viewes">
            <p>Provider List</p>
            <h1>Select Provider To Assign Job</h1>
          </div>
          <div className="">
            <img
              src={process.env.PUBLIC_URL + "../images/model_cross.png"}
              alt="app"
              className="cross_icon"
              onClick={handleClose}
            />
          </div>
        </div>

        <Modal.Body className="py-0" id="model_content">
          <div className="row mx-0">
            {workerData.map((item, index) => (
              <div className="col-sm-12 col-md-12 col-lg-6" key={index}>
                <div className="worker-list-card mb-2">
                <div className="row mx-0">
                    <div className="col-md-4 col-sm-4">
                     
                      <img
                        src={BaseUrl+`${item?.profile_pic?item?.profile_pic:'/images/avatar-default-icon.png'}`}
                        className="vendor_img"
                      />
                    </div>
                    <div className="col-md-8 col-sm-8 px-0">
                      <div className="worklist_username row mx-0">
                        <div className="col-md-12">
                          <h1>{item?.fullName}</h1>
                          <p>{item?.land_mark||item?.address1||item?.city||item?.state||item?.postal_code||item?.country?
                            
                            `${item?.land_mark}, ${item?.address1}, ${item?.city}, ${item?.state}, ${item?.postal_code}, ${item?.country}`
                          :""}
                          </p>
                        </div>
                        <div className="col-md-12 px-0">
                          <div
                            className="assign-worker-container"
                            id="assign_work"
                          >
                            <button
                              className="button assign-worker"
                              onClick={() => {
                                handleAssign(item);
                              }}
                            >
                              Assign Job List
                            </button>
                            <button
                              className="button assign-worker1"
                              onClick={() => {
                                handleAssign(item);
                              }}
                            >
                              Assign Job List
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
        size="sm" // Add this line to set the size to small (sm)
        centered
      >
      
        <Modal.Body>  <div
          className="cross-X"
          onClick={handleClose2}
          style={{ position: "absolute", right: "15px" }}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/user/cross.png"}
            alt="playstore"
          />
          <img
            src={process.env.PUBLIC_URL + "/images/user/X.png"}
            alt="playstore"
          />
        </div>{result ? result : ""}</Modal.Body>
        <Modal.Footer>
          <div className="proceed_next mt-4 mb-4">
            <div
              class="button-container  mt-4 mb-4"
              style={{ marginRight: "5px", width: "100px" }}
            >
              <button
                class="button button1"
                id="btn-otp-resend"
                style={{ width: "100px" }}
                onClick={handleClose2}
              >
                No
              </button>
              <button
                class="button button2"
                style={{ width: "100px" }}
                onClick={handleClose2}
              >
                No
              </button>
            </div>

            <div
              class="button-container proccess_next mt-4 mb-4 mr-0"
              style={{ marginLeft: "15px", width: "100px" }}
              onClick={handleReject}
            >
              <button
                class="button button1"
                style={{ marginLeft: "5px", width: "100px" }}
                onClick={handleReject}
              >
                {" "}
                Yes{" "}
              </button>
              <button
                class="button button2"
                style={{ marginLeft: "5px", width: "100px" }}
                onClick={handleReject}
              >
                {" "}
                Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
        size="sm" // Add this line to set the size to small (sm)
        centered
      >
        <div
          className="cross-X"
          onClick={() => {
            handleClose3();
            window.location.reload();
          }}
          style={{ position: "absolute", right: "3px", top:"15px" }}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/user/cross.png"}
            alt="playstore"
          />
          <img
            src={process.env.PUBLIC_URL + "/images/user/X.png"}
            alt="playstore"
          />
        </div>
        <Modal.Body>Order has been assigned</Modal.Body>
        <Modal.Footer>
          <div className="proceed_next mt-4 mb-4">
            <div class="button-container proccess_next mt-4 mb-4 mr-0" style={{width:"232px"}}>
              <button
                class="button button1"
                onClick={() => {
                  window.location.reload();
                }}
               
              >
                {" "}
                Proceed To Next
              </button>
              <button
                class="button button2"
                onClick={() => {
                  window.location.reload();
                }}
               
              >
                {" "}
                Proceed To Next
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* feedback from provider  */}


      <Modal show={showRate} onHide={handleClose4} id="model_rating" className=" slideInUp">
    <div className="model_header">
        <div className="header_viewes">
            <h1>Your Valuable Feedback</h1>
        </div>
         <div className="">
         <img src={process.env.PUBLIC_URL + "../images/model_cross.png"} alt="app" className="cross_icon" onClick={handleClose} />
         </div>
        </div>
         
        <Modal.Body className="py-0">
          <Form>
            <div className="row mx-0">
               
                <div className="col-md-12">
                    <div className="text_rating">
                    <p>Rate Us</p>
      <div className="multiple_stars_content">
        <ul>
          {[1, 2, 3, 4, 5].map((index) => (
            <li
              key={index}
              onClick={() => handleStarClick(index)}
            >
              <FontAwesomeIcon
                icon={faStar}
                className={index <= rating ? "star_yellow" : "star"}
              />
            </li>
          ))}
        </ul>
      </div>
      <textarea
        name="message"
        placeholder="Feedback message"
        value={formData.message}
        onChange={handleTextareaChange}
      />

      {errorsForRating? <p className="error" id="error_feedback">{errorsForRating}</p> :""}
                     </div> 
                </div>
                
            </div>
           
          </Form>
        </Modal.Body>
        <Modal.Footer >
            <div className="model_submit" onClick={FeedbackForm}>
          <button  className=" button submit_close">Submit</button>
          <button  className="button submit_close_hover">Submit</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Latestvendor_details;
