import React from "react";
import Slider from "react-slick";

const HomemobileBanner = (props) => {
  const{bannerData}=props;
  const slides = [
    {
      heading: 'Get Expert ',
      heading_subheading_1: 'Professional Services',
      heading_subheading_2: 'At Your Door Step.',
      image: '../images/line-1.svg',
      service_heading_banner: 'Quality home services, on demand',
      service_subheadings: 'DoQwik is your one-stop destination for expert <br />  local services.Get dozens of trusted professionals near you to take  <br /> care of all your home and beauty.',
    },
    {
      heading: ' Dont settle for subpar',
      heading_subheading_1: 'service or compromise',
      heading_subheading_2: 'on quality.',
      image: '../images/line-1.svg',
      service_heading_banner: 'With DoQwik, excellence is  non-negotiable.',
      service_subheadings: '  We ve forged a community of  dependable and skilled professionals <br /> who are fervent about their craft  and committed to surpassing your <br/> expectations. Your satisfaction reigns supreme.',
    },
    {
      heading: 'Get ready to unleash',
      heading_subheading_1: 'your productivity like',
      heading_subheading_2: 'never before.',
      image: '../images/line-1.svg',
      service_heading_banner: ' Embrace the dynamic power of DoQwik,the ultimate handyman app',
      service_subheadings: '   Download DoQwik now and embark on <br /> a transformative journey towards  unparalleled  <br /> success.The future is within your grasp!',
    }
  ];

  const settings = {
    dots: false,
    dotsClass: 'slick-dots  customDotsClass',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    initialSlide: 1,
    autoplay: false,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div>
      <Slider {...settings}>
        {bannerData.map((item, index) => (
          <div key={index} className="home_mobile_main_banner">
            <div className="home_mobile">
              <p className="home_mobile_heading">{item.title[0]}</p>
              <p className="home_mobile_heading">{item.title[1]}</p>
              <p className="home_mobile_heading">{item.title[2]}</p>
            </div>
            <img src='../images/line-1.svg' className="banner-text-child_first" />
            <div className="home_mobile_heading_service">
            {item.title[3]}
            </div>
            {/* <img src={slide.image} alt={`Slide ${index + 1}`} className="banner-text-child_first" /> */}
            <img src='../images/line-1.svg' className="banner-text-child_first" />
            <div className="mobile_container">
              <p className="home_mobile_heading_service" >   {item.title[4]} <br/> {item.title[5]} <br/>  {item.title[6]}</p>

            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HomemobileBanner;
