import { getSessionForLoginUser } from "../../utils/helper";
import { firebaseWeb } from "../../firebase";
import {
  getDatabase,
  ref,
  query,
  orderByChild,
  equalTo,
  push,
  onValue,
  set
} from "firebase/database";
const database = getDatabase(firebaseWeb);
  const databaseRef = ref(database, "/");

const userData = getSessionForLoginUser('user')

export const ChatList = () => {
  try {
    const userId = userData?._id;
    const toUserId = profileData?._id;
    // const database = firebaseWeb.database();
    const chatRef = ref(database, `/messages/${userId}/${toUserId}`);
    const unsubscribe = onValue(chatRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Data exists, process it
        let messages = Object.entries(data).map(([key, messageData]) => {
          return {
            sender: messageData.sender,
            receiver: messageData.receiver,
            message: messageData.message,
            image: messageData.image,
            createdAt: messageData.createdAt,
          };
        });

        // Sort messages by createdAt in descending order
        messages.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

        setChatData(messages);
      } else {
        // Data doesn't exist or is empty, handle accordingly
        setChatData([]);
      }
    });

    // Clean up the listener when the component unmounts
    return () => {
      unsubscribe();
    };
  } catch (error) {
    console.error(error);
    // Handle the error using your Alert component or other error-handling method
  }

}

export const SendMessage = async (currentUid, toUid, message, image, createdAt) => {
  try {
    const receiverId = typeof toUid === 'object' ? toUid.id || toUid._id : toUid;

    const data = {
      sender: currentUid,
      receiver: receiverId,
      message: message || '',
      image: image || '',
      createdAt: createdAt
    };


    const messageRef = ref(database, `/messages/${currentUid}/${receiverId}`);
    // Make sure 'database' is defined and initialized correctly

    await push(messageRef, data);
    console.log(" SendMessage Data set.");
  } catch (error) {
    console.error(error);
    return error;
  }
}

export const ReceiveMessage = async (currentUid, toUid, message, image, createdAt) => {
  try {
    const receiverId = typeof toUid === 'object' ? toUid.id || toUid._id : toUid;

    const data = {
      sender: currentUid,
      receiver: receiverId,
      message: message || '',
      image: image || '',
      createdAt: createdAt
    };

    const messageRef = ref(database, `/messages/${receiverId}/${currentUid}`);
    // Make sure 'database' is defined and initialized correctly

    await push(messageRef, data);
    console.log('ReceiveMessage Data set.');
  } catch (error) {
    console.error(error);
    return error;
  }
}

// export const ReceiveMessage = async (currentUid, toUid, message, image, createdAt) => {
//     try{
//       const receiverId = typeof toUid === 'object' ? toUid.id || toUid._id : toUid;

      
//     const data = {
//       sender: currentUid,
//       receiver: receiverId,
//       message: message || '',
//       image: image || '',
//       createdAt: createdAt
//     };

//     const messageRef = ref(database, `/messages/${receiverId}/${currentUid}`);
//     await push(messageRef, data);
//     console.log("ReceiveMessage Data set.");

//     }catch (error) {
//         console.log(error);
//         return error;
//     }
// }


// export const SendUserInfo = async (currentUid, toUid, message, image,senderInfo, createdAt) => {
//     try{
//       const receiverId = typeof toUid === 'object' ? toUid.id || toUid._id : toUid;
//       const data= {
//         sender: currentUid,
//         receiver: receiverId,
//         message: message || '',
//         image: image || '',
//         userInfo: JSON.stringify(senderInfo),
//         createdAt: createdAt
//     }
//         const messageRef = ref(database,`/users/${currentUid}/${toUid}`);
//      await set(messageRef, data).then(() => console.log(' SendUserInfo Data set.'));

//     }
//     catch (error) {
//         console.log(error);
//         return error;
//     }
// }

export const SendUserInfo = async (currentUid, toUid, message, image, recieverInfo, createdAt) => {
  const address = recieverInfo.state ?recieverInfo.state+' '+recieverInfo.city :  recieverInfo.address
  try {
    const receiverId = typeof toUid === 'object' ? toUid.id || toUid._id : toUid;
    const userData = {
      id          : recieverInfo._id || receiverId,
      name        : recieverInfo.fullName ||recieverInfo.name ,
      address     : address,
      profile_pic : recieverInfo.profile_pic
    }
    // console.log('userData',userData);
    // console.log('recieverInfo',recieverInfo);
    const data = {
      sender: currentUid,
      receiver: receiverId,
      message: message || '',
      image: image || '',
      userInfo: JSON.stringify(userData),
      createdAt: createdAt
    };
    console.log('Send Data',data)

    const messageRef = ref(database, `/users/${currentUid}/${receiverId}`);
    // Make sure 'database' is defined and initialized correctly

    await set(messageRef, data);
    // console.log('SendUserInfo Data set.');
  } catch (error) {
    console.error(error);
    return error;
  }
}


export const RecieveUserInfo = async (currentUid, toUid, message, image, senderInfo, createdAt) => {
  try {
    const address = senderInfo.state?senderInfo.state+' '+senderInfo.city : senderInfo.address
    const receiverId = typeof toUid === 'object' ? toUid.id || toUid._id : toUid;
    const userData = {
      id          : senderInfo._id || senderInfo.id,
      name        : senderInfo.fullName ||senderInfo.name,
      address     : address,
      profile_pic : senderInfo.profile_pic
    }
    const data = {
      sender: receiverId,
      receiver: currentUid,
      message: message || '',
      image: image || '',
      userInfo: JSON.stringify(userData),
      createdAt: createdAt
    };
    console.log('Receive Data',data)
    const messageRef = ref(database, `/users/${receiverId}/${currentUid}`);
    // Make sure 'database' is defined and initialized correctly

    await set(messageRef, data);
    console.log('RecieveUserInfo Data set.');
  } catch (error) {
    console.error(error);
    return error;
  }
}


// export const RecieveUserInfo = async (currentUid, toUid, message, image, senderInfo, createdAt) => {
//     try{

//       const receiverId = typeof toUid === 'object' ? toUid.id || toUid._id : toUid;
//       const data= {
//         sender: receiverId ,
//         receiver: currentUid,
//         message: message || '',
//         image: image || '',
//         userInfo: JSON.stringify(senderInfo),
//         createdAt: createdAt
//     }

//     const messageRef = ref(database,`/users/${toUid}/${currentUid}`)
//     await set(messageRef, data).then(() => console.log(' SendUserInfo Data set.'))
        
//         .then(() => console.log('Data set.'));

//     }
//     catch (error) {
//         console.log(error);
//         return error;
//     }
// }
