import { useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { setSession, getSession } from "../../utils/helper";
import Header from "../../components/header";
import Footer from "../../components/footer";
import LeftnavbarIndividual from "../../components/leftnavbaraIndividual";
import { Link, useNavigate, useLocation } from "react-router-dom";

import lottie from "lottie-web";
import { apiBaseUrl } from "../../config/constant";
const Individualservice_details = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const location = useLocation();
  const containerRef = useRef();
  const data_id = location.state;

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: containerRef.current,
      renderer: "svg", // Use 'svg' or 'canvas' depending on your preference
      loop: true,
      autoplay: true,
      animationData: require("./data.json"), // Replace with your animation JSON file
    });
    return () => animation.destroy();
  }, []);

  const [showConfirmation, setShowConfirmation] = useState(false);
  //const { id } = useParams();
  const [priceproceed, setpriceproced] = useState(false);
  const navigate = useNavigate(); // Access the 'id' from the URL parameters
  const [data, setData] = useState({
    serviceData: {
      service_name: "",
    },
    subServices: {
      sub_service_name: "",
    },
    price: "",
    category: "",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = getSession("token");
        const response = await axios.post(
          apiBaseUrl + "provider/get-individual-single-services",
          { id: data_id._id },
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
        setData(response.data.response.result);
      } catch (error) {
        console.error(error);
        setError("Failed to fetch data");
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const delete_popup = () => {
    setShowConfirmation(true);
  };
  const onCancel = () => {
    setShowConfirmation(false);
  };
  const deleteService = async (id) => {
    navigate("/individual/services");
    try {
      // Make the API request to delete the service using the 'id' parameter
      let token = getSession("token");
      const response = await axios.post(
        apiBaseUrl + "provider/delete-individual-services",
        { id: id },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const edit_view = (data_id) => {
    setSelectedItem(data_id);
    navigate("/Individual/service_edit", { state: data_id });
  };

  return (
    <>
      <Header></Header>
      <div className="dashboard">
        <div className="container">
          <div className="row mx-0">
            <LeftnavbarIndividual></LeftnavbarIndividual>
            <div className="col-md-9">
              <div className="vendors_right_forms">
                <div className="right_side">
                  <div className="row mx-0" id="services_details">
                    <div className="col-12">
                      <div className="btn_es_align mb-3">
                        <button className="arrow_down">
                          <Link to="/individual/services">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/vendor_dashboard/order_detail/order_details.png"
                              }
                            />
                          </Link>
                        </button>
                        <div
                          className="edit_Service" id="editBtnindi"
                          onClick={() => edit_view(data_id)}
                        >
                          <button className="button edit_Service_first">
                            Edit Service
                          </button>
                          <button className="button edit_Service_second">
                            Edit Service
                          </button>
                        </div>
                        <div className="cancel_book" onClick={delete_popup}>
                          <button className="button button_cancel_book">
                            Delete Service
                          </button>
                          <button className="button button_cancel--hover">
                            Delete Service{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {showConfirmation && (
                    <div className="delete-confirmation-popup">
                      <div className="popup-content">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/delete_icon.png"
                          }
                        ></img>
                        <h2>Confirm Deletion</h2>
                        <p>Are you sure you want to delete this service?</p>
                        <div className="popup-buttons">
                          <button className="cancel-button" onClick={onCancel}>
                          No
                          </button>
                          <button
                            className="delete-button"
                            onClick={() => deleteService(data._id)}
                          >
                        Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div>
                      <div className="row">
                        <div className="col-md-3">
                          <h1 className="services_deatils_heading">Service</h1>

                          <div className="user_name">
                            <input
                              type="text"
                              value={data?.serviceData?.service_name}
                              placeholder="Unisex Salon"
                              className="unix_slon"
                            />
                          </div>
                        </div>
                        <div className="col-md-9"> </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <h1 className="services_deatils_heading">
                            Sub-Service
                          </h1>

                          <div className="user_selection">
                            <ul className="mb-0">
                              <li className="user_Selective" id="sub_serice-Indi">
                                {data.subServices.sub_service_name}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="work_list_category">
                            <h2 className="my-2">Service ( Work Gallery )</h2>
                            <ul>
                              <li>
                                <div className="service_category_list">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/vendor_dashboard/worklist/left_side.png"
                                    }
                                  />
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/vendor_dashboard/worklist/right_side.png"
                                    }
                                  />
                                </div>
                              </li>
                              <li>
                                <div className="service_category_list">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/vendor_dashboard/worklist/left_side.png"
                                    }
                                  />
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/vendor_dashboard/worklist/right_side.png"
                                    }
                                  />
                                </div>
                              </li>
                              <li>
                                <div className="service_category_list">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/vendor_dashboard/worklist/left_side.png"
                                    }
                                  />
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/vendor_dashboard/worklist/right_side.png"
                                    }
                                  />
                                </div>
                              </li>
                              <li>
                                <div className="service_category_list">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/vendor_dashboard/worklist/left_side.png"
                                    }
                                  />
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/vendor_dashboard/worklist/right_side.png"
                                    }
                                  />
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <h1 className="services_deatils_heading">Price</h1>

                          <div className="user_selection">
                            <div className="price">
                              <span>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/vendor_dashboard/nan.png"
                                  }
                                />
                              </span>
                              <input
                                type="text"
                                placeholder="295"
                                value={data.price}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Individualservice_details;
