import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import Header from "../../components/header";
import Providerslider from "../../slider/providerSlider";
import Footer from "../../components/footer";
import Clientreview from "../../slider/clientReviews";
import { faStar } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { setSessionForAddress } from "../../utils/helper";
import { BaseUrl, apiBaseUrl } from "../../config/constant";
import {
  getSession,
  setSession,
  getSessionForLoginUser,
  setSessionForLoginUser,
} from "../../utils/helper";
import {
  validationSlotSchema,
  SlotBookingSchema,
} from "../../components/validators/userFormValidator";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AddressModal from "../../components/AddressModal";
import { validationSchemaForAddress } from "../../components/validators/userFormValidator";
import { getSessionForAddress } from "../../utils/helper";
import {
  getDatabase,
  ref,
  query,
  orderByChild,
  equalTo,
  push,
  onValue,
} from "firebase/database";
import { firebaseWeb } from "../../firebase";
import { ChatList , SendUserInfo ,RecieveUserInfo ,ReceiveMessage } from "../../components/FirebaseChat.js/ChatList";
import { ChatWithSelectedUser } from "../../components/FirebaseChat.js/ChatBox";
import { getCountry,getSate,getCity, formatTime, generateTimes } from '../../components/common';
  
import { faCheck} from "@fortawesome/fontawesome-free-solid";

const Providerprofile = () => {
  const scrollControlRef1 = useRef(null);
  const scrollControlRef2 =useRef(null)
  const location = useLocation();
  const pid = getSessionForAddress("pid");
  const fileInputRef = useRef(null);
  const NavigateData = location;
  const key = NavigateData.state.item._id;
  const similarProvider = NavigateData.state.filteredProviders;
  const selectedSubService =NavigateData.state.activeTab;
  const userData = getSessionForLoginUser("user");
  const [imageSrc, setImageSrc] = useState("");
  const navigate = useNavigate();
  const token = getSession("token");
  const imageInputRef2 = useRef(null);
  const [profileData, setProfileData] = useState([]);
  // console.log(profileData,"pppppp")
  const [serviceData, setServicesData] = useState([]);
  const [message, setMessage] = useState("");
  const [serviceId,setServiceId]=useState('')
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => {chatsData(); ChatWithSelectedUser(userData,profileData) ;setShow8(true)};
  const [workerGalleryData, setWorkerGalleryData] = useState([]);
  const [clientReviewsData, setClientReviewsData] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isTrue, setIsTrue] = useState(false);
  const [show, setShow] = useState(false);
  const preMOnth = () => {
    alert("clicked");
    sub(currentDate, { months: 1 });
  };
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => {
  setShow2(true);
  };
  const handleClose2 = () => {
    setShow2(false);
  };
  const handleShow = (id) => {
    if (!userData) {
      openHeaderModal();
    }else setShow(true);

    // setSessionForAddress('pid', id);
    // navigate('/provider_profile' , {state:id});
  };
  const handleClose = () => {
    setShow(false);
  };
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [selectedItem, setSelectedItem] = useState(0);
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [selectedTimeItem, setSelectedTimeItem] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [data, setData] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [address, setAddress] = useState("");
  const [errors2, seterrors2] = useState({});
  const [data2, setData2] = useState([]);
  const[ state, setState ]=useState([]);
  const[city, setCity]=useState([])
  const [country, setCountry] = useState([])

  
  const selectedTime = (index, time) => {
    setSelectedTimeItem(index);
    setFormData((prevFormData) => ({
      ...prevFormData,
      service_time: time,
    }));
  };

  useEffect(() => {
    if (!userData) {
      openHeaderModal();
    }
  }, []);

  const certificateImage = new File([""], "profile_pic.png", {
    type: "image/png",
  });
  
  const [formData, setFormData] = useState({
    service_location: selectedItemId,
    service_date: selectedDate,
    service_time: "03:00 PM",
    service_type: serviceId,
    provider_id: profileData?._id || pid,
    platform: "Web",
    job_desc: "",
    jobImage: selectedImage2,
    // latitude: "74.2364",
    // longitude: "74.2665",
    // ipAddress: ":1",
  });
  useEffect(() => {
    if (profileData?._id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        provider_id: profileData._id,
      }));
    }
  }, [profileData]);

  const fetchSlotData = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl+"order/assign-job",
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          apiBaseUrl+"users/web-service-provider-details",
          {
            provider_id: key,
          },
          {
            headers: {
              key: "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
              "Content-Type": "application/json",
            },
          }
        );
        setProfileData(response.data.response.result.providerData);
        setServicesData(response.data.response.result.subServiceData);
        // console.log(response.data.response.result.subServiceData);
        setWorkerGalleryData(response.data.response.result.workGallery );
        setClientReviewsData(response.data.response.result.feedbackData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(()=>{
    const filteredData = serviceData.filter((item) => {
      return item.subServices.sub_service_name === selectedSubService;
    });

    setFormData((prevFormData) => ({
      ...prevFormData,
      service_type: filteredData[0]?._id,
    }));


    setServiceId(filteredData[0]?._id);
  },[serviceData])

  const percentage = 70;
  // const profileImage = "http://localhost:3000/vendor_dashboard/my%20Profile.png";

  //  CAC Capture

  const handleImageSelect2 = (event) => {
    const file = event.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      jobImage: file,
    }));
    setSelectedImage2(file);
  };

  const handleCaptureButtonClick2 = () => {
    // Trigger the file input click event
    imageInputRef2.current.click();
  };

  const cancelSelectedImage = () => {
    setSelectedImage2(null);
  };
  // END CAC Capture

  // validation , handle submit, handlechnage

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await SlotBookingSchema.validate(formData, { abortEarly: false });
      setFormErrors({});
      fetchSlotData()
        .then((data) => {
          if (data.status === true) {
            const ids = {
              slotid: data.response.result._id,
              worker_id: data.response.result.worker_id,
            };
            navigate("/provider_checkout", { state: { data, profileData } });
          }
        })
        .catch((error) => {
          console.error(error);
        });

      // Validation succeeded, proceed with form submission
    } catch (validationErrors) {
      // Validation failed, handle the errors
      const errors = {};
      // console.log('validationErrors',validationErrors)
      validationErrors.inner.forEach((error) => {
        errors[error.path] = error.message;
      });

      setFormErrors(errors);
      console.log('error',formErrors);
    }
  };

  // end validation , handle submit, handlechnage

  // calender dates

  const handleDateClick = (index, date) => {
    setSelectedItem(index);
  
    // Extract day, month, and year from the date
    const day = date.slice(0, 2);
    const month = date.slice(3, 5);
    const year = date.slice(6, 8);
  
    // Format the selected date as "DD-MM-YY"
    const formattedDate = day + "-" + month + "-" + year;
    setSelectedDate(formattedDate);
    setFormData((prevData) => ({
      ...prevData,
      service_date: formattedDate,
    }));
  };
  // -----------------------------------------------mouse scroll----------------------------


  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e, scrollRef) => {
    setIsMouseDown(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseMove = (e, scrollRef) => {
    if (!isMouseDown) return;
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 3; // Adjust the multiplier for faster/slower scrolling
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };
  // ----------------------------------------------end-----------------------------------------
  
  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const daysInNextMonth = new Date(year, month + 2, 0).getDate(); // Days in the next month
    const totalDays = daysInMonth + daysInNextMonth - currentDay; // Total days for 10-day range
    const generatedDates = [];

    for (
      let i = currentDay, count = 0;
      count < totalDays && count < 10;
      i++, count++
    ) {
      const date = new Date(year, month, i);
      const day = date.toLocaleString("en-us", { weekday: "short" });
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      });

      generatedDates.push({ day, date: formattedDate });
    }

    setDates(generatedDates);

    if (generatedDates.length > 0) {
      handleDateClick(0, generatedDates[0].date);
    }

  }, []);

  // end calenderdates

  const headerRef = useRef();
  const openHeaderModal = () => {
    headerRef.current.openModal();
  };
  const fetchData = async () => {
    try {
      const url = apiBaseUrl+"users/address-list";
      const response = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });

      // Do something with the response data
      setData(response.data.response.result);

      if (response.data.response.result) {
        userData.addressData = response?.data?.response?.result;
        setSessionForLoginUser("user", userData);
      }
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // getting address start from here

  const [formData2, setformData2] = useState({
    country: "",
    state: "",
    city: "",
    land_mark: "",
    address1: "",
    address2: "",
    postal_code: "",
    platform: "Web",
  });

  let currentDate = null; 
  const handleChange2 = async (e) => {
    const { name, value } = e.target;
  
    try {
      if (name === 'country') {
        const jsonObject = JSON.parse(value);
        const country = await getSate(jsonObject.id);
        setState(country);
  
        setformData2((prevFormData) => ({
          ...prevFormData,
          [name]: jsonObject.name,
        }));
      } else if (name === 'state') {
        const jsonObject = JSON.parse(value);
        const state = await getCity(jsonObject.id);
        setCity(state);
  
        setformData2((prevFormData) => ({
          ...prevFormData,
          [name]: jsonObject.name,
        }));
      } else if (name === 'city') {
        const jsonObject = JSON.parse(value);
        setformData2((prevFormData) => ({
          ...prevFormData,
          [name]: jsonObject.name,
        }));
      } else {
        setformData2((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      }
  
      seterrors2((prevErrors2) => ({
        ...prevErrors2,
        [name]: '',
      }));
    } catch (error) {
      console.error('Error parsing JSON:', error);
      // Handle the error as needed (e.g., show a message to the user)
    }
  };
  

  useEffect(() => {

    if (data) {
      if (userData) {
        userData.addressData = data;
        setSessionForLoginUser("user", userData);
      }
    }
  }, [data]);

  const postData = async () => {
    try {
      const response = await axios.post(
        apiBaseUrl+"users/address",
        formData2,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmit2 = (e) => {
    e.preventDefault();
    validationSchemaForAddress
      .validate(formData2, { abortEarly: false })
      .then(() => {
        postData()
          .then((response) => {
            if (response.status === true) {
              fetchData();
              handleClose3();
              handleShow();
            }
          })
          .catch((error) => {
            console.error(error);
          });
        // console.log("Form is valid. Submitting...");
      })
      .catch((err) => {
        // Validation failed
        const validationerrors2 = {};
        err.inner.forEach((error) => {
          validationerrors2[error.path] = error.message;
        });
        seterrors2(validationerrors2);
      });
  };

  // end

  const handleAdd = () => {
    if (userData?.addressData && userData?.addressData?.length > 0) {
      handleShow2();
      handleClose();
    } else handleShow3();
    handleClose();
  };

  React.useEffect(() => {
    if (data.length > 0 && selectedItemId === null) {
      setSelectedItemId(data[0]._id);
      setFormData({
        ...formData,
        service_location: data[0]._id,
      });
      setAddress(data[0]);
    }
  }, [data, selectedItemId, formData]);

  const handleRadioClick = (item) => {
    setSelectedItemId(item?._id);
    setAddress(item);
    handleClose2();
    handleShow();
    setFormData({
      ...formData,
      service_location: item?._id,
    });
  };

  const [selectedTimeItem2, setSelectedTimeItem2] = useState(0);
  const [times, setTimes] = useState([]);

  const selectedTime2 = (index, time) => {
    setSelectedTimeItem2(index);
  };




  // ----------------------------------------------chats firebase --------------------------
  const chatContainerRef = useRef(null);
  const database = getDatabase(firebaseWeb);
  const databaseRef = ref(database, "/");

const chatsData=()=>{
  try {
    const userId = userData?._id;
    const toUserId = profileData?._id;
    // const database = firebaseWeb.database();
    const chatRef = ref(database, `/messages/${userId}/${toUserId}`);
    const unsubscribe = onValue(chatRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Data exists, process it
        let messages = Object.entries(data).map(([key, messageData]) => {
          return {
            sender: messageData.sender,
            receiver: messageData.receiver,
            message: messageData.message,
            image: messageData.image,
            createdAt: messageData.createdAt,
          };
        });

        // Sort messages by createdAt in descending order
        messages.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

        setChatData(messages);
      } else {
        // Data doesn't exist or is empty, handle accordingly
        setChatData([]);
      }
    });

    // Clean up the listener when the component unmounts
    return () => {
      unsubscribe();
    };
  } catch (error) {
    console.error(error);
    // Handle the error using your Alert component or other error-handling method
  }

}


  const SendMessage = async (event) => {
    event.preventDefault();
  
    if (!message.trim() && !imageSrc) {
      alert("Enter a valid message or select an image");
      return;
    }
  
    try {
      const currentUid = userData?._id;
      const toUid = profileData?._id;
  
      const data = {
        sender: currentUid,
        receiver: toUid,
        message: message,
        image: imageSrc || "", // Ensure image is not undefined
        createdAt: new Date().getTime(),
      };
      const messageRef = ref(database, `/messages/${currentUid}/${toUid}`);
      await push(messageRef, data);
      // console.log("Data set.");
      setMessage("");
      const createdAt = new Date().getTime();
      // const data=await SendMessage(vendor._id,selectUserToChat,message,imageSrc,createdAt);
   SendUserInfo(userData?._id,profileData,message,imageSrc,profileData,createdAt);
   RecieveUserInfo(userData?._id,profileData,message,imageSrc,userData,createdAt);
   ReceiveMessage(userData?._id,profileData,message,imageSrc,createdAt)
      const chatMessages = await ChatWithSelectedUser(userData, profileData);
      setChatData(chatMessages);
      setImageSrc('')
    } catch (error) {
      console.error("Error:", error);
    }

  };
  

  const handleButtonClick = () => {
    // Trigger the file input dialog
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

    const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Image = e.target.result;
        setImageSrc(base64Image);
      };

      reader.readAsDataURL(file);
    }
  };
  const handleCloseImg=()=>{
    setImageSrc("")
  }



  // ----------------------------------------------end firebase ------------------------

  // useEffect(() => {
  //   // Sort the chat messages by createdAt timestamp in ascending order
  //   const sortedMessages = [...chatData].sort((a, b) => a.createdAt - b.createdAt);
  //   setChatData(sortedMessages);
  // }, [chatData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countries = await getCountry();
        setCountry(countries);
      } catch (error) {
        // Handle any errors here
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
        setTimes(generateTimes());
  }, []);

  return (
    <>
      <Header ref={headerRef} />
      <div className="banner_providerprofile">
        <div className="container">
          <div className="row">
            <>
              <div className="col-md-2 col-lg-2">
                <div style={{ width: "100px", margin: "auto", height: "98px" }} id="banner_providerprofile">
                  <CircularProgressbar
                    value={percentage}
                    strokeWidth={6}
                    styles={buildStyles({
                      strokeLinecap: "butt", // Use 'butt' for a round image, 'round' for a circular image
                      pathColor: `rgba(86,209,98, 1, ${percentage / 100})`,
                      textColor: "#24A831",
                      trailColor: "#d6d6d6",
                      borderradius: "10px",
                    })}
                  />

                  <img src={BaseUrl + profileData.profile_pic} alt="Your Image"
                    style={{ width: "63px", height: "65px", position: "absolute", transform: "translate(-118%, 16%)", borderradius: "50%", }}
                  />
                  <div className="circular_value">
                    <p className="percentage_value"> {`${percentage}%`} </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="provider_list_box">
                  <h1>
                    {profileData.fullName}{" "}
                    <span className="user_list_list">
                      <div className="multiple_stars_content">
                        <ul>
                          <li>
                            <FontAwesomeIcon icon={faStar} className="star" />
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faStar} className="star" />
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faStar} className="star" />
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faStar} className="star" />
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faStar} className="star" />
                          </li>
                        </ul>
                      </div>
                      {profileData.averageRating?profileData.averageRating : <p> </p> }
                    </span>
                  </h1>
                  <p className="my-2 mx-0">
                    {profileData.land_mark} {profileData.address2}
                    {profileData.postal_code} {profileData.city}
                    {profileData.country}
                  </p>
                  <div className="assign_work_provider">
                  {userData?    <button onClick={handleShow8}>
                      <img
                        src={process.env.PUBLIC_URL + "/images/chat.png"}
                        alt="app"
                      />
                      <p>Chat</p>
                    </button> : ''}
                 
                    <button
                      className="assignes_worker"
                      onClick={() => {
                        handleShow(profileData._id);
                      }}
                    >
                      Assign Job
                    </button>
                  </div>
                </div>
              </div>
            </>

            <div className="col-md-4"></div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="work_list_category">
                <h2>Service ( Work Category )</h2>
                <div className="work_Category">
                  <ul>
                  {[...new Set(serviceData.map(item => item.serviceData.service_name))].map((serviceName, index) => {
  const item = serviceData.find(item => item.serviceData.service_name === serviceName);
  return (
    <li key={index}>
      <div className="services_worklist">
        <img src={item?.subServices?.sub_service_icon} alt="profile-image" />
        <p>{item?.subServices?.sub_service_name}</p>
      </div>
    </li>
  );
})}

                  </ul>
                </div>
              </div>
              {workerGalleryData?.length>0? 
              <div className="work_list_category">
                <h2>Service ( Work Gallery )</h2>
                <ul>
                  {workerGalleryData?.slice(0,4).map((item, index)=>(
                        <li>
                            <div className='service_category_list'>
                                <img src={BaseUrl+item.before_work_pic} />
                                <img src={BaseUrl+item.after_work_pic } />
                            </div>
                        </li>
                    ))}
                </ul>
              </div>
              : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="provider_profile">
      {clientReviewsData && clientReviewsData.length > 0 && (<></>)}
        <div className="other-provider mt-4 pt-4 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Other Similar Providers</h1>
                <p>
                Trust us to deliver exceptional results, leaving your space fresh, inviting, and hygienic
                  <br></br>Have a look at our other trusted professionals.
                </p>
              </div>
            </div>
          </div>

          <Providerslider similarProvider={similarProvider} />
        </div>
      </div>
      <Footer />

      <Modal show={show} className="join_model">
        <Modal.Body className="pt-0">
          <div className="cross-X" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "/images/user/cross.png"}
              alt="playstore"
            />
            <img
              src={process.env.PUBLIC_URL + "/images/user/X.png"}
              alt="playstore"
            />
          </div>

          <div className="model_popup">
            <h4 className="assign_title">Assign work to provider</h4>

            <div className="get_location_slot d-flex justify-content-between">
              <div>
                <h2>Service Location</h2>
                <p>
                  {address.land_mark}, {address.address1}, {address.city},{" "}
                  {address.state}, {address.postal_code}, {address.country}
                </p>
                {formErrors.service_location? <p className="error">{formErrors.service_location}</p>:""}
              </div>
              <div
                className="slot_change_address"
                onClick={() => {
                  handleAdd();
                }}
              >
                {userData?.addressData && userData?.addressData?.length > 0
                  ? "Change Address"
                  : "Add Address"}
              </div>
            </div>

            <div className="d-flex justify-content-between date-picked">
              <p>Service Date</p>
            </div>

            <div className="calender">
              <div className="calender-header d-flex justify-content-around mb-4">
                <img
                  src={process.env.PUBLIC_URL + "/images/arrow_right.png"}
                  alt=""
                  onClick={preMOnth}
                  id="calender-year-arrow"
                />
                {/* <span> August , 2023</span> */}
                <img
                  src={process.env.PUBLIC_URL + "/images/arrow_left.png"}
                  alt=""
                  id="calender-year-arrow"
                />
              </div>
              <ul
      className="d-flex justify-content-between mt-2 border_date"
      id="scroll-control"
      ref={scrollControlRef1}
        onMouseDown={(e) => handleMouseDown(e, scrollControlRef1)}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onMouseMove={(e) => handleMouseMove(e, scrollControlRef1)}
    >
                {dates.map((date, index) => (
                  <li
                    key={index}
                    className={selectedItem === index ? "activDate" : ""}
                    onClick={() => handleDateClick(index, date.date)}
                  >
                    <p>{date.day}</p>
                    <h1>{date.date.slice(0, 2)}</h1>
                  </li>
                ))}
              </ul>
              {formErrors.selectedDate && (
                <div className="error">{formErrors.selectedDate}</div>
              )}

              <p id="service-time">Service Time</p>

              <ul
                className="d-flex justify-content-between  calendar_content border_date time-pick"
                id="scroll-control"
                ref={scrollControlRef2}
onMouseDown={(e) => handleMouseDown(e, scrollControlRef2)}
onMouseUp={handleMouseUp}
onMouseLeave={handleMouseUp}
onMouseMove={(e) => handleMouseMove(e, scrollControlRef2)}
              >
      {times.map((time, index) => (
        <li
          key={index}
          className={selectedTimeItem === index ? "activDate" : ""}
          onClick={() => selectedTime(index, formatTime(time).split(" ")[0]+(' ')+formatTime(time).split(" ")[1])}
        >
          <h2>{formatTime(time).split(" ")[0]}</h2>
          <p className="mb-0">{formatTime(time).split(" ")[1]}</p>
        </li>
      ))}
    </ul>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form_group">
                  <div className="custom-select-wrapper" id="selected_service_sld">
                    <select
                      className={`custom-select blur-effect ${
                        formErrors.service_type ? "VendorInputError" : ""
                      }`}
                      name="service_type" // Name should be "service_type"
                      onChange={handleChange}
                      value={formData.service_type}
                    >
                      <option value="">{selectedSubService}</option>
                      {/* {serviceData.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item?.subServices?.sub_service_name}
                        </option>
                      ))} */}
                    </select>

                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/user/selectArrow.png"
                      }
                      alt="arrow"
                      className="arrow-image"
                    />
                  </div>
                  {errors.state && <div className="error">{errors.state}</div>}
                </div>
              </div>

              <div className="col-12">
                <div className="form_group">
                  <textarea
                    className={`form-control ${
                      formErrors.job_desc ? "VendorInputError" : ""
                    }`}
                    name="job_desc"
                    onChange={handleChange}
                    value={formData.job_desc}
                    rows={4}
                  ></textarea>
                  {formErrors.job_desc && (
                    <div className="error">{formErrors.job_desc}</div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div
                    className={`form_group ${
                      errors.certificate ? "VendorInputError" : ""
                    }`}
                  >
                    <div
                      class="button-container"
                      style={{ width: "100%", marginLeft: "4.5%" }}
                    >
                      <button
                        className="button button1"
                        onClick={handleCaptureButtonClick2}
                      >
                        Upload Work Image
                      </button>
                      <button
                        className="button button2"
                        onClick={handleCaptureButtonClick2}
                      >
                        Upload Work Image
                      </button>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div
                    className={`form_group ${
                      errors.certificate ? "VendorInputError" : ""
                    }`}
                  >
                    {selectedImage2 ? (
                      <div
                        className="wallpaper-icon-ur d-flex justify-content-around "
                        id="img-upload"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/user/wallpaper.png"
                          }
                          alt="futerMob"
                          className="walpaper-icon"
                        />
                        {selectedImage2 ? (
                          <p> {selectedImage2.name} </p>
                        ) : (
                          <p>IMG 556654645634</p>
                        )}
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/user/X-red.png"
                          }
                          alt="futerMob"
                          className="walpaper-icon-cross-icon"
                          onClick={cancelSelectedImage}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageSelect2}
                      ref={imageInputRef2}
                    />
                  </div>
                  {errors.certificate && (
                    <div className="error">{errors.certificate}</div>
                  )}
                </div>
              </div>
              <div
                class="button-container"
                style={{ width: "50%", margin: "auto" }}
              >
                <button className="button button2" onClick={handleSubmit}>
                  Proceed To Book
                </button>
                <button className="button button1" onClick={handleSubmit}>
                  Proceed To Book
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Body>
          <div
            className=""
            onClick={() => {
              handleClose2();
              handleShow();
            }}
            style={{ position: "absolute", right: "0", top: "-10px" }}
          >
            <img
              src={process.env.PUBLIC_URL + "../images/cross.png"}
              alt="app"
              className="cross_icon"
            />
          </div>
          <h6>Select your location</h6>{" "}
          <div className="row mx-0 mt-3 mb-2 modal_size">
            {data.map((item, index) => (
              <div className="col-6 mb-3" key={index}>
                <div className="address_details">
                  <div className="address_card">
                    <input
                      type="radio"
                      onClick={() => handleRadioClick(item)}
                      checked={selectedItemId === item._id}
                    />
                    <span>
                      <button className="addres_num">
                        Address {index + 1}
                      </button>
                    </span>
                  </div>
                  <p>
                    {item.land_mark}, {item.address1}, {item.city}, {item.state}
                    , {item.postal_code}, {item.country}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal show={show3} onHide={handleClose3}>
        <div className="model_header">
          <div className="header_viewes">
            <p> My Addresses</p>
            <h1>Add New Address</h1>
          </div>
          <div className="">
            <img
              src={process.env.PUBLIC_URL + "../images/cross.png"}
              alt="app"
              className="cross_icon"
              onClick={handleClose3}
            />
          </div>
        </div>
        <Form onSubmit={handleSubmit2}>
          <Modal.Body>
            <div className="row mx-0" id="userAddress">
              <div className="col-md-6">
                <select
                  name="country"
                  className="select_options"
                  // value={formData2.country}
                  onChange={handleChange2}
                >
                  <option>Select Country</option>
                  {country.map((item, index)=>(
    <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                    ))}
                </select>
                {errors2.country && (
                  <p className="error-message">{errors2.country}</p>
                )}
              </div>
              <div className="col-md-6">
                <select
                  name="state"
                  className="select_options"
                  // value={formData2.state}
                  onChange={handleChange2}
                >
                  {/* <option value={formEditData.state}>{formEditData.state}</option> */}
                  <option>Select state</option>
                  {state.map((item, index)=> (           
                    <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                  ) )} 
                </select>
                {errors2.state && (
                  <p className="error-message">{errors2.state}</p>
                )}
              </div>
              <div className="col-md-6">
                <select
                  name="city"
                  className="select_options"
                  // value={formData2.city}
                  onChange={handleChange2}
                >
                  <option>Select City</option>
                  {city.map((item, index)=>(
                      <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                    ))}
                </select>
                {errors2.city && (
                  <p className="error-message">{errors2.city}</p>
                )}
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  placeholder="land_mark"
                  className="ladnmark"
                  name="land_mark"
                  value={formData2.land_mark}
                  onChange={handleChange2}
                />
                {errors2.land_mark && (
                  <p className="error-message">{errors2.land_mark}</p>
                )}
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  name="address1"
                  placeholder="Address Line 1"
                  className="address_line"
                  value={formData2.address1}
                  onChange={handleChange2}
                />
                {errors2.address1 && (
                  <p className="error-message">{errors2.address1}</p>
                )}
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  name="address2"
                  placeholder="Address Line 2"
                  className="address_line"
                  value={formData2.address2}
                  onChange={handleChange2}
                />
                {errors2.address2 && (
                  <p className="error-message">{errors2.address2}</p>
                )}
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  placeholder="Postal Code"
                  className="ladnmark"
                  name="postal_code"
                  value={formData2.postal_code}
                  onChange={handleChange2}
                />
                {errors2.postal_code && (
                  <p className="error-message">{errors2.postal_code}</p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="model_submit">
              <button className=" button submit_close" type="submit">
                Submit
              </button>
              <button className="button submit_close_hover" type="submit">
                Submit
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={show8}
        onHide={handleClose8}
        className="chat_user_boxses slide-up"
        id="chatbox"
      >
        <div className="model_header">
          <div className="header_box">
            <img
         src={ BaseUrl +profileData?.profile_pic}
              alt="app"
              className="app_img"
            />
            <p>{profileData?.fullName}</p>
          </div>
          <div className="">
            <img
              src={process.env.PUBLIC_URL + "/images/model_cross.png"}
              alt="app"
              className="cross_icon"
              onClick={handleClose8}
            />
          </div>
        </div>

        <Modal.Body className="py-0" id="#style-4">
          <Form className="chatBox_height" ref={chatContainerRef}>
            <div className="row mx-0">

            {/* {chatData.slice().reverse().map((message, index) => (
  <div key={index} className="col-md-12 px-0">
    {message.sender === userData._id ? (
      <div className="chat_boxex receiver mt-2">
        <div className="user_box">
          <p>{message.image ? <img src={message.image} alt="sender_img" /> : message.message}</p>
        </div>
      </div>
    ) : (
      <div className="client_box sender">
        <p>{message.image ? <img src={message.image} alt="sender_img" /> : message.message}</p>
      </div>
    )}
  </div>
))} */}

{chatData.slice().reverse().map((message, index, array) => {
  const isSender = message.sender === array[index - 1]?.sender;
  const isReceiver = message.receiver === array[index - 1]?.receiver;
  
  // Format the timestamp to get the date (e.g., "02/09/20")
  const messageDate = new Date(message.createdAt).toLocaleDateString(undefined, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });

  // Check if the current date is different from the previous message's date
  const shouldDisplayDate = messageDate !== currentDate;
  
  // Update the current date to the new message's date
  currentDate = messageDate;

  return (
    <div key={index} className="col-md-12 px-0">
      {shouldDisplayDate && <div className="date_on_chat">{messageDate==="Invalid Date"? '' : messageDate}</div>}
      {message.sender === userData._id ? (
        <div className="chat_boxex">
          <div className="user_box">
          <p className="timeStampBlock">
  {message.message ? (
    <>
      {message.message}
      <span className="timestamp">
      {new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
      <FontAwesomeIcon icon={faCheck}  className="fa_check"/>
    
      </span>
    </>
  ) : message.image ? (
    <>
    <img src={message.image} alt='sender_image' style={{ width: "100%" }} />
<span className="timestamp" id="timeStamp_receiver">
    {new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })} 
    {/* <img src={process.env.PUBLIC_URL+"/images/icon/check.png"} alt="check"/> */}
    {/* <FontAwesomeIcon icon="fa-regular fa-check" /> */}
    <FontAwesomeIcon icon={faCheck}  className="fa_check"/>
    </span>
</>
  ) : (
    ""
  )}
</p>
          </div>
        </div>
      ) : (
        
        <div className="client_box">
          
          {message.image ? (
            <>
                <img src={message.image} alt='receiver_image' style={{ width: "100%" }} />
            <span className="timestamp" id="timeStamp_receiver">
                {new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
         
                </span>
             
            </>
        
          ) : (
            <p className="timeStampBlock">
            {message.message ? (
              <>
                {message.message}
                <span className="timestamp" id="timeStamp_receiver">
                {new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
                </span>
              </>
            ) : message.image ? (
              <img src={message.image} alt="sender_image" />
            ) : (
              ""
            )}
          </p>
          )}
        </div>
      )}
    </div>
  );
})}

            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="chat_model_footer">
            <button onClick={handleButtonClick}>+</button>

            {imageSrc ? (
              <div className="image_chat_box">
                {imageSrc && (
                  <img
                    src={imageSrc}
                    alt="Preview"
                    style={{ maxWidth: "100%", maxHeight: "300px" }}
                  />
                )}
                <span className="closeImgChat" onClick={handleCloseImg}>✖</span>
                <button className="sendImg" onClick={SendMessage}>send</button>
              </div>
            ) : (
              <input
                type="text"
                className="user_enter_name"
                placeholder="Write Your Message here..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    SendMessage(e);
                  }
                }}
              />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Providerprofile;
